// @flow

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Componentify from "react-componentify";

import { __ } from "../../lib/translate";
import apiMethod from "../../api/apiMethod";
import { linkConverter } from "../../lib/componentifyConverters";

import { ButtonCentered } from "../../components/ButtonDefault";
import InputStyledCheckbox from "../../components/InputStyledCheckbox";
import { LOCATION_REGIONS, US_LOCATIONID, EU_LOCATIONID } from "@pcloud/web-utilities/dist/config/constants";
import { currencyPrices } from "../../../web-shared/prices";
import { currencyMap } from "../../../web-shared/constants";

import { Container } from "./SharedComponents";

type Props = {
  hasFree?: boolean,
  hasPaidRelocation?: boolean,
  currency?: string,
  currentLocationId: US_LOCATIONID | EU_LOCATIONID,
  changeToLocationId: US_LOCATIONID | EU_LOCATIONID,
  onSuccess: () => void
};

const TermsStep = ({ hasFree = true, hasPaidRelocation = false, currency = "$", currentLocationId, changeToLocationId, onSuccess = () => {} }: Props) => {
  const isFamily = useSelector(({ user }) => user.userinfo.family);
  const isBusiness = useSelector(({ user }) => user.userinfo.business);
  const [isAcceptedTerms, setIsAcceptedTerms] = useState(false);
  const [errorCheckbox, setErrorCheckbox] = useState(false);

  const currentLocationKey = LOCATION_REGIONS[currentLocationId].key;
  const changeToLocationKey = LOCATION_REGIONS[changeToLocationId].key;
  const currencySign = currencyMap[currency];
  const relocationPlanId = 112;
  const descriptionText = __("change_region_description")
    .replace("%locationA%", __(currentLocationKey))
    .replace("%locationB%", __(changeToLocationKey));

  const onSubmit = () => {
    setErrorCheckbox(!isAcceptedTerms);
    if (!isAcceptedTerms) {
      return;
    }

    onSuccess();
  };

  const onTermsChange = (e: any) => {
    setIsAcceptedTerms(e.target.checked);
  };
  const hasFreeRelocation = hasFree || hasPaidRelocation;

  const renderBasicBullets = () => {
    return (
      <React.Fragment>
        {!hasFreeRelocation ? (
          <BulletWrapper>
            <Bullet />
            <BulletText>
              {__(
                "change_region_terms_bullet4",
                "You'll be charged a single fee of %price% %currency% for the move.",
                {
                  currency: currency,
                  price: currencyPrices[relocationPlanId][currencySign].onetime
                }
              )}
            </BulletText>
          </BulletWrapper>
        ) : null}
        <BulletWrapper>
          <Bullet />
          <BulletText>{__("change_region_terms_bullet1")}</BulletText>
        </BulletWrapper>
        <BulletWrapper>
          <Bullet />
          <BulletText>{__("change_region_terms_bullet2")}</BulletText>
        </BulletWrapper>
        <BulletWrapper>
          <Bullet />
          <BulletText>{__("change_region_terms_bullet3")}</BulletText>
        </BulletWrapper>
        <BulletWrapper>
          <Bullet />
          <BulletText>{__("change_region_terms_bullet5")}</BulletText>
        </BulletWrapper>
        <BulletWrapper>
          <Bullet />
          <BulletText>
            <Componentify
              text={__("change_region_terms_bullet10")}
              converters={[linkConverter]}
            />
          </BulletText>
        </BulletWrapper>
      </React.Fragment>
    )
  }

  const renderFamilyBullets = () => {
    return (
      <React.Fragment>
        {!hasFreeRelocation ? (
          <BulletWrapper>
            <Bullet />
            <BulletText>
              {__(
                "change_region_terms_bullet4",
                "You'll be charged a single fee of %price% %currency% for the move.",
                {
                  currency: currency,
                  price: currencyPrices[relocationPlanId][currencySign].onetime
                }
              )}
            </BulletText>
          </BulletWrapper>
        ) : null}
        <BulletWrapper>
          <Bullet />
          <BulletText>{__("change_region_terms_bullet6")}</BulletText>
        </BulletWrapper>
        <BulletWrapper>
          <Bullet />
          <BulletText>{__("change_region_terms_bullet1")}</BulletText>
        </BulletWrapper>
        <BulletWrapper>
          <Bullet />
          <BulletText>{__("change_region_terms_bullet7")}</BulletText>
        </BulletWrapper>
        <BulletWrapper>
          <Bullet />
          <BulletText>{__("change_region_terms_bullet8")}</BulletText>
        </BulletWrapper>
        <BulletWrapper>
          <Bullet />
          <BulletText>{__("change_region_terms_bullet5")}</BulletText>
        </BulletWrapper>
        <BulletWrapper>
          <Bullet />
          <BulletText>
            <Componentify
              text={__("change_region_terms_bullet10")}
              converters={[linkConverter]}
            />
          </BulletText>
        </BulletWrapper>
      </React.Fragment>
    )
  }

  const renderBusinessBullets = () => {
    return (
      <React.Fragment>
        {!hasFreeRelocation ? (
          <BulletWrapper>
            <Bullet />
            <BulletText>
              {__(
                "change_region_terms_bullet4",
                "You'll be charged a single fee of %price% %currency% for the move.",
                {
                  currency: currency,
                  price: currencyPrices[relocationPlanId][currencySign].onetime
                }
              )}
            </BulletText>
          </BulletWrapper>
        ) : null}
        <BulletWrapper>
          <Bullet />
          <BulletText>{__("change_region_terms_bullet9")}</BulletText>
        </BulletWrapper>
        <BulletWrapper>
          <Bullet />
          <BulletText>{__("change_region_terms_bullet1")}</BulletText>
        </BulletWrapper>
        <BulletWrapper>
          <Bullet />
          <BulletText>{__("change_region_terms_bullet11")}</BulletText>
        </BulletWrapper>
        <BulletWrapper>
          <Bullet />
          <BulletText>{__("change_region_terms_bullet12")}</BulletText>
        </BulletWrapper>
        <BulletWrapper>
          <Bullet />
          <BulletText>{__("change_region_terms_bullet5")}</BulletText>
        </BulletWrapper>
        <BulletWrapper>
          <Bullet />
          <BulletText>
            <Componentify
              text={__("change_region_terms_bullet10")}
              converters={[linkConverter]}
            />
          </BulletText>
        </BulletWrapper>
      </React.Fragment>
    )
  }

  const renderBullets = () => {
    if (isFamily) {
      return renderFamilyBullets();
    } else if (isBusiness) {
      return renderBusinessBullets();
    } else {
      return renderBasicBullets();
    }
  }

  return (
    <Container key="terms">
      <Header>{__("change_region_title")}</Header>
      <Desc>{descriptionText}</Desc>
      <MessageColor>
        <Title>{__("change_region_terms_title")}</Title>
        {renderBullets()}
      </MessageColor>
      <InputWrapper errorCheckbox={errorCheckbox}>
        <InputStyledCheckbox
          name="terms-data-region"
          size="small"
          text={__("change_region_terms_checkbox")}
          checked={isAcceptedTerms}
          onChange={onTermsChange}
        />
      </InputWrapper>
      <ButtonCentered
        style={{ marginTop: "37px", fontSize: "15px" }}
        onClick={onSubmit}
        minWidth="360px"
        // disabledColor="#dddddd"
        // disabled={!isAcceptedTerms}
      >
        {__("change_region_request_button")}
      </ButtonCentered>
    </Container>
  );
};

export default TermsStep;

const Header = styled.div`
  font-size: 19px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 13px;
`;

const Desc = styled.div`
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: normal;
  text-align: center;
`;

const MessageColor = styled.div`
  font-size: 13px;
  line-height: 1.2;
  box-sizing: border-box;
  border-radius: 4.3px;
  border: solid 1px #fcf2d5;
  background-color: #fff8e0;
  margin: 37px 0 17px 0;
  padding: 20px;
`;

const BulletWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 13px;

  &:last-of-type {
    margin-bottom: 0px;
  }

  a {
    color: #17bed0;
  }
`;

const Bullet = styled.div`
  width: 5px;
  height: 5px;
  border-radius: 100%;
  margin-right: 8px;
  background-color: #000000;
  flex-shrink: 0;
`;

const BulletText = styled.div`
  font-size: 13px;
  font-weight: normal;
  text-align: left;
`;

const Title = styled.div`
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 14px;
  text-align: left;
`;

const InputWrapper = styled.div`
  color: ${props => (props.errorCheckbox ? "#ff2626 !important" : "#000")};
  text-align: left;

  label {
    font-weight: normal;
  }
`;
