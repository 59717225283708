// @flow

import React from "react";
import styled from "styled-components";
import { hiDPI } from "polished";

import * as Style from "./SharedStyledComponents";
import { __ } from "../../lib/translate";
import { detectIsMobile } from "../../lib/utils";
import { detectOS } from "../../../web-shared/utils";

const MobileInfo = () => {
  const isRetinaDisplay = window.devicePixelRatio > 1;
  const isMobileDevice = detectIsMobile();
  const os = detectOS(false, true);

  const imagesByOs = {
    android: {
      header: isRetinaDisplay
        ? require("../../../root/img/backups/infopages/android-header@2x.png")
        : require("../../../root/img/backups/infopages/android-header.png")
    },
    ios: {
      header: isRetinaDisplay
        ? require("../../../root/img/backups/infopages/ios-header@2x.png")
        : require("../../../root/img/backups/infopages/ios-header.png")
    },
    default: {
      header: isRetinaDisplay
        ? require("../../../root/img/backups/infopages/DEV@2x.png")
        : require("../../../root/img/backups/infopages/DEV.png")
    }
  };

  const currentOsImages = imagesByOs[os] || imagesByOs.default;

  return (
    <MobileWrapper>
      <Style.Header>
        <Style.HeaderTitle>{__("mobile_backup_title")}</Style.HeaderTitle>
        <Style.HeaderSubtitle>{__("mobile_backup_subtitle")}</Style.HeaderSubtitle>
      </Style.Header>

      <Style.BoxContainer>
        <Style.BoxItem alignDirection="right" className="image">
          <Style.BoxImage src={currentOsImages.header} width="374" />
        </Style.BoxItem>
        <Style.BoxItem alignDirection="left" className="text">
          <Style.List>
            <MobileListItem>{__("mobile_backup_bullet1")}</MobileListItem>
            <MobileListItem>{__("mobile_backup_bullet2")}</MobileListItem>
            <MobileListItem>{__("mobile_backup_bullet3")}</MobileListItem>
            <MobileListItem>{__("mobile_backup_bullet4")}</MobileListItem>
          </Style.List>
          {isMobileDevice && os === "ios" ? (
            <a className="app" href="https://itunes.apple.com/bg/app/id692002098?mt=8">
              <AppStoreImage />
            </a>
          ) : null}
          {isMobileDevice && os === "android" ? (
            <a className="app" href="https://play.google.com/store/apps/details?id=com.pcloud.pcloud">
              <GooglePlayImage />
            </a>
          ) : null}
        </Style.BoxItem>
      </Style.BoxContainer>

      {isMobileDevice ? null : (
        <Style.Section>
          <Style.SectionTitle>{__("mobile_backup_section1_title")}</Style.SectionTitle>

          <Style.BoxContainer>
            <Style.BoxItem alignDirection="right" className="text">
              <Style.BoxTitle>1. {__("mobile_backup_section1_step1_headline")}</Style.BoxTitle>
              <Style.BoxDescription>
                <div>{__("mobile_backup_section1_step1_description")}</div>
                <AppButtonContainer>
                  <a className="app" href="https://play.google.com/store/apps/details?id=com.pcloud.pcloud">
                    <GooglePlayImage />
                  </a>
                  <a className="app" href="https://itunes.apple.com/bg/app/id692002098?mt=8">
                    <AppStoreImage />
                  </a>
                </AppButtonContainer>
              </Style.BoxDescription>
            </Style.BoxItem>
            <Style.BoxItem alignDirection="left" className="image">
              <Style.BoxImage
                src={
                  isRetinaDisplay
                    ? require("../../../root/img/backups/infopages/autoupload@2x.png")
                    : require("../../../root/img/backups/infopages/autoupload.png")
                }
                width="471"
              />
            </Style.BoxItem>
          </Style.BoxContainer>

          <Style.BoxContainer>
            <Style.BoxItem alignDirection="right" className="image">
              <Style.BoxImage
                src={
                  isRetinaDisplay
                    ? require("../../../root/img/backups/infopages/setautoupload@2x.png")
                    : require("../../../root/img/backups/infopages/setautoupload.png")
                }
                width="471"
              />
            </Style.BoxItem>
            <Style.BoxItem alignDirection="left" className="text">
              <Style.BoxTitle>2. {__("mobile_backup_section1_step2_headline")}</Style.BoxTitle>
              <Style.BoxDescription>
                {__("mobile_backup_section1_step2_description")}
              </Style.BoxDescription>
            </Style.BoxItem>
          </Style.BoxContainer>

          <Style.BoxContainer>
            <Style.BoxItem alignDirection="right" className="text">
              <Style.BoxTitle>3. {__("mobile_backup_section1_step3_headline")}</Style.BoxTitle>
              <Style.BoxDescription>
                {__("mobile_backup_section1_step3_description")}
              </Style.BoxDescription>
            </Style.BoxItem>
            <Style.BoxItem alignDirection="left" className="image">
              <Style.BoxImage
                src={
                  isRetinaDisplay
                    ? require("../../../root/img/backups/infopages/storage@2x.png")
                    : require("../../../root/img/backups/infopages/storage.png")
                }
                width="280"
              />
            </Style.BoxItem>
          </Style.BoxContainer>
        </Style.Section>
      )}
    </MobileWrapper>
  );
};

export default MobileInfo;

const AppButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-top: 26px;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const StoreImage = styled.div`
  width: 160px;
  height: 52px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  cursor: pointer;

  &:first-of-type {
    margin-right: 10px;
  }

  @media (max-width: 768px) {
    margin: 0 auto !important;
  }
`;

const AppStoreImage = styled(StoreImage)`
  background-image: url(${require("../../../root/img/2fa-login/appstore.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/2fa-login/appstore@2x.png")});
  }
`;

const GooglePlayImage = styled(StoreImage)`
  background-image: url(${require("../../../root/img/2fa-login/google-play.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/2fa-login/google-play@2x.png")});
  }
`;

const MobileWrapper = styled(Style.Wrapper)`
  @media (max-width: 768px) {
    background-color: #f6f6f6;
  }
`;

const MobileListItem = styled(Style.ListItem)`
  @media (max-width: 768px) {
    background-color: #fff;
  }
`;
