import React, { useEffect, useRef, useState } from 'react';

import UploadContext from './upload-context';
import useFileUpload from './../hooks/useFileUpload';
import useControls from './../hooks/useControls';
import { UPLOAD_TAB } from '../constants';
import { isFileManagerPage } from '../helpers';

const UploadContextProvider = ({ data, lockCryptoAttempt, children, action, random }) => {
  // pass random number to refresh edge cases
  const [_, setRefresh] = useState(random);

  const refresh = () => {
    setRefresh(Math.random() * 1000);
  }

  const isMobile = HFN.config.isMobile();
  const opts = {
    auth: HFN.config.auth,
    isDlink: HFN.config.isDlink(),
    hasQuota: HFN.hasQuota(),
    isDlinkOwner: HFN.config.isDlinkOwner(),
    emailVerified: HFN.config.user.emailverified,
    encrypted: HFN.config.isDlink() ? false : HFN.inCrypto(),
  };

  useEffect(() => {
    // Function to check if the hashed parameter is set to 'filemanager'
    const checkHash = () => {
      if (isFileManagerPage()) {
        refresh()
      } else {
        refresh()
      }
    };

    // Check the hash when the component mounts
    checkHash();

    // Add event listener for hash changes
    window.addEventListener('hashchange', checkHash);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('hashchange', checkHash);
    };
  }, []);

  const {
    initializeUploads,
    uploadQueue,
    handleRetryAction,
    donePercentage,
    cancelUpload,
    closeUpload,
    failedItems,
    failedFolderError,
    processItemsUpload,
    abortUploadAction,
    uploadQueueExisting,
    handleUploadOptionsModalClose,
    handleUploadOptionsModalYesClick,
    activeTab,
    setActiveTab,
    filteredUploadQueue,
    handleClear,
    handleRetryAll,
    tabItemsLength,
    isUploadRemoteModalOpen,
    handleUploadRemoteModalClose,
    handleUploadRemoteModalYesClick,
    isUploadPasteModalOpen,
    handleUploadPasteModalClose,
    handleUploadPasteModalYesClick,
  } = useFileUpload({ opts });

  const {
    isToggled,
    handleToggleState,

    isOpened,
    handleOpenState,

    isWarningOnUploadModalOpen,
    handleWarningOnUploadModalClose,
    handleWarningOnUploadModalYesClick,

    handleCancel,
    isUploadCancelModalOpen,
    handleUploadCancelModalClose,
    handleUploadCancelModalYesClick,

    openFailedFolderModal,
    failedFolderModalItemId,
    handleFailedFolderModalOpenClose,
    handleFailedFolderModalOpenYesClick,

    isLockCryptoModalOpen,
    handleLockCryptoModalClose,
    handleLockCryptoYesClick,

    isTabDropdownOpened,
    setIsTabDropdownOpened,

    isUploadCloseWithRetryOpen,
    handleUploadCloseWithRetryClose,
    handleUploadCloseWithRetryYesClick,
  } = useControls({
    isUploading: donePercentage.isUploading,
    cancelUpload,
    closeUpload,
    failedItems,
    handleRetryAction,
    lockCryptoAttempt,
    action,
    random,
    setActiveTab,
    handleRetryAll,
  });

  useEffect(() => {
    const isRefresh = action === 'refresh' || action === 'minimize';
    initializeUploads(data);
    if (!isRefresh) {
      setActiveTab(UPLOAD_TAB.ALL);
      if (isToggled) {
        handleToggleState();
      }
    } else {
      refresh();
    }
    if (data && Object.keys(data).length !== 0 && !isOpened && !data.isRemoteUpload && !data.pasteItemUrl) {
      handleOpenState();
    }
  }, [data, random]);

  let objContext = {
    initializeUploads,
    uploadQueue,
    handleRetryAction,
    donePercentage,
    cancelUpload,
    closeUpload,
    failedItems,
    failedFolderError,
    processItemsUpload,
    abortUploadAction,
    uploadQueueExisting,
    handleUploadOptionsModalClose,
    handleUploadOptionsModalYesClick,
    activeTab,
    setActiveTab,
    isToggled,
    handleToggleState,
    isOpened,
    handleOpenState,
    isWarningOnUploadModalOpen,
    handleWarningOnUploadModalClose,
    handleWarningOnUploadModalYesClick,
    handleCancel,
    isUploadCancelModalOpen,
    handleUploadCancelModalClose,
    handleUploadCancelModalYesClick,
    openFailedFolderModal,
    failedFolderModalItemId,
    handleFailedFolderModalOpenClose,
    handleFailedFolderModalOpenYesClick,
    filteredUploadQueue,
    opts,
    isMobile,
    isLockCryptoModalOpen,
    handleLockCryptoModalClose,
    handleLockCryptoYesClick,
    handleClear,
    handleRetryAll,
    isTabDropdownOpened,
    setIsTabDropdownOpened,
    tabItemsLength,
    isUploadCloseWithRetryOpen,
    handleUploadCloseWithRetryClose,
    handleUploadCloseWithRetryYesClick,
    isUploadRemoteModalOpen,
    handleUploadRemoteModalClose,
    handleUploadRemoteModalYesClick,
    isUploadPasteModalOpen,
    handleUploadPasteModalClose,
    handleUploadPasteModalYesClick,
  };

  return (
    <UploadContext.Provider value={objContext}>
      {children}
    </UploadContext.Provider>
  );
};

export default UploadContextProvider;