import React, { useRef } from "react";
import { TimeSlider, SliderValueChangeEvent } from '@vidstack/react';

const ProgressTimeSlider = ({
  vidstackMediaPlayer,
  onDraggingVideoProgress,
  noSwipeGesture = false
}) => {
  const bufferEl = useRef(null);

  const calcBufferedPercent = (bufferedEnd, duration) => {
    return Number(Math.min(bufferedEnd / Math.max(duration, 1), 1) * 100, 3).toFixed(2) + '%';
  }

  const onProgressValueChange = (value: number, nativeEvent: SliderValueChangeEvent) => {
    if (!vidstackMediaPlayer.current) {
      return;
    }
    
    const { duration, buffered, currentTime } = vidstackMediaPlayer.current.state;

    if (duration > 0) {
      for (let i = 0; i < buffered.length; i++) {
          if (buffered.start(buffered.length - 1 - i) <= currentTime) {
              const bufferedEnd = buffered.end(
                  buffered.length - 1 - i,
              );

              if (bufferEl.current) {
                bufferEl.current.style.setProperty("--chapter-progress", calcBufferedPercent(bufferedEnd, duration));
              }
              break;
          }
      }
    }
  }

  return (
    <div className="plyr__controls__item plyr__progress__container">
      <div className="plyr__progress">
        <TimeSlider.Root
          className="plyr__slider"
          // Important to be false! 
          // Otherwise there is a bug when dragging to the end and it starts from the beginning.
          pauseWhileDragging={false}
          //keyStep={seekTime}
          //aria-label={seekText}
          data-plyr="seek"
          onValueChange={onProgressValueChange}
          onDragStart={() => onDraggingVideoProgress(true)}
          onDragEnd={() => onDraggingVideoProgress(false)}
          noSwipeGesture={noSwipeGesture}
        >
          <div className="plyr__slider__track"></div>
          <div className="plyr__slider__thumb"></div>
          <div className="plyr__slider__buffer" ref={bufferEl}></div>
          <span className="plyr__tooltip">
            <TimeSlider.Value />
          </span>
        </TimeSlider.Root>
      </div>
    </div>
  );
}

export default ProgressTimeSlider;