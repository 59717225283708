// @flow

import React from "react";
import styled from "styled-components";
import { hiDPI } from "polished";
import Modal, { OvalModalClose } from "../../components/Modal";
import { __ } from "../../lib/translate";
import OpenAppButton from "./OpenAppButton";

import { PLAY_STORE_APP_URL, APP_STORE_APP_URL } from "@pcloud/web-utilities/dist/config/constants";

type State = { isOpen: boolean };
type Props = { isAndroid: boolean, onClose: () => void };

class UseAppModal extends React.Component<Props, State> {
  constructor() {
    super();

    this.state = {
      isOpen: false
    };

    (this: any).open = this.open.bind(this);
    (this: any).close = this.close.bind(this);
    (this: any).openAppStore = this.openAppStore.bind(this);
  }

  open() {
    this.setState({ isOpen: true });
  }

  close() {
    this.setState({ isOpen: false });
    this.props.onClose();
  }

  openAppStore() {
    const { isAndroid } = this.props;

    if (isAndroid) {
      window.location = PLAY_STORE_APP_URL;
    } else {
      window.location = APP_STORE_APP_URL;
    }
  }

  componentDidMount() {
    this.open();
  }

  render() {
    return (
      <Modal onClose={this.close} opened={this.state.isOpen}>
        <OvalModalWrap isAndroid={this.props.isAndroid}>
          <OvalModalClose onClick={this.close} />
          <Container>
            <DownloadAppIcon isAndroid={this.props.isAndroid} onClick={this.openAppStore} />
            <Message>{__("modal_overlay_text")}</Message>
            <Information>
              <li>{__("modal_overlay_bullet1")}</li>
              <li>{__("modal_overlay_bullet2")}</li>
              <li>{__("modal_overlay_bullet3")}</li>
            </Information>
            {/*  DISABLE AUTO FOCUS ON LOGIN INPUTS FOR IOS SAFARI */}
            <input style={{ display: "none" }} type="text" autoFocus="true" />
          </Container>
        </OvalModalWrap>
      </Modal>
    );
  }
}

export default UseAppModal;

const DownloadAppIcon = styled.div`
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
  width: 209px;
  height: 57px;
  margin: 0 auto;
  margin-top: 120px;

  @media only screen and (max-width: 375px) {
    width: 170px;
    margin-top: 95px;
  }

  ${props =>
    props.isAndroid
      ? `
      background-image: url(${require("../../../root/img/mobile/sa-ads/andro-button.png")});
      ${hiDPI(1.5)} {
        background-image: url(${require("../../../root/img/mobile/sa-ads/andro-button@2x.png")});
      }
    `
      : `
      background-image: url(${require("../../../root/img/mobile/sa-ads/ios-button.png")});
      ${hiDPI(1.5)} {
        background-image: url(${require("../../../root/img/mobile/sa-ads/ios-button@2x.png")});
      }
    `}
`;

const OvalModalWrap = styled.div`
  display: inline-block;
  margin: 0px 16px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.25);
  background: #fff;
  position: relative;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: auto 88%;
  max-width: 414px;

  @media only screen and (max-width: 375px) {
    margin: 0px auto;
  }

  ${props =>
    props.isAndroid
      ? `
      background-image: url(${require("../../../root/img/mobile/warn-bg-android.png")});
      ${hiDPI(1.5)} {
        background-image: url(${require("../../../root/img/mobile/warn-bg-android@2x.png")});
      }
    `
      : `
      background-image: url(${require("../../../root/img/mobile/warn-bg-ios.png")});
      ${hiDPI(1.5)} {
        background-image: url(${require("../../../root/img/mobile/warn-bg-ios@2x.png")});
      }
    `}
`;

const Container = styled.div`
  text-align: center;
  border-radius: 15px;
  padding: 10px;
  font-family: "Roboto", sans-serif;
  color: #333;
`;

const Header = styled.div`
  font-size: 17px;
  font-weight: bold;
  margin: 0px 30px 8px 30px;
`;

const Message = styled.div`
  width: 82%;
  font-family: "Roboto", sans-serif;
  font-size: 28px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: -0.5px;
  text-align: center;
  color: #000000;
  margin: 40px auto;
  min-width: 220px;

  @media only screen and (max-width: 375px) {
    width: 66%;
    font-size: 26px;
    margin: 30px auto;
  }

  @media only screen and (min-width: 400px) {
    width: 66%;
  }
`;

const Information = styled.ul`
  list-style: none;
  padding-left: 0px;
  min-width: 220px;
  width: 66%;
  margin: 0 auto;
  margin-bottom: 15px;
  li {
    margin: 5px auto;
  }
`;
