import React from 'react';

const PlayerContext = React.createContext({
  data: {},
  isPublic: false,
  isMobile: false,
  headerRef: {},
  boxPlayerRef: {},

  audioRef: {},
  currentSong: {},
  queue: [],
  shuffleQueue: [],
  isReady: false,
  isPlaying: false,
  repeatState: false,
  volume: 0.5,
  duration: 0,
  playlist: {},
  playlistItems: [],
  isShuffle: false,
  podcastMode: false,
  analyzerData: {},
  loadedThumbs: {},
  additionalClass: '',
  playerVariant: {},
  controlsWithOverlay: {},
  playRate: 1,
  sleepTimer: 0,

  setAnalyzerData: (data) => {},
  setQueue: (data) => {},
  setDuration: (data) => {},
  setIsReady: (data) => {},
  setIsPlaying: (data) => {},
  setVolume: (data) => {},
  setPlaylist: (data) => {},
  setPlayRate: (data) => {},
  setSleepTimer: (data) => {},

  onPrev: (e) => {},
  onNext: (e, isAuto) => {},
  onPrevProgress: (e) => {},
  onNextProgress: (e) => {},
  onVolumeChange: (data) => {},
  onVolumeChangeEnd: (data) => {},
  onMuteUnmute: () => {},
  onSelectSong: (data) => {},
  onToggleRepeat: () => {},
  onToggleShuffle: () => {},
  onTogglePlayPause: () => {},
  onRemoveFromQueue: (e, id) => {},
  onCreatePlaylist: (name, callback) => {},
  onLoadPlaylist: (id, callback) => {},
  onSharePlaylist: () => {},
  onLoadPlaylistItems: (forceFresh) => {},
  onTogglePodcastMode: () => {},
  loadPrimeThumb: (fileId, size) => {},
  onPlayerClose: () => {},
  onPlayerMinimize: () => {},
  onPlayerMaximize: () => {},
  onShowMiniPlayer: () => {},
  onShowFullPagePlayer: () => {},
  onShowPlayer: () => {},
  onOpenSettingsToggle: () => {},
  onVolumePanelToggle: () => {},
  onCloseAllOverlays: () => {},
  onOpenPlaylistCreateForm: () => {},
  onOpenPlaylistLoadForm: () => {},
  onOpenPlayRateToggle: () => {},
  onOpenSleepTimerToggle: () => {}
});

export default PlayerContext;