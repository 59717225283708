import styled, { keyframes } from "styled-components";

export const Header = styled.div`
  text-align: center;
`;

export const Title = styled.div`
  margin: 0 auto;
  margin-bottom: 5px;
  font-family: "Roboto", sans-serif;
  font-size: 17px;
  font-weight: bold;
  line-height: 1.18;
  letter-spacing: -0.3px;
  text-align: center;
  color: #000000;
`;

export const Description = styled.div`
  max-width: 560px;
  margin: 0 auto;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  line-height: 1.2;
  letter-spacing: -0.2px;
  text-align: center;
  color: #000000;
`;

export const Warning = styled.div `
  width: 100%;
  max-width: 780px;
  margin: 25px auto 0px auto;
  padding: 20px 50px;
  border-radius: 6px;
  background-color: #fff8df;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  line-height: 1.23;
  letter-spacing: -0.2px;
  text-align: center;
  color: #000;
  box-sizing: border-box;
  a {
    font-weight: bold;
    color: #17bed0;
  }
`

export const spin = keyframes`
    0%{
      transform: rotate(0deg)
    }
    
    100% {
      transform: rotate(360deg);
    }
`;

export const LoadingWrapper = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  display: inline-block;
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255,0.75);
  z-index: 1;

  div {
    display: inline-block;
    height: 48px;
    width: 48px;
    background-image: url(${require("../../../root/img/loading_blue.png")});
    background-position: center;
    background-size: 48px 48px;
    position: relative;
    top: 50%;
    margin-top: -24px;
    left: 50%;
    margin-left: -24px;
    animation-name: ${spin};
    animation-duration: 500ms;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
`

export const Message = styled.div`
  width: 100%;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  line-height: 1.2;
  text-align: center;
  color: #000;
  padding: 40px 0px;
  .path {
    display: block;
    font-weight: bold;
  }
`;