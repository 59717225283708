import $ from 'jquery';

const eventHandlers = {
  reset: function (event) {
    HFN.cache.cleanAll();

    var root = {
      isfolder: true,
      ismine: true,
      id: 'd0',
      folderid: 0,
      path: '/',
      name: '/',
      thumb: false,
      icon: 'folder',
      isshared: false,
      created: event.time,
      modified: event.time,
      contents: []
    };

    HFN.cache.set('listfolder-list-0-default', root, 604800);
    $.bbq.pushState({page:'filemanager'}, 2);

    return; // normal one after this
    HFN.cache.cleanAll();
    $.bbq.pushState({page:'filemanager'}, 2);
  },

  // filesystem
  createfolder: function (event) {

    if (!event.metadata.contents) {
      event.metadata.contents = [];
    }

    if (HFN.config.isDlink()) {
      HFN.cacheTree(event.metadata, 'public', HFN.data.fflookup);
    }
    console.log('live grid', HFN.metaInLiveGrid(event.metadata));
    console.log('filter?', HFN._filterTree([event.metadata]).length);

    if (HFN._filterTree([event.metadata]).length) {
      if (HFN.metaInLiveGrid(event.metadata)) {
        HFN.gridAppend(event.metadata, HFN.config.isDlink());
      }

      HFN.appendMetadata(event.metadata);
    }

    //c.expire(c.cacheid('listfolder', 'list', event.metadata.parentfolderid, 'default'));
    HFN.cache.expire('folders-lookup');
    //HFN.refreshGrid(event.metadata.parentfolderid);
  },
  deletefolder: function (event) {
    HFN
      .gridRemove(event.metadata)
      .expireMetadata([event.metadata.id]);
      //.refreshGrid(event.metadata.parentfolderid);

    if ($.bbq.getState('folder') == event.metadata.folderid)
      $.bbq.pushState({ folder: event.metadata.parentfolderid });

    if (HFN.metaInLiveGrid(event.metadata) && $.bbq.getState('comments') == event.metadata.id) {
      $(daGrid.parentSelector() + ' tr.comments.meta'+event.metadata.id).remove();
      $.bbq.removeState('comments');
    }

    HFN.cache.expireMatch('listpublinks');
  },
  deletefile: function (event) {

    // grid remove cleans the metadata and the quota update must be done here.
    if ( event.metadata.size && event.metadata.ismine )
      HFN.updateQuota( -1 * event.metadata.size );

    if (HFN.metaInLiveGrid(event.metadata)) {

      if ($.bbq.getState('comments') == event.metadata.id) {
        $(daGrid.parentSelector() + ' tr.comments.meta'+event.metadata.id).remove();
        $.bbq.removeState('comments');
      }

      HFN.gridRemove(event.metadata);
    }

    HFN.expireMetadata([event.metadata.id]);
    HFN.cache.expireMatch('listpublinks');
  },
  createfile: function (event) {
    var metadata = event.metadata;

    if (HFN._filterTree([metadata]).length) {
      if (HFN.metaInLiveGrid(metadata)) {
        console.log('grid append');
        HFN.gridAppend(metadata);
      }

      console.log('append meta');
      HFN.appendMetadata(metadata);
    }

    if (metadata.ismine && metadata.size && !metadata.isfolder)
      HFN.updateQuota(metadata.size);
  },
  modifyfile: function (event) {
    var orig = HFN.findOriginalMetadata(event.metadata), expireList = [];

    console.log('original meta', orig);
    if (orig) {

      // gridRemove removes the data so the quota update can't be done at later point.
      if (orig.size && orig.ismine) {
        HFN.updateQuota(-1 * orig.size);
        HFN.updateQuota(event.metadata.size);
      }

      if (HFN.metaInLiveGrid(orig))
        HFN.gridRemove(orig, true);
      else
        expireList.push(orig.id);

      if (daGrid && orig.parentfolderid != event.metadata.parentfolderid) {
        $(daGrid.parentSelector() + ' tr.comments.metaf'+event.metadata.deletedfileid).remove();
        $.bbq.removeState('comments');
      }
    }

    if (event.metadata.deletedfileid) {

      console.log('f' + event.metadata.deletedfileid, HFN.data.fflookup['f' + event.metadata.deletedfileid]);
      if (HFN.data.fflookup['f' + event.metadata.deletedfileid]) {
        if (HFN.metaInLiveGrid(HFN.data.fflookup['f' + event.metadata.deletedfileid]))
          HFN.gridRemove(HFN.data.fflookup['f' + event.metadata.deletedfileid]);
        else
          expireList.push('f' + event.metadata.deletedfileid);
      }

      if (daGrid) {
        $(daGrid.parentSelector() + ' tr.comments.metaf'+event.metadata.deletedfileid).remove();
        $.bbq.removeState('comments');
      }
    }

    console.log('expire metadata', expireList);

    HFN.expireMetadata(expireList);

    if (HFN._filterTree([event.metadata]).length) {
      if (HFN.metaInLiveGrid(event.metadata)) {
        HFN.gridAppend(event.metadata, true);
      }
      HFN.appendMetadata(event.metadata);
    }

    HFN.cache.expireMatch('api-listrevisions-fileid:' + event.metadata.fileid);
  },
  modifyfolder: function (event) {
    var orig = HFN.findOriginalMetadata(event.metadata);

    console.log('orig', orig);

    if (orig) {
      if (HFN.metaInLiveGrid(orig))
        HFN.gridRemove(orig, true);
      else
        HFN.expireMetadata([orig.id]);

      if (orig.parentfolderid != event.metadata.parentfolderid && daGrid) {
        $(daGrid.parentSelector() + ' tr.comments.meta'+orig.id).remove();
        $.bbq.removeState('comments');
      }
    }

    if (HFN._filterTree([event.metadata], { encrypted: event.metadata.encrypted }).length) {
      if (HFN.metaInLiveGrid(event.metadata))
        HFN.gridAppend(event.metadata, true);
      else
        HFN.appendMetadata(event.metadata);
    }

    HFN.cache.expireMatch('folders-lookup');
    if (orig) {
      HFN.refreshGrid(orig.parentfolderid);
      HFN.refreshGrid(orig.folderid);
    }

    if (event.metadata.folderid) {
      // If folder has been renamed we should cleanup the breadcrumb cache to refetch the new name there.
      HFN.cache.expireMatch('api-getbreadcrumb-folderid:' + event.metadata.folderid);
    }

    if (daGrid && daGrid.opts.metadata && daGrid.opts.metadata.folderid && daGrid.opts.metadata.folderid == event.metadata.folderid) {
      daGrid.opts.metadata = event.metadata;
      daGrid.customHeader();
    }

    console.log(orig, event.metadata);

    if ((orig && orig.isshared) || event.metadata.isshared)
      HFN.cache.expireMatch('listshares');
    if ((orig && orig.isbusiness_shared) || event.metadata.isbusiness_shared)
      HFN.cache.expireMatch('account_listshares');

    HFN.cache.expireMatch('listpublinks');
    HFN.cache.expireMatch('listuploadlinks');
  },

  // shares @todo: append / cleanup from cache specific share items instead of whole lists
  requestsharein: function (event) {
    HFN.cache.expireMatch(HFN.cache.cacheid('api', 'listshares'));
    HFN.refreshSharesAll();
    HFN.checkNewShares();
  },
  acceptedsharein: function (event) {

    // share
    HFN.cache.expireMatch(HFN.cache.cacheid('api', 'listshares'));

    HFN.refreshSharesAll();
    HFN.checkNewShares();
  },
  acceptedshareout: function (event) {
    HFN.cache.expireMatch(HFN.cache.cacheid('api', 'listshares'));

    HFN.refreshSharesAll();
    HFN.checkNewShares();

    var meta = HFN.findOriginalMetadata({id: 'd'+event.share.folderid});
    if (meta) {
      HFN.setShareFlags(meta, { isshared: true });
    }
  },
  declinedsharein: function (event) {
    HFN.cache.expireMatch(HFN.cache.cacheid('api', 'listshares'));
    HFN.refreshSharesAll();
  },
  declinedshareout: function () {
    HFN.cache.expireMatch(HFN.cache.cacheid('api', 'listshares'));
    HFN.refreshSharesAll();
  },
  cancelledsharein: function (event) {
    HFN.cache.expireMatch(HFN.cache.cacheid('api', 'listshares'));
    HFN.refreshSharesAll();
  },
  removedsharein: function (event) {
    HFN.cache.expireMatch(HFN.cache.cacheid('api', 'listshares'));
    HFN.refreshSharesAll();
  },
  modifiedsharein: function (event) {
    HFN.cache.expireMatch(HFN.cache.cacheid('api', 'listshares'));
    HFN.refreshSharesAll();
  },
  removedshareout: function (event) {
    HFN.cache.expireMatch(HFN.cache.cacheid('api', 'listshares'));
    //triggerOutgoingShares('.outshares', true);

    var meta = HFN.findOriginalMetadata({id: 'd'+event.share.folderid});
    if (event.share.islast && meta)
      HFN.removeShareFlags(meta);
    HFN.refreshSharesAll();
  },
  requestshareout: function (event) {
    HFN.cache.expireMatch(HFN.cache.cacheid('api', 'listshares'));

    var meta = HFN.findOriginalMetadata({ id: 'd' + event.share.folderid });
    if (meta && 0)
      HFN.setShareFlags(meta, {}, true);

    //triggerOutgoingRequests('.outrequests', true);
    HFN.refreshSharesAll();
  },
  cancelledshareout: function (event) {
    HFN.cache.expireMatch(HFN.cache.cacheid('api', 'listshares'));
    //triggerOutgoingRequests('.outrequests', true);
    HFN.refreshSharesAll();
  },
  modifyuserinfo: function (event) {
    var olduser = HFN.config.user;

    HFN.config.user = $.extend({}, HFN.config.user, event.userinfo);
    HFN.psettings.init();
    HFN.spaceinfo.update();
    pCloudIntl.receivedLang(HFN.config.user.language);

    //HFN.state.dispatch(HFN.state.actions.user.modify(event.userinfo));

    console.log('olduser', olduser);
    console.log(event.userinfo);

    if( HFN.pages.current == 'space' ){ spacelist.refresh(); }
    if( HFN.pages.current == 'bonussteps' ){ steps.load(); }

    if (olduser.cryptosetup != event.userinfo.cryptosetup) {
      HFN.initAnalytics();
      if (!event.userinfo.cryptosetup) {
        if (!pCrypt.locked) {
          pCloudCrypto.lock();
        }
        $.bbq.pushState({ page: 'crypto' }, 2);
      }
    }

    var user = $.extend({}, HFN.config.user);
    var isPremium = user.premium || user.premiumlifetime || user.cryptosubscription || user.cryptolifetime || user.business || user.vivapcloud;

    if (user.emailverified || isPremium) {
      HFN.unverifiedEmailAlert.remove();
    }
  },

  // Share out
  establishbshareout: function (event) {

    HFN.refreshBusinessSharesAll();

    var meta = HFN.findOriginalMetadata({id: 'd'+event.share.folderid});
    console.log('!found meta', meta);
    if (meta)
      HFN.setShareFlags(meta, {}, true);
  },
  modifybshareout: function (event) {
    HFN.refreshBusinessSharesAll();
    //HFN.refreshSharesAll();
  },
  removebshareout: function (event) {

    HFN.refreshBusinessSharesAll();

    var meta = HFN.findOriginalMetadata({id: 'd'+event.share.folderid});
    console.log('!found meta', meta);
    if (event.share.islast && meta) {
      HFN.removeShareFlags(meta, true);
    }

    //HFN.refreshSharesAll();
  },

  // Share in
  establishbsharein: function (event) {
    HFN.refreshBusinessSharesAll();
    //HFN.refreshSharesAll();

    var meta = HFN.findOriginalMetadata({id: 'd' + event.share.folderid});
    if (meta) {
      HFN.setMountFlags(meta);
    }
  },

  modifybsharein: function (event) {
    HFN.refreshBusinessSharesAll();

    if (event.share) {
      var share = event.share;
      var meta = HFN.findOriginalMetadata({ id: 'd' + share.folderid});

      if (meta) {
        HFN.setShareFlags(meta, $.extend(share.permissions, {
          ismount: true,
          isbusiness_shared: true
        }));
      }
    }
  },
  removebsharein: function (event) {
    HFN.refreshBusinessSharesAll();
    //HFN.refreshSharesAll();

    var meta = HFN.findOriginalMetadata({id: 'd' + event.share.folderid});
    if (meta && event.share.islastmount) {
      HFN.removeMountFlags(meta);
    }
  }
}

window.eventHandlers = eventHandlers;

export default eventHandlers;
