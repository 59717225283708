// @flow

import React, { useState, useEffect } from "react";
import styled from "styled-components";

import Componentify from "react-componentify";
import Modal, { CloseModalIcon } from "../Modal";
import { Loader } from "../../containers/TwoFactorAuth/SharedComponents";
import { ButtonCentered } from "../ButtonDefault";
import * as Style from "./styledComponents";

import { __ } from "../../lib/translate";
import apiMethod from "../../api/apiMethod";
import { styledSpanTagConverter } from "../../lib/componentifyConverters";

const customConverter = Object.assign({}, styledSpanTagConverter, {
  props: {
    style: {
      color: "#17bed0",
      paddingLeft: "5px",
      maxWidth: "300px",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap"
    }
  }
});

type Props = {
  metadata: object,
  candownload: boolean,
  method: string,
  params: object,
  offsetX: string,
  offsetY: string
};

const PreviewFileCodeModal = ({
  metadata,
  candownload,
  method,
  params,
  offsetX,
  offsetY
}: Props) => {
  const documentName = metadata.name;
  const [isOpen, setIsOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let prevplcCss = {};
    if (!candownload) {
      prevplcCss.userSelect = "none";
    }
    apiMethod(
      method,
      params,
      ret => {
        $(".prevplc")
          .css(prevplcCss)
          .html('<pre class="prettyprint linenums"></pre>');
        $(".prettyprint")
          .text(ret)
          .html();

        prettyPrint(() => {
          setIsLoading(false);
        });
      },
      { dataType: "text" }
    );
  }, [method, params]); //[method, params]

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Modal shouldCloseOnOverlayClick={false} animate onClose={handleClose} opened={isOpen}>
      <ModalContainer offsetX={offsetX} offsetY={offsetY}>
        <CloseModalIcon onClick={handleClose} />
        <ContainerW offsetX={offsetX} offsetY={offsetY}>
          <Style.Header>
            <Componentify
              text={`${__("Preview Document", "Preview Document")}<span>"${documentName}"</span>`}
              converters={[customConverter]}
            />
          </Style.Header>
          {isLoading ? (
            <LoaderWrapper offsetY={offsetY} offsetX={offsetX}>
              {/* <Loader /> */}
            </LoaderWrapper>
          ) : null}
          <TextFileWrapper offsetY={offsetY} offsetX={offsetX} className="prevplc" />
          <ButtonCentered
            inline
            color="lightgray4"
            style={{
              marginRight: "5px",
              maxWidth: "300px"
            }}
            onClick={handleClose}
          >
            {__("Close", "Close")}
          </ButtonCentered>
        </ContainerW>
      </ModalContainer>
    </Modal>
  );
};

export default PreviewFileCodeModal;

const TextFileWrapper = styled.div`
  margin: 30px 0;
  text-align: left;
  font-weight: 400;
  font-size: 13px;

  .prettyprint {
    width: ${props => props.offsetX - 60}px;
    height: ${props => props.offsetY - 222}px;
    overflow: auto;
  }
`;

const LoaderWrapper = styled.div`
  width: ${props => props.offsetX - 60}px;
  height: ${props => props.offsetY - 222}px;
  background: #fefefe url('/ZCDNURL/img/processing.gif') no-repeat center center !important;
`;

const ContainerW = styled(Style.Container)`
  width: ${props => props.offsetX}px;
`;

const ModalContainer = styled.div`
  position: relative;
  display: inline-block;
  width: ${props => props.offsetX}px;
  max-height: ${props => props.offsetY}px;
  background: #fff;
  border-radius: 4px;
  display: inline-block;
  overflow: hidden;
`;
