import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { hiDPI } from "polished";
import { siteUrl } from "@pcloud/web-utilities/dist/config";

import Dropdown, { DIR_UP, DIR_DOWN } from "../components/Dropdown";
import { __ } from "../lib/translate";
import { URLSITE } from "@pcloud/web-utilities/dist/config";

const curYear = new Date().getFullYear();

const FooterSmall = () => (
  <Wrap>
    © {curYear} pCloud International AG
    <DropdownWrap>
      <Dropdown
        list={footerList()}
        verticalDirection={DIR_UP}
        position="fixed"
        onSelect={item => (location.href = item.href)}
      >
        <DropdownImage />
      </Dropdown>
    </DropdownWrap>
  </Wrap>
);

export default FooterSmall;

const Wrap = styled.div`
  width: 220px;
  box-sizing: border-box;
  padding-left: 2px;
  padding-right: 8px;
  border-top: 1px solid #f4f4f4;
  color: #b7b7bd;
  height: 35px;
  font-size: 12px;
  line-height: 36px;
`;

const DropdownWrap = styled.div`
  float: right;
  margin-right: -8px;
`;

const DropdownImage = styled.div`
  cursor: pointer;
  width: 40px;
  height: 35px;
  background-size: 18px 4px;
  background-repeat: no-repeat;
  background-position: center center;

  background-image: url(${require("../../root/img/header/more.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../root/img/header/more@2x.png")});
  }

  &:hover,
  &.opn {
    background-image: url(${require("../../root/img/header/more-active.png")});
    ${hiDPI(1.5)} {
      background-image: url(${require("../../root/img/header/more-active@2x.png")});
    }
  }
`;

const footerList = () => [
  {
    title: "pCloud",
    href: false,
    submenu: [
      {
        title: __("Download"),
        href: siteUrl("/download-free-online-cloud-file-storage.html")
      },
      { title: __("HOME_PREMIUM_NAV"), href: siteUrl("/cloud-storage-pricing-plans.html") },
      { title: __("Encryption"), href: siteUrl("/crypto.html") },
      { title: __("Business"), href: siteUrl("/business.html") },
      { title: __("footer_family"), href: siteUrl("/family.html") },
      { title: __("Transfer"), href: "https://transfer.pcloud.com/" }
    ]
  },
  {
    title: __("features"),
    href: false,
    submenu: [
      {
        title: __("PAGE_FILE_MANAGEMENT"),
        href: siteUrl("/features/file-management.html")
      },
      { title: __("Sharing"), href: siteUrl("/features/file-sharing.html") },
      { title: __("PAGE_SECURITY"), href: siteUrl("/features/security.html") },
      {
        title: __("PAGE_FILE_VERSIONING"),
        href: siteUrl("/features/file-versioning.html")
      },
      { title: __("footer_backup", "Backup"), href: siteUrl("/backup.html") },
      { title: __('digital_asset_management'), href: siteUrl("/digital-asset-management.html") }
    ]
  },
  {
    title: __("PAGE_COMPANY"),
    href: false,
    submenu: [
      { title: __("about_us"), href: siteUrl("/company/about.html") },
      { title: __("Certification"), href: siteUrl("/company/certification.html") },
      { title: __("footer_gdpr"), href: URLSITE + "gdpr/", target: "_blank" },
      { title: __("footer_press"), href: siteUrl("/company/press.html") },
      { title: __("footer_testimonials"), href: siteUrl("/testimonials.html") },
      { title: __("footer_blog"), href: "https://blog.pcloud.com/", target: "_blank" },
      { title: __("careers"), href: "https://blog.pcloud.com/jobs/", target: "_blank" }
    ]
  },
  {
    title: __("Help Center"),
    href: false,
    submenu: [
      { title: __("f.a.q."), href: siteUrl("/help.html") },
      { title: __("privacy_options"), href: siteUrl("/help/privacy.html") },
      { title: __("PAGE_CONTACTUS"), href: siteUrl("/company/contactus.html") },
      { title: __("release notes"), href: siteUrl("/release-notes/windows.html") }
    ]
  },
  {
    title: __("Community"),
    href: false,
    submenu: [
      { title: __("Affiliate Program"), href: siteUrl("/affiliate.html") },
      { title: __("pCloud Resellers"), href: siteUrl("/reseller.html") },
      { title: __("For Developers"), href: "https://docs.pcloud.com/" }
    ]
  },
  {
    title: __("Legal"),
    href: false,
    submenu: [
      {
        title: __("terms and conditions"),
        href: siteUrl("/terms_and_conditions.html")
      },
      { title: __("Privacy Policy"), href: siteUrl("/privacy_policy.html") },
      {
        title: __("Intellectual Property Policy"),
        href: siteUrl("/int_pr_policy.html")
      }
    ]
  }
];
