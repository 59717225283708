import React from "react";
import {ContentHeader, ContentText} from "../styles";
import { __ } from "../../../../lib/translate";
import styled from "styled-components";

const PlayerPodcastContent = () => {
    return (
        <Wrapper>
            <ContentHeader>{__('Mode switch')}</ContentHeader>
            <ContentText>{__('You can easily switch between Music and Podcast mode.')}</ContentText>
        </Wrapper>
    );
}

export default PlayerPodcastContent;

const Wrapper = styled.div`
    width: 280px;
`;