import React from "react";
import styled from "styled-components";
import Button from "../../components/ButtonDefault";
import { TSpan } from "../../components/intl";
import { URLSITE } from "@pcloud/web-utilities/dist/config";

const UpgradeWrap = styled.div`
  display: inline-block;
  vertical-align: top;

  & > button {
    min-width: 180px;
  }
`;

const Money = styled(TSpan)`
  line-height: 18px;
  font-size: 12px;
`;

const UpgradeButton = () => {
  const userCurrency = HFN.config.user.currency;
  return (
    <UpgradeWrap>
      <Button color="green" onClick={() => window.open(URLSITE + "cloud-storage-pricing-plans.html")}>
        <TSpan id="upgrade">Upgrade</TSpan>
      </Button>
      <Money id="public_folder_premium_from" replace={{currency: userCurrency}}>(from 3.99 %currency%/mo)</Money>
    </UpgradeWrap>
  );
}

export default UpgradeButton;
