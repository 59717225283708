import PropTypes from 'prop-types';
import React, { Component } from "react";
import { validateEmail } from "../lib/utils";
import userinfo from "../api/userinfo";
import apiMethod from "../api/apiMethod";
import { TDiv, TSpan, TString } from "../components/intl";
import { __ } from "../lib/translate";
import ResponsiveWrap from "../components/ResponsiveWrap";
import SubmitButton from "../components/SubmitButton";
import InputReadOnly from "../components/InputReadOnly";
import InputPassword from "../components/InputPassword";
import InputText from "../components/InputText";

import styles from "../styles/forms.less";

export default class ChangeEmailConfirmation extends Component {
  static propTypes = {
    code: PropTypes.string.isRequired,
    onChangeMail: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = {
      newmail: "",
      oldmail: "",
      userid: 0,
      password: "",
      error: ""
    };

    this._changeEmail = this._changeEmail.bind(this);
  }

  _loadData() {
    this.xhr = apiMethod(
      "changemailinfo",
      { code: this.props.code },
      ret =>
        this.setState({
          newmail: ret.newmail,
          oldmail: ret.oldmail,
          userid: ret.userid
        }),
      {
        errorCallback: ret => {
          if (ret.result === 2012) {
            ret.error = __("Wrong code");
          } else if (ret.result === 2074) {
            ret.error = __(
              "change_mail_code_expires",
              "This link has expired."
            );
          }

          this.setState({ error: ret.error });
        }
      }
    );
  }

  componentDidMount() {
    this._loadData();
  }

  componentWillUnmount() {
    if (this.xhr) {
      this.xhr.abort();
    }
  }

  _input(name, value) {
    this.setState({ [name]: value });
  }

  _changeEmail(e) {
    e.preventDefault();

    const { code } = this.props;
    const { newmail, password } = this.state;

    if (password.length < 5) {
      this.setState({ error: __("provide_password", "Provide password.") });
      return;
    }

    this.xhr = apiMethod(
      "changemail",
      { code: code, password: password },
      ret => this.setState({ done: true }),
      {
        errorCallback: ret => {
          if (ret.result === 2272) {
            ret.error = __("Incorrect password");
          }

          this.setState({ error: ret.error, password: "", loading: false })
        }
      }
    );

    this.setState({ loading: true });
  }

  _form() {
    const { newmail, oldmail, error, loading, password } = this.state;

    let errorEl = <div className="error">&nbsp;</div>;
    if (error) {
      errorEl = <div className="error">{error}</div>;
    }

    return (
      <ResponsiveWrap>
        <form method="post" onSubmit={this._changeEmail}>
          {errorEl}

          <label className="vertical">
            <TSpan id="current_email">Current email</TSpan>
            <InputReadOnly value={oldmail} />
          </label>
          <br />
          <label className="vertical">
            <TSpan id="new_email">New email</TSpan>
            <InputReadOnly value={newmail} />
          </label>

          <br />

          <label className="vertical">
            <TSpan id="enter_password_confirm">Enter password to confirm</TSpan>

            <InputPassword
              name="password"
              placeholder={__("your_password", "Your password")}
              value={password}
              onChange={e => this._input("password", e.target.value)}
            />
          </label>
          <br /><br />

          <SubmitButton minWidth={"200px"} loading={loading} active={!loading}>
            <TSpan id="change_email">Change Email</TSpan>
          </SubmitButton>
        </form>
      </ResponsiveWrap>
    );
  }

  _initializing() {
    return <div className="loading-wrap" />;
  }

  _done() {
    const { newmail, password } = this.state;
    const props = this.props;

    return (
      <ResponsiveWrap
        style={{
          textAlign: "center",
          lineHeight: "18px",
          fontSize: "14px",
          margin: "20px auto 0 auto"
        }}
      >
        <TSpan id="email_changed_successfully">Your email was changed successfully!</TSpan>
        <br /><br />

        {props.onChangeMail
          ? <SubmitButton minWidth={"200px"} onClick={() => props.onChangeMail(newmail, password)}>
              <TSpan id="Continue">Continue</TSpan>
            </SubmitButton>
          : null}
      </ResponsiveWrap>
    );
  }

  _alreadyused() {
    return (
      <div
        style={{
          textAlign: "center",
          lineHeight: "18px",
          fontSize: "14px",
          margin: "20px 0 0 0"
        }}
      >
        <TSpan id="current_email_already_changed">Your current email has already been changed.</TSpan>
      </div>
    );
  }

  _error(error) {
    return (
      <div className="error" style={{ margin: "30px 0 0 0" }}>{error}</div>
    );
  }

  render() {
    const { oldmail, newmail, done, error } = this.state;

    let middle;
    if (!newmail) {
      if (error) {
        middle = this._error(error);
      } else {
        middle = this._initializing();
      }
    } else if (newmail === oldmail) {
      middle = this._alreadyused();
    } else if (done) {
      middle = this._done();
    } else {
      middle = this._form();
    }

    return (
      <div className="form">
        <TDiv id="confirm_new_email" className="title">{"Confirm new email"}</TDiv>

        {middle}
        <br /><br />
      </div>
    );
  }
}
