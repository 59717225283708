// @flow

import React, { useState, useEffect, useCallback } from "react";
import Componentify from "react-componentify";

import Modal, { OvalModalWrap, OvalModalCloseV2 } from "../Modal";
import Button from "../ButtonDefault";
import * as Style from "./styledComponents";

import { brTagConverter } from "../../lib/componentifyConverters";
import { __ } from "../../lib/translate";

type Props = {
  isOpen: boolean,
  isPremium: boolean,
  onDeactivateConfirm: () => void,
  handleClose: () => void
};

const DeactivatePublicFolderPage = ({
  isOpen,
  isPremium,
  onDeactivateConfirm,
  handleClose
}: Props) => {
  const [isOpened, setIsOpened] = useState(isOpen);

  useEffect(() => {
    setIsOpened(isOpen);
  }, [isOpen]); //[isOpen]

  const onEnter = useCallback(
    (e: any) => {
      if (e.keyCode === 13) {
        onDeactivateConfirm();
      }
    },
    [onDeactivateConfirm]
  );

  useEffect(() => {
    if (isOpened) {
      document.addEventListener("keyup", onEnter);
    } else {
      document.removeEventListener("keyup", onEnter);
    }
  }, [isOpened, onEnter]); //[isOpened, onEnter]

  const messageModal = __(
    "public_folder_deactivation_message_modal",
    "Are you sure you want to deactivate your public folder?<br/>All your Direct links will be disabled."
  );
  return (
    <Modal animate onClose={handleClose} opened={isOpened}>
      <OvalModalWrap>
        <OvalModalCloseV2 onClick={handleClose} />
        <Style.Container>
          <Style.Header>{__("public_folder_deactivation_header")}</Style.Header>
          <Style.Message>
            <Componentify text={messageModal} converters={[brTagConverter]} />
          </Style.Message>
          {!isPremium && (
            <Style.Message>
              {("trial_is_over", "You won't be able to renew your trial.")}
            </Style.Message>
          )}
          <Style.Footer>
            <Button
              color="lightgray4"
              style={{
                marginRight: "5px"
              }}
              onClick={handleClose}
            >
              {__("Cancel")}
            </Button>
            <Button
              disabled={false}
              loading={false}
              color="red"
              style={{
                marginLeft: "5px"
              }}
              onClick={onDeactivateConfirm}
            >
              {__("public_folder_deactivation_header")}
            </Button>
          </Style.Footer>
        </Style.Container>
      </OvalModalWrap>
    </Modal>
  );
};

export default DeactivatePublicFolderPage;
