import styled from "styled-components";

export const MenuWrapper = styled.div`
  .szh-menu {
    font-family: 'Roboto';
    color: #000;
    font-size: 12px;
    font-weight: 400;
    padding: 8px 0;
    min-width: 160px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.25);
    background: #FFF;
    box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.15);
  }

  .szh-menu-container {
    right: 206px;
  }

  .szh-menu__item {
    padding: 6px 16px 6px 12px;    
  }

  .szh-menu__item--hover {
    background-color: #f9f9f9;
  }
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
`;