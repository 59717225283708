import PropTypes from "prop-types";
import React, { Component } from "react";
import { validateEmail } from "../lib/utils";
import userinfo from "../api/userinfo";
import apiMethod from "../api/apiMethod";
import { TDiv, TSpan, TString, HtmlTString } from "../components/intl";
import { __ } from "../lib/translate";
import ResponsiveWrap from "../components/ResponsiveWrap";
import SubmitButton from "../components/SubmitButton";
import InputReadOnly from "../components/InputReadOnly";
import InputText from "../components/InputText";

import styles from "../styles/forms.less";

export default class ChangeEmailChooseNewEmail extends Component {
  static propTypes = {
    code: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      newemail: "",
      error: ""
    };

    this._pickEmail = this._pickEmail.bind(this);
  }

  _input(name, value) {
    this.setState({ [name]: value });
  }

  _pickEmail(e) {
    e.preventDefault();

    const { code } = this.props;
    const { newemail } = this.state;

    if (!newemail.length) {
      this.setState({ error: __("provide_email", "Provide Email.") });
      return;
    }

    if (!validateEmail(newemail)) {
      this.setState({ error: __("invalid_email", "Invalid Email.") });
      return;
    }

    apiMethod(
      "sendnewchangemail",
      { code: code, newmail: newemail },
      ret => this.setState({ done: true }),
      {
        errorCallback: ret =>
          this.setState({ error: ret.error, loading: false })
      }
    );

    this.setState({ loading: true });
  }

  _form() {
    const { newemail, error, loading } = this.state;

    let errorEl = <div className="error">&nbsp;</div>;
    if (error) {
      errorEl = <div className="error">{error}</div>;
    }

    return (
      <ResponsiveWrap>
        <form method="post" onSubmit={this._pickEmail}>
          {errorEl}
          <InputText
            name="newemail"
            placeholder={__("new_email", "New email")}
            value={newemail}
            onChange={e => this._input("newemail", e.target.value)}
          />
          <SubmitButton loading={loading} active={!loading}>
            <TSpan id="continue">{__("Continue")}</TSpan>
          </SubmitButton>
        </form>
      </ResponsiveWrap>
    );
  }

  _done() {
    const { newemail } = this.state;

    return (
      <div
        style={{
          textAlign: "center",
          lineHeight: "18px",
          fontSize: "14px",
          margin: "20px 0 0 0"
        }}
      >
        <HtmlTString
          id="change_email_mail_sent"
          text="We've sent link to your email <b>%newemail%</b> with further instructions."
          replace={{ newemail: newemail }}
        />
      </div>
    );
  }

  render() {
    const { done } = this.state;

    return (
      <div className="form">
        <TDiv id="change_email_btn" className="title">
          Change email
        </TDiv>
        <TDiv id="enter_new_email_for_pcloud_account" className="subtitle">
          {"Enter a new email address for your pCloud account"}
        </TDiv>

        {done ? this._done() : this._form()}
        <br />
        <br />
      </div>
    );
  }
}
