// @flow

import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Componentify from "react-componentify";

import apiMethod from "../../api/apiMethod";
import { __ } from "../../lib/translate";
import { makeTextFile } from "../../lib/utils";

import { getSecurityCodesText, renderCode } from "./SecurityCodesStep";

import Button from "../../components/ButtonDefault";
import { Footer } from "../../components/Modals/styledComponents";
import { Container, Header, Message, LoaderSecurityCodes } from "./SharedComponents";
import { spanClassTagConverter } from "../../lib/componentifyConverters";

type Props = {
  token: string,
  password: string,
  msisdn: string
};

type State = {
  securityCodes: Array<{ code: string, used: boolean }>,
  isLoading: boolean
};

class SecurityCodesViewStep extends Component<Props, State> {
  static defaultProps = {
    token: "",
    password: "",
    msisdn: ""
  };

  constructor(props: Props) {
    super(props);

    (this: any).onResetCodes = this.onResetCodes.bind(this);

    this.state = {
      securityCodes: [],
      isLoading: true
    };
  }

  componentDidMount() {
    const { token, password } = this.props;

    apiMethod(
      "tfa_getrecoverycodes",
      {
        auth: token,
        password
      },
      ({ codes }) => {
        this.setState({ securityCodes: codes, isLoading: false });
      },
      {
        errorCallback: ({ error }) => {
          this.setState({ isLoading: false });
          HFN.message(__("something_went_wrong_refresh_and_try_again"), "error");
          throw new Error(error);
        }
      }
    );
  }

  onResetCodes() {
    const { token, password } = this.props;
    const { isLoading } = this.state;

    if (isLoading) {
      return;
    }

    apiMethod(
      "tfa_resetrecoverycodes",
      {
        auth: token,
        password
      },
      ({ codes }) => {
        this.setState({ securityCodes: codes });
      },
      {
        errorCallback: ({ error }) => {
          HFN.message(__("something_went_wrong_refresh_and_try_again"), "error");
          throw new Error(error);
        }
      }
    );
  }

  render() {
    const { securityCodes, isLoading } = this.state;
    const { msisdn } = this.props;
    const textHref = makeTextFile(getSecurityCodesText(securityCodes));

    return (
      <Container>
        <Header>{__("tfa_setup_view_recovery_codes")}</Header>
        <Message className="important-message view">
          <div className="security-text">
            <Componentify
              text={__("tfa_setup_view_recovery_codes_msg_span", "", {
                linkattrs: `class='no-link'`
              })}
              converters={[spanClassTagConverter]}
            />
          </div>
          {isLoading ? (
            <LoaderSecurityCodes />
          ) : (
            <div>
              <Title>{__("tfa_setup_view_recovery_codes", "Recovery Codes")}</Title>
              <div className="security-codes">{securityCodes.map(renderCode)}</div>
            </div>
          )}
        </Message>
        <Footer>
          <Button
            style={{ width: "100%", marginRight: "10px" }}
            color="lightgray4"
            disabled={isLoading}
            loading={isLoading}
            onClick={this.onResetCodes}
          >
            {__("tfa_setup_view_recovery_codes_reset_btn")}
          </Button>
          <Button
            style={{ width: "100%" }}
            color="cyan"
            className="float-left"
            disabled={isLoading}
            href={textHref}
            download="pcloudSecurityKeys.txt"
          >
            {__("tfa_setup_view_recovery_codes_download_btn")}
          </Button>
        </Footer>
      </Container>
    );
  }
}

export default connect(({ user }) => {
  const { token, userinfo: { password, msisdn } = {} } = user;

  return {
    token,
    password,
    msisdn
  };
})(SecurityCodesViewStep);

const Title = styled.div`
  color: #000;
  font-size: 13px;
  text-align: center;
  font-weight: 500;
  margin: 17px 0;
`;
