// @flow

import React from "react";
import { __ } from "../../lib/translate";
import * as ICONS from "./icons";

export const actionsGallery = {
  image: [
    // "download",
    // "getpublink",
    ["openloc",
    "open-orig",
    "downloadsizes"],
    //--------------
    ["delete",
    "move",
    "copy",
    "rename"],
    //--------------
    ["info",  
    "revisions"]
    // ["print"],
  ],
  video: [
    // "download",
    // "getpublink",
    ["openloc",
    "open-orig"],
    //--------------
    ["delete",
    "move",
    "copy",
    "rename"],
    //--------------
    ["info",  
    "revisions"]
    // ["print"],
  ]
};

export const actionsGalleryToIcon = {
  downloadsizes: <ICONS.DownloadResizedIcon />,
  print: <ICONS.PrintIcon />,
  copy: <ICONS.CopyIcon />,
  move: <ICONS.MoveIcon />,
  rename: <ICONS.RenameIcon />,
  info: <ICONS.FileInfoIcon />,
  revisions: <ICONS.RevisionsIcon />,
  openloc: <ICONS.OpenLocationIcon />,
  "open-orig": <ICONS.OpenOriginalIcon />,
  delete: <ICONS.DeleteIcon />
};

export const actionsGalleryToName = {
  downloadsizes: __("Download Resized", "Download resized"),
  print: __("Print", "Print"),
  copy: __("Copy", "Copy"),
  move: __("Move", "Move"),
  rename: __("Rename", "Rename"),
  info: __("File Info", "File info"),
  revisions: __("Revisions", "Revisions"),
  openloc: __("Open Location", "Open location"),
  delete: __("Delete", "Delete"),
  "open-orig": __("Open Original", "Open original")
};


// Slideshow options in milliseconds
export const SLIDESHOW_OPTIONS = [
  3000, // 3 sec
  5000, // 5 sec
  10000, // 10 sec
  20000, // 20 sec
  30000, // 30 sec
  60000, // 60 sec
];