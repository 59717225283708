import React, {useEffect, useRef} from "react";
import usePlayerContext from "../../../hooks/usePlayerContext";
import {
    OverlayPanelWrapper,
} from "../../../userinterface/styles";

const OverlayPanelContainer = (props) => {
    const {
        onCloseAllOverlays,
        controlsWithOverlay
    } = usePlayerContext();

    const wrapperRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            event.stopPropagation();
            if (
                wrapperRef.current &&
                !wrapperRef.current.contains(event.target) &&
                !controlsWithOverlay.showSavePlaylistPanel &&
                !controlsWithOverlay.showLoadPlaylistPanel
            ) {
                onCloseAllOverlays();
            }
        }

        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [
        wrapperRef,
        controlsWithOverlay.showSavePlaylistPanel,
        controlsWithOverlay.showLoadPlaylistPanel
    ]);

    return (
        <OverlayPanelWrapper
            ref={wrapperRef}
            {...props}
        >
            {props.children}
        </OverlayPanelWrapper>
    );
}

export default OverlayPanelContainer;