import React, {useState, useRef, useEffect} from "react";
import styled, { keyframes } from "styled-components";
import { getPalette, buildRgb, quantization } from "../../../lib/colorPicker";
import {BOX_THUMB_SIZE, DEFAULT_THUMB} from "../constants";
import {checkIsWhiteBackground, getThumb} from "../helpers";
import usePlayerContext from "../hooks/usePlayerContext";

const paletteInitialState = {
  main: [],
  complementary: []
};

const ColoredImageBackground = (props) => {
  const [paletteColors, setPaletteColors] = useState(paletteInitialState);
  const [thumbError, setThumbError] = useState(null);

  const {
    setIsWhiteBackground,
    isWhiteBackground,
    additionalClass,
    playerBackgroundRef,
    currentSong,
    loadedThumbs,
    isPlaying,
  } = usePlayerContext();

  const canvasEl = useRef();
  const imageEl = useRef();

  useEffect(() => {
    setIsWhiteBackground(false);
    setThumbError(null);
  }, [currentSong.fileid]);

  const onImageLoad = () => {
    if (
        (
            !imageUrl ||
            imageUrl === DEFAULT_THUMB
        ) &&
        currentSong.thumb !== true
    ) {
      setPaletteColors(paletteInitialState);
      return;
    }
    const canvas = canvasEl.current;
    canvas.width = BOX_THUMB_SIZE;
    canvas.height = BOX_THUMB_SIZE;
    const ctx = canvas.getContext("2d", { willReadFrequently: true });
    ctx.drawImage(imageEl.current, 0, 0);
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

    const rgbArray = buildRgb(imageData.data);
    const quantColors = quantization(rgbArray, 0);
    const paletteColors = getPalette(quantColors);

    if (
        Array.isArray(paletteColors.main) &&
        paletteColors.main.length >= 4
    ) {
      setIsWhiteBackground(checkIsWhiteBackground(paletteColors.main));
    } else {
      setIsWhiteBackground(false);
    }

    setPaletteColors(paletteColors);
  }

  const getBackgroundColor = (paletteColors) => {
    let _paletteColors = [...paletteColors];

    if (
        imageUrl !== DEFAULT_THUMB &&
        _paletteColors &&
        Array.isArray(_paletteColors) &&
        _paletteColors.length >= 4 &&
        !thumbError
    ) {
      if (_paletteColors.length >= 7) {
        if (_paletteColors.length > 7) {
          _paletteColors.shift();
        }
        return `radial-gradient(18% 28% at 24% 50%, rgb(${_paletteColors[0].r}, ${_paletteColors[0].g}, ${_paletteColors[0].b}) 7%, rgba(7, 58, 255, 0) 100%), radial-gradient(18% 28% at 18% 71%, rgba(255, 255, 255, 0.35) 6%, rgba(7, 58, 255, 0) 100%), radial-gradient(70% 53% at 36% 76%, rgb(${_paletteColors[1].r}, ${_paletteColors[1].g}, ${_paletteColors[1].b}) 0%, rgba(7, 58, 255, 0) 100%), radial-gradient(42% 53% at 15% 94%, rgb(255, 255, 255) 7%, rgba(7, 58, 255, 0) 100%), radial-gradient(42% 53% at 34% 72%, rgb(255, 255, 255) 7%, rgba(7, 58, 255, 0) 100%), radial-gradient(18% 28% at 35% 87%, rgb(255, 255, 255) 7%, rgba(7, 58, 255, 0) 100%), radial-gradient(31% 43% at 7% 98%, rgb(255, 255, 255) 24%, rgba(7, 58, 255, 0) 100%), radial-gradient(21% 37% at 72% 23%, rgba(${_paletteColors[2].r}, ${_paletteColors[2].g}, ${_paletteColors[2].b}, 0.61) 24%, rgba(7, 58, 255, 0) 100%), radial-gradient(35% 56% at 91% 74%, rgba(${_paletteColors[3].r}, ${_paletteColors[3].g}, ${_paletteColors[3].b}, 0.96) 9%, rgba(7, 58, 255, 0) 100%), radial-gradient(74% 86% at 67% 38%, rgba(${_paletteColors[4].r}, ${_paletteColors[4].g}, ${_paletteColors[4].b}, 0.96) 24%, rgba(7, 58, 255, 0) 100%), linear-gradient(125deg, rgb(${_paletteColors[5].r}, ${_paletteColors[5].g}, ${_paletteColors[5].b}) 1%, rgb(${_paletteColors[6].r}, ${_paletteColors[6].g}, ${_paletteColors[6].b}) 100%)`;
      }
      if (_paletteColors.length >= 4) {
        _paletteColors.shift();
        return `linear-gradient(147deg, rgba(${_paletteColors[0].r}, ${_paletteColors[0].g}, ${_paletteColors[0].b},1) 0%, rgba(${_paletteColors[1].r}, ${_paletteColors[1].g}, ${_paletteColors[1].b},1) 60%, rgba(${_paletteColors[2].r}, ${_paletteColors[2].g}, ${_paletteColors[2].b},1) 100%)`;
      }
    }
    return "linear-gradient(147deg, rgba(21,195,214,1) 0%, rgba(34,1,51,1) 60%, rgba(61,0,105,1) 100%)";
  }

  const imageUrl = getThumb(
      currentSong?.fileid,
      loadedThumbs,
      BOX_THUMB_SIZE,
      BOX_THUMB_SIZE
  );

  return (
    <Background
        className={additionalClass}
        color={getBackgroundColor(paletteColors.main)}
        $isPlaying={isPlaying}
        ref={playerBackgroundRef}
    >
      {imageUrl !== DEFAULT_THUMB && !thumbError && <BackgroundOverlay $isWhiteBackground={isWhiteBackground}/>}
      <canvas style={{ display: "none" }} ref={canvasEl}></canvas>
      <img ref={imageEl} crossOrigin="anonymous" src={imageUrl !== DEFAULT_THUMB ? imageUrl + '/?cache=none' : imageUrl} onLoad={onImageLoad} onError={(error) => {
        setThumbError(imageUrl);
      }}/>
      {props.children}
    </Background>
  )
}

export default React.memo(ColoredImageBackground);

const gradient = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

const BackgroundOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: ${({isWhiteBackground}) => isWhiteBackground ? 'rgba(255, 255, 255, 0.07)' : 'rgba(0, 0, 0, 0.07)'};
`;

const Background = styled.div`
  position: relative;
  &.minimizePlayer {
    transition: left 500ms ease-in-out;
    -moz-transition: left 500ms ease-in-out;
    -webkit-transition: left 500ms ease-in-out;
    -o-transition: left 500ms ease-in-out;
    left: 0;
    position: relative;
    overflow: hidden;
  }
  width: 100%;
  height: 100%;
  background-image: ${({ color }) => color};
  background-size: 200% 200%;
	animation: ${gradient} 12s ease infinite;
  animation-play-state: ${({$isPlaying}) => $isPlaying ? "running" : "paused"};
  & > img {
    display: none;
  }
`;
