export const onMoreOptionsClick = (action, data, closePreviewModal, modalContainer, mediaType) => {
  if (typeof gtag === "function") {
    gtag("event", "media_preview_click", {
      action: "options",
      eventValue: action,
      category: mediaType
    });
  }

  switch (action) {
    case "print": {
      HFN.initPrintUrl(data);
      break;
    }
    /*case "download":
      if (data.encrypted) pCloudCrypto.initFileDownload(data, modalContainer);
      else if ($.browser.msie && versionCompare($.browser.version, "10.0") == -1) HFN.downloadFile(data.fileid);
      else {
        const params = { fileid: data.fileid, forcedownload: 1 };
        if (data.revisionid) {
          params.revisionid = data.revisionid;
          params.name = data.name.replace(fileext(data.name));
          params.name = data.name.replace(/\.([^\.]+)$/g, `-r${data.revisionid}.$1`);
        }
        HFN.downloadLinkFile(params);
      }
      break;*/
    case "downloadsizes":
      HFN.initDownloadImageSizes(data, modalContainer);
      break;
    case "openloc":
      $.bbq.pushState({ page: "filemanager", folder: data.parentfolderid, file: data.id }, 2);
      closePreviewModal(null, "openloc");
      break;
    case "rename":
      HFN.initRename(data, modalContainer);
      break;
    case "copy":
      HFN.initCopy(data, modalContainer);
      break;
    case "move":
      HFN.initMove(data, modalContainer);
      break;
    case "delete":
      HFN.deleteItem(data, triggerOpenFolder.bind(null, data.parentfolderid, false, true), modalContainer);
      break;
    case "info":
      HFN.itemInfo(data, modalContainer);
      break;
    case "revisions":
      $.bbq.pushState({ page: "revisions", fileid: data.fileid }, 2);
      closePreviewModal(null, "revisions");
      break;
    case "open-orig":
      HFN.downloadLinkFile({ fileid: data.fileid });
      break;
  }
};