import styled, { keyframes } from "styled-components";
import { hiDPI } from "polished";

//Modal components
export const Fadein = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

export const BlackBackground = styled.div`
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  animation-name: ${Fadein};
  animation-duration: 300ms;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  animation-fill-mode: forwards;
  overflow: hidden;
`;

export const CenterContainer = styled.div`
  position: fixed;
  left: 50%;
  margin-left: -200px;
  top: 50%;
  margin-top: -129px;
  z-index: 100;
  @media (max-width: 800px) {
    margin-left: -182px;
  }
`;
//End of Modal components

//Buttons

export const ButtonDefault = styled.div`
  /* margin-top: 12px; */
  float: right;
  min-width: 96px;
  padding: 0px 8px;
  box-sizing: border-box;
  height: 26px;
  line-height: 26px;
  font-size: 12px;
  text-transform: uppercase;
  color: white;
  text-align: center;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  border-radius: 5px;
  cursor: pointer;
  background-color: #17bed0;

  &.manage {
      margin-right: 10px;
  }
  @media (max-width: 490px) {
    &.manage {
      margin-right: 0;
      margin-bottom: 10px;
    }

    &.remove {
      margin-left: 15px;
    }
  }
`;

export const ButtonDisabled = styled(ButtonDefault)`
  opacity: 0.35;
  cursor: default;
  pointer-events: none;
`;

export const GreyButton = styled(ButtonDefault)`
  background-color: #f4f4f4;
  color: #111;
`;

export const GreyButtonDisabled = styled(GreyButton)`
  opacity: 0.35;
  cursor: default;
  pointer-events: none;
`;
//End of Buttons

//Table Components
export const Table = styled.div`
  clear: both;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  border-radius: 4px;
  border: 1px solid #eee;
  -webkit-box-shadow: 0px 6px 8px -6px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 6px 8px -6px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 8px -6px rgba(0, 0, 0, 0.25);
`;

export const TableTitle = styled.div`
  font-size: 15px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  margin: 10px 2px;
  margin-top: 25px;
`;

export const Header = styled.div`
  background-color: #fafafa;
  height: 40px;
  line-height: 40px;
  padding: 0px 13px;
  border-bottom: 1px solid #f1f1f1;
  display: flex;
  justify-content: space-between;
`;

export const HeaderTitle = styled.span`
  color: #888;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-weight: 400;

  &.service {
    /* flex-basis: 15%; */
  }
  &.subscription {
    /* flex-basis: 30%; */
  }
  &.billing {
    /* flex-basis: 25%; */
  }

  &:first-child {
    @media (max-width: 800px) {
      width: auto;
    }
  }
  &:last-child {
    /* float: right; */
  }
  &:nth-of-type(2) {
    width: 36%;
    /* float: left; */
    @media (max-width: 800px) {
      display: none;
      width: auto;
    }
  }
`;

export const MergedCells = styled.div`
  width: calc(70% + 22px);
  display: flex;
  justify-content: space-between;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  background-color: white;
  justify-content: space-between;
  color: ${props => (props.isSubscribed ? "#111" : "#aaa")};
  border-bottom: 1px solid #f1f1f1;
  min-height: 50px;
  box-sizing: border-box;

  &:last-child {
    border-bottom: none;
    @media (max-width: 800px) {
      padding: 10px 13px;
      position: relative;
      line-height: normal;
    }
  }
  padding: 13px 9px;
  @media (max-width: 800px) {
    padding: 10px 13px;
    position: relative;
    line-height: normal;
    /* flex-direction: column; */

    .left {
      width: 100%;
      justify-content: space-between;
      flex-direction: column;
      align-items: flex-start;
      margin-left: 10px;
    }

    .right {
      width: 100%;
      box-sizing: border-box;
    }
  }
`;

export const RowCell = styled.span`
  font-family: "Roboto", sans-serif;
  font-size: 17px;
  font-weight: 400;

  @media (max-width: 800px) {
    font-size: 15px;
    .right {
      padding-left: 31px;
      box-sizing: border-box;
    }
  }
  &:first-of-type {
    margin-left: 5px;
    max-width: 500px;
    @media (max-width: 800px) {
      width: auto;
      margin-left: 0;
    }
  }
  &:last-of-type {
    /* float: right; */
    @media (max-width: 800px) {
      width: auto;
      float: none;
    }
  }
  &:nth-of-type(2) {
    width: 36%;
    @media (max-width: 800px) {
      width: auto;
      float: none;
      clear: left;
      display: block;
    }
  }
`;

export const Col = styled.div`
  display: flex;
  align-items: center;

  &.left {
    width: 70%;
    justify-content: space-between;
  }

  &.header {
    width: 64%;
  }

  &.right {
    width: 30%;
    justify-content: flex-end;
  }

  @media (max-width: 800px) {
    &.left {
      width: 50%;
      justify-content: space-between;
      flex-direction: column;
      align-items: flex-start;
      margin-left: 10px;
    }

    &.right {
      width: 50%;
      box-sizing: border-box;
    }
  }

`;
//End of Table Components

//Icons and images
export const Icon = styled.div`
  /* position: relative; */
  top: 15px;
  /* margin-right: 15px; */
  /* float: left; */
  background-position: center;
  background-repeat: no-repeat;
  width: 30px;
  height: 22px;
  background-size: contain;
`;

export const SubscribedIcon = styled(Icon)`
  background-size: 15px;
  background-image: url(${require("../../../root/img/managesubscriptions/check.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/managesubscriptions/check@2x.png")});
  }
  visibility: ${props => (props.isSubscribed ? "default" : "hidden")};
`;
//End of Icons and images

const spin = keyframes`
  100% { transform: rotate(360deg) };
`;

export const Loading = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin: -10px 0 0 -10px;
  -webkit-animation: ${spin} 0.5s linear infinite;
  animation: ${spin} 0.5s linear infinite;
  background: url(${require("../../../root/img/loading_button.png")}) no-repeat 50%;
  background-size: 20px 20px;
`;

export const BlueButtonLoading = styled(ButtonDefault)`
  background-color: #0070ba;
  cursor: default;
  pointer-events: none;
  margin-top: 0px;
  position: absolute;
  right: 0px;
  z-index: 1;
  /* float: none; */
`;

export const PaypalChangeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 800px) {
    flex-wrap: wrap;
  }
`;

export const ChangeLabel = styled.div`
  color: #888;
  font-family: "Roboto",sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin-right: 8px;

  @media (max-width: 541px) {
    margin-right: 0px;
    margin-bottom: 8px;
  }
`;

export const PaypalButtonContainer = styled.div`
  width: 96px;
  height: 26px;
  position: relative;

  #paypal-button {
    line-height: 1;
    z-index: 0;
    position: relative;

    .paypal-buttons {
      min-width: 0px !important;
    }
  }
`;

export const NotBillableMessage = styled.div`
  margin-top: 14px;
  text-align: right;
  font-size: 14px;
  font-style: italic;
`;
