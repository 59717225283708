import {useState, useEffect} from 'react';
import {getPlayerState} from "../helpers";

type Props = {
    queue: Object<any>
};

const initialState = {
    showSavePlaylistPanel : false,
    showLoadPlaylistPanel : false,
    showSettingsOptionPanel : false,
    showVolumeChangingPanel : false,
    showPlayRatePanel: false,
    showSleepTimerPanel: false
};

const usePlayerOverlayControls = ({
   playerVariant = {}
}: Props) => {
    const [controlsWithOverlay, setControlsWithOverlay] = useState(initialState);

    useEffect(() => {
        onCloseAllOverlays();
    }, [
        playerVariant.isMinimize,
        playerVariant.isMiniPlayer,
        playerVariant.isFullPage
    ]);

    const onCloseAllOverlays = () => {
        setControlsWithOverlay(initialState);
    }

    const onOpenSettingsToggle = (event) => {
        if (event) {
            event.stopPropagation();
        }

        setControlsWithOverlay({
            ...initialState,
            showSettingsOptionPanel : !controlsWithOverlay.showSettingsOptionPanel,
        });

        if (!controlsWithOverlay.showSettingsOptionPanel && typeof gtag === "function") {
            gtag("event", "audio_player_view", {
                action: "settings",
                state: getPlayerState(playerVariant)
            });
        }
    };

    const onOpenPlayRateToggle = (event) => {
        if (event) {
            event.stopPropagation();
        }

        setControlsWithOverlay({
            ...initialState,
            showPlayRatePanel : !controlsWithOverlay.showPlayRatePanel,
        });

        if (!controlsWithOverlay.showPlayRatePanel && typeof gtag === "function") {
            gtag("event", "audio_player_view", {
                action: "playrate",
                state: getPlayerState(playerVariant)
            });
        }
    };

    const onOpenSleepTimerToggle = (event) => {
        if (event) {
            event.stopPropagation();
        }

        setControlsWithOverlay({
            ...initialState,
            showSleepTimerPanel : !controlsWithOverlay.showSleepTimerPanel,
        });

        if (!controlsWithOverlay.showSleepTimerPanel && typeof gtag === "function") {
            gtag("event", "audio_player_view", {
                action: "sleeptimer",
                state: getPlayerState(playerVariant)
            });
        }
    };

    const onVolumePanelToggle = (event) => {
        if (event) {
            event.stopPropagation();
        }

        setControlsWithOverlay({
            ...initialState,
            showVolumeChangingPanel : !controlsWithOverlay.showVolumeChangingPanel,
        });

        if (!controlsWithOverlay.showVolumeChangingPanel && typeof gtag === "function") {
            gtag("event", "audio_player_view", {
                action: "volume",
                state: getPlayerState(playerVariant)
            });
        }
    };

    const onOpenPlaylistCreateForm = (event) => {
        if (event) {
            event.stopPropagation();
        }

        setControlsWithOverlay({
            ...controlsWithOverlay,
            showSavePlaylistPanel : true,
        });

        if (typeof gtag === "function") {
            gtag("event", "audio_player_click", {
                action: "initiate save as playlist",
                state: getPlayerState(playerVariant)
            });
        }
    };

    const onOpenPlaylistLoadForm = (event) => {
        if (event) {
            event.stopPropagation();
        }

        setControlsWithOverlay({
            ...controlsWithOverlay,
            showLoadPlaylistPanel : true,
        });

        if (typeof gtag === "function") {
            gtag("event", "audio_player_click", {
                action: "initiate load playlist",
                state: getPlayerState(playerVariant)
            });
        }
    };

    return {
        controlsWithOverlay,
        onOpenSettingsToggle,
        onVolumePanelToggle,
        onCloseAllOverlays,
        onOpenPlaylistCreateForm,
        onOpenPlaylistLoadForm,
        onOpenPlayRateToggle,
        onOpenSleepTimerToggle
    }
}

export default usePlayerOverlayControls;