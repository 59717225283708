// @flow

import React from "react";
import styled from "styled-components";

import FolderInvitation from "../components/shares/FolderInvitation";
import BusinessInvitation from "./Business/BusinessInvitation";
import FamilyInvitation from "./FamilyPlan/FamilyInvitation";
import Image from "../../root/img/svg/login-reg.svg";
import ImageBusiness from "../../root/img/svg/wellcome/business.svg";
import ImageInvite from "../../root/img/svg/wellcome/invite.svg";
import ImageFamily from "../../root/img/svg/wellcome/family.svg";

type Props = { form: string, onSuccessInvitation: () => void };

const PAGES = {
  INVITE_FOLDER: "invite_folder",
  BUSINESS_INVITE: "business_invite",
  FAMILY_INVITE: "family_invite"
};

const pageConfig = {
  [PAGES.INVITE_FOLDER]: {
    title: "",
    subTitle: "",
    image: ImageInvite,
    formComponent: FolderInvitation
  },
  [PAGES.BUSINESS_INVITE]: {
    title: "",
    subTitle: "",
    image: ImageBusiness,
    formComponent: BusinessInvitation
  },
  [PAGES.FAMILY_INVITE]: {
    title: "",
    subTitle: "",
    image: ImageFamily,
    formComponent: FamilyInvitation
  }
};

const InvitationPage = ({ form = "invite_folder", onSuccessInvitation = () => {}, ...rest }: Props) => {
  const { title, subTitle, image: Image, formComponent: FormComponent } = pageConfig[form];

  if (HFN.config.isMobile()) {
    return <FormComponent {...rest} onSuccessInvitation={onSuccessInvitation} />;
  }

  return (
    <FlexContainer>
      <LeftBox>
        <TextWrapper>
          <Header>{title}</Header>
          <Subheader>{subTitle}</Subheader>
          <Image />
        </TextWrapper>
      </LeftBox>

      <RightBox>
        <Form>
          <FormComponent {...rest} onSuccessInvitation={onSuccessInvitation} />
        </Form>
      </RightBox>
    </FlexContainer>
  );
};

export default InvitationPage;

const FlexContainer = styled.div`
  display: flex;
  width: 100%;
  height: calc(100vh - 60px);
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 910px) {
    flex-direction: column;
  }
`;

const LeftBox = styled.div`
  position: relative;
  z-index: 2;
  background-color: #fbfbfb;
  width: 56%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 910px) {
    width: 100%;
    display: none;
  }
`;

const RightBox = styled.div`
  position: relative;
  z-index: 3;
  background-color: #ffffff;
  width: 44%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 910px) {
    width: 100%;
    flex-direction: column;
  }
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
  position: relative;

  @media screen and (max-width: 910px) {
    min-width: 0px;
    text-align: center;
    max-width: 400px;
    padding: 60px 20px;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  width: 100%;

  svg {
    width: auto;
    height: auto;
    /* max-width: 637px;
    max-height: 316px; */
  }

  @media screen and (max-width: 910px) {
    min-width: 0px;
    text-align: center;
    max-width: 400px;
    padding: 60px 20px;
  }
`;

const Header = styled.div`
  font-size: 32px;
  font-weight: bold;
  line-height: 1.19;
  letter-spacing: -0.32px;
  color: #000;
  padding: 0px;
  width: 100%;

  @media screen and (max-width: 910px) {
    text-align: center;
  }
`;

const Subheader = styled.div`
  font-size: 19px;
  font-weight: normal;
  line-height: 1.32;
  letter-spacing: normal;
  color: #000;
  padding: 0px;
  width: 100%;
  margin-bottom: 35px;

  @media screen and (max-width: 910px) {
    text-align: center;
  }
`;

export const InfoBoxWrapper = styled.div`
  max-width: 486px;
  max-width: 400px;
  padding: 20px;
  box-sizing: border-box;
  text-align: left;
  width: 100%;
  margin: 0 auto;

  @media screen and (max-width: 910px) {
    min-width: 0px;
    text-align: center;
    max-width: 400px;
    padding: 60px 20px;
  }
`;
