// @flow
import React, { useState } from "react";
import styled from "styled-components";
import { hiDPI } from "polished";
import ReactTooltip from "react-tooltip";

import Dropdown, { DIR_DOWN } from "../Dropdown";
import { __ } from "../../lib/translate";
import { VIEW, EDIT, RESHARE } from "@pcloud/web-utilities/dist/config/constants";

type Item = {
  title: string,
  href: boolean,
  extra: { id: number }
};

type Props = {
  permission?: number,
  shouldShowTooltip?: boolean,
  getPermission?: number => void,
  disable?: boolean,
  canReshare: boolean,
  className?: string
};

const PermissionButton = ({
  permission = EDIT,
  shouldShowTooltip = false,
  getPermission,
  canReshare,
  disable = false,
  className
}: Props) => {
  const [selectedEl, setSelectedEl] = useState(permission);
  const PERM = {
    "0": __("share_permission_view_headline"),
    "7": __("share_permission_edit_headline"),
    "15": __("share_permission_manage_headline")
  };

  const getDropDownList = () => {
    const dropdwonList = [
      {
        title: PERM[VIEW],
        href: false,
        extra: { id: 0 }
      },
      {
        title: PERM[EDIT],
        href: false,
        extra: { id: 7 }
      }
    ];

    if (canReshare) {
      dropdwonList.push({
        title: PERM[RESHARE],
        href: false,
        extra: { id: 15 }
      });
    }

    return dropdwonList;
  };

  const onItemDropdownClick = (item: Item) => {
    const { extra: { id } = {} } = item;
    setSelectedEl(id);
    if (getPermission) {
      getPermission(id);
    }
  };

  const list = getDropDownList();
  return (
    <Wrapper disable={disable} className={className}>
      {disable ? <DisableWrapper /> : null}
      <Dropdown
        direction={DIR_DOWN}
        childDirection={DIR_DOWN}
        list={list}
        onSelect={onItemDropdownClick}
      >
        <AccessButton>
          {PERM[selectedEl]}
          <span className="arrow">&#9662;</span>
        </AccessButton>
      </Dropdown>
      {shouldShowTooltip && (
        <React.Fragment>
          <div data-tip data-for="permission">
            <InfoIcon />
          </div>
          <ReactTooltip place="right" className="tooltip-width" id="permission" effect="solid">
            <TooltipWrapper>
              <Tooltip>
                <PermissionTitle>{PERM[VIEW]}</PermissionTitle>
                <PermissionDesc>
                  {__(
                    "can_edit_explain",
                    "Invited people can add new files and modify/delete your files."
                  )}
                </PermissionDesc>
              </Tooltip>
              <Tooltip>
                <PermissionTitle>{PERM[EDIT]}</PermissionTitle>
                <PermissionDesc>
                  {__("can_view_explain", "Invited people can not modify or delete your files.")}
                </PermissionDesc>
              </Tooltip>
              {canReshare ? (
                <Tooltip>
                  <PermissionTitle>{PERM[RESHARE]}</PermissionTitle>
                  <PermissionDesc>
                    {__(
                      "can_manage_explain",
                      "Invited people can add, modify and delete files, and reshare the folder."
                    )}
                  </PermissionDesc>
                </Tooltip>
              ) : null}
            </TooltipWrapper>
          </ReactTooltip>
        </React.Fragment>
      )}
    </Wrapper>
  );
};

export default PermissionButton;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  height: auto;
  opacity: ${props => (props.disable ? 0.35 : 1)};

  &.email-perm {
    margin: 7px;
  }

  .tooltip-width {
    min-width: 270px;
    background-color: #000000;
  }
`;

const AccessButton = styled.div`
  height: 26px;
  line-height: 10px;
  font-size: 12px;
  padding: 0 10px;
  min-width: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 3px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.07);
  border: solid 1px #8e8e95;
  background-color: #fff;
  box-sizing: border-box;
  cursor: pointer;
`;

export const InfoIcon = styled.div`
  display: inline-block;
  margin-left: 5px;
  margin-top: 4px;
  width: 18px;
  height: 18px;

  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url(${require("../../../root/img/share-icons/info.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/share-icons/info@2x.png")});
  }
`;

const TooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Tooltip = styled.div`
  color: #ffffff;
  margin-top: 10px;

  &:first-child {
    margin-top: 0;
  }
`;
const PermissionTitle = styled.div`
  color: #16b7c9;
`;
const PermissionDesc = styled.div``;

const DisableWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;
