import "@vidstack/react/player/styles/base.css";
import "@vidstack/react/player/styles/plyr/theme.css";

import "./less/gallery.less";

import React, { useState, useEffect, useRef, useMemo } from "react";

import { MediaPlayer, MediaProvider, MediaErrorDetail, MediaVolumeChange, MediaSeekingEvent, MediaSeekingRequestEvent, useMediaRemote } from "@vidstack/react";
import { PlyrLayout, plyrLayoutIcons } from '@vidstack/react/player/layouts/plyr';

// Custom controls
import MuteAndVolumeMenu from "./video/controls/MuteAndVolumeMenu";
import ProgressTimeSlider from "./video/controls/ProgressTimeSlider";
import GoogleChromeCastButton from "./video/controls/GoogleChromeCastButton";
import SettingsMenu from "./video/controls/SettingsMenu";
import PlayLargeButton from "./video/controls/PlayLargeButton";
// import SeekingLabel from "./video/controls/SeekingLabel";
import { PlayIcon, PauseIcon, FullscreenIcon, FullscreenExitIcon, PipIcon, PipExitIcon, AirPlayIcon } from "./icons";

import VideoPlayerLocalStorage from "./video/storage/VideoPlayerLocalStorage";
import { detectIsMobile } from "../../lib/utils";

type Props = {
  videoData: {
    id: string,
    name: string,
    videoUrls: [any],
    thumbUrl: string,
    thumbSize: string,//<width>x<height>
    thumbType: string,
    maxWidth: number,
    maxHeight: number
  },
  autoPlay: boolean,
  onVideoEnded: (video: videoData) => void,
  slideshowActive: boolean,
  slideshowPlaying: boolean,
  videoLoading: boolean,
  setVideoLoading: (loading: boolean) => void,
  onPause: () => void,
  onPlay: () => void,
  onFullscreenChange: (isFullscreen: boolean) => void,
  onEnterFullscreenRequest: () => void,
  defaultDocumentTitle: string,
  onError: ({autoPlay: boolean}) => void,
  onSuccessLoadingThumb: () => void,
  seek: number,
  onDraggingVideoProgress: (dragging: boolean) => void,
  noSwipeGesture: boolean
};

const VideoContainer = React.forwardRef(
(
  {
    videoData,
    autoPlay = false,
    onVideoEnded = () => {},
    slideshowActive = false,
    slideshowPlaying = false,
    videoLoading = false,
    setVideoLoading = () => {},
    onPause = () => {},
    onPlay = () => {},
    onFullscreenChange = () => {},
    onEnterFullscreenRequest = () => {},
    defaultDocumentTitle = "",
    onError = () => {},
    onSuccessLoadingThumb = () => {},
    seek = 0,
    onDraggingVideoProgress = () => {},
    noSwipeGesture = true
  }: Props,
  ref
) => {

  // !!!Important don't use const vidstackMediaPlayerProps = useStore(MediaPlayerInstance, vidstackMediaPlayer);
  // This will trigger undesired rerenders -> instead use ref.current?.state -> 
  // https://www.vidstack.io/docs/player/core-concepts/state-management?styling=default-theme#avoiding-renders

  const remote = useMediaRemote();
  
  const lastPlayingTime = useRef(0);

  const playVideoAfterquailityChanged = useRef(null);

  const initialSeek = useRef(seek);

  const volumeLevelAndMuted = useRef(null);

  const volumeSliderRef = useRef(null);
  const disableSendAnalyticsDataForPause = useRef(false);

  const [videoDataForPlayer, setVideoDataForPlayer] = useState(videoData);
  const [playerControlsHidden, setPlayerControlsHidden] = useState(false);
//  const [seekingLabel, setSeekingLabel] = useState(null);

  const storage = useMemo(() => new VideoPlayerLocalStorage(), []);

  const isMobile = useMemo(() => !!detectIsMobile(), []);

  const videoPlayerIcons = useMemo(() => {
    return {
      ...plyrLayoutIcons,
      Play: PlayIcon,
      Pause: PauseIcon,
      EnterFullscreen: FullscreenIcon,
      ExitFullscreen: FullscreenExitIcon,
      EnterPiP: PipIcon,
      ExitPiP: PipExitIcon,
      AirPlay: AirPlayIcon
    };
  }, []);

  // Important to be passed to MediaPlayer as separate variable through useMemo!
  // Otherwise each rerender calls new MediaMetadata(...) which increases the CPU usage especially on Safari.
  const thumbForMediaSessionAPI = useMemo(() => videoDataForPlayer ? [{
    src: videoDataForPlayer.thumbUrl,
    sizes: videoDataForPlayer.thumbSize,
    type: videoDataForPlayer.thumbType,
  }] : (thumbForMediaSessionAPI || null), [videoDataForPlayer]);

  useEffect(() => {
    return async () => {
      // Rollback document.title
      document.title = defaultDocumentTitle;
      
      await exitPIP();
    }
  }, []);

  useEffect(() => {
    if (videoData !== videoDataForPlayer) {
      // Calling setVideoDataForPlayer to null will force destroy of player to be reloaded for next video.
      // This helps us to apply load="play" or autoPlay. 
      // Load="play" shows us only the thumbnail without buffering any video data.
      setVideoDataForPlayer(autoPlay || (ref.current?.state.played && ref.current?.state.played.length > 0) ? null : videoData);
    }
  }, [videoData]);

  useEffect(() => {
    document.title = defaultDocumentTitle;

    if (videoData && videoDataForPlayer === null) {
      setVideoDataForPlayer(videoData);
      exitPIP();
    }

    setVideoLoading(autoPlay);

    // Subscribe for updates without triggering renders.
    return ref.current?.subscribe(({ currentTime }) => {
      lastPlayingTime.current = currentTime > 0 ? currentTime : lastPlayingTime.current;
    });
  }, [videoDataForPlayer]);

  useEffect(() => {
    if (!slideshowPlaying) {
      return;
    }

    // Playing slideshow.
    if (ref.current && !ref.current.state.playing && ref.current.state.canPlay) {
      ref.current.play();
    } else if (autoPlay && ref.current && !ref.current?.state.playing) {
      // We have to reload the player to play due to browser's policy: "User should interact with the player first."
      setVideoDataForPlayer(null);
    }
  }, [slideshowPlaying, autoPlay]);

  const exitPIP = async () => {
    if (document.pictureInPictureElement) {
      try {
        await ref.current.exitPictureInPicture();
      } catch (e) {}
    }
  }

  const onStartedVideoEvent = (nativeEvent) => {
    setVideoLoading(false);
    
    if (autoPlay && remote && initialSeek.current > 0) {
      remote.seek(initialSeek.current, nativeEvent);
      initialSeek.current = 0;
      lastPlayingTime.current = 0;
    }
  }

  const onProviderSetupVideoEvent = async () => {
    // Change quality to be the best after "Original".
    if (ref.current?.state.canSetQuality && ref.current?.state.qualities && ref.current?.state.qualities.length > 1) {
      let bestNonOriginal = null;
      for (let i = 0; i < ref.current.state.qualities.length; i++) {
        if (!ref.current?.state.qualities[i].isoriginal && (!bestNonOriginal || ref.current?.state.qualities[i].height > bestNonOriginal.height)) {
          bestNonOriginal = ref.current?.state.qualities[i];
        }
      }

      if (bestNonOriginal) {
        // It changes the quality.
        bestNonOriginal.selected = true;
      }
    }

    // Save initial volume object. -> Used only for Google Analytics data.
    if (typeof ref.current?.state.volume !== "undefined" && typeof ref.current?.state.muted !== "undefined") {
      const storageDataVolume = await storage.getVolume();
      const storageDataMuted = await storage.getMuted();
      volumeLevelAndMuted.current = {
        volume: storageDataVolume !== null ? storageDataVolume : ref.current.state.volume, 
        muted: storageDataMuted !== null ? storageDataMuted : ref.current.state.muted
      };
    }
  }

  const onPlayVideoEvent = () => {
    sendAnalyticsData("play");

    document.title = String.fromCharCode(9654) + " " + videoData.name + " - pCloud";
    onPlay();
  }

  const onPauseVideoEvent = () => {
    // Add this delay, because pause is called also when video is ended.
    // We want only "complete" to be sent in such case.
    setTimeout(() => {
      if (!ref.current?.state.ended && !disableSendAnalyticsDataForPause.current) {
        sendAnalyticsData("pause");
      } // Else we've already sent "complete" event.
      disableSendAnalyticsDataForPause.current = false;
    }, 100);
    
    document.title = videoData.name + " - pCloud";
    onPause();
  }

  const onEndedVideoEvent = () => {
    sendAnalyticsData("complete");

    document.title = videoData.name + " - pCloud";
    lastPlayingTime.current = 0;

    if (slideshowActive) {
      disableSendAnalyticsDataForPause.current = true;
    }

    if (slideshowActive && ref.current?.state.fullscreen) {
      // Wait the video to exit the fullscreen and then activeSlideshow useEffect will exit the slideshow fullscreen as well.
      ref.current?.exitFullscreen().finally(() => {
        onVideoEnded(videoDataForPlayer);
      });
    } else {
      onVideoEnded(videoDataForPlayer);
    }
  };

  const onErrorVideoEvent = (detail: MediaErrorDetail) => {
    setVideoLoading(false);
    onError({autoPlay: true, mediaType: "video", lastPlayingTime: lastPlayingTime.current});
  };

  const onErrorLoadingThumb = () => {
    setVideoLoading(false);
    onError({autoPlay: false, mediaType: "videoThumb"});
  }

  const onControlsVisibilityChange = (isVisible) => {
    if (!isMobile) {
      return;
    }

    setPlayerControlsHidden(!isVisible);
  };

  // This can be used if quality AUTO is active to prevent stopping video on resized window.
  // Fix for: After resizing window quality is changed, but it's not continue playing.
  const onQualityChangeVideoEvent = (quality) => {
    if (ref.current?.state.playing) {
      playVideoAfterquailityChanged.current = true;
    }
  };

  // This can be used if quality AUTO is active to prevent stopping video on resized window.
  // Fix for: After resizing window quality is changed, but it's not continue playing.
  const onCanPlayVideoEvent = () => {
    if (ref.current && !ref.current.state.playing) {
      ref.current.play();
    }
    
    playVideoAfterquailityChanged.current = false;
  };

  const onSeekingVideoEvent = (currentTime: number, nativeEvent: MediaSeekingEvent) => {
    // Show controls, because when keyTarget is document, they are not shown.
    ref.current.controls.show(0, nativeEvent);
    // Hide after defaultDelay
    ref.current.controls.hide(undefined, nativeEvent);
  };

  // const onMediaSeekingRequestVideoEvent = (time: number, nativeEvent: MediaSeekingRequestEvent) => {
  //   if (nativeEvent && nativeEvent.originEvent && nativeEvent.originEvent.key && (nativeEvent.originEvent.key === "Right" || nativeEvent.originEvent.key === "Left") && seekingLabel !== nativeEvent.originEvent.key) {
  //     setSeekingLabel(nativeEvent.originEvent.key);
  //   }
  // }

  // const onSeekedVideoEvent = (currentTime: number, nativeEvent: MediaSeekingEvent) => {
  //   setSeekingLabel(null);
  // };

  const onVolumeChangeVideoEvent = async (volume: MediaVolumeChange) => {
    if (!volumeSliderRef.current?.state.dragging) {
      if (volume.volume !== volumeLevelAndMuted.current?.volume) {
        sendAnalyticsData("volume", volume.volume);
      }
  
      if (volume.muted !== volumeLevelAndMuted.current?.muted && volume.muted) {
        sendAnalyticsData("mute");
      }
  
      volumeLevelAndMuted.current = volume;
    }
  };

  const onFullscreenChangeVideoEvent = (isFullscreen: boolean) => {
    if (isFullscreen) {
      sendAnalyticsData("fullscreen", "on");
    }

    onFullscreenChange(isFullscreen);
  };

  const onRateChangeVideoEvent = (rate: number) => {
    sendAnalyticsData("playrate change", rate);
  };

  const sendAnalyticsData = (action, eventValue) => {
    if (typeof gtag === "function") {
      const params = {
        action: action,
        category: "video"
      };

      if (typeof eventValue !== "undefined") {
        params.eventValue = eventValue;
      }

      gtag("event", "media_preview_click", params);
    }
  };

  const renderVideoPlayer = () => {
    if (!videoDataForPlayer || videoDataForPlayer.videoUrls.length === 0) {
      return null;
    }

    return (
      <div 
        className={`video-wrapper${slideshowActive ? " active-slideshow" : ""}${slideshowPlaying ? " playing-slideshow" : ""}`} 
        style={{
          "background": videoLoading ? "unset" : "#000",
          "--plyr-player-visibility": videoLoading ? "hidden" : "visible",
          "--plyr-video-aspect-ratio" : videoDataForPlayer.maxWidth > 0 && videoDataForPlayer.maxHeight > 0 ? `${videoDataForPlayer.maxWidth} / ${videoDataForPlayer.maxHeight}` : "16 / 9",
          "--plyr-video-max-width": videoDataForPlayer.maxWidth > 0 && videoDataForPlayer.maxHeight > 0 ? `${videoDataForPlayer.maxWidth}px` : "inherit",
          "--plyr-video-max-height": videoDataForPlayer.maxWidth > 0 && videoDataForPlayer.maxHeight > 0 ? `${videoDataForPlayer.maxHeight}px` : "inherit",
          "--plyr-video-object-fit": videoDataForPlayer.maxWidth > 0 && videoDataForPlayer.maxHeight > 0 ? "contain" : "scale-down",
          "--plyr-video-pointer-events": isMobile && playerControlsHidden ? "unset" : "none"
        }}>
        <MediaPlayer
          playsInline={true}
          ref={ref} 
          title={videoDataForPlayer.name}
          src={videoDataForPlayer.videoUrls}
          poster={!autoPlay && videoDataForPlayer.thumbUrl !== "" ? videoDataForPlayer.thumbUrl : null}
          posterLoad="eager"
          autoPlay={autoPlay}
          load={autoPlay ? "visible" : "play"}
          streamType="on-demand"
          storage={storage}
          onEnded={onEndedVideoEvent}
          onStarted={onStartedVideoEvent}
          keyTarget="document"
          onPlay={onPlayVideoEvent}
          onPause={onPauseVideoEvent}
          fullscreenOrientation="none"
          onFullscreenChange={onFullscreenChangeVideoEvent}
          onMediaEnterFullscreenRequest={onEnterFullscreenRequest}
          //duration={videoDataForPlayer.duration}
          onQualityChange={onQualityChangeVideoEvent}
          onCanPlay={onCanPlayVideoEvent}
          onControlsChange={onControlsVisibilityChange}
          // logLevel="warn"
          artwork={thumbForMediaSessionAPI}
          onError={onErrorVideoEvent}
          onProviderSetup={onProviderSetupVideoEvent}
          onVolumeChange={onVolumeChangeVideoEvent}
          onSeeking={onSeekingVideoEvent}
          onRateChange={onRateChangeVideoEvent}
          // onSeeked={onSeekedVideoEvent}
          // onMediaSeekingRequest={onMediaSeekingRequestVideoEvent}
        >
          <MediaProvider />
          
          <PlyrLayout
            icons={videoPlayerIcons}
            displayDuration={true}
            viewType="video"
            controls={
              [
                'progress',
                'play',
                'play-large',
                'current-time',
                'pip',
                'airplay',
                'settings',
                'fullscreen'
              ]
            }
            slots={{
              timeSlider: <ProgressTimeSlider vidstackMediaPlayer={ref} onDraggingVideoProgress={onDraggingVideoProgress} noSwipeGesture={noSwipeGesture} />,
              afterCurrentTime: !isMobile ? <MuteAndVolumeMenu ref={volumeSliderRef} onVolumeSliderDragEnd={(value) => onVolumeChangeVideoEvent({volume: value / 100, muted: ref.current?.state.muted})} /> : null,
              beforeSettings: <GoogleChromeCastButton />,
              // Prevent auto quality option to be shown.
              settings: <SettingsMenu speed={[0.5, 1, 1.5, 2]} autoQualityText={false}/>,
              playLargeButton: <PlayLargeButton />,
              // afterPlayLargeButton: <SeekingLabel value={seekingLabel} />
            }}
          >
          </PlyrLayout>
        </MediaPlayer>
        {!autoPlay && videoDataForPlayer.thumbUrl !== "" ? <img src={videoDataForPlayer.thumbUrl} className="hidden-poster-for-error-event" onError={onErrorLoadingThumb} onLoad={onSuccessLoadingThumb} /> : null}
      </div>
    );
  };

  return renderVideoPlayer();
});

export default VideoContainer;