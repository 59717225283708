// @flow
import React from "react";
import styled, { keyframes } from "styled-components";
import { hiDPI } from "polished";

type Props = {
  total?: number,
  current?: number,
  message: string
};

const ProgressLoader = ({ current, total, message }: Props) => {
  return (
    <Wrapper>
      <Loader />
      <Message>{message}</Message>
      {current && total ? <Counter>{`${current} of ${total}`}</Counter> : null}
    </Wrapper>
  );
};

export default ProgressLoader;

const Spin = keyframes`
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`;

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background-color: #fff;
  z-index: 100000;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 21px;
`;

const Loader = styled.div`
  width: 64px;
  height: 64px;
  margin-bottom: 17px;
  animation-iteration-count: infinite;
  animation: ${Spin} 0.8s linear infinite;
  opacity: 1;

  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(${require("../../root/img/share-icons/loading.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../root/img/share-icons/loading@2x.png")});
  }
`;

const Counter = styled.div`
  font-size: 13px;
`;

const Message = styled.div`
  font-size: 13px;
`;
