// @flow

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import Modal, { OvalModalWrap, OvalModalCloseV2 } from "../../Modal";
import Button from "../../ButtonDefault";
import * as Style from "../styledComponents";
import InputTextarea from "../../InputTextarea";

import apiMethod from "../../../api/apiMethod";
import { __ } from "../../../lib/translate";

const RequestMoreSpaceModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState(
    __(
      "b_more_space_default_message",
      "Hello, pCloud Team, I would like to increase my account's space."
    )
  );
  const userEmail = useSelector(({ user }) => user.userinfo.email);

  useEffect(() => {
    setIsOpen(true);
  }, []);

  const handleClose = () => {
    setIsOpen(false);
  };

  const onMessageChange = ({ target }) => {
    setMessage(target.value);
  };

  const onEnter = (e: any) => {
    if (e.keyCode === 13) {
      onSendClick();
    }
  };

  const onSendClick = () => {
    const messageTrimed = message.trim();
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    if (messageTrimed.length < 1) {
      HFN.message(__("Message can't be empty"), "error");
      return;
    }

    apiMethod(
      "feedback",
      {
        mail: userEmail,
        reason: "Request for more space",
        message: messageTrimed,
        feedbacktype: "contactsales"
      },
      () => {
        handleClose();
        HFN.message(__("Your message was sent successfully!"));
      },
      {
        errorCallback: ({ error, result }) => {
          setIsLoading(false);
          let errorMessage = __("something_went_wrong_refresh_and_try_again");

          if (result === 2076) {
            errorMessage = __("error_2076", "You do not have the permissions for this action.");
          }

          HFN.message(errorMessage, "error");
          throw new Error(error);
        }
      }
    );
  };

  return (
    <Modal animate onClose={handleClose} opened={isOpen}>
      <OvalModalWrap>
        <OvalModalCloseV2 onClick={handleClose} />
        <Style.Container>
          <Style.Header>{__("Request More Space")}</Style.Header>
          <Style.Label>{__("Message")}</Style.Label>
          <InputTextarea
            style={{ width: "100%", height: "50px", padding: "10px", boxSizing: "border-box" }}
            name="message"
            onChange={onMessageChange}
            value={message}
            autoFocus
            onKeyDown={onEnter}
          />
          <Style.Footer>
            <Button
              color="lightgray4"
              style={{
                marginRight: "5px"
              }}
              onClick={handleClose}
            >
              {__("Cancel")}
            </Button>
            <Button
              color="cyan"
              loading={isLoading}
              style={{
                marginLeft: "5px"
              }}
              disabledColor="#dddddd"
              onClick={onSendClick}
            >
              {__("Send")}
            </Button>
          </Style.Footer>
        </Style.Container>
      </OvalModalWrap>
    </Modal>
  );
};

export default RequestMoreSpaceModal;
