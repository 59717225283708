import React, {useEffect, useRef} from 'react';
import usePlayerContext from "../hooks/usePlayerContext";
import {animateBars, useAnalyzerCheck} from "../helpers";
import styled, {keyframes} from "styled-components";

const WaveAnimation = (props) => {
    const {
        isPublic,
        currentSong,
        data,
        analyzerData,
        isWhiteBackground,
        isPlaying
    } = usePlayerContext();

    const useAnalyzer = useAnalyzerCheck(currentSong, isPublic, data);

    return (
        <WaveAnimationWrapper
            $width={props.width}
            $height={props.height}
        >
            {!useAnalyzer && (
                <SimulationWave
                    className={isPlaying ? 'animated' : ''}
                    $isWhiteBackground={isWhiteBackground}
                    $isPlaying={isPlaying}
                >
                    <SimulationWaveBar></SimulationWaveBar>
                    <SimulationWaveBar></SimulationWaveBar>
                    <SimulationWaveBar></SimulationWaveBar>
                    <SimulationWaveBar></SimulationWaveBar>
                    {!props.isMini && (
                        <>
                            <SimulationWaveBar></SimulationWaveBar>
                            <SimulationWaveBar></SimulationWaveBar>
                        </>
                    )}
                </SimulationWave>
            )}
            {analyzerData && useAnalyzer && <WaveForm analyzerData={analyzerData} isWhiteBackground={isWhiteBackground} {...props}/>}
        </WaveAnimationWrapper>
    );
}

const WaveForm = (props) => {
    const canvasRef = useRef(null);
    const { dataArray, analyzer, bufferLength } = props.analyzerData;

    let requestFrame;
    const height = props.height ?? 15;
    const width = props.width ?? 30;

    const draw = (dataArray, analyzer, bufferLength, isWhiteBackground) => {
        const canvas = canvasRef.current;
        if (!canvas || !analyzer) return;
        const canvasCtx = canvas.getContext("2d");

        const animate = () => {
            requestFrame = requestAnimationFrame(animate);
            canvas.width = canvas.width;
            animateBars(analyzer, canvas, canvasCtx, dataArray, bufferLength, isWhiteBackground);
        };

        animate();
    };

    useEffect(() => {
        draw(dataArray, analyzer, bufferLength, props.isWhiteBackground);

        return () => {
            if (requestFrame) {
                cancelAnimationFrame(requestFrame);
            }
        }
    }, [dataArray, analyzer, bufferLength, props.isWhiteBackground]);

    return (
        <canvas
            ref={canvasRef}
            width={width}
            height={height}
        />
    );
};

export default WaveAnimation;

const wave = keyframes`
    0% {
        height: 20%
    }
    50% {
        height: 100%
    }
    100% {
        height: 20%
    }
`;

const SimulationWave = styled.div`
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: end;
    box-sizing: border-box;
    & > div {
        background: ${({$isWhiteBackground}) => $isWhiteBackground ? '#017986' : '#17BED0'};
        height: ${({$isPlaying}) => $isPlaying ? '100%' : '3px'};
        box-sizing: border-box;
    }
    
    &.animated > div {
        height: 20%;
        animation: ${wave};
        animation-duration: 1.2s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
    }
    
    & > div:nth-child(1) { animation-delay: .3s }
    & > div:nth-child(2) { animation-delay: .2s }
    & > div:nth-child(3) { animation-delay: .4s }
    & > div:nth-child(4) { animation-delay: .6s }
    & > div:nth-child(5) { animation-delay: .1s }
    & > div:nth-child(6) { animation-delay: .2s }
`;
const SimulationWaveBar = styled.div`
    transition: all .3s linear;
    
    width: 3px;
    border-radius: 5px;
`;

const WaveAnimationWrapper = styled.div`
    width: ${({$width}) => $width ? $width+'px' : '33px'};
    height: ${({$height}) => $height ? $height+'px' : '15px'};
`;