export const galleryFooterConfig = {
  desktop: {
    slideshowOff: {
      image: {
        slideShow: true,
        showNavigation: false,
        navigationArrows: false,
        scale: true,
      }, 
      video: {
        slideShow: true,
        showNavigation: false,
        navigationArrows: false,
        scale: false,
      }
    },
    slideshowPlaying: {
      image: {
        slideShow: true,
        showNavigation: false,
        navigationArrows: false,
        scale: false,
      }, 
      video: {
        slideShow: true,
        showNavigation: false,
        navigationArrows: false,
        scale: false,
      }
    },
    slideshowPaused: {
      image: {
        slideShow: true,
        showNavigation: false,
        navigationArrows: false,
        scale: false,
      }, 
      video: {
        slideShow: true,
        showNavigation: false,
        navigationArrows: false,
        scale: false,
      }
    }
  },
  mobile: {
    slideshowOff: {
      image: {
        slideShow: false,
        showNavigation: true,
        navigationArrows: true,
        scale: false,
      }, 
      video: {
        slideShow: false,
        showNavigation: true,
        navigationArrows: true,
        scale: false,
      }
    },
    slideshowPlaying: {
      image: {
        slideShow: true,
        showNavigation: false,
        navigationArrows: true,
        scale: false,
      }, 
      video: {
        slideShow: true,
        showNavigation: false,
        navigationArrows: false,
        scale: false,
      }
    },
    slideshowPaused: {
      image: {
        slideShow: true,
        showNavigation: false,
        navigationArrows: false,
        scale: false,
      }, 
      video: {
        slideShow: true,
        showNavigation: false,
        navigationArrows: false,
        scale: false,
      }
    }
  }
};