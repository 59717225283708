// @flow

import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";

import Modal, { OvalModalWrap, CloseModalIcon } from "../Modal";
import Button from "../ButtonDefault";
import * as Style from "./styledComponents";
import FileIcon from "../FileIcon";

import { __ } from "../../lib/translate";
import { errorKeys } from "../../lib/errors";
import apiMethod from "../../api/apiMethod";
import { ICONS } from "../../lib/icons";

const RevertRevisionModal = ({ data = {} }: { data: Array<any> }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const { id, icon, fileid, revisionid, name } = data;

  useEffect(() => {
    if (isOpen) {
      console.log("ADD event on keyup RevertRevisionModal");
      document.addEventListener("keyup", onEnter);
    } else {
      console.log("REMOVE event on keyup RevertRevisionModal");
      document.removeEventListener("keyup", onEnter);
    }
  }, [isOpen, onEnter]); //[isOpen, onEnter]

  const handleClose = () => {
    setIsOpen(false);
  };

  const onEnter = useCallback(
    (e: any) => {
      if (e.keyCode === 13) {
        revertRevision();
      }
    },
    [revertRevision]
  );

  const revertRevision = useCallback(() => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    apiMethod(
      "revertrevision",
      { fileid: fileid, revisionid: revisionid },
      ({ result, error }) => {
        HFN.cache.expire("api-listrevisions-fileid:" + fileid);
        if ($.bbq.getState("page") == "revisions") $.bbq.pushState({ refresh: uniqueNum.get() });

        setIsOpen(false);
        HFN.message(
            i18n.get("File") + " <u>" + name + "</u> " + i18n.get("reverted to revision:") + " " + revisionid,
            null,
            null,
            null,
            null,
            null,
            null,
            {
              linkConverter: false,
              linkConverterV2: false
            }
        );
      },
      {
        errorCallback: ({ result, error }) => {
          if (errorKeys[result]) {
            HFN.message(__(errorKeys[result]), "error");
          } else {
            console.log("ERROR", { result, error });
            HFN.message(__("something_went_wrong_refresh_and_try_again"), "error");
          }
          setIsLoading(false);
        },
        onXhrError: (xhr, status, error) => {
          HFN.message(__("something_went_wrong_refresh_and_try_again"), "error");
          setIsLoading(false);
        }
      }
    );
  });

  return (
    <Modal animate onClose={handleClose} opened={isOpen}>
      <OvalModalWrap>
        <CloseModalIcon onClick={handleClose} />
        <Style.Container>
          <Style.Header>{__("revert_revision", "Revert Revision")}</Style.Header>
          <Style.Label>{__("revert_file", "Revert File")}</Style.Label>
          <BoxRevision style={{ marginBottom: "15px" }}>
            <Style.Row key={id}>
              <FileIcon item={icon} type={ICONS.LIST_SMALL} metadata={data} />
              <Style.NameEllipsis>{name}</Style.NameEllipsis>
            </Style.Row>
          </BoxRevision>
          <Style.Label>{__("to_revision", "To Revision")}</Style.Label>
          <BoxRevision>
            <Style.NameEllipsis>{revisionid}</Style.NameEllipsis>
          </BoxRevision>
          <Style.Footer>
            <Button
              color="lightgray4"
              style={{
                marginRight: "5px"
              }}
              onClick={handleClose}
            >
              {__("Close")}
            </Button>
            <Button
              color="cyan"
              style={{
                marginLeft: "5px"
              }}
              onClick={revertRevision}
              disabled={isLoading}
              loading={isLoading}
            >
              {__("Revert")}
            </Button>
          </Style.Footer>
        </Style.Container>
      </OvalModalWrap>
    </Modal>
  );
};

export default RevertRevisionModal;

const BoxRevision = styled(Style.Box)`
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 10px;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  text-align: left;

  & > div:only-child {
    padding-bottom: 0px;
  }
`;
