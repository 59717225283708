import styled from "styled-components";

export const LinkWrapper = styled.div`
  height: 36px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 2.9px;
  border: solid 1px #60d2df;
  background-color: #ffffff;
  padding: 10px 15px;
  box-sizing: border-box;

  &[disabled] {
    border: solid 1px #c1c1c1;
    background-color: #fafafa;
  }
`;

export const LinkInput = styled.input`
  display: flex;
  flex: 1;
  outline: none;
  border: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-family: "Roboto", sans-serif;
  ${props => {
    if (props.disabled) {
      return `
        background: none;
        color: #000000 !important;
      `;
    }
  }}
  transition: all 200ms ease-in-out;
`;

export const CopyLink = styled.div`
  margin-left: 10px;
  cursor: ${props => (props.disabled ? "default" : "pointer")};
  font-size: 13px;
  font-weight: 500;
  color: ${props => (props.disabled ? "#c1c1c1" : "#17bed0")};
  transition: all 200ms ease-in-out;
`;

export const SettingsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fafafa;
  border-top: 1px solid #f2f2f2;
  font-family: "Roboto", sans-serif;
  position: absolute;
  top: 126px;
  transition: all 1000ms ease-in-out;
  min-height: ${props => (props.overlayHeader ? "100%" : props.bodyHeight + 50 + "px")};
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;
  border-top-left-radius: ${props => (props.overlayHeader ? "18px" : "0px")};
  border-top-right-radius: ${props => (props.overlayHeader ? "18px" : "0px")};
  padding: 40px 20px;
  box-sizing: border-box;
  z-index: 40;
  padding-top: ${props => (props.topOffest ? props.topOffest : 40)}px;

  &.share-publink .stats {
    width: 100%;
    height: 100%;
    min-height: 294px;

    .linkstats-wrap {
      background-color: #ffffff;
      margin-top: 36px;

      .linkstats {
        margin-top: 40px;

        .overview .summary .summary-wrap {
          bottom: auto;
          top: -56px;

          .summary-text {
            background-color: #eeeeee;
            border: 1px solid #e1e1e1;

            .next.inactive {
              opacity: 0.2;
              background-color: transparent;
              cursor: default;
            }
          }
        }
      }
    }
  }
`;

export const SettingsHeader = styled.div`
  max-width: 428px;
`;

export const SettingsTitle = styled.h1`
  margin: ${props => (props.margin ? "34px" : "0")};
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  letter-spacing: -0.18px;
  text-align: center;
  color: #000000;
`;

export const FileRequestSettingsTitle = styled(SettingsTitle)`
  margin-top: 35px;
`;

export const SettingsDescription = styled.p`
  font-size: 13px;
  line-height: 1.23;
  letter-spacing: -0.2px;
  text-align: center;
  color: #888888;
  margin-top: 8px;
`;

export const FileRequestSettingsDescription = styled(SettingsDescription)`
  font-size: 15px;
`;

export const SettingOptionsContainer = styled.div`
  width: 328px;
  margin: 32px auto 0px auto;
  position: relative;

  &.limit {
    display: flex;
    justify-content: center;
  }

  ${props => {
    if (props.disabledFeature) {
      return `
        &::after {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0px;
          left: 0px;
          z-index: 101;
          background-color: #ffffff;
          opacity: 0.5;
        }
      `;
    }
  }}
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
`;

export const ToggleLabel = styled.label`
  padding: 0px;
  margin-left: 10px;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.23;
  letter-spacing: -0.13px;
  text-align: left;
  color: #000000;
`;

export const Error = styled.p`
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: -0.11px;
  text-align: left;
  color: #ff2626;
  margin: 12px 0px;
`;

export const FadeAnimationContainer = styled.div`
  .fade-enter {
    opacity: 0;
  }
  .fade-exit {
    opacity: 1;
  }
  .fade-enter-active {
    opacity: 1;
  }
  .fade-exit-active {
    opacity: 0;
  }
  .fade-enter-active,
  .fade-exit-active {
    transition: opacity 200ms ease-in-out;
  }
`;

export const SlideAnimationWrapper = styled.div`
  .slide-enter {
    transform: translateX(100%);
  }
  .slide-exit {
    transform: translateX(0);
  }
  .slide-enter-active {
    transform: translateX(0);
  }
  .slide-exit-active {
    transform: translateX(100%);
  }
  .slide-enter-active,
  .slide-exit-active {
    transition: transform 500ms ease-in-out;
  }

  /* .slideunder-enter {
    opacity: 0.5;
    transform: translateX(-100px);
  }
  .slideunder-exit {
    opacity: 1;
    transform: translateX(0);
  }
  .slideunder-enter-active {
    opacity: 1;
    transform: translateX(0);
  }
  .slideunder-exit-active {
    opacity: 0.5;
    transform: translateX(-100px);
  }
  .slideunder-enter-active,
  .slideunder-exit-active {
    transition: all 500ms ease-in-out;
  } */
`;
