//  @flow

import React from "react";

import { __ } from "../../../lib/translate";
import { Table, BodyTable, HeaderTable, DescriptionTable, ButtonLink } from "./StyledComponents";

const ProFormaInvoices = () => {
  const onEmailClick = () => {
    window.location = "mailto:sales@pcloud.com";
  };

  return (
    <React.Fragment>
      <Table>
        <HeaderTable>{__("Pro Forma Invoices:")}</HeaderTable>
        <BodyTable>
          <DescriptionTable>
            {__(
              "business_proforma_description",
              "Need a preliminary bill of sale? Request a proforma invoice."
            )}
          </DescriptionTable>
          <ButtonLink onClick={onEmailClick}>sales@pcloud.com</ButtonLink>
        </BodyTable>
      </Table>
    </React.Fragment>
  );
};

export default ProFormaInvoices;
