import React from 'react';
import SongThumb from "../../../userinterface/SongThumb";
import usePlayerContext from "../../../hooks/usePlayerContext";
import {
    BoxQueueButtonWrapper,
    BoxQueueControlsRemoveWrapper,
    BoxQueueControlsWrapper,
    BoxQueueListItemWrapper, BoxQueueSongArtist, BoxQueueSongArtistSkeleton,
    BoxQueueSongDetailsWrapper,
    BoxQueueSongName, BoxQueueSongNameSkeleton
} from "../../../userinterface/styles";
import {QUEUE_THUMB_SIZE} from "../../../constants";
import SwipeItemContainer from "./SwipeItemContainer";
import LongTextAnimationContainer from "../../../../LongTextAnimationContainer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose} from '@fortawesome/pro-regular-svg-icons/faClose';
import {faBars} from '@fortawesome/pro-regular-svg-icons/faBars';
import {getPlayerState} from "../../../helpers";

function getStyle({ provided, style, isDragging }) {
    const combined = {
        ...style,
        ...provided.draggableProps.style
    };

    const marginBottom = 0;
    return {
        ...combined,
        height: isDragging ? combined.height : combined.height - marginBottom,
        marginBottom
    };
}

function Item(props) {
    const {
        provided,
        item,
        style,
        isDragging
    } = props;

    const {
        currentSong,
        onSelectSong,
        onRemoveFromQueue,
        isMobile,
        additionalClass,
        playerVariant
    } = usePlayerContext();

    if (item === undefined) {
        return null;
    }

    const isActive = item.fileid === currentSong.fileid;

    return (
        <BoxQueueListItemWrapper
            {...provided.draggableProps}
            ref={provided.innerRef}
            style={getStyle({ provided, style, isDragging })}
            className={`item ${isDragging ? "is-dragging" : ""}`}
        >
            <SwipeItemContainer
                isActive={isActive}
                isDragging={isDragging}
                id={item.fileid}
            >
                <SongThumb
                    songFileId={item.fileid}
                    width={QUEUE_THUMB_SIZE}
                    height={QUEUE_THUMB_SIZE}
                    isQueue={true}
                    isActive={isActive}
                    disableAnimation={true}
                    onClick={() => {
                        onSelectSong(item.fileid)
                    }}
                />
                <BoxQueueSongDetailsWrapper
                    $activeSong={isActive}
                    onClick={() => {
                        onSelectSong(item.fileid)
                    }}
                >
                    {item.hasOwnProperty('name') && (
                        <>
                            <BoxQueueSongName>
                                <LongTextAnimationContainer animateOnHover={true}>
                                    {item.title ?? item.name}
                                </LongTextAnimationContainer>
                            </BoxQueueSongName>
                            {!!item?.artist && item.artist !== '' && (
                                <BoxQueueSongArtist>
                                    <LongTextAnimationContainer animateOnHover={true}>
                                        {item.artist}
                                    </LongTextAnimationContainer>
                                </BoxQueueSongArtist>
                            )}
                        </>
                    )}
                    {!item.hasOwnProperty('name') && (
                        <>
                            <BoxQueueSongNameSkeleton />
                            <BoxQueueSongArtistSkeleton />
                        </>
                    )}
                </BoxQueueSongDetailsWrapper>
                <BoxQueueControlsWrapper>
                    {!isMobile && (
                        <BoxQueueControlsRemoveWrapper className="remove-item">
                            <BoxQueueButtonWrapper
                                onClick={(e) => {
                                    onRemoveFromQueue(e, item.fileid);

                                    if (typeof gtag === "function") {
                                        gtag("event", "audio_player_click", {
                                            action: "remove song from queue",
                                            state: getPlayerState(playerVariant)
                                        });
                                    }
                                }}
                            >
                                <FontAwesomeIcon style={{fontSize: 12, color: '#7A8D8F'}} icon={faClose} />
                            </BoxQueueButtonWrapper>
                        </BoxQueueControlsRemoveWrapper>
                    )}
                    <BoxQueueButtonWrapper
                        className={additionalClass + ' drag-item'}
                        {...provided.dragHandleProps}
                    >
                        <FontAwesomeIcon style={{fontSize: 12, color: '#7A8D8F'}} icon={faBars} />
                    </BoxQueueButtonWrapper>
                </BoxQueueControlsWrapper>
            </SwipeItemContainer>
        </BoxQueueListItemWrapper>
    );
}

export default Item;
