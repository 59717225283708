import React, {useState} from 'react';
import { __ } from "../../../lib/translate";
import styled from "styled-components";
import OpenInApp from "../../OpenInApp";
import usePlayerContext from "../hooks/usePlayerContext";

const OpenAppButton = () => {
    const {
        showOpenInAppButton,
        setShowOpenInAppButton
    } = usePlayerContext();

    const handleOnClick = () => {
        window.location = "https://www.pcloud.com/app-open.html"
    }

    const handleClose = (e) => {
        e.stopPropagation();
        setShowOpenInAppButton(false);
    }
    
    if (!showOpenInAppButton) {
        return null;
    }

    return (
        <Wrap>
            <OpenInApp onClose={handleClose} onOpen={handleOnClick} />
        </Wrap>
    )
};

export default OpenAppButton;

const Wrap = styled.div`
    text-align: center;
    width: 300px;
`;