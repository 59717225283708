// @flow

import * as React from "react";
import styled from "styled-components";
import { hiDPI, clearFix } from "polished";

import { TSpan, TDiv } from "../../components/intl";
import Button from "../../components/SubmitButton";

const PublicFolderFeatures = () =>
  <Wrap>
    <Title id="public-usage-title">You can use public folder for:</Title>
    <Boxes>
      <Box>
        <SiteImage />
        <BoxTitle id="dl_title_static">Static HTML website</BoxTitle>
        <BoxDescription id="dl_description_static">
          Simply put HTML-formatted text files and linked graphics in the Public Folder (or in a sub-folder). Click
          'Share' and 'Copy Link' on the main directory, where your site's resources are. Open the link and there you
          have it - your very own website, hosted in pCloud!
        </BoxDescription>
      </Box>
      <Box>
        <ImagesImage />
        <BoxTitle id="dl_title_embed">Embed Images</BoxTitle>
        <BoxDescription id="dl_description_embed">
          Тo embed some images in your existing website or a forum, email signature, etc., click 'Share' for the
          selected image and then 'Embed'. An embed code for the image will be copied to your clipboard.
        </BoxDescription>
      </Box>
      <Box>
        <LinkImage />
        <BoxTitle id="dl_title_links">Direct links to files</BoxTitle>
        <BoxDescription id="dl_description_links">
          You can get a direct link to any file in the Public Folder by selecting 'Share'>'Get Link'. Upon clicking a
          direct link to a file, your browser will download it or preview it, depending on the file type.
        </BoxDescription>
      </Box>
    </Boxes>
  </Wrap>;

export default PublicFolderFeatures;

const Wrap = styled.div`
  ${clearFix()}
  margin: 40px auto 0 auto;
  padding-top: 40px;
  width: 980px;
  border-top: 1px solid #f0f0f0;
`;

const Title = styled(TDiv)`
  color: #999999;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
`;

const Boxes = styled.div`margin-top: 100px;`;

const Box = styled.div`
  width: 310px;
  height: 310px;
  border: 1px solid #f0f0f0;
  box-sizing: border-box;
  background: #fafafa;
  float: left;
  margin-left: 25px;

  &:first-child {
    margin-left: 0;
  }
`;

const BoxImage = styled.div`
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 216px 153px;
  width: 216px;
  height: 153px;
  margin: -60px auto 0 auto;
`;

const SiteImage = styled(BoxImage)`
  background-image: url(${require("../../../root/img/publicfolder/site.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/publicfolder/site@2x.png")});
  }
`;

const LinkImage = styled(BoxImage)`
  background-image: url(${require("../../../root/img/publicfolder/link.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/publicfolder/link@2x.png")});
  }
`;

const ImagesImage = styled(BoxImage)`
  background-image: url(${require("../../../root/img/publicfolder/images.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/publicfolder/images@2x.png")});
  }
`;

const BoxTitle = styled(TDiv)`
  text-align: center;
  font-weight: bold;
  margin-bottom: 20px;
  margin-top: 25px;
  font-size: 15px;
`;

const BoxDescription = styled(TDiv)`
  font-size: 12px;
  line-height: 20px;
  text-align: justify;
  margin: 0 30px 0 30px;
`;
