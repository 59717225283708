// @flow

import React, { Component } from "react";
import update from "immutability-helper";
import styled from "styled-components";
import { connect } from "react-redux";

import { TSpan } from "../components/intl";
import SubmitButton from "../components/SubmitButton";
import SubscriptionsSettings from "../components/SubscriptionsSettings";
import apiMethod from "../api/apiMethod";

import { __ } from "../lib/translate";

import type { NotificationType } from "../components/SubscriptionsSettings";
import type { UserState } from "../lib/state/reducers/user";

type SubscriptionsProps = {
  user: UserState,
  onSettingsSaved: number => void
};

type SubscriptionsState = {
  types: Array<NotificationType>,
  isSaving: boolean,
  hasChange: boolean
};

class SubscriptionsSettingsContainer extends Component<SubscriptionsProps, SubscriptionsState> {
  xhr: any;

  constructor(props: SubscriptionsProps) {
    super(props);

    this.state = { types: [], isSaving: false, hasChange: false };

    (this: any)._update = this._update.bind(this);
    (this: any)._save = this._save.bind(this);
  }

  componentDidMount() {
    if (this.props.user.logged) {
      this.xhr = apiMethod("listnotificationsettings", { auth: this.props.user.token }, ret =>
        this.setState({ types: ret.types })
      );
    }
  }

  componentWillUnmount() {
    if (this.xhr) {
      this.xhr.abort();
    }
  }

  _update(flag: number, status: boolean) {
    const { types } = this.state;
    const edit = types.find(type => type.flag === flag);

    if (edit) {
      const index = types.indexOf(edit);
      const newTypes = update(types, {
        [index]: { subscribed: { $set: status } }
      });

      this.setState({ types: newTypes, hasChange: true });
    }
  }

  _settingAccum(): number {
    const { types } = this.state;

    return types.reduce((acc, { subscribed, flag }) => (acc += subscribed ? flag : 0), 0);
  }

  _save() {
    const { user, onSettingsSaved } = this.props;
    const setting = this._settingAccum();

    if (user.logged) {
      this.xhr = apiMethod(
        "changenotificationsettings",
        { setting: setting, auth: user.token },
        () => {
          this.setState({ isSaving: false });
          onSettingsSaved(setting);
        },
        { errorCallback: ret => console.error(ret) }
      );

      this.setState({ isSaving: true, hasChange: false });
    }
  }

  render() {
    const { types, isSaving, hasChange } = this.state;

    if (types.length) {
      return (
        <div>
          <h3>
            <TSpan id="mail_groups">Choose what emails and push notifications to receive:</TSpan>
          </h3>
          <Wrap>
            <SubscriptionsSettings types={types} update={this._update} />
          </Wrap>

          <SubmitButton onClick={this._save} loading={isSaving} active={!isSaving && hasChange}>
            {__("Save")}
          </SubmitButton>
        </div>
      );
    } else {
      return (
        <div>
          <h3>
            <TSpan id="mail_groups">Mail Groups</TSpan>
          </h3>
          <Wrap>
            <div className="loading-wrap" />
          </Wrap>
        </div>
      );
    }
  }
}

export default connect(state => ({ user: state.user }))(SubscriptionsSettingsContainer);

const MailGroups = styled.div`
  color: #bebebe;
  margin: 5px 0 15px 5px;
  font-size: 14px;
`;

const Wrap = styled.div`
  background: #fafafa;
  border-top: 2px solid #ddd;
  border-bottom: 1px solid #e4e4e4;
  padding: 10px 5px 10px 5px;
  margin-bottom: 10px;
`;
