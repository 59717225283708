// @flow

import React, { useState, useEffect, useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import Modal, { OvalModalWrap, CloseModalIcon } from "../Modal";
import Button from "../ButtonDefault";
import * as Style from "./styledComponents";
import FileIcon from "../FileIcon";

import apiConfigDownloadLinks from "../../api/downloadLinksConfig";
import apiConfig from "../../api/config";
import { __ } from "../../lib/translate";
import { getIconType } from "../../lib/utils";

const DownloadArchiveModal = ({
  method,
  filename = '',
  code = '',
  linkpassword = '',
  data = {},
  ars,
  tm_key = ""
}: {
  data: any,
  ars: any,
  tm_key: string
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const token = useSelector(({ user }) => user.token);
  const formRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      console.log("ADD event on keyup DownloadArchiveModal");
      document.addEventListener("keyup", onEnter);
    } else {
      console.log("REMOVE event on keyup DownloadArchiveModal");
      document.removeEventListener("keyup", onEnter);
    }
  }, [isOpen]);

  const handleClose = () => {
    setIsOpen(false);
  };

  const onEnter = useCallback(
    (e: any) => {
      if (e.keyCode === 13) {
        downloadArchive();
      }
    },
    [downloadArchive]
  );

  const downloadArchive = useCallback(e => {
    e.preventDefault();
    if (isLoading) {
      return;
    }

    setIsLoading(true);
    formRef.current.submit();
    handleClose();
  });

  const renderRow = item => {
    const iconName = getIconType(item);

    return (
      <Row key={item.id}>
        <FileIcon item={iconName} type={HFN.ICONS.LIST_SMALL} metadata={item} />
        <Style.NameEllipsis>{item.name}</Style.NameEllipsis>
      </Row>
    );
  };
  console.log("apiMethodUrl", formRef);
  return (
    <Modal animate onClose={handleClose} opened={isOpen}>
      <OvalModalWrap>
        <CloseModalIcon onClick={handleClose} />
        <Style.ContainerForm
          ref={formRef}
          action={`https://${(HFN.config.isDlink() ? apiConfigDownloadLinks : apiConfig).apiServer}/${method}`}
          method="post"
          target="_blank"
        >
          <Style.Header>{__("Download Archive")}</Style.Header>
          <Style.Label>{__("Files in archive")}</Style.Label>
          <BoxTeam>{data.map(renderRow)}</BoxTeam>
          <Style.Message></Style.Message>
          <input type="hidden" name="auth" value={token} />
          <input type="hidden" name="filename" value={filename} />
          {tm_key ? <input type="hidden" name="tmkey" value={tm_key} /> : null}
          {code ? <input type="hidden" name="code" value={code} /> : null}
          {linkpassword ? <input type="hidden" name="linkpassword" value={linkpassword} /> : null}
          {ars.fileids ? (
            <input type="hidden" name="fileids" value={ars.fileids.join(",")} />
          ) : null}
          {ars.folderids ? (
            <input type="hidden" name="folderids" value={ars.folderids.join(",")} />
          ) : null}
          <Style.Footer>
            <Button
              color="lightgray4"
              style={{
                marginRight: "5px"
              }}
              onClick={handleClose}
            >
              {__("Close")}
            </Button>
            <Button
              color="cyan"
              style={{
                marginLeft: "5px"
              }}
              onClick={downloadArchive}
            >
              {__("Download")}
            </Button>
          </Style.Footer>
        </Style.ContainerForm>
      </OvalModalWrap>
    </Modal>
  );
};

export default DownloadArchiveModal;

const BoxTeam = styled(Style.Box)`
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 10px;
  font-size: 15px;
  max-height: 193px;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;

    /* width */
    &::-webkit-scrollbar {
    width: 5px;
    border-radius: 3px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #eaeaea;
    border-radius: 3px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #aaa;
    border-radius: 3px;
    transition: background 200ms ease-in-out;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
  min-height: 30px;
`;
