import {
	PREMIUM_PLAN_ID,
	PREMIUM_PLUS_PLAN_ID,
	CUSTOM4TB_PLAN_ID,
	FAMILY2TB_PLAN_ID,
	FAMILY_CUSTOM_2COUNT_PLAN_ID,
	FAMILY_CUSTOM_5COUNT_PLAN_ID,
	FAMILY_ADD_1COUNT_PLAN_ID,
	FAMILY_ADD_2COUNT_PLAN_ID,
	FAMILY_ADD_5COUNT_PLAN_ID,
	ADD_STORAGE_1COUNT_PLAN_ID,
	ADD_STORAGE_4COUNT_PLAN_ID,
	ADD_STORAGE_8COUNT_PLAN_ID,
	ADD_STORAGE_12COUNT_PLAN_ID,
	ADD_STORAGE_20COUNT_PLAN_ID,
	CUSTOM_20COUNT_PLAN_ID,
	CUSTOM_8COUNT_PLAN_ID,
	CRYPTO_PLAN_ID,
	EFH_PLAN_ID,
	RELOCATION_ID,
	PASS_PREMIUM_PLAN_ID,
	PASS_FAMILY_PLAN_ID,
	ADDITIONAL_TRAFFIC_ID,
	BUSINESS_PLAN_ID,
	BUSINESS_PLAN_2_ID
} from "./constants";

export const currencyPrices = {
	[PREMIUM_PLAN_ID]: {
		'$': {
			'month': 4.99,
			'month-total': 59.88,
			'year': 4.16,
			'year-total': 49.99,
			'lifetime': 199,
			'lifetime_discount': 299
		},
		'€': {
			'month': 4.99,
			'month-total': 59.88,
			'year': 4.16,
			'year-total': 49.99,
			'lifetime': 199,
			'lifetime_discount': 299
		},
		'₤': {
			'month': 4.99,
			'month-total': 59.88,
			'year': 4.16,
			'year-total': 49.99,
			'lifetime': 199,
			'lifetime_discount': 299
		}
	},
	[PREMIUM_PLUS_PLAN_ID]: {
		'$': {
			'month': 9.99,
			'month-total': 119.88,
			'year': 8.33,
			'year-total': 99.99,
			'lifetime': 399,
			'lifetime_discount': 599
		},
		'€': {
			'month': 9.99,
			'month-total': 119.88,
			'year': 8.33,
			'year-total': 99.99,
			'lifetime': 399,
			'lifetime_discount': 599
		},
		'₤': {
			'month': 9.99,
			'month-total': 119.88,
			'year': 8.33,
			'year-total': 99.99,
			'lifetime': 399,
			'lifetime_discount': 599
		}
	},
	[CUSTOM4TB_PLAN_ID]: {
		'$': {
			'lifetime': 800,
			'lifetime_discount': 2300,
			'month': 9.99,
			'month-total': 119.88,
			'year': 8.33,
			'year-total': 99.99,
		},
		'€': {
			'lifetime': 800,
			'lifetime_discount': 2300,
			'month': 9.99,
			'month-total': 119.88,
			'year': 8.33,
			'year-total': 99.99,
		},
		'₤': {
			'lifetime': 800,
			'lifetime_discount': 2300,
			'month': 9.99,
			'month-total': 119.88,
			'year': 8.33,
			'year-total': 99.99,
		}
	},
	[FAMILY2TB_PLAN_ID]: {
		'$': {
			'lifetime': 595,
			'lifetime_discount': 1700
		},
		'€': {
			'lifetime': 595,
			'lifetime_discount': 1700
		},
		'₤': {
			'lifetime': 595,
			'lifetime_discount': 1700
		}
	},
	[FAMILY_CUSTOM_2COUNT_PLAN_ID]: {
		'$': {
			'lifetime': 1200,
			'lifetime_discount': 3400
		},
		'€': {
			'lifetime': 1200,
			'lifetime_discount': 3400
		},
		'₤': {
			'lifetime': 1200,
			'lifetime_discount': 3400
		}
	},
	[FAMILY_CUSTOM_5COUNT_PLAN_ID]: {
		'$': {
			'lifetime': 1499,
			'lifetime_discount': 7600
		},
		'€': {
			'lifetime': 1499,
			'lifetime_discount': 7600
		},
		'₤': {
			'lifetime': 1499,
			'lifetime_discount': 7600
		}
	},
	[FAMILY_ADD_1COUNT_PLAN_ID]: {
		'$': {
			'lifetime': 595,
			'lifetime_discount': 1700
		},
		'€': {
			'lifetime': 595,
			'lifetime_discount': 1700
		},
		'₤': {
			'lifetime': 595,
			'lifetime_discount': 1700
		}
	},
	[FAMILY_ADD_2COUNT_PLAN_ID]: {
		'$': {
			'lifetime': 1200,
			'lifetime_discount': 3400
		},
		'€': {
			'lifetime': 1200,
			'lifetime_discount': 3400
		},
		'₤': {
			'lifetime': 1200,
			'lifetime_discount': 3400
		}
	},
	[FAMILY_ADD_5COUNT_PLAN_ID]: {
		'$': {
			'lifetime': 1499,
			'lifetime_discount': 7600
		},
		'€': {
			'lifetime': 1499,
			'lifetime_discount': 7600
		},
		'₤': {
			'lifetime': 1499,
			'lifetime_discount': 7600
		}
	},
	[CRYPTO_PLAN_ID]: {
		'$': {
			'month': 4.99,
			'month-total': 59.88,
			'year': 4.16,
			'year-total': 49.99,
			'lifetime': 150,
			'lifetime_discount': 229
		},
		'€': {
			'month': 4.99,
			'month-total': 59.88,
			'year': 4.16,
			'year-total': 49.99,
			'lifetime': 150,
			'lifetime_discount': 229
		},
		'₤': {
			'month': 4.99,
			'month-total': 59.88,
			'year': 4.16,
			'year-total': 49.99,
			'lifetime': 150,
			'lifetime_discount': 229
		}
	},
	[EFH_PLAN_ID]: {
		'$': {
			'month': 7.99,
			'month-total': 95.88,
			'year': 6.58,
			'year-total': 79,
			'lifetime': 0,
			'lifetime_discount': 0
		},
		'€': {
			'month': 7.99,
			'month-total': 95.88,
			'year': 6.58,
			'year-total': 79,
			'lifetime': 0,
			'lifetime_discount': 0
		},
		'₤': {
			'month': 7.99,
			'month-total': 95.88,
			'year': 6.58,
			'year-total': 79,
			'lifetime': 0,
			'lifetime_discount': 0
		}
	},
	[ADDITIONAL_TRAFFIC_ID]: {
		'$': {
			'month': 9.99,
			'month-total': 119.88,
			'year': 8.33,
			'year-total': 99.99,
			'lifetime': 350,
			'lifetime_discount': 980
		},
		'€': {
			'month': 9.99,
			'month-total': 119.88,
			'year': 8.33,
			'year-total': 99.99,
			'lifetime': 350,
			'lifetime_discount': 980
		},
		'₤': {
			'month': 9.99,
			'month-total': 119.88,
			'year': 8.33,
			'year-total': 99.99,
			'lifetime': 350,
			'lifetime_discount': 980
		}
	},
	[ADD_STORAGE_1COUNT_PLAN_ID]: {
		'$': {
			'lifetime': 199,
			'lifetime_discount': 299
		},
		'€': {
			'lifetime': 199,
			'lifetime_discount': 299
		},
		'₤': {
			'lifetime': 199,
			'lifetime_discount': 299
		}
	},
	[ADD_STORAGE_4COUNT_PLAN_ID]: {
		'$': {
			'lifetime': 399,
			'lifetime_discount': 599
		},
		'€': {
			'lifetime': 399,
			'lifetime_discount': 599
		},
		'₤': {
			'lifetime': 399,
			'lifetime_discount': 599
		}
	},
	[ADD_STORAGE_8COUNT_PLAN_ID]: {
		'$': {
			'lifetime': 800,
			'lifetime_discount': 2280
		},
		'€': {
			'lifetime': 800,
			'lifetime_discount': 2280
		},
		'₤': {
			'lifetime': 800,
			'lifetime_discount': 2280
		}
	},
	[ADD_STORAGE_12COUNT_PLAN_ID]: {
		'$': {
			'lifetime': 1200,
			'lifetime_discount': 3420
		},
		'€': {
			'lifetime': 1200,
			'lifetime_discount': 3420
		},
		'₤': {
			'lifetime': 1200,
			'lifetime_discount': 3420
		}
	},
	[ADD_STORAGE_20COUNT_PLAN_ID]: {
		'$': {
			'lifetime': 1200,
			'lifetime_discount': 6000
		},
		'€': {
			'lifetime': 1200,
			'lifetime_discount': 6000
		},
		'₤': {
			'lifetime': 1200,
			'lifetime_discount': 6000
		}
	},
	[CUSTOM_8COUNT_PLAN_ID]: {
		'$': {
			'lifetime': 800,
			'lifetime_discount': 2280
		},
		'€': {
			'lifetime': 800,
			'lifetime_discount': 2280
		},
		'₤': {
			'lifetime': 800,
			'lifetime_discount': 2280
		}
	},
	[CUSTOM_20COUNT_PLAN_ID]: {
		'$': {
			'lifetime': 1190,
			'lifetime_discount': 6000
		},
		'€': {
			'lifetime': 1190,
			'lifetime_discount': 6000
		},
		'₤': {
			'lifetime': 1190,
			'lifetime_discount': 6000
		}
	},
	[RELOCATION_ID]: {
		'$': {
			'onetime': 19.99
		},
		'€': {
			'onetime': 19.99
		},
		'₤': {
			'onetime': 19.99
		}
	},
	[PASS_PREMIUM_PLAN_ID]: {
		'$': {
			'trial': 0.00,
			'month': 2.99,
			'month-total': 35.88,
			'year': 2.42,
			'year-total': 29,
			'lifetime': 149,
			'lifetime_discount': 595
		},
		'€': {
			'trial': 0.00,
			'month': 2.99,
			'month-total': 35.88,
			'year': 2.42,
			'year-total': 29,
			'lifetime': 149,
			'lifetime_discount': 595
		},
		'₤': {
			'trial': 0.00,
			'month': 2.99,
			'month-total': 35.88,
			'year': 2.42,
			'year-total': 29,
			'lifetime': 149,
			'lifetime_discount': 595
		}
	},
	[PASS_FAMILY_PLAN_ID]: {
		'$': {
			'trial': 0.00,
			'month': 4.99,
			'month-total': 59.88,
			'year': 4.08,
			'year-total': 49,
			'lifetime': 252,
			'lifetime_discount': 993
		},
		'€': {
			'trial': 0.00,
			'month': 4.99,
			'month-total': 59.88,
			'year': 4.08,
			'year-total': 49,
			'lifetime': 252,
			'lifetime_discount': 993
		},
		'₤': {
			'trial': 0.00,
			'month': 4.99,
			'month-total': 59.88,
			'year': 4.08,
			'year-total': 49,
			'lifetime': 252,
			'lifetime_discount': 993
		}
	}
};

export const businessPrices = {
	[BUSINESS_PLAN_ID]: {
		'$': {
			month: 9.99,
			year: 7.99
		},
		'€': {
			month: 9.99,
			year: 7.99
		},
		'₤': {
			month: 9.99,
			year: 7.99
		}
	},
	[BUSINESS_PLAN_2_ID]: {
		'$': {
			month: 19.98,
			year: 15.98
		},
		'€': {
			month: 19.98,
			year: 15.98
		},
		'₤': {
			month: 19.98,
			year: 15.98
		}
	},
};
