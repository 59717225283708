import React, {useEffect} from 'react';
import {Draggable} from "react-beautiful-dnd";
import {areEqual} from "react-window";
import usePlayerContext from "../../../hooks/usePlayerContext";
import Item from "./Item";

const Row = React.memo(function Row(props) {
    const { index, style } = props;

    const {
        queue,
        loadQueueItem,
    } = usePlayerContext();

    useEffect(() => {
        let timeout;
        if (!item.hasOwnProperty('name')) {
            timeout = setTimeout(() => {
                loadQueueItem(item.fileid);
            }, 600);
        }

        return () => clearTimeout(timeout);
    }, [queue]);

    const item = queue[index];

    return (
        <Draggable draggableId={item.fileid.toString()} index={index} key={item.fileid}>
            {(provided) => {
                return (
                    <Item
                        provided={provided}
                        item={item}
                        style={style}
                    />
                );
            }}
        </Draggable>
    );
}, areEqual);

export default Row;