import apiMethod from "../api/apiMethod";
import apiConfig from "../api/config";
import { __ } from "./translate";
import {
  STRIPE_PK,
  SAFECHARGE_MERCHANT_ID,
  SAFECHARGE_MERCHANT_SITE_ID,
  SAFECHARGE_ENV
} from "@pcloud/web-utilities/dist/config/";
import { PROVIDERS_IDS, PROVIDERS_NAMES, SUBSC_PERIOD } from "@pcloud/web-utilities/dist/config/constants";
import { signToCurrency } from "../../web-shared/constants";
import { sfc } from "../../web-shared/insertSafechargeForm"
import { removeCyclic } from "../../web-shared/utils"

import { EU_LOCATIONID, US_LOCATIONID } from "@pcloud/web-utilities/dist/config/constants";

const paymentApiUrls = {};
paymentApiUrls[EU_LOCATIONID] = "eqapayments.pcloud.com";
paymentApiUrls[US_LOCATIONID] = "qabraintree.pcloud.com";

export const getPaymentsApiUrl = () => {
  // TODO: get from better source, not global variable
  return paymentApiUrls[apiConfig.locationid];
};

export function SubscriptionUpdate(params) {
  const {
    provider,
    period, // `number` for busuness(only Paypal, Safecharge, Stripe) and `string` for all other cases
    discountcode,
    billingAddress = {},
    userAuth,
    products = [],
    members = 0,
    storage = 0,
    traffic = 0,
    toggleLoader = () => {},
    showPaymentError,
    newCC,
    showBadInput = () => {},
    providerSourceId,
    open3DsecureIframe = () => {},
    on3DsecureError = () => {},
    isBusiness = false,
    endTrial = false,
    prorationtime = 0,
    businessplanid
  } = params;

  this.apiMethods = {
    stripe: isBusiness ? "account_paystripe" : "paystripe",
    safecharge: "paysafecharge",
    braintree: isBusiness ? "subscription/createbusiness" : "purchase/create",
    paypal: "purchase/create",
  };

  this.method = this.apiMethods[provider];
  this.checkout = null;
  this.safeChargeCCToken = "";
  this.amountToPay = 1;
  this.userTokenId = "";
  this.orderid = null

  this.paymentInfo = {
    auth: userAuth,
    storage,
    traffic,
    discountcode
  };

  if (isBusiness) {  // is Business subscription
    if (provider === PROVIDERS_NAMES.BRAINTREE) {
      const braintreePeriod = period === SUBSC_PERIOD.MONTHLY ? "month" : "year";
      this.paymentInfo.period = braintreePeriod;
    } else {
      this.paymentInfo.billingperiod = period;
    }
  } else {
    this.paymentInfo.period = period;
  }

  if (endTrial) {
    this.paymentInfo.endtrial = 1;
  }

  if (businessplanid) {
    this.paymentInfo.businessplanid = businessplanid;
  }

  if (products.length) {
    this.paymentInfo.products = products.join(",");
  }

  if (members) {
    this.paymentInfo.members = members;
  }

  if (prorationtime) {
    this.paymentInfo.prorationtime = prorationtime;
  }

  this.errors = {
    "1120": __("discount_error_1120"),
    "1126": "Invalid usage.",
    "1131": "Please provide ‘period’.",
    "2247": __("discount_error_2247"),
    "2248": __("discount_error_2248"),
    "2249": __("discount_error_2249"),
    "2250": __("discount_error_2250"),
    "2252": __("discount_error_2252"),
    "2253": __("discount_error_2253"),
    "2256": "You can’t use your own referral code.",
    "2257": "You already bought this lifetime product.",
    "2289": "This custom plan can’t be bought.",
    "1101": "Invalid request.",
    "1056": __("payment_error_1056"),
    "2209": __("payment_error_2209"),
    "2215": __("payment_error_2215"),
    "93107": __("payment_error_93107"),
    "93109": __("payment_error_93109"),
    "82901": __("payment_error_82901"),
    "92910": __("payment_error_92910"),
    "92911": __("payment_error_92911"),
    "93108": __("payment_error_93108"),
    "81901": __("payment_error_81901"),
    "81905": __("payment_error_81905"),
    "91609": __("payment_error_91609"),
    api_connection_error: __("stripe_api_connection_error"),
    api_error: __("stripe_api_error"),
    authentication_error: __("stripe_authentication_error"),
    card_error: __("stripe_card_error"),
    invalid_request_error: __("stripe_invalid_request_error"),
    rate_limit_error: __("stripe_rate_limit_error"),
    invalid_number: __("stripe_invalid_number"),
    invalid_expiry_month: __("stripe_invalid_expiry_month"),
    invalid_expiry_year: __("stripe_invalid_expiry_year"),
    invalid_cvc: __("stripe_invalid_cvc"),
    incorrect_number: __("stripe_incorrect_number"),
    expired_card: __("stripe_expired_card"),
    incorrect_cvc: __("stripe_incorrect_cvc"),
    incorrect_zip: __("stripe_incorrect_zip"),
    card_declined: __("stripe_card_declined"),
    processing_error: __("stripe_processing_error"),
    missing: __("stripe_missing"),
    "5000": __("something_went_wrong_refresh_and_try_again")
  };

  this.handlePaymenterror = data => {
    const code = data.reasoncode || data.result || data.reason || data.error || data.code;
    const translated =
      this.errors[data.reasoncode] ||
      this.errors[data.reason] ||
      this.errors[data.result] ||
      this.errors[data.error] ||
      this.errors[data.code];
    let message;

    if (translated && (!data.result || (data.result === 5000 && !data.reason && !data.reasoncode))) {
      message = translated;
    } else {
      if (data.reason && data.reason.indexOf("TRANSID") != -1) {
        message = __("payment_error_unhandled");
      } else {
        message = data.reason || data.errorDescription || data.error || data.message || data.result;
      }
      if (message instanceof Object) {
        message = message.message || message.code
      }
    }

    if (data.field && data.field === "members") {
      message = __("business_subscription_downgrade");
    }

    if (code == "incorrect_number" || code == "invalid_number" || code == "card_declined") {
      showBadInput("card_number");
    } else if (code == "invalid_expiry_month") {
      showBadInput("exp_date");
    } else if (code == "invalid_cvc" || code == "incorrect_cvc") {
      showBadInput("cvc");
    }

    showPaymentError(message);
  };

  this.onPaymentSuccess = (res, opt) => {
    if (opt.callback && typeof opt.callback == "function") {
      opt.callback(res, () => {
        toggleLoader("hide");
      });
    } else {
      toggleLoader("hide");
    }

    if (opt.refresh) {
      location.reload();
    }
  };

  this.onPaymentError = data => {
    toggleLoader("hide");
    this.handlePaymenterror(data);
  };

  this.createCardData = (numberProp, expMonthProp, expYearProp, nameProp, cvcProp) => {
    if (newCC) {
      const forCardData = {};
      forCardData[numberProp] = newCC.cardNumber;
      forCardData[expMonthProp] = newCC.expirationMonth;
      forCardData[expYearProp] = newCC.expirationYear;
      forCardData[nameProp] = newCC.cardHolderName;
      forCardData[cvcProp] = newCC.CVV;

      return forCardData;
    }
  };

  this.apiCall = opt => {
    toggleLoader("show");
    console.log("PAYMENT INFO:", this.paymentInfo);

    apiMethod(
      this.method,
      this.paymentInfo,
      data => {
        if (data.redirecturl || (data.action && data.action.type === "redirect")) {
          open3DsecureIframe(data.action || data.redirect || data);
          window.showSafeChargeError = message => {
            on3DsecureError();
            this.onPaymentError(message);
          };
        } else {
          this.onPaymentSuccess(data, opt);
        }
      },
      {
        timeout: 300000,
        errorCallback: ret => {
          this.onPaymentError(ret);
        }
      }
    );
  };

  // Stripe step 2
  this.confirmStripePayment = ({ newpaymentmethod, paymentmethodid, paymentintentsecret }, opt) => {
    const stripe = Stripe(STRIPE_PK);
    const self = this;
    let params = {};

    if (newpaymentmethod) {
      params = {
        payment_method: {
          card: { token: this.paymentInfo["token"] },
          billing_details: { name: newCC && newCC.cardHolderName }
        },
        save_payment_method: true,
        setup_future_usage: "off_session"
      }	
    }

    if (paymentmethodid) {
      params = { payment_method: paymentmethodid };
    }

    stripe.confirmCardPayment(paymentintentsecret, params)
    .then(function(result) {
      if (result.error) {
        self.handlePaymenterror(result);
      } else {
        self.onPaymentSuccess(result, opt);
      }
    });
  }

// Stripe step 1 Update subs
  this.updateStripeSubscription = opt => {
    // remove param "token" from account_paystripe and paystripe api call;
    delete this.paymentInfo.token;
    toggleLoader("show");
    if (newCC) {
      if (opt.changeCard) {
        this.paymentInfo.changecard = opt.changeCard;
      }
      this.apiCall({
        callback: (res) => {
          if (res.paymentintentsecret) {
            this.confirmStripePayment(res, opt);
          } else {
            this.onPaymentSuccess(res, opt);
          }
        }
      });
    } else {
      this.apiCall({
        callback: (res) => {
          if (res.paymentintentsecret) {
            this.confirmStripePayment(res, opt);
          } else {
            this.onPaymentSuccess(res, opt);
          }
        }
      });
    }
  };

  this.updateStripeDefaultSource = (methodid, opt) => {
    toggleLoader("show");
    apiMethod(
      "billing/stripe/setdefaultpaymentmethod",
      { auth: userAuth, methodid: methodid },
      data => {
       this.onPaymentSuccess(data, opt);
      },
      {
        errorCallback: ret => {
          this.onPaymentError(ret);
        }
      }
    );
  };

  // SafeCharge step 2
  this.openSafechargeOrder = ({ successCallback, changeCard = false }) => {
    let openorderParams = {}; 
    const isBusinessSubscripton = isBusiness && members;

    if (isBusinessSubscripton) { // for business subscriotion
      openorderParams = {
        members,
        billingperiod: period,
        endtrial: endTrial ? 1 : 0,
        businessplanid: businessplanid
      }
      if (prorationtime) { // add prorationtime
        openorderParams.prorationtime = prorationtime;
      }
    } else if (changeCard) { // only change card
      openorderParams = {
        authorizecard: 1
      }
    } else {
      openorderParams = this.paymentInfo;
    }

    apiMethod('safecharge/openorder', openorderParams, data => {
      if (data.sessiontoken) {
        this.safeChargeCCToken = data.sessiontoken;
        this.userTokenId = data.usertokenid;
        this.amountToPay = data.amounttopay / 100;

        if (data.orderid) {
          this.orderid = data.orderid;
        }
        
        if (successCallback && typeof successCallback === "function") {
          successCallback(data);
        }
      } else {
        if (successCallback && typeof successCallback === "function") {
          successCallback(data);
        }
      }
    }, {
      errorCallback: (res) => {
        this.handlePaymenterror(res);
        toggleLoader("hide");
      }
    })
  }

// SafeCharge step 3 not working
  this.applySafechargeOrder = (opt) => {
    apiMethod('safecharge/applyorder', {
      auth: userAuth,
      orderid: this.orderid
    }, res => {
      this.onPaymentSuccess(res, opt);
    }, {
      errorCallback: (res) => {
        this.handlePaymenterror(res);
        toggleLoader("hide");
      }
    })
  }

  this.callSafechargePayment = (opt) => {
    if (!this.safeChargeCCToken) {
      return;
    }
    const currency = sessionStorage.pclCurrency || '$';
    const params = {
      sessionToken: this.safeChargeCCToken, //received from openOrder API 
      userTokenId: this.userTokenId,
      merchantId: SAFECHARGE_MERCHANT_ID,
      merchantSiteId : SAFECHARGE_MERCHANT_SITE_ID,
      clientUniqueId: Date.now(),
      currency: signToCurrency[currency],
      amount: this.amountToPay,
      billingAddress: billingAddress,
      merchantDetails: {
        customField7: apiConfig.locationid, // locationid
        customField8: 1 // indicates new api/implementation version
      }
    }
    if (newCC && SFCardNumber) {
      removeCyclic(SFCardNumber);
      params.paymentOption = SFCardNumber;
      params.cardHolderName = newCC.cardHolderName
    } else if (providerSourceId) {
      params.paymentOption = { userPaymentOptionId: providerSourceId };
    }

    sfc.createPayment(params, res => {
      if (res.result === "SUCCESS" || res.result === "APPROVED") {
        this.onPaymentSuccess(res, opt);
      } else {
        this.handlePaymenterror(res);
        toggleLoader("hide");
      }
    });
  }

  // SafeCharde step 1
  this.initSafechargePayment = (opt) => {
    const afterOpenOrder = (res) => {
      const hasCardNumber = typeof SFCardNumber !== "undefined" && SFCardNumber;
      if (res.amounttopay === 0 && !res.sessiontoken) {
        this.onPaymentSuccess(res, opt);
      } else if (res.amounttopay === 0 && res.orderid && !newCC && !hasCardNumber) {
        this.applySafechargeOrder(opt);
      } else {
        this.callSafechargePayment(opt);
      }
    }
    toggleLoader("show");    
    this.openSafechargeOrder({
      successCallback: (res) => afterOpenOrder(res),
      changeCard: opt.changeCard
    });
  };

  // Braintree Step 3 create payment or Step 1 existing payment
  this.createBraintreePayment = opt => {
    const apiServer = getPaymentsApiUrl();
    toggleLoader("show");
    apiMethod(
      this.method,
      this.paymentInfo,
      res => {
        this.onPaymentSuccess(res, opt);
      },
      {
        errorCallback: ret => {
          this.handlePaymenterror(ret);
          toggleLoader("hide");
        },
        apiServer: apiServer
      }
    );
  };

  // Braintree step 2
  this.initBrainTree = (token, opt) => {
    const self = this;
    opt = $.extend({}, { paypalButtonId: "#paypal-button", refresh: true }, opt);

    braintree.client.create({
      authorization: token
    }, function (clientErr, clientInstance) {
      // Stop if there was a problem creating the client.
      // This could happen if there is a network error or if the authorization
      // is invalid.
      if (clientErr) {
        console.error('Error creating client:', clientErr);
        return;
      }

      // Create a PayPal Checkout component.
      braintree.paypalCheckout.create({
        client: clientInstance
      }, function (paypalCheckoutErr, paypalCheckoutInstance) {
        paypalCheckoutInstance.loadPayPalSDK({
          vault: true
        }, function () {
          paypal.Buttons({
            fundingSource: paypal.FUNDING.PAYPAL,
            style: {
              color:  'blue',
              size: 'responsive',
              height: opt.buttonHeight || 45,
              label:  opt.label || ''
            },
            createBillingAgreement: function () {
              return paypalCheckoutInstance.createPayment({
                flow: 'vault', // Required
              });
            },

            onApprove: function (data, actions) {
              toggleLoader("show");
              return paypalCheckoutInstance.tokenizePayment(data, function (err, payload) {
                if (payload.nonce) {
                  self.paymentInfo["nonce"] = payload.nonce;
                  self.createBraintreePayment(opt);
                } else {
                  showPaymentError(
                    __(
                      "something_went_wrong_refresh_and_try_again",
                      "Something went wrong. Refresh and try again."
                    )
                  );
                }
                // Submit `payload.nonce` to your server
              });
            },

            onCancel: function (data) {
              console.log('PayPal payment canceled', JSON.stringify(data, 0, 2));
              toggleLoader("hide");
            },

            onError: function (err) {
              console.error('PayPal error', err);
              toggleLoader("hide");
            }
          }).render(opt.paypalButtonId).then(function () {
            // The PayPal button will be rendered in an html element with the ID
            // `paypal-button`. This function will be called when the PayPal button
            // is set up and ready to be used
            if (opt.onReady) {
              // remove loader
              opt.onReady();
            }
          });
        });
      });
    });
  };

  // Braintree step 1
  this.getTokenBraintree = opt => {
    const apiServer = getPaymentsApiUrl();
    apiMethod(
      "./token/get",
      this.paymentInfo,
      res => {
        this.initBrainTree(res.token, opt);
      },
      {
        apiServer: apiServer
      }
    );
  };

  this.confirmStripeCard = ({ newpaymentmethod, paymentmethodid, clientsecret }, opt) => {
    const stripe = Stripe(STRIPE_PK);
    const params = {
      payment_method: {
        card: { token: this.paymentInfo["token"] }
      }
    }
    const self = this;

    if (paymentmethodid) {
      params.payment_method = paymentmethodid;
    }

    stripe.confirmCardSetup(clientsecret, params)
    .then(function(result) {
      if (result.error) {
        self.handlePaymenterror(result);
        toggleLoader("hide");
      } else {
        if (opt.shoudUpdateDefaultSource) {
          self.updateStripeDefaultSource(result.setupIntent.payment_method, {
            refresh: false,
            callback: opt.callback
          });
        } else if (opt.shouldUpdateSubs) {
          self.updateStripeSubscription({
            refresh: false,
            callback: opt.callback,
            changeCard: opt.changeCard
          });
        } else {
          self.onPaymentSuccess(result, opt);
        }
      }
    });
  }

  // Stripe 
  this.setupIntent = (token, { callback }) => {
    apiMethod(
      "billing/stripe/setupintent",
      { auth: userAuth },
      res => {
        if (callback && typeof callback === "function"){
          callback(res);
        }
      },
      ret => {
        this.handlePaymenterror(ret);
        toggleLoader("hide");
      }
    );
  }
  // Stripe set new default card
  this.setStripePaymentSource = (opt) => {
    toggleLoader("show");
    const self = this;
    opt.shoudUpdateDefaultSource = typeof opt.shoudUpdateDefaultSource !== "undefined" ? opt.shoudUpdateDefaultSource : true;
    console.log("setStripePaymentSource opts", opt);

    if (newCC) {
      Stripe.setPublishableKey(STRIPE_PK);
      const cardData = this.createCardData("number", "exp_month", "exp_year", "name", "cvc");

      Stripe.card.createToken(cardData, (status, tokenInfo) => {
        if (status == 200) {
          this.paymentInfo["token"] = tokenInfo.id;
          self.setupIntent(tokenInfo, {
            callback: (res) => {
              if (res.clientsecret) {
                self.confirmStripeCard(res, opt);
              } else {
                opt.callback(res);
              }
            }
          });
        } else {
          toggleLoader("hide");
          self.handlePaymenterror(tokenInfo.error);
        }
      });
    }
  }

  this.updatePaypalBusinessSubscription = (opt = { reason: "modify" }) => {
    const baseUrl = window.location.protocol + "//" + window.location.host + "/";
    const method = "account_" + opt.reason + "_prepare";
    const params = {
      auth: userAuth,
      billingperiod: period,
      endtrial: endTrial,
      members: members,
      billingprovider: PROVIDERS_IDS.PAYPAL
    };

    if (!params.returnUrl) {
      params.returnUrl = baseUrl + "bpaypal.html?do=" + opt.reason;
    }

    if (!params.cancelUrl) {
      params.cancelUrl = baseUrl + "?r=1#page=b_billing";
    }

    if (businessplanid) {
      params.businessplanid = businessplanid;
    }

    apiMethod(
      method,
      params,
      ({ token }) => {
        if (token) {
          setTimeout(function() {
            window.location =
              "https://" + paypalHost + "/cgi-bin/webscr?cmd=_express-checkout&token=" + token;
          }, 1);
        }
      },
      {
        errorCallback: ret => {
          togglePaymentLoader("hide");
          throw new Error(ret);
        }
      }
    );
  }
}
