import React from 'react';

import { __ } from "../../../../lib/translate";
import usePlayerContext from "../../hooks/usePlayerContext";
import {HeaderControlsWrapper, HeaderTitleWrapper, HeaderWrapper} from "../../userinterface/styles";
import ActionButton from "../../userinterface/ActionButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose} from '@fortawesome/pro-solid-svg-icons/faClose';
import {faChevronDown} from '@fortawesome/pro-solid-svg-icons/faChevronDown';
import {faListUl} from '@fortawesome/pro-solid-svg-icons/faListUl';
import {faExpandArrows} from '@fortawesome/pro-regular-svg-icons/faExpandArrows';

const Header = () => {
    const {
        headerRef,
        queue,
        playlist,
        isPublic,
        isMobile,
        playerVariant,
        onPlayerClose,
        onPlayerMinimize,
        onShowMiniPlayer,
        onShowFullPagePlayer,
        onShowPlayer,
        additionalClass,
        isSingleSongPlayer
    } = usePlayerContext();

    const showStandartBtns = !isPublic || (
        isMobile && queue.length > 1
    );
    const showCloseBtns = !isMobile || (queue.length > 1 || !isPublic);
    const showPublicBtns = isPublic && !isMobile;

    const handleHeaderClick = (e) => {
        e.stopPropagation();
        if (
            isMobile &&
            !playerVariant.isMinimize &&
            !isSingleSongPlayer
        ) {
            onPlayerMinimize();
        }
    }

    return (
        <HeaderWrapper
            ref={headerRef}
            className={additionalClass + ' animate'}
            onClick={handleHeaderClick}
        >
            <HeaderTitleWrapper>
                <>
                    {__('Currently playing')} {playlist && playlist.name ? "\""+playlist.name+"\"" : ""}
                </>
            </HeaderTitleWrapper>
            <HeaderControlsWrapper
                id={"headerControlsWrapper"}
                className={additionalClass}
            >
                {showStandartBtns && (
                    <>
                        <ActionButton
                            onClick={playerVariant.isMiniPlayer ? onShowPlayer : onShowMiniPlayer}
                            className={'svg-color-default'}
                            label={
                                <FontAwesomeIcon style={{fontSize: 16}} icon={faListUl} />
                            }
                        />
                        <ActionButton
                            onClick={onPlayerMinimize}
                            className={'svg-color-default'}
                            label={
                                <FontAwesomeIcon style={{fontSize: 16}} icon={faChevronDown} />
                            }
                        />
                    </>
                )}
                {showPublicBtns && (
                    <>
                        <ActionButton
                            onClick={onShowFullPagePlayer}
                            className={'svg-color-default'}
                            label={<FontAwesomeIcon style={{fontSize: 12}} icon={faExpandArrows} />}
                        />
                    </>
                )}
                {showCloseBtns && (
                    <ActionButton
                        onClick={onPlayerClose}
                        className={'svg-color-default'}
                        label={
                            <FontAwesomeIcon style={{fontSize: 16}} icon={faClose} />
                        }
                    />
                )}
            </HeaderControlsWrapper>
        </HeaderWrapper>
    );
}

export default Header;