import { each, range } from "../../lib/utils";

export const StepArr = [1, 2, 3, 4, 5, 6];

const stepsMap: Array<[string, number]> = [
  ["verifymail", 1],
  ["uploadfile", 2],
  ["downloaddrive", 4],
  ["downloadapp", 8],
  ["autoupload", 16],
  ["sentinvitation", 32],
];

export function firstStepNotDone(mask: number): BonusStep | false {
  for (let i = 0; i < stepsMap.length; ++i) {
    if ((stepsMap[i][1] & mask) == 0) {
      return (i + 1: any);
    }
  }
  return false;
}

export function nextStepNotDone(
  mask: number,
  selected: number
): BonusStep | false {
  const rangeOfSteps = range(StepArr.length - selected, 1, selected).concat(
    range(selected - 1, 1, 0)
  );
  const steps = rangeOfSteps.map(element => ({
    id: stepsMap[element][1],
    step: element + 1
  }));


  for (let i = 0; i < steps.length; ++i) {
    if ((steps[i].id & mask) === 0) {
      return (steps[i].step: any);
    }
  }
  return false;
}

export function boolsJourneyStepsToMask(steps: JourneySteps): number {
  return stepsMap.reduce(
    (mask, [step, bit]) => mask + (steps[step] ? bit : 0),
    0
  );
}

export function userJourneyMask({ journey: { steps } }: Userinfo): number {
  console.log("fhauskjhf", boolsJourneyStepsToMask(steps));
  return boolsJourneyStepsToMask(steps);
}
