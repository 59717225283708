import React from 'react';
import styled, { keyframes } from "styled-components";
import { hiDPI } from "polished";

type Props = {
  isOpen: boolean,
  isCompleted: boolean
};

const UnlockSpaceArrow = ({ isOpen, isCompleted, ...rest }: Props) => {
  return isCompleted ? <CompletedImg /> : (isOpen ? <OpenedImg /> : <ClosedImg />)
};

export default UnlockSpaceArrow;


const rotate0 = keyframes`
  from { transform: rotate(180deg); }
  to { transform: rotate(0deg); }
`;

const rotate180 = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(180deg); }
`;

const Img = styled.div`
  width: 15px;
  height: 10px;
  background-repeat: no-repeat;
  background-position: center center;
  display: inline-block;
  background-size: 15px 10px;

  position: absolute;
  top: 25px;
  right: 15px;

  background-image: url(${require("../../../root/img/unlockspace/arrow-up.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/unlockspace/arrow-up@2x.png")});
  }
`;

const OpenedImg = styled(Img)`
  animation: ${rotate0} 0.25s ease;
`;

const ClosedImg = styled(Img)`
  opacity: 0.2;
  transform: rotate(180deg);
  animation: ${rotate180} 0.1s linear;
`;

const CompletedImg = styled(Img)`
  background-image: url(${require("../../../root/img/unlockspace/check.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/unlockspace/check@2x.png")});
  }
`;
