// @flow

import React from "react";
import styled from "styled-components";
import { hiDPI } from "polished";

import StepImage from "./StepImage";
import StepContent /*, { StepFinal as StepFinalContent }*/ from "./StepContent";

import type { BonusStep } from "./types";

type StepProps = {
  completed: boolean,
  step: BonusStep,
  onUploadFile: () => void,
  onVerifyMail: () => void
};

/*type FinalStepProps = {
  premium: boolean,
  onRewardClaim: () => void,
  claimingreward: boolean
}*/

const Step = (props: StepProps) => (
  <Wrap>
    <ImageWrap>
      <StepImage {...props} />
    </ImageWrap>
    <ContentWrap>
      <StepContent {...props} />
    </ContentWrap>
  </Wrap>
);

/*const FinalStep = (props: FinalStepProps) => (
  <Wrap>
    <ImageWrap>
      <StepImage step="final" />
    </ImageWrap>
    <ContentWrap>
      <StepFinalContent step="final" {...props} />
    </ContentWrap>
  </Wrap>
)*/

export default Step;
//export { FinalStep };

const Wrap = styled.div`
  width: 900px;
  margin: 0 auto 20px auto;
  min-height: 144px;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.07), 0px 3px 5px rgba(0, 0, 0, 0.03);

  background: #fff;
  position: relative;
`;

const ImageWrap = styled.div`
  width: 144px;
  height: 144px;
  border-right: 1px solid #f7f7f7;
  background: #fafafa;
  float: left;

  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
`;

const ContentWrap = styled.div`
  background: #fff;
  margin-left: 144px;
`;
