// @flow

import React, { useState, useEffect, useRef } from "react";

import Modal, { OvalModalWrap, CloseModalIcon } from "../../Modal";
import Button from "../../ButtonDefault";
import * as Style from "../../Modals/styledComponents";
import InputText from "../../InputText";

import apiMethod from "../../../api/apiMethod";
import { __ } from "../../../lib/translate";
import { errorKeys } from "../../../lib/errors";

import useIsMount from "../../../../web-shared/hooks/useIsMount";

const CreatePlaylistModal = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [playlistName, setPlaylistName] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const isMount = useIsMount();
  const inputTextRef = useRef(null);

  useEffect(() => {
    var inputName = inputTextRef.current;

    createSelection(inputName, 0, inputName.value.length);
    inputName.focus();
  }, [isMount]);

  const handleClose = () => {
    setIsOpen(false);
    setError("");
    setPlaylistName("");
  };

  const onFolderNameChange = ({ target }) => {
    setPlaylistName(target.value);
    if (error) {
      setError(__(""));
    }
  };

  const onEnter = (e: any) => {
    if (e.keyCode === 13) {
      onCreateButtonClick();
    }
  };

  const onCreateButtonClick = () => {
    if (isLoading) {
      console.log("clicked loading");
      return;
    }
    setIsLoading(true);

    if (playlistName.trim().length < 1) {
      HFN.message(__("Please, enter proper playlist name."), 'error');
      setPlaylistName("");
      setIsLoading(false);
      return;
    }

    apiMethod(
      "collection_create",
      { name: playlistName },
      ret => {
        HFN.refreshPlaylist(ret.collection.id);
        HFN.message(__("Playlist Created"));

        handleClose();
      },
      {
        errorCallback: ({ result, error }) => {
          if (errorKeys[result]) {
            HFN.message(__(errorKeys[result]), "error");
          } else {
            HFN.message(__("something_went_wrong_refresh_and_try_again"), "error");
            throw new Error(error);
          }
          setIsLoading(false);
        }
      }
    );
  };

  return (
    <Modal onClose={handleClose} opened={isOpen}>
      <OvalModalWrap>
        <CloseModalIcon onClick={handleClose} />
        <Style.Container>
          <Style.Header>{__("Create Playlist")}</Style.Header>
          {error ? (
            <Style.ErrorMessage>{error}</Style.ErrorMessage>
          ) : (
            <Style.Label>{__("Name", "Name")}</Style.Label>
          )}
          <InputText
            innerRef={inputTextRef}
            name="playlistName"
            placeholder={__("Playlist name")}
            onChange={onFolderNameChange}
            value={playlistName}
            autoFocus
            onKeyDown={onEnter}
          />
          <Style.Footer>
            <Button
              color="lightgray4"
              style={{
                marginRight: "5px"
              }}
              onClick={handleClose}
            >
              {__("Cancel")}
            </Button>
            <Button
              loading={isLoading}
              disabled={isLoading}
              color="cyan"
              style={{
                marginLeft: "5px"
              }}
              onClick={onCreateButtonClick}
            >
              {__("Create")}
            </Button>
          </Style.Footer>
        </Style.Container>
      </OvalModalWrap>
    </Modal>
  );
};

export default CreatePlaylistModal;
