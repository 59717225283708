//@flow

import React, { useState, useEffect } from "react";
import styled from "styled-components"

import {
  formatExpDatetoMMYY,
  preventEnteringChars,
  preventSymbolsOnCCHolderName,
  findCardType,
  getExpiryDatePeriod,
  validateExpiryDate
} from "../../../web-shared/utils";
import { SubscriptionUpdate } from "../../lib/SubscriptionUpdate";
import { PlanIDType, ColorType } from "../../../web-shared/dataTypes";
import type { CreditCardFormTemplateProps } from "./CreditCardFormTemplate";
import Popup from "../../lib/Popup";
import { insertSafechargeForm } from "../../../web-shared/insertSafechargeForm";
import { PROVIDERS_NAMES } from "@pcloud/web-utilities/dist/config/constants";

type CreditCardBoxType = {
  auth: string,
  planId: PlanIDType | Array<PlanIDType>,
  period: "month" | "year" | "lifetime" | "onetime",
  members?: number,
  provider: "safecharge" | "stripe",
  billingAddress: {
    email: string,
    country: string
  },
  RenderForm: (props: CreditCardFormTemplateProps) => React$Element<any>,
  afterPaymentActions: () => void,
  open3DsecureIframe: () => void,
  on3DsecureError: () => void,
  RenderPrefilledForm?: ({
    paymentLoading: boolean,
    userPaymentSource: Object,
    handleCardChange: () => void,
    handleSubmit: (event: SyntheticInputEvent<HTMLInputElement>) => void
  }) => React$Element<any>,
  userPaymentSource?: Object,
  discount?: string,
  color?: ColorType,
  currency?: String,
  price?: number,
  endTrial?: boolean,
  prorationtime?: number,
  isBusiness?: boolean,
  onPaymentProcessing?: () => void,
  afterPaymentProcessing?: () => void,
  isIE?: boolean,
  showLoading?: () => void,
  businessplanid?: 1 | 2
};

let isLoading = false;

const CreditCardBox = ({
  auth,
  planId,
  period,
  members = 0,
  discount,
  provider,
  billingAddress,
  RenderForm,
  afterPaymentActions,
  open3DsecureIframe,
  on3DsecureError,
  RenderPrefilledForm,
  userPaymentSource,
  color = "cyan",
  currency = null,
  price = null,
  endTrial = false,
  prorationtime = 0,
  isBusiness = false,
  onPaymentProcessing = () => {},
  afterPaymentProcessing = () => {},
  isIE,
  showLoading = () => {},
  businessplanid
}: CreditCardBoxType) => {
  const [cardNumber, setCardNumber] = useState("");
  const [cardNumberLength, setCardNumberLength] = useState(16);
  const [cardType, setCardType] = useState("default");
  const [expDate, setExpDate] = useState("");
  const [CVC, setCVC] = useState("");
  const [holderName, setHolderName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [validCardNumber, setValidCardNumber] = useState(true);
  const [validExpDate, setValidExpDate] = useState(true);
  const [validCVC, setValidCVC] = useState(true);
  const [validHolderName, setValidHolderName] = useState(true);

  const [paymentLoading, setPaymentLoading] = useState(false);
  const [changeCard, setChangeCard] = useState(false);
  const [safechargeFormInserted, setSafechargeFormInserted] = useState(false);

  useEffect(() => {
    return () => {
      if (provider === PROVIDERS_NAMES.SAFECHARGE) {
        if (SFCardNumber) {
          SFCardNumber.destroy();
          SFCardNumber = null;
          if (typeof cardExpiry !== "undefined") {
            cardExpiry.destroy();
            cardExpiry = null;
          }
          if (typeof cardCvc !== "undefined") {
            cardCvc.destroy();
            cardCvc = null;
          }
        }
      }
    }
  }, [])

  useEffect(() => {
    if (provider === "safecharge" && !safechargeFormInserted && (!userPaymentSource || (userPaymentSource && changeCard)) && !isIE) {
      insertSafechargeForm({ language: rcookie("lang") || "en" });
      setSafechargeFormInserted(true);
    }
  }, [changeCard])

  const handleCardNumberChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
    let value = event.target.value;
    let newCardType;

    if (value.length <= 4 && value.length > 0) {
      newCardType = findCardType(value);
    } else if (value.length == 0) {
      setCardType("default");
      setCardNumberLength(16);
    }

    if (newCardType && cardType != newCardType.name) {
      setCardType(newCardType.name);
      setCardNumberLength(newCardType.valid_length[0]);
    }

    if ((value.length - cardNumber.length) <= 1) {
      setCardNumber(value);
    }
  };

  const handleExpDateChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
    let value = event.target.value;
    setExpDate(value);
  };

  const handleCVCChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
    let value = event.target.value;
    setCVC(value);
  };

  const handleCVCKeyPress = (event: SyntheticInputEvent<HTMLInputElement>) => {
    preventEnteringChars(event);
  };

  const handleHolderNameChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
    let value = event.target.value.toUpperCase();
    setHolderName(value);
  };

  const handleCardNumberKeyPress = (event: SyntheticInputEvent<HTMLInputElement>) => {
    preventEnteringChars(event);
    formatCardNumberOnKeyPress(event);
  };

  const handleExpDateKeyUp = (event: SyntheticInputEvent<HTMLInputElement>) => {
    formatExpDate(event);
  };

  const handleHolderNameKeyPress = (event: SyntheticInputEvent<HTMLInputElement>) => {
    preventSymbolsOnCCHolderName(event);
  };

  const formatCardNumberOnKeyPress = (event: SyntheticInputEvent<HTMLInputElement>) => {
    let value = event.target.value;
    let formatedValue = value.replace(/\s+/g, "");

    if (formatedValue.length > cardNumberLength - 1) {
      event.preventDefault();
      return;
    }

    if (cardType === "amex" && formatedValue) {
      if (formatedValue.length === 4 || formatedValue.length === 10) {
        value += " ";
        setCardNumber(value);
      }
    } else if (formatedValue && formatedValue.length % 4 === 0) {
      value += " ";
      setCardNumber(value);
    }
  };

  const formatCardNumberOnPaste = (event: SyntheticInputEvent<HTMLInputElement>) => {
    let paste = (event.clipboardData || window.clipboardData).getData('text');
    let formattedNumberSubs = [];
    paste = paste.replace(/\D/g,'');

    if (paste) {
      let newCardType = findCardType(paste);
      let validCardLength;
  
      if (newCardType) {
        setCardType(newCardType.name);
        validCardLength = newCardType.valid_length[0];
        paste = paste.slice(0, validCardLength);

        if (newCardType.name === "amex") {
          formattedNumberSubs.push(paste.slice(0, 4))
          formattedNumberSubs.push(paste.slice(4, 10))
          formattedNumberSubs.push(paste.slice(10))
        } else {
          formattedNumberSubs = paste.match(/.{1,4}/g);
        }
      } else {
        setCardType("default");
        setCardNumberLength(16);
        formattedNumberSubs = paste.match(/.{1,4}/g);
      }

      let formattedNumber = formattedNumberSubs.join(" ");

      setCardNumber(formattedNumber);
    }
  };

  const formatExpDate = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const code = event.keyCode;
    const allowedKeys = [8];

    if (allowedKeys.indexOf(code) !== -1) {
      event.preventDefault();
      return;
    }

    let value = event.target.value;

    setExpDate(formatExpDatetoMMYY(value));
  };

  const validateCreditCardInputs = (callback: () => void) => {
    let validExpDateTemp = false;
    let validCardNumberTemp = false;
    let validHolderNameTemp = false;
    let validCVCTemp = false;
    let validInputs = false;
    let filledInputs = false;

    if (provider === "safecharge") {
      validExpDateTemp = !document.getElementById("card-expiry").classList.contains('empty');
      validCardNumberTemp = !document.getElementById("card-number").classList.contains('empty');
      validHolderNameTemp = !(!holderName || holderName.length < 3);
      validCVCTemp = !document.getElementById("card-cvc").classList.contains('empty');
      filledInputs = validCardNumberTemp && validExpDateTemp && holderName && validCVCTemp;
    } else if (provider === "stripe") {
      validExpDateTemp = validateExpiryDate(expDate);
      validCardNumberTemp = !(!cardNumber || cardNumber.length < 12);
      validHolderNameTemp = !(!holderName || holderName.length < 3);
      validCVCTemp = !(!CVC || CVC.length < 3);
      filledInputs = cardNumber && expDate && holderName && CVC;
    }

    setValidExpDate(validExpDateTemp);
    setValidCardNumber(validCardNumberTemp);
    setValidHolderName(validHolderNameTemp);
    setValidCVC(validCVCTemp);

    validInputs = validExpDateTemp && validCardNumberTemp && validHolderNameTemp && validCVCTemp;

    if (
      validInputs &&
      filledInputs &&
      callback &&
      typeof callback == "function"
    ) {
      callback();
    }
  };

  const handleCardChange = () => {
    if (paymentLoading) {
      return;
    }
    setErrorMessage("");
    setChangeCard(!changeCard);
    if (provider === "safecharge" && changeCard) {
      setSafechargeFormInserted(false);
    }
  };

  const togglePaymentLoader = (action: "show" | "hide") => {
    isLoading = action === "show";
    setPaymentLoading(isLoading);
    showLoading(isLoading);
    isLoading ? onPaymentProcessing() : afterPaymentProcessing();
  };

  const showBadInput = (inputName: "card_number" | "exp_date" | "cvc") => {
    if (inputName === "card_number") {
      setValidCardNumber(false);
    }
    if (inputName == "exp_date") {
      setValidExpDate(false);
    }
    if (inputName == "cvc") {
      setValidCVC(false);
    }
  };

  const showPaymentError = message => {
    togglePaymentLoader("hide");
    setErrorMessage(message);
  };

  const handlePaymentSuccess = (res, callback) => {
    isLoading = false;
    afterPaymentActions(res, callback);
  }

  const handleSubmit = (event: SyntheticInputEvent<HTMLInputElement>) => {
    event.preventDefault();
    setErrorMessage("");

    const params = {
      provider: provider,
      period: period,
      userAuth: auth,
      products: planId,
      members: members,
      billingAddress: billingAddress,
      storage: 0,
      traffic: 0,
      isBusiness: isBusiness,
      endTrial: endTrial,
      businessplanid: businessplanid,
      prorationtime: prorationtime,
      toggleLoader: togglePaymentLoader,
      showPaymentError,
      showBadInput,
      open3DsecureIframe,
      on3DsecureError
    };

    if (discount) {
      params.discountcode = discount;
    }

    let payments = new SubscriptionUpdate(params);

    // submit payment with default card
    if (userPaymentSource && !changeCard) {
      if (isLoading) {
          return;
      }
      isLoading = true;
      if (userPaymentSource.providersourceid) {
        params.providerSourceId = userPaymentSource.providersourceid;
      }

      let payments = new SubscriptionUpdate(params);

      togglePaymentLoader("show");
      if (provider === "safecharge") {
        payments.initSafechargePayment({
          refresh: false,
          callback: handlePaymentSuccess
        });
      } else if (provider === "stripe") {
        payments.updateStripeSubscription({
          refresh: false,
          callback: handlePaymentSuccess
        });
      }
    } else { // submit payment with new card
      validateCreditCardInputs(() => {
        if (isLoading) {
          return;
        }
        isLoading = true;

        togglePaymentLoader("show");
        let newCC = null;

        if (provider === "safecharge") {
          newCC = {
            cardHolderName: holderName
          }
        } else {
          newCC = {
            cardNumber: cardNumber.replace(/\s+/g, ""),
            cardHolderName: holderName,
            expirationMonth: getExpiryDatePeriod(expDate, "month"),
            expirationYear: getExpiryDatePeriod(expDate, "year"),
            CVV: CVC
          };
        }
        params.newCC = newCC;

        let payments = new SubscriptionUpdate(params);

        if (provider === "safecharge") {
          payments.initSafechargePayment({
            refresh: false,
            callback: handlePaymentSuccess
          });
        } else if (provider === "stripe") {
          payments.setStripePaymentSource({
            shouldUpdateSubs: true,
            refresh: false,
            callback: handlePaymentSuccess,
            shoudUpdateDefaultSource: false,
            changeCard: changeCard
          });
        }
      });
    }
  };

  window.handleSafeChargeSuccess = transactionID => {
    if (Popup.isOpen()) {
      $(".sf_logo_wrap .sf_logo_wrap_inner").empty();
      $(".safecharge_wrap .spinner").show();
      $(".modal.sf_modal")
        .find("#sf_iframe")
        .css({ "padding-top": 0, opacity: 0 });
      $(".modal.sf_modal")
        .find(".close")
        .hide();

      Popup.close();
      sessionStorage.removeItem("pCloud");
    }
    handlePaymentSuccess(transactionID);
  };

  if (isIE) {
    return (
      <Message><span>{__("ie_safercharge_unsupported")}</span></Message>
    )
  }

  if (userPaymentSource && RenderPrefilledForm && !changeCard) {
    return (
      <RenderPrefilledForm
        paymentLoading={paymentLoading}
        userPaymentSource={userPaymentSource}
        errorMessage={errorMessage}
        handleCardChange={handleCardChange}
        handleSubmit={handleSubmit}
        color={color}
        currency={currency}
        price={price}
      />
    );
  }

  return (
    <RenderForm
      cardNumber={cardNumber}
      validCardNumber={validCardNumber}
      cardType={cardType}
      handleCardNumberChange={handleCardNumberChange}
      handleCardNumberKeyPress={handleCardNumberKeyPress}
      handleCardNumberPaste={formatCardNumberOnPaste}
      expDate={expDate}
      validExpDate={validExpDate}
      handleExpDateKeyUp={handleExpDateKeyUp}
      handleExpDateChange={handleExpDateChange}
      CVC={CVC}
      validCVC={validCVC}
      handleCVCChange={handleCVCChange}
      handleCVCKeyPress={handleCVCKeyPress}
      holderName={holderName}
      validHolderName={validHolderName}
      handleHolderNameChange={handleHolderNameChange}
      handleHolderNameKeyPress={handleHolderNameKeyPress}
      errorMessage={errorMessage}
      paymentLoading={paymentLoading}
      handleSubmit={handleSubmit}
      changeCard={changeCard}
      handleCardChange={handleCardChange}
      color={color}
      currency={currency}
      price={price}
      shouldRenderInputs={provider !== "safecharge"}
    />
  );
};

export default CreditCardBox;

const Message = styled.div`
  min-height: 298px;
  height: 297px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
`;
