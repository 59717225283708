// @flow
import { NavCommand } from "../types/page";
import { __ } from "./translate";
import type { Userinfo } from "../types/user";
import apiMethod from "../api/apiMethod";

export const cryptoGetNavCmd = (): Promise => {
  const cmdPromise = new Promise((resolve, reject) => {
    const user = HFN.config.user;

    if (!cryptoBrowserSupport.isBrowserSupported()) {
      resolve({ state: { page: "cryptosupport" } });
    }

    if (user.business) {
      apiMethod("account_info", { auth: HFN.config.auth }, ret => {
        if (!user.account.permissions.crypto_access) {
          resolve({
            state: { page: "filemanager" },
            errroMessage: __(
              "ba_no_access_to_crypto",
              "Your access to Crypto Folder for this account is disabled."
            )
          });
        }

        if (ret.account.cryptov2activated) {
          if (user.account.owner) {
            if (user.cryptosetup) {
              resolve({ state: { page: "cryptolocked" } });
            } else {
              resolve({ state: { page: "cryptobusiness" } });
            }
          } else {
            apiMethod("crypto_getroots", { auth: HFN.config.auth }, roots => {
              if (user.cryptosetup && roots.folders.length > 0) {
                resolve({ state: { page: "cryptolocked" } });
              } else {
                resolve({ state: { page: "cryptobusiness" } });
              }
            }, {
              errorCallback: (e) => resolve({ state: { page: "cryptobusiness" } }),
              forceFresh: true
            });
            return;
          }
        } else {
          if (HFN.config.user.cryptosetup) {
            resolve({ state: { page: "cryptolocked" } });
          } else {
            resolve({ state: { page: "cryptoset" } });
          }
        }
      });
      return;
    }

    if (
      new Date(HFN.config.user.cryptoexpires).getTime() + 2592000000 /* 30 days */ >
      new Date().getTime()
    ) {
      if (HFN.config.user.cryptosetup) {
        resolve({ state: { page: "cryptolocked" } });
      } else {
        resolve({ state: { page: "cryptoset" } });
      }
    } else {
      resolve({ state: { page: "cryptobuy" } });
    }
  });

  return cmdPromise;
};

export const processNavCmd = (cmd: NavCommand) => {
  if (!cmd) {
    return;
  }
  if (cmd.errorMessage) {
    HFN.message(cmd.errorMessage, "error");
  }

  if (HFN.pages.current == cmd.state.page) {
    console.warn("Navigation to current page not executed");
    return;
  }

  $.bbq.pushState(cmd.state, 2);
};
