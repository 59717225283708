// @flow

import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { hiDPI } from "polished";

import Modal, { OvalModalWrap, OvalModalCloseV2 } from "../../Modal";
import Button from "../../ButtonDefault";
import * as Style from "../styledComponents";

import apiMethod from "../../../api/apiMethod";
import { __ } from "../../../lib/translate";
import { validateEmail } from "../../../lib/utils";
import { getSharedContacts } from "../../../lib/shareFolder-utils";

const InviteUsersToTeamModal = ({
  teamName = "",
  teamId = 0
}: {
  teamName: string,
  teamId: number
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isBusiness = useSelector(({ user }) => user.userinfo.business);
  const userContacts = useSelector(({ user }) => user.userContacts);
  const bUserContacts = useSelector(({ business }) => business.users);

  const [contacts, setContacts] = useState([]);
  const [inputPrepared, setInputPrepared] = useState(false);
  const [vals, setVals] = useState(0);
  const [combo, setCombo] = useState({});
  const [qEngine, setqEngine] = useState(null);

  const prepareSendInput = useCallback(
    contacts => {
      if (inputPrepared) {
        return;
      }

      setInputPrepared(true);

      setTimeout(() => {
        var tokenizer = function(name) {
          var ret = Bloodhound.tokenizers.whitespace(name.replace(/\(?\)?/g, ""));
          if (ret.indexOf(name.replace(/\(?\)?/g, "")) == -1) ret.push(name.replace(/\(?\)?/g, ""));
          return ret;
        };

        let qEngine = new Bloodhound({
          name: "name",
          datumTokenizer: function(r) {
            var ret = Bloodhound.tokenizers.whitespace(r.name.replace(/\(?\)?/g, ""));
            if (ret.indexOf(r.name.replace(/\(?\)?/g, "")) == -1)
              ret.push(r.name.replace(/\(?\)?/g, ""));
            if (validateEmail(r.value) && ret.indexOf(r.value.replace(/\(?\)?/g, "")) == -1)
              ret.push(r.value.replace(/\(?\)?/g, ""));
            if (r.userobj) ret.push(r.userobj.email);
            return ret;
          },
          queryTokenizer: Bloodhound.tokenizers.whitespace,
          local: contacts
        });

        var orig_get = qEngine.get;
        qEngine.get = function(query, cb) {
          return orig_get.apply(qEngine, [
            query,
            function(suggestions) {
              if (!suggestions) return cb(suggestions);
              suggestions.forEach(function(s) {
                var tokens = tokenizer(s.name.toLowerCase()),
                  score = 0;
                for (var n = 0; n < tokens.length; ++n) {
                  if (tokens[n].indexOf(query) == 0) score++;
                }
                s.exact_match = score;
              });
              console.log("suggestions!!!!", suggestions);
              suggestions.sort(function(a, b) {
                return a.exact_match > b.exact_match ? -1 : a.exact_match < b.exact_match ? 1 : 0;
              });
              cb(suggestions);
            }
          ]);
        };

        qEngine.initialize();

        var comboOptions = {
          place: ".tomails",
          name: "emails",
          width: 410,
          innerWidth: 410,
          sourceKey: "name",
          focusOnInit: false,
          // onlySuggested: true,
          source: qEngine,
          placeholder: __("Name or Email"),
          autocomplete: "off",
          maxNotFoundSymbols: 5,
          suggestOnFocus: false,
          getShowVal: r => {
            if (validateEmail(r)) return HFN.strFitMail(r, 40);
            return HFN.strFit(r, 40);
          },
          onRemoveResultBox: function(box, data) {
            setVals(vals => --vals);
            console.log("bb", box);
            box.find(".combo-res").tooltip("destroy");
          },
          fancyResultBox: (box, data) => {
            var ent;

            console.log("fancyResultBox: box.data()", box.data());
            setVals(vals => ++vals);

            if (data && data.data) {
              ent = data.data;
              if (ent.source == 2)
                box.prepend('<img src="/img/fb-tag.png" width="16" height="16" class="inf">');
              else if (ent.source == 3) {
                box.prepend('<img src="/img/gm-tag.png" width="16" height="16" class="inf">');
                box
                  .parent()
                  .tooltip("destroy")
                  .attr("title", ent.value)
                  .tooltip({ container: "body" });
              } else if (ent.source == 1)
                box.prepend(
                  '<img src="/img/or-mail-small.png" width="16" height="16" class="inf">'
                );
              else if (ent.source == 1001) {
                box.prepend(
                  '<img src="' +
                    HFN.prepUrl(ent.userobj.avatar) +
                    '" width="16" height="16" class="inf">'
                );
                box
                  .parent()
                  .tooltip("destroy")
                  .attr("title", ent.userobj.email)
                  .tooltip({ container: "body" });
              } else if (ent.source == 1002)
                box.prepend('<img src="/img/b/team.png" width="16" height="16" class="inf">');
            } else if (box.data("val")) {
              if (validateEmail(box.data("val"))) {
                box.prepend(
                  '<img src="/img/or-mail-small.png" width="16" height="16" class="inf">'
                );
                box
                  .parent()
                  .tooltip("destroy")
                  .attr("title", box.data("val"))
                  .tooltip({ container: "body" });
              }
            }
          },

          imgCloseFile: "",

          extraMulticompleteOpts: {
            suggestAt: ".tomails",
            boxRender: function(data) {
              console.log("BOX>>>> render box", data);
              var box = $("<div>"),
                img = $('<img width="32" height="32">');
              box
                .addClass("clearfix")
                .append($('<div class="iconw"></div>').append(img))
                .append(
                  $('<div class="textpart"></div>')
                    .append($('<span class="title"></span>').text(data.name))
                    .append($('<span class="explain"></span>'))
                );

              if (data.source == "import") {
                if (data.value == "Facebook") {
                  img.attr("src", "/img/fab.png");
                  //box.addClass('fbimport');
                } else if (data.value == "Gmail") {
                  img.attr("src", "/img/gmail.png");
                  //box.addClass('gmailimport');
                }
                box.find(".explain").text(
                  __("Import contacts from %source%", false, {
                    source: data.value
                  })
                );
              } else if (data.source == 3) {
                img.attr("src", "/img/gmail.png");
                box.find(".explain").text(data.value);
              } else if (data.source == 2) {
                img.attr(
                  "src",
                  "https://graph.facebook.com/" + data.value + "/picture?type=square"
                );
                box.find(".iconw").append('<img src="/img/fb-over.png" class="smicon">');
                box.find(".explain").text(__("Facebook Message"));
              } else if (data.source == 1) {
                img.attr("src", "/img/or-mail.png");
                box.find(".explain").text(__("Send Email"));
              } else if (data.source == 1001) {
                img.attr("src", HFN.prepUrl(data.userobj.avatar));
                box.find(".explain").text(data.userobj.email);
              } else if (data.source == 1002) {
                img.attr("src", "/img/b/team.png");
                box.find(".explain").text(
                  __("%num% member(s)", false, {
                    num: PCB.teamUserCount(data.teamobj)
                  })
                );
              }
              console.log("boxRender", box);
              return box;
            }
          }
        };
        setqEngine(qEngine);
        setCombo(new combo2(comboOptions));
      }, 0);
    },
    [inputPrepared]
  );

  useEffect(() => {
    const contacts = getSharedContacts(userContacts, bUserContacts, [], true);
    setContacts(contacts);
  }, [userContacts, bUserContacts]);

  useEffect(() => {
    if (inputPrepared && qEngine) {
      qEngine.clear();
      qEngine.local = contacts;
      qEngine.initialize(true);
    } else {
      prepareSendInput(contacts);
    }
  }, [contacts, inputPrepared, isBusiness, prepareSendInput, qEngine]);

  const isDeactivatedOrDeteted = inputVal => {
    let result = false;

    contacts.forEach(conatct => {
      const { userobj } = conatct;
      if (userobj) {
        if (userobj.email === inputVal) {
          result = !userobj.active || userobj.frozen;
        }
      }
    });
    return result;
  };

  const sendInvitations = () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    let vals = combo.getFullVals(true);
    let manuallyAdded = [];
    let emails = [];
    let busers = [];

    if (vals && !vals.length) {
      HFN.message(__("Enter valid e-mail."), "error");
      return;
    }

    let hasErrorEmail = false;

    for (let n = 0; n < vals.length; n++) {
      let error = "";
      let isGmail = false;
      let isBusinessUser = false;

      if (vals[n].data && [1, 3].indexOf(vals[n].data.source) != -1) {
        // gmails
        isGmail = true;
      } else if (vals[n].data && vals[n].data.source == 1001) {
        // business user
        isBusinessUser = true;
        if (isDeactivatedOrDeteted(vals[n].data.userobj.email)) {
          error = __("The user is inactive and can't receive mails.");
        }
      } else if (!isBusinessUser) {
        // not a business user
        error = __("error_email_doesnt_exist_business", "User with this email doesn\'t exist in your Business account.");
      } else if (!validateEmail(vals[n].val)) {
        // not a valid email
        error = __("This is not a valid contact or an email.");
      }

      if (!hasErrorEmail && error !== "") {
        hasErrorEmail = true;
      }

      if (error !== "") {
        vals[n].obj
          .addClass("error")
          .find(".combo-res")
          .tooltip("destroy")
          .attr("title", __(error))
          .tooltip({ container: "body" });
        continue;
      } else if (isGmail) {
        emails.push({ data: vals[n].data, obj: vals[n].obj });
      } else if (isBusinessUser) {
        if (
          !busers.some(el => (el.buser && el.buser.value) === (vals[n].data && vals[n].data.value))
        ) {
          busers.push({ data: vals[n].data.userobj, obj: vals[n].obj });
        } else {
          continue;
        }
      } else {
        emails.push({ data: { ...vals[n], source: 1 }, obj: vals[n].obj });
        if (!vals[n].data && manuallyAdded.indexOf(vals[n].val) == -1) {
          qEngine.add([{ name: vals[n].val, value: vals[n].val, source: 1 }]);
          manuallyAdded.push(vals[n].val);
        }
      }
    }

    if (hasErrorEmail) {
      setIsLoading(false);
      return;
    }

    if (!emails.length && !busers.length) {
      setIsLoading(false);
      return;
    }

    // setIsLoading(true);
    console.log("{ emails, busers }", { emails, busers });

    let allEmails = emails.concat(busers);
    let xhr = [];
    let successRet = [];
    let callbacksAtOnce = [];
    let manualToAdd = [];
    let hasError = false;

    allEmails.forEach(({ data, obj }) => {
      console.log("send email>>>>>>>>", { data, obj });
      const email = data.value || data.email || data.val;
      const params = {
        email: email,
        userid: data.id,
        teamid: teamId
      };

      xhr.push(
        apiMethod(
          "account_teamlink",
          params,
          res => {
            console.log("SUCC>>>>>", res);
            PCB.refreshAll();
            HFN.message(__("Users added to team(s)."));
            callbacksAtOnce.push(() => {
              successRet.push(res);
              obj.remove();
            });

            if (manuallyAdded.indexOf(email) != -1) {
              manualToAdd.push(email);
            }
            handleClose();
          },
          {
            errorCallback: ret => {
              setIsLoading(false);
              callbacksAtOnce.push(() => {
                console.log("ERROR>>>", ret);
                if (ret.result === 2019 || ret.result === 2024) {
                  obj.remove();
                  PCB.refreshAll();
                  HFN.message(__("Users added to team(s)."));
                  handleClose();
                } else if (ret.result === 2018) {
                  hasError = true;
                  obj
                    .addClass("error")
                    .find(".combo-res")
                    .tooltip("destroy")
                    .attr("title", __("This is not a valid contact or an email."))
                    .tooltip({ container: "body" });
                } else {
                  hasError = true;
                  obj
                    .addClass("error")
                    .find(".combo-res")
                    .tooltip("destroy")
                    .attr("title", __(ret.error))
                    .tooltip({ container: "body" });
                }
              });
            }
          }
        )
      );
    });

    $.when.apply($, xhr).then(() => {
      for (let n = 0; n < callbacksAtOnce.length; ++n) {
        callbacksAtOnce[n]();
      }

      if (!hasError && successRet.length !== 0) {
        setVals(0);
      }

      if (manualToAdd.length) {
        apiMethod("contactload", { source: 1, mails: manualToAdd.join(",") }, () => {});
        manualToAdd = [];
      }
    });
  };

  useEffect(() => {
    setIsOpen(true);
  }, []);

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Modal animate onClose={handleClose} opened={isOpen}>
      <OvalModalWrap>
        <OvalModalCloseV2 onClick={handleClose} />
        <InviteWrapper>
          <Style.Header>{__("business_invite_user_header", "Add users")}</Style.Header>
          <Style.Label>{__("Account user", "Account user")}</Style.Label>
          <InputEmailWrapper>
            <div className="tomails" />
          </InputEmailWrapper>
          <Style.Label>{__("Add to team", "Add to team")}</Style.Label>
          <BoxTeam>{teamName}</BoxTeam>
          <Style.Footer>
            <Button
              color="lightgray4"
              style={{
                marginRight: "5px"
              }}
              onClick={handleClose}
            >
              {__("Cancel")}
            </Button>
            <Button
              color="cyan"
              style={{
                marginLeft: "5px"
              }}
              disabledColor="#dddddd"
              loading={isLoading}
              onClick={sendInvitations}
            >
              {__("Add")}
            </Button>
          </Style.Footer>
        </InviteWrapper>
      </OvalModalWrap>
    </Modal>
  );
};

export default InviteUsersToTeamModal;

const InviteWrapper = styled(Style.Container)`
  text-align: left;

  .tomails {
    display: inline-block;
    width: 417px;
    height: 36px;
    font-size: 13px;
    min-height: 36px;
    height: auto;
    max-height: 80px;

    .combo-contain {
      height: 36px;
      padding: 5px;
      border: none;
      box-shadow: none;
      box-sizing: border-box;
      background: #fff !important;

      .combo-wrap {
        height: 26px;
        margin: 3px;
        box-sizing: border-box;
      }

      input {
        height: 22px;
        line-height: 22px;
        width: 400px !important;
        margin-bottom: 0;
        font-size: 14px;
        box-sizing: border-box;
        background: #fff !important;
      }

      input::placeholder {
        font-size: 13px;
      }

      .combo-res {
        height: 18px;
        line-height: 18px;
        margin: 0 5px 2px 0;
        border-radius: 12px;
        border: solid 1px #d1f0fb;
        background-color: #f4fdff;
        padding: 3px 10px;

        img.rem {
          height: 9px;
          width: 9px;
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          background-image: url(${require("../../../../root/img/share-icons/blue-close.png")});
          ${hiDPI(1.5)} {
            background-image: url(${require("../../../../root/img/share-icons/blue-close@2x.png")});
          }
        }

        .inf {
          margin: -1px 5px 0 0;
        }
      }

      .error {
        img.rem {
          height: 9px;
          width: 9px;
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          background-image: url(${require("../../../../root/img/share-icons/red-close.png")});
          ${hiDPI(1.5)} {
            background-image: url(${require("../../../../root/img/share-icons/red-close@2x.png")});
          }
        }
      }
    }
    .combo-contain.focu,
    .combo-contain.focu input {
      background: #fff !important;
    }
  }

  .textarea {
    width: 420px;
    box-sizing: border-box;
    border: 1px solid #cacaca;
    resize: none;
    border-top: none;
    outline: none;
    padding: 10px 5px;
    font-size: 13px;
    height: 63px;
    box-shadow: inset 0px 3px 0 0 rgba(0, 0, 0, 0.03);
    font-family: "Roboto", sans-serif;

    &:focus {
      background-color: #fff;
    }
  }
`;

const InputEmailWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  min-height: 36px;
  box-sizing: border-box;
  box-shadow: inset 0px 3px 0 0 rgba(0, 0, 0, 0.03);
  border-radius: 6px;
  margin-bottom: 17px;
  border: solid 1px #a1a1a1;
  background-color: #ffffff;
  overflow-y: auto;
  overflow-x: hidden;
  
  & ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

const BoxTeam = styled(Style.Box)`
  height: auto;
  padding: 10px;
  font-size: 15px;
`;
