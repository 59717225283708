// @flow
type item = {
  path: string,
  comments: number,
  encrypted: boolean,
  contents: Array<any>,
  created: string,
  folderid: number,
  fileid: number,
  icon: number,
  itemId: string,
  isfolder: boolean,
  ismine: boolean,
  isshared: boolean,
  ispublicroot: boolean,
  issount: boolean,
  modified: string,
  name: string,
  parentfolderid: number,
  thumb: boolean
};

export type selectedItemType = {
  itemId: string,
  isfolder: boolean,
  name: string
};

export const parseItem = (item: item) => {
  const { isfolder, folderid, fileid } = item;
  
  return Object.assign(item, {
    itemId: isfolder ? folderid.toString() : fileid.toString(),
  })
};

export const parseSelectedItem = ((item = {}) => {
  const { isfolder = 0, folderid = 0, fileid = 0, name = "" } = item;
  return {
    itemId: isfolder ? folderid.toString() : fileid.toString(),
    isfolder: isfolder,
    name: name
  }
});
