
import {
    ADDITIONAL_TRAFFIC_ID,
    MB_PER_TRAFFIC_COUNT,
    MB_PER_STORAGE_COUNT_FOR_TB,
    // MB_PER_STORAGE13_COUNT,
    MB_PER_STORAGE_COUNT,
    TB_PER_TRAFFIC_COUNT,
    CUSTOM4TB_PLAN_ID,
    ADD_STORAGE_1COUNT_PLAN_ID,
    ADD_STORAGE_4COUNT_PLAN_ID,
    ADD_STORAGE_8COUNT_PLAN_ID,
    ADD_STORAGE_12COUNT_PLAN_ID,
    ADD_STORAGE_20COUNT_PLAN_ID,
    CUSTOM_8COUNT_PLAN_ID,
    CUSTOM_20COUNT_PLAN_ID,
    PREMIUM_PLAN_ID,
    PREMIUM_PLUS_PLAN_ID,
    CUSTOM_PLAN_ID,
    FAMILY_CUSTOM_PLAN_ID,
    FAMILY2TB_PLAN_ID,
    MB_PER_FAMILY_STORAGE_COUNT,
    FAMILY_CUSTOM_2COUNT_PLAN_ID,
    FAMILY_CUSTOM_5COUNT_PLAN_ID,
    FAMILY_ADD_1COUNT_PLAN_ID,
    FAMILY_ADD_2COUNT_PLAN_ID,
    FAMILY_ADD_5COUNT_PLAN_ID
} from "./constants";

import { renderQuota } from "./utils";
  

export const getCustomPlanStorageCount = (planId) => { 
    if (typeof planId === "string" && planId.indexOf("_") !== -1) {
      const storageCount = planId.split("_")[1];
      const storageCountNumber = Number(storageCount);
  
      return storageCountNumber;
    }
  
    return 0;
  }
  
  export const getCurrentStorageCount = (userInfo) => {
    const userIsPremiumLifetime = userInfo && userInfo.premiumlifetime && (userInfo.plan === PREMIUM_PLAN_ID || userInfo.plan === PREMIUM_PLUS_PLAN_ID || userInfo.plan === CUSTOM_PLAN_ID || userInfo.plan === CUSTOM4TB_PLAN_ID);
    const userIsFamily = userInfo && (userInfo.plan === FAMILY2TB_PLAN_ID || userInfo.plan === FAMILY_CUSTOM_PLAN_ID || userInfo.plan === FAMILY2TB_PLAN_ID);
    const userHasStoragePlan = userIsPremiumLifetime || userIsFamily;

    if (!userHasStoragePlan || !userInfo) {
      return 0;
    }
  
    const { planparams, quota, plan } = userInfo;
    
    if (planparams && planparams.storage) {
      return planparams.storage;
    } else if (userIsFamily) {
      if (plan === FAMILY2TB_PLAN_ID) {
        return 1;
      } else if (plan === FAMILY_CUSTOM_PLAN_ID) {
        return 2;
      }
    };

    const MB = userIsFamily ? MB_PER_FAMILY_STORAGE_COUNT : MB_PER_STORAGE_COUNT;
    const thresh = 1024;
  
    return Math.round(quota / thresh / thresh / thresh / MB);
  }
  
  export const getCustomPlanStorage = (planId) => {
    const isCustomStorageId = planId === ADD_STORAGE_1COUNT_PLAN_ID || planId === ADD_STORAGE_4COUNT_PLAN_ID || planId === ADD_STORAGE_8COUNT_PLAN_ID || planId === ADD_STORAGE_12COUNT_PLAN_ID || planId === ADD_STORAGE_20COUNT_PLAN_ID || planId === CUSTOM_20COUNT_PLAN_ID || planId === CUSTOM_8COUNT_PLAN_ID;
    const isFamilyCustomId = planId === FAMILY_CUSTOM_2COUNT_PLAN_ID || planId === FAMILY_CUSTOM_5COUNT_PLAN_ID || planId === FAMILY_ADD_1COUNT_PLAN_ID || planId === FAMILY_ADD_2COUNT_PLAN_ID || planId === FAMILY_ADD_5COUNT_PLAN_ID;

    if (isCustomStorageId || isFamilyCustomId) {
      const storageCount = planId.split("_")[1];
      const storageCountNumber = Number(storageCount);
      const thresh = 1024;
      const MB_PER_STORAGE_COUNT_FOR_TB = 512;
      let MB_VALUE = MB_PER_STORAGE_COUNT_FOR_TB;
  
      if (isCustomStorageId && storageCountNumber === 1) {
        MB_VALUE = MB_PER_STORAGE_COUNT;
      } else if (isFamilyCustomId) {
        MB_VALUE = MB_PER_FAMILY_STORAGE_COUNT;
      }
  
      return (storageCountNumber * thresh * thresh * thresh * MB_VALUE);
    }
  
    return 0;
  }
  
  export const getTotalStorage = (planId, userInfo) => {
    const isFamilyCustomId = planId === FAMILY_CUSTOM_2COUNT_PLAN_ID || planId === FAMILY_CUSTOM_5COUNT_PLAN_ID || planId === FAMILY_ADD_1COUNT_PLAN_ID || planId === FAMILY_ADD_2COUNT_PLAN_ID || planId === FAMILY_ADD_5COUNT_PLAN_ID;
    const planStorage = planId ? getCustomPlanStorage(planId) : 0;
    
    if (userInfo) {
      const { quota, plan, planparams } = userInfo;
      let currentPlanQuota = quota;
      let cusrrentPlanStorageCount = 1;

      if (!(planparams && planparams.storage)) {
        if (isFamilyCustomId) {
          if (plan == FAMILY2TB_PLAN_ID) {
            cusrrentPlanStorageCount = 1;
            currentPlanQuota = MB_PER_FAMILY_STORAGE_COUNT * 1024 * 1024 * 1024 * cusrrentPlanStorageCount;
          } else if (plan == FAMILY_CUSTOM_PLAN_ID) {
            cusrrentPlanStorageCount = 2;
            currentPlanQuota = MB_PER_FAMILY_STORAGE_COUNT * 1024 * 1024 * 1024 * cusrrentPlanStorageCount;
          }
        }
      } else {   
        if (plan == FAMILY_CUSTOM_PLAN_ID && isFamilyCustomId) {
          cusrrentPlanStorageCount = planparams.storage;
          currentPlanQuota = MB_PER_FAMILY_STORAGE_COUNT * 1024 * 1024 * 1024 * cusrrentPlanStorageCount;
        }
      }

      return renderQuota((currentPlanQuota + planStorage));
    } else {
      return renderQuota(planStorage);
    } 
  }
  
  export const getPlanTtraffic = (additionalTraffic) => {
    const thresh = 1024;
    const planTraffic = additionalTraffic * MB_PER_TRAFFIC_COUNT * thresh * thresh * thresh;
  
    return renderQuota(planTraffic);
  }
  
  export const getUserTtrafficCount = (userInfo, skipSubscriptionCheck) => {
    let userTraffic = 0;
    const userHasSubscription = userInfo && userInfo.lastsubscription && userInfo.lastsubscription.status === "active";
    const userHasAdditionalTraffic = userHasSubscription && userInfo.lastsubscription.products.indexOf(ADDITIONAL_TRAFFIC_ID) !== -1;
  
    if ((userHasAdditionalTraffic || skipSubscriptionCheck) && (userInfo.planparams && userInfo.planparams.additionaltraffic)) {
      userTraffic = userInfo.planparams.additionaltraffic;
    }
  
    return userTraffic;
  }
  
  export const getTotalTraffic = (planId, userInfo, additionalTraffic) => {
    const thresh = 1024;
    const userTraffic = getUserTtrafficCount(userInfo);
  
    if (planId === ADD_STORAGE_1COUNT_PLAN_ID || planId === ADD_STORAGE_4COUNT_PLAN_ID || planId === ADD_STORAGE_8COUNT_PLAN_ID || planId === ADD_STORAGE_12COUNT_PLAN_ID || planId === ADD_STORAGE_20COUNT_PLAN_ID || planId === CUSTOM_20COUNT_PLAN_ID || planId === CUSTOM_8COUNT_PLAN_ID) {
      let totalStorage =  getCustomPlanStorageCount(planId);
  
      if (userInfo && userInfo.premiumlifetime && (userInfo.plan === PREMIUM_PLAN_ID || userInfo.plan === PREMIUM_PLUS_PLAN_ID || userInfo.plan === CUSTOM_PLAN_ID || userInfo.plan === CUSTOM4TB_PLAN_ID)) {
        totalStorage += getCurrentStorageCount(userInfo)
      };
  
      totalStorage = totalStorage < MAX_CUSTOM_TRAFFIC_COUNT ? totalStorage : MAX_CUSTOM_TRAFFIC_COUNT;
      const totalTrafficQuota = (totalStorage * thresh * thresh * thresh * MB_PER_STORAGE_COUNT);
    
      return renderQuota(totalTrafficQuota);
    } else if (planId === ADDITIONAL_TRAFFIC_ID && (additionalTraffic || userTraffic)) {
      const planTraffic = (additionalTraffic || userTraffic) * MB_PER_TRAFFIC_COUNT * thresh * thresh * thresh;
  
      return renderQuota(planTraffic);
    } else if (planId === FAMILY_ADD_1COUNT_PLAN_ID || planId === FAMILY_ADD_2COUNT_PLAN_ID || planId === FAMILY_ADD_5COUNT_PLAN_ID) {
      let totalTraffic =  getCustomPlanStorageCount(planId);

      if (userInfo && (userInfo.plan === FAMILY2TB_PLAN_ID || userInfo.plan === FAMILY_CUSTOM_PLAN_ID)) {
        totalTraffic += getCurrentStorageCount(userInfo)
      };

      const totalTrafficQuota = totalTraffic * MB_PER_TRAFFIC_COUNT * thresh * thresh * thresh;

      return renderQuota(totalTrafficQuota);
    }
  
    return renderQuota(0); 
  }