// @flow
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Menu, MenuItem } from "@szhsin/react-menu";

import ButtonIcon from "../images/svg/pcloud-pass/dots.svg";
import BlueLogoIcon from "../images/svg/pcloud-blue-logo.svg";
import PassLogoIcon from "../images/svg/pcloud-pass/pass_small_logo.svg";
import TransferLogoIcon from "../images/svg/pcloud-pass/transfer_small_logo.svg";
import DocsLogoIcon from "../images/svg/pcloud-pass/docs_small_logo.svg";
import pCloudSaveLogoIcon from "../images/svg/pcloud-pass/psave_small_logo.svg";

const PCloudServicesDropdown = ({ styleComponent = {} }) => {
  const [services, setServices] = useState([]);

  useEffect(() => {
    const servicesDomains = [
      {
        hrefs: [
          "https://my.pcloud.com",
          "https://e.pcloud.com",
          "https://u.pcloud.com",
          "https://e.pcloud.link",
          "https://e1.pcloud.link",
          "https://u.pcloud.link",
          "https://pclmy.pcloud.work",
          "https://emyqa4.pcloud.com",
          "https://emyqa5.pcloud.com",
        ],
        name: "my pCloud",
        icon: BlueLogoIcon
      },
      { hrefs: ["https://pass.pcloud.com", "https://passtest.pcloud.com"], name: "pCloud Pass", icon: PassLogoIcon },
      { hrefs: ["https://transfer.pcloud.com"], name: "pCloud Transfer", icon: TransferLogoIcon },
      { hrefs: ["https://docs.pcloud.com/"], name: "pCloud for Devs", icon: DocsLogoIcon },
      { hrefs: ["https://www.pcloud.com/download-free-online-cloud-file-storage.html?show=psave"], name: "pCloud Save", icon: pCloudSaveLogoIcon }
    ];
    const currentDomain = location.origin;

    setServices(
      servicesDomains.filter(domain => {
        const result = domain.hrefs.filter(href => href === currentDomain);
        return !result.length;
      })
    );
  }, []);

  const renderItem = ({ hrefs, name, icon: Icon }) => {
    return (
      <MenuItem key={hrefs[0]} href={hrefs[0]} target="_blank">
        <Icon style={{ marginRight: "12px" }} />
        {name}
      </MenuItem>
    );
  };

  return (
    <Wrapper style={styleComponent}>
      <Menu
        align="end"
        menuButton={({ open }) => (
          <div>
            <ButtonIcon className="button-icon" fill={open ? "#746de4" : "#707070"} />
          </div>
        )}
        transition
      >
        {services.map(renderItem)}
      </Menu>
    </Wrapper>
  );
};

export default PCloudServicesDropdown;

const Wrapper = styled.div`
  display: inline-block;

  .szh-menu {
    margin-top: 10px;
    width: 240px;
    border-radius: 9px;
    box-shadow: 2px 4px 15px 0 rgba(0, 0, 0, 0.15);
    background-color: #fff;
    font-weight: normal;
  }

  .szh-menu__item {
    padding: 0 16px;
    height: 48px;
    font-family: Roboto;
    font-size: 15px !important;
    color: #69696e !important;
    font-weight: normal !important;
  }

  .szh-menu__item--hover {
    background-color: #f8f7fe;
    color: #000 !important;
  }

  svg:hover {
    cursor: pointer;
    fill: #746de4;
  }
`;
