// @flow

import * as React from "react";
import styled from "styled-components";

import Button from "../../components/ButtonDefault";
import PublicFolderFeatures from "./Features";
import PublicFolderTop from "./Top";
import UpgradeButton from "./UpgradeButton";
import { TSpan, TDiv } from "../../components/intl";

const PublicFolderCreate = ({
  onCreateFolder,
  creatingFolder,
  cancreate,
  ispremium
}: {
  onCreateFolder: () => void,
  creatingFolder: boolean,
  cancreate: boolean,
  ispremium: boolean
}) =>
  <div>
    <PublicFolderTop />
    {!cancreate && <Sorry id="premium_for_public">Upgrade to Premium to enable it!</Sorry>}
    <Buttons>
      {cancreate &&
        ispremium &&
        <Button
          inline
          onClick={onCreateFolder}
          disabled={creatingFolder}
          loading={creatingFolder}
          style={{ minWidth: "180px" }}
        >
          <TSpan id="enable">Enable</TSpan>
        </Button>}
      {!ispremium && <UpgradeButton />}
    </Buttons>
    <PublicFolderFeatures />
  </div>;

export default PublicFolderCreate;

const Buttons = styled.div`
  text-align: center;
  margin-top: 20px;

  & > button {
    min-width: 180px;
    margin: 0 10px;
  }
`;

const Sorry = styled(TDiv)`
  text-align: center;
  margin: 10px 0 10px 0;
`;

const EnableTrialWrap = styled.div`
  display: inline-block;
  vertical-align: top;
  margin-right: 20px;

  & > button {
    min-width: 180px;
  }
`;

const Money = styled(TSpan)`
  line-height: 18px;
  font-size: 12px;
`;

const EnableTrial = props =>
  <EnableTrialWrap>
    <Button {...props}>
      <TSpan id="free_trial">Free Trial</TSpan>
    </Button>
    <Money id="try_seven_days">Try for 7 days</Money>
  </EnableTrialWrap>;
