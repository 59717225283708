// @flow

import * as React from "react";
import ReactTooltip from "react-tooltip";
import styled from "styled-components";

import { __ } from "../../lib/translate";
import ArrowIcon from "../../../root/img/svg/arrow-picker.svg";

type ItemProps = {
  iconSrc: string,
  itemId: string,
  name: string,
  disableSelection: boolean,
  isFolderSelectionOnly: boolean,
  isfolder: boolean,
  isSelected: boolean,
  onItemClick: (itemId: string, isfolder: boolean) => void,
  onItemDoubleClick: (isfolder: boolean, itemId: string, name: string) => void
};

const Item = ({
  iconSrc = "",
  itemId = "0",
  name = "",
  disableSelection = false,
  isFolderSelectionOnly = false,
  isfolder = false,
  isSelected = false,
  onItemClick = () => {},
  onItemDoubleClick = () => {}
}: ItemProps) => {
  const onClick = () => {
    if (!disableSelection) {
      onItemClick(itemId, isfolder);
    }
  };

  const onDoubleClick = () => {
    if (!disableSelection) {
      onItemDoubleClick(isfolder, itemId, name);
    }
  };

  const isFileSelectionDisabled = isFolderSelectionOnly && !isfolder;
  const isSelectionDisabled = disableSelection || isFileSelectionDisabled;

  return (
    <React.Fragment>
      <Row
        key={itemId}
        data-tip
        data-for={"item-disabled" + itemId}
        isSelectionDisabled={isSelectionDisabled}
        isSelected={isSelected}
        onClick={onClick}
        onDoubleClick={onDoubleClick}
      >
        <Icon
          isSelectionDisabled={isSelectionDisabled}
          src={iconSrc}
          alt="icon"
          onClick={onDoubleClick}
        />
        <ItemName isSelectionDisabled={isSelectionDisabled} onClick={onDoubleClick}>
          {name}
        </ItemName>
        {isSelected ? (
          <IconWrapper onClick={onDoubleClick}>
            <ArrowIcon />
          </IconWrapper>
        ) : null}
      </Row>
      {isfolder ? (
        <ReactTooltip
          disable={!disableSelection}
          id={"item-disabled" + itemId}
          effect="solid"
          multiline
        >
          {__("Can't write in this folder.")}
        </ReactTooltip>
      ) : null}
    </React.Fragment>
  );
};

export default Item;

export const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 0 10px;
  background: ${props => (props.isSelected ? "#eaeaea" : "#fff")};
  &:hover {
    background-color: ${props => (props.isSelected ? "#f4f4f4" : "#f8f8f8")};
  }
  box-sizing: border-box;
  height: 30px;
  opacity: ${props => (props.isSelectionDisabled ? "0.5" : "1")};
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1;

  &:hover {
    cursor: ${props => (props.isSelectionDisabled ? "initial" : "pointer")};
  }
`;

export const ItemName = styled.div`
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
  text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.5);
  user-select: none;
  text-align: left;

  &:hover {
    text-decoration: ${props => (props.isSelectionDisabled ? "none" : "underline")};
    cursor: ${props => (props.isSelectionDisabled ? "initial" : "pointer")};
  }
`;

const Icon = styled.img`
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  vertical-align: middle;

  &:hover {
    cursor: ${props => (props.isSelectionDisabled ? "initial" : "pointer")};
  }
`;
