import styled from "styled-components";

export const Title = styled.div`
  font-weight: bold;

  ${({size}) => {
    if (!size || size == "big") {
      return {
        "height": "31px",
        "padding-top": "16px",
        "padding-left": "8px",
        "margin": "10px 0 0 0"
      };
    } else if (size == "small") {
      return {
        "font-size": "13px",
        "line-height": "16px",
        "margin-bottom": "7px"
      };
    }
  }}
`;

export const Row = styled.div`
  margin: 0;
  padding: 10px 8px ;
  min-height: 30px;
  line-height: 30px;
  font-size: 13px;
  background: ${props => props.bg || "#fafafa"};
  box-sizing: border-box;

  &.condensed {
    padding: 6px 10px;
  }
  &:last-child {
    border-bottom: 1px solid ${props => props.borderColor || "#e9e9e9"};
  }

  &.custom-row {
    border-top: 1px solid${props => props.borderColor || "#e9e9e9"};
  }

  &.first-row {
    border-top: 2px solid #ddd;
  }
  &.no-border {
    border: none;
  }
  &.border-bottom {
    border-top: none;
    border-bottom: 1px solid${props => props.borderColor || "#e9e9e9"};
  }
  &.border-all {
    border: 1px solid ${props => props.borderColor || "#e9e9e9"}; 
  }
  :after {
    content: "";
    display: table;
    clear: both;
  }

  @media only screen and (max-width: 400px) {
    min-width: 300px;
  }
`;

export const Col = styled.div`
  display: inline-block;
  margin-bottom: -3px;

  &.text-wrap {
    width: 75%;
    line-height: 16px;
  }

  &.float, &.go-right {
    float: right;
  }
  :after {
    content: "";
    display: table;
    clear: both;
  }

  @media only screen and (max-width: 400px) {
    a {
      width: 65px;
    }
  }
`;
export const Border = styled.div`
  border: 1px solid ${props => props.color || "#eee"};
`