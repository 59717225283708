// @flow

import * as React from "react";
import styled from "styled-components";

type InputSelectProps = {
  placeholder?: string,
  value: mixed,
  options: Array<{ value: number | string, text: string, disabled?: boolean, readonly?: boolean }>
};

const InputSelect = ({ options, value, ...rest }: InputSelectProps) => (
  <Select value={value} {...rest}>
    {options.map(({ value, text, disabled, readonly, hidden }) => (
      <option value={value} key={value} disabled={disabled} readOnly={readonly} hidden={hidden}>
        {text}
      </option>
    ))}
  </Select>
);

export default InputSelect;

const Select = styled.select`
  line-height: 40px;
  height: 40px;
  padding: 0 10px;
  border: 1px solid #ccc;
  font-size: 14px;
  border-radius: 5px;
  box-sizing: border-box;
  min-width: initial;
  width: 100%;
  color: #000;
`;
