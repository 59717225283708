// @flow

import React, { Component } from "react";
import styled from "styled-components";
import { clearFix } from "polished";

import { __ } from "../lib/translate";

export type NotificationType = {
  name: string,
  flag: number,
  canedit: boolean,
  subscribed: boolean
};

type SubscriptionsSettingsProps = {
  types: Array<NotificationType>,
  update: (number, boolean) => void
};

const SubscriptionsSettings = ({ types, update }: SubscriptionsSettingsProps) =>
  <Wrap>
    {types.map(({ name, subscribed, flag, canedit }) =>
      <Type key={flag}>
        <input
          type="checkbox"
          name="types[]"
          value={subscribed ? "on" : ""}
          id={"type_" + flag}
          onChange={({ target: { checked } }) => update(flag, checked)}
          checked={subscribed}
          disabled={!canedit}
        />
        {subscribed}
        <TypeDescription htmlFor={"type_" + flag}>
          <TypeName>
            {__("subscription_type_" + flag + "_title", name)}
          </TypeName>
          <TypeExplain>
            {__("subscription_type_" + flag + "_description", "description")}
          </TypeExplain>
        </TypeDescription>
      </Type>
    )}
  </Wrap>;

export default SubscriptionsSettings;

const TypeName = styled.div`
  font-weight: bold;
  margin-bottom: 4px;
`;

const TypeExplain = styled.div`
  line-height: 17px;
  font-size: 12px;
`;

const Wrap = styled.div`
  ${clearFix()}
`;

const TypeDescription = styled.label`
  font-weight: normal;
  display: block;
  margin-left: 22px;
  cursor: pointer;
`;

const Type = styled.div`
  ${clearFix()}

  & > input {
    float: left;
    margin: 2px 0 0 0;
  }

  &:after {
    clear: both;
  }

  margin: 0 5px 10px 5px;

  &:last-child {
    margin-bottom: 0;
  }
`;
