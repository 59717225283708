// @flow

import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import apiMethod from "../../../api/apiMethod";
import { __ } from "../../../lib/translate";

import Button from "../../../components/ButtonDefault";
import { Container, HeaderModal, DescriptionModal } from "./StyledComponents";
import { Loader } from "../../PaymentForm/PaymentLoader";

type Props = {
  onSuccess: () => void,
  onClose: () => void
};

const CancelSubscriptionStep = ({ onSuccess = () => {}, onClose = () => {} }: Props) => {
  const dispatch = useDispatch();

  const isTrial = useSelector(
    ({ user }) => user.userinfo.account && user.userinfo.account.is_trial
  );
  const billingInfo = useSelector(({ business }) => business.billingInfo);
  const { subscriptions = [] } = billingInfo;
  const [loader, setLoader] = useState(false);

  const onSubmit = () => {
    const { billingprovider, id, pcloudmanaged, stripesubscriptionid } = subscriptions[0];
    const params = {
      billingprovider: billingprovider,
      subscriptionid: stripesubscriptionid || id,
      pcloudmanaged: pcloudmanaged ? 1 : 0
    };
    setLoader(true);
    apiMethod(
      "cancelpaymentsubscription",
      params,
      res => {
        console.log("RESSSS", res);
        setLoader(false);
        onSuccess();
        dispatch({ type: "SET_PROCESSING_PROFILE" });
      },
      {
        errorCallback: res => {
          HFN.message(
            __(
              "cancel_subsc_error_message",
              "There was a problem canceling your subscription. Please try again later."
            ),
            "error"
          );
          setLoader(false);
          onClose();
          console.log("ERRORR", res);
        }
      }
    );
  };

  if (loader) {
    return (
      <Container key="cancelSubsc" style={{ minHeight: "249px" }}>
        <Loader color="green" />
      </Container>
    );
  }

  return (
    <Container className="cancel-subs" key="cancelSubsc">
      <HeaderModal>
        {__("cancel_subsc_modal_header", "Are you sure you want to cancel?")}
      </HeaderModal>
      <DescriptionModal>
        {isTrial ? __("cancel_subs_modal_desc_trial") : __("cancel_subs_modal_desc")}
      </DescriptionModal>
      <ButtonWrapper>
        <Button
          color="lightgray4"
          style={{ marginRight: "5px" }}
          onClick={onSubmit}
        >
          {__("cancel_subs_modal_bnt_cancel", "Cancel subscription")}
        </Button>
        <Button
          color="green"
          style={{ marginLeft: "5px" }}
          onClick={onClose}
        >
          {__("cancel_subs_modal_bnt_keep", "Keep plan")}
        </Button>
      </ButtonWrapper>
    </Container>
  );
};

export default CancelSubscriptionStep;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
