import styled from "styled-components";

export const Container = styled.div`
  width: 580px;
  text-align: center;
  font-family: "Roboto", sans-serif;
  border-radius: 15px;
  box-shadow: 0px 10px 20px 0 rgba(0, 0, 0, 0.3);
  background-color: #ffffff;
  padding: 40px;
  box-sizing: border-box;

  &.cancel-subs {
    padding: 30px;
  }
`;

export const Header = styled.div`
  font-size: 17px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 35px;
`;

export const SuccHeader = styled(Header)`
  margin-bottom: 0;
  margin-top: 17px;
  width: 100%;
`;

export const SuccContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 520px;
`;

export const Description = styled.div`
  margin-top: 10px;
  font-size: 15px;
  font-weight: normal;
  text-align: center;
  width: 100%;
`;

export const HeaderModal = styled(Header)`
  margin-bottom: 30px;
`;

export const DescriptionModal = styled(Description)`
  margin: 0 0 30px 0;
`;

export const Table = styled.div`
  /* margin-top: 10px; */

  &:last-child {
    border-bottom: 1px solid #e9e9e9;
  }
`;

export const HeaderTable = styled.div`
  width: 780px;
  height: 27px;
  background: #f0f0f0;
  box-sizing: border-box;
  border-top: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
  font-family: "Roboto", sans-serif;
  font-size: 11px;
  font-weight: bold;
  color: #aeaeae;
  padding-left: 10px;
  text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.5);
  zoom: 1;
  line-height: 26px;
`;

export const BodyTable = styled.div`
  width: 780px;
  display: flex;
  margin: 15px 0;
  padding: 0 10px;
  justify-content: space-between;
  box-sizing: border-box;
`;

export const DescriptionTable = styled.div`
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
`;

export const ButtonLink = styled.span`
  cursor: pointer;
  text-decoration: underline;
  font-size: 12px;
`;

export const MinUsersNote = styled.div`
  visibility: ${props => props.show ? "visible" : "hidden"};
  color: #999999;
  padding-top: 5px;
  font-size: 12px;
  font-style: italic;
`;
