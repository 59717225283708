// @flow

import * as React from "react";
import styled from "styled-components";
import { connect } from "react-redux";

import ItemRow from "../components/ItemRow";
import EmptyRow from "../components/EmptyRow";
import { __ } from "../lib/translate";

const PublinkList = ({ loaded, error, items, folder, onFolderClick, thumbOptions }) =>
  loaded ? (
    <div>
      {error && <ErrorWrap>{error}</ErrorWrap>}
      {!error && (
        <div>
          <FolderWrap>
            {"parentfolderid" in folder && (
              <Back onClick={onFolderClick.bind(null, folder.parentfolderid)} />
            )}
            {folder.name}
          </FolderWrap>
          <ListWrap>
            {items.length ? (
              items.map(item => (
                <ItemRow
                  item={item}
                  key={item.id}
                  onClick={() => item.isfolder && onFolderClick(item.folderid)}
                  thumbOptions={thumbOptions}
                />
              ))
            ) : (
              <EmptyRow />
            )}
          </ListWrap>
        </div>
      )}
    </div>
  ) : (
    <LoadingWrap>Loading ...</LoadingWrap>
  );

export default connect(({ publiclink: { loaded, folders, openFolderId } }) => {
  if (!loaded) {
    return { loaded: false };
  } else if (openFolderId in folders) {
    return {
      loaded: true,
      items: folders[openFolderId].contents,
      folder: folders[openFolderId]
    };
  } else {
    return { loaded: true, error: __("wrong_folder", "Wrong Folder") };
  }
})(PublinkList);

const ListWrap = styled.div`
  margin: 0 10px 0 5px;
  color: #000;
`;

const LoadingWrap = styled.div`
  margin: 10px;
  color: #000;
`;

const ErrorWrap = styled.div`
  color: red;
  margin: 10px;
`;

const FolderWrap = styled.div`
  margin: 10px;
  font-size: 18px;
`;

const Back = styled.div`
  vertical-align: middle;
  display: inline-block;
  width: 24px;
  height: 24px;
  margin: 0 15px 0 0;
  background: url(${require("../../root/img/back-mob-push.png")});
  background-size: 14px 14px;
  background-position: center center;
  background-repeat: no-repeat;
`;
