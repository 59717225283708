import React from "react";
import { Menu, VolumeSlider, MuteButton } from '@vidstack/react';
import { VolumeIcon, VolumeLowIcon, MuteIcon } from "../../icons";

const MuteAndVolumeMenu = React.forwardRef(
  ({
    onVolumeSliderDragEnd = () => {}
  }, ref) => {

  const renderVolumeSlider = () => {
    return (
      <VolumeSlider.Root ref={ref} onDragEnd={onVolumeSliderDragEnd} className="plyr__slider" data-plyr="volume" aria-label="Volume" orientation="vertical">
        <div className="plyr__slider__track"></div>
        <div className="plyr__slider__thumb"></div>
      </VolumeSlider.Root>
    );
  };

  const renderMuteButton = () => {
    return (
      <MuteButton className="plyr__control" data-no-label data-plyr="mute">
        <MuteIcon className="mute-icon icon--pressed" />
        {/* <VolumeLowIcon className="volume-low-icon icon--not-pressed" /> */}
        <VolumeLowIcon className="volume-low-icon icon--not-pressed" />
        <VolumeIcon className="volume-high-icon icon--not-pressed" />
        {/* <span className="label--pressed plyr__tooltip">Unmute</span>
        <span className="label--not-pressed plyr__tooltip">Mute</span> */}
      </MuteButton>
    );
  }

  return (
    <div className="mute-and-volume-menu plyr__controls__item plyr__menu">
      <Menu.Root>
        <Menu.Button className="plyr__control" data-plyr="mute-and-volume">
          <>
            <MuteButton className="plyr__control inactive-mute-btn" data-no-label data-plyr="mute"></MuteButton>
            <MuteIcon className="mute-icon" />
            <VolumeLowIcon className="volume-low-icon icon--not-pressed" />
            <VolumeIcon className="volume-high-icon icon--not-pressed" />
            <span className="plyr__tooltip">Volume</span>
          </>
        </Menu.Button>
        <Menu.Items className="plyr__menu__container" placement="top end">
          <div className="plyr__controls__item" data-plyr="mute-and-volume">
            {renderVolumeSlider()}
            {renderMuteButton()}
          </div>
        </Menu.Items>
      </Menu.Root>
    </div>
  );
});

export default MuteAndVolumeMenu;