import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import GridDownloadLinkTraffic from "./TrafficStats";
import Dropdown from "../Dropdown";
import Arrow from "../Arrow";
import { __ } from "../../lib/translate";

const tabs = ["views", "downloads", "traffic"];
const showTitles = {
	traffic: __("traffic", "Traffic"),
	views: __("views", "Views"),
	downloads: __("downloads", "Downloads")
};

const dropDownList = tabs.map(id => ({ title: showTitles[id], extra: { id: id }}));
const isMobile = HFN.config.isMobile();

export default class GridDownloadLinkStats extends Component {
	static propTypes = {
		show: PropTypes.oneOf(tabs).isRequired
	};

	static defaultProps = {
		show: "traffic"
	};

	constructor(props) {
		super(props);

		this.state = { show: props.show };
	}

	render() {
		const { show } = this.state;

		return (
			<Wrap className={isMobile ? "mobile" : ""}>
				<Head>
					Stats
					{/*{showTitles[show]}*/}
					<Dropdown list={dropDownList} overwriteTip={{ right: "8px" }} onSelect={({extra: { id }}) => this.setState({ show: id})}>
						{showTitles[show]}
            <Arrow
							color="#000"
							size={5}
							direction="bottom"
						/>
					</Dropdown>
					{/*
          <Tabs
            show={show}
            onSwitch={show => this.setState({ show: show })}
          />
          */}
				</Head>
				<GridDownloadLinkTraffic {...this.props} show={show} />
			</Wrap>
		);
	}
}

function ucfirst(str) {
	return str.substring(0, 1).toUpperCase() + str.substring(1);
}

const Head = styled.div`
	background-color: #fafafa;
	border-top: 1px solid #e9e9e9;
	border-bottom: 1px solid #e9e9e9;
  height: 40px;
  line-height: 40px;
  padding: 0 0 0 10px;
  font-size: 13px;

  & > .dropdown-holder {
    float: right;
    margin-right: 10px;
    cursor: pointer;

    & > div {
      margin: 2px 9px 0px 6px;
      vertical-align: middle;
      display: inline-block;
    }
  }
`;

const Switch = styled.div`
  float: right;
  height: 40px;
  line-height: 40px;
  margin-right: 10px;

  & > b {
    margin: 0 4px 0 4px;
  }

  & > span {
    margin: 0 4px 0 4px;
    display: inline-block;
    cursor: pointer;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
`;

const Tabs = ({ show, onSwitch }) => {
	return (
		<Switch>
			{tabs.map(
				view =>
					view === show
						? <b key={view}>{view}</b>
						: <span key={view} onClick={onSwitch.bind(null, view)}>
								{view}
							</span>
			)}
		</Switch>
	);
};

const Wrap = styled.div`
  width: 100%;
	box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05);
	
	&.mobile {
		box-shadow: none;
	}
`;
