// @flow

import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import Modal, { OvalModalWrap, OvalModalCloseV2 } from "../../Modal";
import Button from "../../ButtonDefault";
import * as Style from "../styledComponents";

import apiMethod from "../../../api/apiMethod";
import { __ } from "../../../lib/translate";
import { errorKeys } from "../../../lib/errors";
import { renderQuota } from "../../../../web-shared/utils";
import {
  BUSINESS_MIN_FREE_OWNER_QUOTA,
  BUSINESS_QUOTA_STEP,
  BUSINESS_MIN_USER_QUOTA
} from "@pcloud/web-utilities/dist/config/constants";

import ManageMemberQuotaSlider from "../../../containers/Business/MemberQuotaManagement/ManageMemberQuotaSlider";

type Props = {
  id: number,
  firstname: boolean,
  lastname: number,
  email: string,
  quota: number,
  usedquota: number
};

const ManageMemberQuotaModal = ({
  id = 0,
  firstname = "",
  lastname = "",
  email = "",
  quota = 0,
  usedquota = 0
}: Props) => {
  const [isOpen, setIsOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [shouldChangeQuota, setShouldChangeQuota] = useState(true);
  const [canProceed, setCanProceed] = useState(false);
  const [updatedQuota, setUpdatedQuota] = useState(0);
  const accountInfo = useSelector(({ business }) => business.accountInfo);
  const { account: { owner = {} } = {} } = accountInfo;
  const userQUota = useSelector(({ user }) => user.userinfo.quota);
  const businessMemners = useSelector(({ business }) => business.users);
  const currenMember = businessMemners.find(member => member.id === id) || {};
  const { quota: memberQuota = 0, usedquota: memberUsedQuota = 0 } = currenMember;
  const { quota: accountQuota, usedquota: accountUsedQuota } = owner;
  const freeAccountQuota = accountQuota - accountUsedQuota;

  useEffect(() => {
    canChangeMemberQuota();
  }, [canChangeMemberQuota]);

  const onEnter = useCallback(
    (e: any) => {
      if (e.keyCode === 13) {
        changeStorage();
      }
    },
    [changeStorage]
  );

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("keyup", onEnter);
    } else {
      document.removeEventListener("keyup", onEnter);
    }
  }, [isOpen, onEnter]);

  const handleClose = () => {
    setIsOpen(false);
  };

  const canChangeMemberQuota = () => {
    const ownerMinQuota = (accountQuota % BUSINESS_QUOTA_STEP) + BUSINESS_MIN_USER_QUOTA;
    const hasAvailableOwnerQuota = hasAvailableFreeQuota(
      freeAccountQuota,
      accountUsedQuota,
      ownerMinQuota
    );
    const memberFreeQuota = memberQuota - memberUsedQuota;
    const hasAvailableMemberQuota = hasAvailableFreeQuota(
      memberFreeQuota,
      memberUsedQuota,
      BUSINESS_MIN_USER_QUOTA
    );

    if (!hasAvailableOwnerQuota && !hasAvailableMemberQuota) {
      setShouldChangeQuota(false);
    }
  };

  const hasAvailableFreeQuota = (freeQuota, usedQuota, minQuota) => {
    if (usedQuota < minQuota) {
      return freeQuota - minQuota > BUSINESS_QUOTA_STEP;
    } else {
      return freeQuota > BUSINESS_QUOTA_STEP;
    }
  };

  const getUpdatedQuota = updatedQuota => {
    setCanProceed(updatedQuota != memberQuota);
    setUpdatedQuota(updatedQuota);
  };

  const getMaxValue = () => {
    const freeAccountQuota = accountQuota - accountUsedQuota;

    if (freeAccountQuota > BUSINESS_MIN_FREE_OWNER_QUOTA) {
      return memberQuota + freeAccountQuota - BUSINESS_MIN_FREE_OWNER_QUOTA;
    } else {
      return memberQuota;
    }
  };

  const changeStorage = useCallback(() => {
    if (isLoading || !canProceed) {
      return;
    }

    setIsLoading(true);

    apiMethod(
      "account_userchangequota",
      { userid: id, quota: updatedQuota },
      () => {
        PCB.refreshAll();
        HFN.message(__("family_manage_quota_updated"));
        handleClose();
      },
      {
        errorCallback: ({ error, result }) => {
          setIsLoading(false);

          if (errorKeys[result]) {
            HFN.message(__(errorKeys[result]), "error");
          } else {
            handleClose();
            HFN.message(__("something_went_wrong_refresh_and_try_again"), "error");
            throw new Error(error);
          }
        }
      }
    );
  });

  const maxValue = Math.ceil(getMaxValue() / BUSINESS_QUOTA_STEP) * BUSINESS_QUOTA_STEP;
  const minStartValue = memberUsedQuota
    ? Math.ceil(memberUsedQuota / BUSINESS_QUOTA_STEP) * BUSINESS_QUOTA_STEP
    : BUSINESS_QUOTA_STEP;
  const startValue = Math.ceil(memberQuota / BUSINESS_QUOTA_STEP) * BUSINESS_QUOTA_STEP;

  console.log("owenerQuota !!!!!!!", renderQuota(accountQuota));
  console.log("owner free quota!!!!!!", renderQuota(freeAccountQuota));
  console.log("userQuota!!!!!!!", renderQuota(userQUota));
  console.log("memberQuota!!!!!!!", renderQuota(memberQuota));
  console.log("member used quota", renderQuota(memberUsedQuota));
  console.log("member free quota", renderQuota(memberQuota - memberUsedQuota));
  console.log("ownerUsedQuota", renderQuota(accountUsedQuota));
  console.log("startValue", renderQuota(startValue));
  console.log("maxValue", renderQuota(maxValue));
  console.log("maxValue bytes", maxValue);
  console.log("minStartValue", renderQuota(minStartValue));

  return (
    <Modal onClose={handleClose} opened={isOpen}>
      <OvalModalWrap>
        <OvalModalCloseV2 onClick={handleClose} />
        <Style.Container>
          <Style.Header>
            {__("manage_business_storage_header", "Manage user's storage")}
          </Style.Header>
          <Style.Label>{__("Name")}</Style.Label>
          <BoxReset>
            <b>{`${firstname} ${lastname}`}</b>
            {` (${email})`}
          </BoxReset>
          {shouldChangeQuota ? (
            <React.Fragment>
              <Style.Label>{__("manage_business_storage_quota", "Storage quota")}</Style.Label>
              <ManageMemberQuotaSlider
                startValue={startValue}
                minStartValue={minStartValue}
                maxValue={maxValue > startValue ? maxValue : startValue}
                getStorageValue={getUpdatedQuota}
                usedquota={usedquota}
                quota={quota}
              />
            </React.Fragment>
          ) : (
            <NotEnoughSpace>{__("family_accountfull_tooltip_error")}</NotEnoughSpace>
          )}

          <Style.Footer>
            <Button
              color="lightgray4"
              style={{
                marginRight: "5px"
              }}
              onClick={handleClose}
            >
              {__("Cancel")}
            </Button>
            <Button
              loading={isLoading}
              color="cyan"
              style={{
                marginLeft: "5px"
              }}
              disabled={!canProceed}
              onClick={changeStorage}
            >
              {__("Save")}
            </Button>
          </Style.Footer>
        </Style.Container>
      </OvalModalWrap>
    </Modal>
  );
};

export default ManageMemberQuotaModal;

const BoxReset = styled(Style.Box)`
  display: block;
  font-weight: normal;
  text-align: left;
  margin-bottom: 20px;
`;

const NotEnoughSpace = styled.div`
  padding: 55px 0;
  box-sizing: border-box;
  border-radius: 6px;
  border: solid 1px #fcf2d5;
  background-color: #fff8e0;
  font-size: 13px;
  font-weight: normal;
  text-align: center;
`;
