import React from 'react';
import {BoxSongArtistWrapper, BoxSongDetailsWrapper, BoxSongNameWrapper} from "../../../userinterface/styles";
import usePlayerContext from "../../../hooks/usePlayerContext";
import LongTextAnimationContainer from "../../../../LongTextAnimationContainer";

const BoxSongDetails = () => {
    const {
        additionalClass,
        currentSong,
        playerVariant,
        isMobile
    } = usePlayerContext();

    const showSongArtist = (!playerVariant.isMinimize || isMobile) && !playerVariant.isFullPage;

    const renderContent = () => {
        return (
            <>
                <BoxSongNameWrapper
                    className={additionalClass}
                >
                    <LongTextAnimationContainer
                        delay={200}
                        changeModifier={currentSong?.name+JSON.stringify(playerVariant)}
                        fixAnimation={true}
                    >
                        {!showSongArtist && (
                            currentSong.title && currentSong.artist ?
                                currentSong.artist + ' - ' + currentSong.title :
                                currentSong?.title ?? currentSong?.name ?? '-'
                        )}
                        {showSongArtist && (currentSong?.title ?? currentSong?.name ?? '-')}
                    </LongTextAnimationContainer>
                </BoxSongNameWrapper>
                {showSongArtist && (currentSong?.artist || !playerVariant.isMinimize) && (
                    <BoxSongArtistWrapper
                        className={additionalClass}
                    >
                        <LongTextAnimationContainer
                            delay={200}
                            changeModifier={currentSong?.artist+JSON.stringify(playerVariant)}
                            fixAnimation={true}
                        >
                            {currentSong?.artist ?? '\u00A0'}
                        </LongTextAnimationContainer>
                    </BoxSongArtistWrapper>
                )}
            </>
        );
    }

    return (
        <>
            <BoxSongDetailsWrapper
                className={additionalClass}
                initial={{ height: 0, opacity: 0 }}
                animate={{ height: '100%', opacity: 1 }}
                transition={{duration: 0}}
                layout={playerVariant.isMiniPlayer && !playerVariant.isMinimize}
            >
                {renderContent()}
            </BoxSongDetailsWrapper>
        </>
    );
}

export default BoxSongDetails;