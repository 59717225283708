import React, { useState } from "react";
import Componentify from "react-componentify";

import Modal, { OvalModalWrap, OvalModalCloseV2 } from "../Modal";
import Button from "../ButtonDefault";
import { __ } from "../../lib/translate";
import { boldConverter } from "../../lib/componentifyConverters";
import useUploadContext from './hooks/useUploadContext';
import { Content, Body, ButtonWrapper, Text, Title } from "./sharedStyles";

const WarningOnUploadModal = () => {
  const {
    isWarningOnUploadModalOpen,
    handleWarningOnUploadModalClose,
    handleWarningOnUploadModalYesClick,
  } = useUploadContext();
  return (
    <Modal animate shouldCloseOnOverlayClick={false} onClose={handleWarningOnUploadModalClose} opened={isWarningOnUploadModalOpen}>
      <OvalModalWrap>
        <OvalModalCloseV2 onClick={handleWarningOnUploadModalClose} />
        <Content>
          <React.Fragment>
            <Body>
              <Title>{__('upload_manager_close_title', 'Close Upload Manager')}</Title>
              <Text>
                <Componentify
                  text={__(
                    "upload_manager_close_description",
                    "There are still active uploads. Closing the Upload manager will cancel them. How do you want to proceed?"
                  )}
                  converters={[boldConverter]}
                />
              </Text>
            </Body>
            <ButtonWrapper>
              <Button inline color="lightgray4" onClick={handleWarningOnUploadModalYesClick}>
                {__('Close', 'Close')}
              </Button>
              <Button inline color="cyan" onClick={handleWarningOnUploadModalClose}>
                {__('upload_manager_close_cancel_action', 'Keep uploading')}
              </Button>
            </ButtonWrapper>
          </React.Fragment>
        </Content>
      </OvalModalWrap>
    </Modal>
  );
};

export default WarningOnUploadModal;