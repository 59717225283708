import React from "react";

import {
  SettingsWrapper,
  SettingsHeader,
  SettingsTitle,
  SettingsDescription
} from "./SharedStyledComponents";
import TopBar from "./TopBar";

import { __ } from "../../lib/translate";

type Props = {
  allSettingsBack: any,
  bodyHeight: number,
  parentRef: any
};

const BrandingUpgradeSetting = ({ allSettingsBack, bodyHeight, parentRef, currentSettingId }: Props) => {
  const renderHeader = () => {
    return (
      <SettingsHeader>
        <SettingsTitle>{__("branding-intro-title")}</SettingsTitle>
        <SettingsDescription>{__("branding-intro-description-free")}</SettingsDescription>
      </SettingsHeader>
    );
  };

  return (
    <SettingsWrapper
      bodyHeight={bodyHeight}
      ref={parentRef}
    >
      <div>
        <TopBar settingId={currentSettingId} allSettingsBack={allSettingsBack} showAlert={true}/>
        {renderHeader()}
      </div>
    </SettingsWrapper>
  );
};

export default BrandingUpgradeSetting;