import subscriptions from "../lib/subscriptions";
import apiMethod from "../api/apiMethod";
import { getBillingInfoCall } from "../api/business";

const events = {
  params: {},
  _set: function (params) {
    $.extend(this.params, params);
  },
  init: function (token) {
    if (HFN.config.isDlink())
      return;

    this.params.auth = token;

    var self = this;
    apiMethod('eventslast', { auth: token }, function (ret) {
      self._set(ret.events);

      subscriptions.setState('publinks', 'ready');
      subscriptions.setState('uploadlinks', 'ready');

      if (HFN.config.user.business) {
        subscriptions.setState('accountinfo', 'ready');
      }
    }, { cacheTime: 0, forceFresh: true, errorCallback: function (ret) {} });

    subscriptions.add('publinks', self);
    subscriptions.add('uploadlinks', self);

    if (HFN.config.user.business) {
      subscriptions.add('accountinfo', self);
    }
  },
  getParams: function () {
    return this.params;
  },
  handleEvents: function (ret) {

    console.log('Received ret: ', ret);

    if (ret.publinkid)
      this.params.publinkid = ret.publinkid;
    if (ret.uploadlinkid)
      this.params.uploadlinkid = ret.uploadlinkid;

    subscriptions.setState('publinks', 'ready');
    subscriptions.setState('uploadlinks', 'ready');

    if (HFN.config.user.business) {
      subscriptions.setState('accountinfo', 'ready');
    }

    // process ...

    if (ret.publinks)
      this.handlePublinks(ret.publinks);
    if (ret.uploadlinks)
      this.handlePuplinks(ret.uploadlinks);
    if (ret.from == 'accountinfo')
      this.handleAccountInfo(ret.accountinfo);
  },

  handlePublinks: function (publinks) {
    console.log('received publinks', publinks);

    HFN.cache.expire(HFN.cache.cacheid('publinks', 'all'));
    HFN.cache.expireMatch('api-listpublinks');
    HFN.refreshSharesAll();
  },

  handlePuplinks: function (puplinks) {
    console.log('received puplinks', puplinks);

    HFN.cache.expireMatch(HFN.cache.cacheid('puplinks', 'all'))
    HFN.cache.expireMatch('api-listuploadlinks');
    HFN.refreshSharesAll();
  },

  handleAccountInfo: function() {
    console.log('Subscription diff');

    if (HFN.pages.current === 'b_account') {
      HFN.pages.refresh();
      HFN.message('Account Updated.');
    }

    if (HFN.pages.current === 'b_billing') {
      getBillingInfoCall(function(ret) {
        delete ret.result
        HFN.state.dispatch(HFN.state.actions.business.updateBillingInfo(ret));
      });
    }
  }
}

export default events;
