// @flow
import React from "react";

import Button from "../ButtonDefault";
import { Title, Row, Col } from "../tables/dark";
import { __ } from "../../lib/translate";

type Props = {
  onChangeClick: () => void
};

const Begin = ({ onChangeClick }: Props) => {
  console.log("change clk", onChangeClick);
  return (
    <React.Fragment>
      <Title key="title">{__("heading_change_crypto_pass")}</Title>
      <Row className="first-row">
        <Col className="text-wrap" style={{ width: "345px" }}>
          {__("change_crypto_pass_desc_step_1")}
        </Col>
        <Col className="float" style={{ width: "240px" }}>
          <Button size="big" borderRadius="3" color="green" onClick={onChangeClick}>
            {__("button_change_crypto_pass")}
          </Button>
        </Col>
        <div className="clearfix" />
      </Row>
    </React.Fragment>
  );
};

export default Begin;
