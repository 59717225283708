// @flow

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { List, Map } from "immutable";

import { __ } from "../../lib/translate";
import NavigationFolder from "./NavigationFolder";
import { ROOT_FOLDER_NAME, CRYPTO_FOLDER } from "./constants";
import { ROOT_FOLDER_ID } from "@pcloud/web-utilities/dist/config/constants";

type Folder = {
  name: string,
  contents: List<any>
};

type NavigationProps = {
  path: List<string>,
  folders: Map<string, Folder>,
  onNameClick: () => void
};

const Navigation = React.memo(
  ({ path = List(), folders = Map(), onNameClick = () => {} }: NavigationProps) => {
    const [breadcrumb, setBreadcrumb] = useState(List());

    useEffect(() => {
      if (path.size > 5) {
        const lastFour = path.slice(-3, path.size);

        setBreadcrumb(List([path.first(), { folderid: "ellipsis" }]).concat(lastFour));
      } else {
        setBreadcrumb(path);
      }
    }, [path]);

    const getFolderName = (item: any, index: number): string => {
      console.log("item",  item)

      const { folderid, encrypted, name } = item;
      let itemName = "";

      if (encrypted && index == ROOT_FOLDER_ID) {
        itemName = __(CRYPTO_FOLDER);
      } else if (encrypted) {
        itemName = HFN.metaName(item);
      } else if (folderid == ROOT_FOLDER_ID) {
        itemName = __(ROOT_FOLDER_NAME);
      } else if (name) {
        itemName = name;
      }
      return itemName;
    };

    const renderFolder = (onNameClick: () => void) => {
      return (item: string, index) => {
        const { folderid } = item;
        const folderName = getFolderName(item, index);
        const shouldRenderIcon = index !== 0;

        return (
          <NavigationFolder
            index={index}
            key={folderid}
            itemId={folderid}
            name={folderName}
            onNameClick={() => onNameClick(folderid)}
            shouldRenderIcon={shouldRenderIcon}
          />
        );
      };
    };
    console.log("breadcrumb", breadcrumb)
    return <Path>{breadcrumb.map(renderFolder(onNameClick))}</Path>;
  }
);

export default Navigation;

const Path = styled.div`
  display: flex;
  padding: 0 10px;
  height: 40px;
  align-items: center;
`;
