import {
    BOX_THUMB_SIZE,
    DEFAULT_THUMB, DEFAULT_THUMB_BIG,
    MINI_MOBILE_THUMB_SIZE,
    MINIMIZE_MOBILE_THUMB_SIZE,
    MINIMIZE_THUMB_SIZE,
    PAGE_PLAYER_THUMB_SIZE, STOP_ANALYZER
} from "./constants";
import {fileext} from "../../lib";

export const generateShuffleQueue = (queue, currentSong) => {
    let shuffleQueue = queue.map(item => item.fileid).sort(function (a, b) {
        return Math.random() - 0.5;
    });
    shuffleQueue = shuffleQueue.filter((item) => {
        return item !== currentSong.fileid
    })
    shuffleQueue.push(currentSong.fileid);

    return shuffleQueue;
}

export const getFileIds = (list, asArray = false) => {
    const fileIdsArray = list.map(item => item.fileid);
    return !asArray ? fileIdsArray.join(',') : fileIdsArray;
}
export const getMinifyData = (item) => {
    return {
        contenttype: item.contenttype,
        title: item.title,
        name: item.name,
        artist: item.artist,
        fileid: item.fileid,
        id: item.id,
        size: item.size,
        thumb: item.thumb,
        code: item.code,
        audiolink: item.audiolink,
    }
};


export const combineQueues = (currentQueue, newQueue) => {
    const tmpQueue = [...currentQueue];
    newQueue.forEach(function (newItem) {
        const existItem = tmpQueue.find((item) => {
            return item.fileid === newItem.fileid;
        });
        if (!existItem) {
            tmpQueue.push(newItem);
        }
    })
    return tmpQueue;
}

export const getThumbIdenticator = (fileId, size) => {
    return fileId + '_' + size;
}

export const getThumb = (fileId, thumbs, width = 32, height = 32) => {
    const thumbData = thumbs.get(getThumbIdenticator(fileId, width+'x'+height));
    return thumbData ? thumbData : DEFAULT_THUMB;
}

export const animateBars = (analyser, canvas, canvasCtx, dataArray, bufferLength, isWhiteBackground) => {
    analyser.getByteFrequencyData(dataArray);
    const primeColor = isWhiteBackground ? "#017986" : "#17BED0";
    canvasCtx.fillStyle = primeColor;
    const HEIGHT = canvas.height;
    const WIDTH = canvas.width;
    let barWidth = 3;
    let barHeight;
    let x = 0;

    for (let i = 0; i < bufferLength; i++) {
        barHeight = Math.max(3, (dataArray[i] / 255) * HEIGHT);
        const blueHex = [primeColor];
        canvasCtx.beginPath();
        canvasCtx.strokeStyle = 'rgba(0,0,0,0)';
        canvasCtx.fillStyle = blueHex;
        canvasCtx.roundRect(x, HEIGHT - barHeight, barWidth, barHeight, 5);
        canvasCtx.fill();
        canvasCtx.stroke();

        if (x > WIDTH + barWidth + 2) {
            break;
        }

        x += barWidth + 2;
    }
}

export const getAdditionalClass = (playerVariant, isMobile, isPublic, isSingle) => {
    let additionalClass;
    if (playerVariant.isFullPage) {
        additionalClass = 'fullPagePlayer';
    } else if (playerVariant.isMiniPlayer && playerVariant.isMinimize) {
        additionalClass = 'minimizePlayer';
    } else if (playerVariant.isMiniPlayer) {
        additionalClass = 'miniPlayer';
    } else {
        additionalClass = 'standartPlayer';
    }

    if (isMobile) {
        additionalClass += ' isMobile'
    }

    if (isPublic) {
        additionalClass += ' isPublic'
    }

    if (isSingle) {
        additionalClass += ' oneSong'
    }
    return additionalClass;
}

export const getPlayerState = (playerVariant) => {
    let state = 'queue';
    if (playerVariant.isFullPage) {
        state = 'fullpage';
    } else if (playerVariant.isMiniPlayer && playerVariant.isMinimize) {
        state = 'minimized';
    } else if (playerVariant.isMiniPlayer) {
        state = 'expanded';
    }

    return state;
}

export const getBoxThumbSize = (playerVariant, isMobile) => {
    if (playerVariant.isMinimize) {
        return isMobile ? MINIMIZE_MOBILE_THUMB_SIZE : MINIMIZE_THUMB_SIZE;
    }
    if (playerVariant.isFullPage) {
        return PAGE_PLAYER_THUMB_SIZE;
    }
    if (playerVariant.isMiniPlayer && isMobile) {
        return MINI_MOBILE_THUMB_SIZE;
    }
    return BOX_THUMB_SIZE;
}

export const getPageHeaderHeight = (isMobile, isPublic) => {
    const headerContainer = !isMobile || isPublic ?
        document.querySelector('.header-wrap') :
        document.querySelector('.header-wrap .middle');

    return headerContainer ? headerContainer.offsetHeight : 0;
}

export const turnOffOverflow = () => {
    document.body.style.overflow = "hidden";
    document.documentElement.style.overflow = "hidden";
}

export const turnOnOverflow = () => {
    document.body.style.overflow = "auto";
    document.documentElement.style.overflow = "auto";
}

export const checkIsWhiteBackground = (palette) => {
    let colorSum = 0;
    let middleColorSum = (255 / 2) * 3 * palette.length;

    let tmpPalette = [...palette];
    if (tmpPalette.length >= 4) {
        if (tmpPalette.length > 7) {
            tmpPalette.shift();
            tmpPalette = tmpPalette.slice(0, 7);
        } else if (tmpPalette.length < 7) {
            tmpPalette.shift();
        }
    }

    tmpPalette.forEach((item) => {
        colorSum += item.r + item.g + item.b;
    });
    return middleColorSum < colorSum;
}

export const timeDifference = (timeStart, timeEnd) => {
    const difference = timeEnd - timeStart;

    const hours = Math.floor(difference/1000/60/60);
    const minutes = Math.floor(difference/1000/60);
    const seconds = Math.floor(difference/1000);

    return {
        seconds,
        minutes,
        hours,
    }
}

export const mediaSessionActions = {
    update: (options) => {
        if (!"mediaSession" in navigator) {
            return;
        }
        const thumbUrl = options.loadedThumbs.get(getThumbIdenticator(options.currentSong.fileid, '512x512'));

        try {
            navigator.mediaSession.metadata = new MediaMetadata({
                title: options.currentSong?.title ?? options.currentSong?.name,
                artist: options.currentSong?.artist,
                artwork: [
                    {
                        src: thumbUrl ? thumbUrl : DEFAULT_THUMB_BIG,
                        sizes: "512x512",
                        type: "image/jpeg",
                    },
                ],
            });

            navigator.mediaSession.playbackState = options.audio.paused ? 'paused' : 'playing';
            navigator.mediaSession.setActionHandler('stop', () => {
                options.audio.pause();
                options.audio.currentTime = 0;
            });
            navigator.mediaSession.setActionHandler("play", () => {
                options.audio.play();
            });
            navigator.mediaSession.setActionHandler("pause", () => {
                options.audio.pause();
                navigator.mediaSession.playbackState = 'paused';
            });
            navigator.mediaSession.setActionHandler("previoustrack", () => {
                options.onPrev();
            });
            navigator.mediaSession.setActionHandler("nexttrack", () => {
                options.onNext();
            });
            navigator.mediaSession.setActionHandler('seekto', (details) => {
                options.audio.currentTime = details.seekTime;
            });
            navigator.mediaSession.setActionHandler('seekbackward', function() {});
            navigator.mediaSession.setActionHandler('seekforward', function() {});
        } catch (e) {
            console.error(e);
        }
    },
    clear: () => {
        if (!"mediaSession" in navigator) {
            return;
        }

        try {
            navigator.mediaSession.metadata = null;
            navigator.mediaSession.playbackState = "none";
            navigator.mediaSession.setActionHandler("play", null);
            navigator.mediaSession.setActionHandler("pause", null);
            navigator.mediaSession.setActionHandler("stop", null);
            navigator.mediaSession.setActionHandler("seekto", null);
            navigator.mediaSession.setActionHandler("nexttrack", null);
            navigator.mediaSession.setActionHandler("previoustrack", null);
            navigator.mediaSession.setActionHandler("previousslide", null);
            navigator.mediaSession.setActionHandler("nextslide", null);
        } catch (e) {

        }
    }
}

export const useAnalyzerCheck = (currentSong, isPublic, data = {}) => {
    const disableForAudioExtVariants = ['wma', 'aif', 'aiff', 'm4a'];
    return !STOP_ANALYZER &&
        (
            !data ||
            !data.hasOwnProperty('candownload') ||
            data.candownload
        ) &&
        (
            !currentSong.hasOwnProperty('name') ||
            !disableForAudioExtVariants.includes(fileext(currentSong.name).toLowerCase())
        )
}