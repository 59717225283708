// @flow

import React from "react";

import { __ } from "../../lib/translate";
import Button from "../../components/ButtonDefault";
import { Footer } from "../../components/Modals/styledComponents";
import { Container, Header, Message } from "./SharedComponents";

type Props = {
  onSuccess: () => void
};

const ImportantVersionMessageStep = ({ onSuccess = () => {} }: Props) => {
  return (
    <Container>
      <Header>{__("Important")}</Header>
      <Message className="understand">
        <div>{__("tfa_setup_important_version_msg")}</div>
      </Message>
      <Footer>
        <Button color="cyan" onClick={onSuccess}>
          {__("I understand")}
        </Button>
      </Footer>
    </Container>
  );
};

export default ImportantVersionMessageStep;
