// @flow

import React, { PureComponent } from "react";
import Componentify from "react-componentify";
import styled from "styled-components";

import { __ } from "../../lib/translate";
import { spanClassTagConverter } from "../../lib/componentifyConverters";

import Button from "../../components/ButtonDefault";
import { Container, Header, Message, Footer, Title } from "./SharedFamilyComponents";
import type { Member, Invite } from "./types";

type Props = {
  currentItem: Member | Invite,
  onRemoveMemberButton: () => void,
  onSuccess: () => void,
  onClose: () => void
};

class RemoveMemberModal extends PureComponent<Props, {}> {
  static defaultProps = {
    currentItem: null,
    onRemoveMemberButton: () => {},
    onSuccess: () => {},
    onClose: () => {}
  };

  constructor(props: Props) {
    super(props);

    (this: any).onRemove = this.onRemove.bind(this);
    (this: any).onEnter = this.onEnter.bind(this);
  }

  onEnter(e) {
    if (e.keyCode === 13) {
      this.onRemove();
    }
  }
  componentDidMount() {
    document.addEventListener("keyup", this.onEnter);
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this.onEnter);
  }

  onRemove() {
    const { currentItem, onRemoveMemberButton, onSuccess } = this.props;
    const { userid } = currentItem;

    onRemoveMemberButton(userid);
    onSuccess();
  }

  render() {
    const { onClose, currentItem } = this.props;
    const { email } = currentItem;
    const componentifyText = __("family_remove_message", "", {
      emailattr: `class='member-email'`,
      email: email
    });

    return (
      <Container>
        <Header>{__("family_remove")}</Header>
        <Message>
          <RemoveTitle>
            <Componentify
              text={componentifyText}
              converters={[spanClassTagConverter]}
            />
          </RemoveTitle>
        </Message>
        <Footer>
          <Button
            color="lightgray4"
            style={{
              marginRight: "5px"
            }}
            onClick={onClose}
          >
            {__("No")}
          </Button>
          <Button
            color="red"
            style={{
              marginLeft: "5px"
            }}
            onClick={this.onRemove}
          >
            {__("Yes")}
          </Button>
        </Footer>
      </Container>
    );
  }
}

export default RemoveMemberModal;

const RemoveTitle = styled(Title)`
  margin: 0;
  color: black;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;

  .member-email {
    font-weight: bold;
    text-overflow: ellipsis;
    max-width: 250px;
    white-space: nowrap;
    display: inline-block;
    overflow: hidden;
    margin: 0 5px;
  }
`;
