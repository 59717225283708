//@flow

import React from "react";
import styled, { keyframes } from "styled-components";
import { hiDPI } from "polished";
import { __ } from "../../lib/translate";

type CardNumberComponentProps = {
  cardNumber: string,
  validCardNumber: boolean,
  handleCardNumberChange: (value: string) => void,
  cardType: string,
  onPasteCardNumber: (value: string) => void,
  cardNumberLength: number,
  shouldRenderInput: boolean
};

const togglePlaceholder = eventTarget => {
  const value = eventTarget.target.value;
  eventTarget.target.style.background = value ? "white" : "none";
};

export const CardNumberComponent = ({
  cardNumber,
  validCardNumber,
  handleCardNumberChange,
  cardType,
  onPasteCardNumber,
  cardNumberLength,
  shouldRenderInput = true
}: CardNumberComponentProps) => {
  const _handleCardNumberChange = e => {
    togglePlaceholder(e);
    handleCardNumberChange(e.target.value);
  };

  const validateCardNumber = e => {
    const key = e.which || e.keyCode || e.charCode;
    const pressedCharacter = String.fromCharCode(key);
    let value = e.target.value.replace(/\s+/g, "");

    value = value.trim();
    if (!/[0-9 ]/.test(pressedCharacter) || value.length > cardNumberLength - 1) {
      e.preventDefault();
    }
  };

  return (
    <CreditCardNumberContainer>
      <Label>{__("card_number", "Card number")}</Label>
      <Field
        data-invalid={!validCardNumber}
        id="card-number"
      >
        {
          shouldRenderInput ? (
            <React.Fragment>
              <input
                type="text"
                value={cardNumber}
                name="cardnumber"
                autocomplete="cc-number"
                onChange={_handleCardNumberChange}
                onKeyPress={validateCardNumber}
                // onPaste={onPasteCardNumber}
                autoFocus
              />
              <Placeholder className="placeholder">**** **** **** ****</Placeholder>
              {cardType ? cardIcons[cardType] : cardIcons["default"]}
            </React.Fragment>
          ) : null
        }
      </Field>
    </CreditCardNumberContainer>
  );
};

type ExpiryDateComponentProps = {
  expiryDate: string,
  validExpiryDate: boolean,
  handleExpiryDateChange: (value: string) => void,
  onPasteExpDate: (value: string) => void,
  validateExpiryDate: (expiryDate: string, period?: string) => void,
  shouldRenderInput:  boolean
};

export const ExpiryDateComponent = ({
  expiryDate,
  validExpiryDate,
  handleExpiryDateChange,
  onPasteExpDate,
  validateExpiryDate,
  shouldRenderInput = true
}: ExpiryDateComponentProps) => {
  const _handleExpiryDateChange = e => {
    togglePlaceholder(e);
    handleExpiryDateChange(e.target.value);
  };

  const _validateExpiryDateTyping = e => {
    const key = e.which || e.keyCode || e.charCode;
    const pressedCharacter = String.fromCharCode(key);
    if (!/[0-9 /]/.test(pressedCharacter)) {
      e.preventDefault();
    }
  };

  const _validateExpiryDateOnBlur = e => {
    validateExpiryDate(e.target.value);
  };

  return (
    <ExpDateContainer>
      <Label>{__("expiration_date_short", "Exp. date")}</Label>
      <Field
        data-invalid={!validExpiryDate}
        id="card-expiry"
      >
      {
        shouldRenderInput ? (
          <React.Fragment>
            <input
              type="text"
              value={expiryDate}
              name="cc-exp"
              maxLength="7"
              autocomplete="cc-exp"
              onChange={_handleExpiryDateChange}
              onPaste={onPasteExpDate}
              onKeyPress={_validateExpiryDateTyping}
              onBlur={_validateExpiryDateOnBlur}
            />
            <Placeholder className="placeholder">{__("MM", "MM") + " / " + __("YY", "YY")}</Placeholder>
          </React.Fragment>
        ) : null
      }
      </Field>
    </ExpDateContainer>
  );
};

type HolderNameComponentProps = {
  holderName: string,
  validHolderName: boolean,
  handleHolderNameChange: (value: string) => void
};

export const HolderNameComponent = ({
  holderName,
  validHolderName,
  handleHolderNameChange
}: HolderNameComponentProps) => {
  const _handleHolderNameChange = e => {
    togglePlaceholder(e);
    handleHolderNameChange(e.target.value);
  };

  const _validateHolderName = e => {
    const key = e.which || e.keyCode || e.charCode;
    const pressedCharacter = String.fromCharCode(key);

    if (!/[a-zA-Z '-]/.test(pressedCharacter)) {
      e.preventDefault();
    }
  };

  return (
    <HolderNameContainer>
      <Label>{__("card_holder_name", "Cardholder name")}</Label>
      <Field data-invalid={!validHolderName}>
        <input
          type="text"
          value={holderName}
          name="ccname"
          maxLength="25"
          autocomplete="cc-name"
          onChange={_handleHolderNameChange}
          onKeyPress={_validateHolderName}
        />
        <Placeholder className="placeholder">
          {" "}
          {__("card_holder_name", "Cardholder name")}{" "}
        </Placeholder>
      </Field>
    </HolderNameContainer>
  );
};

type CVCComponentProps = {
  cvc: string,
  validCVC: boolean,
  handleCVCChange: (value: string) => void,
  shouldRenderInput: boolean
};

export const CVCComponent = ({ cvc, validCVC, handleCVCChange, shouldRenderInput = true }: CVCComponentProps) => {
  const _handleCVCChange = e => {
    togglePlaceholder(e);
    handleCVCChange(e.target.value);
  };

  const _validateOnlyNumbers = e => {
    const key = e.which || e.keyCode || e.charCode;
    const pressedCharacter = String.fromCharCode(key);

    if (!/[0-9 ]/.test(pressedCharacter)) {
      e.preventDefault();
    }
  };

  return (
    <CVCContainer>
      <Label id="">{__("CVC", "CVC")}</Label>
      <TooltipContainer>
        <TooltipContent>
          {__("find_card_cvc", "This is the secret code located on the back of your credit card.")}
        </TooltipContent>
        <CVCInformationIcon>i</CVCInformationIcon>
      </TooltipContainer>
      <Field
        data-invalid={!validCVC}
        id="card-cvc"
      >
      {
        shouldRenderInput ? (
          <React.Fragment>
            <input
              type="text"
              maxLength="4"
              value={cvc}
              name="cvc"
              autocomplete="cc-csc"
              onChange={_handleCVCChange}
              onKeyPress={_validateOnlyNumbers}
            />
            <Placeholder id="" className="placeholder">
              {" *** "}
            </Placeholder>
          </React.Fragment>
        ) : null
      }
      </Field>
    </CVCContainer>
  );
};

const shake = keyframes`
    10%, 90% {
        transform: translate3d(-1px, 0, 0);
    }
    
    20%, 80% {
        transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%, 60% {
        transform: translate3d(4px, 0, 0);
    }
`;

const InputWrapper = styled.div`
  margin: 5px 0px;
  position: relative;
  display: inline-block;
`;

const slideLeft = keyframes`
  0% {
    opacity: 0;
    left: -6px;
  }

  100% {
    opacity: 1;
    left: 0px;
  }
`;

const CardIcon = styled.div`
  z-index: 2;
  width: 28px;
  height: 22px;
  margin: 8px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: inline-block;
  position: absolute;
  top: 0px;
  display: inline;
  animation-name: ${slideLeft};
  animation-duration: 500ms;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  animation-fill-mode: forwards;
`;

const VisaCardIcon = styled(CardIcon)`
  background-image: url(${require("../../../root/img/changepaymentmethod/card_logo/visa.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/changepaymentmethod/card_logo/visa@2x.png")});
  }
`;

const MasterCardIcon = styled(CardIcon)`
  background-image: url(${require("../../../root/img/changepaymentmethod/card_logo/mastercard.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/changepaymentmethod/card_logo/mastercard@2x.png")});
  }
`;

const AmexCardIcon = styled(CardIcon)`
  background-image: url(${require("../../../root/img/changepaymentmethod/card_logo/amex.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/changepaymentmethod/card_logo/amex@2x.png")});
  }
`;

const DinersCardIcon = styled(CardIcon)`
  background-image: url(${require("../../../root/img/changepaymentmethod/card_logo/diners.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/changepaymentmethod/card_logo/diners@2x.png")});
  }
`;

const DiscoverCardIcon = styled(CardIcon)`
  background-image: url(${require("../../../root/img/changepaymentmethod/card_logo/discover.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/changepaymentmethod/card_logo/discover@2x.png")});
  }
`;

const JCBCardIcon = styled(CardIcon)`
  background-image: url(${require("../../../root/img/changepaymentmethod/card_logo/jcb.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/changepaymentmethod/card_logo/jcb@2x.png")});
  }
`;

const DefaultCardIcon = styled(CardIcon)`
  background-image: url(${require("../../../root/img/changepaymentmethod/card_logo/no_card.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/changepaymentmethod/card_logo/no_card@2x.png")});
  }
`;

const Field = styled.div`
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  padding: 10px;
  height: 39px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #ddd;
  text-align: left;
  outline: none;
  color: #444;
  background: #fff;

  &[data-invalid=true] {
    animation-name: ${shake};
    animation-duration: 700ms;
    animation-delay: 500ms;
    animation-timing-function: ease-in-out;
    animation-direction: alternate;
    animation-fill-mode: forwards;
    border-color: #C2313A;
  }

  input {
    width: 100%;
    border: none;
    outline: none;
    z-index: 1;
    position: relative;
    background: none;
    font-size: 14px;
    box-sizing: border-box;
    
    &::placeholder {
      color: #cccccc;
      opacity: 1;
    }
  }
`;

const CreditCardNumberContainer = styled(InputWrapper)`
  width: 280px;
  & input {
    text-align: center;
    padding-left: 44px;
  }
  & .placeholder {
    text-align: center;
    padding-left: 44px;
  }
  @media (max-width: 800px) {
    width: 240px;
  }
`;

const ExpDateContainer = styled(InputWrapper)`
  width: 85px;
  float: right;
  & input {
    text-align: center;
  }
  & .placeholder {
    text-align: center;
  }
  @media (max-width: 800px) {
    width: 75px;
  }
`;

const HolderNameContainer = styled(InputWrapper)`
  clear: right;
  width: 280px;
  @media (max-width: 800px) {
    width: 240px;
  }
`;

const CVCContainer = styled(InputWrapper)`
  float: right;
  width: 85px;
  & input {
    text-align: center;
  }
  & .placeholder {
    text-align: center;
  }
  @media (max-width: 800px) {
    width: 75px;
  }
`;

const slideUp = keyframes`
  0% {
    opacity: 0;
    bottom: 80%;
    transform: scale(0.94);
  }

  100% {
    opacity: 1;
    bottom: 100%;
    transform: scale(1);
  }
`;

const CVCInformationIcon = styled.div`
  border-radius: 50%;
  width: 16px;
  height: 16px;
  color: white;
  background: #ddd;
  text-align: center;
  line-height: 18px;
  font-size: 11px;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  cursor: pointer;
`;

const TooltipContainer = styled.div`
  display: inline-block;
  position: absolute;
  right: 0px;
  top: 3px;
  &:hover {
    div {
      display: block;
    }
  }
`;

const TooltipContent = styled.div`
  display: none;
  box-sizing: border-box;
  width: 280px;
  background-color: #444;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 15px;
  position: absolute;
  z-index: 1;
  left: 50%;
  bottom: 100%;
  margin-left: -140px;
  margin-bottom: 10px;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 500;
  padding: 10px;
  &:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #444 transparent transparent transparent;
  }
  animation-name: ${slideUp};
  animation-duration: 350ms;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  animation-fill-mode: forwards;
`;

const Label = styled.div`
  margin: 5px 0px;
  color: #aaa;
  text-transform: uppercase;
  text-align: left;
  font-size: 12px;
  font-weight: 400;
`;

const Placeholder = styled.div`
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  position: absolute;
  left: 0px;
  bottom: 1px;
  padding: 10px;
  font-size: 14px;
  color: #aaa;
  outline: none;
  background: white;
`;

const cardIcons = {
  visa: <VisaCardIcon />,
  master: <MasterCardIcon />,
  mastercard: <MasterCardIcon />,
  amex: <AmexCardIcon />,
  americanexpress: <AmexCardIcon />,
  diners: <DinersCardIcon />,
  dinersclub: <DinersCardIcon />,
  discover: <DiscoverCardIcon />,
  jcb: <JCBCardIcon />,
  default: <DefaultCardIcon />
};

export { TooltipContainer, TooltipContent };
