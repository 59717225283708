// @flow

import React, { useState, useEffect, useCallback } from "react";

import Modal, { OvalModalWrap, CloseModalIcon } from "../Modal";
import Button from "../ButtonDefault";
import * as Style from "./styledComponents";

import apiMethod from "../../api/apiMethod";
import { __ } from "../../lib/translate";
import { errorKeys } from "../../lib/errors";

type Props = {
  app: object
};

const UnlinkAppModal = ({ app }: Props) => {
  const { client_id, name: appName, publisher: publisherName } = app;
  const [isOpen, setIsOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      console.log("ADD event on keyup UnlinkAppModal");
      document.addEventListener("keyup", onEnter);
    } else {
      console.log("REMOVE event on keyup UnlinkAppModal");
      document.removeEventListener("keyup", onEnter);
    }
  }, [isOpen]);

  const handleClose = () => {
    setIsOpen(false);
  };

  const onEnter = useCallback(
    (e: any) => {
      if (e.keyCode === 13) {
        onDeleteTokenClick();
      }
    },
    [onDeleteTokenClick]
  );

  const onDeleteTokenClick = () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    apiMethod(
      'app_userdelete', 
      { client_id: client_id },
      (del) => {
        setIsLoading(false);
        HFN.message('App Removed.');
        $.bbq.pushState({ refr: uniqueNum.get() });

        handleClose();
      },
      {
        errorCallback: ({ result, error }) => {
          if (errorKeys[result]) {
            HFN.message(__(errorKeys[result]), "error");
          } else {
            HFN.message(__("something_went_wrong_refresh_and_try_again"), "error");
            throw new Error(error);
          }
          setIsLoading(false);
        }
      }
    );
  };

  return (
    <Modal onClose={handleClose} opened={isOpen}>
      <OvalModalWrap>
        <CloseModalIcon onClick={handleClose} />
        <Style.Container>
          <Style.Header>{__("Remove App")}</Style.Header>
          <Style.Label>{__("Details")}</Style.Label>
          <Style.Box>
            <Style.WrapperElements>
              <Style.ElementBold>{__("Name")}</Style.ElementBold>
              <Style.ElementBold>{__("Publisher")}</Style.ElementBold>
            </Style.WrapperElements>
            <Style.WrapperElements>
              <Style.ElementUnderLine>{appName}</Style.ElementUnderLine>
              <Style.ElementUnderLine>{publisherName}</Style.ElementUnderLine>
            </Style.WrapperElements>
          </Style.Box>
          <Style.Footer>
            <Button
              color="lightgray4"
              style={{
                marginRight: "5px"
              }}
              onClick={handleClose}
            >
              {__("Cancel")}
            </Button>
            <Button
              loading={isLoading}
              disabled={isLoading}
              color="red"
              style={{
                marginLeft: "5px"
              }}
              disabledColor="#dddddd"
              onClick={onDeleteTokenClick}
            >
              {__("Delete")}
            </Button>
          </Style.Footer>
        </Style.Container>
      </OvalModalWrap>
    </Modal>
  );
};

export default UnlinkAppModal;
