// @flow

import React from 'react';
import styled from "styled-components";

type Props = {
	prefix?: string,
	color?: string
};


const InputFieldWithPrefix = (props: Props) => {
	const { prefix, color } = props;
	console.log('Color>>> ', color)

	return (
		<VatPrefixWrapper color={color}> { prefix } </VatPrefixWrapper>
	);
}

InputFieldWithPrefix.defaultProps = {
	prefix: "",
	color: "#000"
};

export default InputFieldWithPrefix;

const VatPrefixWrapper = styled.span`
	position: absolute;
	top: 0;
	left: 0;
	margin: 22px 10px;
	color: ${props => props.color};
	font-family: "Roboto", sans-serif;
	font-size: 15px;
`; 