import React from "react";
import { hiDPI } from "polished";
import styled from "styled-components";

export const Wrapper = styled.div`
  max-width: 1040px;

  @media(max-width: 768px) {
    width: 100%;
    padding: 0px 30px;
    box-sizing: border-box;
  }
`;

export const Section = styled.div`
  padding: 27px 0px;
`;

export const SectionTitle = styled.div`
  padding: 0px;
  margin: 0px;
  margin-bottom: 26px;
  font-family: "Roboto", sans-serif;
  font-size: 34px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
`;

export const Header = styled.div`
  padding: 45px 0px 27px 0px;

  @media(max-width: 768px) {
    padding-top: 30px;
    padding-bottom: 22px;
  }
`;

export const HeaderTitle = styled(SectionTitle)`
  margin-bottom: 12px;
`;

export const HeaderSubtitle = styled.div`
  max-width: 660px;
  padding: 0px;
  margin: 0px auto;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: -0.1px;
  text-align: center;
  color: #000000;
`;

export const BoxContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 27px 0px;

  @media(max-width: 768px) {
    flex-direction: column;
    padding-top: 0px;
  }
`;

export const BoxItem = styled.div`
  width: 50%;
  margin: 0px 20px;
  text-align: ${({ alignDirection }) => alignDirection && alignDirection === "right" ? "right" : "left"};

  @media(max-width: 1180px) {
    &.image img {
      width: 100%;
    }
  }

  @media(max-width: 768px) {
    width: 100%;
    text-align: center;

    img {
      width: 100%;
      max-width: 360px;
    }

    &.text {
      order: 2;
    }

    &.image {
      order: 1;
      margin-top: 20px;
    }
  }
`;

export const List = styled.ul`
  padding-left: 0px;
  margin-left: 10px;

  @media(max-width: 768px) {
    margin-top: 32px;
  }
`;

export const ListItem = styled.li`
  font-family: "Roboto", sans-serif;
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: -0.09px;
  text-align: left;
  color: #000000;
  list-style: none;
  /* text-indent: 12px; */
  margin: 14px auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;

  &:first-of-type {
    margin-top: 0px;
  }

  &:last-of-type {
    margin-bottom: 0px;
  }

  &::before {
    display: inline-block;
    content: "";
    width: 6px;
    height: 10px;
    border-right: 2px solid #17bed0;
    border-bottom: 2px solid #17bed0;
    transform: rotate(45deg);
    position: relative;
    right: 12px;
    bottom: 3px;
  }

  @media(max-width: 768px) {
    background-color: #f8f8f8;
    padding: 12px 30px;
    border-radius: 15px;
    width: 100%;
    box-sizing: border-box;
    margin: 6px auto;
  }
`;

export const BoxTitle = styled.div`
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #000000;
  margin: 0px;
  margin-bottom: 10px;

  @media(max-width: 768px) {
    margin-top: 20px;
  }
`;

export const BoxDescription = styled.p`
  font-family: "Roboto", sans-serif;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.41;
  letter-spacing: -0.09px;
  color: #000000;

  /* @media(max-width: 768px) {
    border-bottom: 1px solid #e2e2e2;
    padding-bottom: 40px;

    &:last-child {
      border-bottom: none;
      padding-bottom: 0px;
    }
  } */
`;

export const BoxImage = styled.img`
  @media(max-width: 768px) {
    width: 100%;
  }
`;

export const OvalButton = styled.a`
  display: inline-block;
  min-width: 190px;
  margin-top: 32px;
  padding: 12px;
  border-radius: 20px;
  background-color: #17bed0;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.15;
  letter-spacing: 0.13px;
  text-align: center;
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
`;
