// @flow

import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import apiMethod from "../../api/apiMethod";
import errors from "../../lib/errors";
import { __ } from "../../lib/translate";
import { getHash } from "../../lib/utils";
import { setHasPassword } from "../../lib/state/actions/user";

import { ButtonCentered } from "../../components/ButtonDefault";
import { Container, Header, Message, InputPass, ErrorMessage } from "./SharedComponents";

type Props = {
  token: string,
  onSuccess: () => void,
  setHasPassword: () => void
};

type State = {
  code: string,
  newPass: string,
  newPassRepeat: string,
  showErrorMessage: boolean,
  errorMessage: string,
  isLoading: boolean
};

class SetupPassFormStep extends Component<Props, State> {
  static defaultProps = {
    token: "",
    onSuccess: () => {},
    setHasPassword: () => {}
  };

  constructor(props: Props) {
    super(props);

    (this: any).onSubmit = (this: any).onSubmit.bind(this);
    (this: any).onEnter = (this: any).onEnter.bind(this);
    (this: any).onChange = (this: any).onChange.bind(this);
    (this: any).onChangeRepeat = (this: any).onChangeRepeat.bind(this);

    this.state = {
      code: "",
      newPass: "",
      newPassRepeat: "",
      showErrorMessage: false,
      errorMessage: "",
      isLoading: false
    };
  }

  componentWillMount() {
    if (getHash("opensetpassword")) {
      this.setState({
        code: getHash("code")
      });
    }
  }

  getErrorMessage() {
    const { newPass, newPassRepeat } = this.state;

    if (!newPass || !newPassRepeat) {
      return __("Passwords are required");
    }

    if (newPass != newPassRepeat) {
      return __("Both passwords should match");
    }

    return "";
  }

  onSubmit() {
    const { token, setHasPassword, onSuccess } = this.props;
    const { code, newPass } = this.state;
    const errorMessage = this.getErrorMessage();

    if (errorMessage !== "") {
      this.setState({ errorMessage, showErrorMessage: true });

      return;
    }

    this.setState({ isLoading: true });

    apiMethod(
      "resetpassword",
      {
        code: code,
        newpassword: newPass
      },
      res => {
        this.setState({ isLoading: false });
        setHasPassword();
        onSuccess();
      },
      {
        errorCallback: ({ result, error }) => {
          let errorMessage = "";

          if (errors[result]) {
            errorMessage = errors[result];
          } else {
            errorMessage = __("something_went_wrong_refresh_and_try_again");
          }

          this.setState({
            errorMessage,
            showErrorMessage: true,
            isLoading: false
          });

          if (!errors[result]) {
            throw new Error(error);
          }
        }
      }
    );
  }

  onEnter(e: any) {
    if (e.keyCode === 13) {
      this.onSubmit();
    }
  }

  onChange(e: any) {
    this.setState({ newPass: e.target.value });
  }

  onChangeRepeat(e: any) {
    this.setState({ newPassRepeat: e.target.value });
  }

  render() {
    const { showErrorMessage, errorMessage, isLoading, newPass, newPassRepeat } = this.state;
    const shouldDisableBtn = isLoading || !newPass || !newPassRepeat;

    return (
      <Container>
        <Header>{__("Set up your password")}</Header>
        <p>{__("tfa_setup_new_pass_subheading")}</p>
        <Message className="setup-pass-form-msg">
          <ErrorMessage show={showErrorMessage}>{errorMessage}</ErrorMessage>
          <InputPass
            className="setup-pass-form"
            name="pass"
            type="password"
            value={newPass}
            autoFocus
            placeholder={__("Password")}
            shouldRenderRedBorder={showErrorMessage}
            onKeyUp={this.onEnter}
            onChange={this.onChange}
          />
          <InputPass
            name="repeatPass"
            type="password"
            value={newPassRepeat}
            placeholder={__("Repeat Password")}
            shouldRenderRedBorder={showErrorMessage}
            onKeyUp={this.onEnter}
            onChange={this.onChangeRepeat}
          />
        </Message>
        <ButtonCentered
          style={{
            width: "100%",
            marginTop: "30px"
          }}
          disabled={shouldDisableBtn}
          loading={isLoading}
          onClick={this.onSubmit}
        >
          {__("Continue")}
        </ButtonCentered>
      </Container>
    );
  }
}

export default connect(
  ({ user }) => {
    return { token: user.token };
  },
  dispatch =>
    bindActionCreators(
      {
        setHasPassword
      },
      dispatch
    )
)(SetupPassFormStep);
