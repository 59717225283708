// @flow

import React, { Component } from "react";
import styled from "styled-components";

type Props = {
  startFrom: number,
  onFinish: () => void
};

type State = {
  counter: number,
  intervalId: number
};

class CountDown extends Component<Props, State> {
  static defaultProps = {
    startFrom: 0,
    onFinish: () => {}
  };

  constructor(props: Props) {
    super(props);

    this.state = { counter: this.props.startFrom, intervalId: 0 };
  }

  componentWillMount() {
    const { onFinish } = this.props;

    let intervalId = setInterval(() => {
      const { counter } = this.state;
      console.log("counter", counter);
      if (counter === 0) {
        clearInterval(intervalId);
        onFinish();
      } else {
        this.setState({ counter: counter - 1 });
      }
    }, 1000);

    this.setState({ intervalId: intervalId });
  }

  componentWillUnmount() {
    const { intervalId } = this.state;

    clearInterval(intervalId);
  }

  render() {
    const { counter } = this.state;

    return (
      <div>
        {"("}
        {counter}
        {")"}
      </div>
    );
  }
}

export default CountDown;
