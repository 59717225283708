// @flow

import React, { useState } from "react";
import ReactTooltip from "react-tooltip";
import Componentify from "react-componentify";
import styled from "styled-components";

import { getIconType } from "../../lib/utils";
import { boldConverter, brTagConverter } from "../../lib/componentifyConverters";

import FileIcon from "../FileIcon";
import RemoveIcon from "../../../root/img/svg/remove-small.svg";
import RemoveErrorIcon from "../../../root/img/svg/remove-error.svg";
import ErrorIcon from "../../../root/img/svg/attention.svg";

type Props = {
  list: Array<any>,
  shouldRemove: boolean,
  onRemoveItem: () => void
};

const PickerSelectedItems = ({
  list = [],
  shouldRemove = true,
  onRemoveItem = () => {}
}: Props) => {
  const [removeBtnId, setRemoveBtnId] = useState("");

  const renderItem = (data, index) => {
    const { meta, error } = data;
    const itemData = meta || data;
    const { id, name, encrypted } = itemData;
    const iconName = getIconType(itemData);
    const shouldRenderRemoveBtn = shouldRemove && list.size > 1;

    return (
      <React.Fragment key={id}>
        <div data-tip data-for={"error" + id} >
          <Row
            showHover={shouldRenderRemoveBtn}
            onMouseOver={() => setRemoveBtnId(id)}
            onMouseLeave={() => setRemoveBtnId("")}
          >
            <FileIcon item={iconName} type={HFN.ICONS.LIST_SMALL} metadata={itemData} />
            <Title>{encrypted ? HFN.metaName(itemData) : name}</Title>
            {error ? (
              <React.Fragment>
                <AttentionIcon
                  display={removeBtnId === id && shouldRenderRemoveBtn ? "none" : "inline-block"}
                />
                <RemoveErrorButton
                  display={removeBtnId === id && shouldRenderRemoveBtn ? "inline-block" : "none"}
                  onClick={e => onRemoveItem(index)}
                />
              </React.Fragment>
            ) : (
              <RemoveButton
                display={removeBtnId === id && shouldRenderRemoveBtn ? "inline-block" : "none"}
                onClick={e => onRemoveItem(index)}
              />
            )}
          </Row>
        </div>
        {error && (
          <ReactTooltip id={"error" + id} effect="solid">
            <ErrorMessage>
              <Componentify text={error} converters={[boldConverter, brTagConverter]} />
            </ErrorMessage>
          </ReactTooltip>
        )}
      </React.Fragment>
    );
  };

  return <ItemsList>{list.map(renderItem)}</ItemsList>;
};

export default PickerSelectedItems;

const ItemsList = styled.div`
  padding: 6px 0;
  border-radius: 5px;
  box-sizing: border-box;
  border: solid 1px #eeeeee;
  background-color: #fafafa;
  max-height: 115px;
  overflow: auto;
  overflow-x: hidden;
  margin-bottom: 17px;

  /* width */
  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 3px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #eaeaea;
    border-radius: 3px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #aaa;
    border-radius: 3px;
    transition: background 200ms ease-in-out;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const Row = styled.div`
  display: flex;
  padding: 3px 10px;
  align-items: center;

  div.iconwrap {
    margin: 0 10px 0 0;
    width: 24px;
    height: 25px;
    min-width: 24px;
  }

  &:hover {
    background-color: ${props => (props.showHover ? "#f4f4f4" : "#fafafa")};
  }
`;

const Title = styled.div`
  font-size: 15px;
  font-weight: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex: 1;
  text-align: left;
`;

const ErrorMessage = styled.div`
  font-size: 15px;
  font-weight: normal;
`;

const RemoveButton = styled(RemoveIcon)`
  display: ${props => props.display};
  cursor: pointer;
`;

const RemoveErrorButton = styled(RemoveErrorIcon)`
  display: ${props => props.display};
  cursor: pointer;
`;

const AttentionIcon = styled(ErrorIcon)`
  display: ${props => props.display};
  color: #ff4242;
`;
