// @flow

import React, { PureComponent } from "react";
import styled from "styled-components";
import { connect } from "react-redux";

import { __ } from "../../lib/translate";
import { formatSizeInGb } from "../../lib/utils";

import Button from "../../components/ButtonDefault";
import { Container, Header, Message, Footer } from "./SharedFamilyComponents";
import StorageManagment from "./StorageManagment";
import { MIN_USER_QUOTA, QUOTA_STEP } from "@pcloud/web-utilities/dist/config/constants";
import type { Member, Invite } from "./types";

type Props = {
  currentItem: Member | Invite,
  owenerQuota: number,
  ownerUsedQuota: number,
  onUpdateMemberButton: () => void,
  onSuccess: () => void,
  onClose: () => void
};

type State = {
  updatedQuota: number,
  shouldChangeQuota: boolean
};

class ManageQuotaModal extends PureComponent<Props, State> {
  static defaultProps = {
    currentItem: {},
    onUpdateMemberButton: () => {},
    onSuccess: () => {},
    onClose: () => {}
  };

  constructor(props: Props) {
    super(props);

    this.state = { updatedQuota: 0, shouldChangeQuota: true, canProceed: false };

    (this: any).onUpdate = this.onUpdate.bind(this);
    (this: any).getUpdatedQuota = this.getUpdatedQuota.bind(this);
    (this: any).onEnter = this.onEnter.bind(this);
  }

  onEnter(e) {
    if (e.keyCode === 13) {
      this.onUpdate();
    }
  }
  
  componentDidMount() {
    document.addEventListener("keyup", this.onEnter);
    this.canChangeMemberQuota();
  }
  
  componentWillUnmount() {
    document.removeEventListener("keyup", this.onEnter);
  }
  componentDidUpdate(prevProps) {
    // prev props
    const {
      owenerQuota: prevOwenerQuota,
      ownerUsedQuota: prevOwnerUsedQuota,
      currentItem: prevCurrentItem
    } = prevProps;
    const { quota: prevMemberQuota, usedquota: prevMemberUsedQuota } = prevCurrentItem;
    const prevOwnerFreeQuota = prevOwenerQuota - prevOwnerUsedQuota;
    const prevMemberFreeQuota = prevMemberQuota - prevMemberUsedQuota;

    // props
    const { owenerQuota, ownerUsedQuota, currentItem } = this.props;
    const { quota, usedquota } = currentItem;
    const ownerFreeQuota = owenerQuota - ownerUsedQuota;
    const memberFreeQuota = quota - usedquota;

    if (ownerFreeQuota !== prevOwnerFreeQuota || prevMemberFreeQuota !== memberFreeQuota) {
      this.canChangeMemberQuota();
    }
  }

  canChangeMemberQuota() {
    const { owenerQuota, ownerUsedQuota, currentItem } = this.props;
    const { quota, usedquota } = currentItem;
    const ownerFreeQuota = owenerQuota - ownerUsedQuota;
    const ownerMinQuota = (owenerQuota % QUOTA_STEP) + MIN_USER_QUOTA;
    const hasAvailableOwnerQuota = this.hasAvailableFreeQuota(
      ownerFreeQuota,
      ownerUsedQuota,
      ownerMinQuota
    );
    const memberUsedQuota = usedquota;
    const memberFreeQuota = quota - memberUsedQuota;
    const hasAvailableMemberQuota = this.hasAvailableFreeQuota(
      memberFreeQuota,
      memberUsedQuota,
      MIN_USER_QUOTA
    );

    if (!hasAvailableOwnerQuota && !hasAvailableMemberQuota) {
      this.setState({ shouldChangeQuota: false });
    }
  }

  hasAvailableFreeQuota(freeQuota, usedQuota, minQuota) {
    if (usedQuota < minQuota) {
      return freeQuota - minQuota > QUOTA_STEP;
    } else {
      return freeQuota > QUOTA_STEP;
    }
  }

  getUpdatedQuota(updatedQuota) {
    const { currentItem } = this.props;
    const { quota } = currentItem;

    this.setState({
      updatedQuota: updatedQuota,
      canProceed: updatedQuota != quota
    });
  }

  getMaxValue() {
    const { currentItem, owenerQuota, ownerUsedQuota } = this.props;
    const freeOwnerQuota = owenerQuota - ownerUsedQuota;
    const { quota } = currentItem;

    if (ownerUsedQuota < MIN_USER_QUOTA) {
      return quota + freeOwnerQuota - MIN_USER_QUOTA;
    } else {
      return quota + freeOwnerQuota;
    }
  }

  onUpdate() {
    const { currentItem, onSuccess, onUpdateMemberButton } = this.props;
    const { updatedQuota, canProceed } = this.state;
    const { userid } = currentItem;

    if (!canProceed) {
      return;
    }

    onUpdateMemberButton(userid, updatedQuota);
    onSuccess();
  }

  render() {
    const { onClose, currentItem, owenerQuota, ownerUsedQuota } = this.props;
    const { shouldChangeQuota, canProceed } = this.state;
    const { email, quota, usedquota } = currentItem;
    const freeOwnerQuota = owenerQuota - ownerUsedQuota;
    const startValue = quota;
    const maxValue = this.getMaxValue();
    const minStartValue = Math.ceil(usedquota / QUOTA_STEP) * QUOTA_STEP;

    console.log("memberQuota", formatSizeInGb(quota));
    console.log("member used quota", formatSizeInGb(usedquota));
    console.log("member free quota", formatSizeInGb(quota - usedquota));
    console.log("owner free quota", formatSizeInGb(freeOwnerQuota));
    console.log("owenerQuota", formatSizeInGb(owenerQuota));
    console.log("ownerUsedQuota", formatSizeInGb(ownerUsedQuota));
    console.log("startValue", formatSizeInGb(startValue));
    console.log("maxValue", formatSizeInGb(maxValue));
    console.log("maxValue", formatSizeInGb(minStartValue));

    return (
      <Container>
        <Header>{__("family_manage_quota_modal_header", "Manage Family Member")}</Header>
        <Message>
          <MemberWrapper>
            <MemberHeader>{__("family_manage_quota_modal_member", "Member")}</MemberHeader>
            <Email>{email}</Email>
          </MemberWrapper>
          {shouldChangeQuota ? (
            <StorageManagment
              isModal
              startValue={startValue}
              minStartValue={minStartValue}
              maxValue={maxValue > startValue ? maxValue : startValue}
              getQuota={this.getUpdatedQuota}
              valueBoxColor="#f4f4f4"
            />
          ) : (
            __("family_accountfull_tooltip_error")
          )}
        </Message>
        <Footer>
          <Button
            color="lightgray4"
            style={{
              marginRight: "5px"
            }}
            onClick={onClose}
          >
            {__("Close")}
          </Button>
          {shouldChangeQuota ? (
            <Button
              color="cyan"
              style={{
                marginLeft: "5px"
              }}
              onClick={this.onUpdate}
              disabled={!canProceed}
            >
              {__("family_invite_form_update_button", "Update")}
            </Button>
          ) : null}
        </Footer>
      </Container>
    );
  }
}

export default connect(({ user }) => {
  const { userinfo: { quota, usedquota } = {} } = user;

  return { owenerQuota: quota, ownerUsedQuota: usedquota };
})(ManageQuotaModal);

const MemberWrapper = styled.div`
  width: 100%;
  display: flex;
  text-align: left;
  justify-content: space-around;
  flex-direction: column;
  margin-bottom: 17px;
`;

const MemberHeader = styled.div`
  font-size: 13px;
  font-weight: normal;
  margin-bottom: 10px;
  text-align: left;
`;

const Email = styled.div`
  box-sizing: border-box;
  padding: 11px;
  border-radius: 6px;
  border: solid 1px #eee;
  background-color: #fafafa;
  font-size: 15px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;
