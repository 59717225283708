import PropTypes from "prop-types";
import React, { Component } from "react";
import { validateEmail } from "../lib/utils";
import userinfo from "../api/userinfo";
import apiMethod from "../api/apiMethod";
import { TDiv, TString, TSpan } from "../components/intl";
import { __ } from "../lib/translate";
import ResponsiveWrap from "../components/ResponsiveWrap";
import SubmitButton from "../components/SubmitButton";
import InputReadOnly from "../components/InputReadOnly";
import InputPassword from "../components/InputPassword";
import InputText from "../components/InputText";

import styles from "../styles/forms.less";

export default class DeactivateAccount extends Component {
  static propTypes = {
    code: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      password: ""
    };

    this._deactivateAccount = this._deactivateAccount.bind(this);
  }

  componentWillUnmount() {
    if (this.xhr) {
      this.xhr.abort();
    }
  }

  _input(name, value) {
    this.setState({ [name]: value });
  }

  _deactivateAccount(e) {
    e.preventDefault();

    const { code } = this.props;
    const { password } = this.state;

    if (password.length < 5) {
      this.setState({ error: __("provide_password", "Provide password.") });
      return;
    }

    this.xhr = apiMethod(
      "deactivateuser",
      { code: code, password: password },
      ret => this.setState({ done: true }),
      {
        errorCallback: ret =>
          this.setState({ error: ret.error, password: "", loading: false })
      }
    );

    this.setState({ loading: true });
  }

  _form() {
    const { error, loading, password } = this.state;

    let errorEl = <div className="error">&nbsp;</div>;
    if (error) {
      errorEl = <div className="error">{error}</div>;
    }

    return (
      <ResponsiveWrap>
        <form method="post" onSubmit={this._deactivateAccount}>
          {errorEl}

          <InputPassword
            name="password"
            placeholder={__("your_password", "Your password")}
            value={password}
            onChange={e => this._input("password", e.target.value)}
          />
          <br />

          <SubmitButton loading={loading} active={!loading}>
            <TSpan id="delete_account">Delete Account</TSpan>
          </SubmitButton>
        </form>
      </ResponsiveWrap>
    );
  }

  _initializing() {
    return <div className="loading-wrap" />;
  }

  _missingcode() {
    return (
      <div className="error">
        <TSpan id="missing_code">Missing code.</TSpan>
      </div>
    );
  }

  _done() {
    const { newmail } = this.state;

    return (
      <div
        style={{
          textAlign: "center",
          lineHeight: "18px",
          fontSize: "14px",
          margin: "20px 0 0 0"
        }}
      >
        <TSpan id="your_account_deleted">
          Your account, along with your files and Personal Information is scheduled for deletion in 3 months.
        </TSpan>
      </div>
    );
  }

  render() {
    const { done } = this.state;
    const { code } = this.props;

    if (!code) {
      return (
        <div className="form">
          <TDiv id="delete_confirm_delete" className="title">
            Confirm account deletion
          </TDiv>
          <br />
          {this._missingcode()}
        </div>
      );
    }

    let middle;
    if (!code) {
      middle = this._missingcode();
    } else if (done) {
      middle = this._done();
    } else {
      middle = this._form();
    }

    return (
      <div className="form">
        {done ? (
          ""
        ) : (
          <div>
            <TDiv id="delete_confirm_delete" className="title">
              Confirm account deletion
            </TDiv>
            <TDiv id="delete_confirm_enter_password" className="subtitle">
              Enter account password to continue.
            </TDiv>
          </div>
        )}

        {middle}
        <br /><br />
      </div>
    );
  }
}
