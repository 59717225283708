// @flow

import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";

import Modal, { OvalModalWrap, OvalModalCloseV2 } from "../../Modal";
import Button from "../../ButtonDefault";
import * as Style from "../styledComponents";

import apiMethod from "../../../api/apiMethod";
import { __ } from "../../../lib/translate";

type Props = {
  memberId: number,
  memberFirstName: boolean,
  memberLastName: number,
  memberEmail: string
};

const ResetMemberPassModal = ({
  memberId = 0,
  memberFirstName = "",
  memberLastName = "",
  memberEmail = ""
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsOpen(true);
  }, []);

  const onEnter = useCallback((e: any) => {
    if (e.keyCode === 13) {
      resetPass();
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("keyup", onEnter);
    } else {
      document.removeEventListener("keyup", onEnter);

    }
  }, [isOpen]);

  const handleClose = () => {
    setIsOpen(false);
  };

  const resetPass = () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    apiMethod(
      "account_invalidate_password",
      { userid: memberId },
      () => {
        PCB.refreshAll();
        HFN.message(__("The password has been reset."));
        handleClose();
      },
      {
        errorCallback: ({ error, result }) => {
          setIsLoading(false);
          let errorMessage = __("something_went_wrong_refresh_and_try_again");

          if (result === 2076) {
            errorMessage = __("error_2076", "You do not have the permissions for this action.");
          }

          HFN.message(errorMessage, "error");
          throw new Error(error);
        }
      }
    );
  };

  return (
    <Modal animate onClose={handleClose} opened={isOpen}>
      <OvalModalWrap>
        <OvalModalCloseV2 onClick={handleClose} />
        <Style.Container>
          <Style.Header>{__("Reset Password")}</Style.Header>
          <Style.Label>{__("modal_reset_member_pass_header", "Reset password for user")}</Style.Label>
          <BoxReset>
            <b>{`${memberFirstName} ${memberLastName}`}</b>
            {` (${memberEmail})`}
          </BoxReset>
          <Style.Footer>
            <Button
              color="lightgray4"
              style={{
                marginRight: "5px"
              }}
              onClick={handleClose}
            >
              {__("Cancel")}
            </Button>
            <Button
              loading={isLoading}
              color="cyan"
              style={{
                marginLeft: "5px"
              }}
              disabledColor="#dddddd"
              onClick={resetPass}
            >
              {__("Reset")}
            </Button>
          </Style.Footer>
        </Style.Container>
      </OvalModalWrap>
    </Modal>
  );
};

export default ResetMemberPassModal;

const BoxReset = styled(Style.Box)`
  display: block;
  font-weight: normal;
  text-align: left;
`;
