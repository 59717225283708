//@flow

import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { SwitchTransition, CSSTransition } from "react-transition-group";

import {
  SettingsWrapper,
  SettingsHeader,
  SettingsTitle,
  FileRequestSettingsDescription,
  SettingOptionsContainer,
  Error,
  FadeAnimationContainer
} from "./styledComponents";
import TopBar from "./TopBar";
import { ButtonCentered } from "../../components/ButtonDefault";

import apiMethod from "../../api/apiMethod";
import { __ } from "../../lib/translate";

type Props = {
  allSettingsBack: any,
  bodyHeight: string,
  parentRef: any
};

const FileRequestDeleteLink = ({ allSettingsBack, bodyHeight, parentRef }: Props) => {
  const linkData = useSelector(({ fileRequestSettings }) => fileRequestSettings.linkData);
  const { metadata, uploadlinkid: linkId } = linkData;
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successStatus, setSuccessStatus] = useState(false);

  const handleSubmit = () => {
    errorMessage && setErrorMessage("");
    setLoading(true);
    deletePublink();
  };

  const deletePublink = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    apiMethod(
      "deleteuploadlink",
      { uploadlinkid: linkId },
      ret => {
        setLoading(false);
        setSuccessStatus(true);
        HFN.cache.expireMatch(HFN.cache.cacheid("puplinks", "all"));
        HFN.cache.expireMatch("api-listuploadlinks");

        HFN.gridRemove(metadata, true);
        HFN.gridAppend(metadata, true);
        HFN.refreshSharesAll();
      },
      {
        errorCallback: ({ error }) => {
          setLoading(false);
          setErrorMessage(error);
        }
      }
    );
  };

  const renderHeader = () => {
    return (
      <SettingsHeader>
        <SettingsTitle>{__("Delete link")}</SettingsTitle>
        <FileRequestSettingsDescription>{__("delete_link_message")}</FileRequestSettingsDescription>
      </SettingsHeader>
    );
  };

  const renderBody = () => {
    return (
      <SettingOptionsContainer>
        <FadeAnimationContainer>
          <SwitchTransition>
            <CSSTransition
              key={errorMessage}
              addEndListener={(node, done) => node.addEventListener("transitionend", done, false)}
              classNames="fade"
            >
              {errorMessage ? (
                <Error style={{ textAlign: "center" }}>{errorMessage}</Error>
              ) : (
                <Error />
              )}
            </CSSTransition>
          </SwitchTransition>
        </FadeAnimationContainer>
        <ButtonWrapper>
          <ButtonCentered loading={loading} borderRadius="3" color="red" onClick={handleSubmit}>
            {__("delete_link_confirmation_button", "Yes, delete link")}
          </ButtonCentered>
        </ButtonWrapper>
      </SettingOptionsContainer>
    );
  };

  const renderSuccesMessage = () => {
    return <SuccessMessage>{__("your_link_has_been_deleted", "Your link has been deleted.")}</SuccessMessage>;
  };

  return (
    <SettingsWrapper
      overlayHeader={successStatus}
      style={{
        bottom: "0px",
        top: "auto"
      }}
      bodyHeight={bodyHeight}
      ref={parentRef}
    >
      <FadeAnimationContainer>
        <SwitchTransition>
          <CSSTransition
            key={successStatus}
            addEndListener={(node, done) => node.addEventListener("transitionend", done, false)}
            classNames="fade"
          >
            {successStatus ? (
              renderSuccesMessage()
            ) : (
              <div>
                <TopBar allSettingsBack={allSettingsBack} />
                {renderHeader()}
                {renderBody()}
              </div>
            )}
          </CSSTransition>
        </SwitchTransition>
      </FadeAnimationContainer>
    </SettingsWrapper>
  );
};

export default FileRequestDeleteLink;

const ButtonWrapper = styled.div`
  margin: 0 auto;

  a {
    min-width: 120px;
  }

  a[disabled] {
    background-color: #dddddd;
    color: #a2a2a2;
    opacity: 1;
  }
`;

const SuccessMessage = styled(SettingsTitle)``;
