import styled from "styled-components";

export const ContentHeader = styled.div`
    color: #000;
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 10px;
`;
export const ContentText = styled.div`
    color: #000;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
`;