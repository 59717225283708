import styled from "styled-components";

export const Row = styled.div`
  padding: 15px 0;

  font-size: 13px;
  &.top-border {
    border-top: 1px solid ${props => props.borderColor || "#e9e9e9"};
  }
  &.bottom-border {
    border-bottom: 1px solid ${props => props.borderColor || "#e9e9e9"};
  }
  &.border-all {
   border: 1px solid ${props => props.borderColor || "#e9e9e9"}; 
  }

  input {
    border: 1px solid #bfbfbf;
    width: 320px;
    padding: 8px 10px 7px 10px;
    box-sizing: border-box;
    font-size: 13px;
  }
`;
export const Col = styled.div`
  display: inline-block;

  &.go-right {
    float: right;
  }
  &.text {
    vertical-align: top;
    padding: 6px 15px 0 0;
    box-sizing: border-box;
    width: 400px;
  }
  &.label {
    vertical-align: middle;
    padding: 0 10px 0 0;
    box-sizing: border-box;
    width: 400px;
  }
  &.input {
    width: 320px;
  }
`;
