// @flow
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { hiDPI } from "polished";

import { useSelector } from "react-redux";

import apiMethod from "../../api/apiMethod";
import { __ } from "../../lib/translate";

type Props = {
  password: string,
  onPassChange: string => void
};

const PassWithHint = ({ password, onPassChange }: Props) => {
  const [showHint, toggleHint] = useState(false);
  const [userHint, setUserHint] = useState(false);

  const token = useSelector(({ user }) => user.token);
  const userinfo = useSelector(({ user }) => user.userinfo);

  useEffect(() => {
    apiMethod("crypto_getuserhint", { auth: token }, ({ hint }) => {
      setUserHint(hint);
    });
  }, [token]);

  return (
    <React.Fragment>
      <input
        placeholder={__("enter_crypto_pass_placeholder")}
        type="password"
        onChange={e => onPassChange(e.target.value)}
        onKeyUp={e => onPassChange(e.target.value)}
        value={password}
      />
      {!showHint ? (
        <HintLink
          role="button"
          onKeyDown={e => e.keyCode == 13 && toggleHint(true)}
          tabIndex="0"
          onClick={() => toggleHint(true)}
        >
          {__("crypto_pass_having_trouble")}
        </HintLink>
      ) : null}
      {showHint ? (
        <Hint>
          <Close onClick={() => toggleHint(false)} />
          {userHint ? (
            <React.Fragment>
              <b>{__("Hint")}:</b> {userHint}
              <br />
            </React.Fragment>
          ) : null}

          {userinfo.business && userinfo.account.owner ? (
            <InfoWrap>
              <b>{__("contact_support")}:</b> {__("hint_help_message_owner")}
            </InfoWrap>
          ) : null}
          {userinfo.business && !userinfo.account.owner ? (
            <InfoWrap>
              <b>{__("contact_business_account_owner")}:</b> {__("hint_help_message_user")}
            </InfoWrap>
          ) : null}
        </Hint>
      ) : null}
    </React.Fragment>
  );
};

export default PassWithHint;

const HintLink = styled.span`
  text-decoration: underline;
  font-size: 12px;
  color: #000;
  cursor: pointer;
  margin-top: 10px;
  display: inline-block;
  line-height: initial;

  &:hover {
    text-decoration: none;
  }
`;

const Hint = styled.div`
  background-color: #fff8df;
  border: 1px solid #fcf0cd;
  font-size: 12px;
  line-height: 15px;
  color: #000;
  padding: 17px 11px 14px 11px;
  position: relative;
  margin-top: 10px;
`;

const InfoWrap = styled.div`
  margin-top: 10px;
`;

const Close = styled.div`
  cursor: pointer;
  position: absolute;
  padding: 5px;
  right: 4px;
  top: 4px;
  opacity: 0.5;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 9px 9px;
  width: 9px;
  height: 9px;
  background-image: url(${require("../../../root/img/header/close-modal.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/header/close-modal@2x.png")});
  }
`;
