import React from "react";
import styled from "styled-components";
import { hiDPI } from "polished";

const ImagesBase = styled.div`
	display: block;
	margin: 0 auto 17px;
	height: 32px;
	width: 34px;
	background-size: 32px 34px;
	background-repeat: no-repeat;
	background-color: transparent;
	background-position: center center;
`;

const TotalInvites = styled(ImagesBase)`
	background-image: url(${require("../../root/img/invite-friend/all-invites.png")});
	${hiDPI(1.5)} {
		background-image: url(${require("../../root/img/invite-friend/all-invites@2x.png")});
	}
`;

const Invited = styled(ImagesBase)`
	background-image: url(${require("../../root/img/invite-friend/my-invites.png")});
	${hiDPI(1.5)} {
		background-image: url(${require("../../root/img/invite-friend/my-invites@2x.png")});
	}
`;

const ChanceToWin = styled(ImagesBase)`
	background-image: url(${require("../../root/img/invite-friend/chance.png")});
	${hiDPI(1.5)} {
		background-image: url(${require("../../root/img/invite-friend/chance@2x.png")});
	}
`;

const images = {
	allinvites: <TotalInvites />,
	myinvites: <Invited />,
	chance: <ChanceToWin />
};

const IconStats = props => {
	console.log("IconStats ", props);
	return (
		<InvitationStats {...props}>
			{images[props.icon]}
			<div>{__(props.title)}</div>
			<span>
				{props.value}
				{props.children}
			</span>
		</InvitationStats>
	);
};

export default IconStats;

const StatsBase = styled.div`
	font-family: "Roboto", sans-serif;
	max-width: 230px;
	width: 100%;
	display: inline-block;
	text-align: center;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
`;

const InvitationStats = styled(StatsBase)`
	font-size: 12px;
	color: #8a8a8a;

	span {
		display: block;
		font-size: 28px;
		padding-top: 5px;
		color: #000;
	}
`;
