// @flow
import React, { useState } from "react";
import styled from "styled-components";

import { useSelector } from "react-redux";

import { __ } from "../../lib/translate";
import apiMethod from "../../api/apiMethod";

import Button from "../ButtonDefault";
import { Title, Row, Col } from "../tables/dark";
import Alert, { AlertIcon } from "../Alert";
import PassWithHint from "./PassWithHint";

type Props = {
  onPassOk: () => void
};

const EnterPass = ({ onPassOk }: Props) => {
  const [errorMessage, setErrorMessage] = useState();
  const [password, onPassChange] = useState("");
  const [loading, setLoading] = useState(false);
  const token = useSelector(({ user }) => user.token);

  const checkPassword = e => {
    e.preventDefault();
    if (loading || !password.length) {
      return;
    }
    setLoading(true);

    pCloudCrypto.unlockCrypto(password, ok => {
      if (ok) {
        apiMethod("crypto_sendchangeuserprivate", { auth: token }, res => {
          if (res.result == 0) {
            onPassOk();
          }
        });
      } else {
        setErrorMessage(__("wrong_password"));
        setLoading(false);
      }
    });
  };

  return (
    <Wrap className="clearfix" onSubmit={checkPassword}>
      <Title>{__("heading_change_crypto_pass")}</Title>
      {errorMessage ? (
        <Alert theme="error" centerText hasIcon multiline style={{ marginBottom: "10px" }}>
          {errorMessage}
        </Alert>
      ) : null}
      <Row className="first-row  setbl">
        <Col className="text-wrap" style={{ width: "340px" }}>
          {__("change_crypto_pass_desc_step_2")}
        </Col>
        <Col className="float" style={{ width: "342px" }}>
          <PassWithHint onPassChange={onPassChange} password={password} />
        </Col>
      </Row>
      <ButtonWrap>
        <Button
          size="big"
          borderRadius="3"
          color="green"
          onClick={checkPassword}
          loading={loading}
          disabled={!password.length}
        >
          {__("Continue")}
        </Button>
      </ButtonWrap>
    </Wrap>
  );
};

export default EnterPass;

const Wrap = styled.form``;

const ButtonWrap = styled.div`
  float: right;
  width: 240px;
  margin-top: 15px;
  margin-right: 11px;
`;
