// @flow

import React, { useState, useEffect, useCallback } from "react";
import Componentify from "react-componentify";
import { List } from "immutable";
import styled from "styled-components";

import Modal, { OvalModalWrap, CloseModalIcon } from "../Modal";
import Button from "../ButtonDefault";
import InputStyledCheckbox from "../InputStyledCheckbox";
import Picker from "../Picker/Picker";
import PickerSelectedItems from "../Picker/PickerSelectedItems";
import * as Style from "./styledComponents";

import apiMethod from "../../api/apiMethod";
import { __ } from "../../lib/translate";
import { abortAll } from "../../lib/XhrComponentUtils";
import { boldConverter } from "../../lib/componentifyConverters";
import { errorKeys } from "../../lib/errors";
import { CRYPTO_FOLDER } from "../Picker/constants";

const generalErrors = [2343, 2342, 2341, 2346, 2043, 2023, 2340, 2285, 2283, 2284, 2005, 2282];
const generalErrorsClose = [2008, 2003, 2352, 2208];
// 2005 - Directory does not exist.
// 2341 - Shared folders can't be placed inside backup folders.
// 2346 - You cannot add files/folders to this backup folder.
// 2043 - You cannot move a folder to one of its subfolders
// 2023 - You cannot place a shared folder into another shared folder
// 2340 - Moving is not allowed for this folder.
// 2285 - Permissions denied. Shared folder can't contain public folder.
// 2283 - Permissions denied. Public folder can't contain upload link.
// 2208 - The destination folder no longer exists.

type Props = {
  folder: any,
  fileList: Array<any>,
  opts: any,
  modalContainer?: any
};

const MoveModal = React.memo(
  ({
    folder = { folderid: 0, name: "/", contents: null },
    fileList = [],
    opts = { isCryptoFolder: false },
    modalContainer
  }: Props) => {
    const [isOpen, setIsOpen] = useState(true);
    const [currentFolder, setCurrentFolder] = useState(folder);
    const [applyAll, setApplyAll] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [moveList, setMoveList] = useState(List());
    const [itemsAlreadyExist, setItemsAlreadyExist] = useState(List());
    const [successMesssage, setSuccessMessage] = useState("");
    const xhrs = [];
    const xhrOverwrite = [];
    const xhrOverwriteAfterDeleting = [];

    useEffect(() => {
      parseMoveList();
      parseFolder();
    }, []); // []

    const parseFolder = useCallback(() => {
      console.log("FOLDER", folder);

      if (folder.name === CRYPTO_FOLDER && opts.isCryptoFolder) {
        HFN.findCryptoFolder(folder => {
          HFN.listCryptoFolderRecursive(folder, folderobj => {
            console.log("buildCryptoFolderBrowse", folderobj);
            setIsLoading(false);
            setCurrentFolder(folderobj);
          });
        });
      } else {
        setIsLoading(false);
        setCurrentFolder(folder);
      }
    }, []); // [];

    const parseMoveList = useCallback(() => {
      let list = List();
      fileList.forEach((item, index) => {
        let method = "";
        let params = { tofolderid: null, noover: 1 };

        if (item.isfolder) {
          params.folderid = item.folderid;
          method = "renamefolder";
        } else {
          params.fileid = item.fileid;
          method = "renamefile";
        }
        console.log("moveList.size", moveList);

        list = list.set(index, {
          method: method,
          params: params,
          meta: item,
          error: ""
        });
      });
      setMoveList(list);
    }, []); // []

    const handleClose = () => {
      setIsOpen(false);
      abortAll(xhrs);
      abortAll(xhrOverwrite);
      setIsLoading(false);
    };

    const prep = (item, callback) => {
      const { meta, destinationfolderid } = item;

      if (meta.encrypted) {
        pCloudCrypto.asyncDecryptMeta(meta, function(decrypted) {
          console.log("got decrypted", decrypted);
          pCloudCrypto.asyncEncryptMeta(
            { name: decrypted, parentfolderid: destinationfolderid },
            function(encrypted) {
              console.log("got encrypted", encrypted);
              callback(encrypted, decrypted);
            }
          );
        });
      } else {
        callback(meta.name);
      }
    };

    const onMove = destinationFolderId => {
      let callbacksAtOnce = [];
      let successItems = [];
      let alreadyExist = [];
      let withErrors = [];
      let hasError = false;

      if (isLoading) {
        return;
      }

      setIsLoading(true);
      moveList.forEach((item, index) => {
        item.destinationfolderid = destinationFolderId;
        prep(item, (prepname, decryptedName) => {
          const itemName = decryptedName ? decryptedName : prepname;
          item.params.toname = prepname;
          xhrs.push(
            apiMethod(
              item.method,
              item.params,
              res => {
                callbacksAtOnce.push(() => {
                  console.log("SUCC>>>>>", res);
                  successItems.push(item);
                });

                document.dispatchEvent(new CustomEvent("more-options-click", { detail: { action: "move", data: res.metadata } }));
              },
              {
                showErrorMessage: false,
                errorCallback: ret => {
                  callbacksAtOnce.push(() => {
                    hasError = true;
                    if (ret.result === 2004) {
                      let errMessage = "";
                      if (item.method == "renamefolder") {
                        errMessage = __(
                          "folder_exists",
                          'Files or folders under folder "<b>%name%</b>" already exist in destination folder.',
                          { name: itemName }
                        );
                      } else {
                        errMessage = __(
                          "file_exists",
                          "File <b>%name%</b> already exists in destination folder.",
                          { name: itemName }
                        );
                      }
                      const folderIdToDetete = ret.existingfolder && ret.existingfolder.folderid;

                      if (item.meta.isfolder && folderIdToDetete) {
                        item.folderIdToDetete = folderIdToDetete;
                      }
                      item.error = errMessage;
                      alreadyExist.push(item);
                    } else if (generalErrorsClose.indexOf(ret.result) !== -1) {
                      const destinationFolder = HFN.data.fflookup["d" + destinationFolderId];

                      if (ret.result === 2008 && destinationFolder && !destinationFolder.ismine) {
                        HFN.message(
                          __(
                            "picker_shared_folder_full_quota",
                            "The owner of this folder has no available storage in their account. You won't be able to perform any action in this folder."
                          ),
                          "error"
                        );
                      } else {
                        HFN.message(__(errorKeys[ret.result]), "error");
                      }

                      handleClose();
                    } else if (generalErrors.indexOf(ret.result) !== -1) {
                      let errorMsg = errorKeys[ret.result];

                      if (!errorMsg) {
                        errorMsg = errorKeys[ret.result + "_" + item.method];
                      }
                      item.error = errorMsg
                        ? __(errorMsg)
                        : __("something_went_wrong_refresh_and_try_again");
                      withErrors.push(item);
                    } else {
                      console.log("unhandle Error", ret);
                      HFN.message(__("something_went_wrong_refresh_and_try_again"), "error");
                      handleClose();
                    }
                    setIsLoading(false);
                  });
                }
              }
            )
          );
        });
      });

      $.when.apply($, xhrs).then(() => {
        let shouldClose = true;
        let moveListFilter = moveList;

        for (let n = 0; n < callbacksAtOnce.length; ++n) {
          callbacksAtOnce[n]();
        }

        if (alreadyExist.length !== 0) {
          shouldClose = false;
          setItemsAlreadyExist(List(alreadyExist));
        }

        if (withErrors.length !== 0) {
          shouldClose = false;
          moveListFilter = List(withErrors);
        }

        if (successItems.length) {
          setSuccessMessage(__("Item(s) moved."));
          successItems.forEach(item => {
            moveListFilter = moveListFilter.filter(el => el.meta.id !== item.meta.id);
          });
          console.log("successItems", successItems);
        }
        setMoveList(moveListFilter);

        if (!hasError) {
          HFN.message(__("Item(s) moved."));
        }

        if (shouldClose || !moveListFilter.size) {
          handleClose();
        }
        setIsLoading(false);
      });
    };

    const onPick = ({ data }) => {
      const { itemId = -1 } = data;
      if (itemId == -1) {
        return;
      }

      moveList.map(item => {
        return (item.params.tofolderid = itemId);
      });

      onMove(itemId);
    };

    const onSkipClick = () => {
      let moveListFilter = moveList;

      if (applyAll) {
        itemsAlreadyExist.forEach(item => {
          moveListFilter = moveListFilter.filter(el => {
            return el.meta.id !== item.meta.id;
          });
        });
      } else {
        const skipElement = itemsAlreadyExist.first();
        moveListFilter = moveListFilter.filter(el => {
          return el.meta.id !== skipElement.meta.id;
        });
      }

      if (applyAll) {
        setItemsAlreadyExist(List());
      } else {
        setItemsAlreadyExist(itemsAlreadyExist.delete(0));
      }

      setMoveList(moveListFilter);
      if (!moveListFilter.size) {
        handleClose();
        if (successMesssage) {
          HFN.message(successMesssage);
        }
      }
    };

    const onOverwriteClick = () => {
      let callbacksAtOnce = [];
      let callbacksAtOnceAfterDeleting = [];
      let successItems = [];
      let hasError = false;
      let moveListFilter = moveList;

      const overwrite = item => {
        delete item.params.noover;
        if (item.params.skipexisting) {
          delete item.params.skipexisting;
        }

        if (item.folderIdToDetete) {
          // delete existing item;
          xhrOverwrite.push(
            apiMethod(
              "deletefolderrecursive",
              { folderid: item.folderIdToDetete },
              ret => {
                // move item
                console.log("deletefolderrecursive SUCC>>>", ret);
                prep(item, (prepname, decryptedName) => {
                  const itemName = decryptedName ? decryptedName : prepname;
                  item.params.toname = prepname;
                  callbacksAtOnce.push(() => {
                    xhrOverwriteAfterDeleting.push(
                      apiMethod(
                        item.method,
                        item.params,
                        res => {
                          callbacksAtOnceAfterDeleting.push(() => {
                            successItems.push(res);
                            console.log("rename SUCC>>>", res);
                          });
                        },
                        {
                          showErrorMessage: false,
                          errorCallback: ret => {
                            callbacksAtOnceAfterDeleting.push(() => {
                              hasError = true;
                              console.log("deletefolderrecursive ERROR>>>", ret);
                            });
                          }
                        }
                      )
                    );
                  });
                });
              },
              {
                errorCallback: ret => {
                  callbacksAtOnce.push(() => {
                    hasError = true;
                    console.log("rename ERROR>>>", ret);
                  });
                }
              }
            )
          );
        } else {
          prep(item, (prepname, decryptedName) => {
            const itemName = decryptedName ? decryptedName : prepname;
            item.params.toname = prepname;
            xhrOverwrite.push(
              apiMethod(
                item.method,
                item.params,
                res => {
                  callbacksAtOnce.push(() => {
                    successItems.push(res);
                    console.log("rename SUCC>>>", res);
                  });

                  document.dispatchEvent(new CustomEvent("more-options-click", { detail: { action: "move", data: res.metadata } }));
                },
                {
                  showErrorMessage: false,
                  errorCallback: ret => {
                    callbacksAtOnce.push(() => {
                      hasError = true;
                      console.log("rename ERROR>>>", ret);
                    });
                  }
                }
              )
            );
          });
        }
      };

      if (applyAll) {
        itemsAlreadyExist.forEach(item => {
          moveListFilter = moveListFilter.filter(el => {
            return el.meta.id !== item.meta.id;
          });

          overwrite(item);
        });
      } else {
        const overwriteElement = itemsAlreadyExist.first();
        moveListFilter = moveListFilter.filter(el => {
          return el.meta.id !== overwriteElement.meta.id;
        });
        overwrite(overwriteElement);
      }

      const onSuccess = () => {
        if (applyAll) {
          setItemsAlreadyExist(List());
        } else {
          setItemsAlreadyExist(itemsAlreadyExist.delete(0));
        }

        setMoveList(moveListFilter);
        if (!moveListFilter.size) {
          if (successItems.length) {
            HFN.message(__("Item(s) moved."));
          } else if (hasError) {
            HFN.message(__("something_went_wrong_refresh_and_try_again"), "error");
          }
          handleClose();
        }
      };

      $.when.apply($, xhrOverwrite).then(() => {
        for (let n = 0; n < callbacksAtOnce.length; ++n) {
          callbacksAtOnce[n]();
        }

        if (xhrOverwriteAfterDeleting.length) {
          $.when.apply($, xhrOverwriteAfterDeleting).then(() => {
            for (let n = 0; n < callbacksAtOnceAfterDeleting.length; ++n) {
              callbacksAtOnceAfterDeleting[n]();
            }
            onSuccess();
          });
        } else {
          onSuccess();
        }
      });
    };

    const renderAlreadyExist = item => {
      return (
        <React.Fragment>
          <Style.ExistErrorMessage>
            <Componentify text={item.error || ""} converters={[boldConverter]} />
          </Style.ExistErrorMessage>
          <Style.CheckboxWrapper>
            <Style.CheckboxLabel for="apply">
              <InputStyledCheckbox
                id="apply"
                name="applyAll"
                size="small"
                checked={applyAll}
                onChange={e => setApplyAll(e.target.checked)}
              />
              <Style.CheckboxLabelText>{__("Apply for all")}</Style.CheckboxLabelText>
            </Style.CheckboxLabel>
          </Style.CheckboxWrapper>
          <Style.Footer>
            <Button
              color="lightgray4"
              className="skip"
              style={{
                marginRight: "5px"
              }}
              disabled={isLoading}
              onClick={onSkipClick}
            >
              {__("Skip")}
            </Button>
            <Button
              color="red"
              className="overwrite"
              style={{
                marginLeft: "5px"
              }}
              disabled={isLoading}
              onClick={onOverwriteClick}
            >
              {__("Overwrite")}
            </Button>
          </Style.Footer>
        </React.Fragment>
      );
    };

    const onRemoveRow = index => {
      if (moveList.size === 1) {
        handleClose();
      } else {
        setMoveList(moveList.delete(index));
      }
    };

    const renderMoveBody = () => {
      if (itemsAlreadyExist.size) {
        return renderAlreadyExist(itemsAlreadyExist.first());
      } else {
        return (
          <Body key="body">
            <LabelCopy>{__("selected_items", "Items")}</LabelCopy>
            <PickerSelectedItems list={moveList} onRemoveItem={onRemoveRow} />
            <LabelCopy>{__("move_modal_move_to", "Move to")}</LabelCopy>
            <Picker
              key="picker"
              folder={currentFolder}
              opts={Object.assign(opts, {
                buttonText: "Move",
                isCrypto: opts.isCryptoFolder
              })}
              onPick={onPick}
              onCancel={handleClose}
            />
          </Body>
        );
      }
    };

    return (
      <Modal animate onClose={handleClose} opened={isOpen} container={modalContainer}>
        <OvalModalWrap>
          <CloseModalIcon onClick={handleClose} />
          <Style.Container>
            <HeaderCopy>{__("Move")}</HeaderCopy>
            {isLoading ? (
              <Style.LoaderWrapper>
                <Style.Loader />
              </Style.LoaderWrapper>
            ) : null}
            {renderMoveBody()}
          </Style.Container>
        </OvalModalWrap>
      </Modal>
    );
  }
);

export default MoveModal;

const LabelCopy = styled(Style.Label)`
  height: 18px;
  margin-bottom: 5px;
`;

const HeaderCopy = styled(Style.Header)`
  height: 26px;
  margin-bottom: 16px;
  z-index: 1000001;
  position: relative;
`;

const Body = styled.div``;
