// @flow

import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import isMobilePhone from 'validator/lib/isMobilePhone';
import PhoneInput from "react-phone-input-2";
import "../../../node_modules/react-phone-input-2/lib/style.css";

import apiMethod from "../../api/apiMethod";
import { __ } from "../../lib/translate";
import errors from "../../lib/errors";
import { setPhoneNumber, setPhoneCountryCode } from "../../lib/state/actions/user";

import { ButtonCentered } from "../../components/ButtonDefault";
import { Container, Header, Message, ErrorMessage } from "./SharedComponents";

type Props = {
  token: string,
  password: string,
  userCountry: string,
  setPhoneNumber: string => void,
  setPhoneCountryCode: string => void,
  onSuccess: () => void
};

type State = {
  inputPhoneValue: string,
  isPhoneNumberValid: boolean,
  dialCode: string,
  phoneNumber: string,
  showErrorMessage: boolean,
  errorMessage: string,
  isLoading: boolean
};

class EnterPhoneNumberStep extends Component<Props, State> {
  static defaultProps = {
    token: "",
    password: "",
    userCountry: "",
    setPhoneNumber: () => {},
    setPhoneCountryCode: () => {},
    onSuccess: () => {}
  };

  constructor(props: Props) {
    super(props);

    (this: any).onSubmit = (this: any).onSubmit.bind(this);
    (this: any).onPhoneChange = (this: any).onPhoneChange.bind(this);
    (this: any).onPhoneBlur = (this: any).onPhoneBlur.bind(this);

    this.state = {
      inputPhoneValue: "",
      isPhoneNumberValid: false,
      dialCode: "",
      phoneNumber: "",
      showErrorMessage: false,
      errorMessage: "",
      isLoading: false
    };
  }

  onPhoneChange(value: any, countryData: any, e: any, formattedValue: string) {
    const { dialCode } = countryData;
    const inputPhoneValueRaw = formattedValue.replace(/[^0-9+]/g, "");

    this.setState({
      inputPhoneValue: inputPhoneValueRaw,
      isPhoneNumberValid: isMobilePhone(inputPhoneValueRaw),
      showErrorMessage: false,
      dialCode: dialCode,
      phoneNumber: formattedValue
    });
  }

  onPhoneBlur(e: any, countryData: any) {
    const inputPhoneValueRaw = typeof e.target.value !== "undefined" ? e.target.value.replace(/[^0-9+]/g, "") : "";
    const isMobPhone = isMobilePhone(inputPhoneValueRaw);

    this.setState({
      showErrorMessage: !isMobPhone,
      isPhoneNumberValid: isMobPhone
    });
  }

  onSubmit() {
    const { dialCode, phoneNumber, isPhoneNumberValid, isLoading } = this.state;
    const { token, password, onSuccess } = this.props;
    const msisdn = phoneNumber.replace(/[^0-9]/g, "");

    if (isLoading) {
      return;
    }

    if (!isPhoneNumberValid) {
      this.setState({
        errorMessage: __("Incorrect number"),
        showErrorMessage: true
      });

      return;
    }

    this.setState({ isLoading: true });

    apiMethod(
      "tfa_sendactivationcode",
      {
        auth: token,
        password: password,
        msisdn: msisdn,
        countrycode: dialCode
      },
      () => {
        const { setPhoneNumber, setPhoneCountryCode } = this.props;

        this.setState({ isLoading: false });
        setPhoneCountryCode(dialCode);
        setPhoneNumber(msisdn);
        onSuccess();
      },
      {
        errorCallback: ({ result, error }) => {
          let errorMessage = "";

          if (errors[result]) {
            errorMessage = errors[result];
          } else {
            errorMessage = __("something_went_wrong_refresh_and_try_again");
          }

          this.setState({
            errorMessage: errorMessage,
            showErrorMessage: true,
            isLoading: false
          });

          if (!errors[result]) {
            throw new Error(error);
          }
        }
      }
    );
  }

  render() {
    const {
      isPhoneNumberValid,
      showErrorMessage,
      errorMessage,
      inputPhoneValue,
      isLoading
    } = this.state;
    const defaultCountry = this.props.userCountry || "us";

    return (
      <Container>
        <Header>{__("tfa_setup_phone_number_header")}</Header>
        <p>{__("tfa_setup_phone_number_subheading")}</p>
        <Message className="phone-number-msg">
          <ErrorMessage show={showErrorMessage}>{errorMessage}</ErrorMessage>
          <PhoneInput 
            key={defaultCountry}
            value={inputPhoneValue}
            country={defaultCountry}
            placeholder={__("Phone number")}
            onChange={this.onPhoneChange}
            onBlur={this.onPhoneBlur}
            onEnterKeyPress={this.onSubmit}
            inputProps={{
              name: "phone",
              autoFocus: true
            }}
            inputClass="phone-input"
          />
        </Message>
        <ButtonCentered
          disabled={isLoading || !isPhoneNumberValid}
          loading={isLoading}
          onClick={this.onSubmit}
          style={{ width: "100%" }}
        >
          {__("Next")}
        </ButtonCentered>
      </Container>
    );
  }
}

export default connect(
  ({ user }) => {
    const { token, userinfo: { password, userCountry } = {} } = user;

    return {
      token,
      password,
      userCountry
    };
  },
  dispatch =>
    bindActionCreators(
      {
        setPhoneNumber,
        setPhoneCountryCode
      },
      dispatch
    )
)(EnterPhoneNumberStep);
