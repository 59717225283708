// @flow

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Componentify from "react-componentify";
import styled from "styled-components";

import { __ } from "../../../lib/translate";
import { currencyToSign, getPriceWithCurrency, getSubsPrice } from "../../../lib/utils";
import apiMethod from "../../../api/apiMethod";
import { changeUsersQuotaNoCharge } from "../../../lib/paymentsUtils";
import { getPaymentsApiUrl } from "../../../lib/SubscriptionUpdate";

import { ButtonCentered } from "../../../components/ButtonDefault";

import UsersInput from "./UsersInput";
import { Container, Header, MinUsersNote } from "./StyledComponents";
import { SmallLoader, Loader } from "../../PaymentForm/PaymentLoader";
import {
  PROVIDERS,
  PROVIDERS_NAMES,
  SUBSC_PERIOD,
  B_USERS,
  PROVIDERS_IDS
} from "@pcloud/web-utilities/dist/config/constants";
import apiConfig from "../../../api/config";
import { businessPrices } from "../../../../web-shared/prices";
import { BUSINESS_PLANS_ID_MAP_FROM_API } from "../../../../web-shared/constants";
import { linkConverterV2, boldConverter } from "../../../../web-shared/componentifyConverters";

type Props = {
  currentFlowName: string,
  setMembers: () => void,
  setPrice: () => void,
  setProrationtimeCb: () => void,
  setPeriod: () => void,
  setCurrency: () => void,
  onSuccess: () => void
};

const ManageUsersQuotaStep = ({
  currentFlowName = "updateUsers",
  setMembers = () => {},
  setPrice = () => {},
  setProrationtimeCb = () => {},
  setPeriod = () => {},
  setCurrency = () => {},
  onSuccess = () => {}
}: Props) => {
  const userInfo = useSelector(({ user }) => user.userinfo);
  const paymentSource = useSelector(({ user }) => user.paymentSource);
  const { currency: paymentSourceCurrency } = paymentSource;
  const { email } = userInfo;
  const billingInfo = useSelector(({ business }) => business.billingInfo);
  const { billing = {}, memberinfo = {} } = billingInfo;
  const { provider = 1, currency: billingCurrency = "USD", period: billingPeriod, memberprice = {}, businessplanid: planId } = billing;
  const currency = billingCurrency || paymentSourceCurrency;
  const curSign = currencyToSign(currency);
  const currencyCode = currency
  const { minimum: minUsers, current: currentUsers, maximum: maxUsers, maximumupgrade: maxUpgrade = B_USERS.MAX} = memberinfo;
  const minUsersForDowngrade = currentUsers > minUsers ? currentUsers : minUsers;
  const planid = BUSINESS_PLANS_ID_MAP_FROM_API[planId];
  const {
    yearly: pricePerUserYearly = businessPrices[planid][currency].year,
    monthly: pricePerUserMonthly = businessPrices[planid][currency].month
  } = memberprice;

  const providerName = PROVIDERS[provider] ? PROVIDERS[provider].toLowerCase() : "";
  const currentPeriod = currentFlowName === "upgrade" ? SUBSC_PERIOD.YEARLY : billingPeriod;

  const [selectedUsers, setSelectedUsers] = useState(maxUsers || maxUpgrade);
  const [totalAmount, setTotalAmount] = useState(0);
  const [prorationtime, setProrationtime] = useState(0);
  const [credit, setCredit] = useState(0);
  const [amountToPay, setAmountToPay] = useState(0);
  const [priceLoading, setPriceLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [canProcced, setCanProceed] = useState(false);
  const [inRangeUsers, setInRangeUsers] = useState(false);

  useEffect(() => {
    if (currentFlowName === "upgrade") {
      setLoader(true);
    }
  }, [currentFlowName]);

  useEffect(() => {
    const inRange = selectedUsers >= minUsersForDowngrade && selectedUsers <= maxUpgrade;
    const usersChanged = selectedUsers != maxUsers;
    setInRangeUsers(inRange);
    setCanProceed(
      usersChanged || credit || amountToPay || (usersChanged && !credit && !amountToPay)
    );
  }, [selectedUsers, credit, amountToPay, minUsersForDowngrade, maxUsers]);

  useEffect(() => {
    const inRangeUsers = selectedUsers >= minUsersForDowngrade && selectedUsers <= maxUpgrade;
    const alreadyPaidUsers = selectedUsers == maxUsers && currentPeriod === billingPeriod; // update user quota
    const isBraintree = provider === PROVIDERS_IDS.BRAINTREE;
    let period = currentPeriod;
    const params = { members: selectedUsers, billingperiod: period };

    if (alreadyPaidUsers || !inRangeUsers) {
      const pricePerUser = currentPeriod === 0 ? pricePerUserMonthly : pricePerUserYearly;
      const initialPrice = getSubsPrice(selectedUsers, currentPeriod, parseFloat(pricePerUser));
      setTotalAmount(initialPrice);
      setCredit(0);
      setAmountToPay(0);
      return;
    }

    let method = "";

    if (providerName === PROVIDERS_NAMES.PAYPAL) {
      method = "account_modify_preview";
    } else if (providerName === PROVIDERS_NAMES.BRAINTREE) {
      method = "subscription/previewbusinessplanchange";
      period = currentPeriod === SUBSC_PERIOD.MONTHLY ? "month" : "year";
      params.period = period;
    } else if (providerName === PROVIDERS_NAMES.SAFECHARGE) {
      method = "safecharge/previewbusinessplanchange";
    } else if (providerName === PROVIDERS_NAMES.STRIPE) {
      method = "billing/stripe/previewbusinessplanchange";
    }

    if (planId) {
      params.businessplanid = planId;
    }

    if (!method) {
      setLoader(false);
      return;
    }

    setPriceLoading(true);
    apiMethod(
      method,
      params,
      ({ totalamount, credit, prorationtime, amounttopay }) => {
        setCredit(credit / 100);
        setTotalAmount(totalamount / 100);
        setProrationtime(prorationtime);
        setAmountToPay(amounttopay / 100);
        setTimeout(() => {
          setPriceLoading(false);
          setLoader(false);
        }, 500);
      },
      {
        errorCallback: () => {
          setTimeout(() => {
            setPriceLoading(false);
            setLoader(false);
            setCanProceed(false);
            HFN.message(__("something_went_wrong_refresh_and_try_again"), "error");
          }, 500);
        },
        apiServer: isBraintree ? getPaymentsApiUrl() : apiConfig.apiServer
      }
    );
  }, [selectedUsers]);


  const getDisclaimerText = () => {
    let disclaimerText = __("business_switch");

    if (planId === 1) {
      return disclaimerText.replace("%PLANNAME%", "<b>" + __("plan_name_business_pro") + "</b>");
    } else if (planId === 2) {
      return disclaimerText.replace("%PLANNAME%","<b>" +  __("plan_name_business") + "</b>");
    }

    return disclaimerText.replace("%PLANNAME%", "");
  }

  const onSubmit = () => {
    if (!canProcced || priceLoading || !inRangeUsers) {
      return;
    }

    if (amountToPay) {
      setPrice(amountToPay);
      setMembers(selectedUsers);
      setProrationtimeCb(prorationtime);
      setCurrency(currency);
      onSuccess();
      setPeriod(currentPeriod);
    } else {
      setLoader(true);
      const paypalEmail = paymentSource.paypal && paymentSource.paypal.email;
      const params = {
        provider: providerName,
        period: currentPeriod,
        members: selectedUsers,
        billingAddress: {
          email: email,
          country: "US"
        },
        paypalEmail: paypalEmail || "",
        isBusiness: true,
        prorationtime: prorationtime,
        businessplanid: planId,
        showPaymentError: message => {
          HFN.message(message, "error");
          setLoader(false);
        },
        afterPaymentActions: () => {
          onSuccess({ skipNext: true });
          setLoader(false);
          setMembers(selectedUsers);
          setPeriod(currentPeriod);
        }
      };
      changeUsersQuotaNoCharge(params);
    }
  };

  const renderDescriptionPrice = () => {
    const textCredit = __(
      "b_update_users_quota_credit",
      "<b>%credit%</b> will be deducted from your next billing.",
      {
        credit: getPriceWithCurrency(credit, currencyCode)
      }
    );
    const textAmountToPay = __(
      "b_update_users_quota_amount_pay",
      "<b>%amountToPay%</b> will be your charge now.",
      {
        amountToPay: getPriceWithCurrency(amountToPay, currencyCode)
      }
    );
    const text = credit ? textCredit : textAmountToPay;
    const textComponent = priceLoading ? (
      <SmallLoader color="green" />
    ) : (
      <Componentify text={text} converters={[boldConverter]} />
    );

    return <PriceDescription show={credit || amountToPay}>{textComponent}</PriceDescription>;
  };

  const renderMinUsersNote = () => {
    let text = "";

    if (selectedUsers < minUsersForDowngrade) {
      text = __("Need to deactivate users first to downgrade", "Need to deactivate users first to downgrade");
    }
    if (selectedUsers < minUsers) {
      text = __("busines_min_users", "Minimum 3 users required");
    }
    return <MinUsersNote show={text}>{text}</MinUsersNote>;
  }

  const headerText =
    currentFlowName === "upgrade"
      ? __("Upgrade to Annual", "Upgrade to Annual")
      : __("b_billing_manage_user_quota_header", "Manage User Quota");

  return (
    <ContainerUpdate key="updateUsersStep">
      {loader ? (
        <Loader color="green" />
      ) : (
        <React.Fragment>
          <Header>{headerText}</Header>
          <UsersInput
            numOfUsers={selectedUsers}
            min={minUsersForDowngrade}
            max={maxUpgrade}
            onChange={setSelectedUsers}
            disabled={priceLoading}
          />
          {renderMinUsersNote()}
          <PriceWrapper disabled={priceLoading || !inRangeUsers}>
            <Text>{__("Subscription")}:</Text>
            <PriceBox>
              <PriceText>
                {getPriceWithCurrency(totalAmount, currencyCode)} /{" "}
                <span>{currentPeriod == SUBSC_PERIOD.MONTHLY ? __("month") : __("year")}</span>
              </PriceText>
            </PriceBox>
            {renderDescriptionPrice()}
          </PriceWrapper>
          <ButtonCentered
            color="green"
            style={{ fontSize: "14px" }}
            onClick={onSubmit}
            minWidth="240px"
            disabledColor="#dddddd"
            disabled={!canProcced || priceLoading || !inRangeUsers}
          >
            {__("b_billing_button_proceed", "proceed").toUpperCase()}
          </ButtonCentered>
          <Disclaimer>
            <Componentify
              text={getDisclaimerText()}
              converters={[linkConverterV2, boldConverter]}
            />
          </Disclaimer>
        </React.Fragment>
      )}
    </ContainerUpdate>
  );
};

export default ManageUsersQuotaStep;

const ContainerUpdate = styled(Container)`
  min-height: 420px;
`;

const PriceWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 35px auto;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
`;

const Text = styled.div`
  font-size: 13px;
  font-weight: normal;
  color: #999;
  margin-bottom: 7px;
`;

const PriceBox = styled.div`
  width: 240px;
  border-radius: 20px;
  border: solid 1px #eeeeee;
  background-color: #ffffff;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PriceText = styled.div`
  font-size: 17px;
  font-weight: bold;
  span {
    font-weight: normal;
  }
`;

const PriceDescription = styled.div`
  visibility: ${props => (props.show ? "visible" : "hidden")};
  height: 18px;
  font-size: 13px;
  font-weight: normal;
  margin-top: 10px;
`;

const Disclaimer = styled.div`
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: center;
  color: #999999;
  margin-top: 20px;
  font-size: 12px;
  margin-top: 16px;

  a {
    text-decoration: none;
    color: #17bed0;
  }
`;
