// @flow

import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";

import { hiDPI } from "polished";
import { __ } from "../../lib/translate";
import apiMethod from "../../api/apiMethod";
import { HtmlTString } from "../intl";

import type { UserState } from "../../lib/state/reducers/user";

import Modal from "../Modal";
import InputCopy from "../InputCopy";

type ModalProps = {
  user: UserState
};

type ModalState = {
  open: boolean,
  inviteUrl: string
};

class JourneyModal extends React.Component<ModalProps, ModalState> {
  constructor(props: ModalProps) {
    super(props);

    this.state = { open: true, inviteUrl: "" };

    (this: any)._close = this._close.bind(this);
  }

  componentWillMount() {
    this._getInviteUrl();
  }

  _getInviteUrl() {
    if (this.props.user.logged) {
      apiMethod("invite", { auth: this.props.user.token }, ret => {
        this.setState({ inviteUrl: ret.url });
      });
    }
  }

  _close() {
    this.setState({ open: false });
  }

  render() {
    const { open, inviteUrl } = this.state;
    const { user } = this.props;

    if (open && inviteUrl && user.logged) {
      const premium = user.userinfo.premium || user.userinfo.business;

      return (
        <Modal opened={true} onClose={this._close}>
          <Wrap>
            <Close onClick={this._close} />
            <Body>
              <Title>
                {premium
                  ? __("invmodal_title_premium", "Congratulations! You've completed all steps!")
                  : __("invmodal_title_free", "Congratulations! You've unlocked the 4GB bonus!")}
              </Title>
              <Check />
              <MoreWrap>
                <b>
                  {premium
                    ? __("morespace_title_premium", "Spread the word!")
                    : __("morespace_title_free", "Want even more storage?")}
                </b>
                <br />
                {premium
                  ? __(
                      "morespace_body_premium",
                      "Invite your friends over at pCloud by sending them the link below. Every friend, who joins pCloud, will be treated with 1 GB additional free storage. "
                    )
                  : __(
                      "morespace_body_free",
                      "Invite your friends over at pCloud by sending them the link below. You and every friend, who joins pCloud, will both be treated with 1 GB additional free storage. "
                    )}
                <br />
              </MoreWrap>
              <InputCopyWrap>
                <InputCopy value={inviteUrl} />
              </InputCopyWrap>
              <UnderCopy>
                {__("morespace_users", "You can check your sent invites %here%", { here: "" })}
                <a href="/#page=uinvite" onClick={this._close}>
                  {__("t_here", "here")}
                </a>.
              </UnderCopy>
              {/*
              <div>
                <SharesLink>Share this link</SharesLink>
                <ShareFacebook />
                <ShareGoogle />
                <ShareTwitter />
                <ShareMail />
              </div>
              */}
            </Body>
          </Wrap>
        </Modal>
      );
    } else {
      return null;
    }
  }
}

export default connect(state => ({ user: state.user }))(JourneyModal);

const Wrap = styled.div`
  background-repeat: repeat;
  background-image: url(${require("../../../root/img/journey/bg.png")});
  position: relative;
`;

const Body = styled.div`
  padding: 30px 80px;
  width: 730px;
  box-sizing: border-box;
  text-align: center;
`;

const Title = styled.div`
  font-size: 21px;
  font-weight: bold;
  color: #000;
`;

const Close = styled.div`
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 15px;
  width: 30px;
  height: 30px;
  background-size: 30px 30px;
  background-image: url(${require("../../../root/img/journey/close-modal.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/journey/close-modal@2x.png")});
  }

  &:hover {
    opacity: 0.8;
  }
`;

const Check = styled.div`
  margin: 40px auto;
  width: 100px;
  height: 100px;
  background-size: 100px 100px;
  background-image: url(${require("../../../root/img/journey/modal-check.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/journey/modal-check@2x.png")});
  }
`;

const InputCopyWrap = styled.div`
  & > label > div > div:first-child {
    background-color: #61c200;
    &:hover {
      background-color: #59b101;
    }
  }
`;

const MoreWrap = styled.div`
  margin: 0 0 20px 0;
  font-size: 15px;
  line-height: 18px;
`;

const SharesWrap = styled.div``;

const SharesLink = styled.div`
  font-size: 12px;
  line-height: 46px;
  color: #666;
`;

const ShareBase = styled.div`
  width: 32px;
  height: 32px;
  background-size: 32px 32px;
  display: inline-block;
  margin: 0 5px 0 5px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const ShareFacebook = styled(ShareBase)`
  background-image: url(${require("../../../root/img/journey/f.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/journey/f@2x.png")});
  }
`;

const ShareGoogle = styled(ShareBase)`
  background-image: url(${require("../../../root/img/journey/g.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/journey/g@2x.png")});
  }
`;

const ShareTwitter = styled(ShareBase)`
  background-image: url(${require("../../../root/img/journey/t.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/journey/t@2x.png")});
  }
`;

const ShareMail = styled(ShareBase)`
  background-image: url(${require("../../../root/img/journey/m.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/journey/m@2x.png")});
  }
`;

const UnderCopy = styled.div`
  margin-top: 5px;
  font-size: 15px;

  & a {
    color: #000;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
`;
