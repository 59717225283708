import styled, {keyframes} from "styled-components";
import {motion} from 'framer-motion'

// Skeleton base
const skeletonLoading = keyframes`
    0% {
        background-color: hsl(200, 20%, 80%);
    }
    100% {
        background-color: hsl(200, 20%, 95%);
    }
`;
export const Skeleton = styled.div`
    animation: ${skeletonLoading} 1s linear infinite alternate;
`;

// Box container
export const PlayerBoxWrapper = styled(motion.div)`
    --headerHeight: ${({ $headerHeight }) => ($headerHeight ? $headerHeight + 'px' : '0px')};
    --delay: ${({ $delay }) => ($delay ? $delay+'ms' : '0ms')};
    
    --defaultColor: #fff; 
    --defaultColorLight: rgba(255,255,255,0.6);
    --defaultColorLighter: rgba(255, 255, 255, .4);
    --colorProgressBar: rgba(255, 255, 255, .4);
    --colorPrime: #17BED0;
    --colorGray: #7A8D8F;
    &.dark-theme {
        --defaultColor: #000;
        --defaultColorLight: rgba(0,0,0,0.6);
        --defaultColorLighter: rgba(0,0,0,0.4);
        --colorProgressBar: #d7d7d7;
        --colorPrime: #017986;
    }

    transition: left 500ms ease-in-out, max-height var(--delay) ease-in-out, height var(--delay) ease-in-out;
    -moz-transition: left 500ms ease-in-out, max-height var(--delay) ease-in-out, height var(--delay) ease-in-out;
    -webkit-transition: left 500ms ease-in-out, max-height var(--delay) ease-in-out, height var(--delay) ease-in-out;
    -o-transition: left 500ms ease-in-out, max-height var(--delay) ease-in-out, height var(--delay) ease-in-out;

    & .animate {
        transition: all var(--delay) linear;
        -moz-transition: all var(--delay) linear;
        -webkit-transition: all var(--delay) linear;
        -o-transition: all var(--delay) linear;
        transition-delay: 0ms;
        -moz-transition-delay: 0ms;
        -webkit-transition-delay: 0ms;
        -o-transition-delay: 0ms;
    }

    & .fade-transition {
        transition: opacity var(--delay) ease-in-out;
        -moz-transition: opacity var(--delay) ease-in-out;
        -webkit-transition: opacity var(--delay) ease-in-out;
        -o-transition: opacity var(--delay) ease-in-out;
        transition-delay: 0ms;
        -moz-transition-delay: 0ms;
        -webkit-transition-delay: 0ms;
        -o-transition-delay: 0ms;
    }

    &.isPublic:not(.isMobile) {
        &.oneSong {
            transition: none;
            -moz-transition: none;
            -webkit-transition: none;
            -o-transition: none;
        }

        & .animate {
            transition: none;
            -moz-transition: none;
            -webkit-transition: none;
            -o-transition: none;
        }
    }

    &:not(.fullPagePlayer):not(.isMobile) {
        max-height: ${({ $showPlayer }) => (!$showPlayer ? '0px' : '377px')};

        &.fixed-container {
            position: fixed;
            bottom: 3px;
            right: 4px;
        }

        z-index: 100;
        width: 400px;
        border-radius: 4px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.22);
        overflow: hidden;
    }
    &.fullPagePlayer,
    &.isMobile {
        width: 100%;
        height: ${({ $showPlayer }) => (!$showPlayer ? '0px' : 'calc(100% - var(--headerHeight))')};
        z-index: 105;
        overflow: hidden;

        &:not(.minimizePlayer) {
            bottom: 0;
            left: 0;
            position: ${({ $isSingleSongPlayer }) => $isSingleSongPlayer ? 'static' : 'fixed'};
            &.fullPagePlayer, &.isPublic {
                height: ${({ $showPlayer }) => (!$showPlayer ? '0px' : '100%')};
            }
        }

        &.minimizePlayer {
            position: relative;
            height: 60px;
            border-radius: 0;
            left: 0;
        }
    }

    &.isMobile {
        max-height: ${({ $showPlayer }) => (!$showPlayer ? '0px' : '100%')};

        & div[class^="OpenAppButton__Container"] {
            width: 30px;
            height: 30px;
            transform: translateY(-8px);
        }
    }
`;
export const BackdropOverlay = styled.div`
    display: none;
    
    &.minimizePlayer.isMobile {
        transition: left 500ms ease-in-out;
        -moz-transition: left 500ms ease-in-out;
        -webkit-transition: left 500ms ease-in-out;
        -o-transition: left 500ms ease-in-out;

        background-color: #7F868C;
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 0 10px;
    }
`;
export const MainContainer = styled.div`    
    &.fullPagePlayer {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        position: relative;
        padding-top: 30px;
    }
    &.miniPlayer.isMobile {
        height: 100%;
    }
    &.standartPlayer {
        height: 89px;
    }
    &.standartPlayer.isMobile {
        height: 106px;
    }
`;
export const SwipeBoxControlWrapper = styled(motion.div)`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    touch-action: none;
`;

// Header area
export const HeaderWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(255, 255, 255, 0.30);
    height: auto;
    padding: 8px 9px 8px 16px;

    &.isMobile {
        padding: 20px 9px 20px 12px;
        height: 72px;
    }
`;
export const HeaderTitleWrapper = styled.div`
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    color: var(--defaultColor);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    
    & > div {
        display: inline-block;
        vertical-align: middle;
        margin-left: 15px;
    }
`;
export const HeaderControlsWrapper = styled.div`
    display: flex;
    justify-content: space-between;

    &.isPublic {
        position: relative;
    }

    &.isMobile {
        column-gap: 10px;
    }
`;

// Box Controls area
export const BoxPlayerWrapper = styled.div`
    width: ${({$width}) => $width ? $width+'px' : '100%'};
    position: relative;
    box-sizing: border-box;
    display: flex;

    &.miniPlayer,
    &.fullPagePlayer {
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        row-gap: 8px;
        padding: 25px 50px;
        box-sizing: border-box;

        &.miniPlayer {
            padding: 24px 80px 16px;
        }
        
        &.miniPlayer.isMobile {
            height: 100%;
        }

        &.isMobile {
            padding: 32px;
            row-gap: 24px;
        }
    }

    &.fullPagePlayer {
        width: 320px;
        padding: 0;
        position: static;
    }

    &.standartPlayer {
        align-items: center;
        justify-content: space-between;
        column-gap: 16px;
        padding: 13px 8px 6px 16px;

        & > .thumb-wrapper {
            align-self: start;
        }
        
        &.isMobile {
            column-gap: 12px;
            padding-bottom: 5px;
            align-items: start;
        }
    }

    &.minimizePlayer {
        align-items: center;
        justify-content: space-between;
        column-gap: 8px;
        padding: 3px 8px 3px 8px;

        & .progress-bar {
            width: 100%;
            position: absolute;
            bottom: -5px;
            left: 1px;
        }

        &.isMobile {
            height: 60px;
            padding: 8px;
            column-gap: 16px;
        }
    }

`;
export const MobileMiniWrapper = styled(motion.div)`
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    touch-action: none;
`;
export const MobileMiniSongWrapper = styled.div`
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    width: 100%;
`;
export const MobileMiniControlsWrapper = styled.div`
    width: 100%;
`;
export const BoxSongWrapper = styled.div`
    width: 100%;

    &.minimizePlayer {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: space-between;
        min-width: 0;
    }
    &.standartPlayer.isMobile {
        min-width: 0;
    }
`;
export const  BoxSongNameWrapper = styled(motion.div)`
    font-size: 13px;
    line-height: 15px;
    height: 15px;
    font-weight:  500;
    color: var(--defaultColor);

    &.isMobile {
        font-size: 14px;
        line-height: 16px;
        height: 16px;
    }

    &.miniPlayer {
        font-size: 13px;
        line-height: 15px;
        height: 15px;
        font-weight: 500;
        &.isMobile {
            font-size: 20px;
            line-height: 23px;
            height: 23px;
            font-weight: 500;
            margin-bottom: 4px;
        }
    }
    &.minimizePlayer {
        font-size: 15px;
        line-height: 18px;
        height: 18px;
        font-weight: 400;
        &.isMobile {
            font-weight: 500;
        }
    }
    &.fullPagePlayer {
        font-size: 20px;
        line-height: 23px;
        height: 23px;
        font-weight: 400;
    }
`;
export const BoxSongArtistWrapper = styled.div`
    font-size: 13px;
    line-height: 15px;
    height: 15px;
    font-weight: 500;
    color: var(--defaultColorLight);

    &.isMobile {
        font-size: 14px;
        line-height: 16px;
        height: 16px;
    }

    &.miniPlayer.isMobile {
        font-size: 16px;
        line-height: 19px;
        height: 19px;
    }

    &.minimizePlayer.isMobile {
        font-size: 15px;
        line-height: 18px;
        height: 18px;
        font-weight: 400;
    }
    &.fullPagePlayer {
        display: none;
    }
    &.standartPlayer {
        margin-bottom: 3px;
    }
`;
export const BoxTopHeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;

    &.minimizePlayer {
        margin-bottom: 0;
        min-width: 0;
    }

    &.standartPlayer {
        margin-bottom: 0;
    }
`;
export const BoxSongDetailsWrapper = styled(motion.div)`
    width: 230px;
    &.miniPlayer, &.minimizePlayer {
        width: 100%;
        text-align: center;
    }

    &.minimizePlayer.isMobile {
        text-align: left;
    }

    &.standartPlayer.isMobile {
        width: calc(100% - 25px);
        min-width: 0;
    }

    &.fullPagePlayer {
        width: 100%;
        margin-bottom: 24px;
        text-align: center;
    }
`;
export const BoxTopHeaderControlsWrapper = styled.div`

    &.miniPlayer,
    &.standartPlayer {
        position: absolute;
        top: 5px;
        right: 7px;
        z-index: 4;
        display: flex;
    }

    &.standartPlayer {
        display: flex;
        justify-content: end;
        width: 45px;
    }

    &.fullPagePlayer {
        position: absolute;
        top: 10px;
        right: 10px;
        display: flex;
        justify-content: end;
    }
`;
export const BoxWaveHolder = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
    margin-bottom: 10px;
`;
export const BoxMiniProgressWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 8px;

    & > div[class^="styles__ButtonWrapper"] {
        width: 30px;
        height: 30px;
        transform: translateY(-8px);
    }
`;
export const SongControlsWrapper = styled(motion.div)`
    display: flex;
    align-items: center;
    &.miniPlayer {
        justify-content: space-between;
        margin-top: 8px;

        &.isMobile {
            width: 100%;
            margin-top: 12px
        }
    }
    &.standartPlayer,
    &.fullPagePlayer {
        justify-content: space-between;
    }
    &.fullPagePlayer {
        width: 100%;
        height: 40px;
    }
`;
export const SongControlsCenterWrapper = styled.div`
    display: flex;
    column-gap: 16px;

    &.miniPlayer.isMobile {
        column-gap: 35px;
    }
    
    &.standartPlayer.isMobile {
        column-gap: 0;
    }

    &.minimizePlayer {
        column-gap: 0;
    }

    &.fullPagePlayer {
        column-gap: 16px;
    }
`;

// Playlist area
export const SettingsItemWrapper = styled.div`
    padding: 4px 10px;
    display: flex;
    align-items: center;
    justify-content: start;
    column-gap: 10px;
    cursor: pointer;
    box-sizing: border-box;
    width: 100%;
    
    &.only-text {
        padding: 9px 12px;
    }
    &.active {
        color: var(--colorPrime)
    }
    &.secondary-item {
        color: #8C959D;
        cursor: auto;
    }
`;

export const SettingItemIconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;

    &.svg-color-gray svg {
        color: var(--colorGray);
    }
`;
export const SettingsItemHR = styled.div`
    background: #D8D8D8;
    height: 1px;
    width: 100%;
`;
export const SettingsItemLabelWrapper = styled.div`
    white-space: nowrap;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
`;
export const PlaylistControlsWrapper = styled.div`
    position: relative;
    box-sizing: border-box;
    background: #fff;
    padding: 12px 16px;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, .11);
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    height: 100%;
    justify-content: center;
`;

export const PlaylistLabelWrapper = styled.div`
    font-size: 13px;
    font-weight: bold;
`;
export const PlaylistInputWrapper = styled.div`
    width: 100%;
`;

// Box Queue area
export const BoxQueueWrapper = styled(motion.div)`
    height: calc(100% - ${({$topPartHeight}) => $topPartHeight ? $topPartHeight+"px" : "0px"});
    
    &.is-dragging-over .remove-item {
        opacity: 0;
    }
`;
export const BoxQueueListWrapper = styled.div`
    position: relative;
    row-gap: 5px;
    width: 100%;
    background: #fff;
    height: 100%;
    padding: 10px 0;
    box-sizing: border-box;
`;
export const BoxScrollableQueueWrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;

    &, & * {
        -webkit-transition: none !important;
        -moz-transition: none !important;
        -o-transition: none !important;
        transition: none !important;
    }
    & .List {
        overflow-x: hidden!important;
        
        &:not(.isMobile)::-webkit-scrollbar {
            width: 6px;
            visibility: hidden;
        }

        &:hover, &.isMobile {
            &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
                background-color: #D9D9D9;
                border-radius: 5px;
            }

            &::-webkit-scrollbar {
                visibility: visible;
                display: inline-block;
                width: 6px;
                background-color: #f5f5f5;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #898991;
                border-radius: 5px;
            }
        }
    }
`;
export const SwipeQueueItemContainerWrapper = styled(motion.div)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 5px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    background: ${({$activeSong}) => $activeSong ? "#f7f7f7" : "#fff"};
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding-left: 10px;

    &:hover {
        background: #f7f7f7;
    }
`;
export const BackdropQueueOverlay = styled.div`
    background-color: #7F868C;
    height: calc(100% - 2px);
    transform: translateY(-1px);
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 10px;
    border-radius: none;
`;
export const BoxQueueListItemWrapper = styled.div`
    background: #fff;
    padding: 8px 6px 8px 16px;
    box-sizing: border-box;
    cursor: pointer;
    border: none;

    &:not(.is-dragging):hover .remove-item {
        display: inline-block;
    }

    & .thumb-container {
        align-self: auto!important;
    }
`;
export const BoxQueueSongDetailsWrapper = styled.div`
    width: 100%;
    min-width: 0;
    font-weight: ${({$activeSong}) => $activeSong ? "bold" : "400"};
    font-size: 13px;
    line-height: 15px;
`;
export const BoxQueueSongName = styled.div`
    color: #000;
    height: 15px;
`;
export const BoxQueueSongArtist = styled.div`
    color: rgba(0, 0, 0, 0.4);
    height: 15px;
`;
export const BoxQueueSongNameSkeleton = styled(Skeleton)`
    width: 70%;
    height: 15px;
    border-radius:5px;
`;
export const BoxQueueSongArtistSkeleton = styled(Skeleton)`
    width: 40%;
    height: 15px;
    border-radius:5px;
    margin-top: 3px;
`;
export const BoxQueueControlsWrapper = styled.div`
    display: flex;
    height: 100%;
    justify-content: end;
    align-items: center
`;
export const BoxQueueControlsRemoveWrapper = styled.div`
    display: none;
`;
export const BoxQueueButtonWrapper = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    
    &:not(.isMobile).drag-item {
        margin-right: 7px;
    }
    
    &.isMobile {
        width: 40px;
        height: 40px;
    }
`;
export const ShowAppButtonQueueWrapper = styled.div`
    position: fixed;
    width: 100%;
    height: auto;
    box-sizing: border-box;
    left: 0;
    bottom: 0;
    padding: 32px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
`;

// Shared
export const LinkButton = styled.div`
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
    color: rgb(23, 190, 208);
`;
export const DisabledText = styled.span`
    color: rgba(0,0,0,0.4);
`;
export const FlexWrapper = styled.div`
    display: flex;
    width: ${({$width}) => $width ? $width+'px' : '100%'};
    align-items: center;
    justify-content: ${({$justifyContent}) => $justifyContent ? $justifyContent : 'space-between'};
    column-gap: ${({$columnGap}) => $columnGap !== undefined ? $columnGap+'px' : '8px'};
`;
export const DivWrapper = styled(motion.div)`
    width: ${({$width}) => $width ? $width : "100%"};
    height: auto;

    &.fullPagePlayer {
        display: flex;
        flex-direction: column;
        row-gap: 16px;
        justify-content: center;
        align-items: center;
    }

    &.miniPlayer.main-layout.isMobile {
        height: calc(100% - 72px);
    }

    &.miniPlayer.isMobile {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &.bottom-controls {
            height: auto;
        }
    }

    &.fade-transition {
        opacity: ${({$show}) => $show ? 1 : 0};
    }

    &.progress-bar {
        opacity: 0;
    }
    &.progress-bar,
    &.progress-bar * {
        -webkit-transition: none !important;
        -moz-transition: none !important;
        -o-transition: none !important;
        transition: none !important;
    }
`;
export const OverlayPortalContainer = styled.div`
    position: relative;
`;
export const FlexColumnWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
`;
export const OverlayPanelWrapper = styled.div`
    background: #fff;
    padding: 10px 0px;
    box-sizing: border-box;
    width: ${({$width}) => $width ? $width+"px" : "auto"};
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    row-gap: 4px;
    position: absolute;
    z-index: 4;

    &.volume {
        left: -20px;
        top: 35px;
    }

    &.settings {
        right: 0;
        top: 35px;
    }

    &.play-rate {
        left: -16px;
        right: auto;

        &.position-top {
            bottom: 30px;
            top: auto;
        }
        &.position-bottom {
            top: 30px;
            bottom: auto;
        }

        &.standartPlayer {
            bottom: auto;
            top: 25px;
            &.isMobile {
                top: 35px;
            }
        }
    }

    &.sleep-timer {
        right: -35px;
        left: auto;
        padding: 5px 0;
        
        & .only-text {
            padding: 8px 12px;
        }
        
        &.isMobile {
            right: -10px;
        }
        
        &.position-top {
            bottom: 30px;
            top: auto;
        }
        &.position-bottom {
            top: 30px;
            bottom: auto;
        }
        
        &.standartPlayer {
            left: auto;
            right: 0;
            bottom: auto;
            top: 25px;
            &.isMobile {
                top: 35px;
            }
        }
    }
`;
export const PublicButtonWrapper = styled.div`
    height: 30px;
    font-weight: bold;
    font-size: 12px;
    width: 100%;
    text-align: center;
    color: ${({$white}) => $white ? "var(--colorPrime)" : "#fff"};
    background: ${({$white}) => $white ? "#F5FBFD" : "var(--colorPrime)"};
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
        background: ${({$white}) => $white ? "#EEF3F5" : "#16a6b6"};
    }

    & > svg {
        color: ${({$white}) => $white ? "var(--colorPrime)" : "#fff"};
        margin-right: 10px;
    }
`;

// Volume area
export const VolumeInputWrapper = styled.div`
    position: relative;
    width: 4px;
    height: 110px;
    margin-bottom: 10px;
`;
export const VolumeInput = styled.input`
    --progress-volume: 0.5;
    padding: 10px 0;
    cursor: pointer;
    position: absolute;
    left: -55px;
    top: 50px;
    width: 110px;
    overflow: hidden;
    border-radius: 10px;
    -webkit-appearance: none;
    -webkit-transform: rotate(270deg);
    z-index: 4;

    &::-webkit-slider-runnable-track {
        width: 300px;
        height: 4px;
        background: #DCDCDF;
        border: none;
        border-radius: 10px;
    }
    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        border: none;
        height: 16px;
        width: 16px;
        border-radius: 50%;
        background: transparent;
        margin-top: -5px;
    }
    &::-moz-range-track {
        width: 300px;
        height: 4px;
        background: #DCDCDF;
        border: none;
        border-radius: 10px;
    }
    &::-moz-range-thumb {
        -webkit-appearance: none;
        border: none;
        height: 16px;
        width: 16px;
        border-radius: 50%;
        background: transparent;
        margin-top: -5px;
    }
    &::-moz-range-progress {
        background-color: var(--colorPrime);
        height: 4px;
        border: none;
        border-radius: 10px;
    }

    &:focus {
        outline: none;
    }

    &::before {
        content: ' ';
        transform: scaleX(var(--progress-volume));
        z-index: 3;
        background-color: var(--colorPrime);
        transform-origin: left;
        position: absolute;
        width: 100%;
        height: 4px;
        border-radius: 10px;
    }
`;
export const ProgressBarWrapper = styled.div`
    margin-top: 10px;
    width: 100%;
    height: 3px;
    position: relative;
    transform: translateY(-5px) translateX(-3px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 8px;
    
    &.minimizePlayer {
        height: 4px;
    }
`;
export const ProgressBarInputWrapper = styled.input`
    --progress-width: 0;
    --buffered-width: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 3;
    appearance: none;
    background: var(--colorProgressBar);
    border-radius: 5px;
    
    &:hover {
        &::-webkit-slider-thumb{
            width: 9px;
            height: 9px;
        }
        &::-moz-range-thumb{
            width: 9px;
            height: 9px;
        }
    }

    &::-webkit-slider-thumb{
        background: var(--colorPrime);
        width: 7px;
        height: 7px;
        position: relative;
        -webkit-appearance: none;
        border-radius: 50%;
        opacity: ${({$showPointer}) => $showPointer ? 1 : 0};
        z-index: 3;
        &:hover {
            width: 9px;
            height: 9px;
        }
    }
    &::-moz-range-thumb {
        background: var(--colorPrime);
        width: 7px;
        height: 7px;
        position: relative;
        -webkit-appearance: none;
        border-radius: 50%;
        opacity: ${({$showPointer}) => $showPointer ? 1 : 0};
        z-index: 3;
    }

    &::-moz-range-progress {
        background-color: var(--colorPrime);
        z-index: 3;
    }

    &::before {
        content: ' ';
        transform: scaleX(var(--progress-width));
        z-index: 3;
        background-color: var(--colorPrime);
        transform-origin: left;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 5px;
    }

    &::after {
        content: ' ';
        transform: scaleX(var(--buffered-width));
        transform-origin: left;
        z-index: 2;
        background: rgb(197, 197, 197);
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 5px;
    }
    
    &.minimizePlayer {
        background: transparent;
        &::after {
            background: transparent;
        }
    }
`;
export const ProgressBarSongTimeWrapper = styled.div`
    max-width: 50px;
    font-size: 11px;
    font-weight: 400;
    color: var(--defaultColorLighter);
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    white-space: nowrap;
`;
export const ButtonWrapper = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${({$width}) => $width ? $width+"px" : "30px"};
    height: ${({$height}) => $height ? $height+"px" : "30px"};
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
    
    &.disabled {
        opacity: 0.3; 
        cursor: auto;
    }

    &.svg-color-default svg {
        color: var(--defaultColor);
        fill: var(--defaultColor);
    }
    &.svg-color-default-light svg {
        color: var(--defaultColorLight);
        fill: var(--defaultColorLight);
    }
    &.svg-color-default-lighter svg {
        color: var(--defaultColorLighter);
        fill: var(--defaultColorLighter);
    }
    &.svg-color-prime svg {
        color: var(--colorPrime);
        fill: var(--colorPrime);
    }
    &.svg-color-gray svg {
        color: var(--colorGray);
        fill: var(--colorGray);
    }
    &.color-default {
        color: var(--defaultColor);
    }
    &.color-default-light {
        color: var(--defaultColorLight);
    }
    &.color-prime {
        color: var(--colorPrime);
    }
`;
export const IconText = styled.div`
    font-size: ${({$fontSize}) => $fontSize ? $fontSize+'px' : '13px'};
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
`;