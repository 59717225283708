// @flow

import React, { Component } from "react";
import { connect } from "react-redux";

import apiMethod from "../../api/apiMethod";
import { __ } from "../../lib/translate";

import { ButtonCentered } from "../../components/ButtonDefault";
import { Container, WarningIcon, Header, Message } from "./SharedComponents";

type Props = {
  token: string,
  onSuccess: () => void
};

type State = {
  isLoading: boolean
};

class SetupPassStep extends Component<Props, State> {
  static defaultProps = {
    token: "",
    onSuccess: () => {}
  };

  constructor(props: Props) {
    super(props);

    (this: any).onSubmit = (this: any).onSubmit.bind(this);

    this.state = { isLoading: false };
  }

  onSubmit() {
    const { token, onSuccess } = this.props;
    const { isLoading } = this.state;

    this.setState({ isLoading: true });

    apiMethod(
      "sendsetpasswordlink",
      {
        auth: token,
        fromtwofacauth: true
      },
      res => {
        this.setState({ isLoading: false });
        onSuccess();
      },
      {
        errorCallback: ({ error }) => {
          this.setState({ isLoading: false });
          HFN.message(__("something_went_wrong_refresh_and_try_again"), "error");
          throw new Error(error);
        }
      }
    );
  }

  render() {
    const { isLoading } = this.state;

    return (
      <Container>
        <Header>{__("Set up a password")}</Header>
        <Message className="understand setup-pass">
          <WarningIcon className="important-icon" />
          {__("tfa_setup_pass_setup_message")}
        </Message>
        <ButtonCentered
          disabled={isLoading}
          loading={isLoading}
          onClick={this.onSubmit}
          style={{ width: "100%", marginTop: "30px" }}
        >
          {__("Set up a password")}
        </ButtonCentered>
      </Container>
    );
  }
}

export default connect(({ user }) => {
  return { token: user.token };
})(SetupPassStep);
