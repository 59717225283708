// @flow

import React from "react";
type Props = {
  // use className to style textarea
  className?: string,
  name?: string,
  value: any,
  placeholder: ?string,
  innerRef?: null
};

const InputTextarea = ({
  className = "",
  innerRef = null,
  name = "",
  value,
  placeholder,
  ...rest
}: Props) => {
  return (
    <textarea
      className={className}
      ref={innerRef}
      placeholder={placeholder}
      name={name}
      value={value}
      {...rest}
    />
  );
};

export default InputTextarea;
