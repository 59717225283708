// @flow

import React from "react";
import styled from "styled-components";
import { hiDPI } from "polished";
import { connect } from "react-redux";

import { getToken } from "../lib/notifications/push";
import { pushSubscribe } from "../lib/notifications/pushSubscribe";
import { initBasicNotifications } from "../lib/notifications/pushSubscribe";
import type { UserState } from "../lib/state/reducers/user";

import { setcookie } from "../lib/utils";

type NotificationsPopupProps = {
  user: UserState
};

type NotificationsPopupState = {
  permissionState:  "ask" | "block" | "allow",
  pushToken?: string
};

class NotificationsPopup extends React.Component<NotificationsPopupProps, NotificationsPopupState> {
  constructor(props) {
    super(props);

    this.state = { permissionState: "ask" };
  }

  _checkNotificationsState() {
    console.log(this.props);

    const token = this.props.user.logged ? this.props.user.token : undefined;
    initBasicNotifications(
      token,
      () => {
        this.setState({ permissionState: "allow" });
        setcookie("notifications-subscribed", 1, 30);
        window.close();
      },
      () => {
        this.setState({ permissionState: "block" });
      }
    );
  }

  componentDidMount() {
    this._checkNotificationsState();
  }

  _allow() {
    return (
      <Wrap>
        <AllowImage />
        <Title>You've already subscribed for notifications.</Title>
        <SubTitle>You may now close this window.</SubTitle>
      </Wrap>
    );
  }

  _ask() {
    return (
      <Wrap>
        <AllowImage />
        <Title>Click on 'Allow' to confirm push notifications</Title>
        <SubTitle>Never miss a promo or update from us! Subscribe to receive them first!</SubTitle>
      </Wrap>
    );
  }

  _block() {
    return (
      <Wrap>
        <BlockedImage />
        <Title>You seem to have previously blocked/denied push notifications from pCloud.</Title>
        <SubTitle>See the image   above to know how to unblock them.</SubTitle>
      </Wrap>
    );
  }

  _loading() {
    return (
      <Wrap>
        <Loading />
      </Wrap>
    );
  }

  render() {
    const { permissionState } = this.state;

    return (
      <div>
        {permissionState === "init" && this._loading()}
        {permissionState === "ask" && this._ask()}
        {permissionState === "allow" && this._allow()}
        {permissionState === "block" && this._block()}
      </div>
    );
  }
}

export default connect(state => ({
  user: state.user
}))(NotificationsPopup);

const Wrap = styled.div`
  width: 550px;
  height: 500px;
  box-sizing: border-box;
  padding: 20px;
  font-family: "Roboto", sans-serif;
`;

const Logo = styled.div`
  width: 120px;
  height: 120px;
  margin: 0 auto 20px auto;
`;

const Title = styled.div`
  font-size: 20px;
  margin: 20px 20px 20px 20px;
  text-align: center;
`;

const SubTitle = styled.div`
  font-size: 14px;
  margin: 0 20px;
  text-align: center;
`;

const AllowImage = styled.div`
  width: 118px;
  height: 118px;
  background-image: url(${require("../../root/img/notifications/push.png")});
  ${hiDPI()} {
    background-image: url(${require("../../root/img/notifications/push@2x.png")});
  }
  background-size: 118px 118px;
  margin: 100px auto 60px auto;
`;

const BlockedImage = styled.div`
  background: url(${require("../../root/img/notifications/unblock.png")}) no-repeat center center;
  height: 354px;
  padding: 0 0 0 0;
  margin: 10px 0 10px 0;
  background-size: 418px 354px;
`;

const Loading = styled.div`
  background: url(${require("../../root/img/loading.gif")}) no-repeat center center;
  height: 100%;
  width: 100%;
`;
