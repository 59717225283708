import React from "react";
import AddPlaylistIcon from "../../../../../../root/img/player/add-playlist.svg";
import PlayPlaylistIcon from "../../../../../../root/img/player/play-playlist.svg";
import SharePlaylist from "../../../../../../root/img/player/share-playlist.svg";

import { __ } from "../../../../../lib/translate";
import usePlayerContext from "../../../hooks/usePlayerContext";
import {
    SettingItemIconWrapper, SettingsItemLabelWrapper,
    SettingsItemWrapper, SettingsItemHR
} from "../../../userinterface/styles";
import {getPlayerState} from "../../../helpers";

const PlaylistSettingItems = () => {
    const {
        playlist,
        onSharePlaylist,
        onOpenPlaylistLoadForm,
        onOpenPlaylistCreateForm,
        onCloseAllOverlays,
        playerVariant
    } = usePlayerContext();

    return (
        <>
            <SettingsItemWrapper
                onClick={onOpenPlaylistLoadForm}
            >
                <SettingItemIconWrapper>
                    <PlayPlaylistIcon />
                </SettingItemIconWrapper>
                <SettingsItemLabelWrapper>
                    {__('Load playlist')}
                </SettingsItemLabelWrapper>
            </SettingsItemWrapper>
            <SettingsItemWrapper
                onClick={onOpenPlaylistCreateForm}
            >
                <SettingItemIconWrapper>
                    <AddPlaylistIcon />
                </SettingItemIconWrapper>
                <SettingsItemLabelWrapper>
                    {__('Save as playlist')}
                </SettingsItemLabelWrapper>
            </SettingsItemWrapper>
            {playlist && (
                <SettingsItemWrapper
                    onClick={() => {
                        onSharePlaylist();
                        onCloseAllOverlays();

                        if (typeof gtag === "function") {
                            gtag("event", "audio_player_click", {
                                action: "share playlist",
                                state: getPlayerState(playerVariant)
                            });
                        }
                    }}
                >
                    <SettingItemIconWrapper>
                        <SharePlaylist />
                    </SettingItemIconWrapper>
                    <SettingsItemLabelWrapper>
                        {__('Share playlist')}
                    </SettingsItemLabelWrapper>
                </SettingsItemWrapper>
            )}
            <SettingsItemHR />
        </>
    );
}

export default PlaylistSettingItems;