// @flow

import React from "react";
import Componentify from "react-componentify";

import { __ } from "../../../lib/translate";
import { boldConverter } from "../../../lib/componentifyConverters";
import SuccessIcon from "../../../../web-shared/images/svg/success-icon.svg";
import { SuccContainer, SuccHeader, Description } from "./StyledComponents";

type Props = {
  currentFlowName: string,
  expDate: string
};

const SuccessMessageStep = ({ currentFlowName = "payNow", expDate = "N/A" }: Props) => {
  const messages = {
    changeCard: {
      header: __("b_billing_succ_message_header_change_card", "Card details updated"),
      desc: __(
        "b_billing_succ_message_desc_change_card",
        "Your account has been updated with your new credit card details."
      )
    },
    upgrade: {
      header: __("b_billing_succ_message_header_upgrade", "Billing updated"),
      desc: __(
        "b_billing_succ_message_desc_upgrade",
        "From now on, you will be billed annually for your pCloud Business subscription."
      )
    },
    updateUsers: {
      header: __("b_billing_succ_message_header_update", "User quota updated"),
      desc: __(
        "b_billing_succ_message_desc_update",
        "The user quota in your Business account has been updated."
      )
    },
    payNow: {
      header: __(
        "b_billing_succ_message_header_start",
        "You started a pCloud Business Subscription"
      ),
      desc: __(
        "b_billing_succ_message_desc_start",
        "You can get an invoice until the 5th of the next month. If you miss the time limit, you can only get a sales receipt."
      )
    },
    cancelSubs: {
      header: __("calcel_subsc_success_header", "Subscription cancelled"),
      desc: (
        <Componentify
          text={__(
            "calcel_subsc_success_desc",
            "Your Business plan has been canceled and will expire on <b>%date%.</b>",
            { date: expDate }
          )}
          converters={[boldConverter]}
        />
      )
    }
  };

  const { header, desc } = messages[currentFlowName];

  return (
    <SuccContainer key="succMessage">
      {currentFlowName !== "cancelSubs" ? <SuccessIcon width="64px" fill="#86c600" /> : null}
      <SuccHeader>{header}</SuccHeader>
      <Description>{desc}</Description>
    </SuccContainer>
  );
};

export default SuccessMessageStep;
