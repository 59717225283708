import PropTypes from "prop-types";
import React, { Component } from "react";
import Scrollbar from "../../components/Scrollbar";
import styled, { keyframes } from "styled-components";
import { hiDPI } from "polished";
import { AutoSizer } from "react-virtualized";

import { TSpan } from "../../components/intl";

const Wrap = styled.div`
  background: #f4f4f4;
  width: 360px;
  padding: 0px 20px 20px 20px;
  height: 100%;
`;

const Head = styled.div`
  height: 54px;
  border-bottom: 0px solid #e9e9e9;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: normal;
  float: left;
  line-height: 54px;
  margin: 0;
`;

const rotate0 = keyframes`
  from { transform: rotate(45deg); }
  to { transform: rotate(0deg); }
`;

const rotate45 = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(45deg); }
`;

const Close = styled.div`
  transform: rotate(${props => (props.open ? "0def" : "45deg")});
  animation: ${props => (props.open ? rotate0 : rotate45)} 0.25s ease;

  cursor: pointer;
  margin: 15px 0 0 0;
  float: right;
  width: 20px;
  height: 20px;
  background-size: 12px 12px;
  background-repeat: no-repeat;
  background-position: center center;

  background-image: url(${require("../../../root/img/dlink/close.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/dlink/close@2x.png")});
  }

  &:hover {
    opacity: 0.7;
  }
`;

const Body = styled.div`
  padding: 0px 0px 0 0;
`;

const DownloadLinkStatsHolder = ({
  onButtonClicked,
  children,
  open = true
}) => (
  <Wrap>
    <Head>
      <Title><TSpan id="dl_statistics">Statistics</TSpan></Title>
      <Close onClick={onButtonClicked} open={open} />
    </Head>
    {open &&
      <div style={{ height: "100%" }}>
        <AutoSizer disableWidth>
          {({ height, width }) => {
            return <Scrollbar style={{ height: height - 140 }}><Body>{children}</Body></Scrollbar>
          }}
        </AutoSizer>
      </div>}
  </Wrap>
);

const StatsDivider = styled.div`
  border-top: 1px solid #e9e9e9;
  height: 0px;
`;

export { StatsDivider };
export default DownloadLinkStatsHolder;
