// @flow

import React, { PureComponent } from "react";
import styled from "styled-components";

import { __ } from "../../lib/translate";

import Button from "../../components/ButtonDefault";
import { Container, Header, Message, Label, Footer } from "./SharedFamilyComponents";
import type { Member, Invite } from "./types";

type Props = {
  title: string,
  currentItem: Member | Invite,
  onClose: () => void
};

class CopyLinkModal extends PureComponent<Props, {}> {
  static defaultProps = {
    title: "Copy link",
    currentItem: "",
    onClose: () => {}
  };

  constructor(props: Props) {
    super(props);

    (this: any).copyToClipboard = this.copyToClipboard.bind(this);
  }

  componentDidMount() {
    this.copyToClipboard();
  }

  copyToClipboard() {
    const inviteLinkInput = document.getElementById("inviteLink");
    inviteLinkInput && inviteLinkInput.select();
    document.execCommand("copy");
    HFN.message(__("family_invitation_link_copy"));
  }

  render() {
    const { title, currentItem, onClose } = this.props;
    const { invitelink } = currentItem;

    return (
      <Container>
        <Header>{__(title)}</Header>
        <Message className="left">
          <Label>{__("Link")}</Label>
          <InviteLink
            id="inviteLink"
            type="text"
            name="inviteLink"
            value={invitelink}
            onClick={this.copyToClipboard}
            readOnly
          />
        </Message>
        <Footer>
          <Button
            color="lightgray4"
            style={{
              marginRight: "5px"
            }}
            onClick={onClose}
          >
            {__("Close")}
          </Button>
          <Button
            color="cyan"
            style={{
              marginLeft: "5px"
            }}
            onClick={this.copyToClipboard}
          >
            {__("shared_links_settings_copy_link", "Copy Link")}
          </Button>
        </Footer>
      </Container>
    );
  }
}

export default CopyLinkModal;

const InviteLink = styled.input`
  width: 100%;
  height: 32px;
  font-size: 13px;
  padding: 0 5px;
  border-radius: 5px;
  border: solid 1px #61d2df;
  background-color: #ffffff;
  box-sizing: border-box;
`;
