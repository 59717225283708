import React, {useEffect} from 'react';
import PlayerBoxUI from "./UI";
import {BackdropOverlay, PlayerBoxWrapper} from "../userinterface/styles";
import {getPageHeaderHeight, mediaSessionActions} from "../helpers";
import AudioTag from "../userinterface/AudioTag";
import usePlayerContext from "../hooks/usePlayerContext";
import {GENERAL_TRANSITION_DELAY} from "../constants";
import {LayoutGroup, MotionConfig} from 'framer-motion'
import ActionButton from "../userinterface/ActionButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose} from '@fortawesome/pro-solid-svg-icons/faClose';

const AudioPlayer = () => {
    const {
        isReady,
        isPlaying,
        playerBoxRef,
        audioRef,
        isWhiteBackground,
        backDropOverlayRef,
        isPublic,
        isMobile,
        playerVariant,
        additionalClass,
        currentSong,
        loadedThumbs,
        onPrev,
        onNext,
        isSingleSongPlayer
    } = usePlayerContext();

    useEffect(() => {
        if (!isReady) {
            return;
        }

        mediaSessionActions.update({
            onPrev,
            onNext,
            currentSong,
            loadedThumbs,
            audio: audioRef.current,
        });
    }, [loadedThumbs, currentSong.fileid, isReady, isPlaying]);

    const headerHeight = getPageHeaderHeight(isMobile, isPublic);

    let playerClasses = additionalClass + ' animate fixed-content';
    if (isWhiteBackground) {
        playerClasses += ' dark-theme'
    }

    return (
        <>
            <AudioTag />
            <MotionConfig
                transition={{
                    opacity: {ease: "linear"},
                    layout: {duration: 0.25},
                }}
            >
                <LayoutGroup>
                    <PlayerBoxWrapper
                        ref={playerBoxRef}
                        $isSingleSongPlayer={isSingleSongPlayer}
                        $showPlayer={playerVariant.showPlayer}
                        $headerHeight={headerHeight}
                        $delay={GENERAL_TRANSITION_DELAY}
                        className={playerClasses}
                        layout={true}
                    >
                        <BackdropOverlay
                            className={additionalClass}
                            ref={backDropOverlayRef}>
                            <ActionButton
                                onClick={() => {}}
                                className={'svg-color-default'}
                                label={<FontAwesomeIcon style={{fontSize: 20}} icon={faClose} />}
                            />
                            <ActionButton
                                onClick={() => {}}
                                className={'svg-color-default'}
                                label={<FontAwesomeIcon style={{fontSize: 20}} icon={faClose} />}
                            />
                        </BackdropOverlay>
                        <PlayerBoxUI/>
                    </PlayerBoxWrapper>
                </LayoutGroup>
            </MotionConfig>
        </>
    );
}

export default AudioPlayer;