// @flow

import React, { useState, useEffect, useRef, useCallback } from "react";
import Componentify from "react-componentify";
import styled from "styled-components";

import Modal, { OvalModalWrap, CloseModalIcon } from "../Modal";
import Button from "../ButtonDefault";
import * as Style from "./styledComponents";
import InputText from "../InputText";
import FileIcon from "../FileIcon";

import apiMethod from "../../api/apiMethod";
import { errorKeys } from "../../lib/errors";
import { __ } from "../../lib/translate";
import { getIconType } from "../../lib/utils";
import { boldConverter } from "../../lib/componentifyConverters";

const GenerateSharedLinkModal = ({
  selectedData = {},
  params = {}
}: {
  selectedData: any,
  params: object
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [sharedLinkName, setSharedLinkName] = useState("");

  const inputTextRef = useRef(null);

  useEffect(() => {
    var inputName = inputTextRef.current;

    inputName.focus();
  }, []); //[]

  const handleClose = () => {
    setIsOpen(false);
  };

  const onSharedLinkNameChange = ({ target }) => {
    setSharedLinkName(target.value);
  };

  const onEnter = (e: any) => {
    if (e.keyCode === 13) {
      generateSharedLink();
    }
  };

  const generateSharedLink = () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    if (sharedLinkName.trim().length < 1) {
      HFN.message(__("Link name is required for creating a download link."), "error");
      setIsLoading(false);
      return;
    }

    params.name = sharedLinkName;

    apiMethod(
      "gettreepublink",
      params,
      ret => {
        console.log(ret);

        HFN.message(__("Download link is generated."));
        HFN.cache.expire(HFN.cache.cacheid("publinks", "all"));
        HFN.cache.expireMatch("api-listpublinks");

        HFN.getPublink(ret, function(link) {
          console.log("link after create->get ", link);

          setIsOpen(false);
          HFN.sharePublink(link);
        });
      },
      {
        type: "post",
        errorCallback: ({ result, error }) => {
          setIsLoading(false);

          if (errorKeys[result]) {
            HFN.message(__(errorKeys[result]), "error");
          } else {
            handleClose();
            HFN.message(__("something_went_wrong_refresh_and_try_again"), "error");
            throw new Error(error);
          }
        }
      }
    );
  };

  const renderRow = item => {
    const iconName = getIconType(item);

    return (
      <Row key={item.id}>
        <FileIcon item={iconName} type={HFN.ICONS.LIST_SMALL} metadata={item} />
        <Style.NameEllipsis>{item.name}</Style.NameEllipsis>
      </Row>
    );
  };

  const text = __("Give a name to your link. You can later locate it in <b>Shares</b>");
  return (
    <Modal animate onClose={handleClose} opened={isOpen} style={{ overflowY: "auto" }}>
      <OvalModalWrap>
        <CloseModalIcon onClick={handleClose} />
        <Style.Container>
          <Style.Header>{__("Generate Download Link")}</Style.Header>
          <Style.Label>{__("Link Content")}</Style.Label>
          <BoxLinks>{selectedData.map(renderRow)}</BoxLinks>
          <Style.Label>{__("Name", "Name")}</Style.Label>
          <InputText
            innerRef={inputTextRef}
            name="shared-link-name"
            onChange={onSharedLinkNameChange}
            value={sharedLinkName}
            autoFocus
            onKeyDown={onEnter}
          />
          <InfoMsgLinkName>
            <Componentify text={text} converters={[boldConverter]} />
          </InfoMsgLinkName>
          <Style.Footer>
            <Button
              color="lightgray4"
              style={{
                marginRight: "5px"
              }}
              onClick={handleClose}
            >
              {__("Cancel")}
            </Button>
            <Button
              color="cyan"
              style={{
                marginLeft: "5px"
              }}
              onClick={generateSharedLink}
              loading={isLoading}
            >
              {__("Generate")}
            </Button>
          </Style.Footer>
        </Style.Container>
      </OvalModalWrap>
    </Modal>
  );
};

export default GenerateSharedLinkModal;

const BoxLinks = styled(Style.Box)`
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 10px;
  font-size: 15px;
  max-height: 193px;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  margin-bottom: 15px;

  /* width */
  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 3px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #eaeaea;
    border-radius: 3px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #aaa;
    border-radius: 3px;
    transition: background 200ms ease-in-out;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
  min-height: 30px;
`;

const InfoMsgLinkName = styled.div`
  font-weight: normal;
  font-size: 13px;
  text-align: left;
  color: #888;
  padding-top: 7px;
`;
