import diffs from './diffs';
import $ from 'jquery';
import eventHandlers from './diffHandlers';

let token;
let stopListen = false;
let jqEvent = {};

function init(authtoken) {
  if (stopListen) {
    return;
  }

  token = authtoken;

  stopListen = diffs.listen(authtoken, handleEvents);
}

function reset() {
  console.log("stopListen", stopListen);

  if (stopListen) {
    stopListen();
    stopListen = false;
  }
}

function listen(cb) {
  return diffs.listen(token, cb);
}

export default { init, reset, jqEvent, listen, eventHandlers };

///

function handleEvents(events) {
  var n = 0;
  var per = 50;
  var parts = [];
  var t = 0;
  var rounds = Math.ceil(events.length / per);
  var diffHas = function (diffs, event) { for(var n = 0; n < diffs.length; ++n) { if (diffs[n].event == event) return true; } return false; };
  var hasUserinfo = diffHas(events, 'modifyuserinfo');
  var evcopy = events.slice(0);

  for ( ; t < rounds; ++t) {
    parts.push(evcopy.splice(0, per));
  }

  console.log(parts);

  for ( ; n < parts.length; ++n) {
    (function (n) {
      setTimeout(function () {
        for (var k = 0; k < parts[n].length; ++k) {
          handleEvent( parts[n][k] );

          //if (n == parts.length - 1 && k == parts[n].length - 1)
          //	subscriptions.setState('diff', 'ready');
        }
      }, per * 2 * n);

    })(n);
  }

  /*
  @TODO: this should me reimplemented elsewhere. Everybody can listen to diff events now.
  // after diff
  var p = $.bbq.getState('page');
  if (p == 'history')
    $.bbq.pushState({ refr: uniqueNum.get() });
  else if (p == 'settings' && hasUserinfo && 0) {
    $.bbq.pushState({ refr: uniqueNum.get() });
  }
  */
}

function handleEvent(event) {
  console.log("Incoming event: ", event);

  if (event.event in eventHandlers) {
    eventHandlers[event.event](event);
  } else {
    console.log("Unhandled event: ", event);
  }

  $(jqEvent).trigger('diffEvent.' + event.event, event);

  /*

  @TODO: Reimplement this

  var n;
  for (n in this.subscribers) {
    if (this.subscribers[n].events.indexOf(event.event) != -1) {
      this.subscribers[n].callback(event.event);
    }
  }
  */
}
