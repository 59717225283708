import React from "react";
import styled from "styled-components";
import { hiDPI } from "polished";

import Button from "./ButtonDefault";

const Base = styled.div`
	width: 22px;
	height: 22px;
	position: absolute;
	top: 50%;
	left: 12px;
	margin: -11px 0;
	z-index: 1;
	display: inline;
	background-size: 22px 22px;
	background-repeat: no-repeat;
	background-color: transparent;
	background-position: center center;
`;
const ButtonImageFb = styled(Base)`
	background-image: url(${require("../../root/img/invite-friend/fb.png")});
	${hiDPI(1.5)} {
		background-image: url(${require("../../root/img/invite-friend/fb@2x.png")});
	}
`;
const ButtonImageLink = styled(Base)`
	background-image: url(${require("../../root/img/invite-friend/link.png")});
	${hiDPI(1.5)} {
		background-image: url(${require("../../root/img/invite-friend/link@2x.png")});
	}
`;
const ButtonImageTwitter = styled(Base)`
	background-image: url(${require("../../root/img/invite-friend/tw.png")});
	${hiDPI(1.5)} {
		background-image: url(${require("../../root/img/invite-friend/tw@2x.png")});
	}
`;

const images = {
	link: <ButtonImageLink />,
	fb: <ButtonImageFb />,
	twitter: <ButtonImageTwitter />
};

const SecondaryButton = props => {
	return (
		<Wrapper>
			<ButtonModified {...props}>
				{images[props.type]}
				{props.children}
			</ButtonModified>
		</Wrapper>
	);
};

export default SecondaryButton;

const Wrapper = styled.div`
	position: relative;
	display: inline-block;
`;

const ButtonModified = styled(Button)`
	background-color: #fff;
	border-radius: 20px;
	min-width: 172px;
	color: #040404;
	border: 1px solid #f0f0f0;
	font-size: 11px;
	display: inline-block;
	padding-left: 40px;
	text-align: left;
`;
