import React from "react";
import styled, { keyframes } from "styled-components";
import { hiDPI } from "polished";

export const Error = styled.div`
  width: 100%;
  box-shadow: inset 3px 0 0 0 rgba(255, 115, 115, 0.1), inset -3px 0px 0 0 rgba(255, 115, 115, 0.1), inset 0px 3px 0 0 rgba(255, 115, 115, 0.1), inset 0px -3px 0 0 rgba(255, 115, 115, 0.1);
  border: solid 1px #ff7373;
  background-color: #fff4f4;
  background-image: url(${require("../../../root/img/attention.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/attention@2x.png")});
  }
  background-repeat: no-repeat;
  background-size: 24px 22px;
  background-position: 20px center;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: center;
  color: #ff2626;
  margin-bottom: 18px;
  box-sizing: border-box;
  padding: 20px 20px 20px 64px;
`;

const slideRight = keyframes`
  0% {
    opacity: 0;
    right: 2px;
  }

  100% {
    opacity: 1;
    right: 9px;
  }
`;

const CardIcon = styled.div`
  z-index: 2;
  width: 28px;
  height: 22px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  position: absolute;
  top: 36px;
  right: 9px;
  animation-name: ${slideRight};
  animation-duration: 500ms;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  animation-fill-mode: forwards;

  &.billing-icon {
    position: static;
    top: 0;
    right: 0;
    margin-right: 10px;
  }
`;

const VisaCardIcon = styled(CardIcon)`
  background-image: url(${require("../../../root/img/plans/input_cards/visa.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/plans/input_cards/visa@2x.png")});
  }
`;

const MasterCardIcon = styled(CardIcon)`
  background-image: url(${require("../../../root/img/plans/input_cards/mastercard.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/plans/input_cards/mastercard@2x.png")});
  }
`;

const AmexCardIcon = styled(CardIcon)`
  background-image: url(${require("../../../root/img/plans/input_cards/amex.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/plans/input_cards/amex@2x.png")});
  }
`;

const DinersCardIcon = styled(CardIcon)`
  background-image: url(${require("../../../root/img/plans/input_cards/diners.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/plans/input_cards/diners@2x.png")});
  }
`;

const DiscoverCardIcon = styled(CardIcon)`
  background-image: url(${require("../../../root/img/plans/input_cards/discover.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/plans/input_cards/discover@2x.png")});
  }
`;

const JCBCardIcon = styled(CardIcon)`
  background-image: url(${require("../../../root/img/plans/input_cards/jcb.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/plans/input_cards/jcb@2x.png")});
  }
`;

const DefaultCardIcon = styled(CardIcon)`
  background-image: url(${require("../../../root/img/plans/input_cards/no_card.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/plans/input_cards/no_card@2x.png")});
  }
`;

export const cardIcons = {
  visa: <VisaCardIcon />,
  master: <MasterCardIcon />,
  mastercard: <MasterCardIcon />,
  amex: <AmexCardIcon />,
  americanexpress: <AmexCardIcon />,
  diners: <DinersCardIcon />,
  dinersclub: <DinersCardIcon />,
  discover: <DiscoverCardIcon />,
  jcb: <JCBCardIcon />,
  default: <DefaultCardIcon />
};

export const cardIconsComponents = {
  visa: VisaCardIcon,
  master: MasterCardIcon,
  mastercard: MasterCardIcon,
  amex: AmexCardIcon,
  americanexpress: AmexCardIcon,
  diners: DinersCardIcon,
  dinersclub: DinersCardIcon,
  discover: DiscoverCardIcon,
  jcb: JCBCardIcon,
  default: DefaultCardIcon
};