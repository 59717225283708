// @flow

import React, { PureComponent } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import styled, { css } from "styled-components";
import { hiDPI } from "polished";

import apiMethod from "../api/apiMethod";
import { __ } from "../lib/translate";

import Dropdown, { DIR_RIGHT } from "./Dropdown";

type Extra = { id: number };

type DropdownItem = {
  titile: string,
  href: string,
  extra: Extra
};

type Props = {
  shouldSerchInDocs: boolean,
  selectedItemId: number,
  onItemSelect: number => void
};

class SearchFilterDropdown extends PureComponent<Props, {}> {
  static defaultProps = {
    shouldSerchInDocs: false,
    selectedItemId: 0,
    onItemSelect: () => {}
  };

  constructor(props: Props) {
    super(props);
    (this: any).onItemClick = (this: any).onItemClick.bind(this);
    (this: any).prepareObject = (this: any).prepareObject.bind(this);
    (this: any).prepareCell = (this: any).prepareCell.bind(this);
  }

  prepareObject(obj: any, level: number) {
    if (level === 0) {
      obj
        .find("ul")
        .prepend(
          '<div style="color:#bbb;font-weight:bold;font-size:11px;padding-right:25px;padding-left:15px;line-height:32px;cursor=default">' +
            __("search_through", "Filters") +
            "</div>"
        );
    }

    return obj;
  }

  prepareCell(cell: any, item: DropdownItem) {
    const { selectedItemId, shouldSerchInDocs } = this.props;
    const {
      extra: { id }
    } = item;

    cell
      .addClass("filter-item")
      .on("click")
      .removeClass("not-available")
      .prepend(HFN.createIcon(id, HFN.ICONS.FILTER_DROPDOWN, "filter-icon"));

    if (selectedItemId === id) {
      cell.append("<div class='check-icon'></div>");
    } else if (shouldSerchInDocs) {
      cell.off("click").addClass("not-available");
    }

    return cell;
  }

  getDropDownList() {
    return [
      {
        title: __("All Files"),
        href: false,
        extra: { id: HFN.CATEGORY.UNKNOWN }
      },
      {
        title: __("Images"),
        href: false,
        extra: { id: HFN.CATEGORY.IMAGE }
      },
      {
        title: __("Audio"),
        href: false,
        extra: { id: HFN.CATEGORY.AUDIO }
      },
      {
        title: __("Video"),
        href: false,
        extra: { id: HFN.CATEGORY.VIDEO }
      },
      {
        title: __("Documents"),
        href: false,
        extra: { id: HFN.CATEGORY.DOCUMENT }
      },
      {
        title: __("Archives"),
        href: false,
        extra: { id: HFN.CATEGORY.ARCHIVE }
      }
    ];
  }

  onItemClick({ extra: { id } }: DropdownItem) {
    const { onItemSelect } = this.props;

    onItemSelect(id);
  }

  render() {
    const { selectedItemId } = this.props;
    const isSelected = selectedItemId !== HFN.CATEGORY.UNKNOWN;

    return (
      <Dropdown
        direction={DIR_RIGHT}
        childDirection={DIR_RIGHT}
        list={this.getDropDownList()}
        eventTrigger="mouseenter"
        eventClose="mouseout"
        onSelect={this.onItemClick}
        prepareHolder={this.prepareObject}
        prepareCell={this.prepareCell}
      >
        <FilterIcon isSelected={isSelected} />
      </Dropdown>
    );
  }
}

export default SearchFilterDropdown;

const FilterIcon = styled.div`
  width: 18px;
  height: 12px;
  cursor: pointer;

  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url(${require("../../root/img/full-text-search/filter.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../root/img/full-text-search/filter@2x.png")});
  }

  ${props =>
    props.isSelected
      ? css`
          background-image: url(${require("../../root/img/full-text-search/filter-active.png")});
          ${hiDPI(1.5)} {
            background-image: url(${require("../../root/img/full-text-search/filter-active@2x.png")});
          }
        `
      : ""}
`;
