// @flow
import React, { useState } from "react";
import styled from "styled-components";

import { useSelector } from "react-redux";
import classnames from "classnames";
import apiMethod from "../../api/apiMethod";
import { __ } from "../../lib/translate";

import Button from "../ButtonDefault";
import { Title, Row, Col } from "../tables/dark";
import Alert, { AlertIcon } from "../Alert";
import PasswordStrength from "../PasswordStrength";
import { MIN_CRYPTO_PASS_STRENGTH } from "@pcloud/web-utilities/dist/config/constants";

type Props = {
  onPassChanged: () => void,
};

const fieldsDefaultValue = { code: "", password: "", repeatPassword: "", hint: "" };

const SetNewPass = ({ onPassChanged }: Props) => {
  const token = useSelector(({ user }) => user.token);
  const [fields, setFields] = useState(fieldsDefaultValue);
  const [errorMessage, setErrorMessage] = useState();
  const [loading, setLoading] = useState(false);
  const [canResend, setCanResend] = useState(true);

  const onFieldChange = function(field, value) {
    const newFields = { ...fields };
    newFields[field] = value;
    setFields(newFields);
  };

  const onSetNewPassword = e => {
    e.preventDefault();
    const { code, password, repeatPassword, hint } = fields;

    if (loading || !password.length) {
      return;
    }

    if (code.length != 6 || !parseInt(code)) {
      setErrorMessage(__("invalid_verification_code"));
      return;
    }

    const strength = pCloudCrypto.passwordStrength(password);

    if (password.length < 8 || strength < MIN_CRYPTO_PASS_STRENGTH) {
      setErrorMessage(__("Password is too weak!"));
      return;
    }

    if (password != repeatPassword) {
      setErrorMessage(__("both passwords must be exact match."));
      return;
    }

    const hintContainsPass = hint.trim().indexOf(password.trim()) != -1;
    if (hintContainsPass) {
      setErrorMessage(__("Your Hint must not contain the Passphrase."));
      return;
    }

    setErrorMessage(null);
    setLoading(true);

    pCrypt.encryptPrivateKeyWithNewPass(password, 0, ({ privatekey, signature }) => {
      apiMethod(
        "crypto_changeuserprivate",
        { auth: token, code: code, hint: hint, privatekey: privatekey, signature: signature },
        res => {
          if (res.result == 0) {
            HFN.message(__("crypto_pass_changed"));
            onPassChanged();
          }
          setLoading(false);
        },
        {
          type: "post",
          errorCallback: error => {
            console.log(error);
            if (error.result == 2012) {
              setErrorMessage(__("invalid_verification_code"));
            } else {
              setErrorMessage("Saving key failed");
            }
            setLoading(false);
          }
        }
      );
    });
  };

  const resendCode = () => {
    if (!canResend) {
      return;
    }
    setCanResend(false);
    setTimeout(() => setCanResend(true), 15000);
    apiMethod(
      "crypto_sendchangeuserprivate",
      { auth: token },
      ({ result }) => {
        if (result == 0) {
          HFN.message("Code has been sent");
        }
      },
      { errorCallback: e => HFN.message(e, "error") }
    );
  };

  return (
    <Form className="clearfix" onSubmit={onSetNewPassword}>
      <input style={{ opacity: 0, position: "absolute" }} />
      <input type="password" style={{ opacity: 0, position: "absolute" }} />

      <Title key="title">{__("heading_change_crypto_pass")}</Title>

      <Alert theme="warning" centerText style={{ marginBottom: "10px" }}>
        {__("crypto_change_pass_verification_code_info")}
      </Alert>

      {errorMessage ? (
        <Alert theme="error" centerText hasIcon multiline style={{ marginBottom: "10px", marginTop: "10px" }}>
          {errorMessage}
        </Alert>
      ) : null}

      <Row className="first-row setbl">
        <Col className="text-wrap" style={{ width: "345px" }}>
          {__("verification_code_label")}
          <ResendWrap>
            (
            <span
              className={classnames("lnk", { disabled: !canResend })}
              role="button"
              tabIndex="0"
              onKeyDown={e => e.keyCode == 13 && resendCode()}
              onClick={resendCode}
            >
              {__("resend_code_action")}
            </span>
            )
          </ResendWrap>
        </Col>
        <Col className="float" style={{ width: "342px" }}>
          <input
            type="text"
            className="error"
            name="code"
            onChange={e => {
              onFieldChange("code", e.target.value);
            }}
            value={fields.code}
          />
        </Col>
      </Row>
      <Row className="setbl custom-row">
        <Col className="text-wrap" style={{ width: "345px" }}>
          {__("new_crypto_pass_label")}
        </Col>
        <Col className="float" style={{ width: "342px" }}>
          <input
            type="password"
            name="cryptpass"
            onChange={e => onFieldChange("password", e.target.value)}
            value={fields.password}
          />
          <PassStrengWrap>
            <PasswordStrength password={fields.password} />
          </PassStrengWrap>
        </Col>
      </Row>
      <Row className="setbl no-border">
        <Col className="text-wrap" style={{ width: "345px" }}>
          {__("Repeat Crypto Pass")}
        </Col>
        <Col className="float" style={{ width: "342px" }}>
          <input
            type="password"
            name="cryptopassrepeat"
            onChange={e => onFieldChange("repeatPassword", e.target.value)}
            value={fields.repeatPassword}
          />
        </Col>
      </Row>
      <Row className="setbl border-bottom">
        <Col className="text-wrap" style={{ width: "345px" }}>
          {__("Hint")} ({__("Optional")})
        </Col>
        <Col className="float" style={{ width: "342px" }}>
          <input
            type="text"
            name="hint"
            onChange={e => onFieldChange("hint", e.target.value)}
            value={fields.hint}
          />
        </Col>
      </Row>
      <ButtonWrap>
        <Button
          size="big"
          borderRadius="3"
          color="green"
          onClick={onSetNewPassword}
          loading={loading}
          disabled={!fields.password.length}
        >
          {__("button_change_crypto_pass")}
        </Button>
      </ButtonWrap>
      <input type="submit" style={{display: "none"}} />
    </Form>
  );
};

export default SetNewPass;

const Form = styled.form``;

const ButtonWrap = styled.div`
  float: right;
  width: 240px;
  margin-top: 15px;
  margin-right: 11px;
`;
const PassStrengWrap = styled.div`
  position: relative;
  top: 3px;
  left: 2px;
`;
const ResendWrap = styled.span`
  display: inline-flex;
  padding-left: 5px;
`;
