const countryToVATPrefix = {
	"AT": "AT",
	"BE": "BE",
	"BG": "BG",
	"HR": "HR",
	"CH": "CHE",
	"CY": "CY",
	"CZ": "CZ",
	"DK": "DK",
	"EE": "EE",
	"FI": "FI",
	"FR": "FR",
	"DE": "DE",
	"GR": "EL",
	"HU": "HU",
	"IE": "IE",
	"IT": "IT",
	"LV": "LV",
	"LT": "LT",
	"LU": "LU",
	"MT": "MT",
	"NL": "NL",
	"PL": "PL",
	"PT": "PT",
	"RO": "RO",
	"SK": "SK",
	"SI": "SI",
	"ES": "ES",
	"SE": "SE",
	"GB": "GB"
};

export default countryToVATPrefix;