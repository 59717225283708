// @flow

import React from "react";
import styled from "styled-components";

import { BUSINESS_QUOTA_STEP } from "@pcloud/web-utilities/dist/config/constants";
import AdvancedSlider from "../../../components/AdvancedSlider";

type Props = {
  users: number,
  startValue: number,
  minStartValue: number,
  maxValue: number,
  getStorageValue: () => void
};

const ManageMemberQuotaSlider = ({
  users = 1,
  startValue,
  minStartValue,
  maxValue,
  getStorageValue
}: Props) => {
  return (
    <StorageSlider>
      <AdvancedSlider
        users={users}
        minValue={0}
        startValue={startValue}
        minStartValue={minStartValue}
        maxValue={maxValue}
        step={BUSINESS_QUOTA_STEP}
        disabled={false}
        getSliderValue={getStorageValue}
      />
    </StorageSlider>
  );
};

export default ManageMemberQuotaSlider;

const StorageSlider = styled.div`
  display: table;
  width: 100%;
  height: 80px;
  padding: 10px;
  background-color: #fafafa;
  font-size: 15px;
  border-radius: 6px;
  border: solid 1px #eeeeee;
  margin-bottom: 10px;
  box-sizing: border-box;
`;
