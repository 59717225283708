import React, {useState, useEffect} from 'react';
import usePlayerContext from "../../../hooks/usePlayerContext";
import {
    SwipeBoxControlWrapper
} from "../../../userinterface/styles";
import {turnOffOverflow, turnOnOverflow} from "../../../helpers";

let swipeBoxTimeout;
const SwipeBoxControlContainer = (props) => {
    const {
        isMobile,
        playerVariant,
        onShowMiniPlayer,
        playerBoxRef,
        boxPlayerRef,
        playerBackgroundRef,
        onPlayerClose,
    } = usePlayerContext();

    const {
        isMinimize,
    } = playerVariant;

    const needSwipeControls = isMinimize && isMobile;

    useEffect(() => {
        const checkState = () => {
            if ((document.hidden || document.visibilityState !== 'visible') && needSwipeControls) {
                onTouchEnd();
            }
        };

        document.addEventListener("visibilitychange", checkState, false);

        if (needSwipeControls) {
            document.addEventListener('focus', onTouchEnd);
            document.addEventListener('blur', onTouchEnd);
            window.addEventListener('focus', onTouchEnd);
            window.addEventListener('blur', onTouchEnd);
        }

        return () => {
            document.removeEventListener('visibilitychange', checkState);
            if (needSwipeControls) {
                document.removeEventListener('focus', onTouchEnd);
                document.removeEventListener('blur', onTouchEnd);
                window.removeEventListener('focus', onTouchEnd);
                window.removeEventListener('blur', onTouchEnd);
            }
        }
    }, []);

    const [touch, setTouch] = useState({
        distanceX: 0,
        distanceY: 0,
        x: 0,
        y: 0
    });
    const [minimizedPlayerHeight, setminimizedPlayerHeight] = useState(0);

    const onTouchStart = (e) => {
        turnOffOverflow();
        boxPlayerRef.current.style.transition = 'none';
        playerBoxRef.current.style.transition = 'none';
        playerBackgroundRef.current.style.transition = 'none';
        setTouch({
            ...touch,
            x: e.targetTouches[0].clientX,
            y: e.targetTouches[0].clientY
        });
        if (!minimizedPlayerHeight) {
            setminimizedPlayerHeight(boxPlayerRef.current.offsetHeight);
        }
    }

    const onTouchMove = (e) => {
        let distanceY = Math.max(0, touch.y - e.targetTouches[0].clientY);
        let distanceX = touch.x - e.targetTouches[0].clientX;

        if (distanceY > 10) {
            distanceX = 0;
            boxPlayerRef.current.style.height = (minimizedPlayerHeight + distanceY) + 'px';
            playerBoxRef.current.style.height = (minimizedPlayerHeight + distanceY) + 'px';
            playerBackgroundRef.current.style.removeProperty('transition');
            playerBackgroundRef.current.style.removeProperty('left');
        } else {
            distanceY = 0;
            if (distanceX > 0) {
                playerBackgroundRef.current.style.left = distanceX * -1 + 'px';
            }
            if (distanceX < 0) {
                playerBackgroundRef.current.style.left = distanceX * -1 + 'px';
            }
        }

        setTouch({
            ...touch,
            distanceX,
            distanceY
        });

        if (swipeBoxTimeout) {
            clearTimeout(swipeBoxTimeout);
        }
        swipeBoxTimeout = setTimeout(() => {
            resetTouch();
        }, 1000);
    }

    const onTouchEnd = (e) => {
        if (touch.distanceY > 150) {
            onShowMiniPlayer();
            turnOnOverflow();
            boxPlayerRef.current.style.removeProperty('transition');
            boxPlayerRef.current.style.removeProperty('height');
            playerBoxRef.current.removeAttribute('style');
        } else if (touch.distanceX > 150) {
            playerBoxRef.current.removeAttribute('style');
            setTimeout(() => {
                playerBoxRef.current.style.left = '-100%';
                onPlayerClose();
            }, 50);
        } else if (touch.distanceX < -150) {
            playerBoxRef.current.removeAttribute('style');
            setTimeout(() => {
                playerBoxRef.current.style.left = '100%';
                onPlayerClose();
            }, 50);
        } else {
            turnOnOverflow();
            boxPlayerRef.current.style.removeProperty('transition');
            boxPlayerRef.current.style.removeProperty('height');
            playerBoxRef.current.removeAttribute('style');
            playerBackgroundRef.current.removeAttribute('style');
        }
        setTouch({
            distanceX: 0,
            distanceY: 0,
            x: 0,
            y: 0
        });

        if (swipeBoxTimeout) {
            clearTimeout(swipeBoxTimeout);
        }
    }

    const resetTouch = () => {
        turnOnOverflow();
        boxPlayerRef.current.style.removeProperty('transition');
        boxPlayerRef.current.style.removeProperty('height');
        playerBoxRef.current.removeAttribute('style');
        playerBackgroundRef.current.removeAttribute('style');

        setTouch({
            distanceX: 0,
            distanceY: 0,
            x: 0,
            y: 0
        });
    }

    return (
        <SwipeBoxControlWrapper
            onTouchStart={needSwipeControls ? onTouchStart : null}
            onTouchMove={needSwipeControls ? onTouchMove : null}
            onTouchEnd={needSwipeControls ? onTouchEnd : null}
        >
            {props.children}
        </SwipeBoxControlWrapper>
    );
}

export default SwipeBoxControlContainer;