import React from 'react';

const UploadContext = React.createContext({
  initializeUploads: () => { },
  uploadQueue: [],
  handleRetryAction: () => { },
  calcFilesDonePercentage: () => { },
  cancelUpload: () => { },
  closeUpload: () => { },
  failedItems: [],
  abortUploadAction: () => { },
  uploadQueueExisting: [],
  handleUploadOptionsModalClose: () => { },
  handleUploadOptionsModalYesClick: () => { },
  activeTab: 0,
  setActiveTab: () => { },
  filteredUploadQueue: [],

  isToggled: false,
  handleToggleState: () => { },

  isOpened: false,
  handleOpenState: () => { },

  isWarningOnUploadModalOpen: false,
  handleWarningOnUploadModalClose: () => { },
  handleWarningOnUploadModalYesClick: () => { },

  handleCancel: () => { },
  isUploadCancelModalOpen: false,
  handleUploadCancelModalClose: () => { },
  handleUploadCancelModalYesClick: () => { },

  openFailedFolderModal: () => { },
  failedFolderModalItemId: false,
  handleFailedFolderModalOpenClose: () => { },
  handleFailedFolderModalOpenYesClick: () => { },
  opts: {},
  isMobile: false,

  isLockCryptoModalOpen: false,
  handleLockCryptoModalClose: () => { },
  handleLockCryptoYesClick: () => { },
  handleClear: () => { },
  handleRetryAll: () => { },

  isTabDropdownOpened: false,
  setIsTabDropdownOpened: () => { },
  tabItemsLength: {},

  isUploadCloseWithRetryOpen: false,
  handleUploadCloseWithRetryClose: () => { },
  handleUploadCloseWithRetryYesClick: () => { },

  isUploadRemoteModalOpen: false,
  handleUploadRemoteModalClose: () => { },
  handleUploadRemoteModalYesClick: () => { },

  isUploadPasteModalOpen: false,
  handleUploadPasteModalClose: () => { },
  handleUploadPasteModalYesClick: () => { },
});

export default UploadContext;