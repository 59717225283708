// @flow

import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { List } from "immutable";

import Modal, { OvalModalWrap, CloseModalIcon } from "../Modal";
import Picker from "../Picker/Picker";
import PickerSelectedItems from "../Picker/PickerSelectedItems";
import * as Style from "./styledComponents";
import InputText from "../InputText";
import Button from "../../../web-shared/components/ButtonDefault";

import { __ } from "../../lib/translate";
import { errorKeys } from "../../lib/errors";
import apiMethod from "../../api/apiMethod";

type Props = {
  folder: any,
  fileList: Array<any>,
  opts: any
};

const ExtractArchiveModal = ({
  folder = { folderid: 0, name: "/", contents: null },
  fileList = [],
  opts = {}
}: Props) => {
  const [isOpen, setIsOpen] = useState(true);
  const [extractList, setExtractList] = useState(List(fileList));
  const [showProgress, setShowProgress] = useState(false);
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isReadyExtract, setIsReadyExtract] = useState(false);
  const [extractToFolderId, setExtractToFolderId] = useState(0);

  const handleClose = () => {
    setIsOpen(false);
  };

  const onPasswordChange = ({ target }) => {
    setPassword(target.value);
  };

  const initExtractProgress = (data, ret) => {
    setIsLoading(false);
    ret.output.forEach((element, index) => {
      const elementComponent = `<div class="${index === 0 ? 'first': ''} extractiong-item" key=${uniqueNum.get(index)}>${element}</div>`;
      $(".progress-list").append(elementComponent);
    });
    const progressExtract = (phash, hostname, lines) => {
      apiMethod(
        "extractarchiveprogress",
        { progresshash: ret.progresshash, lines: lines },
        res => {
          if (res.output.length) {
            res.output.forEach((element, index) => {
              const elementComponent = `<div class="extractiong-item" key=${uniqueNum.get(index)}>${element}</div>`;
              $(".progress-list").append(elementComponent);
            });
          }

          if (!res.finished) {
            setTimeout(progressExtract.bind(null, phash, hostname, res.lines), 150);
          } else {
            setIsReadyExtract(true);
          }
        },
        { apiServer: ret.hostname }
      );
    };

    progressExtract(ret.progresshash, ret.hostname, ret.lines);
  };

  const startExtract = toFolderId => {
    var params = {
      fileid: fileList[0].fileid,
      tofolderid: toFolderId,
      overwrite: "overwrite"
    };

    if (password.length !== 0) {
      params.password = password;
    }

    if (isLoading) {
      return;
    }

    setIsLoading(true);
    setShowProgress(true);

    HFN.apiMethod(
      "extractarchive",
      params,
      ret => {
        if (ret.finished) {
          HFN.message(__("Extract finished."));
          handleClose();
        } else {
          initExtractProgress(fileList[0], ret, params);
        }
      },
      {
        errorCallback: ({ result, error }) => {
          setIsLoading(false);
          if ([2346, 2023].indexOf(result) !== -1) {
            // You cannot add files/folders to this backup folder.
            //You are trying to place shared folder into another shared folder.
            HFN.message(__(errorKeys[result]), "error");
          } else if (errorKeys[result]) {
            handleClose();
            HFN.message(errorKeys[result], "error");
          } else {
            handleClose();
            HFN.message(__("something_went_wrong_refresh_and_try_again"), "error");
            throw new Error(error);
          }
          setIsLoading(false);
          setShowProgress(false);
        },
        type: "POST",
        showErrorMessage: false
      }
    );
  };

  const onPick = ({ data, isChecked }) => {
    const { itemId } = data;
    if (itemId == -1) {
      HFN.message(__("You need to pick folder in order to extract archive."), "error");
      return;
    }
    setExtractToFolderId(itemId);

    if (isChecked) {
      HFN.getOrCreateFolder(data.name.replace("." + fileext(data.name), ""), itemId, fid => {
        startExtract(fid);
      });
    } else {
      startExtract(itemId);
    }
  };

  const onOpenFolderClick = () => {
    handleClose();
    HFN.openFolder(extractToFolderId);
  };

  const onRemoveRow = index => {
    if (extractList.size === 1) {
      handleClose();
    } else {
      setExtractList(extractList.delete(index));
    }
  };

  const renderProgress = () => {
    const message = __(
      "Extracting '%name%' may take some time. You can close this window. The file extraction will continue in background."
    ).replace("%name%", fileList[0].name);

    return (
      <React.Fragment>
        <LoaderWrapper hide={!isLoading}>
          <Style.Loader />
        </LoaderWrapper>
        <HeaderCopy>
          {isReadyExtract ? __("Extraction Completed") : __("Extracting ...")}
        </HeaderCopy>
        <ProgressList className="progress-list" />
        <ProgressText>{message}</ProgressText>
        <Style.Footer>
          <Button
            color="lightgray4"
            style={{
              marginRight: "5px"
            }}
            onClick={handleClose}
          >
            {__("Close")}
          </Button>
          <Button
            color="cyan"
            style={{
              marginLeft: "5px"
            }}
            disabledColor="#dddddd"
            onClick={onOpenFolderClick}
          >
            {isReadyExtract ? __("See extracted files") : __("Open Folder")}
          </Button>
        </Style.Footer>
      </React.Fragment>
    );
  };

  const renderPicker = () => {
    return (
      <React.Fragment>
        <HeaderCopy>{__("Extract Archive")}</HeaderCopy>
        <LabelCopy>{__("File")}</LabelCopy>
        <PickerSelectedItems list={extractList} shouldRemove={false} onRemoveItem={onRemoveRow} />
        <LabelCopy>{__("Archive Password")}<Required>{__("(if required)")}</Required></LabelCopy>
        <InputText
          type="password"
          name="password"
          autoComplete="off"
          autoFill="off"
          placeholder={__("Password")}
          onChange={onPasswordChange}
          value={password}
          style={{ marginBottom: "17px" }}
        />
        <LabelCopy>{__("Extract to") + ":"}</LabelCopy>
        <Picker
          folder={folder}
          opts={Object.assign(opts, {
            buttonText: "Extract",
            shouldRenderCheckbox: true,
            checkboxName: "Extract in new folder"
          })}
          onPick={onPick}
          onCancel={handleClose}
        />
      </React.Fragment>
    );
  };

  return (
    <Modal animate onClose={handleClose} opened={isOpen}>
      <OvalModalWrap>
        <CloseModalIcon onClick={handleClose} />
        <Style.Container>{showProgress ? renderProgress() : renderPicker()}</Style.Container>
      </OvalModalWrap>
    </Modal>
  );
};

export default ExtractArchiveModal;

const LabelCopy = styled(Style.Label)`
  height: 18px;
  margin-bottom: 5px;
`;

const LoaderWrapper = styled.div`
  position: absolute;
  display: ${props => (props.hide ? "none" : "inline-block")};
  width: 100%;
  height: 85%;
  top: 28px;
  left: 0;
  z-index: 100;
  background-color: #fff;
`;

const HeaderCopy = styled(Style.Header)`
  height: 26px;
  margin-bottom: 16px;
`;

const ProgressText = styled.div`
  font-size: 13px;
  font-weight: normal;
  color: #8e8e95;
  margin-bottom: 25px;
`;

const ProgressList = styled.div`
  font-size: 15px;
  font-weight: normal;
  margin-bottom: 25px;
  padding: 20px 10px;
  height: 152px;
  border-radius: 6px;
  border: solid 1px #eeeeee;
  background-color: #fafafa;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
  text-align: left;

    /* width */
    &::-webkit-scrollbar {
    width: 5px;
    border-radius: 3px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #eaeaea;
    border-radius: 3px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #aaa;
    border-radius: 3px;
    transition: background 200ms ease-in-out;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .extractiong-item {
    font-size: 15px;
    font-weight: normal;
    margin-left: 0;
  }

  .first {
    font-weight: bold;
    margin-left: 10px;
  }
`;

const Required = styled.span`
  font-weight: normal;
  margin-left: 5px;
`;
