import React from 'react';
import SongThumb from "../../../userinterface/SongThumb";
import usePlayerContext from "../../../hooks/usePlayerContext";
import {
    BoxSongWrapper,
    BoxTopHeaderWrapper,
    BoxWaveHolder,
    DivWrapper,
    FlexWrapper
} from "../../../userinterface/styles";
import WaveAnimation from "../../../userinterface/WaveAnimation";
import ProgressBar from "./ProgressBar";
import OpenAppButton from "../../../userinterface/OpenAppButton";
import {getBoxThumbSize} from "../../../helpers";
import BoxSongDetails from "./BoxSongDetails";
import SongControls from "./SongControls";
import PodcastControls from "./PodcastControls";

const DefaultLayout = () => {
    const {
        isPublic,
        isMobile,
        playerVariant,
        additionalClass,
        currentSong,
        podcastMode
    } = usePlayerContext();

    const {
        showPlayer,
        isFullPage,
        isMiniPlayer,
        isMinimize,
    } = playerVariant;

    const thumbSize = getBoxThumbSize(playerVariant, isMobile);
    const showTitleOnTop = isFullPage;
    const showAnimation = isMiniPlayer && (!isMinimize || isFullPage);
    const showAppButton = isPublic && isMobile && !playerVariant.isMinimize;

    return (
        <>
            <SongThumb
                songFileId={currentSong?.fileid}
                width={thumbSize}
                height={thumbSize}
                borderRadius={isFullPage ? 16 : null}
                isActive={isMinimize}
            />
            <BoxSongWrapper
                className={additionalClass}
            >
                <BoxTopHeaderWrapper
                    className={additionalClass + ' animate'}
                >
                    {!showTitleOnTop && <BoxSongDetails/>}
                </BoxTopHeaderWrapper>
                {showAnimation && (
                    <BoxWaveHolder
                        className={additionalClass + ' animate'}
                    >
                        <WaveAnimation/>
                    </BoxWaveHolder>
                )}
                <DivWrapper className={additionalClass + ' bottom-controls fade-transition'}
                            $width={'auto'} $show={showPlayer}>
                    <ProgressBar
                        showMiddleTimes={!isMiniPlayer && !isMinimize}
                        showBottomTimes={isMiniPlayer && !isMinimize}
                        showPointer={(!isMinimize && (!isFullPage || isMobile))}
                    />
                    {podcastMode && <PodcastControls />}
                    {!podcastMode && <SongControls/>}
                    {showAppButton && isMiniPlayer && (
                        <FlexWrapper style={{marginTop: 32}} $justifyContent={'center'}>
                            <OpenAppButton/>
                        </FlexWrapper>
                    )}
                </DivWrapper>
            </BoxSongWrapper>
        </>
    );
}

export default DefaultLayout;