// @flow

import React from "react";
import styled from "styled-components";
import { hiDPI } from "polished";

import { DEVICE_OS } from "@pcloud/web-utilities/dist/config/constants";

type Props = {
  deviceOs: ?(string | number),
  step: number,
  imgSize: {}
};

const UnlockSpaceStepImage = ({ step, deviceOs, ...rest }: Props) => {
  return (
    <Wrap>
      <Base {...rest}>
        {(deviceOs == DEVICE_OS.Android ? steps[step] : stepsIos[step])}
      </Base>
    </Wrap>
  );
};

export default UnlockSpaceStepImage;

const Base = styled.div`
  width: ${props => props.imgSize.width}px;
  height: ${props => props.imgSize.height}px;
  background-size: ${props => props.imgSize.width}px ${props => props.imgSize.height}px;
  background-position: center center;
  background-repeat: no-repeat;
`;

const ImgDefault = styled.div`
  width: inherit;
  height: inherit;
  background-size: inherit;
`;

const ImageOne = styled(ImgDefault)`
  background-image: url(${require("../../../root/img/unlockspace/verify.png")});
  ${hiDPI()} {
    background-image: url(${require("../../../root/img/unlockspace/verify@2x.png")});
  }
`;

const ImageTwo = styled(ImgDefault)`
  background-image: url(${require("../../../root/img/unlockspace/upload.png")});
  ${hiDPI()} {
    background-image: url(${require("../../../root/img/unlockspace/upload@2x.png")});
  }
`;

const ImageThree = styled(ImgDefault)`
  background-image: url(${require("../../../root/img/unlockspace/download-drive.png")});
  ${hiDPI()} {
    background-image: url(${require("../../../root/img/unlockspace/download-drive@2x.png")});
  }
`;

const ImageFour = styled(ImgDefault)`
  background-image: url(${require("../../../root/img/unlockspace/download-app.png")});
  ${hiDPI()} {
    background-image: url(${require("../../../root/img/unlockspace/download-app@2x.png")});
  }
`;

const ImageFive = styled(ImgDefault)`
  background-image: url(${require("../../../root/img/unlockspace/auto-upload.png")});
  ${hiDPI()} {
    background-image: url(${require("../../../root/img/unlockspace/auto-upload@2x.png")});
  }
`;

const ImageSix = styled(ImgDefault)`
  background-image: url(${require("../../../root/img/unlockspace/invite-friends.png")});
  ${hiDPI()} {
    background-image: url(${require("../../../root/img/unlockspace/invite-friends@2x.png")});
  }
`;

const ImageOneIos = styled(ImgDefault)`
  background-image: url(${require("../../../root/img/unlockspace/verify-ios.png")});
  ${hiDPI()} {
    background-image: url(${require("../../../root/img/unlockspace/verify-ios@2x.png")});
  }
`;

const ImageTwoIos = styled(ImgDefault)`
  background-image: url(${require("../../../root/img/unlockspace/upload-ios.png")});
  ${hiDPI()} {
    background-image: url(${require("../../../root/img/unlockspace/upload-ios@2x.png")});
  }
`;

const ImageThreeIos = styled(ImgDefault)`
  background-image: url(${require("../../../root/img/unlockspace/download-drive-ios.png")});
  ${hiDPI()} {
    background-image: url(${require("../../../root/img/unlockspace/download-drive-ios@2x.png")});
  }
`;

const ImageFourIos = styled(ImgDefault)`
  background-image: url(${require("../../../root/img/unlockspace/download-app-ios.png")});
  ${hiDPI()} {
    background-image: url(${require("../../../root/img/unlockspace/download-app-ios@2x.png")});
  }
`;

const ImageFiveIos = styled(ImgDefault)`
  background-image: url(${require("../../../root/img/unlockspace/auto-upload-ios.png")});
  ${hiDPI()} {
    background-image: url(${require("../../../root/img/unlockspace/auto-upload-ios@2x.png")});
  }
`;

const ImageSixIos = styled(ImgDefault)`
  background-image: url(${require("../../../root/img/unlockspace/invite-friends-ios.png")});
  ${hiDPI()} {
    background-image: url(${require("../../../root/img/unlockspace/invite-friends-ios@2x.png")});
  }
`;

const Wrap = styled.div`
  width: 30px;
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 1;

  & > div {
    margin: 0 auto;
  }
`;

const steps = [
  <ImageOne />,
  <ImageTwo />,
  <ImageThree />,
  <ImageFour />,
  <ImageFive />,
  <ImageSix />
];
const stepsIos = [
  <ImageOneIos />,
  <ImageTwoIos />,
  <ImageThreeIos />,
  <ImageFourIos />,
  <ImageFiveIos />,
  <ImageSixIos />
];
