// @flow

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import apiMethod from "../api/apiMethod";
import { __ } from "../lib/translate";
import { formatDt } from "../lib/utils";
import { myUrlLocation } from "@pcloud/web-utilities/dist/config";

import Button from "./ButtonDefault";
import Tooltip from "./Tooltip";
import { TDiv, TSpan } from "./intl";

var styles = {
  buttonModalMinWidth: {
    textDecoration: "underline",
    padding: "0 5px",
    color: "#000",
    background: "none",
    fontWeight: "normal"
  }
};

const showErrorAlert = (...args) => {
  HFN.message(...args);
};

const getSourceName = (provider: string, instrument: string): string => {
  const providerInstrumentToSource = {
    Braintree: { paypalaccount: "PayPal", creditcard: "Credit Card" },
    SafeCharge: { creditcard: "Credit Card", apm: "Local Payment" }
  };
  const providerToSource = {
    Stripe: "Credit Card"
  };

  if (providerInstrumentToSource[provider] && providerInstrumentToSource[provider][instrument]) {
    return providerInstrumentToSource[provider][instrument];
  } else if (providerToSource[provider]) {
    return providerToSource[provider];
  }

  return provider;
};

const BillingHistory = () => {
  const isBusiness = useSelector(({ user }) => user.userinfo.business);
  const userSettings = useSelector(({ user }) => user.userSettings || {});
  const { payments: billingHistory = [], billingInfo = [] } = userSettings;
  const [loading, setLoading] = useState(false);
  const [hasCompleteInfo, setHasCompleteInfo] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const billingKeysCompany = ["billingAddress", "country", "company"];
    const billingKeysPerson = ["billingAddress", "country", "names"];
    const hasCompleteInfo =
      billingKeysCompany.every(key => !!billingInfo[key]) ||
      billingKeysPerson.every(key => !!billingInfo[key]);

    setHasCompleteInfo(hasCompleteInfo);
  }, [billingInfo]);

  const issueInvoice = (payment: PaymentData) => {
    const { paymentId } = payment;

    if (loading) {
      return;
    }

    setLoading(true);

    apiMethod(
      "issueinvoice",
      {
        paymentid: paymentId
      },
      ({ invoicecode: invoiceCode }) => {
        const findPaymentById = paymentId => currentPayment =>
          currentPayment.paymentId === paymentId;
        // imp. syntax
        const invpayments = billingHistory.filter(findPaymentById(paymentId));
        const invpayment = invpayments[0];
        console.log("invpaymnet   >>> ", invpayment, invoiceCode, billingHistory);

        invpayment.invoiceCode = invoiceCode;
        dispatch({ type: "UPDATE_BILLING_INFO", billingInfo: [...billingHistory] });
        setLoading(false);
      },
      {
        errorCallback: ret => {
          showErrorAlert(ret.error, "error");
          setLoading(false);
        }
      }
    );
  };

  const issueReceipt = (payment: PaymentData) => {
    const { paymentId } = payment;

    if (loading) {
      return;
    }

    setLoading(true);

    apiMethod(
      "issuereceipt",
      {
        paymentid: paymentId
      },
      ({ receiptcode: receiptCode }) => {
        const findPaymentById = paymentId => currentPayment =>
          currentPayment.paymentId === paymentId;
        // imp. syntax
        const invpayments = billingHistory.filter(findPaymentById(paymentId));
        const invpayment = invpayments[0];
        console.log("invpaymnet   >>> ", invpayment, receiptCode, billingHistory);

        invpayment.receiptCode = receiptCode;
        dispatch({ type: "UPDATE_BILLING_INFO", billingInfo: [...billingHistory] });
        setLoading(false);
      },
      {
        errorCallback: ret => {
          showErrorAlert(ret.error, "error");
          setLoading(false);
        }
      }
    );
  };

  const renderGetInvoiceButton = payment => {
    if (!hasCompleteInfo) {
      return (
        <Tooltip
          title={__(
            "no_invoice_info_for_payment",
            "Please fill all missing fields to be able to generate an invoice."
          )}
        >
          <Button style={styles.buttonModalMinWidth} color="gray" size="medium" disabled inline>
            <TSpan id="ph_get_invoice">Get</TSpan>
          </Button>
        </Tooltip>
      );
    } else if (payment.invoiceCode) {
      return (
        <Button
          style={styles.buttonModalMinWidth}
          href={myUrlLocation("invoice/show?code=" + payment.invoiceCode)}
          target="_blank"
          size="medium"
          inline
        >
          <TSpan id="ph_show_invoice">Show</TSpan>
        </Button>
      );
    } else if (payment.canCreateInvoice) {
      return (
        <Button
          style={styles.buttonModalMinWidth}
          size="medium"
          inline
          onClick={() => issueInvoice(payment)}
        >
          <TSpan id="ph_get_invoice">Get</TSpan>
        </Button>
      );
    } else {
      return (
        <Tooltip
          title={__(
            "no_invoice_for_payment",
            "Unfortunately, you can no longer issue an invoice for this payment."
          )}
        >
          <Button style={styles.buttonModalMinWidth} color="gray" size="medium" disabled inline>
            <TSpan id="ph_get_invoice">Get</TSpan>
          </Button>
        </Tooltip>
      );
    }
  };

  const hasBillingHistory = billingHistory.length;

  //sort billing history by date
  hasBillingHistory &&
    billingHistory.sort(function(a, b) {
      return new Date(b.paymentDate).getTime() - new Date(a.paymentDate).getTime();
    });
  console.log("billingHistory", billingHistory);

  return (
    <div>
      {hasBillingHistory ? (
        <div>
          {!isBusiness ? <Title id="ph_billing_history">Payment History:</Title> : null}
          <Container isBusiness={isBusiness}>
            <Row className="header-history">
              <Col>
                <TSpan id="ph_date">Date</TSpan>
              </Col>
              <Col>
                <TSpan id="ph_type">Type</TSpan>
              </Col>
              <Col>
                <TSpan id="ph_price">Price</TSpan>
              </Col>
              <Col>
                <TSpan id="ph_getshow">Invoice</TSpan>
              </Col>
              <Col>
                <TSpan id="ph_receipt">Receipt</TSpan>
              </Col>
            </Row>
            {billingHistory.map(payment => {
              return (
                <Row className="billing-history" key={payment.paymentId}>
                  <Col>{formatDt(payment.paymentDate)}</Col>
                  <Col>{getSourceName(payment.billingProvider, payment.instrument)}</Col>
                  <Col>
                    {payment.amount} {payment.currency}
                  </Col>
                  <Col>{renderGetInvoiceButton(payment)}</Col>
                  <Col>
                    {console.log("inv", payment)}
                    {payment.receiptCode ? (
                      <Button
                        style={styles.buttonModalMinWidth}
                        href={myUrlLocation("receipt/show?code=" + payment.receiptCode)}
                        target="_blank"
                        size="medium"
                        inline
                      >
                        <TSpan id="ph_show_receipt">Show</TSpan>
                      </Button>
                    ) : (
                      <Button
                        style={styles.buttonModalMinWidth}
                        size="medium"
                        inline
                        onClick={() => issueReceipt(payment)}
                      >
                        <TSpan id="ph_get_receipt">Get</TSpan>
                      </Button>
                    )}
                  </Col>
                </Row>
              );
            })}
          </Container>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default BillingHistory;

const Title = styled(TDiv)`
  font-size: 15px;
  font-weight: bold;
  height: 31px;
  border-bottom: 2px solid #ddd;
  padding-top: 17px;
  padding-left: 2px;
  margin: 10px 0 0 0;
`;

const Container = styled.ul`
  margin: 0;
  padding: 0;
  background: ${props => (props.isBusiness ? "#fff" : "#fafafa")};
`;

const Row = styled.li`
  font-size: 12px;
  line-height: 33px;
  border-bottom: 1px solid #e9e9e9;
  list-style-type: none;

  &.header-history {
    font-weight: 700;
    padding: 0;
    background: #f4f4f4;
  }
  &.billing-history {
    padding: 10px 0;
  }
  &.billing-history div,
  &.header-history div {
    width: 20%;
  }

  &:last-child {
    border-bottom: none;
  }
`;

const Col = styled.div`
  width: 70%;
  display: inline-block;
  padding: 0 0 0 10px;
  font-size: 12px;
  line-height: 33px;
  box-sizing: border-box;

  &.row-heading {
    font-weight: 700;
    width: 30%;
  }
  &.row-heading:first-letter {
    text-transform: uppercase;
  }
`;

const Span = styled.span`
  display: block;
  overflow: hidden;
  position: relative;
  input,
  select {
    margin-bottom: 10px;
  }
  input[name="vat"] {
    margin: 10px 0 30px 0;
    width: auto;
    padding-left: 35px;
  }
  select {
    padding: 10px 5px;
  }
  textarea {
    min-height: 75px;
  }
`;
