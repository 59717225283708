// @flow

import React from "react";
import { useSelector } from "react-redux";
import reactStringReplace from "react-string-replace";
import styled from "styled-components";
import { hiDPI } from "polished";

import Tooltip from "../../components/Tooltip";
import * as Style from "./SharedStyledComponents";
import { __ } from "../../lib/translate";
import { detectIsMobile } from "../../lib/utils";
import { detectOS } from "../../../web-shared/utils";
import { URLSITE } from "@pcloud/web-utilities/dist/config";

const DesktopInfo = () => {
  const isBusiness = useSelector(({ user }) => user.userinfo.business);
  const isRetinaDisplay = window.devicePixelRatio > 1;
  const isMobileDevice = detectIsMobile();
  const os = detectOS(false, true);
  const pageTitle = os === "mac" ? __("desktop_backup_title_mac") : __("desktop_backup_title_pc");

  const imagesByOs = {
    mac: {
      header: isRetinaDisplay
        ? require("../../../root/img/backups/infopages/mac-header@2x.png")
        : require("../../../root/img/backups/infopages/mac-header.png"),
      step1: isRetinaDisplay
        ? require("../../../root/img/backups/infopages/step1@2x.png")
        : require("../../../root/img/backups/infopages/step1.png"),
      step2: isRetinaDisplay
        ? require("../../../root/img/backups/infopages/mac-step2@2x.png")
        : require("../../../root/img/backups/infopages/mac-step2.png")
    },
    windows: {
      header: isRetinaDisplay
        ? require("../../../root/img/backups/infopages/win-header@2x.png")
        : require("../../../root/img/backups/infopages/win-header.png"),
      step1: isRetinaDisplay
        ? require("../../../root/img/backups/infopages/step1@2x.png")
        : require("../../../root/img/backups/infopages/step1.png"),
      step2: isRetinaDisplay
        ? require("../../../root/img/backups/infopages/win-step2@2x.png")
        : require("../../../root/img/backups/infopages/win-step2.png")
    },
    linux: {
      header: isRetinaDisplay
        ? require("../../../root/img/backups/infopages/linux-header@2x.png")
        : require("../../../root/img/backups/infopages/linux-header.png"),
      step1: isRetinaDisplay
        ? require("../../../root/img/backups/infopages/step1@2x.png")
        : require("../../../root/img/backups/infopages/step1.png"),
      step2: isRetinaDisplay
        ? require("../../../root/img/backups/infopages/linux-step2@2x.png")
        : require("../../../root/img/backups/infopages/linux-step2.png")
    },
    default: {
      header: isRetinaDisplay
        ? require("../../../root/img/backups/infopages/default-header@2x.png")
        : require("../../../root/img/backups/infopages/default-header.png"),
      step1: "",
      step2: ""
    }
  };

  const currentOsImages = imagesByOs[os] || imagesByOs.default;

  return (
    <Style.Wrapper>
      <Style.Header>
        <Style.HeaderTitle>{pageTitle}</Style.HeaderTitle>
        <Style.HeaderSubtitle>
          {__("desktop_backup_subtitle", false, {
            period: isBusiness
              ? __("desktop_backup_period_halfyear")
              : __("desktop_backup_period_year")
          })}
        </Style.HeaderSubtitle>
      </Style.Header>

      <Style.BoxContainer>
        <Style.BoxItem alignDirection="right" className="image">
          <Style.BoxImage src={currentOsImages.header} width="471" />
        </Style.BoxItem>
        <Style.BoxItem alignDirection="left" className="text">
          <Style.List>
            <Style.ListItem>{__("desktop_backup_bullet1")}</Style.ListItem>
            <Style.ListItem>
              <span>
              {isBusiness
                ? __("desktop_backup_bullet2", "Restore old versions in %period%*", {
                    period: __("desktop_backup_period_halfyear")
                  })
                : reactStringReplace(
                    __("desktop_backup_bullet2", "Restore old versions in %period%*"),
                    /(%period%)/g,
                    () => (
                      <span>
                        {__("desktop_backup_period_year")}
                        <Tooltip title={__("desktop_backup_bullet2_tooltip")}>
                          <InfoIcon></InfoIcon>
                        </Tooltip>
                      </span>
                    )
                  )}
              </span>
            </Style.ListItem>
            <Style.ListItem>{__("desktop_backup_bullet3")}</Style.ListItem>
            <Style.ListItem>{__("desktop_backup_bullet4")}</Style.ListItem>
            <Style.ListItem>
              <span>
                {__("desktop_backup_bullet5")}
                <Tooltip title={__("desktop_backup_bullet5_tooltip")}>
                  <InfoIcon></InfoIcon>
                </Tooltip>
              </span>
            </Style.ListItem>
          </Style.List>
          {isMobileDevice ? null : (
            <Style.OvalButton target="_blank" href={URLSITE + "download-free-online-cloud-file-storage.html"}>
              {__("desktop_backup_download_button")}
            </Style.OvalButton>
          )}
        </Style.BoxItem>
      </Style.BoxContainer>
      {isMobileDevice ? null : (
        <Style.Section>
          <Style.SectionTitle>{__("desktop_backup_section1_title")}</Style.SectionTitle>

          <Style.BoxContainer>
            <Style.BoxItem alignDirection="right" className="text">
              <Style.BoxTitle>1. {__("desktop_backup_section1_step1_headline")}</Style.BoxTitle>
              <Style.BoxDescription>
                {__("desktop_backup_section1_step1_description")}
              </Style.BoxDescription>

              <Style.OvalButton target="_blank" href={URLSITE + "download-free-online-cloud-file-storage.html"}>
                {__("desktop_backup_download_button")}
              </Style.OvalButton>
            </Style.BoxItem>
            <Style.BoxItem alignDirection="left" className="image">
              <Style.BoxImage src={currentOsImages.step1} width="290" />
            </Style.BoxItem>
          </Style.BoxContainer>

          <Style.BoxContainer>
            <Style.BoxItem alignDirection="right" className="image">
              <Style.BoxImage src={currentOsImages.step2} width="471" />
            </Style.BoxItem>
            <Style.BoxItem alignDirection="left" className="text">
              <Style.BoxTitle>2. {__("desktop_backup_section1_step2_headline")}</Style.BoxTitle>
              <Style.BoxDescription>
                {__("desktop_backup_section1_step2_description")}
              </Style.BoxDescription>
            </Style.BoxItem>
          </Style.BoxContainer>
        </Style.Section>
      )}
    </Style.Wrapper>
  );
};

export default DesktopInfo;

const InfoIcon = styled.div`
  display: inline-block;
  margin: 0px 6px;
  position: relative;
  top: 2px;
  width: 16px;
  height: 16px;
  cursor: pointer !important;

  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  background-image: url(${require("../../../root/img/data-location/info.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/data-location/info@2x.png")});
  }
`;
