import dropDown from './dropDown';
import $ from 'jquery';

const singleChoiceTemplate = [
    '<span class="single_choice">',
        '<input type="hidden" class="choice">',
        '<span class="value"></span>',
        '<img src="/img/share-opt-black.png">',
    '</span>'
].join("\n");

const singleChoiceDefaultOptions = {
    name: 'single_choice_n',
    onChange: function() {}
}

function getSelectedOption(options) {
    var selected;

    selected = options.filter(function (option) {
        return option.selected;
    });

    if (selected.length) {
        return selected[0];
    }

    return options[0];
}

function singleChoiceSetVal(wrap, option) {
    wrap.find('.choice').val(option.value);
    wrap.find('.value').text(option.title);
    wrap.data('val', option);
}

function getCurrentChoice(wrap) {
    return wrap.find('.choice').val();
}

export default function singleChoiceDropdown(opts) {
    opts = $.extend({}, singleChoiceDefaultOptions, opts);

    var wrap = $(singleChoiceTemplate);
    var id = uniqueNum.get();
    var selectedOption = getSelectedOption(opts.options);
    var val;

    if (opts.name) {
        wrap.find('input.choice').attr('name', opts.name);
    }

    singleChoiceSetVal(wrap, selectedOption);

    dropDown.bindList(opts.options, wrap, {
        direction: dropDown.DIR_LEFT,
        childDirection: dropDown.DIR_LEFT,
        position: 'fixed',
        holderClass: 'mnnew singlechoicemn',
        eventTrigger: 'click',
        eventClose: 'click',
        showTip: false,
        overwriteTip: {
            right: '4px'
        },
        buildCell: function (item, el) {
            var a = $('<a>')
                .addClass(
                    getCurrentChoice(wrap) == item.value ?
                        'active' : ''
                )
                .attr('href', 'javascript:void(0);')
                //.attr('data-action', item)
                .append($('<li>').text(item.title))
                .on('click', function (e) {
                    e.preventDefault();
                    e.stopPropagation();

                    if (getCurrentChoice(wrap) != item.value) {
                        singleChoiceSetVal(wrap, item);
                        opts.onChange({
                            name: opts.name,
                            item: item
                        });
                    }
                    //dropDown.resetTo(0);
                    dropDown.close();
                })
                .appendTo(el);

            return a;
        },
        buildHolder: function (obj) {
            obj.addClass('mn-perm').css('z-index', 20000);
        }
    });

    return wrap;
}
