import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const colors = {
	gray: "#fafafa"
};
const borderColor = {
	gray: "#f0f0f0"
};

const BoxContainer = props => {
	return <Box {...props} />;
};

BoxContainer.defaultProps = {
	color: "gray",
	border: "gray"
};
BoxContainer.PropTypes = {
	color: PropTypes.string.isRequired,
	border: PropTypes.string
};

export default BoxContainer;

const Box = styled.div`
	padding: 19px;
	background: ${props => colors[props.color]};
	border-color: ${props => borderColor[props.border]};
	border-width: 1px;
	border-style: solid;
`;
