// @flow

import React, { useState, useEffect } from "react";

import Modal, { OvalModalWrap, CloseModalIcon } from "../Modal";
import Button from "../ButtonDefault";
import FileIcon from "../FileIcon";
import * as Style from "./styledComponents";

import { __ } from "../../lib/translate";
import { getIconType } from "../../lib/utils";

const ItemInfoModal = ({ data, modalContainer }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [fields, setFields] = useState([]);

  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const {
      contenttype,
      thumb,
      ismine,
      isshared,
      created,
      modified,
      isfolder,
      size,
      filescnt,
      parentfolderid,
      category,
      width,
      height
    } = data;

    let itemFields = [
      [__("Content type"), contenttype],
      [__("Has thumb"), thumb ? __("Yes") : __("No")],
      [__("Is mine"), ismine ? __("Yes") : __("No")],
      [__("label_shares", "Shared"), isshared ? __("Yes") : __("No")],
      [__("Created"), HFN.prepDt(created)],
      [__("Last modify"), HFN.prepDt(modified)]
    ];

    if (isfolder) {
      itemFields.splice(0, 3);
    }

    if (!isfolder) {
      itemFields.unshift([
        __("Size"),
        HFN.formatSize(size) + (filescnt ? ", " + filescnt + " " + __("Files") : "")
      ]);
    }

    if ("d" + parentfolderid in HFN.data.fflookup) {
      const parent = HFN.data.fflookup["d" + parentfolderid];
      const parentName = HFN.metaName(parent);
      const iconName = getIconType(parent);

      itemFields.unshift([
        __("Parent Folder"),
        <React.Fragment>
          <FileIcon item={iconName} type={HFN.ICONS.LIST_SMALL} metadata={parent} />
          <Style.NameEllipsis className="text">{parentName}</Style.NameEllipsis>
        </React.Fragment>
      ]);
    }

    if (category == HFN.CATEGORY.IMAGE || category == HFN.CATEGORY.VIDEO) {
      if (width && height) {
        itemFields.push([__("Width"), width + "px"]);
        itemFields.push([__("Height"), height + "px"]);
      }
    }

    setFields(itemFields);
  }, [data]);

  const renderRow = (item, index) => {
    return (
      <Style.TableRow key={index + item[0]}>
        <Style.TableCol>{item[0]}</Style.TableCol>
        <Style.TableCol>{item[1]}</Style.TableCol>
      </Style.TableRow>
    );
  };

  const iconName = getIconType(data);
  const itemName = HFN.metaName(data);

  return (
    <Modal animate onClose={handleClose} opened={isOpen} container={modalContainer}>
      <OvalModalWrap>
        <CloseModalIcon onClick={handleClose} />
        <Style.Container>
          <Style.Header>{__("Item Details")}</Style.Header>
          <Style.Label>{__("Details")}</Style.Label>
          <Style.Table>
            <Style.TableHeader>
              <FileIcon item={iconName} type={HFN.ICONS.LIST_SMALL} metadata={data} />
              <Style.NameEllipsis>{itemName}</Style.NameEllipsis>
            </Style.TableHeader>
            <Style.TableBody>{fields.map(renderRow)}</Style.TableBody>
          </Style.Table>
          <Style.Footer>
            <Button color="lightgray4" onClick={handleClose}>
              {__("Close")}
            </Button>
          </Style.Footer>
        </Style.Container>
      </OvalModalWrap>
    </Modal>
  );
};

export default ItemInfoModal;
