// @flow

import React, { PureComponent } from "react";
import { findDOMNode } from "react-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import styled, { css } from "styled-components";
import { hiDPI } from "polished";
import $ from "jquery";

import apiMethod from "../api/apiMethod";
import { getHash } from "../lib/utils";
import { __ } from "../lib/translate";

const showError = () => {
  HFN.message(
    __("search_photos_character_error", "You need to enter at least 2 characters."),
    "error"
  );
};

type Props = {
  isBusiness: boolean
};

type State = {
  searchText: string
};

class PhotoSearchInput extends PureComponent<Props, State> {
  static defaultProps = {
    isBusiness: false
  };

  constructor(props: Props) {
    super(props);

    (this: any).onClear = (this: any).onClear.bind(this);
    (this: any).onKeyDown = (this: any).onKeyDown.bind(this);
    (this: any).onSearchIconClick = (this: any).onSearchIconClick.bind(this);
    (this: any).onSearchButtonClick = (this: any).onSearchButtonClick.bind(this);
    (this: any).search = (this: any).search.bind(this);
    (this: any).onInputChange = (this: any).onInputChange.bind(this);
    (this: any).getInputRef = (this: any).getInputRef.bind(this);

    this.state = {
      searchText: ""
    };

    (this: any).inputSearch = null;
  }

  componentDidMount() {
    const queryQ = getHash("q") || "";
    const paramSearchText = queryQ.split(":")[1] || "";

    this.setState({
      searchText: paramSearchText.split(/\+/g).join(" ")
    });
  }

  onClear(e: any) {
    this.setState({
      searchText: ''
    });
    this.onSearchIconClick(e);
  }

  onSearchButtonClick(e: any) {
    this.search();
  }

  onKeyDown(e: any) {
    if (e.keyCode == 13) {
      this.search();
    }
  }

  onInputChange(e: any) {
    this.setState({ searchText: e.target.value });
  }

  onSearchIconClick(e: any) {
    if (this.inputSearch) {
      (this: any).inputSearch.focus();
    }
  }

  getInputRef(node) {
    return ((this: any).inputSearch = node);
  }

  search() {
    const { folderId } = this.props;
    const { searchText } = this.state;
    const textLength = searchText.length;
    const minTextLength = 2;

    if (textLength < minTextLength) {
      showError();
      return;
    }

    $.bbq.pushState(
      Object.assign(
        {},
        {
          page: "photosearch",
          q: "name:" + searchText
        }
      ),
      2
    );
  }

  render() {
    const { searchText } = this.state;

    return (
      <Wrapper className="photo-search-wrapper">
        <SearchIcon
          className="search-icon"
          onClick={this.onSearchIconClick}
        />
        <SearchInput
          ref={this.getInputRef}
          name="search"
          type="text"
          autoComplete="off"
          value={searchText}
          placeholder={__("search_photos_input_placeholder", "Search your photos")}
          onChange={this.onInputChange}
          onKeyDown={this.onKeyDown}
        />
        {searchText !== '' ?
          <ClearButton
            className="photo-search-clear"
            onClick={this.onClear}
          />
          : null}
        <SearchButton
          className="photo-search-button"
          onClick={this.onSearchButtonClick}
          notActive={searchText.length < 2}
        />
      </Wrapper>
    );
  }
}

export default connect(({ user: { userinfo: { business } } }) => {
  return { isBusiness: business };
})(PhotoSearchInput);

const Wrapper = styled.div`
  position: relative;
  min-width: 325px;
  max-width: 500px;
`;

const SearchInput = styled.input`
  height: 48px;
  padding: 12px 48px 12px 48px;
  border-radius: 24px;
  background-color: #ffffff;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid rgba(228, 228, 228, 1);
  font-size: 15px;
  &:focus, &:focus-visible {
    border: 1px solid rgba(23, 190, 208, 1);
    outline: none;
  }
  &::-ms-clear {
    display: none;
    height: 0;
    width: 0;
  }
`;

const ClearButton = styled.div`
  position: absolute;
  right: 7px;
  top: 7px;
  padding: 10px;
  width: 10px;
  height: 10px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 10px;

  background-image: url(${require("../../root/img/full-text-search/close.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../root/img/full-text-search/close@2x.png")});
  }

  &:hover {
    background-image: url(${require("../../root/img/full-text-search/close-active.png")});
    ${hiDPI(1.5)} {
      background-image: url(${require("../../root/img/full-text-search/close-active@2x.png")});
    }
  }
`;

const SearchIcon = styled.div`
  top: 12px;
  left: 16px;
  position: absolute;
  width: 24px;
  height: 24px;
  cursor: default;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 18px;
  background-image: url(${require("../../root/img/photosearch/search.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../root/img/photosearch/search@2x.png")});
  }
`;

const SearchButton = styled.div`
  top: 4px;
  right: 4px;
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: ${props => (props.notActive ? "default" : "pointer")};
  background-repeat: no-repeat;
  background-position: center;
  background-size: 24px;
  background-image: url(${require("../../root/img/photosearch/arrow_right_alt.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../root/img/photosearch/arrow_right_alt@2x.png")});
  }
  background-color: ${props => (props.notActive ? "rgba(217, 217, 217, 1)" : "rgba(23, 190, 208, 1)")};
`;
