// @flow

import React from "react";
import styled from "styled-components";
import { hiDPI } from "polished";

import type { BonusStep } from "./types";

type StepImageProps = {
  step: BonusStep /*| "final"*/,
  completed?: boolean
};

const Base = styled.div`
  width: 144px;
  height: 144px;
  background-size: 144px 144px;
  background-position: center center;
  background-repeat: no-repeat;
`;

const StepOne = styled(Base)`
  background-image: url(${require("../../../root/img/journey/1.png")});
  ${hiDPI()} {
    background-image: url(${require("../../../root/img/journey/1@2x.png")});
  }
`;

const StepTwo = styled(Base)`
  background-image: url(${require("../../../root/img/journey/2.png")});
  ${hiDPI()} {
    background-image: url(${require("../../../root/img/journey/2@2x.png")});
  }
`;

const StepThree = styled(Base)`
  background-image: url(${require("../../../root/img/journey/3.png")});
  ${hiDPI()} {
    background-image: url(${require("../../../root/img/journey/3@2x.png")});
  }
`;

const StepFour = styled(Base)`
  background-image: url(${require("../../../root/img/journey/4.png")});
  ${hiDPI()} {
    background-image: url(${require("../../../root/img/journey/4@2x.png")});
  }
`;

const StepFive = styled(Base)`
  background-image: url(${require("../../../root/img/journey/5.png")});
  ${hiDPI()} {
    background-image: url(${require("../../../root/img/journey/5@2x.png")});
  }
`;

const StepSix = styled(Base)`
  background-image: url(${require("../../../root/img/journey/6.png")});
  ${hiDPI()} {
    background-image: url(${require("../../../root/img/journey/6@2x.png")});
  }
`;

const StepFinal = styled(Base)`
  background-image: url(${require("../../../root/img/journey/final.png")});
  ${hiDPI()} {
    background-image: url(${require("../../../root/img/journey/final@2x.png")});
  }
`;

const Wrap = styled.div`
  width: 144px;
  height: 100%;
  position: relative;

  & > div {
    position: absolute;
  }
`;

const Done = styled.div`
  width: 144px;
  height: 100%;
  opacity: 0.7;
  background-color: #999999;
  background-size: 64px 64px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${require("../../../root/img/journey/ok.png")});
  ${hiDPI()} {
    background-image: url(${require("../../../root/img/journey/ok@2x.png")});
  }
`;

const steps = {
  "1": <StepOne />,
  "2": <StepTwo />,
  "3": <StepThree />,
  "4": <StepFour />,
  "5": <StepFive />,
  "6": <StepSix />
};
//final: <StepFinal />

const StepImage = ({ step, completed }: StepImageProps) => (
  <Wrap>
    {steps[step]}
    {completed && <Done />}
  </Wrap>
) ;

export default StepImage;
