import React, {useState} from 'react';
import usePlayerContext from "../../../hooks/usePlayerContext";
import {BackdropQueueOverlay, SwipeQueueItemContainerWrapper} from "../../../userinterface/styles";
import ActionButton from "../../../userinterface/ActionButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose} from '@fortawesome/pro-solid-svg-icons/faClose'

const SwipeItemContainer = ({children, isActive, isDragging, id}) => {
    const {
        isMobile,
        onRemoveFromQueue
    } = usePlayerContext();

    const [dragX, setDragX] = useState(0);

    const handleDragStart = (info) => {
        setDragX(info.point.x)
    }

    const handleDragEnd = (info, itemId) => {
        if (info.point.x == 0) {
            return;
        }
        const dragDistance = dragX - info.point.x;
        if (dragX > 0 && (dragDistance < -200 || dragDistance > 200)) {
            onRemoveFromQueue(null, itemId);
            setDragX(0);
        }
    }

    return (
        <>
            <SwipeQueueItemContainerWrapper
                drag={!isDragging && isMobile ? 'x' : null}
                dragConstraints={{ left: 0, right: 0 }}
                onDragStart={(_, info) => handleDragStart(info)}
                onDragEnd={(_, info) => handleDragEnd(info, id)}
                $activeSong={isActive}
            >
                {children}
            </SwipeQueueItemContainerWrapper>
            {isMobile && (
                <>
                    <BackdropQueueOverlay>
                        <ActionButton
                            onClick={() => {}}
                            label={<FontAwesomeIcon style={{fontSize: 16, color: '#fff'}} icon={faClose} />}
                        />
                        <ActionButton
                            onClick={() => {}}
                            label={<FontAwesomeIcon style={{fontSize: 16, color: '#fff'}} icon={faClose} />}
                        />
                    </BackdropQueueOverlay>
                </>
            )}
        </>
    );
}

export default SwipeItemContainer;