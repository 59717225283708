// @flow

import React, { Component } from "react";
import { connect } from "react-redux";
import Componentify from "react-componentify";

import { spanClassTagConverter } from "../../lib/componentifyConverters";

import { __ } from "../../lib/translate";
import { ButtonCentered } from "../../components/ButtonDefault";
import { Container, Header, Message } from "./SharedComponents";
import { Footer } from "../../components/Modals/styledComponents";
import SuccessIcon from "../../../root/img/family/success.svg";

type Props = {
  msisdn: string,
  onSuccess: () => void
};

class ChangePhoneNumberStep extends Component<Props, {}> {
  static defaultProps = {
    msisdn: "",
    onSuccess: () => {}
  };

  render() {
    const { msisdn, onSuccess } = this.props;
    const phoneNumber = msisdn ? `+${msisdn}` : "";

    return (
      <Container>
        <Header>{__("tfa_setup_change_phone_number_header")}</Header>
        <SuccessIcon style={{ marginTop: "15px" }} />
        <Message
          className="change-phone"
        >
          <Componentify
            text={__("tfa_setup_change_phone_number_message", "", {
              phonenumberattr: `class='phone-number'`,
              phonenumber: phoneNumber
            })}
            converters={[spanClassTagConverter]}
          />
        </Message>
        <Footer className="single">
          <ButtonCentered
            color="lightgray4"
            style={{
              width: "100%"
            }}
            onClick={onSuccess}
          >
            {__("Done")}
          </ButtonCentered>
        </Footer>
      </Container>
    );
  }
}

export default connect(({ user }) => {
  const { userinfo: { msisdn } = {} } = user;

  return {
    msisdn
  };
})(ChangePhoneNumberStep);
