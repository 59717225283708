import React, {useState} from "react";
import {__} from "../../../lib/translate";
import CloseIcon from "../../../../root/img/onboarding/onboarding-close.svg";
import NextIcon from "../../../../root/img/onboarding/next.svg";
import PrevIcon from "../../../../root/img/onboarding/prev.svg";
import DoneIcon from "../../../../root/img/onboarding/done.svg";
import styled from "styled-components";
import {useAnimationControls, motion} from "framer-motion";

type Props = {
    content: Array<any>,
    onClose: () => void,
    readMore: string,
};

const PopupContentManager = ({content, readMore, onClose}: Props) => {
    const controls = useAnimationControls();
    const [page, setPage] = useState(1);
    const hasPagination = content.length > 1;

    const handlePrev = () => {
        controls.start({
            x: ["0vw", "100vw", "-100vw", "0vw"],
            transition: { duration: 0.3, times: [0, 0.5, 0.5, 1] }
        });
        setPage(Math.max(1, page - 1));
    };
    const handleNext = () => {
        controls.start({
            x: ["0vw", "-100vw", "100vw", "0vw"],
            transition: { duration: 0.3, times: [0, 0.5, 0.5, 1] }
        });
        setPage(Math.min(content.length, page + 1));
    };

    return (
        <PopupContentWrapper>
            <CloseIconWrapper onClick={onClose}>
                <CloseIcon />
            </CloseIconWrapper>
            <motion.div
                animate={controls}
            >
                {content.length > 0 && content[page - 1]}
            </motion.div>
            <FooterWrapper>
                {(!hasPagination) && (
                    <ActionsWrapper>
                        {typeof readMore === 'string' && (
                            <LinkButton href={readMore} target="_blank">
                                {__('Read more')}
                            </LinkButton>
                        )}
                        <Button onClick={(e) => onClose(e, true)}>
                            {__('tutorial_button_get_started_upload')}
                        </Button>
                    </ActionsWrapper>
                )}
                {(hasPagination) && (
                    <>
                        <PaginationLabel>
                            {page + ' / ' + content.length}
                        </PaginationLabel>
                        <ActionsWrapper>
                            {typeof readMore === 'string' && page === content.length && (
                                <LinkButton
                                    className={'pagination'}
                                    href={readMore}
                                    target="_blank"
                                >
                                    {__('Read more')}
                                </LinkButton>
                            )}
                            <Button
                                className={page === 1 ? 'disabled pagination' : ' pagination'}
                                onClick={page === 1 ? () => {} : handlePrev}
                            >
                                <PrevIcon fill={page === 1 ? '#E2E2E2' : '#17BED0'}/>
                            </Button>
                            {page !== content.length && (
                                <Button
                                    className={'pagination'}
                                    onClick={handleNext}
                                >
                                    <NextIcon />
                                </Button>
                            )}
                            {page === content.length && (
                                <Button
                                    className={page === content.length ? 'active pagination' : ' pagination'}
                                    onClick={(e) => onClose(e, true)}
                                >
                                    <DoneIcon />
                                </Button>
                            )}
                        </ActionsWrapper>
                    </>
                )}
            </FooterWrapper>
        </PopupContentWrapper>
    );
}

export default PopupContentManager;

const PopupContentWrapper = styled.div`
    overflow: hidden;
`;

const CloseIconWrapper = styled.div`
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
`;

const FooterWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-top: 24px;
`;

const ActionsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: end;
    flex-grow: 1;
`;

const Button = styled.div`
    border-radius: 4px;
    border: 1px solid #17BED0;
    padding: 7px 24px;
    box-sizing: border-box;
    background: #fff;
    color: #17BED0;
    font-size: 15px;
    display: flex;
    cursor: pointer;
    -webkit-transition: background .2s linear, color .2s linear;
    -ms-transition: background .2s linear, color .2s linear;
    transition: background .2s linear, color .2s linear;
    
    &:not(.disabled):hover {
        background: #17BED0;
        color: #fff;
        & > svg {
            fill: #fff;
        }
    }
    &.active {
        background: #17BED0;
        color: #fff;
        & > svg {
            fill: #fff;
        }
    }
    &.disabled {
        border: 1px solid #E2E2E2;
        color: #E2E2E2;
        cursor: auto;
    }
    &.pagination {
        margin-left: 12px;
        padding: 0;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
    }
`;
const LinkButton = styled.a`
    border-radius: 4px;
    background: #fff;
    color: #17BED0;
    padding: 7px 24px;
    font-size: 15px;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    box-sizing: border-box;
    
    &.pagination {
        padding: 0 8px;
    }
`;
const PaginationLabel = styled.div`
    color: rgba(0, 0, 0, 0.65);
    font-size: 15px;
`;