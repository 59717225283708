import React, { useState, memo, useCallback, useEffect, useMemo } from 'react';
import styled, { keyframes, css } from 'styled-components';
import SuccessSvg from "../../../root/img/svg/upload-manager/success.svg";
import FailedSvg from "../../../root/img/svg/upload-manager/failed.svg";
import LoadingSvg from "../../../root/img/svg/upload-manager/loading.svg";
import FailedFolderSvg from "../../../root/img/svg/upload-manager/failed-folder.svg"
import CancelSvg from "../../../root/img/svg/upload-manager/cancel.svg";
import FolderSvg from "../../../root/img/svg/upload-manager/folder.svg";
import RefreshSvg from "../../../root/img/svg/upload-manager/refresh.svg";
import { formatSize } from "../../lib/utils";
import { UPLOAD_STATUS, UPLOAD_TAB } from "./constants";
import { fileItemShouldFadeOut, routeToFileLocation, getFileDetails } from "./helpers";
import { areEqual } from "react-window";
import useUploadContext from './hooks/useUploadContext';
import { __ } from "../../lib/translate"
import { getIconInfo, ICO, ICONS, getFileIcon } from "../../lib/icons";
import Tooltip from '@pcloud/web-utilities/dist/resources/components/Tooltip';
import { errorKeys } from '../../lib/errors';

const FileItem = memo(({ index, style }) => {
  const pageFolderId = Number($.bbq.getState('folder') || currentFolder || 0)
  const {
    activeTab,
    filteredUploadQueue,
    handleRetryAction,
    abortUploadAction,
    openFailedFolderModal,
    failedItems,
    donePercentage
  } = useUploadContext();
  const { status, name, size, isFile, id, folderidCurrent, progress, encrypted, url, folderidCreated, fileIdCreated } = filteredUploadQueue[index];
  const fieldItem = failedItems.find((item) => item.id === id);
  const { baseName, extension } = getFileDetails(name, isFile);
  const percentage = (isFile ? progress : (progress / size) * 100) || 0;
  const [isHovered, setIsHovered] = useState(false);
  const [prevProgress, setPrevProgress] = useState(percentage);
  const failedItemInFolder = isFile ? undefined : fieldItem;
  let failedItemsCountFolder = undefined;
  if (failedItemInFolder) {
    const filedItemKeyLabel = failedItemInFolder.files.length + failedItemInFolder.uniqueFolders.size === 1
      ? 'failed_item' : 'failed_items';
    failedItemsCountFolder = `${failedItemInFolder.files.length + failedItemInFolder.uniqueFolders.size}
       ${__(filedItemKeyLabel, 'failed').toLowerCase()}`;
  }

  const isIncreasing = useMemo(() => {
    return percentage > prevProgress;
  }, [percentage])

  useEffect(() => {
    setPrevProgress(percentage);
  }, [percentage]);


  let errorCode;
  if (
    isFile &&
    [UPLOAD_STATUS.FAILED, UPLOAD_STATUS.FADING_ACTIVE_FAILED].includes(status) &&
    fieldItem &&
    fieldItem.hasOwnProperty('files')
  ) {
    const fileObj = fieldItem.files[0];
    errorCode = fileObj.hasOwnProperty('errorCode') ? fileObj.errorCode : null;
  }

  const renderIcon = useCallback(() => {
    switch (status) {
      case UPLOAD_STATUS.FAILED:
        return isHovered && isFile ?
          <RefreshSvg onClick={() => handleRetryAction(id, activeTab === UPLOAD_TAB.FAILED)} /> :
          <FailedSvg onClick={() => openFailedFolderModal(id)} />
      case UPLOAD_STATUS.COMPLETED:
        return isHovered && (pageFolderId !== folderidCreated || isFile) ? <FolderSvg onClick={() => location.href = routeToFileLocation(folderidCreated || folderidCurrent, fileIdCreated)} /> : <SuccessSvg />
      case UPLOAD_STATUS.FADING_ACTIVE_COMPLETED:
        return <SuccessSvg />
      case UPLOAD_STATUS.FADING_ACTIVE_FAILED:
        return <FailedSvg />
      case UPLOAD_STATUS.FADING_FAILED_RETRY:
        return <SpinningSVG />
      // pending
      case UPLOAD_STATUS.PENDING:
        return <Tooltip place="left" text={__("upload_manager_cancel_upload", "Cancel this upload")}>{isHovered ? <CancelSvg onClick={() => {
          if (donePercentage.isUploading) {
            abortUploadAction(id);
          }
        }
        } /> : <SpinningSVG />}</Tooltip>
    }
  }, [status, isHovered, filteredUploadQueue, folderidCreated, folderidCurrent, pageFolderId])


  const renderFileSize = () => {
    if (isFile) {
      return size !== undefined ? formatSize(size) : undefined;
    }

    if (failedItemInFolder && (status === UPLOAD_STATUS.FAILED || status === UPLOAD_STATUS.FADING_FAILED_RETRY)) {
      return failedItemsCountFolder;
    }

    if (size) {
      return `${progress} ${__('of', 'of')} ${size} `;
    }
  }

  return (
    <FileItemContainer
      data-for="failed-item-error-tooltip"
      data-tip={
        url || errorCode ?
          `${url ? url + '- ' : ''} ${errorCode ? __(errorKeys[errorCode]) : ''}` :
          ([UPLOAD_STATUS.FAILED, UPLOAD_STATUS.FADING_FAILED_RETRY].includes(status) ? __("something_went_wrong_refresh_and_try_again") : '')
      }
      style={style}
      $fadeOut={fileItemShouldFadeOut(status, activeTab)}
    >
      <Icon disabled>{isFile ? <StyledImg src={getIconInfo(getFileIcon({ name: name.toLowerCase() }), ICONS.LIST).src} /> : <StyledImg src={getIconInfo(encrypted ? ICO.CRYPTO_FOLDER : ICO.FOLDER, ICONS.LIST).src} />}</Icon>
      <ProgressBarWithNameContainer>
        <FileNameContainer title={name}>
          <FileNameLeft $isFile={isFile}>
            {baseName && <BaseName>{baseName}</BaseName>}
            {extension && <FileExtension>{extension}</FileExtension>}
            {url && <BaseName>{__('upload_manager_remote_upload_option', 'File from URL')}</BaseName>}
          </FileNameLeft>
          <FileSize>{(failedItemInFolder && (status !== UPLOAD_STATUS.FAILED && status !== UPLOAD_STATUS.FADING_FAILED_RETRY)) && <Tooltip place="left" text={failedItemsCountFolder}><StyledFailedFolderSvg /></Tooltip>}{renderFileSize()}</FileSize>
        </FileNameContainer>
        <ProgressbarContainer>
          <ProgressBar progress={percentage} status={status} $isIncreasing={isIncreasing} />
          <ProgressBarFront progress={percentage} status={status} $isIncreasing={isIncreasing} />
        </ProgressbarContainer>
      </ProgressBarWithNameContainer>
      <Icon
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        disabled={(!isFile && pageFolderId === folderidCreated) && (status === UPLOAD_STATUS.COMPLETED || status === UPLOAD_STATUS.FADING_ACTIVE_COMPLETED)}
      >
        {renderIcon()}
      </Icon>
    </FileItemContainer>
  );
}, areEqual);

export default FileItem;

const fadeOutAnimation = keyframes`
  to { opacity: 0; }
`;

const FileItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #F1F1F1;
  height: 56px;
  box-sizing: border-box;
  width: 100%;
  padding: 0 16px;

  ${({ $fadeOut }) => $fadeOut && css`
    animation: ${fadeOutAnimation} 1s forwards;
  `}
`;

const Icon = styled.div`
  font-size: 24px;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  cursor: ${({ disabled }) => disabled ? 'default' : 'pointer'};
`

const ProgressBarWithNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 80px);
  margin: 0 12px;
`

const ProgressbarContainer = styled.div`
  height: 3px;
  background-color: #CACACEA6;
  border-radius: 3px;
  width: 100%;
`

const ProgressBar = styled.div`
  height: 100%;
  background-color: ${({ status }) => status === UPLOAD_STATUS.FAILED || status === UPLOAD_STATUS.FADING_ACTIVE_FAILED ? "#F73C3C" : "#17bed0"};
  border-radius: 3px;
  width: ${({ progress, status }) => status === UPLOAD_STATUS.FAILED || status === UPLOAD_STATUS.FADING_ACTIVE_FAILED ? 100 : progress}%;
  transition: ${({ $isIncreasing, status }) => $isIncreasing && status !== UPLOAD_STATUS.COMPLETED && status !== UPLOAD_STATUS.FAILED ? 'width 0.5s ease-in-out' : 'none'};
  z-index: 1;
  position: relative;
`;

const ProgressBarFront = styled.div`
  height: 100%;
  background-color: #FFF;
  border-radius: 3px;
  width: ${({ progress }) => `calc(${progress}% + ${progress === 0 ? 0 : 2}px)`};
  transition:   ${({ $isIncreasing, status }) => $isIncreasing && status !== UPLOAD_STATUS.COMPLETED && status !== UPLOAD_STATUS.FAILED ? 'width 0.5s ease-in-out' : 'none'};
  position: relative;
  top: -3px;
`;

const FileNameContainer = styled.div`
  display: flex;
  width: 100%;
  font-size: 13px;
  font-family: 'Roboto', sans-serif;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;
`;

const FileNameLeft = styled.div`
  width: calc(100% - ${({ $isFile }) => $isFile ? 65 : 100}px);
  display: flex;
`

const FileSize = styled.span`
  color: #A2A2AA;
  font-family: Roboto;
  font-size: 11px;
  align-items: center;
  display: flex;
`

const StyledFailedFolderSvg = styled(FailedFolderSvg)`
  margin-right: 4px;
`

const BaseName = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 20px);
`;

const FileExtension = styled.span`
  flex-shrink: 0;
`;

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

// Create a styled SVG component that spins
const SpinningSVG = styled(LoadingSvg)`
  animation: ${spin} 2s linear infinite;
`;

const StyledImg = styled.img`
  width: 24px;
  height: 24px;
`