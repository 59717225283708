// @flow

import * as React from "react";
import styled from "styled-components";
import { clearFix, hiDPI } from "polished";

import FileIcon from "../FileIcon";
import { ICONS, ICO } from "../../lib/icons";
import { TSpan, TDiv } from "../../components/intl";
import type { Folder } from "../../types/filesystem";
import PublicFolderTop from "./Top";
import PublicFolderFeatures from "./Features";
import Button from "../../components/ButtonDefault";
import UpgradeButton from "./UpgradeButton";
import { URLSITE } from "@pcloud/web-utilities/dist/config";

type PublicFolderSettingsProps = {
  folder: Folder,
  cancreate: boolean,
  directlink: string,
  onDeleteFolder: () => void,
  deletingFolder: boolean,
  token: string,
  ispremium: boolean,
  trialexpiretime?: string
};

const PublicFolderSettings = ({
  folder,
  cancreate,
  directlink,
  onDeleteFolder,
  deletingFolder,
  token,
  ispremium,
  trialexpiretime
}: PublicFolderSettingsProps) => (
  <div>
    <PublicFolderTop folder={folder} trialexpiretime={trialexpiretime} />

    <Wrap>
      <FolderWrap>
        <FileIcon type={ICONS.LIST} item={ICO.PUBLIC} />
        <a href={"/#page=filemanager&folder=" + folder.folderid}>
          <TSpan id="open_your_public_folder">Open your public folder</TSpan>
        </a>
      </FolderWrap>
      <Buttons>
        {!ispremium && (
          <Button
            inline
            color="green"
            onClick={() => window.open(URLSITE + "cloud-storage-pricing-plans.html")}
          >
            Upgrade
          </Button>
        )}
        <Button
          color="red"
          inline
          onClick={onDeleteFolder}
          disabled={deletingFolder}
          loading={deletingFolder}
        >
          <DisableIcon />
        </Button>
      </Buttons>
      <FolderLink>
        <LinkIcon />
        <a href={directlink} target="_blank">
          {directlink}
        </a>
      </FolderLink>
    </Wrap>
    <PublicFolderFeatures />
  </div>
);

export default PublicFolderSettings;

const Buttons = styled.div`
  float: right;

  & > a {
    margin: 0 0 0 5px;
    vertical-align: middle;
  }
`;

const Wrap = styled.div`
  width: 980px;
  box-sizing: border-box;
  ${clearFix()} margin: 0 auto;
  border: 1px solid #f0f0f0;
  background: #fafafa;
  padding: 10px;
`;

const FolderWrap = styled.div`
  float: left;
  line-height: 40px;
  font-size: 13px;

  .iconwrap {
    display: inline-block;
    float: none;
    margin: -4px 6px 0 0;
  }

  a {
    color: #1a1a1a;

    &:hover {
      text-decoration: none;
    }
  }
`;

const FolderLink = styled.div`
  text-align: center;
  margin: 0 150px 0 150px;
  line-height: 40px;

  a {
    color: #1a1a1a;
    font-size: 13px;

    &:hover {
      text-decoration: none;
    }
  }
`;

const LinkIcon = styled.div`
  display: inline-block;
  vertical-align: middle;
  width: 24px;
  height: 24px;
  margin: 0 10px 0 0;
  background-size: 24px 24px;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(${require("../../../root/img/publicfolder/url.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/publicfolder/url@2x.png")});
  }
`;

const DisableIcon = styled.div`
  width: 12px;
  height: 40px;

  background-size: 12px 12px;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(${require("../../../root/img/publicfolder/disable.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/publicfolder/disable@2x.png")});
  }
`;
