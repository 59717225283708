// @flow

import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { __ } from "../lib/translate";
import { EDIT, VIEW } from "@pcloud/web-utilities/dist/config/constants";
import Button from "./ButtonDefault";
import InputStyledRadio from "./InputStyledRadio";

const AcceptInviteToFolder = ({ shareData = {} }) => {
  const [isCheckedView, setIsCheckedView] = useState(true);
  const [isCheckedEdit, setIsCheckedEdit] = useState(false);

  const { frommail = "", sharename = "" } = shareData;
  const iconSrc = HFN.createIconSrc(HFN.ICO.FOLDER, HFN.ICONS.LIST_SMALL);

  useEffect(() => {
    const perm = calcPermissions(shareData);
    if (perm === EDIT) {
      setIsCheckedEdit(true);
      setIsCheckedView(false);
    }

    if (perm === VIEW) {
      setIsCheckedView(true);
      setIsCheckedEdit(false);
    }
  }, [shareData]);

  const onAcceptSuccess = () => {
    $.bbq.pushState({ page: "shares", tab: "requests-tab" }, 2);
    location.reload();
  };

  return (
    <Form>
      <Title>{__("New Share Invitation")}</Title>
      <Box>
        <Row>
          <Col>{__("From:")}</Col>
          <Col>{frommail}</Col>
        </Row>
        <Row>
          <Col>{__("Share:")}</Col>
          <Col>
            <Icon src={iconSrc} /> {sharename}
          </Col>
        </Row>
        <Row>
          <Col>{__("Permissions:")}</Col>
          <Col>
            <RadioWrapper>
              <InputStyledRadio
                disabled
                style={{ margin: "0" }}
                size="small"
                name="edit"
                text={__("Edit")}
                checked={isCheckedEdit}
              />
              <InputStyledRadio
                disabled
                style={{ margin: "0" }}
                size="small"
                name="view"
                text={__("View")}
                checked={isCheckedView}
              />
            </RadioWrapper>
          </Col>
        </Row>
      </Box>
      <Footer>
        <Button
          color="lightgray4"
          borderRadius={8}
          style={{
            marginRight: "5px"
          }}
          onClick={() => HFN.pages.goto("filemanager")}
        >
          {__("Decline")}
        </Button>
        <Button
          color="cyan"
          borderRadius={8}
          style={{
            marginLeft: "5px"
          }}
          onClick={HFN.acceptShare.bind(HFN, shareData, onAcceptSuccess)}
        >
          {__("Accept")}
        </Button>
      </Footer>
    </Form>
  );
};

export default AcceptInviteToFolder;

const Title = styled.div`
  width: 360px;
  font-size: 23px;
  font-weight: bold;
  margin-bottom: 25px;
  text-align: left;
`;

const Box = styled.div`
  width: 360px;
  border-radius: 8px;
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.03);
  border: solid 1px #eeeeee;
  background-color: #fafafa;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 40px;
  padding: 0 10px;
  box-sizing: border-box;
  border-bottom: solid 1px #eeeeee;

  &:first-of-type {
  }

  &:last-of-type {
    border: none;
  }
`;

const Col = styled.div`
  font-size: 13px;
  text-align: left;

  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  overflow: hidden;

  &:first-of-type {
    width: 30%;
    font-weight: bold;
  }

  &:last-of-type {
    width: 70%;
    font-weight: normal;
  }
`;

const Icon = styled.img`
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  vertical-align: middle;
`;

const RadioWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  label {
    margin-bottom: 0;
    padding-bottom: 0;
  }
`;

const Footer = styled.div`
  display: flex;
  margin-top: 30px;
  padding: 0;
  width: 360px;

  &.single {
    display: block;
  }

  a {
    display: flex;
    width: 100%;
    max-width: 100%;
    font-size: 15px;
    font-weight: normal;
    justify-content: center;
  }

  @media (max-width: 480px) {
    flex-direction: column;

    a {
      margin-left: 0 !important;
      margin-right: 0;
    }

    a:first-child {
      margin-bottom: 10px;
    }
  }
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  width: 100%;

  @media screen and (max-width: 910px) {
    min-width: 0px;
    text-align: center;
    max-width: 400px;
    padding: 60px 20px;
  }
`;
