import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianAxis,
  CartesianGrid
} from "recharts";
import { hiDPI } from "polished";

import NoStats from "./NoStats";
import { __ } from "../../lib/translate";
import { toBeautyDate, formatSize, toTime } from "../../lib/utils";

const GridDownloadLinkTraffic = ({ from, to, list, onPrev, onNext, show }) => {
  const isNextActive = toTime(to) + 3600 * 24 * 1000 >= Date.now();
  const noop = () => {};
  const classNameMobile = HFN.config.isMobile() ? "mobile" : "";
  return (
    <Body className={classNameMobile}>
      <Dates className={classNameMobile}>
        <Prev onClick={onPrev} />
        {toBeautyDate(from)} - {toBeautyDate(to)}
        <Next onClick={isNextActive ? noop : onNext} disabled={isNextActive} />
      </Dates>
      <BarChart
        width={360}
        height={140}
        data={list}
        margin={{ right: show === "traffic" ? 15 : 15, top: 0 }}
        style={{ margin: "0 auto" }}
      >

        <XAxis
          dataKey="dt"
          tick={<Lbl />}
          interval={0}
          axisLine={{ stroke: "#dedede" }}
          tickLine={false}
          padding={{ left: 10, right: 10 }}
        />

        <YAxis
          type="number"
          orientation="left"
          interval="preserveStart"
          tick={yAxisLabel(show)}
          // tick={false}
          axisLine={false}
          tickLine={false}
          width={show === "traffic" ? 60 : 35}
        />

        <Tooltip
          isAnimationActive={false}
          content={barTooltip(show)}
          cursor={false}
        />
        
        <CartesianGrid
          vertical={false}
          stroke="#dedede"
        />
        <Bar
          dataKey={show}
          fill="#0e74da"
          barSize={30}
          isAnimationActive={false}
          minPointSize={1}
        />
      </BarChart>
    </Body>
  );
};

GridDownloadLinkTraffic.propTypes = {
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      dt: PropTypes.string.isRequired,
      traffic: PropTypes.number.isRequired,
      downloads: PropTypes.number.isRequired,
      views: PropTypes.number.isRequired
    })
  ).isRequired,
  show: PropTypes.oneOf(["traffic", "downloads", "views"])
};

GridDownloadLinkTraffic.defaultProps = {
  show: "traffic"
};

const Lbl = ({ x, y, stroke, payload: { value } }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={10} textAnchor="middle" fontSize={11} fill="#999">
        {new Date(value).getDate()}
      </text>
    </g>
  );
};

const yAxisLabel = show => ({ x, y, payload: { value }, ...rest }) => {
  //console.log(rest);

  let showValue = value;
  let xOffset = -2;
  if (show === "traffic") {
    showValue = formatSize(value);
    xOffset = -2;
  }

  if (value === 0) {
    return null;
  }

  return (
    <g>
      <text
        x={x + xOffset}
        y={y + 3}
        fontSize={10}
        fill="#999"
        textAnchor="end"
      >
        {showValue}
      </text>
    </g>
  );

  return (
    <g transform={`translate(${x + xOffset},${y + 3})`}>
      <text fontSize={11} fill="#999" textAnchor="start">{showValue}</text>
    </g>
  );
};

const barTooltip = show => ({ payload: pay }) => {
  if (!pay.length) {
    return null;
  }

  const { dataKey, payload } = pay[0];

  let showValue = payload[dataKey];
  if (show === "traffic") {
    showValue = formatSize(showValue);
  }

  return (
    <ToolWrap>
      {__("dl_" + show, ucfirst(show))}: {showValue}
    </ToolWrap>
  );

  return (
    <ToolWrap>
      {__("dl_downloads", "Downloads")}: {payload.downloads}<br />
      {__("dl_traffic", "Traffic")}: {formatSize(payload.traffic)}
    </ToolWrap>
  );
};

export default GridDownloadLinkTraffic;

const Body = styled.div`
  background: #fff;
  padding: 10px 0 10px 0;

  &.mobile {
    padding: 0;
  }
`;

const Dates = styled.div`
  text-align: center;
  font-weight: bold;
  font-size: 12px;
  padding: 20px;

  &.mobile {
    height: auto;
    font-size: 15px;
    box-sizing: border-box;
  }
`;

const DateNavigation = styled.div`
  background-size: 15px 15px;
  background-repeat: no-repeat;
  background-position: center center;
  width: 15px;
  height: 15px;
  display: inline-block;
  vertical-align: middle;
  margin-top: -2px;
  cursor: pointer;
  opacity: ${props => (props.disabled ? 0.5 : 1)};

  &:hover {
    opacity: 0.5;
  }
`;

const Prev = styled(DateNavigation)`
  background-image: url(${require("../../../root/img/arrow-left.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/arrow-left@2x.png")});
  }
  margin-right: 5px;
`;

const Next = styled(DateNavigation)`
  background-image: url(${require("../../../root/img/arrow-right.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/arrow-right@2x.png")});
  }
  margin-left: 5px;
`;

const ToolWrap = styled.div`
  border-radius: 3px;
  border: 1px solid #000;
  background: #000;
  padding: 4px;
  color: #fff;
  font-size: 13px;
  line-height: 22px;
`;

function ucfirst(str) {
  return str.slice(0, 1).toUpperCase() + str.slice(1);
}
