"use strict";
exports.__esModule = true;
var horizontalPositionEnum;
(function (horizontalPositionEnum) {
    horizontalPositionEnum[horizontalPositionEnum["LEFT"] = 5] = "LEFT";
    horizontalPositionEnum[horizontalPositionEnum["CENTER"] = 6] = "CENTER";
    horizontalPositionEnum[horizontalPositionEnum["RIGHT"] = 7] = "RIGHT";
})(horizontalPositionEnum = exports.horizontalPositionEnum || (exports.horizontalPositionEnum = {}));
;
var verticalPositionEnum;
(function (verticalPositionEnum) {
    verticalPositionEnum[verticalPositionEnum["TOP"] = 0] = "TOP";
    verticalPositionEnum[verticalPositionEnum["CENTER"] = 1] = "CENTER";
    verticalPositionEnum[verticalPositionEnum["BOTTOM"] = 2] = "BOTTOM";
})(verticalPositionEnum = exports.verticalPositionEnum || (exports.verticalPositionEnum = {}));
;
var eventNamespace = '.trackTooltip';
function setup(selector, options) {
    options = _setupDefaults(options);
    if (typeof selector == 'string') {
        options._elementObj = $(selector);
    }
    else {
        options._elementObj = selector;
    }
    if (!options.container) {
        options.container = document.body;
    }
    options._elementObj.data('tooltipTrackingOptions', options);
    options._tooltipObj = build(options);
    options._elementObj.on('mouseenter', function (event) {
        show(options);
        $(window).on('mousemove' + eventNamespace, function (e) {
            var elementPosition = { x: e.pageX, y: e.pageY };
            if (!_comparePositions(elementPosition, options._lastPosition)) {
                draw(elementPosition, options);
                options._lastPosition = elementPosition;
            }
        });
    });
    options._elementObj.on('mouseleave', function (e) {
        $(window).off('mousemove' + eventNamespace);
        hide(options);
    });
}
function show(options) {
    options._tooltipObj.show();
}
function hide(options) {
    options._tooltipObj.hide();
}
function draw(position, options) {
    var position = _positionOffest(position, options);
    options._tooltipObj.css({
        top: position.y,
        left: position.x
    });
}
function build(options) {
    var obj = $('<div></div>')
        .addClass('track-tooltip')
        .text(options.text)
        .hide()
        .appendTo(options.container);
    if (options.html) {
        obj.html(options.text);
    }
    else {
        obj.text(options.text);
    }
    return obj;
}
function _setupDefaults(options) {
    if (options.verticalPosition == undefined) {
        options.verticalPosition = verticalPositionEnum.CENTER;
    }
    if (options.horizontalPosition == undefined) {
        options.horizontalPosition = horizontalPositionEnum.RIGHT;
    }
    options._lastPosition = { x: -1, y: -1 };
    return options;
}
function _positionOffest(position, options) {
    var newPosition = {
        x: position.x,
        y: position.y
    };
    var offset = 5;
    switch (options.horizontalPosition) {
        case horizontalPositionEnum.RIGHT:
            newPosition.x += offset;
            break;
        case horizontalPositionEnum.LEFT:
            newPosition.x -= (offset + options._tooltipObj.outerWidth());
            break;
        case horizontalPositionEnum.CENTER:
            newPosition.x -= options._tooltipObj.outerWidth() / 2;
            break;
    }
    switch (options.verticalPosition) {
        case verticalPositionEnum.CENTER:
            newPosition.y -= options._tooltipObj.outerHeight() / 2;
            break;
        case verticalPositionEnum.TOP:
            newPosition.y -= (offset + options._tooltipObj.outerHeight());
            break;
        case verticalPositionEnum.BOTTOM:
            newPosition.y += offset;
    }
    newPosition.y -= $(window).scrollTop();
    newPosition.x -= $(window).scrollLeft();
    return newPosition;
}
function _comparePositions(position1, position2) {
    return position1.x == position2.x && position1.y == position2.y;
}
function destroy(selector) {
}
exports["default"] = { setup: setup, destroy: destroy };
