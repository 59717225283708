// @flow

import React, { useState, useLayoutEffect, useRef } from "react";
import styled from "styled-components";

import { __ } from "../lib/translate";
import { CloseButton } from "../containers/TwoFactorAuth/SharedComponents";

type Props = {
  url: string,
  label: string,
  channelid: number,
  headlinetext: string,
  headlinetextcolor: string,
  btntext: string,
  btntextcolor: string,
  btnbackground: string,
  bgrcolor: string,
  smallimg: string,
  smallimg2x: string,
  smallimg_size: string,
  smallimg2x_size: string,
  middleimg: string,
  middleimg2x: string,
  middleimg_size: string,
  middleimg2x_size: string,
  bigimg: string,
  bigimg2x: string,
  bigimg_size: string,
  bigimg2x_size: string
};

const LeftMenuBanner = ({
  url,
  label,
  channelid,
  headlinetext,
  headlinetextcolor,
  btntext,
  btntextcolor,
  btnbackground,
  bgrcolor,
  smallimg,
  smallimg2x,
  smallimg_size,
  smallimg2x_size,
  middleimg,
  middleimg2x,
  middleimg_size,
  middleimg2x_size,
  bigimg,
  bigimg2x,
  bigimg_size,
  bigimg2x_size
}: Props) => {
  const [showBanner, setShowBanner] = useState(true);
  const [bannerHeight, setBannerHeight] = useState(0);
  const [displayBanner, setDisplayBanner] = useState(false);

  const [currentBanner, setCurrentBanner] = useState(0);
  const arrOffLeftMenuBanners = [
    {
      imageSrc: bigimg,
      imageSrc2x: bigimg2x,
      imageWidth: bigimg_size.split("x")[0],
      imageHeight: bigimg_size.split("x")[1],
      textSize: "26px"
    },
    {
      imageSrc: middleimg,
      imageSrc2x: middleimg2x,
      imageWidth: middleimg_size.split("x")[0],
      imageHeight: middleimg_size.split("x")[1],
      textSize: "18px"
    },
    {
      imageSrc: smallimg,
      imageSrc2x: smallimg2x,
      imageWidth: smallimg_size.split("x")[0],
      imageHeight: smallimg_size.split("x")[1],
      textSize: "12px"
    }
  ];

  const arrLength = arrOffLeftMenuBanners.length - 1;
  const targetRef = useRef(null);

  useLayoutEffect(() => {
    if (targetRef.current) {
      if (bannerHeight < availableSpase) {
        setDisplayBanner(true);
      } else {
        setCurrentBanner(prevCurrentBanner => prevCurrentBanner + 1);
      }
    }
  }, [availableSpase, bannerHeight]); //[bannerHeight]

  useLayoutEffect(() => {
    if (targetRef.current) {
      setBannerHeight(targetRef.current.offsetHeight);
    }
  }, [currentBanner]); //[currentBanner]

  const isRetina = window.devicePixelRatio > 1;
  const leftMenuDefaultHeight = 331;
  const leftMenuHeight =
    $(".left.lnav").outerHeight() >= leftMenuDefaultHeight
      ? $(".left.lnav").outerHeight()
      : leftMenuDefaultHeight;
  const headerDefaultHeight = 62;
  const headerHeight =
    $(".header-wrap").outerHeight() >= 62 ? $(".header-wrap").outerHeight() : headerDefaultHeight;
  const availableSpase = window.innerHeight - leftMenuHeight - headerHeight - 55;
  let buttonText = btntext ? btntext : __("leftmenu_banner_lifetime_cta", "Get the Offer");

  const handleClick = () => {
    HFN.sendAnalyticsEvent(
      {
        category: "mybottomleftbanner",
        action: "click",
        label: label,
        os: 4
      },
      {
        channelid: channelid
      }
    );
    // if (typeof gaAll === "function") {
    //   gaAll("event", label, "click");
    // }

    if (typeof ga4 == 'function') {
      ga4({
        eventname: "select_promotion",
        ecommerce: {
          items: [{
            promotion_name: label,
            creative_slot: "my_bottom_left_banner",
            item_id: channelid
          }]
        }
      })
    }

    window.open(url, "_blank");
  };

  const handleCloseClick = () => {
    setShowBanner(false);
  };

  if (!showBanner || currentBanner > arrLength) {
    return null;
  }

  return (
    <Wrap bgrcolor={bgrcolor} ref={targetRef} isDisplayed={displayBanner}>
      <PlansAdCloseButton onClick={handleCloseClick} />
      <BannerImage
        src={
          isRetina
            ? arrOffLeftMenuBanners[currentBanner].imageSrc2x
            : arrOffLeftMenuBanners[currentBanner].imageSrc
        }
        width="160px"
        height={arrOffLeftMenuBanners[currentBanner].imageHeight}
      />
      <Text color={headlinetextcolor} fontSize={arrOffLeftMenuBanners[currentBanner].textSize}>
        {__(headlinetext)}
      </Text>
      <Button
        title={__(buttonText)}
        btnbackground={btnbackground}
        btntextcolor={btntextcolor}
        onClick={handleClick}
      >
        {__(buttonText)}
      </Button>
    </Wrap>
  );
};

export default LeftMenuBanner;

const Wrap = styled.div`
  border: 1px solid #f9f9f9;
  border-radius: 10px;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.05);
  min-width: 160px;
  box-sizing: border-box;
  background-color: ${props => (props.bgrcolor ? `#${props.bgrcolor}` : "#ffffff")};
  overflow: hidden;
  visibility: ${props => (props.isDisplayed ? "visible" : "hidden")};
`;

const BannerImage = styled.img`
  margin-bottom: 15px;
`;

const Text = styled.div`
  margin: 0px auto 17px;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  padding: 0 5px;
  width: 120px;
  color: ${props => (props.color ? `#${props.color}` : "#000000")};
  font-size: ${props => (props.fontSize ? props.fontSize : "12px")};
`;

const Button = styled.div`
  color: ${props => (props.btntextcolor ? `#${props.btntextcolor}` : "#ffffff")};
  background-color: ${props => (props.btnbackground ? `#${props.btnbackground}` : "#86c600")};
  box-sizing: border-box;
  text-align: center;
  cursor: pointer;
  max-width: 130px;
  font-size: 13px;
  font-weight: bold;
  line-height: 34px;
  border-radius: 15px;
  margin: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 10px;
`;

const PlansAdCloseButton = styled(CloseButton)`
  width: 20px;
  opacity: 0.35;
  transition: opacity 200ms ease-in-out;

  &:hover {
    opacity: 0.6;
  }
`;
