// @flow

import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";

import apiMethod from "../api/apiMethod";
import PublicFolderSettings from "../components/publicfolder/Settings";
import PublicFolderCreate from "../components/publicfolder/Create";
import { abortAll } from "../lib/XhrComponentUtils";
import DeactivatePublicFolderPage from "../components/Modals/DeactivatePublicFolderPage";

import type { Folder } from "../types/filesystem";

type PublicFolderPageProps = {
  ispremium: boolean,
  token: string
};

type PublicFolderPageState = {
  init: boolean,
  folder?: Folder,
  cancreate?: boolean,
  hasfolder?: boolean,
  directlink?: string,
  creatingFolder: boolean,
  deletingFolder: boolean,
  trialexpiretime?: string
};

class PublicFolderPage extends Component<PublicFolderPageProps, PublicFolderPageState> {
  xhr: Array<XMLHttpRequest> = [];

  constructor(props: PublicFolderPageProps) {
    super(props);

    this.state = {
      init: false,
      creatingFolder: false,
      deletingFolder: false,
      isOpen: false,
    };

    (this: any)._createPublicFolder = this._createPublicFolder.bind(this);
    (this: any)._deletePublicFolder = this._deletePublicFolder.bind(this);
    (this: any).handleCloseModal = this.handleCloseModal.bind(this);
    (this: any).handleOpenModal = this.handleOpenModal.bind(this);
  }

  componentDidMount() {
    this.xhr.push(
      apiMethod(
        "getpublicfolder",
        { auth: this.props.token },
        ret =>
          this.setState({
            init: true,
            folder: ret.metadata,
            directlink: ret.directlink,
            cancreate: ret.cancreate,
            hasfolder: ret.hasfolder,
            trialexpiretime: ret.trialexpiretime
          }),
        {
          errorCallback: ret => {
            this.setState({ init: true });
          }
        }
      )
    );
  }

  componentWillReceiveProps(newProps: PublicFolderPageProps) {
    if (newProps.ispremium && !this.state.cancreate) {
      this.setState({ cancreate: true });
    }
  }

  componentWillUnmount() {
    abortAll(this.xhr);
  }

  handleCloseModal = () => {
    this.setState({ isOpen: false })
  };

  handleOpenModal = () => {
    this.setState({ isOpen: true })
  };

  _createPublicFolder() {
    this.setState({ creatingFolder: true });

    this.xhr.push(
      apiMethod(
        "createpublicfolder",
        { auth: this.props.token },
        ret =>
          this.setState({
            folder: ret.metadata,
            directlink: ret.directlink,
            creatingFolder: false
          }),
        {
          errorCallback: ret => {
            HFN.message(ret.error, "error");
            this.setState({ creatingFolder: false });
          }
        }
      )
    );
  }

  _deletePublicFolder(confirm: boolean = false) {
    const { ispremium } = this.props;
    const { folder } = this.state;

    if (folder) {
      this.setState({ deletingFolder: true });
      this.xhr.push(
        apiMethod("deactivatepublicfolder", { auth: this.props.token }, () => {
          this.setState({ deletingFolder: false, folder: undefined, isOpen: false });

          if (!this.props.ispremium) {
            this.setState({ cancreate: false });
          }
        })
      );
    }
  }

  render() {
    const { ispremium, token } = this.props;
    const { init, folder, directlink, isOpen,  ...state } = this.state;

    return (
      <div>
        <DeactivatePublicFolderPage
          isOpen={isOpen}
          isPremium={ispremium}
          onDeactivateConfirm ={this._deletePublicFolder}
          handleClose={this.handleCloseModal}
        />
        {!init && <div className="loading" />}
        {init &&
          !!folder &&
          directlink && (
            <PublicFolderSettings
              {...state}
              token={token}
              ispremium={ispremium}
              directlink={directlink}
              folder={folder}
              onDeleteFolder={this.handleOpenModal}
            />
          )}
        {init &&
          !folder && (
            <PublicFolderCreate
              {...state}
              ispremium={ispremium}
              onCreateFolder={this._createPublicFolder}
            />
          )}
      </div>
    );
  }
}

export default connect(state => ({
  token: state.user.token,
  ispremium: state.user.userinfo.premium || state.user.userinfo.business
}))(PublicFolderPage);

const ConfirmModalBody = styled.div`
  padding: 20px;
  text-align: center;
  font-size: 13px;
  line-height: 19px;
  width: 400px;
`;
