import apiMethod from "./apiMethod";
import { __ } from "../lib/translate";
import { errorKeys } from "../lib/errors";

export default function changeuploadlink(params, options) {
  const optsApiMethod = {
    errorCallback: ({ result, error }) => {
      if (errorKeys[result]) {
        HFN.message(__(errorKeys[result]), "error");
      } else {
        console.log("ERROR", { result, error })
        HFN.message(__("something_went_wrong_refresh_and_try_again"), "error");
      }

      if (options.onError) {
        options.onError({ result, error });
      }
    },
    onTimeout: () => {
      if (options.onTimeout) {
        options.onTimeout();
      }
    }
  };

  apiMethod(
    "changeuploadlink",
    params,
    ret => {
      console.log("RET", ret)
      if (options.onSuccess) {
        options.onSuccess(ret);
      }
    },
    optsApiMethod
  );
}
