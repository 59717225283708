import { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import fileUploadService from '../services/fileUploadService';
import { v4 as uuidv4 } from 'uuid';
import { UPLOAD_STATUS, MAX_CONCURRENT_FOLDER_UPLOADS, MAX_FAILED_UPLOADS_RETRIES, UPLOAD_OPTIONS, FILE_SIZE_FOR_CHUNKS, CRYPTOLOCKED_PAGE, UPLOAD_TAB, DEFAULT_PASTE_ITEM_NAME, MAX_CONCURRENT_FILE_UPLOADS } from "../constants";
import { chunkArray, fibonacci, delay } from '../../../lib/utils';
import { getParentFolderPathFromArrayOfPaths, getUniqueFolders, uniqFilename, fileExistsCache, filterFilesByTab, shouldNotRetryForError, getFormattedPasteName } from '../helpers';


const useFileUpload = ({ opts }) => {
  const [uploadQueue, setUploadQueue] = useState([]);
  const [failedFolderError, setFailedFolderError] = useState(0);
  const [failedItems, setFailedItems] = useState([]);
  const [uploadQueueExisting, setUploadQueueExisting] = useState([]);
  const [selectedOptionMap, setSelectedOptionMap] = useState(new Map());
  const [activeTab, setActiveTab] = useState(0);
  const [filteredUploadQueue, setFilteredUploadQueue] = useState([]);
  const [tabItemsLength, setTabItemsLength] = useState({
    [UPLOAD_TAB.ALL]: 0,
    [UPLOAD_TAB.ACTIVE]: 0,
    [UPLOAD_TAB.COMPLETED]: 0,
    [UPLOAD_TAB.FAILED]: 0,
  });
  const [isUploadRemoteModalOpen, setIsUploadRemoteModalOpen] = useState(false);
  const [isUploadPasteModalOpen, setIsUploadPasteModalOpen] = useState(false);
  const [finishedFolderItemToUpdate, setFinishedFolderItemToUpdate] = useState();

  const itemsForUpload = useRef(new Map());
  const workers = useRef(new Map());
  const itemsForUploadExisting = useRef(new Map());
  const abortControllersForDrop = useRef(new Map());

  const uploadFiles = useCallback((uploadItem, foldersObject, signal) => {
    let { id, files, folderidCurrent, overwrite, renameifexists, skip, encrypted, abortController } = uploadItem;
    let activeUploadIndex = 0;
    let isAborted = false;

    // function params are for retries only
    const uploadFileWithRetry = async (attempt = 1, activeUploadIndexInRetry = undefined) => {
      const activeItemUploadIndexExists = activeUploadIndexInRetry !== undefined;
      const currentActiveUploadIndex = activeItemUploadIndexExists ? activeUploadIndexInRetry : activeUploadIndex;
      const fileObj = activeItemUploadIndexExists ? files[activeUploadIndexInRetry] : files[activeUploadIndex];

      // stop recursive calls if there are no more files
      if (!fileObj) {
        return;
      }
      if (!activeItemUploadIndexExists) {
        ++activeUploadIndex;
      }

      // check if file exists
      // if option already selected, do not check
      if (!foldersObject) {
        if (skip) {
          abortUploadAction(id);
          processItemsUpload();
          return;
        } else if (!renameifexists && !overwrite) {
          const fileExists = fileExistsCache(folderidCurrent, fileObj.name, false);
          if (fileExists) {
            handleExistingUpload(uploadItem);
            processItemsUpload();
            return
          }
        }
      }

      const folderid = foldersObject ? foldersObject[getParentFolderPathFromArrayOfPaths(fileObj.webkitRelativePath.split('/'))] : folderidCurrent;

      const onProgress = (progress, total) => {
        if (total) {
          updateFileInQueue(id, { progress, size: total });
        } else {
          updateFileInQueue(id, { progress });
        }
      };

      const onUploadComplete = (data) => {
        if (!foldersObject) {
          workers.current.delete(uploadItem.id);
          itemsForUpload.current.delete(id);
          processItemsUpload();
          if (data.name && data.size) {
            updateFileInQueue(id, { status: UPLOAD_STATUS.FADING_ACTIVE_COMPLETED, progress: 100, name: data.name, size: data.size, fileIdCreated: data.fileIdCreated });
          } else {
            updateFileInQueue(id, { status: UPLOAD_STATUS.FADING_ACTIVE_COMPLETED, progress: 100, fileIdCreated: data.fileIdCreated });
          }
          setTimeout(() => {
            updateFileInQueue(id, { status: UPLOAD_STATUS.COMPLETED });
          }, 1000);
        } else {
          if (activeUploadIndex === files.length) {
            workers.current.delete(uploadItem.id);
            itemsForUpload.current.delete(id);
            processItemsUpload();
          } else {
            uploadFileWithRetry();
          }
          incrementUploadingFolder(id);
        }
      };

      const onUploadFailed = async (delayMs, errorCode = null, shouldNotRetry = false) => {
        if (attempt >= MAX_FAILED_UPLOADS_RETRIES || shouldNotRetry || shouldNotRetryForError(errorCode)) {
          if (!foldersObject) {
            workers.current.delete(uploadItem.id);
            itemsForUpload.current.delete(id);
            processItemsUpload();
            updateFileInQueue(id, { status: UPLOAD_STATUS.FADING_ACTIVE_FAILED, progress: 100 });
            setTimeout(() => {
              updateFileInQueue(id, { status: UPLOAD_STATUS.FAILED });
            }, 1000);
          } else {
            if (activeUploadIndex === files.length) {
              workers.current.delete(uploadItem.id);
              itemsForUpload.current.delete(id);
              processItemsUpload();
            } else {
              uploadFileWithRetry();
            }
            incrementUploadingFolder(id);
          }
          if (errorCode) {
            fileObj.errorCode = errorCode;
          }
          updateFailedItems({
            id,
            files: [fileObj],
            foldersObjectFromRetry: foldersObject,
            uniqueFolders: new Set(),
            folderidCurrent,
            overwrite,
            renameifexists,
            abortController,
          });
        } else {
          ++attempt;
          const waitTime = delayMs ? delayMs : fibonacci(attempt) * 1000;
          await delay(waitTime);
          uploadFileWithRetry(attempt, currentActiveUploadIndex);
        }
      };

      const onUploadAborted = () => {
        if (isAborted) {
          return;
        }
        isAborted = true;
        itemsForUpload.current.delete(id);
        workers.current.delete(id);
        processItemsUpload();
      }

      if (folderid === false) {
        onUploadFailed(100);
        return;
      }

      const optsFileUpload = { auth: opts.auth, nopartial: 1, folderid, overwrite, renameifexists };

      if (HFN.config.isDlink()) {
        const url = window.location.href;
        const urlObj = new URL(url);
        optsFileUpload.code = urlObj.searchParams.get('code');
      }

      //  else if (fileObj.size > FILE_SIZE_FOR_CHUNKS && !isDlink) {
      //   fileUploadService.uploadFileToServerChunks(fileObj, onProgress, onUploadComplete, onUploadFailed, optsFileUpload, signal, !foldersObject, onUploadaed);
      // }
      if (encrypted) {
        fileUploadService.cryptoUpload(fileObj, onProgress, onUploadComplete, onUploadFailed, optsFileUpload, signal, !foldersObject, onUploadAborted);
      } else {
        fileUploadService.uploadFileToServer(fileObj, onProgress, onUploadComplete, onUploadFailed, optsFileUpload, signal, !foldersObject, onUploadAborted);
      }
    };

    // start uploading 2 files
    uploadFileWithRetry();
  }, []);

  const ensureFoldersExist = useCallback(async (uploadItem) => {
    let { uniqueFolders, id, files, folderidCurrent, overwrite, renameifexists, skip, encrypted, foldersObjectFromRetry, abortController } = uploadItem;
    const { signal } = abortController;
    const signalFromDrop = abortControllersForDrop.current.get(id);
    if (signalFromDrop?.signal?.aborted || signal?.aborted) {
      workers.current.delete(id);
      itemsForUpload.current.delete(id);
      abortControllersForDrop.current.delete(id);
      processItemsUpload();
      return;
    };
    let foldersObject = foldersObjectFromRetry || undefined;
    let uploadedFoldersCount = 0;
    if (uniqueFolders.size !== 0 && !foldersObject) {
      // check if folder exists
      // if option is already selected, do not check
      if (skip) {
        processItemsUpload();
        return;
      } else if (!renameifexists && !overwrite) {
        const folderExists = fileExistsCache(folderidCurrent, [...uniqueFolders][0], true);
        if (folderExists) {
          handleExistingUpload(uploadItem);
          processItemsUpload();
          return;
        }
      }

      foldersObject = {};
      const foldersGroupedByDepth = [];

      uniqueFolders.forEach(folder => {
        const depth = (folder.match(/\//g) || []).length; // Count slashes to determine depth
        if (!foldersGroupedByDepth[depth]) {
          foldersGroupedByDepth[depth] = [];
        }
        foldersGroupedByDepth[depth].push(folder);
      });
      for (const [depth, currertFoldersByDepth] of foldersGroupedByDepth.entries()) {
        const folderChunks = chunkArray(currertFoldersByDepth, MAX_CONCURRENT_FOLDER_UPLOADS);
        let folderName = '';
        for (const folderChunk of folderChunks) {
          let resUploadPromises, parentFolderPath;
          for (let attempt = 1; attempt <= MAX_FAILED_UPLOADS_RETRIES; attempt++) {
            if (signal?.aborted) {
              workers.current.delete(id);
              itemsForUpload.current.delete(id);
              processItemsUpload();
              return;
            };
            try {
              const uploadPromises = folderChunk.map(folder => {
                let folderPaths = folder.split("/");
                folderName = depth === 0 && renameifexists ? uniqFilename(currertFoldersByDepth[0], folderidCurrent, true) : folderPaths[folderPaths.length - 1];
                parentFolderPath = getParentFolderPathFromArrayOfPaths(folderPaths);
                return foldersObject[parentFolderPath] === false ?
                  Promise.reject(false) :
                  fileUploadService.getOrCreateFolder(folderName, foldersObject[parentFolderPath] || folderidCurrent, encrypted, setFailedFolderError);
              });
              resUploadPromises = await Promise.all(uploadPromises);
              break;
            } catch (error) {
              if (attempt >= MAX_FAILED_UPLOADS_RETRIES || error === false) {
                if (error === false) {
                  const waitTime = 100;
                  await delay(waitTime);
                }
                updateFailedItems({ id, uniqueFolders: new Set(folderChunk), files: [], foldersObjectFromRetry: foldersObject, folderidCurrent, renameifexists, overwrite, abortController });
                break;
              } else {
                const waitTime = fibonacci(attempt + 1) * 1000;
                await delay(waitTime);
              }
            }
          }
          folderChunk.forEach((folder, index) => {
            foldersObject[folder] = resUploadPromises ? resUploadPromises[index] : false;
          })
          uploadedFoldersCount += folderChunk.length;
          if (depth === 0) {
            updateFileInQueue(id, { progress: uploadedFoldersCount, folderidCreated: foldersObject[currertFoldersByDepth[0]] });
          } else {
            updateFileInQueue(id, { progress: uploadedFoldersCount });
          }
        }
      }
    }
    if (files.length) {
      uploadFiles(uploadItem, foldersObject, signal);
    } else {

      processItemsUpload();
      updateFileInQueue(id, { status: UPLOAD_STATUS.FADING_ACTIVE_COMPLETED });
      setTimeout(() => {
        updateFileInQueue(id, { status: UPLOAD_STATUS.COMPLETED });
      }, 1000);
    }
  }, [])
  // Process and ensure existence of all folders before file uploads
  const processItemsUpload = useCallback(async (items) => {
    if (items) {
      items.forEach(item => {
        if (item) {
          itemsForUpload.current.set(item.id, item);
        }
      })
    }

    const processedItems = new Set();
    while (workers.current.size < MAX_CONCURRENT_FILE_UPLOADS && itemsForUpload.current.size > 0) {
      const nextEntry = Array.from(itemsForUpload.current.entries()).find(
        ([id, item]) => !workers.current.has(id)
      );

      if (nextEntry) {
        const [id, item] = nextEntry;
        workers.current.set(id, item);
        processedItems.add(id);  // Mark the item as processed
        ensureFoldersExist(item);
      } else {
        return;
      }
    }
  }, []);

  const updateFileInQueue = (id, updates) => {
    setUploadQueue(currentQueue => {
      const index = currentQueue.findIndex(item => item.id === id);
      if (index !== -1) {
        const newQueue = [...currentQueue];
        newQueue[index] = { ...newQueue[index], ...updates };
        return newQueue;
      }
      return [...currentQueue];
    }
    );
  };

  // for folders
  const incrementUploadingFolder = (id) => {
    setUploadQueue(currentQueue => {
      const newQueue = JSON.parse(JSON.stringify(currentQueue));
      const upload = newQueue.find((item) => item.id === id);
      if (upload) {
        if (upload.progress !== upload.size) {
          upload.progress = upload.progress + 1;
        }

        if (upload.progress === upload.size) {
          setFinishedFolderItemToUpdate(id);
        }
      }
      return newQueue;
    });

  }


  const updateFailedItems = (item) => {
    const { id, files, uniqueFolders } = item;
    setFailedItems(currentItems => {
      const index = currentItems.findIndex(item => item.id === id);
      if (index === -1) {
        currentItems.push(item);
      } else {
        currentItems[index] =
        {
          ...currentItems[index],
          files: [...currentItems[index].files, ...files],
          uniqueFolders: new Set([...currentItems[index].uniqueFolders, ...uniqueFolders])
        };
      }
      return [...currentItems];
    }
    );
  };

  const abortUploadAction = (id) => {
    const abortController = itemsForUpload.current.get(id)?.abortController;
    setUploadQueue((prev) => prev.filter((item) => item.id !== id));
    itemsForUpload.current.delete(id);
    if (abortController) {
      abortController.abort();
    }
    const abortControllerForDrop = abortControllersForDrop.current.get(id);
    if (abortControllerForDrop) {
      abortControllerForDrop.abort();
    }
  };

  const handleExistingUpload = (uploadItem) => {
    const { id } = uploadItem;

    setUploadQueue((prev) => {
      const filteredQueue = prev.filter((item) => {
        if (item.id === id) {
          itemsForUpload.current.delete(id);
          workers.current.delete(id);
          itemsForUploadExisting.current.set(id, uploadItem)
          setUploadQueueExisting((prevExisting) => [...prevExisting, ...[item]]);
          return false;
        }
        return true;
      });
      return filteredQueue;
    });
  };

  const handleRetryAction = async (id) => {
    const currentItemInQueue = uploadQueue.find((item) => item.id === id);
    let failedItem;
    updateFileInQueue(id, { status: UPLOAD_STATUS.FADING_FAILED_RETRY });
    setTimeout(() => {
      setFailedItems((prev) => {
        failedItem = failedItems.find((item) => item.id === id);
        let progress = currentItemInQueue.isFile ? 0 : currentItemInQueue.size - (failedItem.uniqueFolders.size + failedItem.files.length);
        const filteredFailedItems = failedItems.filter((item) => item.id !== id);
        updateFileInQueue(id, { status: UPLOAD_STATUS.PENDING, progress });
        return [...filteredFailedItems];
      })
      processItemsUpload([failedItem]);
    }, 1000)
  }

  const cancelUpload = () => {
    const pendingUploads = uploadQueue.filter((item) => item.status === UPLOAD_STATUS.PENDING);
    const pendingIds = pendingUploads.map((item) => item.id);

    // Collect abort controllers before clearing itemsForUpload
    const abortControllers = pendingIds.map(id => itemsForUpload.current.get(id)?.abortController).filter(controller => controller);

    // Clear the uploadQueue
    setUploadQueue((prev) => prev.filter((item) => !pendingIds.includes(item.id)));

    // Clear itemsForUpload before aborting
    pendingIds.reverse().forEach(id => {
      itemsForUpload.current.delete(id);
    });

    // Abort each pending upload
    abortControllers.forEach(controller => {
      controller.abort();
    });
    abortControllersForDrop.current.forEach(controller => {
      controller.abort();
    });
  };

  const closeUpload = () => {
    const pendingUploads = uploadQueue.filter((item) => item.status === UPLOAD_STATUS.PENDING);
    const pendingIds = pendingUploads.map((item) => item.id);

    // Collect abort controllers before clearing itemsForUpload
    const abortControllers = pendingIds.map(id => itemsForUpload.current.get(id)?.abortController).filter(controller => controller);

    // Clear the uploadQueue
    setUploadQueue([]);

    // Clear itemsForUpload before aborting
    itemsForUpload.current.clear();

    // Abort each pending upload
    abortControllers.forEach(controller => {
      controller.abort();
    });
    abortControllersForDrop.current.forEach(controller => {
      controller.abort();
    });
  };

  // upload starts here
  // Process and start uploading files with concurrency control
  const initializeUploads = async (data, remoteUrl) => {
    let folderidCurrent = $.bbq.getState('folder') || currentFolder || 0;
    if (!data && !remoteUrl) {
      return
    } else if (remoteUrl) {
      updateUploadQueueItemsFromRemoteUrl(remoteUrl, folderidCurrent);
      return;
    }

    const { items, isDrop, isRemoteUpload, pasteItemUrl } = data;

    if (pasteItemUrl) {
      // paste has only 1 item, if default item generate name
      setIsUploadPasteModalOpen({ items, pasteItemUrl });
      return;
    }

    if (isRemoteUpload) {
      setIsUploadRemoteModalOpen(true);
      return;
    }

    const encrypted = opts.encrypted;

    if (encrypted && !folderidCurrent) {
      folderidCurrent = (await fileUploadService.findCryptoFolder()).folderid;
    }

    // handle drag/drop and button cases
    if (isDrop) {
      updateUploadQueueItemsFromDrop(items, folderidCurrent, encrypted);
    } else {
      if (!items?.length) return;
      updateUploadQueueItemsFromButton(items, folderidCurrent, encrypted);
    }
  };

  const getFilesDataTransferItems = useCallback((dataTransferItemEntry, id) => {
    let files = [], folders = new Set();
    const traverseFileTreePromise = (item) => {
      const signalFromDrop = abortControllersForDrop.current.get(id);
      if (signalFromDrop?.signal?.aborted) {
        return;
      }
      return new Promise(resolve => {
        if (item.isFile) {
          item.file(file => {
            Object.defineProperty(file, 'webkitRelativePath', {
              value: item.fullPath.substring(1),
              writable: false
            });
            files.push(file);
            resolve(file);
          }, _ => resolve());
        } else if (item.isDirectory) {
          folders.add(item.fullPath.substring(1));
          let dirReader = item.createReader();
          readAllEntries(dirReader).then(entries => {
            let entriesPromises = entries.map(entry => traverseFileTreePromise(entry));
            resolve(Promise.all(entriesPromises))
          })
        }
      });
    }

    const readAllEntries = (dirReader, allEntries = []) => {
      return new Promise((resolve, reject) => {
        dirReader.readEntries(entries => {
          if (entries.length === 0) {
            resolve(allEntries); // No more entries, resolve the promise with all collected entries
          } else {
            allEntries = [...allEntries, ...entries]; // Add the new entries to the array
            readAllEntries(dirReader, allEntries).then(resolve).catch(reject); // Recursively read more entries
          }
        });
      });
    }

    return new Promise((resolve, reject) => {
      let entriesPromises = [];
      entriesPromises.push(
        traverseFileTreePromise(dataTransferItemEntry)
      );
      Promise.all(entriesPromises).then(() => {
        resolve([files, folders]);
      });
    });
  }, [])

  const updateUploadQueueItemsFromDrop = async (dataTransfer, folderidCurrent, encrypted) => {
    // extract dataTransfer
    const { files, items } = dataTransfer, formattedFiles = [];
    const optionsModalId = uuidv4();
    const dataTransferItemEntries = [];
    // handle initial drop state
    for (let i = 0; i < files.length; ++i) {
      const entry = items[i].webkitGetAsEntry();
      if (entry) {
        const id = uuidv4();
        const isFile = items[i].webkitGetAsEntry()?.isFile;
        formattedFiles.push({
          name: files[i].name,
          status: UPLOAD_STATUS.PENDING,
          progress: 0,
          isFile,
          size: isFile ? files[i].size : undefined,
          id,
          folderidCurrent,
          optionsModalId,
          encrypted,
        });
        const abortController = new AbortController();
        abortControllersForDrop.current.set(id, abortController);
        dataTransferItemEntries.push(entry);
      }
    }

    setUploadQueue((prev) => [...formattedFiles, ...prev]);

    const formattedItemsForUpload = [];
    const chunks = chunkArray(formattedFiles, 2);

    for (let chunkIndex = 0; chunkIndex < chunks.length; chunkIndex++) {
      // size for folders
      const formattedFilesItemsLength = Array(chunks[chunkIndex].length);
      // extract files and folders from drop
      await Promise.all(chunks[chunkIndex].map(async (file, relativeIndex) => {
        const originalIndex = chunkIndex * 2 + relativeIndex;
        const [actualFiles, folders] = await getFilesDataTransferItems(dataTransferItemEntries[originalIndex], file.id);
        let totalFilesSize = 0;
        if (!file.isFile) {
          actualFiles.forEach((file) => {
            totalFilesSize += file.size;
          })
          totalFilesSize += folders.size * 1024;
        }
        formattedFilesItemsLength[relativeIndex] = { id: file.id, size: folders.size + actualFiles.length, totalFilesSize };
        formattedItemsForUpload.push({
          id: file.id, files: file.isFile ? [files[originalIndex]] : actualFiles, uniqueFolders: folders, folderidCurrent, optionsModalId, renameifexists: 0, overwrite: 0, skip: 0, encrypted, abortController: abortControllersForDrop.current.get(file.id),
        });
      }));

      // set folders length
      setUploadQueue(prev => {
        formattedFilesItemsLength.forEach((itemLength) => {
          prev.forEach((prevItem, index) => {
            if (!prevItem.isFile && prevItem.id === itemLength.id) {
              prev[index].size = itemLength.size;
              prev[index].totalFilesSize = itemLength.totalFilesSize;
            }
          })
        });
        return [...prev];
      });
    }
    processItemsUpload(formattedItemsForUpload);
  }

  const updateUploadQueueItemsFromButton = async (items, folderidCurrent, encrypted) => {
    const isFile = items[0].webkitRelativePath === "";
    const uniqueFolders = isFile ? new Set() : getUniqueFolders(items);
    const optionsModalId = uuidv4();
    const formattedFiles = [];
    const formattedItemsForUpload = [];

    let abortController;
    if (isFile) {
      // handle multiple files
      Array.from(items).forEach((item, index) => {
        abortController = new AbortController();
        const id = uuidv4();
        formattedFiles.push({
          name: item.name,
          status: UPLOAD_STATUS.PENDING,
          progress: 0,
          isFile: true,
          size: item.size,
          id,
          optionsModalId,
          folderidCurrent,
          encrypted,
        });
        formattedItemsForUpload.push({ files: [item], uniqueFolders, id, folderidCurrent, optionsModalId, renameifexists: 0, overwrite: 0, encrypted, abortController });
      })
    } else {
      // handle single folder - multiple not supported via button, only drop
      const id = uuidv4();
      abortController = new AbortController();
      const folderName = items[0].webkitRelativePath.split('/')[0];
      let totalFilesSize = 0;
      Array.from(items).forEach((item) => {
        totalFilesSize += item.size;
      })
      totalFilesSize += uniqueFolders.size * 1024
      formattedFiles.push({
        name: folderName,
        status: UPLOAD_STATUS.PENDING,
        progress: 0,
        isFile: false,
        size: uniqueFolders.size + items.length,
        id,
        optionsModalId,
        folderidCurrent,
        encrypted,
        totalFilesSize,
      });
      formattedItemsForUpload.push({ files: items, id, uniqueFolders, folderidCurrent, optionsModalId, renameifexists: 0, overwrite: 0, encrypted, abortController });
    }

    setUploadQueue((prev) => [...formattedFiles, ...prev]);
    processItemsUpload(formattedItemsForUpload);
  }

  const updateUploadQueueItemsFromRemoteUrl = (remoteUrl, folderidCurrent) => {
    if (remoteUrl.length < 5)
      return;
    let m = false, formattedFiles = [];
    const formattedItemsForUpload = [];
    const optionsModalId = uuidv4();

    if (m = remoteUrl.match(/"name: ([^"]+)+"/)) {
      remoteUrl = remoteUrl.replace(m[0], '');
    }

    const abortController = new AbortController();
    const id = uuidv4();
    formattedFiles.push({
      name: '',
      status: UPLOAD_STATUS.PENDING,
      progress: 0,
      isFile: true,
      size: undefined,
      id,
      optionsModalId,
      folderidCurrent,
      url: remoteUrl,
    });

    formattedItemsForUpload.push({ files: [{ url: remoteUrl }], uniqueFolders: new Set(), id, folderidCurrent, optionsModalId, renameifexists: 0, overwrite: 0, abortController });

    setUploadQueue((prev) => [...formattedFiles, ...prev]);
    processItemsUpload(formattedItemsForUpload);
  }

  const donePercentage = useMemo(() => {
    let doneItems = 0, failedItems = 0, pendingItems = 0;
    uploadQueue.forEach((item) => {
      if (item.status === UPLOAD_STATUS.COMPLETED ||
        item.status === UPLOAD_STATUS.FAILED ||
        item.status === UPLOAD_STATUS.FADING_ACTIVE_COMPLETED ||
        item.status === UPLOAD_STATUS.FADING_ACTIVE_FAILED) {
        ++doneItems;
        if (item.status === UPLOAD_STATUS.FAILED ||
          item.status === UPLOAD_STATUS.FADING_ACTIVE_FAILED) {
          ++failedItems;
        }
      } else if (item.status === UPLOAD_STATUS.PENDING) {
        ++pendingItems;
      }
    })
    const percentage = Math.round((doneItems / uploadQueue.length) * 100);
    const isUploading = percentage !== 100 && uploadQueue.length > 0;
    return { isUploading, failedItems, pendingItems };
  }, [uploadQueue])

  const handleUploadOptionsModalClose = (id) => {
    setUploadQueueExisting(prev => prev.slice(1));
    itemsForUploadExisting.current.delete(id);
    processItemsUpload();
  }

  const handleUploadOptionsModalYesClick = (selectedValueExistingItems, shouldApplyForAll, id, optionsModalId) => {
    const overwrite = selectedValueExistingItems === UPLOAD_OPTIONS.REPLACE;
    const renameifexists = selectedValueExistingItems === UPLOAD_OPTIONS.DUPLICATE;
    const skip = selectedValueExistingItems === UPLOAD_OPTIONS.SKIP;
    if (!shouldApplyForAll) {
      setUploadQueueExisting(prev => {
        let uniqName;
        let item = prev.find((prevItem) => prevItem.id === id);
        const isFile = item.isFile;
        if (renameifexists) {
          uniqName = uniqFilename(item.name, item.folderidCurrent, !isFile);
          item.name = uniqName;
        } else if (skip) {
          item = undefined;
        }
        if (item) {
          setUploadQueue((prevUploadQueue) => [...[item], ...prevUploadQueue]);
        }

        let itemForUpload = itemsForUploadExisting.current.get(id);

        if (overwrite) {
          itemForUpload.overwrite = 1;
        } else if (renameifexists) {
          itemForUpload.renameifexists = 1;
        } else if (skip) {
          itemForUpload = undefined;
        }
        itemsForUploadExisting.current.delete(id);
        processItemsUpload([itemForUpload]);

        return prev.filter((prevItem) => prevItem.id !== id);
      });
    } else {
      setSelectedOptionMap((prev) => {
        const newMap = new Map(prev);
        newMap.set(optionsModalId, selectedValueExistingItems);
        return newMap;
      });
      setUploadQueueExisting((prev) => {
        const prevUploadQueueExisting = prev.map((prevItem, index) => {
          if (renameifexists) {
            prevItem.renameifexists = 1;
            const isFile = prevItem.isFile;
            const uniqName = uniqFilename(prevItem.name, prevItem.folderidCurrent, !isFile);
            prevItem.name = uniqName;
            return prevItem;
          } else if (skip) {
            return undefined;
          } else if (overwrite) {
            prevItem.overwrite = 1;
            return prevItem;
          }
          return prevItem;
        }).filter((item) => item !== undefined);

        setUploadQueue((prevUploadQueue) => [...prevUploadQueueExisting, ...prevUploadQueue]);

        const itemsForUploadArr = [];
        itemsForUploadExisting.current.forEach((item) => {
          if (overwrite) {
            item.overwrite = 1;
            itemsForUploadArr.push(item);
          } else if (renameifexists) {
            item.renameifexists = 1;
            itemsForUploadArr.push(item);
          }
          itemsForUploadExisting.current.delete(item.id);
        })
        processItemsUpload(itemsForUploadArr);
        return [];
      });
    }
  }

  const handleClear = useCallback(() => {
    setUploadQueue((prev) => activeTab === UPLOAD_TAB.COMPLETED ? prev.filter((item) => item.status !== UPLOAD_STATUS.COMPLETED) : []);
    setFailedItems([]);
  }, [activeTab])

  const handleRetryAll = () => {
    setUploadQueue((prev) => prev.map((item) => {
      if (item.status === UPLOAD_STATUS.FAILED) {
        item.status = UPLOAD_STATUS.FADING_FAILED_RETRY
      }
      return item;
    }));
    setTimeout(() => {
      const failedItemsForUpload = [];
      setFailedItems((prevFaileditems) => {
        setUploadQueue((prev) => {
          prev = prev.map((item) => {
            if (item.status === UPLOAD_STATUS.FADING_FAILED_RETRY) {
              const failedItem = failedItems.find((failedItemCurrent) => failedItemCurrent.id === item.id);
              failedItemsForUpload.current.push(failedItem);
              let progress = item.isFile ? 0 : item.size - (failedItem.uniqueFolders.size + failedItem.files.length);
              item.status = UPLOAD_STATUS.PENDING
              item.progress = progress
            }
            return item;
          })
          return [...prev]
        })
        return [];
      });
      processItemsUpload([...failedItemsForUpload]);
    }, 1000)
  }

  // Option is already selected
  useEffect(() => {
    const option = selectedOptionMap.get(uploadQueueExisting[0]?.optionsModalId);
    if (option) {
      handleUploadOptionsModalYesClick(option, false, uploadQueueExisting[0].id, uploadQueueExisting[0].optionsModalId);
    }
  }, [uploadQueueExisting]);

  useEffect(() => {
    const { filteredItems, tabItemsLength } = filterFilesByTab(uploadQueue, activeTab);
    setFilteredUploadQueue([...filteredItems]);
    setTabItemsLength(tabItemsLength);
  }, [activeTab, uploadQueue]);

  // handle beforeunload if upload is running
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (donePercentage.isUploading) {
        const message = 'An upload is in progress. Are you sure you want to leave?';
        event.returnValue = message;
        return message;
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [donePercentage.isUploading]);

  useEffect(() => {
    if (finishedFolderItemToUpdate) {
      setFinishedFolderItemToUpdate(undefined);
      let failedItem = undefined;
      setUploadQueue(currentQueue => {
        const upload = currentQueue.find((item) => item.id === finishedFolderItemToUpdate);
        if (upload) {
          failedItem = failedItems.find((item) => item.id === finishedFolderItemToUpdate);
          upload.status = failedItem ? UPLOAD_STATUS.FADING_ACTIVE_FAILED : UPLOAD_STATUS.FADING_ACTIVE_COMPLETED;
        }
        return [...currentQueue];
      });

      setTimeout(() => {
        setUploadQueue(currentQueue => {
          const upload = currentQueue.find((item) => item.id === finishedFolderItemToUpdate);
          if (upload) {
            upload.status = failedItem ? UPLOAD_STATUS.FAILED : UPLOAD_STATUS.COMPLETED;
          }
          return [...currentQueue];
        });
      }, 1000)
    }

  }, [finishedFolderItemToUpdate]);

  const handleUploadRemoteModalClose = () => setIsUploadRemoteModalOpen(false);

  const handleUploadRemoteModalYesClick = (remoteUrl) => {
    handleUploadRemoteModalClose();
    initializeUploads(undefined, remoteUrl);
  }

  const handleUploadPasteModalClose = () => setIsUploadPasteModalOpen(false);

  const handleUploadPasteModalYesClick = (items) => {
    handleUploadPasteModalClose();
    initializeUploads({ items, isDrop: false });
  }

  return {
    initializeUploads,
    uploadQueue,
    handleRetryAction,
    donePercentage,
    cancelUpload,
    closeUpload,
    failedItems,
    abortUploadAction,
    uploadQueueExisting,
    handleUploadOptionsModalClose,
    handleUploadOptionsModalYesClick,
    activeTab,
    setActiveTab,
    failedFolderError,
    setFailedFolderError,
    filteredUploadQueue,
    handleClear,
    handleRetryAll,
    tabItemsLength,

    isUploadRemoteModalOpen,
    handleUploadRemoteModalClose,
    handleUploadRemoteModalYesClick,

    isUploadPasteModalOpen,
    handleUploadPasteModalClose,
    handleUploadPasteModalYesClick,
  };
};

export default useFileUpload;