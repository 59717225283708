import React from "react";
import apiMethod from "../api/apiMethod";
import styled, { keyframes } from "styled-components";
import { hiDPI } from "polished";
import Button, { ButtonCentered } from "./ButtonDefault";
import Modal from "./Modal";
import { __ } from "../lib/translate";
import store from "../lib/state/store";
import { connect } from "react-redux";
import { URLMY } from "@pcloud/web-utilities/dist/config";

export function ThankYou(props) {
    return (
      <Modal opened={props.showThankyou} onClose={props.closeThankyou}>
        <ThankYouContainer>
          <Header>
            <Title>{__("privacy_popup_thankyou_title", "Thank you!")}</Title>
            <Description>
              {__(
                "privacy_popup_thankyou_message",
                "As always with pCloud your data is safe and private."
              )}
            </Description>
          </Header>
          <CloseButton onClick={props.closeThankyou} />
        </ThankYouContainer>
      </Modal>
    )
}

export class NewPrivacyPolicyModal extends React.Component {
  loadPrivacyPolicyData() {
    // apiMethod(
    //   "privacypolicy.html",
    //   {},
    //   data => (document.querySelector(".privacyPolicy").innerHTML = data),
    //   {
    //     apiServer: URLMY,
    //     dataType: "text"
    //   }
    // );
    const article = HFN.config.label.hasTerms();
    const regex = /-(.*).json/;
    const match = regex.exec(article);
    const language = match ? match[1] : "";

    $.getJSON(article, function (ret) {
      const privacyPolicy = language ? `privacy_policy_${language}` : 'privacy_policy';
			document.querySelector(".privacyPolicy").innerHTML = ret[privacyPolicy].content;
    });
    // apiMethod(
    //   HFN.config.label.hasTerms.slice(1, HFN.config.label.hasTerms.length),
    //   {},
    //   data => {
    //     (document.querySelector(".privacyPolicy").innerHTML = data.privacy_policy.content);
    //   },
    //   {
    //     apiServer: URLMY.slice(0, URLMY.length-1)
    //   }
    // );
  }

  componentDidMount() {
    this.loadPrivacyPolicyData();
  }

  render() {
    const screenHeight = window.innerHeight;
    const modalHeight = 0.85 * screenHeight;
    const mobileModalHeight = screenHeight - 20;
    const scrollContainerHeight = modalHeight - 235;
    const mobileScrollHeight = screenHeight - 270;
    const isMobile = window.innerWidth < 500;

    return (
      <Modal opened={this.props.openedModal} onClose={this.props.closeModal}>
        <Container
          height={modalHeight + "px"}
          heightm={mobileModalHeight + "px"}
        >
          <Header>
            <Title>
              {__("privacy_popup_title", "We've updated our privacy policy!")}
            </Title>
            <Description>
              {__(
                "privacy_popup_description",
                "Our revised policy explains what data we collect, what for and what options you have regarding your personal information."
              )}
            </Description>
          </Header>
          <hr />
          <ScrollContainer
            className="privacyPolicy"
            height={scrollContainerHeight + "px"}
            mobileh={mobileScrollHeight + "px"}
          >
            <LoaderWrapper>
              <div />
            </LoaderWrapper>
          </ScrollContainer>
          {isMobile ? (
            <Footer>
              <ConfirmButton left onClick={this.props.closeModal}>{__("Decline")}</ConfirmButton>
              <ConfirmButton right onClick={this.props.acceptPrivacyPolicy}>
                {__("I Accept")}
              </ConfirmButton>
            </Footer>
          ) : (
            <div>
              <hr />
              <Footer>
                <Instructions>
                  {__(
                    "privacy_popup_footer",
                    "We ask you to review our Privacy Policy carefully, as these changes may be important to you. If you continue using pCloud, you do so under this policy."
                  )}
                </Instructions>
                <ButtonCentered
                  style={{ width: "200px" }}
                  onClick={this.props.acceptPrivacyPolicy}
                  loading={this.props.loading}
                >
                  {__("I Accept")}
                </ButtonCentered>
              </Footer>
            </div>
          )}
        </Container>
      </Modal>
    );
  }
}

export function BottomPrivacyWarning(props){
  return (
    <BlackBar>
      <div>
        <span>
          {__(
            "privacy_popup_important",
            "Important changes to our Privacy Policy."
          )}{" "}
        </span>
        <span className="privacy_policy link" onClick={props.openModal}>
          {__("privacy_popup_review", "Click to review.")}
        </span>
      </div>
    </BlackBar>
  );
}

const Container = styled.div`
  position: relative;
  display: inline-block;
  min-height: ${props => props.height};
  max-width: 780px;
  margin: 10px;
  background: #fff;
  border-radius: 10px;
  text-align: left;
  box-sizing: border-box;
  @media (max-width: 500px) {
    position: absolute;
    top: 0px;
    left: 0px;
    height: ${props => props.heightm};
  }
`;

const ScrollContainer = styled.div`
  height: ${props => props.height};
  overflow-y: scroll;
  overflow-x: hidden;
  line-height: 1.5;
  padding: 0px 35px;
  & h3 {
    font-size: 15px;
    margin: 15px 0px 15px 0px;
    &:first-of-type {
      margin-top: 0px;
    }
  }
  & h4 {
    font-size: 14px;
    margin: 15px 0px;
  }
  & ul {
    padding-left: 16px;
  }
  & li {
    font-size: 13px;
  }
  & p {
    font-size: 13px;
    margin: 8px 0px;
  }
  & a {
    text-decoration: none;
    color: #17bed0;
  }
  @media (max-width: 500px) {
    height: ${props => props.mobileh};
    padding: 20px;
  }
`;

const Header = styled.div`
  text-align: center;
  padding: 20px 35px 20px 35px;
  @media (max-width: 500px) {
    padding: 20px;
  }
`;
const Title = styled.div`
  font-weight: bold;
  font-size: 16px;
  margin: 5px 0px;
`;
const Description = styled.div`
  margin: 5px 0px;
  font-size: 13px;
`;

const Footer = styled.div`
  text-align: center;
  padding: 25px 35px;
  margin-top: 10px;
`;
const Instructions = styled.div`
  margin-bottom: 20px;
  font-size: 13px;
  font-weight: bold;
  line-height: 1.3;
`;

const ConfirmButton = styled.div`
  width: ${(window.innerWidth - 20) / 2 - 1.5 + "px"};
  position: absolute;
  bottom: 0px;
  border: 1px solid #eaeaea;
  height: 68px;
  text-align: center;
  font-size: 18px;
  line-height: 68px;
  color: #17bed0;
  cursor: pointer;
  ${props => {
    if (props.left)
      return `left: 0px;
      border-bottom-left-radius: 10px;`;
    if (props.right)
      return `right: 0px;
      font-weight: bold;
      border-bottom-right-radius: 10px;`;
  }};
`;

const spin = keyframes`
    0% {
        transform:rotate(0deg);
    }
    100% {
        transform:rotate(360deg);
    }
`;

const LoaderWrapper = styled.div`
  position: relative;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  & div {
    animation-name: ${spin};
    animation-duration: 600ms;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 48px;
    height: 48px;
    margin: -8px 0 0 -24px;
    z-index: 100;
    background: url(${require("../../root/img/loading_blue.png")}) no-repeat 0 0
      transparent;
    background-size: cover;
  }
`;

const ThankYouContainer = styled.div`
  position: relative;
  display: inline-block;
  min-height: ${props => props.height};
  max-width: 780px;
  margin: 10px;
  background: #fff;
  border-radius: 10px;
  text-align: left;
  box-sizing: border-box;
  padding: 25px 50px;
  @media (max-width: 500px) {
    padding: 25px 2px;
    vertical-align: middle;
  }
`;

const CloseButton = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  background-repeat: no-repeat;
  width: 25px;
  height: 25px;
  margin: 10px;
  margin: 16px;
  background-position: center;
  background-size: cover;
  cursor: pointer;

  background-image: url(${require("../../root/img/plansmodal/close.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../root/img/plansmodal/close@2x.png")});
  }

  &:hover {
    opacity: 0.8;
  }
`;

const BlackBar = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0px;
  left: 0px;
  background: rgb(74, 74, 74);
  padding: 22px 5px;
  text-align: center;
  color: #fff;
  font-size: 12px;
  box-sizing: border-box;
  & .privacy_policy.link {
    color: #17bed0;
    cursor: pointer;
  }
  @media (max-width: 500px) {
    font-size: 15px;
  }
`;
