import React, {useRef} from "react";
import styled from "styled-components";
import {createPortal} from "react-dom";
import {motion} from "framer-motion";
import type {PlaceTypes} from "../index";
import useOnboardingPosition from "../hooks/useOnboardingPosition";
import {isInFixedContainer} from "../../../lib";
import PopupContentManager from "./PopupContentManager";

type Props = {
    show: boolean,
    showOverlay: boolean,
    onClose: () => void,
    content: Array<any>,
    readMore?: string,
    parentRef: any,
    place: PlaceTypes,
    container: HTMLElement
};

const PopupContainer = ({onClose, parentRef, content, showOverlay, show, place, readMore, container}: Props) => {
    const popupRef = useRef(null);
    const {
        positionData,
        positionClass
    } = useOnboardingPosition({show, parentRef, place, popupRef});

    const handleOutsideClick = () => {
        onClose();
    }

    const hasFixedPosition = isInFixedContainer(parentRef.current);

    return (
        createPortal(
            <>
                {showOverlay && (
                    <Overlay
                        onMouseDown={handleOutsideClick}

                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{duration: 0.2}}
                    />
                )}
                <PopupWrapper
                    $hasFixedPosition={hasFixedPosition}
                    top={positionData.top}
                    left={positionData.left}
                    bottom={positionData.bottom}
                    right={positionData.right}

                    initial={{ opacity: 0, scale: 0 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0 }}
                    transition={{duration: 0.2}}
                >
                    <ContentWrapper
                        className={positionClass}
                        $arrowLeft={positionData.arrowLeft}
                        ref={popupRef}
                    >
                        <PopupContentManager content={content} readMore={readMore} onClose={onClose} />
                    </ContentWrapper>
                </PopupWrapper>
            </>,
            container || document.body
        )
    );
}

export default PopupContainer;

const Overlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.35);
  text-align: center;
  z-index: 1900;
  overflow-y: auto;
`;

const PopupWrapper = styled(motion.div)`
    display: inline-block;
    margin: 0;
    position: ${props => (props.$hasFixedPosition ? "fixed" : `absolute`)};
    top: ${props => (props.top ? props.top + "px" : `auto`)};
    left: ${props => (props.left ? props.left + "px" : `auto`)};
    bottom: ${props => (props.bottom ? props.bottom + "px" : `auto`)};
    right: ${props => (props.right ? props.right + "px" : `auto`)};
    z-index: 1998;
`;

const ContentWrapper = styled.div`
    position: relative;
    padding: 16px;
    box-sizing: border-box;
    background:  #fff;
    border-radius: 20px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.10);
    border: 1px solid #CDCDCD;
    min-width: 280px;

    &:before {
        content: '';
        display: block;
        position: absolute;
        width: 0;
        height: 0;
        border: 13px solid transparent;
    }

    &:after {
        content: '';
        display: block;
        position: absolute;
        width: 0;
        height: 0;
        border: 12px solid transparent;
    }
    
    &.top-mobile,
    &.bottom-mobile {
        width: 100vw;
        border-radius: 0px;
    }

    &.left {
        &:before { top: 35px; left: 100%; border-left-color: #CDCDCD; }
        &:after { top: 36px; left: 100%; border-left-color: white; }
    }

    &.right {
        &:before { top: 35px; right: 100%; border-right-color: #CDCDCD; }
        &:after { top: 36px; right: 100%; border-right-color: white; }
    }

    &.bottom-right {
        &:before { left: 35px; bottom: 100%; border-bottom-color: #CDCDCD; }
        &:after { left: 36px; bottom: 100%; border-bottom-color: white; }
    }

    &.bottom-left {
        &:before { right: 35px; bottom: 100%; border-bottom-color: #CDCDCD; }
        &:after { right: 36px; bottom: 100%; border-bottom-color: white; }
    }

    &.top-right { 
        &:before { left: 35px; top: 100%; border-top-color: #CDCDCD; }
        &:after { left: 36px; top: 100%; border-top-color: white; }
    }

    &.top-left {
        &:before { right: 35px; top: 100%; border-top-color: #CDCDCD; }
        &:after { right: 36px; top: 100%; border-top-color: white; }
    }



    &.bottom-mobile {
        &:before {
            left: ${props => (props.$arrowLeft ? props.$arrowLeft + "px" : `auto`)};
            bottom: 100%; border-bottom-color: #CDCDCD;
        }
        &:after {
            left: ${props => (props.$arrowLeft ? (props.$arrowLeft + 1) + "px" : `auto`)};
            bottom: 100%; border-bottom-color: white; 
        }
    }

    &.top-mobile {
        &:before {
            left: ${props => (props.$arrowLeft ? props.$arrowLeft + "px" : `auto`)};
            top: 100%; border-top-color: #CDCDCD; 
        }
        &:after {
            left: ${props => (props.$arrowLeft ? (props.$arrowLeft + 1) + "px" : `auto`)};
            top: 100%; border-top-color: white;
        }
    }
`;