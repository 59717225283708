// @flow
import React, { useState } from "react";
import { useSelector } from "react-redux";
import Componentify from "react-componentify";

import { __ } from "../../lib/translate";
import { linkConverter, styledSpanTagConverter } from "../../lib/componentifyConverters";
import { getUserInitials } from "../../lib/shareFolder-utils";

import Button from "../ButtonDefault";
import { AvatarWrap } from "../UserAvatar";
import { TSpan } from "../intl";
import * as Style from "./StyleInviteFolder";
import * as StyleModal from "../Modals/styledComponents";
import { TAB_INDEX } from "@pcloud/web-utilities/dist/config/constants";

const customConverter = Object.assign({}, styledSpanTagConverter, {
  props: {
    style: {
      display: "inline-block",
      color: "#17bed0",
      maxWidth: "300px",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap"
    }
  }
});

type Props = {
  usersWithChangePermission: Array<any>,
  showComponent: () => void,
  showManageTab: number => void,
};

const InviteWithChangePermission = ({ usersWithChangePermission, showComponent, showManageTab }: Props) => {
  const permissionId = useSelector(({ shares: { sharePermissionId } }) => sharePermissionId);
  const shareFolderName = useSelector(({ shares: { shareFolderName } }) => shareFolderName);
  
  const  onManageSharesClick = (e: any) => {
    showManageTab(TAB_INDEX.MANAGE);
  };

  // render
  const renderWarningMesaage = () => {
    return (
      <Style.MessageWrapper>
        <Style.Message>
          <Componentify
            text={__("business_invite_manage_message", "The user(s) are not part of your Business account. That's why their level of access has been automatically changed to 'Edit'.")}
            converters={[linkConverter]}
          />
        </Style.Message>
      </Style.MessageWrapper>
    );
  };

  const renderRow = data => {
    return data.map((user, i) => {
      return (<Style.Row key={user.email}>
        <Style.EmailWrapper type="type">
          <AvatarWrap>{getUserInitials(user.email)}</AvatarWrap>
          <Style.Email>{user.email}</Style.Email>
        </Style.EmailWrapper>
      </Style.Row>)
      });
  };

  const title = __("share_folder_share_for").replace("%foldername%", shareFolderName);

  return (
    <Style.InviteWithChangePermissionWrapper>
      <StyleModal.Header>
        <Componentify text={title} converters={[customConverter]} />
      </StyleModal.Header>
      {renderWarningMesaage()}
      <Style.Label id="users_title">User(s):</Style.Label>
      {usersWithChangePermission.length ? (
        <Style.Table>{usersWithChangePermission.map(renderRow)}</Style.Table>
      ) : (
        null
      )}
      <Style.Footer>
        <Button inline color="cyan" size="big" onClick={onManageSharesClick}>
          <TSpan
            id={__("share_folder_manage_share", "Manage Shares")}
            style={{ color: "#fff", minWidth: "180px" }}
          />
        </Button>
      </Style.Footer>
    </Style.InviteWithChangePermissionWrapper>
  );
};

export default InviteWithChangePermission;
