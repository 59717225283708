//  @flow

import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import { __ } from "../../lib/translate";
import { MAX_MESSAGE_LENGTH } from "@pcloud/web-utilities/dist/config/constants";
import changeuploadlink from "../../api/changeuploadlink";

import {
  SettingsWrapper,
  SettingsHeader,
  FileRequestSettingsTitle,
  FileRequestSettingsDescription
} from "./styledComponents";
import TopBar from "./TopBar";
import InputTextarea from "../../components/InputTextarea";
import Button from "../../components/ButtonDefault";

type Props = {
  allSettingsBack: any,
  bodyHeight: number,
  parentRef: any
};

const FileRequestMessage = ({ allSettingsBack, bodyHeight, parentRef }: Props) => {
  const linkData = useSelector(({ fileRequestSettings }) => fileRequestSettings.linkData);
  const { comment, uploadlinkid: linkId, maxspace, expires } = linkData;
  const [message, setMessage] = useState(comment);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const dispatch = useDispatch();

  const onMessageChange = ({ target }) => {
    setMessage(target.value);
    setDisabled(false);
  };

  const onSave = () => {
    const messageParam =  message.trim();

    if (loading) {
      return;
    }

    if (messageParam.length > MAX_MESSAGE_LENGTH) {
      HFN.message(
        __("error_2245", "Maximum message length is 500 characters."),
        "error"
      );
      return;
    }

    setLoading(true);

    changeuploadlink(
      {
        uploadlinkid: linkId,
        comment: messageParam,
        maxspace: maxspace,
        deleteexpire: !expires
      },
      {
        onSuccess: (ret: any) => {
          dispatch({ type: "LOAD_FILE_REQUEST_DATA", linkData: ret });
          setLoading(false);
          setDisabled(true);
          HFN.message(__("shared_links_settings_updated"), "success");
        }
      }
    );
  };

  return (
    <SettingsWrapper bodyHeight={bodyHeight} ref={parentRef}>
      <TopBar allSettingsBack={allSettingsBack} />
      <SettingsHeader>
        <FileRequestSettingsTitle>{__("Message", "Message")}</FileRequestSettingsTitle>
        <FileRequestSettingsDescription>
          {__(
            "file_request_settings_modal_add_message_desc",
            "Add a message, which others will see, when they open the file request."
          )}
        </FileRequestSettingsDescription>
      </SettingsHeader>
      <TextareaStyled
        className="textarea"
        name="message"
        value={message}
        placeholder={__("Your message (optional)")}
        autoComplete="off"
        onChange={onMessageChange}
      />
      <ButtonWrapper>
        <Button
          loading={loading}
          disabled={disabled}
          borderRadius="3"
          color="cyan"
          onClick={onSave}
        >
          {__("Save")}
        </Button>
      </ButtonWrapper>
    </SettingsWrapper>
  );
};

export default FileRequestMessage;

const ButtonWrapper = styled.div`
  a {
    width: 428px;
    height: 40px;
    border-radius: 4px;
  }
  a[disabled] {
    background-color: #dddddd;
    color: #a2a2a2;
    opacity: 1;
  }
`;

const TextareaStyled = styled(InputTextarea)`
  font-size: 15px;
  width: 428px;
  height: 100px;
  margin-top: 25px;
  margin-bottom: 10px;
  padding: 10px 15px;
  border-radius: 4px;
  border: solid 1px #dedede;
  background-color: #fff;
  box-sizing: border-box;
`;
