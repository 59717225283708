import PropTypes from 'prop-types';
import React, { Component } from "react";
import styled, { keyframes } from "styled-components";
import { hiDPI } from "polished";

export default class FoldableText extends Component {
  static propTypes = {
    initialOpen: PropTypes.bool,
    title: PropTypes.string.isRequired,
    children: PropTypes.any.isRequired
  };

  static defaultProps = {
    initialOpen: false
  }

  constructor(props) {
    super(props);

    this.state = { opened: props.initialOpen };

    this._handleClick = this._handleClick.bind(this);
  }

  _handleClick() {
    this.setState({ opened: !this.state.opened });
  }

  render() {
    const { title, children, renderHeader } = this.props;
    const { opened } = this.state;

    return (
      <Wrap>
        <Header onClick={this._handleClick}>
          {opened ? <OpenedImg /> : <ClosedImg />}
          {title}
        </Header>
        <Body opened={opened}>{children}</Body>
        {/*opened ? <Body>{children}</Body> : null*/}
      </Wrap>
    );
  }
}

const Wrap = styled.div`

`;

const Header = styled.div`
  line-height: 40px;
  height: 40px;
  padding-left: 0;
  cursor: pointer;
  font-size: 13px;
  font-weight: bold;
`;

const HeaderAdditional = styled.div`
  float: right;
  height: 40px;
  cursor: default;
`;

const Body = styled.div`
  padding-bottom: 10px;
  display: ${props => props.opened ? "block" : "none"};
`;

const rotate0 = keyframes`
  from { transform: rotate(90deg); }
  to { transform: rotate(0deg); }
`;

const rotate90 = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(90deg); }
`;

const Img = styled.div`
  width: 10px;
  height: 10px;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center center;
  display: inline-block;
  margin: -3px 7px 0 0;

  background-size: 5px 8px;
  background-image: url(${require("../../root/img/dlink/closed.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../root/img/dlink/closed@2x.png")});
  }
`;

const ClosedImg = styled(Img)`
  background-size: 5px 8px;
  animation: ${rotate0} 0.25s ease;
`;

const OpenedImg = styled(Img)`
  transform: rotate(90deg);
  animation: ${rotate90} 0.1s linear;
`;
