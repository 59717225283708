// @flow

import React from "react";
import styled from "styled-components";

import LoginOrSignup from "../UserAuthentication2/LoginOrSignup";
import PcloudLogo from "../../../web-shared/images/svg/pcloud-gradient-logo.svg"

const LoginRegPage = ({ page = "login", ...rest }) => {
  return (
    <FlexContainer>
      <Form>
        <LogoWrapper>
          <PcloudLogo />
        </LogoWrapper>
        <LoginOrSignup {...rest} />
      </Form>
    </FlexContainer>
  );
};

export default LoginRegPage;

const FlexContainer = styled.div`
  display: flex;
  width: 100%;
  height: calc(100vh);
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #fafafa;

  @media screen and (max-width: 910px) {
    flex-direction: column;
    background-color: #fff;
    justify-content: flex-start;
  }
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 64px 48px 48px;
  box-sizing: border-box;
  width: 100%;
  background-color: #fff;
  max-width: 456px;
  border-radius: 8px;
  border: solid 1px #eeeeee;
  position: relative;
  min-height: 426px;

  @media screen and (max-width: 910px) {
    min-width: 0px;
    text-align: center;
    max-width: 400px;
    padding: 60px 20px;
    background-color: #fff;
    border: none;
  }
`;

const LogoWrapper = styled.div`
  margin-bottom: 32px;
`;