// @flow

import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Componentify from "react-componentify";

import * as Style from "../../containers/FamilyPlan/SharedInvitationComponents";
import ModalSignin from "../../containers/UserAuthentication/ModalSignin";
import AcceptInviteToFolder from "../AcceptInviteToFolder";
import FolderInviteIcon from "../../../root/img/svg/invite_to_folder_login.svg";
import WarnIcon from "../../../root/img/svg/warning_invitation.svg";
import apiMethod from "../../api/apiMethod";
import { __ } from "../../lib/translate";
import { getHash, isSameLocation, getLocationFromUrl } from "../../lib/utils";
import { boldConverter, brTagConverter, linkConverterV2 } from "../../lib/componentifyConverters";
import { LOCATION_REGIONS } from "@pcloud/web-utilities/dist/config/constants";
import { URLSITE } from "@pcloud/web-utilities/dist/config";

type Props = {
  logged: boolean,
  userRegionId: number,
  onSuccessInvitation: () => void
};

type State = {
  shareData: Object,
  holder: string,
  folderName: string,
  loading: boolean,
  error: string
};

class FolderInvitation extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      shareData: null,
      holder: "",
      folderName: "",
      loading: true,
      error: ""
    };

    (this: any).getInviteInfo = this.getInviteInfo.bind(this);
    (this: any).onSuccess = this.onSuccess.bind(this);
    (this: any).onLogout = this.onLogout.bind(this);
  }

  componentDidMount() {
    this.getInviteInfo();
  }

  componentDidUpdate(prevProps) {
    const { logged: prevLogged } = prevProps;
    const { logged } = this.props;

    if (prevLogged !== logged) {
      this.setState({ loading: true });
      this.getInviteInfo();
    }
  }

  onSuccess({ userinfo }) {
    const { folderName, holder } = this.state;

    HFN.userLoginAfter(userinfo, () => {
      this.setState({ error: this.getErrorMessage("", folderName, holder) });
    });
  }

  onLogout() {
    HFN.logout();
    this.setState({ error: "" });
  }

  getInviteInfo() {
    const code = getHash("code");
    const { email, logged } = this.props;

    apiMethod(
      "sharerequestinfo",
      { code },
      ({ share }) => {
        const { frommail, sharename, tomail = "" } = share;
        let errorMessage = "";
        share.code = code;

        if (logged && tomail !== "" && tomail.toLowerCase() !== email.toLowerCase()) {
          errorMessage = __("cannot_accept_share", "You can't accept this share. <br> This share is sent to %email%", {
            email: tomail
          });
        }

        this.setState({
          shareData: share,
          holder: frommail,
          folderName: sharename,
          loading: false,
          error: this.getErrorMessage(errorMessage, sharename, frommail)
        });
      },
      {
        errorCallback: ({ result, error }) => {
          if (result == 2012) {
            this.setState({
              loading: false,
              error: this.getErrorMessage(__("family_invite_cancelled_title"))
            });
          } else {
            this.setState({
              loading: false,
              error: this.getErrorMessage(error)
            });
          }
        }
      }
    );
  }

  getErrorMessage(message, folderName, holder) {
    const { logged } = this.props;
    let error = "";

    if (message) {
      error = message;
    } else if (logged && !isSameLocation()) {
      error = __("folder_invitation_message_another_region")
        .replace("%folder%", folderName)
        .replace("%email%", holder)
        .replace("%learnmore%", URLSITE + "data-regions/");
    }
    return error;
  }

  renderLoading() {
    return (
      <Style.LoadingWrapper>
        <div />
      </Style.LoadingWrapper>
    );
  }

  renderErrorMessage() {
    const { logged } = this.props;
    const { error } = this.state;

    return (
      <React.Fragment>
        <Style.Title>{__("Attention")}</Style.Title>
        <Style.WarningMessage>
          <WarnIcon />
          <Style.ComponentifyWrapper>
            <Componentify text={error} converters={[boldConverter, brTagConverter, linkConverterV2]} />
          </Style.ComponentifyWrapper>
        </Style.WarningMessage>
        {logged ? (
          <Style.Link onClick={this.onLogout}>
            {__("family_invite_subscribed_login", "Log in with a different account")}
          </Style.Link>
        ) : null}
      </React.Fragment>
    );
  }

  renderForm() {
    const { userRegionId } = this.props;
    const { holder, folderName } = this.state;
    const message = __("folder_invitation_subheadline")
      .replace("%email%", holder)
      .replace("%folder%", folderName);
    const title = {
      login: __("invite_to_folder_invite_signin_title"),
      register: __("invite_to_folder_invite_signup_title")
    };
    const label = LOCATION_REGIONS[userRegionId];
    const region = __(label.key, label.text);
    const beforeRegisterMessage = __("folder_invitation_message_before_continue")
      .replace("%folder%", folderName)
      .replace("%dataregion%", region);
    const locationFromUrl = getLocationFromUrl() || {};

    return (
      <Style.WrapperFolderInvite>
        <Style.Title>{__("Share Folder")}</Style.Title>
        <Style.WarningMessage>
          <FolderInviteIcon width="32" fill="#ff9700" />
          <Style.ComponentifyWrapper>
            <Componentify text={message} converters={[boldConverter, brTagConverter]} />
          </Style.ComponentifyWrapper>
        </Style.WarningMessage>
        <Style.Wrapper>
          <ModalSignin
            title={title}
            locations={[locationFromUrl]}
            beforeRegisterMessage={beforeRegisterMessage}
            onSuccess={this.onSuccess}
            onForgotPassword={HFN.config.label.forgotPassword.bind(HFN)}
            isLoginModal={false}
          />
        </Style.Wrapper>
      </Style.WrapperFolderInvite>
    );
  }

  render() {
    const { logged } = this.props;
    const { error, loading, shareData } = this.state;

    if (loading) {
      return this.renderLoading();
    } else if (error) {
      return this.renderErrorMessage();
    } else if (logged) {
      return <AcceptInviteToFolder shareData={shareData} />;
    } else {
      return this.renderForm();
    }
  }
}

export default connect(
  ({ user: { logged, userRegionId, userinfo = {} } = {} }) => {
    const { email = "" } = userinfo;

    return {
      logged,
      userRegionId,
      email
    };
  },
  dispatch => bindActionCreators({}, dispatch)
)(FolderInvitation);
