import PropTypes from 'prop-types';
import React from "react";
import InputText from "./InputText";

const InputReadOnly = props => {
  return (
    <InputText
      {...props}
      className="text disabled"
      disabled="disabled"
      placeholder="__"
      name="__"
    />
  );
};

export default InputReadOnly;
