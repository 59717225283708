// @flow
import React, { useState } from "react";

import Begin from "../components/changecryptopass/Begin";
import EnterPass from "../components/changecryptopass/EnterPass";
import SetNewPass from "../components/changecryptopass/SetNewPass";

const STEP_BEGIN = 1;
const STEP_ENTER_PASS = 2;
const STEP_SET_NEW_PASS = 3;

const ChangeCryptoPass = () => {
  const [currentStep, setStep] = useState(STEP_BEGIN);

  const beginChange = () => {  setStep(STEP_ENTER_PASS);  };

  return <React.Fragment>
    {currentStep == STEP_BEGIN ? <Begin onChangeClick={beginChange} /> : null }
    {currentStep == STEP_ENTER_PASS ? <EnterPass onPassOk={() => setStep(STEP_SET_NEW_PASS)} /> : null }
    {currentStep == STEP_SET_NEW_PASS ? <SetNewPass onPassChanged={() => setStep(STEP_BEGIN)} /> : null }
  </React.Fragment>
};

export default ChangeCryptoPass;
