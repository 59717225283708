// @flow

import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { hiDPI } from "polished";

import Button, { ButtonCentered } from "./ButtonDefault";
import Modal from "./Modal";

type Props = {
  type?: string,
  title?: string,
  message?: string,
  button?: any,
};

const InfoModal = ({
  type = "warning",
  title = "",
  message = "",
  button = null,
  show = false,
}: Props) => {
  const [isOpen, setIsOpen] = useState(show);

  const onClose = () => {
    setIsOpen(false);
  };

  const getIcons = () => {
    return {
      warning: WarningIcon,
      attention: AttentionIcon,
    };
  };

  const Icon = getIcons()[type];
  return (
    <Modal opened={isOpen} onClose={onClose}>
      <ModalWrap>
        <Container>
          <Icon />
          {title ? <Header>{title}</Header> : null}
          {message ? <Message>{message}</Message> : null}
          {button ? button : null}
        </Container>
        <CloseButton onClick={onClose} />
      </ModalWrap>
    </Modal>
  );
};

export default InfoModal;

const ModalWrap = styled.div`
  position: relative;
`;

const Container = styled.div`
  text-align: center;
  border-radius: 15px;
  padding: 10px;
  background: #fefefe;
  max-width: 620px;
  min-width: 300px;
  height: 100%;
  font-family: "Roboto", sans-serif;
  color: #333;

  a {
    min-width: 150px;
  }

  @media only screen and (max-width: 500px) {
    a {
      width: 300px;
    }
  }
`;

const IconDefault = styled.div`
  display: inline-block;
  margin: 45px;
  width: 50px;
  height: 50px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  @media only screen and (max-width: 640px) {
    margin: 40px;
  }
`;

const AttentionIcon = styled(IconDefault)`
  background-image: url(${require("../../root/img/full-icon.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../root/img//full-icon@2x.png")});
  }
`;

const WarningIcon = styled(IconDefault)`
  background-image: url(${require("../../root/img/warn.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../root/img//warn@2x.png")});
  }
`;

const Header = styled.div`
  font-size: 17px;
  font-weight: bold;
  margin: 0px 30px 25px 30px;
`;

const Message = styled.div`
  font-size: 15px;
  margin: 0px 70px 50px 70px;
  line-height: 1.2;
  & a {
    color: #17bed0;
  }

  @media only screen and (max-width: 500px) {
    margin: 0px 5px 30px 5px;

    @media only screen and (max-width: 640px) {
      margin: 0px 70px 30px 70px;
    }
  }
`;

const CloseButton = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
  margin: 12px;
  background-position: center;
  cursor: pointer;
  background-size: contain;
  background-image: url(${require("../../root/img/updatedrive/close.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../root/img/updatedrive/close@2x.png")});
  }

  &:hover {
    opacity: 0.8;
  }
`;
