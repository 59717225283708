// Thumbs
import {detectSafari} from "../../lib";

export const DEFAULT_THUMB_QUEUE = '/img/player/default-thumb-queue.png';
export const DEFAULT_THUMB = '/img/player/default-thumb.png';
export const DEFAULT_THUMB_BIG = '/img/player/default-thumb-big.jpg';
export const BOX_THUMB_SIZE = 64;
export const QUEUE_THUMB_SIZE = 32;
export const PAGE_PLAYER_THUMB_SIZE = 164;
export const MINIMIZE_THUMB_SIZE = 32;
export const MINIMIZE_MOBILE_THUMB_SIZE = 44;
export const MINI_MOBILE_THUMB_SIZE = 108;

// Repeat states
export const REPEAT_STATE_INACTIVE = 0;
export const REPEAT_STATE_REPEAT = 1;
export const REPEAT_STATE_REPEAT_ONE = 2;

// Animations
export const GENERAL_TRANSITION_DELAY = 500;

// Configs
export const SHOW_PODCAST_MODE_ABOVE_MIN = 20 * 60;

export const MINIMIZED_HEIGHT = 60;

export const STOP_ANALYZER = detectSafari() || window.location.hostname === 'pclmy.pcloud.work';