// @flow

import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import Componentify from "react-componentify";

import Button from "../../components/ButtonDefault";

import { __ } from "../../lib/translate";
import errors from "../../lib/errors";
import { brTagConverter } from "../../lib/componentifyConverters";
import {
  LOCATION_REGIONS,
  US_LOCATIONID,
  EU_LOCATIONID,
  CHANGE_DATA_REGION_STATUS
} from "@pcloud/web-utilities/dist/config/constants";
import apiMethod from "../../api/apiMethod";

type Props = {
  currentLocationId: US_LOCATIONID | EU_LOCATIONID,
  onSuccess: () => void,
  onClose: () => void
};

const CancelConfirmationStep = ({
  currentLocationId,
  onSuccess = () => {},
  onClose = () => {}
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);

  const token = useSelector(({ user }) => user.token);
  const password = useSelector(({ user }) => user.userinfo.password);
  const currentLocationKey = LOCATION_REGIONS[currentLocationId].key;
  const dispatch = useDispatch();

  const onSubmit = () => {
    setIsLoading(true);

    apiMethod(
      "relocation/cancelrequest",
      {
        auth: token,
        password: password
      },
      () => {
        setIsLoading(false);
        onSuccess();
        dispatch({
          type: "USER_UPDATE_RELOCATION_STATUS",
          relocationStatus: CHANGE_DATA_REGION_STATUS["CANCELED"]
        });
      },
      {
        errorCallback: ({ result, error }) => {
          let errorMessage = "";

          if (errors[result]) {
            errorMessage = errors[result];
          } else {
            errorMessage = __("something_went_wrong_refresh_and_try_again");
          }
          setIsLoading(false);
          HFN.message(errorMessage, "error");

          if (!errors[result]) {
            throw new Error(error);
          }
        }
      }
    );
  };

  return (
    <Container>
      <Header>{__("change_region_cancel_title")}</Header>
      <Desc>
        <Componentify
          text={__(
            "change_region_cancel_description",
            "If you cancel your request, your files and data will remain in %locationA%. Are you sure you want to proceed?",
            { locationA: __(currentLocationKey) }
          )}
          converters={[brTagConverter]}
        />
      </Desc>
      <ButtonsWrapper>
        <Button loading={isLoading} onClick={isLoading ? () => {} : onSubmit}>
          {__("Yes")}
        </Button>
        <Button color="lightgray3" onClick={onClose}>
          {__("No")}
        </Button>
      </ButtonsWrapper>
    </Container>
  );
};

export default CancelConfirmationStep;

const Container = styled.div`
  width: 640px;
  text-align: center;
  font-family: "Roboto", sans-serif;
  border-radius: 15px;
  box-shadow: 0px 10px 20px 0 rgba(0, 0, 0, 0.3);
  background-color: #ffffff;
  padding: 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Header = styled.div`
  font-size: 19px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 13px;
`;

const Desc = styled.div`
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: normal;
  text-align: center;
`;

const ButtonsWrapper = styled.div`
  margin-top: 37px;
  margin-top: 37px;
  display: flex;
  min-width: 290px;

  a {
    margin: 0px 5px;

    &:first-of-type {
      margin-left: 0px;
    }

    &:last-of-type {
      margin-right: 0px;
    }
  }
`;
