// @flow

import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";

import Modal, { OvalModalWrap, CloseModalIcon } from "../../components/Modal";
import Button from "../../components/ButtonDefault";
import { Loader } from "../TwoFactorAuth/SharedComponents";
import * as Style from "../../components/Modals/styledComponents";

import { __ } from "../../lib/translate";
import { createIconSrc, ICO, ICONS } from "../../lib/icons";

type Props = {
  list: Array<any>
};

const DeleteBookmarkModal = ({ list }: Props) => {
  const [isOpened, setIsOpened] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingModal, setLoadingModal] = useState(true);

  useEffect(() => {
    setLoadingModal(false);
    setIsOpened(true);
  }, []);

  const onEnter = useCallback(
    (e: any) => {
      if (e.keyCode === 13) {
        onDeleteClick();
      }
    },
    [onDeleteClick]
  );

  useEffect(() => {
    if (isOpened) {
      document.addEventListener("keyup", onEnter);
    } else {
      document.removeEventListener("keyup", onEnter);
    }
  }, [isOpened, onEnter]);

  const onCloseClick = () => {
    if (loading) {
      return;
    }
    setIsOpened(false);
  };

  const onDeleteClick = () => {
    if (loading) {
      return;
    }
    setLoading(true);

    console.log("deleteBookmarks list +++++", list);
    const linksBatch = new batchApiCall({
      errorCallback: function(ret) {
        HFN.message(ret.error, "error");
        setLoading(false);
      }
    });

    let n;
    for (n = 0; n < list.length; ++n) {
      linksBatch.addCall("publink/unpin", { code: list[n].code, locationid: list[n].locationid });
    }

    linksBatch.execute(function() {
      triggerOpenBookmarks(".bookmarks");
      HFN.message(__("Bookmark(s) Deleted."));
      setIsOpened(false);
    });
  };

  const renderBookmarkItem = (data, index) => {
    const { name } = data;

    return (
      <Style.Row key={index}>
        <Icon src={createIconSrc(ICO.BOOKMARK, ICONS.LIST_SMALL)} alt="icon" />
        <Text>{name}</Text>
      </Style.Row>
    );
  };

  const renderContent = () => {
    const title = __("delete_bookmark_modal_title", "Delete Bookmarks");

    return (
      <React.Fragment>
        <Body>
          <Header>{title}</Header>
          <Style.Container style={{ paddingBottom: "10px" }}>
            <Style.Label>
              {__("delete_bookmark_title", "Do you want ot delete the bookmark to")}
            </Style.Label>
            <ItemsList>{list.map(renderBookmarkItem)}</ItemsList>
          </Style.Container>
        </Body>
        <Style.Footer>
          <Button
            style={{ marginRight: "5px" }}
            color="lightgray4"
            onClick={onCloseClick}
          >
            {__("Close")}
          </Button>
          <Button
            style={{ marginLeft: "5px" }}
            color="red"
            disabled={loading}
            loading={loading}
            onClick={onDeleteClick}
          >
            {__("Delete")}
          </Button>
        </Style.Footer>
      </React.Fragment>
    );
  };

  return (
    <Modal
      animate
      shouldCloseOnOverlayClick={false}
      onClose={() => setIsOpened(false)}
      opened={isOpened}
    >
      <OvalModalWrap>
        <CloseModalIcon onClick={() => setIsOpened(false)} />
        <Content>{loadingModal ? <Loader /> : renderContent()}</Content>
      </OvalModalWrap>
    </Modal>
  );
};

export default DeleteBookmarkModal;

const Content = styled.div`
  padding: 30px;
  width: 480px;
  box-sizing: border-box;
  text-align: center;
  min-height: 220px;
`;

const Text = styled.div`
  max-width: 330px;
  white-space: nowrap;
`;

const Body = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

const LoaderWrapper = styled.div`
  position: relative;
  min-height: 90px;
`;

const Header = styled(Style.Header)`
  margin-bottom: 0;
`;

const Icon = styled.img`
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  vertical-align: middle;
`;

const ItemsList = styled(Style.Box)`
  line-height: 24px;
  padding: 10px;
  font-size: 15px;
  font-weight: normal;
  text-align: left;
  padding: 8px;
  justify-content: flex-start;
  flex-direction: column;
  max-height: 115px;
  overflow: auto;
  overflow-x: hidden;

  & > div:only-child {
    padding-bottom: 0px;
  }

  /* width */
  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 3px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #eaeaea;
    border-radius: 3px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #aaa;
    border-radius: 3px;
    transition: background 200ms ease-in-out;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;
