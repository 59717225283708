import React, { Component } from "react";
import PropTypes from "prop-types";
import { AutoSizer, Table, Column } from "react-virtualized";
import styled from "styled-components";
import { hiDPI } from "polished";
import Scrollbar from "../Scrollbar";
import GridSortableHeader from "./SortableHeader";
import DownloadStatsNoStats from "./NoStats";
import { arraySort, rowClassName } from "../../lib/gridUtils";
import { __ } from "../../lib/translate";
import { formatDt } from "../../lib/utils";
import { TSpan } from "../../components/intl";
import "react-virtualized/styles.css";
import "../../styles/virtualized.less";

function transformList(list) {
  return list.map(item => {
    if (item.open) {
      item.opentimetime = new Date(item.opentime).getTime();
    } else {
      item.opentimetime = 0;
    }

    return item;
  })
}

export default class GridDownloadLinkInvites extends Component {
  static propTypes = {
    onInviteUsers: PropTypes.func.isRequired,
    list: PropTypes.arrayOf(
      PropTypes.shape({
        email: PropTypes.string.isRequired,
        open: PropTypes.bool.isRequired,
        opentime: PropTypes.string
      })
    ).isRequired
  };

  constructor(props) {
    super(props);

    this.state = { list: arraySort(transformList(props.list), "email", 1), sortBy: "email" };

    this._sort = this._sort.bind(this);
  }

  componentWillUpdate(nextProps, nextState) {
    const { sortBy: prevSortBy, sortDirection: prevSortDirection } = this.state;

    if (
      nextState.sortBy !== prevSortBy ||
      nextState.sortDirection !== prevSortDirection
    ) {
      const { sortBy, sortDirection } = nextState;

      let { list } = this.props;

      if (sortBy) {
        list = arraySort(list, sortBy, -1, sortDirection === "DESC" ? -1 : 1);
      }
    }
  }

  render() {
    const { onInviteUsers } = this.props;
    const { list, sortBy, sortDirection } = this.state;

    if (!list.length) {
      return (
        <DownloadStatsNoStats>
          <TSpan id="dl_link_havent_sent">
            {"You haven't sent this link yet."}
          </TSpan>
          <br />
          <a href="javascript:;" onClick={onInviteUsers}>
            <TSpan id="dl_send_link">Send link</TSpan>
          </a>
        </DownloadStatsNoStats>
      );
    }

    return (
      <AutoSizer disableHeight>
        {({ height, width }) => (
          <Table
            headerHeight={30}
            width={this.props.width || width}
            height={Math.min(30 * (list.length + 1), 240)}
            rowCount={list.length}
            rowGetter={({ index }) => list[index]}
            rowHeight={30}
            sort={this._sort}
            sortBy={sortBy}
            //sortDirection={sortDirection}
            rowClassName={rowClassName}
          >
            <Column
              label="Email"
              dataKey="email"
              width={130}
              flexGrow={1}
              headerRenderer={props =>
                __("Email", "Email") + ":"}
            />
            <Column
              label="Viewed"
              dataKey="opentimetime"
              headerClassName="value"
              className="value"
              width={55}
              flexGrow={1}
              maxWidth={75}
              headerRenderer={props => (
                <ViewsHeader active={sortBy === "open"} />
              )}
              cellRenderer={({ cellData, rowData }) =>
                (cellData
                  ? <Opened>{formatDt(rowData.opentime)}</Opened>
                  : <NotOpened>{__("link_sent", "link sent")}</NotOpened>)}
            />
          </Table>
        )}
      </AutoSizer>
    );
  }

  _sort({ sortBy, sortDirection }) {
    const { sortBy: prevSortBy, sortDirection: prevSortDirection } = this.state;

    console.log(sortBy, sortDirection);
    console.log(prevSortBy, prevSortDirection);

    if (prevSortDirection === sortDirection) {
      sortDirection = sortDirection === "DESC" ? "ASC" : "DESC";
    }

    /*
    // If list was sorted DESC by this column.
    // Rather than switch to ASC, return to "natural" order.
    if (prevSortDirection === sortDirection.DESC) {
      sortBy = null;
      sortDirection = null;
    }
    */

    const list = arraySort(
      this.state.list.slice(0),
      sortBy,
      sortBy === "email" ? 1 : -1
      //sortDirection === "DESC" ? -1 : 1
    );

    this.setState({ sortBy, sortDirection, list });
  }
}

const NotOpened = styled.span`
  color: "#888";
`;

const Opened = styled.span`
  color: #ababab;
`;

const ViewsHeader = styled(GridSortableHeader)`
  background-image: url(${require("../../../root/img/dlink/views.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/dlink/views@2x.png")});
  }
`;
