// @flow

import React, { PureComponent } from "react";
import Componentify from "react-componentify";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";

import apiMethod from "../../api/apiMethod";
import { login } from "../../lib/state/actions/user";
import { boldConverter, brTagConverter } from "../../lib/componentifyConverters";
import { __ } from "../../lib/translate";
import { getHash, isSameLocation, getLocationFromUrl } from "../../lib/utils";
import { renderQuota } from "../../../web-shared/utils";
import { URLSITE } from "@pcloud/web-utilities/dist/config";

import Button from "../../components/ButtonDefault";
import InputText from "../../components/InputText";
import RegistrationForm, { ErrorMessage } from "../UserAuthentication/InvitationRegistration/RegistrationForm";
import * as Style from "../FamilyPlan/SharedInvitationComponents";
import WarningInvitationModal from "./WarningInvitationModal";
import BusinessInviteIcon from "../../../root/img/svg/business_invite.svg";
import WarnIcon from "../../../root/img/svg/warning_invitation.svg";

import type { Userinfo, Subscription } from "../../types/user";
import type { SuccessData } from "./types";
import { setGlobalLocationById } from "@pcloud/web-utilities/dist/api/utils.api";

type Sender = {
  position: string,
  firstName: string,
  lastName: string
};

type Props = {
  token: string,
  email: string,
  language: string,
  logged: boolean,
  subscriptions: Array<Subscription>,
  premium: boolean,
  premiumlifetime: boolean,
  cryptosubscription: boolean,
  cryptolifetime: boolean,
  plan: number | string,
  business: boolean,
  login: (Userinfo, string) => void
};

type State = {
  firstName: string,
  lastName: string,
  account: string,
  quota: number,
  sender: Sender,
  invitedEmail: string,
  code: string,
  loading: boolean,
  error: string,
  errorJoin: string,
  shouldOpenWarningModal: boolean,
  newRegistration: boolean
};

class BusinessInvitation extends PureComponent<Props, State> {
  static defaultProps = {
    token: "",
    email: "",
    language: "en",
    logged: false,
    subscriptions: [],
    premium: false,
    premiumlifetime: false,
    cryptosubscription: false,
    cryptolifetime: false,
    plan: "",
    business: false,
    login: () => {}
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      firstName: "",
      lastName: "",
      account: "",
      quota: 0,
      sender: {
        position: "",
        firstName: "",
        lastName: ""
      },
      invitedEmail: "",
      code: "",
      loading: true,
      error: "",
      errorJoin: "",
      shouldOpenWarningModal: false,
      newRegistration: false,
      showMessage: true
    };

    (this: any).join = this.join.bind(this);
    (this: any).getInviteInfo = this.getInviteInfo.bind(this);
    (this: any).onRegistration = this.onRegistration.bind(this);
    (this: any).onModalClose = this.onModalClose.bind(this);
    (this: any).changeFirstName = this.changeFirstName.bind(this);
    (this: any).changeLastName = this.changeLastName.bind(this);
    (this: any).onLogout = this.onLogout.bind(this);
  }

  componentDidMount() {
    this.getInviteInfo();
  }

  componentDidUpdate({ logged: prevLogged }: Props) {
    const { logged: nextLogged } = this.props;
    const { newRegistration } = this.state;

    if (nextLogged !== prevLogged && !newRegistration) {
      this.getInviteInfo();
    }
  }

  onRegistration({ action, userinfo, firstName, lastName }: SuccessData) {
    const { token, login } = this.props;

    if (action === "register") {
      this.setState({
        loading: true,
        shouldOpenWarningModal: false,
        newRegistration: true
      });
    } else {
      this.setState({ loading: false, firstName, lastName });
    }

    login(userinfo, token);
    HFN.userLoginAfter(userinfo, () => {
      if (action === "register") {
        this.join(firstName, lastName);
        this.setState({ showMessage: false });
      }
    });
  }

  getInviteInfo() {
    const { logged } = this.props;
    const code = getHash("code");

    this.setState({ loading: true });

    apiMethod(
      "account_inviteinfo",
      { code },
      ({ account, invited_mail, sender, quota }) => {
        let errorMessage = "";
        let shouldOpenWarningModal = this.shouldRenderWarningModal();

        if (logged && !isSameLocation()) {
          errorMessage = __("business_invite_subscribed_message4");
          shouldOpenWarningModal = false;
        }

        this.setState({
          code,
          account,
          quota,
          sender: {
            position: sender.position,
            firstName: sender.firstname,
            lastName: sender.lastname
          },
          invitedEmail: invited_mail,
          loading: false,
          shouldOpenWarningModal: shouldOpenWarningModal,
          error: errorMessage
        });
      },
      {
        errorCallback: ({ result, error }) => {
          if (result == 2012) {
            this.setState({
              loading: false,
              error: __("family_invite_cancelled_title")
            });
          } else if (result == 2336) {
            this.setState({
              loading: false,
              error: __("business_member_invite_relocation")
            });
          } else {
            this.setState({ loading: false, error });
          }
        }
      }
    );
  }

  join(firstName = "", lastName = "") {
    const { token } = this.props;
    const { code } = this.state;

    if (firstName.length === 0) {
      this.setState({ errorJoin: __("error_enter_fName") });
      return;
    } else if (lastName.length === 0) {
      this.setState({ errorJoin: __("error_enter_lName") });
      return;
    }

    this.setState({ loading: true });

    apiMethod(
      "account_inviteaccept",
      {
        auth: token,
        code,
        firstname: firstName,
        lastname: lastName,
        termsaccepted: "yes"
      },
      () => {
        window.open(URLSITE, "_self");
      },
      {
        errorCallback: ({ error }) => {
          this.setState({ loading: false });

          // TO DO
          HFN.message(error, "error");
        }
      },
      { forceFresh: true }
    );
  }

  onModalClose() {
    this.setState({ shouldOpenWarningModal: false });
  }

  onLogout() {
    HFN.logout();
    setGlobalLocationById(getLocationFromUrl().id);
  }

  getPlanName() {
    let planNameKey = "plan_name_";

    if (typeof HFN.config.user.plan != "undefined") {
      planNameKey += HFN.config.user.plan;
    }

    if (HFN.config.user.premiumlifetime) {
      planNameKey += "_lifetime";
    } else if (HFN.config.user.business) {
      planNameKey = "plan_name_business";
    } else if (HFN.config.user.plan === "undefined" && !HFN.config.user.business) {
      planNameKey += "_unknown";
    }

    return __(planNameKey);
  }

  shouldRenderWarningModal() {
    const { plan, subscriptions, cryptolifetime, business } = this.props;
    const { newRegistration } = this.state;
    const hasSubscription = subscriptions && subscriptions.length > 0;

    return (plan === 0 || hasSubscription) && !cryptolifetime && !business && !newRegistration;
  }

  changeFirstName(event: SyntheticEvent<HTMLInputElement>) {
    const value = event.target.value;
    this.setState({ firstName: value });
  }

  changeLastName(event: SyntheticEvent<HTMLInputElement>) {
    const value = event.target.value;
    this.setState({ lastName: value });
  }

  renderLoading() {
    return (
      <Style.LoadingWrapper>
        <div />
      </Style.LoadingWrapper>
    );
  }

  renderNamesInput() {
    const { firstName, lastName, errorJoin } = this.state;

    return (
      <InputWrapper>
        <ErrorMessage>{errorJoin}</ErrorMessage>
        <InputText type="fName" name="fName" value={firstName} onChange={this.changeFirstName} placeholder={__("First Name")} />
        <InputText type="lName" name="lName" value={lastName} onChange={this.changeLastName} placeholder={__("Last Name")} />
      </InputWrapper>
    );
  }

  renderMessages(error) {
    const { logged, subscriptions, premium, premiumlifetime, plan, business, cryptosubscription, cryptolifetime } = this.props;
    const { firstName, lastName } = this.state;

    const hasSubscription = subscriptions && subscriptions.length > 0;
    const hasEditablePrSubscription =
      subscriptions &&
      subscriptions.some(subscription => {
        const premiumSubscription = subscription.products.indexOf(1) == -1 || subscription.products.indexOf(3) == -1;
        return subscription.canedit == true && premiumSubscription;
      });
    const hasPremiumLifetime = premiumlifetime && (plan == 1 || plan == 3);
    const hasCryptoLifetime = cryptolifetime;
    const hasPremiumSubscription = premium && !premiumlifetime;
    const hasCryptoSubscrioption = cryptosubscription && !cryptolifetime;
    const hasFamilyPlan = plan == 14 || plan == 15;
    const hasBusiness = business;
    const planName = this.getPlanName();

    let Icon = WarnIcon;
    let MessageComponent = Style.WarningMessage;
    let title = __("family_invite_subscribed_header");
    let message;
    let showButtons;

    if (error) {
      title = "";
      message = error;
      showButtons = false;
    } else if (logged && !isSameLocation()) {
      title = "";
      message = __("business_invite_subscribed_message4");
      showButtons = false;
    } else if (hasPremiumLifetime || hasBusiness || hasFamilyPlan) {
      message = __("business_invite_subscribed_message3");
      showButtons = false;
    } else if (hasCryptoLifetime) {
      message = __("business_invite_subscribed_message3").replace("%planname%", "Crypto Lifetime");
      showButtons = false;
    } else if (hasBusiness) {
      message = __("family_access_denied");
      showButtons = false;
    } else if (hasSubscription && !hasEditablePrSubscription) {
      message = __("business_invite_subscribed_message2");
      showButtons = true;
    } else if (hasPremiumSubscription) {
      message = __("business_invite_subscribed_message1");
      showButtons = true;
    } else if (hasCryptoSubscrioption) {
      message = __("business_invite_subscribed_message1").replace("%planname%", "Crypto");
      showButtons = true;
    } else {
      title = __("pCloud Business");
      message = __("business_invite_subscribed_message1");
      Icon = BusinessInviteIcon;
      MessageComponent = Style.Message;
      showButtons = true;
    }

    message = message.replace("%planname%", planName);

    return (
      <React.Fragment>
        <Style.Title>{title}</Style.Title>
        <MessageComponent>
          <Icon fill="#ff9700"/>
          <Style.ComponentifyWrapper>
            <Componentify text={message} converters={[boldConverter, brTagConverter]} />
          </Style.ComponentifyWrapper>
        </MessageComponent>

        {showButtons ? (
          <ButtonsWrapper>
            {this.renderNamesInput()}
            <JoinButton>
              <Button onClick={() => this.join(firstName, lastName)}>{__("Join")}</Button>
            </JoinButton>
          </ButtonsWrapper>
        ) : null}
        {logged ? <Style.Link onClick={this.onLogout}>{__("family_invite_subscribed_login")}</Style.Link> : null}
      </React.Fragment>
    );
  }

  renderRegister() {
    const { language } = this.props;
    const { account, quota, invitedEmail, sender } = this.state;
    const locationFromUrl = getLocationFromUrl() || {};
    const message = __(
      "business_invite_subheadline_new",
      "You have been invited by <b>%firstname% %lastname%</b> to join %account%'s Business account and get <b>%businessquota%</b> secure storage."
    )
      .replace("%firstname%", sender.firstName)
      .replace("%lastname%", sender.lastName)
      .replace("%account%", account)
      .replace("%businessquota%", renderQuota(quota));

    return (
      <React.Fragment>
        <Style.Title>{__("pCloud Business")}</Style.Title>
        <Style.Message>
          <BusinessInviteIcon width="32" fill="#17bed0" />
          <Style.ComponentifyWrapper>
            <Componentify text={message} converters={[boldConverter, brTagConverter]} />
          </Style.ComponentifyWrapper>
        </Style.Message>
        <Style.Wrapper className="business-invitation-reg-wrapper">
          <RegistrationForm
            title={__("family_invite_description", "Log in or create a new account to continue")}
            formWidth="360px"
            language={language}
            email={invitedEmail}
            shouldRednerNames
            noDefaultFiles
            locations={[locationFromUrl]}
            initLoginRegModal={HFN.initLoginRegModal.bind(HFN)}
            onSuccess={this.onRegistration}
          />
        </Style.Wrapper>
      </React.Fragment>
    );
  }

  render() {
    const { logged, email } = this.props;
    const { error, loading, shouldOpenWarningModal, showMessage } = this.state;
    let content = null;

    if (loading) {
      content = this.renderLoading();
    } else if ((logged && showMessage) || error) {
      content = this.renderMessages(error);
    } else if (!logged) {
      content = this.renderRegister();
    }

    return (
      <React.Fragment>
        {content}
        <WarningInvitationModal email={email} isOpen={shouldOpenWarningModal} onCloseModal={this.onModalClose} />
      </React.Fragment>
    );
  }
}

export default connect(
  ({
    user: {
      logged,
      token,
      userinfo: { subscriptions, premium, premiumlifetime, cryptosubscription, cryptolifetime, plan, business, email } = {}
    } = {}
  }) => {
    return {
      logged,
      token,
      subscriptions,
      premium,
      premiumlifetime,
      cryptosubscription,
      cryptolifetime,
      plan,
      business,
      email
    };
  },
  dispatch =>
    bindActionCreators(
      {
        login
      },
      dispatch
    )
)(BusinessInvitation);

const InputWrapper = styled.div`
  input.text {
    width: 100%;
    margin: 0 0 15px 0;
    line-height: 40px;
    height: 40px;
    padding: 0 10px;
    border: 1px solid #ccc;
    font-size: 14px;
    border-radius: 5px;
    box-sizing: border-box;
    min-width: initial;
    width: 100%;
    color: #000;
  }
`;

const ButtonsWrapper = styled.div`
  display: table;
  max-width: 360px;
  margin: 10px auto;
  text-align: center;
`;

const JoinButton = styled(Style.ButtonContainerInline)`
  a {
    width: 100%;
    max-width: 100%;
    margin: 0;
  }
`;
