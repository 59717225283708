import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Modal, { OvalModalWrap, OvalModalCloseV2 } from "../Modal";
import Button from "../ButtonDefault";
import { __ } from "../../lib/translate";
import useUploadContext from './hooks/useUploadContext';
import { Content, Body, ButtonWrapper, Title, Text } from "./sharedStyles";
import NoPreviewSvg from "../../../root/img/svg/upload-manager/nopreview.svg";

const UploadPasteModal = () => {
  const {
    isUploadPasteModalOpen,
    handleUploadPasteModalClose,
    handleUploadPasteModalYesClick,
    handleOpenState,
    isOpened,
  } = useUploadContext();

  const [isError, setIsError] = useState(false);

  const handleError = () => {
    setIsError(true);
  };

  useEffect(() => {
    setIsError(false);
  }, [isUploadPasteModalOpen])


  return (
    <Modal animate shouldCloseOnOverlayClick={false} onClose={handleUploadPasteModalClose} opened={isUploadPasteModalOpen}>
      <OvalModalWrap>
        <OvalModalCloseV2 onClick={handleUploadPasteModalClose} />
        <Content>
          <React.Fragment>
            <Body>
              <StyledTitle>{isUploadPasteModalOpen && isUploadPasteModalOpen.items[0].name}</StyledTitle>
              {(isUploadPasteModalOpen.pasteItemUrl && !isError) ? (
                <ScreenshotContainer>
                  <ScreenshotPreview src={isUploadPasteModalOpen.pasteItemUrl} alt="Screenshot Preview" onError={handleError} />
                </ScreenshotContainer>
              ) : isError ? <>
                <NoPreviewSvg />
                <Text>{__('upload_manager_screenshot_no_preview', 'Looks like this file doesn’t have a preview we can show you.')}</Text>
              </> : null}
            </Body>
            <ButtonWrapper>
              <Button inline color="lightgray4" onClick={handleUploadPasteModalClose}>
                {__('Cancel', 'Cancel')}
              </Button>
              <Button inline color="cyan" onClick={() => {
                handleUploadPasteModalYesClick(isUploadPasteModalOpen.items);
                if (!isOpened) {
                  handleOpenState();
                }
              }}>
                {__('Upload', 'Upload')}
              </Button>
            </ButtonWrapper>
          </React.Fragment>
        </Content>
      </OvalModalWrap>
    </Modal>
  );
};

export default UploadPasteModal;

const ScreenshotContainer = styled.div`
  border: 2px dashed #0000001C;
  padding: 20px;
  text-align: center;
  position: relative;
  margin-bottom: 20px;
  box-sizing: border-box;
  padding: 8px;
`;

const ScreenshotPreview = styled.img`
  width: 100%;
  max-height: 500px;
`

const StyledTitle = styled(Title)`
  word-break: break-all;
`