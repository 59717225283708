import React, {useEffect} from 'react';
import {BoxTopHeaderControlsWrapper, OverlayPortalContainer} from "../../../userinterface/styles";
import ActionButton from "../../../userinterface/ActionButton";
import FullPageBoxControls from "./FullPageBoxControls";
import usePlayerContext from "../../../hooks/usePlayerContext";
import HighlightOnboardingComponent from "../../../../HighlightOnboardingComponent";
import PlayerPodcastContent from "../../../../HighlightOnboardingComponent/Contents/AudioPlayer/PlayerPodcastContent";
import {useDispatch} from "react-redux";
import {ONBOARDING_ADD_ITEM, ONBOARDING_REMOVE_ITEM} from "../../../../../lib/state/constants/onboarding";
import {ONBOARDING_ID_PLAYER_SETTINGS_PODCAST} from "../../../../HighlightOnboardingComponent/constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faVolume} from '@fortawesome/pro-solid-svg-icons/faVolume';
import {faVolumeSlash} from '@fortawesome/pro-solid-svg-icons/faVolumeSlash';
import {faEllipsisVertical} from '@fortawesome/pro-solid-svg-icons/faEllipsisVertical';
import {SHOW_PODCAST_MODE_ABOVE_MIN} from "../../../constants";

const BoxControls = () => {
    const {
        isMobile,
        playerVariant,
        onPlayerClose,
        onShowMiniPlayer,
        additionalClass,
        controlsWithOverlay,
        onOpenSettingsToggle,
        onVolumePanelToggle,
        volume,
        duration,
        isSingleSongPlayer
    } = usePlayerContext();

    const {
        isFullPage,
        isMiniPlayer,
        isMinimize,
        showPlayer
    } = playerVariant;

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: ONBOARDING_REMOVE_ITEM, id: ONBOARDING_ID_PLAYER_SETTINGS_PODCAST });
    }, [isMiniPlayer, showPlayer, isFullPage]);

    useEffect(() => {
        if (duration !== Infinity && duration > SHOW_PODCAST_MODE_ABOVE_MIN) {
            dispatch({ type: ONBOARDING_ADD_ITEM, id: ONBOARDING_ID_PLAYER_SETTINGS_PODCAST });
        }
    }, [duration]);

    const controlDefaultColor = isFullPage || isMiniPlayer ? 'svg-color-default' : 'svg-color-default-light';
    const showSettingsControlBtn = !isMinimize;
    const showFullPageControls = isFullPage && !isSingleSongPlayer;
    const showVolumeControlBtn = !isMobile;
    return (
        <BoxTopHeaderControlsWrapper
            className={additionalClass}
        >
            {showVolumeControlBtn && (
                <OverlayPortalContainer id="volumeContainer">
                    <ActionButton
                        onClick={onVolumePanelToggle}
                        className={controlsWithOverlay.showVolumeChangingPanel ? 'svg-color-prime' : controlDefaultColor}
                        label={
                            volume > 0 ?
                                <FontAwesomeIcon style={{fontSize: 16}} icon={faVolume} /> :
                                <FontAwesomeIcon style={{fontSize: 16}} icon={faVolumeSlash} />
                        }
                    />
                </OverlayPortalContainer>
            )}

            {showSettingsControlBtn && (
                <OverlayPortalContainer id="settingsContainer">
                    <HighlightOnboardingComponent
                        id={ONBOARDING_ID_PLAYER_SETTINGS_PODCAST}
                        content={[
                            <PlayerPodcastContent />
                        ]}
                        temporaryHide={controlsWithOverlay.showSettingsOptionPanel || controlsWithOverlay.showVolumeChangingPanel}
                        pulseAnimation={true}
                        pulseCircle={true}
                        showOverlay={false}
                        place={!isSingleSongPlayer && isFullPage ? 'bottom-left' : 'left'}
                        delay={1000}
                    >
                        <ActionButton
                            onClick={onOpenSettingsToggle}
                            className={controlsWithOverlay.showSettingsOptionPanel ? 'svg-color-prime' : controlDefaultColor}
                            label={<FontAwesomeIcon style={{fontSize: 16}} icon={faEllipsisVertical} />}
                        />
                    </HighlightOnboardingComponent>
                </OverlayPortalContainer>
            )}

            {showFullPageControls && (
                <FullPageBoxControls
                    onShowMiniPlayer={onShowMiniPlayer}
                    onPlayerClose={onPlayerClose}
                />
            )}
        </BoxTopHeaderControlsWrapper>
    );
}

export default BoxControls;