import React, {useEffect} from 'react';
import styled from "styled-components";
import usePlayerContext from "../hooks/usePlayerContext";
import {getThumb} from "../helpers";
import WaveAnimation from "./WaveAnimation";
import {motion} from "framer-motion";
import {DEFAULT_THUMB, DEFAULT_THUMB_QUEUE} from "../constants";

const SongThumb = (props) => {
    const {
        songFileId,
        isActive,
        onClick,
        isQueue = false,
        disableAnimation = false
    } = props;

    const {
        loadedThumbs,
        currentSong,
        queue,
        loadPrimeThumb,
        playerVariant,
        isMobile,
        additionalClass
    } = usePlayerContext();

    const size =  props.width+'x'+props.height;

    useEffect(() => {
        if (!currentSong.fileid || currentSong.fileid !== songFileId) {
            return;
        }
        loadPrimeThumb(currentSong.fileid, size);
    }, [currentSong.fileid, queue]);

    let styles = Object.assign({
        borderRadius: props.borderRadius ? props.borderRadius : 4,
        width: props.width,
        height: props.height,
    }, (props.styles ?? {}));

    const thumbUrl = getThumb(songFileId, loadedThumbs, props.width, props.height);
    return (
        <ThumbContainer style={styles} className={additionalClass + ' thumb-container'}>
            <ThumbWrapper
                style={styles}
                onClick={onClick}
                className={'thumb-wrapper'}
                layout={(playerVariant.isMiniPlayer || isMobile) && !disableAnimation}
                transition={{duration: 0.2}}
            >
                <Thumb
                    css={{backgroundImage: 'url("'+thumbUrl+'"), url("'+(isQueue ? DEFAULT_THUMB_QUEUE : DEFAULT_THUMB)+'")'}}
                />
                {isActive && (
                    <ActiveThumbWrapper
                        style={styles}
                    >
                        <WaveAnimation width={18} height={12} isMini={true} />
                    </ActiveThumbWrapper>
                )}
            </ThumbWrapper>
        </ThumbContainer>
    );
}

export default SongThumb;

const ThumbContainer = styled(motion.div)`
    &.standartPlayer {
        align-self: start;
    }
`;

const ThumbWrapper = styled(motion.div)`
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    display: flex;
    flex-shrink: 0;
    overflow: hidden;
    position: relative;
    align-self: center;
`;

const Thumb = styled.div`
    width: 100%;
    height: 100%;
    background-position: center center;
    background-size: cover;
`;


const ActiveThumbWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .5);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    
    &.invisible {
        opacity: 0;
    }
`;
