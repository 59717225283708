// @flow

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import Componentify from "react-componentify";

import FlowManager from "../TwoFactorAuth/FlowManager";
import TermsStep from "./TermsStep";
import PaymentStep from "./PaymentStep";
import RequestRelocationStep from "./RequestRelocationStep";
import RequestReceivedStep from "./RequestReceivedStep";
import CancelVerificationStep from "./CancelVerificationStep";
import CancelConfirmationStep from "./CancelConfirmationStep";
import CancelSuccessStep from "./CancelSuccessStep";

import * as Step from "../TwoFactorAuth/index";
import { boldConverter } from "../../lib/componentifyConverters";
import { __ } from "../../lib/translate";
import { getGlobalLocationId } from "../../lib/utils";
import apiMethod from "../../api/apiMethod";
import {
  LOCATION_REGIONS,
  US_LOCATIONID,
  EU_LOCATIONID,
  CHANGE_DATA_REGION_STATUS
} from "@pcloud/web-utilities/dist/config/constants";

const modalFlows = {
  sentEmail: [Step.SetupPassStep, Step.SentEmailStep],
  freeTransfer: [TermsStep, RequestRelocationStep, RequestReceivedStep],
  paidTransfer: [TermsStep, RequestRelocationStep, PaymentStep, RequestReceivedStep],
  cancelTransfer: [CancelVerificationStep, CancelConfirmationStep, CancelSuccessStep]
};

const statusListKeys = ["change_region_pending", "change_region_progress", "change_region_done"];

const DataRegionSettings = () => {
  const hasPassword = useSelector(({ user }) => user.userinfo.haspassword);
  const isVerified = useSelector(({ user }) => user.userinfo.emailverified);
  const isBusiness = useSelector(({ user }) => user.userinfo.business);
  const isBusinessMember = isBusiness && useSelector(({ user }) => !user.userinfo.account.owner);
  const isBusinessTrial = isBusiness && !isBusinessMember && useSelector(({ user }) => user.userinfo.account.is_trial);
  const isFamily = useSelector(({ user }) => user.userinfo.family);
  const isFamilyMember = isFamily && useSelector(({ user }) => !user.userinfo.family.owner);
  const isVivacom = useSelector(({ user }) => !!user.userinfo.vivapcloud);
  const currency = useSelector(({ user }) => user.userinfo.currency);
  const hasPaidRelocation = useSelector(({ user }) => user.userinfo.haspaidrelocation);
  const relocationStatus = useSelector(({ user }) => user.relocationInfo.relocationStatus);
  const canRelocate = useSelector(({ user }) => user.relocationInfo.canRelocate);
  const hasFree = useSelector(({ user }) => user.relocationInfo.hasFree);
  const [loading, setLoading] = useState(false);
  const [country, setCountry] = useState("us");

  const [currentFlow, setCurrentFlow] = useState([]);
  const dispatch = useDispatch();

  const [shouldCloseOnEscape, setshouldCloseOnEscape] = useState(true);

  const currentLocationId = getGlobalLocationId();
  const changeToLocationId = currentLocationId === EU_LOCATIONID ? US_LOCATIONID : EU_LOCATIONID;
  const currentLocationKey = LOCATION_REGIONS[currentLocationId].key;

  useEffect(() => {
    apiMethod(
      "getip",
      {},
      ({ country }) => {
        setCountry(country);
      },
      {
        errorCallback: ({ result, error }) => {
          throw new Error(error);
        }
      }
    );
  }, [])

  const onChangeClick = () => {
    if (!isVerified) {
      HFN.message(__("Please verify your email address to perform this action."), "error");
      return;
    } else if (!canRelocate) {
      HFN.message(
        __(
          "error_2333",
          "You have recently moved this account. It's not possible to move it again so soon."
        ),
        "error"
      );
      return;
    } else if (isFamilyMember) {
      HFN.message(__("family_member_relocation_message"), "error");
    } else if (isBusinessMember) {
      HFN.message(__("business_user_relocation_message"), "error");
    } else if (!hasPassword) {
      setCurrentFlow(modalFlows["sentEmail"]);
    } else if (!hasFree && !hasPaidRelocation) {
      setCurrentFlow(modalFlows["paidTransfer"]);
    } else {
      setCurrentFlow(modalFlows["freeTransfer"]);
    }
  };

  const onCancelClick = () => {
    setCurrentFlow(modalFlows["cancelTransfer"]);
  };

  const onCloseClick = () => {
    setCurrentFlow([]);
  };

  const renderButton = () => {
    if (isBusiness && isBusinessMember) {
      return null;
    } else if (relocationStatus === CHANGE_DATA_REGION_STATUS["IN_PROGRESS"]) {
      return null;
    } else if (relocationStatus === CHANGE_DATA_REGION_STATUS["IN_QUEUE"]) {
      return <Button onClick={onCancelClick}>{__("change_region_cancel_button")}</Button>;
    } else if (
      relocationStatus === CHANGE_DATA_REGION_STATUS["CANCELED"] ||
      relocationStatus === CHANGE_DATA_REGION_STATUS["DEFAULT"]
    ) {
      return <Button onClick={onChangeClick}>{__("region_setting_change_button")}</Button>;
    }
  };

  const renderRightCol = () => {
    if (isBusinessTrial || isVivacom) {
      return null;
    }

    return (
      <React.Fragment>
        {statusListKeys[relocationStatus] ? (
          <React.Fragment>
            <Status>
              {__("region_setting_status")}
              {": "}
              <b>{__(statusListKeys[relocationStatus])}</b>
            </Status>
            {relocationStatus !== CHANGE_DATA_REGION_STATUS["IN_PROGRESS"] ? <SeparationLine /> : null}
          </React.Fragment>
        ) : null}
        {renderButton()}
      </React.Fragment>
    );
  };

  return (
    <Wrapper>
      <Title className="row-title">{__("data_region", "Data Region")}</Title>
      <Row>
        <Col>
          <Componentify
            text={__(
              "region_setting_description",
              "Your files and data are currently being stored in <b>%locationA%</b>.",
              { locationA: __(currentLocationKey) }
            )}
            converters={[boldConverter]}
          />
        </Col>
        <Col>{renderRightCol()}</Col>
      </Row>
      <FlowManager
        flow={currentFlow}
        onClose={onCloseClick}
        hasFree={hasFree}
        hasPaidRelocation={hasPaidRelocation}
        currency={currency}
        currentLocationId={currentLocationId}
        changeToLocationId={changeToLocationId}
        shouldCloseOnEscape={shouldCloseOnEscape}
        loading={loading}
        setLoading={setLoading}
        country={country}
      />
    </Wrapper>
  );
};

export default DataRegionSettings;

const Wrapper = styled.div``;

const Title = styled.div`
  font-size: 15px;
  font-weight: bold;
  height: 31px;
  padding-top: 17px;
  padding-left: 2px;
  margin: 10px 0 0 0;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 2px solid #ddd;
  background: #fafafa;
  border-bottom: 1px solid #e9e9e9;
  padding: 15px 8px 15px 8px;
  font-size: 13px;
  line-height: 18px;
  margin: 0;
`;

const Col = styled.div`
  display: flex;
  /* width: 100%; */
  margin: 0px 10px;

  &:first-of-type {
    display: inline-block;
    margin-left: 0px;
  }

  &:last-of-type {
    margin-right: 0px;
  }
`;

const Status = styled.div`
  display: inline-block;
`;

const SeparationLine = styled.div`
  display: inline-block;
  width: 1px;
  height: 100%;
  background-color: #e9e9e9;
  margin: 0px 10px;
`;

const Button = styled.div`
  color: #17bed0;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
`;
