// @flow

import React, { useState, useEffect } from "react";

import Modal, { OvalModalWrap, OvalModalCloseV2 } from "../../Modal";
import CopyLinkModal from "../../../containers/FamilyPlan/CopyLinkModal";

const CopyInviteLinkModal = ({ link = "" }: { link: string }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(true);
  }, []);

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Modal animate onClose={handleClose} opened={isOpen}>
      <OvalModalWrap>
        <OvalModalCloseV2 onClick={handleClose} />
        <CopyLinkModal title={"shared_links_settings_copy_link"} currentItem={{ invitelink: link }} onClose={handleClose} />
      </OvalModalWrap>
    </Modal>
  );
};

export default CopyInviteLinkModal;
