// @flow

import React, { useState, useEffect, useCallback } from "react";

import Modal, { OvalModalWrap, CloseModalIcon } from "../Modal";
import Button from "../ButtonDefault";
import * as Style from "./styledComponents";

import apiMethod from "../../api/apiMethod";
import { __ } from "../../lib/translate";
import { errorKeys } from "../../lib/errors";

const DeleteCommentModal = ({ comment, meta }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
  };

  const onEnter = useCallback(
    (e: any) => {
      if (e.keyCode === 13) {
        onDeleteClick();
      }
    },
    [onDeleteClick]
  );

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("keyup", onEnter);
    } else {
      document.removeEventListener("keyup", onEnter);
    }
  }, [isOpen, onEnter]);

  const onDeleteClick = useCallback(() => {
    const { id } = comment;
    const { isfolder, folderid, fileid } = meta;

    const params = { commentid: id };
    if (isfolder) {
      params.folderid = folderid;
    } else {
      params.fileid = fileid;
    }

    if (isLoading) {
      return;
    }

    setIsLoading(true);
    apiMethod(
      "commentdelete",
      params,
      () => {
        handleClose();
        PCB.removeComment($.extend({}, comment, params));
      },
      {
        errorCallback: ({ result, error }) => {
          if (errorKeys[result]) {
            HFN.message(__(errorKeys[result]), "error");
          } else {
            handleClose();
            HFN.message(__("something_went_wrong_refresh_and_try_again"), "error");
            throw new Error(error);
          }
        }
      }
    );
  });

  return (
    <Modal animate onClose={handleClose} opened={isOpen}>
      <OvalModalWrap>
        <CloseModalIcon onClick={handleClose} />
        <Style.Container>
          <Style.Header>{__("Delete Comment", "Delete comment")}</Style.Header>
          <Style.Message> {__("Are you sure want to delete this comment?")}</Style.Message>
          <Style.Footer>
            <Button
              color="lightgray4"
              style={{
                marginRight: "5px"
              }}
              onClick={handleClose}
            >
              {__("Cancel")}
            </Button>
            <Button
              loading={isLoading}
              color="red"
              style={{
                marginLeft: "5px"
              }}
              disabledColor="#dddddd"
              onClick={onDeleteClick}
            >
              {__("Delete")}
            </Button>
          </Style.Footer>
        </Style.Container>
      </OvalModalWrap>
    </Modal>
  );
};

export default DeleteCommentModal;
