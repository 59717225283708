// @flow

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import PhoneInput from "react-phone-input-2";
import "../../../../node_modules/react-phone-input-2/lib/style.css";
import Componentify from "react-componentify";

import Modal, { OvalModalWrap, OvalModalCloseV2 } from "../../Modal.js";
import Button from "../../ButtonDefault.js";
import * as Style from "../styledComponents.js";
import InputTextarea from "../../InputTextarea.js";

import apiMethod from "../../../api/apiMethod.js";
import { __ } from "../../../lib/translate";
import { linkConverter } from "../../../lib/componentifyConverters.js";

const RequestMoreSpaceModalBusinessPro = () => {
  const [userCountry, setUserCountry] = useState("auto");
  const [step, setStep] = useState(1);
  const [isOpen, setIsOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [dialCode, setDialCode] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [city, setCity] = useState("");
  const [countryField, setCountryField] = useState("");
  const [question1Answer, setQuestion1Answer] = useState("");
  const [question2Answer, setQuestion2Answer] = useState("");

  const userEmail = useSelector(({ user }) => user.userinfo.email);
  const userAuth = useSelector(({ user }) => user.token);

  const [errorFields, setErrorFields] = useState({
    firstName: false,
    lastName: false,
    companyName: false,
    phoneNumber: false,
    companyAddress: false,
    city: false,
    countryField: false,
    question1Answer: false,
    question2Answer: false
  });

  useEffect(() => {
    apiMethod(
      "getip",
      {},
      ({ country }) => {
        setUserCountry(country);
      },
      {
        errorCallback: ({ result, error }) => {
          throw new Error(error);
        }
      }
    );
  }, []);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleBack = () => {
    setStep(1);
  }

  const onMessageChange = ({ target }) => {
    setMessage(target.value);
  };

  const onFirstNameChange = ({ target }) => {
    if (errorFields.firstName && target.value) {
      const newInputValidationObj = { ...errorFields };
      newInputValidationObj.firstName = false;
      setErrorFields(newInputValidationObj);
    }

    setFirstName(target.value);
  };

  const onLastNameChange = ({ target }) => {
    if (errorFields.lastName && target.value.trim()) {
      const newInputValidationObj = { ...errorFields };
      newInputValidationObj.lastName = false;
      setErrorFields(newInputValidationObj);
    }

    setLastName(target.value);
  };

  const onCompanyNameChange = ({ target }) => {
    if (errorFields.companyName && target.value.trim()) {
      const newInputValidationObj = { ...errorFields };
      newInputValidationObj.companyName = false;
      setErrorFields(newInputValidationObj);
    }

    setCompanyName(target.value);
  };

  const onPhoneChange = (value: any, countryData: any, e: any, formattedValue: string) => {
    const numberValue = formattedValue.replace(/[^0-9+]/g, "");

    if (errorFields.phoneNumber && numberValue) {
      const newInputValidationObj = { ...errorFields };
      newInputValidationObj.phoneNumber = false;
      setErrorFields(newInputValidationObj);
    }

    const { dialCode } = countryData;
    setPhoneNumber(numberValue);
    setDialCode(dialCode)
    // this.setState({
    //   inputPhoneValue: value.replace(/[^0-9+]/g, ""),
    //   isPhoneNumberValid: isValid,
    //   showErrorMessage: false,
    //   dialCode: dialCode,
    //   phoneNumber: number
    // });
  };

  const onCompanyAddressChange = ({target}) => {
    if (errorFields.companyAddress && target.value.trim()) {
      const newInputValidationObj = { ...errorFields };
      newInputValidationObj.companyAddress = false;
      setErrorFields(newInputValidationObj);
    }

    setCompanyAddress(target.value);
  };

  const onCityChange = ({target}) => {
    if (errorFields.city && target.value.trim()) {
      const newInputValidationObj = { ...errorFields };
      newInputValidationObj.city = false;
      setErrorFields(newInputValidationObj);
    }

    setCity(target.value.trim());
  };

  const onCountryChange = ({target}) => {
    if (errorFields.countryField && target.value.trim()) {
      const newInputValidationObj = { ...errorFields };
      newInputValidationObj.countryField = false;
      setErrorFields(newInputValidationObj);
    }

    setCountryField(target.value);
  }

  const onQuestion1AnswerChange = ({target}) => {
    if (errorFields.question1Answer && target.value.trim()) {
      const newInputValidationObj = { ...errorFields };
      newInputValidationObj.question1Answer = false;
      setErrorFields(newInputValidationObj);
    }

    setQuestion1Answer(target.value);
  }

  const onQuestion2AnswerChange = ({target}) => {
    if (errorFields.question2Answer && target.value.trim()) {
      const newInputValidationObj = { ...errorFields };
      newInputValidationObj.question2Answer = false;
      setErrorFields(newInputValidationObj);
    }

    setQuestion2Answer(target.value);
  }

  const onEnter = (e: any) => {
    if (e.keyCode === 13) {
      onSendClick();
    }
  };

  const validateStep1Fields = () => {
    const newInputValidationObj = { ...errorFields };
    let validInputs = true;

    if (!firstName.length) {
      newInputValidationObj.firstName = true;
      validInputs = false;
    }

    if (!lastName.length) {
      newInputValidationObj.lastName = true;
      validInputs = false;
    }

    if (!companyName.length) {
      newInputValidationObj.companyName = true;
      validInputs = false;
    }

    if (!phoneNumber.length) {
      newInputValidationObj.phoneNumber = true;
      validInputs = false;
    }

    if (!companyAddress.length) {
      newInputValidationObj.companyAddress = true;
      validInputs = false;
    }

    if (!city.length) {
      newInputValidationObj.city = true;
      validInputs = false;
    }

    if (!countryField.length) {
      newInputValidationObj.countryField = true;
      validInputs = false;
    }

    setErrorFields(newInputValidationObj);

    return validInputs;
  };

  const validateStep2Fields = () => {
    const newInputValidationObj = { ...errorFields };
    let validInputs = true;

    if (!question1Answer.length) {
      newInputValidationObj.question1Answer = true;
      validInputs = false;
    }

    if (!question2Answer.length || question2Answer.length < 100 || question2Answer.length > 1000) {
      newInputValidationObj.question2Answer = true;
      validInputs = false;
    }

    setErrorFields(newInputValidationObj);

    return validInputs;
  };

  const onContinueClick = (e) => {
    e.preventDefault();

    if (validateStep1Fields()) {
      setStep(2);
    }
  }

  const onSendClick = (e) => {
    e.preventDefault();

    if (isLoading || !validateStep2Fields()){
      return;
    }

    setIsLoading(true);
    apiMethod(
      "feedback",
      {
        auth: userAuth,
        mail: userEmail,
        firstname: firstName.trim(),
        lastname: lastName.trim(),
        companyname: companyName.trim(),
        phone: `+${dialCode}${phoneNumber.trim()}`,
        companyaddress: companyAddress.trim(),
        city: city.trim(),
        country: countryField.trim(),
        reason: "Request for more space",
        requestedstorage: question1Answer.trim(),
        message: question2Answer.trim(),
        feedbacktype: "contactsalesmorespace"
      },
      () => {
        handleClose();
        HFN.message(__("Your message was sent successfully!"));
      },
      {
        errorCallback: ({ error, result }) => {
          setIsLoading(false);
          let errorMessage = __("something_went_wrong_refresh_and_try_again");

          if (result === 2076) {
            errorMessage = __("error_2076", "You do not have the permissions for this action.");
          }
          
          if (result === 2255) {
            errorMessage = __("BusinessPro_storage_modal_error", "You have already submitted an Additional storage request form");
          }

          HFN.message(errorMessage, "error");
          throw new Error(error);
        },
        onXhrError: () => {
          setIsLoading(false);
          HFN.message(__("something_went_wrong_refresh_and_try_again"), "error");
        }
      }
    );
  };

  const renderStep = () => {
    if (step === 1) {
      return (
        <React.Fragment>
          <Heading>{__("BusinessPro_storage_modal_title", "Additional storage request form")}</Heading>
          <Subheading>{__("BusinessPro_storage_modal_subtitle", "In order to receive more storage, please take a few moments to fill in and submit the form below.")}</Subheading>
          <Form onSubmit={onContinueClick}>
            <Style.InputGridTable className="template-2x4">
              <Style.InputWrapper
                invalid={errorFields.firstName}
                className="r1c1"
              >
                <Label
                  htmlFor="firstName"
                >
                  {__("BusinessPro_storage_modal_body1", "First Name")}*
                </Label>
                <Style.Input
                  autoFocus
                  value={firstName}
                  onChange={onFirstNameChange}
                  type="text"
                  id="firstName"
                  name="firstName"
                  autocomplete="given-name"
                  placeholder={__("BusinessPro_storage_modal_body1", "First Name")}
                />
              </Style.InputWrapper>
              <Style.InputWrapper
                className="r1c2"
                invalid={errorFields.lastName}
              >
                <Label
                  htmlFor="lastName"
                >
                  {__("BusinessPro_storage_modal_body2", "Last Name")}*
                </Label>
                <Style.Input
                  placeholder={__("BusinessPro_storage_modal_body2", "Last Name")}
                  value={lastName}
                  onChange={onLastNameChange}
                  type="text"
                  id="lastName"
                  name="lastName"
                  autocomplete="family-name"
                />
              </Style.InputWrapper>
              <Style.InputWrapper
                className="r2c1"
                invalid={errorFields.companyName}
              >
                <Label
                  htmlFor="companyName"
                >
                  {__("BusinessPro_storage_modal_body3", "Company Name")}*
                </Label>
                <Style.Input
                  placeholder={__("BusinessPro_storage_modal_body3", "Company Name")}
                  value={companyName}
                  onChange={onCompanyNameChange}
                  type="text"
                  id="companyName"
                  name="companyName"
                  autocomplete="organization"
                />
              </Style.InputWrapper>
              <Style.InputWrapper
                className="r2c2"
                invalid={errorFields.phoneNumber}
              >
                <Label
                  htmlFor="phoneNumber"
                >
                  {__("BusinessPro_storage_modal_body7", "Phone number")} *
                </Label>
                <PhoneInput 
                  key={userCountry}
                  value={phoneNumber}
                  country={userCountry}
                  placeholder={__("Phone number")}
                  onChange={onPhoneChange}
                  inputProps={{
                    id: "phoneNumber",
                    name: "phoneNumber"
                  }}
                  inputClass="phone-input"
                />
              </Style.InputWrapper>
              <Style.InputWrapper
                className="r3c1"
                invalid={errorFields.companyAddress}
              >
                <Label
                  htmlFor="companyAddress"
                >
                  {__("BusinessPro_storage_modal_body4", "Company Address")}*
                </Label>
                <Style.Input
                  placeholder={__("BusinessPro_storage_modal_body4", "Company Address")}
                  value={companyAddress}
                  onChange={onCompanyAddressChange}
                  type="text"
                  id="companyAddress"
                  name="companyAddress"
                  autocomplete="street-address"
                />
              </Style.InputWrapper>
              <Style.InputWrapper
                className="r4c1"
                invalid={errorFields.city}
              >
                <Label
                  htmlFor="city"
                >
                  {__("BusinessPro_storage_modal_body5", "City")} *
                </Label>
                <Style.Input
                  placeholder={__("BusinessPro_storage_modal_body5", "City")}
                  value={city}
                  onChange={onCityChange}
                  type="text"
                  id="city"
                  name="city"
                  autocomplete="address-level2"
                />
              </Style.InputWrapper>
              <Style.InputWrapper
                className="r4c2"
                invalid={errorFields.countryField}
              >
                <Label
                  htmlFor="country"
                >
                  {__("BusinessPro_storage_modal_body6", "Country")} *
                </Label>
                <Style.Input
                  placeholder={__("BusinessPro_storage_modal_body6", "Country")}
                  value={countryField}
                  onChange={onCountryChange}
                  type="text"
                  id="country"
                  name="country"
                  autocomplete="country-name"
                />
              </Style.InputWrapper>
            </Style.InputGridTable>
            <Style.Footer>
              <Button
                color="lightgray4"
                style={{
                  marginRight: "6px"
                }}
                onClick={handleClose}
              >
                {__("Cancel")}
              </Button>
              <Button
                color="cyan"
                // loading={isLoading}
                style={{
                  marginLeft: "6px"
                }}
                type="submit"
                // disabledColor="#dddddd"
                onClick={onContinueClick}
              >
                {__("Continue")}
              </Button>
            </Style.Footer>
          </Form>
        </React.Fragment>
      )
    }

    if (step === 2) {
      return (
        <React.Fragment>
          <Form onSubmit={onSendClick}>
            <Style.InputGridTable className="template-1x2">
              <Style.InputWrapper
                className="r1c1"
                invalid={errorFields.question1Answer}
              >
                <Label htmlFor="q1">
                  {__("BusinessPro_storage_modal_question1", "What is the total amount of storage that you need for you and your team?")} *
                </Label>
                <Style.TextArea
                  style={{ width: "100%", height: "50px", padding: "10px", boxSizing: "border-box" }}
                  value={question1Answer}
                  name="q1"
                  id="q1"
                  onChange={onQuestion1AnswerChange}
                  autoFocus
                />
              </Style.InputWrapper>
              <Style.InputWrapper
                className="r2c1"
                invalid={errorFields.question2Answer}
              >
                <Label htmlFor="q2">
                  {__("BusinessPro_storage_modal_question2", "How do you intend to use your account's storage?")} *
                </Label>
                <Style.TextArea
                  style={{ width: "100%", height: "50px", padding: "10px", boxSizing: "border-box" }}
                  value={question2Answer}
                  name="q2"
                  id="q2"
                  onChange={onQuestion2AnswerChange}
                  min={100}
                />
                <Style.Requirements>{__("request_space_field_limitations")}</Style.Requirements>
              </Style.InputWrapper>
            </Style.InputGridTable>
            <Style.Footer>
              <Button
                color="lightgray4"
                style={{
                  marginRight: "6px"
                }}
                onClick={handleBack}
              >
                {__("Back")}
              </Button>
              <Button
                color="cyan"
                loading={isLoading}
                style={{
                  marginLeft: "5px"
                }}
                disabledColor="#dddddd"
                onClick={onSendClick}
              >
                {__("Send")}
              </Button>
            </Style.Footer>
            <DisclaimerList>
              <li>
                <Componentify
                  text={__("BusinessPro_storage_modal_disclaimer1")}
                  converters={[linkConverter]}
                />
              </li>
              <li>{__("BusinessPro_storage_modal_disclaimer2")}</li>
            </DisclaimerList>
          </Form>
        </React.Fragment>
      )
    }
  }

  return (
    <Modal shouldCloseOnOverlayClick={false} animate onClose={handleClose} opened={isOpen}>
      <OvalModalWrap>
        <OvalModalCloseV2 onClick={handleClose} />
        <Style.Container>
          {renderStep()}
        </Style.Container>
      </OvalModalWrap>
    </Modal>
  );
};

export default RequestMoreSpaceModalBusinessPro;

const Heading = styled(Style.Header)`
  margin-bottom: 8px;
`;

const Subheading = styled(Style.Subheading)`
  font-size: 15px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 24px;
`;

const Form = styled.form``;

const Label = styled.label`
  display: block;
  font-size: 13px;
  font-weight: 500;
  text-align: left;
  margin-bottom: 2px;
`;

const DisclaimerList = styled.ol`
  padding: 0px;
  margin: 20px 0px 0px 10px;
  font-weight: normal;
  color: #999;
  font-size: 10px;
  text-align: left;

  li {
    margin: 5px auto;

    &:first-of-type {
      margin-top: 0px;
    }

    &:last-of-type {
      margin-bottom: 0px;
    }
  }

  a {
    color: currentColor;
    text-decoration: none;
    font-weight: bold;
  }
`;
