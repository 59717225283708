// @flow

import React, { useState, useEffect, useRef } from "react";
import Componentify from "react-componentify";

import Modal, { OvalModalWrap, CloseModalIcon } from "../Modal";
import Button from "../ButtonDefault";
import * as Style from "./styledComponents";
import InputText from "../InputText";
import InputStyledCheckbox from "../InputStyledCheckbox";

import createFolder from "../../api/createfolder";
import { spanClassTagConverter } from "../../lib/componentifyConverters";
import { __ } from "../../lib/translate";
import { getDefaultFolderName } from "../../lib/utils";

import useIsMount from "../../../web-shared/hooks/useIsMount";

const CreateFolderModal = ({ folderid = 0 }: { folderid: number }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [folderName, setFolderName] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [shouldOpenAfter, setShouldOpenAfter] = useState(JSON.parse(localStorage.getItem('shouldOpenAfter')) || false);
  const isPublink = HFN.config.isDlink();

  const isMount = useIsMount();
  const inputTextRef = useRef(null);

  useEffect(() => {
    var inputName = inputTextRef.current;

    createSelection(inputName, 0, inputName.value.length);
    inputName.focus();
    setFolderName(getDefaultFolderName(folderid));
  }, [isMount]);

  useEffect(() => {
    localStorage.setItem('shouldOpenAfter', shouldOpenAfter);
  }, [shouldOpenAfter]);

  const handleClose = () => {
    setIsOpen(false);
    setError("");
    setFolderName("");
  };

  const onFolderNameChange = ({ target }) => {
    setFolderName(target.value);
    if (error) {
      setError(__(""));
    }
  };

  const onEnter = (e: any) => {
    if (e.keyCode === 13) {
      onCreateButtonClick();
    }
  };

  const onCreateButtonClick = () => {
    if (isLoading) {
      console.log('clicked loading');
      return;
    }
    setIsLoading(true);
    if (folderName.trim().length < 1) {
      setError(__("Folder name is required."));
      setFolderName("");
      setIsLoading(false);
      return;
    }

    const regex = new RegExp('[><"/]+');
    if (regex.test(folderName)) {
      setError(__("share_folder_modal_error_folder_name_special_chars"));
      setIsLoading(false);
      return;
    }

    const parentFolder = HFN.data.fflookup["d" + folderid];

    createFolder(
      { parent: parentFolder, folderName },
      {
        shouldOpenAfter,
        onSuccess: () => {
          setIsOpen(false);
        },
        onError: () => {
          setIsOpen(false);
          setIsLoading(false);
        }
      }
    );
  };

  let title = __("Create Folder");
  if (HFN.data.fflookup["d" + folderid]) {
    title = __(
      "create_new_folder_header",
      `Create folder in "<span class='ellipsis'>%name%</span>"`,
      {
        name: HFN.metaName(HFN.data.fflookup["d" + folderid])
      }
    );
  }

  return (
    <Modal animate onClose={handleClose} opened={isOpen}>
      <OvalModalWrap>
        <CloseModalIcon onClick={handleClose} />
        <Style.Container>
          <Style.Header>
            <Componentify text={title} converters={[spanClassTagConverter]} />
          </Style.Header>
          {error ? (
            <Style.ErrorMessage>{error}</Style.ErrorMessage>
          ) : (
            <Style.Label>{__("Name", "Name")}</Style.Label>
          )}
          <InputText
            innerRef={inputTextRef}
            name="folderName"
            placeholder={__("Folder name")}
            onChange={onFolderNameChange}
            value={folderName}
            autoFocus
            onKeyDown={onEnter}
          />
          <Style.CheckboxWrapper>
            <Style.CheckboxLabel for="open">
              <InputStyledCheckbox
                id="open"
                name="openLocationAfter"
                size="small"
                checked={shouldOpenAfter}
                onChange={e => setShouldOpenAfter(e.target.checked)}
              />
              <Style.CheckboxLabelText>{__("Open after creation")}</Style.CheckboxLabelText>
            </Style.CheckboxLabel>
          </Style.CheckboxWrapper>
          <Style.Footer>
            <Button
              color="lightgray4"
              style={{
                marginRight: "5px"
              }}
              onClick={handleClose}
            >
              {__("Cancel")}
            </Button>
            <Button
              loading={isLoading}
              disabled={isLoading}
              color="cyan"
              style={{
                marginLeft: "5px"
              }}
              disabledColor="#dddddd"
              onClick={onCreateButtonClick}
            >
              {__("Create")}
            </Button>
          </Style.Footer>
        </Style.Container>
      </OvalModalWrap>
    </Modal>
  );
};

export default CreateFolderModal;
