import React, { Component } from "react";
import styled from "styled-components";
import $ from "jquery";
import apiMethod from "../api/apiMethod";
import GridDownloadLinkInvites from "../components/downloadlink/Invites";

export default class DLInvites extends Component {
  constructor(props) {
    super(props);

    this.state = { list: [], loading: true };
  }

  _load() {
    const { linkid, token } = this.props;

    this.xhr = apiMethod(
      "getpublinkopen",
      { linkid: linkid, auth: token },
      ret => this.setState({ list: ret.opens, loading: false })
    );

    /*
    let xhrs = [];
    let invites = [];
    let views = [];

    xhrs.push(
      apiMethod(
        "getpublinkinvites",
        { linkid: linkid, auth: token },
        ret => (invites = ret.invites)
      )
    );

    xhrs.push(
      apiMethod(
        "getpublinkuserviews",
        { linkid: linkid, auth: token },
        ret => (views = ret.views)
      )
    );

    $.when.apply($, xhrs).then(r => {
      views.forEach(view => (view.open = true));
      this.setState({
        list: invites.concat(views),
        loading: false
      });
      this.xhrs = [];
    });

    this.xhrs = xhrs;
    */
  }

  componentDidMount() {
    this._load();
  }

  componentWillUnmount() {
    console.log("unmounting", this.xhr);

    if (this.xhr) {
      this.xhr.abort();
    }

    //this.xhrs.forEach(xhr => xhr.abort());
  }

  render() {
    const { loading, list } = this.state;

    if (loading) {
      return <Loading />;
    }

    return <GridDownloadLinkInvites list={list} {...this.props} />;
  }
}

const Loading = styled.div`
  width: 100%;
  height: 50px;
  background: url(${require("../../root/img/loading.gif")}) no-repeat center center;
`;
