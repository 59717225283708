import styled from "styled-components";

export const Content = styled.div`
  padding: 30px;
  max-width: 480px;
  box-sizing: border-box;
  text-align: center;
  min-height: 220px;
  width: calc(100vw - 20px);

  @media (max-width: 500px) {
    padding: 15px;
  }
`;

export const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 25px;
  text-align: center;
  align-self: center;
`;

export const Text = styled.div`
  display: inline-block;
  font-size: 15px;
  text-align: center;
  margin-bottom: 30px;
`;

export const Body = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  min-height: 120px;
`;

export const ButtonWrapper = styled.div`
  text-align: center;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  gap: 5px;

  a {
    display: inline-block;
    margin: 0;
    max-width: 200px;
    font-weight: normal;
    font-size: 15px;
    width: 100%;

    @media (max-width: 450px) {
      font-size: 12px;
      padding: 0;
    }
  }
`;
