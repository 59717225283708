// @flow
import React, { useState } from "react";

import EnterPassBusiness from "../components/changecryptopass/EnterPassBusiness";
import SetNewPassBusiness from "../components/changecryptopass/SetNewPassBusiness";
import Alert, { AttIcon } from "../components/Alert";


const STEP_ENTER_PASS = 1;
const STEP_SET_NEW_PASS = 2;

type Props = {
  userId: ?integer,
  teamId: ?integer,
};

const ChangeCryptoPass = ({ userId, teamId } : Props) => {
  const [currentStep, setStep] = useState(STEP_ENTER_PASS);

  let description;
  if (userId) {
    description = __('change_pass_from_owner_to_user_desc');
  } else {
    description = __('change_pass_from_owner_to_team_desc');
  }

  return <React.Fragment>
    {currentStep == STEP_ENTER_PASS ? <EnterPassBusiness description={__("crypto_enter_password_owner_desc")} onPassOk={() => setStep(STEP_SET_NEW_PASS)} /> : null }
    {currentStep == STEP_SET_NEW_PASS ?
      <div>
        <Alert theme="warning" centerText multiline hasIcon directionColumn style={{width: "682px", marginBottom: "20px"}}>{description}</Alert>
        <SetNewPassBusiness userId={userId} teamId={teamId} onPassChanged={() => setStep(STEP_ENTER_PASS)} />
      </div>
     : null }
  </React.Fragment>
};

export default ChangeCryptoPass;
