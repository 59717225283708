// @flow
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { hiDPI } from "polished";

import Componentify from "react-componentify";
import { __ } from "../lib/translate";
import apiMethod from "../api/apiMethod";
import { userInitials, getNames } from "../lib/user-utils";
import { boldConverter } from "../lib/componentifyConverters";

import Tooltip from "../components/Tooltip";
import Alert, { CenterIcon, AttIcon } from "../components/Alert";
import { Title, Row, Col, Border } from "../components/tables/dark";
import { AvatarWrap } from "../components/UserAvatar";
import Button from "../components/ButtonDefault";
import Modal, { ModalTop, ModalBody, ModalButtonArea } from "../components/Modal";
import { LS_CRYPTO_TRANSITION_NOTIFIED_USERS_KEY, LS_CRYPTO_TRANSITION_STARTED } from "@pcloud/web-utilities/dist/config/constants"

const getActiveUsersWithCryptoWithOutOwner = user => {
  return user.cryptosetup === true && user.active === true && user.id != HFN.config.user.userid;
};

const UpdateCrypto = () => {
  const [userList, setUserList] = useState([]);
  const token = useSelector(({ user }) => user.token);
  const [ownerNotified, setOwnerNotified] = useState(false);
  const [notifiedUsers, setNotifiedUsers] = useState(
    JSON.parse(localStorage.getItem(LS_CRYPTO_TRANSITION_NOTIFIED_USERS_KEY)) || []
  );
  const [sendingNotifications, setSendingNotifications] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [forceResetSent, setForceResetSent] = useState(false);

  useEffect(() => {
    apiMethod("account_users", { auth: token }, ({ users }) => {
      setUserList(users.filter(getActiveUsersWithCryptoWithOutOwner));
    });

    localStorage.setItem(LS_CRYPTO_TRANSITION_STARTED, true);
  }, []);

  const notifyAll = () => {
    const wait = [];
    userList.forEach(user => {
      wait.push(
        apiMethod("account_cryptoresetmail", { auth: token, userid: user.id }, res => {
          res.userid = user.id;
          return res;
        })
      );
    });
    setSendingNotifications(true);
    Promise.all(wait).then(results => {

      let notifiedUsers = results.filter(res => res && res.result == 0).map(res => res.userid);

      localStorage.setItem(LS_CRYPTO_TRANSITION_NOTIFIED_USERS_KEY, JSON.stringify(notifiedUsers));

      setNotifiedUsers(notifiedUsers);
      if (notifiedUsers.length == wait.length) {
        HFN.message(__("crypto_reset_notifications_sent"));
      } else {
        HFN.message(__("something_went_wrong_refresh_and_try_again"), "error");
      }
      setSendingNotifications(false);
    });
  };

  const notify = userid => {
    apiMethod("account_cryptoresetmail", { auth: token, userid: userid }, () => {
      notifiedUsers.push(userid);
      localStorage.setItem(LS_CRYPTO_TRANSITION_NOTIFIED_USERS_KEY, JSON.stringify(notifiedUsers));
      HFN.message(__("crypto_reset_notification_sent"));
      setNotifiedUsers(notifiedUsers);
    });
  };

  const resetOwnerCrypto = () => {
    apiMethod("crypto_reset", { auth: token }, () => {
      setOwnerNotified(true);
    });
  };

  const forceReset = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const sendForceResetMail = () => {
    apiMethod("account_cryptoforceresetmail", { auth: token }, () => {
      setForceResetSent(true);
    });
  };

  return (
    <Wrap>
      <Alert theme="warning" centerText multiline hasIcon directionColumn>
        {__("update_crypto_warning_active_old_crypto_folders")}
      </Alert>
      <Space />
      <Title size="small">{__("crypto_update_owner_heading", "Owner (you)")}</Title>
      <Row className="border-all condensed" borderColor="#eee">
        {!ownerNotified ? (
          <React.Fragment>
            <Col>
              <AvatarWrap size={24} textSize="12px">
                {userInitials(HFN.config.user.account)}
              </AvatarWrap>
              <User>{getNames(HFN.config.user)}</User>
            </Col>
            <Col className="go-right" style={{ paddingTop: "2px" }}>
              <Button
                onClick={resetOwnerCrypto}
                size="xm"
                fontWeight="400"
                color="redReset"
                borderRadius="3"
              >
                {__("reset_crypto")}
              </Button>
            </Col>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {__("An email with instructions has been sent to")} <b>{HFN.config.user.email}</b>.{" "}
            {__("Please, follow the steps to reset your Crypto")}
          </React.Fragment>
        )}
      </Row>

      <Space />
      {userList.length ? (
        <React.Fragment>
          <Title size="small">{__('crypto_update_users_with_crypto_list_heading')}</Title>
          <Border color="#eee">
            {userList.map((user, i) => {
              return (
                <Row
                  key={user.id}
                  bg={(i + 1) % 2 ? "#fafafa" : "#fff"}
                  className="no-border condensed"
                >
                  <Col>
                    <AvatarWrap size={24} textSize="12px">
                      {userInitials(user)}
                    </AvatarWrap>
                    <User>{getNames(user)}</User>
                  </Col>
                  <Col className="go-right">
                    {notifiedUsers.includes(user.id) ? (
                      <Tooltip title={__("crypto_reset_member_notified")}>
                        <BellNotified />
                      </Tooltip>
                    ) : (
                      <Bell onClick={() => notify(user.id)} />
                    )}
                  </Col>
                </Row>
              );
            })}
          </Border>
          <Space />
          <Button
            onClick={notifyAll}
            size="big"
            color="green"
            borderRadius="3"
            style={{ maxWidth: "260px", float: "left" }}
            loading={sendingNotifications}
          >
            {__("crypto_notify_member_to_reset_button")}
          </Button>
          <Button
            onClick={forceReset}
            size="big"
            color="redReset"
            borderRadius="3"
            style={{ maxWidth: "260px", float: "right" }}
          >
            {__("crypto_transition_force_reset_button")}
          </Button>
        </React.Fragment>
      ) : null}

      <Modal opened={showModal} onClose={closeModal}>
        <ModalTop onClose={closeModal}>{__("Force reset")}</ModalTop>
        <ModalBody style={{ width: "400px" }}>
          {forceResetSent ? (
            <Componentify
              text={__("force_crypto_reset_modal_mail_sent", "", { email: HFN.config.user.email })}
              converters={[boldConverter]}
            />
          ) : (
            __("force_crypto_reset_modal_body")
          )}
        </ModalBody>
        <ModalButtonArea>
          {forceResetSent ? (
            <Button color="black" onClick={closeModal} inline>
              {__("Close")}
            </Button>
          ) : (
            <React.Fragment>
              <Button
                color="red"
                onClick={sendForceResetMail}
                inline
                style={{ marginRight: "10px" }}
              >
                {__("Yes")}
              </Button>
              <Button color="black" onClick={closeModal} inline>
                {__("Cancel")}
              </Button>
            </React.Fragment>
          )}
        </ModalButtonArea>
      </Modal>
    </Wrap>
  );
};

export default UpdateCrypto;

const Wrap = styled.div`
  width: 600px;
  margin: 0 auto;
`;
const User = styled.div`
  margin-left: 10px;
  text-align: left;
  font-size: 13px;
  display: inline-block;
`;

const Space = styled.div`
  height: 30px;
`;

const Bell = styled.div`
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 16px 19px;
  margin-right: 3px;
  margin-top: 5px;
  width: 16px;
  height: 19px;
  background-image: url(${require("../../root/img/bell-light.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../root/img/bell-light@2x.png")});
  }
`;

const BellNotified = styled(Bell)`
  background-size: 22px 20px;
  width: 22px;
  height: 20px;
  cursor: default;
  margin-right: 0;
  background-image: url(${require("../../root/img/bell-notified.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../root/img/bell-notified@2x.png")});
  }
`;
