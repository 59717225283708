import { LocalMediaStorage } from '@vidstack/react';

class VideoPlayerLocalStorage extends LocalMediaStorage {
  onChange(src: Src, mediaId: string | null, playerId = 'pCloudVideoPlayer') {
    super.onChange(src, mediaId, playerId);
  }

  // Disable cache for played video time.
  async getTime() {};
  
  async setTime(time: number, ended: boolean) {};

  async getPlaybackRate() {}

  async setPlaybackRate(rate) {}

  async getVideoQuality() {}

  async setVideoQuality(quality: SerializedVideoQuality | null) {}
}

export default VideoPlayerLocalStorage;