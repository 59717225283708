//@flow

import React from "react";
import styled from "styled-components";

import Button from "../../components/ButtonDefault";
import Modal, { OvalModalBody, OvalModalCloseSimple, OvalModalWrap } from "../../components/Modal";

type Props = {
  primaryClick: () => void,
  primaryText: string,
  secondaryClick: () => void,
  secondaryText: string,
  title: string,
  description?: string,
  primaryColor: "red" | "green",
  onClose: () => void,
  isOpened: boolean
};

const styles = {
	buttonStyle: {
		margin: "0px 5px",
    maxWidth: "160px"
	}
};

const ActionModal = ({
  primaryClick,
  primaryText,
  secondaryClick,
  secondaryText,
  title,
  description,
  primaryColor,
  onClose,
  isOpened = false
}: Props) => {
  return (
    <Modal 
      onClose={onClose}
      opened={isOpened}
      animate={true}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEscape={false}
    >
      <OvalModalCloseSimple onClick={onClose}/>
      <Wrapper>
        <Title>{title}</Title>
         {description ? <Description>{description}</Description> : null}
        <ButtonWrapper>
          <Button style={styles.buttonStyle} onClick={secondaryClick} color="lightgray1" size="big">{secondaryText}</Button>
          <Button style={styles.buttonStyle} onClick={primaryClick} color={primaryColor} size="big">{primaryText}</Button>
        </ButtonWrapper>
      </Wrapper>
    </Modal>
  )
}

export default ActionModal;

const Wrapper = styled.div`
  width: 100%;
  min-width: 640px;
  padding: 35px 20px 40px 20px;
  border-radius: 19px;
  box-shadow: 0 20px 60px 0 rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
  box-sizing: border-box;

  @media(max-width: 768px) {
    min-width: 0px;
  }
`;

const Title = styled.div`
  font-family: "Roboto", sans-serif;
  font-size: 21px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: -0.21px;
  text-align: center;
  color: #000000;
  margin: 0 auto;
  margin-bottom: 10px;
  max-width: 490px;
`;

const Description = styled.div`
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: -0.15px;
  text-align: center;
  color: #000000;
  margin: 0 auto;
  margin-bottom: 10px;
  max-width: 490px;
`;

const ButtonWrapper = styled.div`
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
`;