import { useState, useEffect } from 'react';
import {debounce} from "../utils";

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    const { offsetWidth: bodyWidth, offsetHeight: bodyHeight } = document.body;
    return {
        width,
        height,
        bodyWidth,
        bodyHeight
    };
}

export default function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        const handleResize = debounce(() => {
            setWindowDimensions(getWindowDimensions());
        })

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}
