import type {PlaceTypes} from "../index";
import {useEffect, useState} from "react";
import useWindowDimensions from "../../../lib/hooks/useWindowDimensions";

type Props = {
    place: PlaceTypes,
    show: boolean,
    parentRef: any,
    popupRef: any,
};

const initialPosition = {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    arrowLeft: 0
};
const spaceFromTargetModifier = 20;
const spaceFromSideModifier = 50;
const arrowWith = 24;

const useOnboardingPosition = ({place, show, parentRef, popupRef}: Props) => {
    const isMobile = HFN.config.isMobile();
    const [positionData, setPositionData] = useState(initialPosition);
    const [positionClass, setPositionClass] = useState('');
    const [originalPopupWidth, setOriginalPopupWidth] = useState(null);
    const { bodyWidth, height: screenHeight } = useWindowDimensions();

    const setPosition = () => {
        if (!popupRef?.current || !parentRef?.current || !show) {
            return;
        }

        if (!originalPopupWidth) {
            setOriginalPopupWidth(popupRef.current.offsetWidth);
        }

        const rect = parentRef.current.getBoundingClientRect();
        const targetHeight = parentRef.current.offsetHeight;
        const targetWidth = parentRef.current.offsetWidth;
        const popupWidth = originalPopupWidth ?? popupRef.current.offsetWidth;
        const popupHeight = popupRef.current.offsetHeight;
        const placeOptions = place.split('-');

        if (isMobile) {
            /**
             * Position fit check for mobile
             */
            if (
                place === 'left' && rect.left < popupWidth + spaceFromTargetModifier ||
                place === 'right' && rect.right + popupWidth + spaceFromTargetModifier > bodyWidth
            ) {
                place = rect.top > screenHeight / 2 ? 'top-mobile' : 'bottom-mobile'
            }

            // When have double direction property
            if (placeOptions.length === 2) {
                if (placeOptions[0] === 'bottom' && popupHeight + spaceFromTargetModifier > screenHeight - rect.bottom) placeOptions[0] = 'top';
                else if (placeOptions[0] === 'top' && popupHeight + spaceFromTargetModifier > rect.top) placeOptions[0] = 'bottom';

                if (placeOptions[1] === 'left' && rect.left < popupWidth - spaceFromSideModifier - targetWidth / 2) placeOptions[1] = 'right';
                else if (placeOptions[1] === 'right' && rect.right + popupWidth - spaceFromSideModifier - targetWidth / 2 > bodyWidth) placeOptions[1] = 'left';

                if (
                    placeOptions[1] === 'left' && rect.left < popupWidth - spaceFromSideModifier - targetWidth / 2 ||
                    placeOptions[1] === 'right' && rect.right + popupWidth - spaceFromSideModifier - targetWidth / 2 > bodyWidth
                ) {
                    placeOptions[1] = 'mobile'
                }

                place = placeOptions[0] + '-' + placeOptions[1];
            }
        } else {
            /**
             * Position fit check for desktop
             */
            if (place === 'left' && rect.left < popupWidth + spaceFromTargetModifier) place = 'right'
            else if (place === 'right' && rect.right + popupWidth + spaceFromTargetModifier > bodyWidth) place = 'left'

            if (['left', 'right'].includes(place) && popupHeight - targetHeight / 2 - spaceFromSideModifier > screenHeight - rect.bottom) place = 'top-'+place;

            // When have double direction property
            if (placeOptions.length === 2) {
                if (placeOptions[0] === 'bottom' && popupHeight + spaceFromTargetModifier > screenHeight - rect.bottom) placeOptions[0] = 'top';
                else if (placeOptions[0] === 'top' && popupHeight + spaceFromTargetModifier > rect.top) placeOptions[0] = 'bottom';

                if (placeOptions[1] === 'left' && rect.left < popupWidth - spaceFromSideModifier - targetWidth / 2) placeOptions[1] = 'right';
                else if (placeOptions[1] === 'right' && rect.right + popupWidth - spaceFromSideModifier - targetWidth / 2 > bodyWidth) placeOptions[1] = 'left';

                place = placeOptions[0] + '-' + placeOptions[1];
            }
        }

        switch (place) {
            case 'left':
                setPositionData({
                    ...initialPosition,
                    top: rect.top + targetHeight / 2 - spaceFromSideModifier,
                    right: bodyWidth - rect.left + spaceFromTargetModifier,
                });
                break;
            case 'right':
                setPositionData({
                    top: rect.top + targetHeight / 2 - spaceFromSideModifier,
                    left: rect.right + spaceFromTargetModifier,
                });
                break;
            case 'bottom-right':
                setPositionData({
                    ...initialPosition,
                    top: rect.bottom + spaceFromTargetModifier,
                    left: rect.left - spaceFromSideModifier + targetWidth / 2,
                });
                break;
            case 'bottom-left':
                setPositionData({
                    ...initialPosition,
                    top: rect.bottom + spaceFromTargetModifier,
                    right: bodyWidth - rect.right - spaceFromSideModifier + targetWidth / 2,
                });
                break;
            case 'top-right':
                setPositionData({
                    ...initialPosition,
                    bottom: screenHeight - rect.top + spaceFromTargetModifier,
                    left: rect.left - spaceFromSideModifier + targetWidth / 2,
                });
                break;
            case 'top-left':
                setPositionData({
                    ...initialPosition,
                    bottom: screenHeight - rect.top + spaceFromTargetModifier,
                    right: bodyWidth - rect.right - spaceFromSideModifier + targetWidth / 2,
                })
                break;
            case 'top-mobile':
                setPositionData({
                    ...initialPosition,
                    bottom: screenHeight - rect.top + spaceFromTargetModifier,
                    arrowLeft: rect.left + targetWidth / 2 - arrowWith / 2,
                })
                break;
            case 'bottom-mobile':
                setPositionData ({
                    ...initialPosition,
                    top: rect.bottom + spaceFromTargetModifier,
                    arrowLeft: rect.left + targetWidth / 2 - arrowWith / 2,
                })
                break;
        }

        setPositionClass(place);
    }

    useEffect(() => {
        window.addEventListener("scroll", setPosition);
        return () => {
            window.removeEventListener("scroll", setPosition);
        };
    }, []);

    useEffect(() => {
        setPosition();
    }, [show, bodyWidth, screenHeight]);

    return {
        positionData,
        positionClass
    };
}

export default useOnboardingPosition;