// @flow
import { SubscriptionUpdate } from "./SubscriptionUpdate";
import { PROVIDERS_NAMES } from "@pcloud/web-utilities/dist/config/constants";
import apiMethod from "../api/apiMethod";

export const changeUsersQuotaNoCharge = (params = {}) => {
  let payments = new SubscriptionUpdate(params);
  const { paypalEmail, provider, afterPaymentActions } = params;

  if (provider === PROVIDERS_NAMES.PAYPAL) {
    payments.updatePaypalBusinessSubscription({ reason: "modify" });
  } else if (provider === PROVIDERS_NAMES.BRAINTREE) {
    payments.createBraintreePayment({
      refresh: false,
      callback: afterPaymentActions
    });
  } else if (provider === PROVIDERS_NAMES.SAFECHARGE) {
    payments.initSafechargePayment({
      refresh: false,
      callback: afterPaymentActions
    });
  } else if (provider === PROVIDERS_NAMES.STRIPE) {
    payments.updateStripeSubscription({
      refresh: false,
      callback: afterPaymentActions
    });
  }
};

export const updatePaymentInfoCall = (onSuccess = () => {}, onError = () => {}) => {
  setTimeout(() => {
    return apiMethod("usersettingsinfo", {}, onSuccess, {
      errorCallback: onError,
      forceFresh: true
    });
  }, 1000);
};
