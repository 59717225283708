// @flow

import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Componentify from "react-componentify";

import type { Userinfo, Subscription } from "../../types/user";

import * as Style from "./SharedInvitationComponents";
import FamilyInviteIcon from "../../../root/img/svg/family_invite.svg";
import WarnIcon from "../../../root/img/svg/warning_invitation.svg";
import RegistrationForm from "../UserAuthentication/InvitationRegistration/RegistrationForm";
import { login } from "../../lib/state/actions/user";
import apiMethod from "../../api/apiMethod";
import { URLSITE } from "@pcloud/web-utilities/dist/config";
import { __ } from "../../lib/translate";
import { getHash, formatSizeInGb, isSameLocation, getLocationFromUrl } from "../../lib/utils";
import { boldConverter, brTagConverter } from "../../lib/componentifyConverters";

type Props = {
  token: string,
  email: string,
  language: string,
  logged: boolean,
  subscriptions: Array<Subscription>,
  premium: boolean,
  premiumlifetime: boolean,
  plan: number,
  business: boolean,
  login: (Userinfo, string) => void
};

type State = {
  holder: string,
  code: string,
  email: string,
  quota: number,
  loading: boolean,
  error: string
};

class FamilyInvitation extends PureComponent<Props, State> {
  static defaultProps = {
    token: "",
    email: "",
    language: "en",
    logged: false,
    subscriptions: [],
    premium: false,
    premiumlifetime: false,
    plan: 0,
    business: false,
    login: () => {}
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      holder: "",
      code: "",
      email: "",
      quota: 0,
      loading: true,
      error: ""
    };

    (this: any).join = this.join.bind(this);
    (this: any).cancelInvitation = this.cancelInvitation.bind(this);
    (this: any).getInviteInfo = this.getInviteInfo.bind(this);
    (this: any).onRegistration = this.onRegistration.bind(this);
    (this: any).onLogout = this.onLogout.bind(this);
  }

  componentDidMount() {
    this.getInviteInfo();
  }

  componentDidUpdate(prevProps) {
    const { logged: prevLogged } = prevProps;
    const { logged: nextLogged } = this.props;

    if (prevLogged !== nextLogged) {
      this.getInviteInfo();
    }
  }

  onRegistration({ action, userinfo }) {
    const { login, token } = this.props;

    login(userinfo, token);
    HFN.userLoginAfter(userinfo, () => {
      this.setState({ loading: false, error: this.getErrorMessage() });
    });
  }

  onLogout() {
    this.setState({ error: "" });
    HFN.logout();
  }

  getInviteInfo() {
    const { token } = this.props;
    const code = getHash("code");
    const params = { code: code };

    if (token) {
      params.auth = token;
    }
    this.setState({ loading: true });

    apiMethod(
      "fm_invitationinfo",
      params,
      ({ invitermail, email, quota }) => {

        this.setState({
          code,
          holder: invitermail,
          email,
          quota,
          loading: false,
          error: this.getErrorMessage()
        });
      },
      {
        errorCallback: ({ result, error }) => {
          if (result == 2012) {
            this.setState({
              loading: false,
              error: this.getErrorMessage(__("family_invite_cancelled_title"))
            });
          } else if (result == 2336) {
            this.setState({
              loading: false,
              error: this.getErrorMessage(__("family_member_invite_relocation"))
            });
          } else {
            this.setState({ loading: false, error: this.getErrorMessage(error) });
          }
        }
      }
    );
  }

  getPlanName() {
    const { plan, business, premiumlifetime } = this.props;
    let planNameKey = "plan_name_";

    if (typeof plan != "undefined") {
      planNameKey += plan;
    }

    if (premiumlifetime) {
      planNameKey += "_lifetime";
    } else if (business) {
      planNameKey = "plan_name_business";
    } else if (plan === "undefined" && !business) {
      planNameKey += "_unknown";
    }

    return __(planNameKey);
  }

  getErrorMessage(err) {
    const { logged } = this.props;
    let error = "";

    if (err) {
      error = err;
    } else if (logged && !isSameLocation()) {
      error = __("family_invite_subscribed_message4");
    }

    return error;
  }

  cancelInvitation() {
    const { token } = this.props;
    const { code } = this.state;

    this.setState({ loading: true });

    apiMethod(
      "fm_cancelinvitation",
      { code, auth: token },
      () => {
        HFN.message("Invitation Cancelled", "success", true, true);
        setTimeout(() => {
          window.open("https://my.pcloud.com", "_self");
        }, 5000);
      },
      {
        errorCallback: ({ error }) => {
          this.setState({ loading: false });
          // TO DO:
          HFN.message(error, "error");
        }
      }
    );
  }

  join() {
    const { token } = this.props;
    const { code } = this.state;

    this.setState({ loading: true });

    apiMethod(
      "fm_acceptinvitation",
      { code, auth: token },
      () => {
        window.open(URLSITE, "_self");
      },
      {
        errorCallback: ({ error }) => {
          this.setState({ loading: false });
          // TO DO
          HFN.message(error, "error");
        }
      }
    );
  }

  renderLoading() {
    return (
      <Style.LoadingWrapper>
        <div />
      </Style.LoadingWrapper>
    );
  }

  renderMessages(error) {
    const { logged, subscriptions, premium, premiumlifetime, plan, business, email } = this.props;
    const { holder, quota } = this.state;

    const hasSubscription = subscriptions && subscriptions.length > 0;
    const hasEditablePrSubscription =
      subscriptions &&
      subscriptions.some(subscription => {
        const premiumSubscription =
          subscription.products.indexOf(1) == -1 || subscription.products.indexOf(3) == -1;
        return subscription.canedit == true && premiumSubscription;
      });
    const hasPremiumLifetime = premiumlifetime && (plan == 1 || plan == 3);
    const hasPremiumSubscription = premium && !premiumlifetime;
    const hasFamilyPlan = plan == 14 || plan == 15;
    const hasBusiness = business;
    const userEmail = email;
    const planName = this.getPlanName();
    const quotaFormated = formatSizeInGb(quota, 0);

    let Icon = WarnIcon;
    let MessageComponent = Style.WarningMessage;
    let title = __("family_invite_subscribed_header");
    let message;
    let showButtons;

    if (logged && !isSameLocation()) {
      title = "";
      message = __("family_invite_subscribed_message4");
    } else if (error) {
      title = "";
      message = error;
      showButtons = false;
    } else if (hasPremiumLifetime || hasBusiness || hasFamilyPlan) {
      message = __("family_invite_subscribed_message3");
      showButtons = false;
    } else if (hasFamilyPlan) {
      message = __("family_access_denied");
      showButtons = false;
    } else if (hasSubscription && !hasEditablePrSubscription) {
      message = __("family_invite_subscribed_message2");
      showButtons = true;
    } else if (hasPremiumSubscription) {
      message = __("family_invite_subscribed_message1");
      showButtons = true;
    } else {
      title = __("plan_name_14");
      message = __("family_invite_join_message");
      Icon = FamilyInviteIcon;
      MessageComponent = Style.Message;
      showButtons = true;
    }

    message = message
      .replace("%planname%", planName)
      .replace("%owneremail%", holder)
      .replace("%planowner%", holder)
      .replace("%useremail%", userEmail)
      .replace("%familyquota%", quotaFormated);

    return (
      <React.Fragment>
        <Style.Title>{title}</Style.Title>
        <MessageComponent>
          <Icon fill="#17bed0"/>
          <Style.ContainerCol>
            <Style.ComponentifyWrapper>
              <Componentify text={message} converters={[boldConverter, brTagConverter]} />
            </Style.ComponentifyWrapper>
            {showButtons ? (
              <Style.ButtonContainerInline>
                <Style.ButtonBlue onClick={this.join}>{__("Join")}</Style.ButtonBlue>
                <Style.ButtonGrey onClick={this.cancelInvitation}>{__("Cancel")}</Style.ButtonGrey>
              </Style.ButtonContainerInline>
            ) : null}
          </Style.ContainerCol>
        </MessageComponent>
        {logged ? (
          <Style.Link onClick={this.onLogout}>{__("family_invite_subscribed_login")}</Style.Link>
        ) : null}
      </React.Fragment>
    );
  }

  renderRegister() {
    const { language } = this.props;
    const { holder, email, quota } = this.state;
    const quotaFormated = formatSizeInGb(quota, 0);
    const message = __("family_invite_subheadline")
      .replace("%planowner%", holder)
      .replace("%familyquota%", quotaFormated);
    const locationFromUrl = getLocationFromUrl() || {};

    return (
      <React.Fragment>
        <Style.Title>{__("plan_name_14")}</Style.Title>
        <Style.Message>
          <FamilyInviteIcon width="32" height="21" fill="#17bed0" />
          <Style.ComponentifyWrapper>
            <Componentify text={message} converters={[boldConverter, brTagConverter]} />
          </Style.ComponentifyWrapper>
        </Style.Message>
        <Style.Wrapper>
          <RegistrationForm
            title={__("family_invite_description")}
            formWidth="360px"
            email={email}
            language={language}
            shouldRednerNames={false}
            locations={[locationFromUrl]}
            initLoginRegModal={HFN.initLoginRegModal.bind(HFN)}
            onSuccess={this.onRegistration}
          />
        </Style.Wrapper>
      </React.Fragment>
    );
  }

  render() {
    const { logged } = this.props;
    const { error, loading } = this.state;

    if (loading) {
      return this.renderLoading();
    } else if (logged || error) {
      return this.renderMessages(error);
    } else {
      return this.renderRegister();
    }
  }
}

export default connect(
  ({
    user: {
      logged,
      token,
      userinfo: { subscriptions, premium, premiumlifetime, plan, business, email } = {}
    } = {}
  }) => {
    return {
      logged,
      token,
      subscriptions,
      premium,
      premiumlifetime,
      plan,
      business,
      email
    };
  },
  dispatch =>
    bindActionCreators(
      {
        login
      },
      dispatch
    )
)(FamilyInvitation);
