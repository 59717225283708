// @flow

import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";

import Modal, { OvalModalWrap, CloseModalIcon } from "../Modal";
import Button from "../ButtonDefault";
import * as Style from "./styledComponents";

import apiMethod from "../../api/apiMethod";
import { __ } from "../../lib/translate";
import { errorKeys } from "../../lib/errors";

type Props = {
  shareId: number,
  folderName: string,
  toMail: string,
  sharedToFrom: string
};

const RemoveShareModal = ({ shareId, folderName, toMail, sharedToFrom }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsOpen(true);
  }, []);

  const onEnter = useCallback(
    (e: any) => {
      if (e.keyCode === 13) {
        removeShare();
      }
    },
    [removeShare]
  );

  useEffect(() => {
    if (isOpen) {
      console.log("Add keyup event RemoveShareModal");
      document.addEventListener("keyup", onEnter);
    } else {
      console.log("Remove keyup event RemoveShareModal");
      document.removeEventListener("keyup", onEnter);
    }
  }, [isOpen, onEnter]);

  const handleClose = () => {
    setIsOpen(false);
  };

  const removeShare = useCallback(() => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);
    apiMethod(
      "removeshare",
      { shareid: shareId },
      res => {
        HFN.message(__("Share removed."));
        handleClose();
      },
      {
        errorCallback: ({ result, error }) => {
          setIsLoading(false);
          if (errorKeys[result]) {
            HFN.message(__(errorKeys[result]), "error");
          } else {
            handleClose();
            HFN.message(__("something_went_wrong_refresh_and_try_again"), "error");
            throw new Error(error);
          }
        },
        forceFresh: true
      }
    );
  });

  return (
    <Modal animate onClose={handleClose} opened={isOpen}>
      <OvalModalWrap>
        <CloseModalIcon onClick={handleClose} />
        <Style.Container>
          <Style.Header>{__("remove_share_modal_header", "Remove share")}</Style.Header>
          <Style.Label>{__("Folder")}</Style.Label>
          <BoxTeam style={{ marginBottom: "15px" }}>
            <Icon src={HFN.createIconSrc(HFN.ICO.FOLDER_SHARED, HFN.ICONS.LIST_SMALL)} alt="icon" />
            {folderName}
          </BoxTeam>
          <Style.Label>{__(sharedToFrom)}</Style.Label>
          <BoxTeam>{toMail}</BoxTeam>
          <Style.Footer>
            <Button
              color="lightgray4"
              style={{
                marginRight: "5px"
              }}
              onClick={handleClose}
            >
              {__("Cancel")}
            </Button>
            <Button
              loading={isLoading}
              color="red"
              style={{
                marginLeft: "5px"
              }}
              disabledColor="#dddddd"
              onClick={removeShare}
            >
              {__("family_remove", "Remove")}
            </Button>
          </Style.Footer>
        </Style.Container>
      </OvalModalWrap>
    </Modal>
  );
};

export default RemoveShareModal;

const BoxTeam = styled(Style.Box)`
  height: auto;
  padding: 10px;
  font-size: 15px;
  align-items: center;
  justify-content: flex-start;
`;

const Icon = styled.img`
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  vertical-align: middle;
`;
