import apiMethod from "./apiMethod";
import { __ } from "../lib/translate";

export default function getCryptoRoots(options) {
  const { onSuccess, onError, onTimeout } = options;

  const optsApiMethod = {
    forceFresh: true,
    cacheTime: false,
    showErrorMessage: false,
    errorCallback: ({ result, error }) => {
      if (HFN.ERROR_MESSAGE[result]) {
        HFN.message(__(HFN.ERROR_MESSAGE[result]), "error");
      } else {
        HFN.message(error, "error");
      }

      if (onError) {
        onError({ result, error });
      }
    },
    onTimeout: () => {
      if (onTimeout) {
        onTimeout();
      }
    }
  };

  return apiMethod(
    "crypto_getroots",
    { iconformat: "id" },
    ({ folders = [] }) => {
      let virtualFolder = $.extend({}, HFN.virtualEncryptedFolder);
      let wait = [];
      virtualFolder.contents = [];
      folders.forEach(folder => {
        const promise = $.Deferred();
        wait.push(promise);
        HFN.listCryptoFolderRecursive(folder, folderobj => {
          virtualFolder.contents.push(folderobj);
          promise.resolve();
        });
      });

      $.when.apply($, wait).then(() => {
        if (typeof onSuccess === "function") {
          onSuccess(virtualFolder);
        }
      });
    },
    optsApiMethod
  );
}
