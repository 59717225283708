// @flow
import ERRORS from "@pcloud/web-utilities/dist/api/errors";

let arrTimes = [];
let i = 0; // start
let timesToTest = 5;
const tThreshold = 150; //ms
const testImage = "http://www.google.com/images/phd/px.gif"; // small image in your server
const dummyImage = new Image();

export const testPing = cb => {
  var tStart = new Date().getTime();
  if (i < timesToTest - 1) {
    dummyImage.src = testImage + "?t=" + tStart;
    dummyImage.onload = function() {
      var tEnd = new Date().getTime();
      var tTimeTook = tEnd - tStart;
      arrTimes[i] = tTimeTook;
      testPing(cb);
      i++;
    };
  } else {
    /** calculate average of array items then callback */
    var sum = arrTimes.reduce(function(a, b) {
      return a + b;
    });
    var avg = sum / arrTimes.length;
    cb(avg);
  }
};

// testPing((avg) => {
//    console.log("Time: " + (avg.toFixed(2)) + "ms")
// });

export const ping = (url, result) => {
  const PING_TIMEOUT = 2000;
  let USE_PING_TIMEOUT = true; //will be disabled on unsupported browsers
  if (/MSIE.(\d+\.\d+)/i.test(navigator.userAgent)) {
    //IE11 doesn't support XHR timeout
    USE_PING_TIMEOUT = false;
  }
  url += (url.match(/\?/) ? "&" : "?") + "cors=true";
  const xhr = new XMLHttpRequest();
  const t = new Date().getTime();
  xhr.onload = () => {
    let instspd = new Date().getTime() - t; //rough timing estimate
    try {
      //try to get more accurate timing using performance API
      let p = performance.getEntriesByName(url);
      p = p[p.length - 1];
      let d = p.responseStart - p.requestStart;
      if (d <= 0) d = p.duration;
      if (d > 0 && d < instspd) instspd = d;
    } catch (e) {}
    result(instspd);
  };
  xhr.onerror = () => {
    result(-1);
  };
  xhr.open("GET", url);
  if (USE_PING_TIMEOUT) {
    try {
      xhr.timeout = PING_TIMEOUT;
      xhr.ontimeout = xhr.onerror;
    } catch (e) {}
  }
  xhr.send();
};

export const getSpeedObj = bitsPerSecond => {
  const roundedDecimals = 2;
  const bytesInAKilobyte = 1024;
  const KBps = (bitsPerSecond / bytesInAKilobyte).toFixed(roundedDecimals);
  if (KBps <= 1) {
    return { value: bitsPerSecond, units: "Bps" };
  }
  const MBps = (KBps / bytesInAKilobyte).toFixed(roundedDecimals);
  if (MBps <= 1) {
    return { value: KBps, units: "KBps" };
  } else {
    return { value: MBps, units: "MBps" };
  }
};

export const downloadSeed = url => {
  const xhr = new XMLHttpRequest();
  const startTime = new Date().getTime();
  url += (url.match(/\?/) ? "&" : "?") + "cors=true";

  xhr.onreadystatechange = () => {
    if (xhr.readyState === 4 && xhr.status === 200) {
      // console.log("average spped: ");
    }
  };

  xhr.onprogress = event => {
    const endTime = new Date().getTime();
    const duration = (endTime - startTime) / 1000;
    const fileSize = event.loaded;
    const speed = getSpeedObj(fileSize / duration);

    // console.log(speed.value, speed.units);
  };

  xhr.open("GET", url, true);
  xhr.send();
};

export const uploadSpped = () => {
  const xhr = new XMLHttpRequest();
};

// data reported to main thread
var testState = -1; // -1=not started, 0=starting, 1=download test, 2=ping+jitter test, 3=upload test, 4=finished, 5=abort
var dlStatus = ""; // download speed in megabit/s with 2 decimal digits
var ulStatus = ""; // upload speed in megabit/s with 2 decimal digits
var pingStatus = ""; // ping in milliseconds with 2 decimal digits
var jitterStatus = ""; // jitter in milliseconds with 2 decimal digits
var clientIp = ""; // client's IP address as reported by getIP.php
var dlProgress = 0; //progress of download test 0-1
var ulProgress = 0; //progress of upload test 0-1
var pingProgress = 0; //progress of ping+jitter test 0-1
var testId = null; //test ID (sent back by telemetry if used, null otherwise)

var log = ""; //telemetry log
const tlog = s => {
  if (settings.telemetry_level >= 2) {
    log += Date.now() + ": " + s + "\n";
  }
};
const tverb = s => {
  if (settings.telemetry_level >= 3) {
    log += Date.now() + ": " + s + "\n";
  }
};
const twarn = s => {
  if (settings.telemetry_level >= 2) {
    log += Date.now() + " WARN: " + s + "\n";
  }
  console.warn(s);
};

// test settings. can be overridden by sending specific values with the start command
var settings = {
  mpot: false, //set to true when in MPOT mode
  test_order: "IP_D_U", //order in which tests will be performed as a string. D=Download, U=Upload, P=Ping+Jitter, I=IP, _=1 second delay
  time_ul_max: 10, // max duration of upload test in seconds
  time_dl_max: 10, // max duration of download test in seconds
  time_auto: true, // if set to true, tests will take less time on faster connections
  time_ulGraceTime: 3, //time to wait in seconds before actually measuring ul speed (wait for buffers to fill)
  time_dlGraceTime: 1.5, //time to wait in seconds before actually measuring dl speed (wait for TCP window to increase)
  count_ping: 5, // number of pings to perform in ping test
  /* test local
  url_dl:
    "https://api71.pcloud.com/speedtestdownload?auth=BzpuNVZCEOAZ4c66Q8iU49upu5SJoiIGLQYKDWAX&code=MZCPQP5ABotv0QsTHazB3pxmN7T2KX",
  url_ul:
    "https://api71.pcloud.com/speedtestdownload?auth=BzpuNVZCEOAZ4c66Q8iU49upu5SJoiIGLQYKDWAX&code=MZCPQP5ABotv0QsTHazB3pxmN7T2KX", // path to an empty file, used for upload test. must be relative to this js file
  url_ping: "https://api.pcloud.com/speedtest", // path to an empty file, used for ping test. must be relative to this js file
  */
  url_getIp: "https://api71.pcloud.com/getip", // path to getIP.php relative to this js file, or a similar thing that outputs the client's ip
  getIp_ispInfo: true, //if set to true, the server will include ISP info with the IP address
  getIp_ispInfo_distance: "km", //km or mi=estimate distance from server in km/mi; set to false to disable distance estimation. getIp_ispInfo must be enabled in order for this to work
  xhr_dlMultistream: 1, // number of download streams to use (can be different if enable_quirks is active)
  xhr_ulMultistream: 1, // number of upload streams to use (can be different if enable_quirks is active)
  xhr_multistreamDelay: 300, //how much concurrent requests should be delayed
  xhr_ignoreErrors: 0, // 0=fail on errors, 1=attempt to restart a stream if it fails, 2=ignore all errors
  xhr_dlUseBlob: false, // if set to true, it reduces ram usage but uses the hard drive (useful with large garbagePhp_chunkSize and/or high xhr_dlMultistream)
  xhr_ul_blob_megabytes: 20, //size in megabytes of the upload blobs sent in the upload test (forced to 4 on chrome mobile)
  garbagePhp_chunkSize: 100, // size of chunks sent by garbage.php (can be different if enable_quirks is active)
  enable_quirks: true, // enable quirks for specific browsers. currently it overrides settings to optimize for specific browsers, unless they are already being overridden with the start command
  ping_allowPerformanceApi: true, // if enabled, the ping test will attempt to calculate the ping more precisely using the Performance API. Currently works perfectly in Chrome, badly in Edge, and not at all in Firefox. If Performance API is not supported or the result is obviously wrong, a fallback is provided.
  overheadCompensationFactor: 1.06, //can be changed to compensatie for transport overhead. (see doc.md for some other values)
  useMebibits: false, //if set to true, speed will be reported in mebibits/s instead of megabits/s
  telemetry_level: 3, // 0=disabled, 1=basic (results only), 2=full (results and timing) 3=debug (results+log)
  url_telemetry: "results/telemetry.php", // path to the script that adds telemetry data to the database
  telemetry_extra: "", //extra data that can be passed to the telemetry through the settings
  forceIE11Workaround: false //when set to true, it will foce the IE11 upload test on all browsers. Debug only
};

var xhr = null; // array of currently active xhr requests
var interval = null; // timer used in tests
var test_pointer = 0; //pointer to the next test to run inside settings.test_order

/*
  this function is used on URLs passed in the settings to determine whether we need a ? or an & as a separator
*/
const url_sep = url => (url.match(/\?/) ? "&" : "?");

/*
  listener for commands from main thread to this worker.
  commands:
  -status: returns the current status as a JSON string containing testState, dlStatus, ulStatus, pingStatus, clientIp, jitterStatus, dlProgress, ulProgress, pingProgress
  -abort: aborts the current test
  -start: starts the test. optionally, settings can be passed as JSON.
    example: start {"time_ul_max":"10", "time_dl_max":"10", "count_ping":"50"}
*/

const clearRequests = () => {
  console.log("stopping pending XHRs");
  if (xhr) {
    for (var i = 0; i < xhr.length; i++) {
      try {
        xhr[i].onprogress = null;
        xhr[i].onload = null;
        xhr[i].onerror = null;
      } catch (e) {}
      try {
        xhr[i].upload.onprogress = null;
        xhr[i].upload.onload = null;
        xhr[i].upload.onerror = null;
      } catch (e) {}
      try {
        xhr[i].abort();
      } catch (e) {}
      try {
        delete xhr[i];
      } catch (e) {}
    }
    xhr = null;
  }
};
// gets client's IP using url_getIp, then calls the done function
var ipCalled = false; // used to prevent multiple accidental calls to getIp
var ispInfo = ""; //used for telemetry
export const getIp = (onProgress = () => {}, done = () => {}, onError: () => {}) => {
  // console.log("getIp");
  if (ipCalled) return;
  else ipCalled = true; // getIp already called?
  var startT = new Date().getTime();
  xhr = new XMLHttpRequest();
  xhr.onload = () => {
    tlog("IP: " + xhr.responseText + ", took " + (new Date().getTime() - startT) + "ms");
    try {
      var data = JSON.parse(xhr.responseText);
      clientIp = data.processedString;
      ispInfo = data.rawIspInfo;
    } catch (e) {
      clientIp = xhr.responseText;
      ispInfo = "";
    }
    done();
  };
  xhr.onerror = () => {
    // tlog("getIp failed, took " + (new Date().getTime() - startT) + "ms");
    done();
  };
  xhr.open(
    "GET",
    settings.url_getIp +
      url_sep(settings.url_getIp) +
      (settings.getIp_ispInfo
        ? "isp=true" +
          (settings.getIp_ispInfo_distance
            ? "&distance=" + settings.getIp_ispInfo_distance + "&"
            : "&")
        : "&") +
      "r=" +
      Math.random(),
    true
  );
  xhr.send();
};
// download test, calls done function when it's over
export const dlTest = (
  url,
  { onProgressCallback = () => {}, doneCallback = () => {}, onErrorCallback = () => {} }
) => {
  console.log("dlTest");
  settings.url_dl = url;
  var totLoaded = 0.0, // total number of loaded bytes
    startT = new Date().getTime(), // timestamp when test was started
    bonusT = 0, //how many milliseconds the test has been shortened by (higher on faster connections)
    graceTimeDone = false, //set to true after the grace time is past
    failed = false; // set to true if a stream fails
  xhr = [];
  // function to create a download stream. streams are slightly delayed so that they will not end at the same time
  const testStream = (i, delay) => {
    setTimeout(() => {
      // if (testState !== 1) return; // delayed stream ended up starting after the end of the download test
      // console.log("dl test stream started " + i + " " + delay);
      var prevLoaded = 0; // number of bytes loaded last time onprogress was called
      var x = new XMLHttpRequest();
      xhr[i] = x;
      xhr[i].onprogress = event => {
        // console.log("dl stream progress event " + i + " " + event.loaded);
        // progress event, add number of new loaded bytes to totLoaded
        var loadDiff = event.loaded <= 0 ? 0 : event.loaded - prevLoaded;
        if (isNaN(loadDiff) || !isFinite(loadDiff) || loadDiff < 0) return; // just in case
        totLoaded += loadDiff;
        prevLoaded = event.loaded;
      };
      xhr[i].onload = () => {
        // the large file has been loaded entirely, start again
        console.log("dl stream finished " + i);
        try {
          xhr[i].abort();
        } catch (e) {} // reset the stream data to empty ram
        testStream(i, 0);
      };
      xhr[i].onerror = () => {
        // error
        // console.log("dl stream failed " + i);
        if (settings.xhr_ignoreErrors === 0) failed = true; //abort
        try {
          xhr[i].abort();
        } catch (e) {}
        delete xhr[i];
        onErrorCallback();
        if (settings.xhr_ignoreErrors === 1) testStream(i, 0); //restart stream
      };
      xhr[i].onreadystatechange = function () {
        // In local files, status is 0 upon success in Mozilla Firefox
        if(xhr[i].readyState === XMLHttpRequest.DONE) {
          var status = xhr[i].status;
          if (status === 0 || (status >= 200 && status < 400)) {
            if (!xhr[i].responseText) {
              return;
            }
            // The request has been completed successfully
            const data = JSON.parse(xhr[i].responseText)
            if (data.result == ERRORS.LOGIN_REQUIRED || data.result == ERRORS.LOGIN_FAILED) {
              try {
                xhr[i].abort();
              } catch (e) {}
              console.log("onreadystatechange dl", data.error);
              HFN.message("Session Expired. Please login.", "error");
              HFN.pages.goto("login");
            } else if (data.result !== 0) {
              onErrorCallback();
            }
          }
        }
      };
      // send xhr
      try {
        if (settings.xhr_dlUseBlob) xhr[i].responseType = "blob";
        else xhr[i].responseType = "arraybuffer";
      } catch (e) {}
      xhr[i].open(
        "GET",
        settings.url_dl +
          url_sep(settings.url_dl) +
          "r=" +
          Math.random() +
          "&ckSize=" +
          settings.garbagePhp_chunkSize,
        true
      ); // random string to prevent caching
      xhr[i].responseType = "text";
      xhr[i].send();
    }, 1 + delay);
  };
  // open streams
  (async function() {
    for (var i = 0; i < settings.xhr_dlMultistream; i++) {
      testStream(i, settings.xhr_multistreamDelay * i);
    }
  })();
  // every 200ms, update dlStatus
  interval = setInterval(() => {
    // console.log("DL: " + dlStatus + (graceTimeDone ? "" : " (in grace time)"));
    var t = new Date().getTime() - startT;
    if (graceTimeDone) dlProgress = (t + bonusT) / (settings.time_dl_max * 1000);
    if (t < 200) return;
    if (!graceTimeDone) {
      if (t > 1000 * settings.time_dlGraceTime) {
        if (totLoaded > 0) {
          // if the connection is so slow that we didn't get a single chunk yet, do not reset
          startT = new Date().getTime();
          bonusT = 0;
          totLoaded = 0.0;
        }
        graceTimeDone = true;
      }
    } else {
      var speed = totLoaded / (t / 1000.0);
      if (settings.time_auto) {
        //decide how much to shorten the test. Every 200ms, the test is shortened by the bonusT calculated here
        var bonus = (5.0 * speed) / 100000;
        bonusT += bonus > 400 ? 400 : bonus;
      }
      //update status
      dlStatus = (
        (speed * 8 * settings.overheadCompensationFactor) /
        (settings.useMebibits ? 1048576 : 1000000)
      ).toFixed(2); // speed is multiplied by 8 to go from bytes to bits, overhead compensation is applied, then everything is divided by 1048576 or 1000000 to go to megabits/mebibits
      onProgressCallback(dlStatus);
      if ((t + bonusT) / 1000.0 > settings.time_dl_max || failed) {
        // test is over, stop streams and timer
        if (failed || isNaN(dlStatus)) dlStatus = "Fail";
        clearRequests();
        clearInterval(interval);
        dlProgress = 1;
        // console.log("dlTest: " + dlStatus + ", took " + (new Date().getTime() - startT) + "ms");
        doneCallback(dlStatus);
      }
    }
  }, 200);
};
// upload test, calls done function whent it's over
export const ulTest = (
  url,
  { onProgressCallback = () => {}, doneCallback = () => {}, onErrorCallback = () => {} }
) => {
  console.log("ulTest");
  settings.url_ul = url;
  // garbage data for upload test
  var req = [];
  var r = new ArrayBuffer(1048576);
	var maxInt = Math.pow(2, 32) - 1;
	try {
		r = new Uint32Array(r);
		for (var i = 0; i < r.length; i++) r[i] = Math.random() * maxInt;
	} catch (e) {}
	for (var i = 0; i < settings.xhr_ul_blob_megabytes; i++) req.push(r);
  const file = new Blob(req);
  req = new FormData();
  req.append("testupload.pcl", file);
  
	var reqsmall = [];
	var rsmall = new ArrayBuffer(262144);
	try {
		rsmall = new Uint32Array(rsmall);
		for (var i = 0; i < rsmall.length; i++) r[i] = Math.random() * maxInt;
	} catch (e) {}
	reqsmall.push(rsmall);
  reqsmall = new Blob(reqsmall);
  
  const testFunction = () => {
    var totLoaded = 0.0, // total number of transmitted bytes
      startT = new Date().getTime(), // timestamp when test was started
      bonusT = 0, //how many milliseconds the test has been shortened by (higher on faster connections)
      graceTimeDone = false, //set to true after the grace time is past
      failed = false; // set to true if a stream fails
    xhr = [];
    // function to create an upload stream. streams are slightly delayed so that they will not end at the same time
    var testStream = (i, delay) => {
      setTimeout(() => {
        // console.log("ul test stream started " + i + " " + delay);
        var prevLoaded = 0; // number of bytes transmitted last time onprogress was called
        var x = new XMLHttpRequest();
        xhr[i] = x;
        var ie11workaround;
        if (settings.forceIE11Workaround) ie11workaround = true;
        else {
          try {
            xhr[i].upload.onprogress;
            ie11workaround = false;
          } catch (e) {
            ie11workaround = true;
          }
        }
        if (ie11workaround) {
          // IE11 workarond: xhr.upload does not work properly, therefore we send a bunch of small 256k requests and use the onload event as progress. This is not precise, especially on fast connections
          xhr[i].onload = xhr[i].onerror = () => {
            // console.log("ul stream progress event (ie11wa)");
            totLoaded += reqsmall.size;
            testStream(i, 0);
          };
          xhr[i].open(
            "POST",
            settings.url_ul + url_sep(settings.url_ul) + "r=" + Math.random(),
            true
          ); // random string to prevent caching
          xhr[i].send(reqsmall);
        } else {
          // REGULAR version, no workaround
          xhr[i].upload.onprogress = event => {
            // console.log("ul stream progress event " + i + " " + event.loaded);
            var loadDiff = event.loaded <= 0 ? 0 : event.loaded - prevLoaded;
            if (isNaN(loadDiff) || !isFinite(loadDiff) || loadDiff < 0) return; // just in case
            totLoaded += loadDiff;
            prevLoaded = event.loaded;
          };
          xhr[i].upload.onload = () => {
            // this stream sent all the garbage data, start again
            // console.log("ul stream finished " + i);
            testStream(i, 0);
          };
          xhr[i].upload.onerror = event => {
            // console.log("ul stream failed " + i, event);
            onErrorCallback();
            if (settings.xhr_ignoreErrors === 0) failed = true; //abort
            try {
              xhr[i].abort();
            } catch (e) {}
            delete xhr[i];
            if (settings.xhr_ignoreErrors === 1) testStream(i, 0); //restart stream
          };
          xhr[i].onreadystatechange = function () {
            // In local files, status is 0 upon success in Mozilla Firefox
            if(xhr[i].readyState === XMLHttpRequest.DONE) {
              var status = xhr[i].status;
              if (status === 0 || (status >= 200 && status < 400)) {
                if (!xhr[i].responseText) {
                  return;
                }
                // The request has been completed successfully
                const data = JSON.parse(xhr[i].responseText)
                if (data.result == ERRORS.LOGIN_REQUIRED || data.result == ERRORS.LOGIN_FAILED) {
                  try {
                    xhr[i].abort();
                  } catch (e) {}
                  console.log("onreadystatechange ul", data.error);
                  HFN.message("Session Expired. Please login.", "error");
                  HFN.pages.goto("login");
                } else if (data.result !== 0) {
                  onErrorCallback();
                }
              }
            }
          };
          // send xhr
          xhr[i].open(
            "POST",
            settings.url_ul + url_sep(settings.url_ul) + "r=" + Math.random(),
            true
          ); // random string to prevent caching
          xhr[i].responseType = "text";
          xhr[i].send(req);
        }
      }, delay);
    };
    // open streams
    (async function() {
      for (var i = 0; i < settings.xhr_ulMultistream; i++) {
        testStream(i, settings.xhr_multistreamDelay * i);
      }
    })();
    // every 200ms, update ulStatus
    interval = setInterval(() => {
      // console.log("UL: " + ulStatus + (graceTimeDone ? "" : " (in grace time)"));
      var t = new Date().getTime() - startT;
      if (graceTimeDone) ulProgress = (t + bonusT) / (settings.time_ul_max * 1000);
      if (t < 200) return;
      if (!graceTimeDone) {
        if (t > 1000 * settings.time_ulGraceTime) {
          if (totLoaded > 0) {
            // if the connection is so slow that we didn't get a single chunk yet, do not reset
            startT = new Date().getTime();
            bonusT = 0;
            totLoaded = 0.0;
          }
          graceTimeDone = true;
        }
      } else {
        // debugger
        var speed = totLoaded / (t / 1000.0);
        if (settings.time_auto) {
          //decide how much to shorten the test. Every 200ms, the test is shortened by the bonusT calculated here
          var bonus = (5.0 * speed) / 100000;
          bonusT += bonus > 400 ? 400 : bonus;
        }
        //update status
        ulStatus = (
          (speed * 8 * settings.overheadCompensationFactor) /
          (settings.useMebibits ? 1048576 : 1000000)
        ).toFixed(2); // speed is multiplied by 8 to go from bytes to bits, overhead compensation is applied, then everything is divided by 1048576 or 1000000 to go to megabits/mebibits
        onProgressCallback(ulStatus);
        if ((t + bonusT) / 1000.0 > settings.time_ul_max || failed) {
          // test is over, stop streams and timer
          if (failed || isNaN(ulStatus)) ulStatus = "Fail";
          clearRequests();
          clearInterval(interval);
          ulProgress = 1;
          console.log("ulTest: " + ulStatus + ", took " + (new Date().getTime() - startT) + "ms");
          doneCallback(ulStatus);
        }
      }
    }, 200);
  };
  if (settings.mpot) {
    console.log("Sending POST request before performing upload test");
    xhr = [];
    xhr[0] = new XMLHttpRequest();
    xhr[0].onload = xhr[0].onerror = () => {
      console.log("POST request sent, starting upload test");
      testFunction();
    };
    xhr[0].open("POST", settings.url_ul);
    xhr[0].send();
  } else testFunction();
};

export const pingTest = (url_ping, { doneCallback = () => {}, onErrorCallback = () => {} }) => {
  // console.log("pingTest");
  var startT = new Date().getTime(); //when the test was started
  var prevT = null; // last time a pong was received
  var ping = 0.0; // current ping value
  var jitter = 0.0; // current jitter value
  var i = 0; // counter of pongs received
  var prevInstspd = 0; // last ping time, used for jitter calculation
  xhr = [];
  // ping function
  var doPing = () => {
    // console.log("doPing star");
    pingProgress = i / settings.count_ping;
    prevT = new Date().getTime();
    xhr[0] = new XMLHttpRequest();
    xhr[0].onload = () => {
      // pong
      if (i === 0) {
        prevT = new Date().getTime(); // first pong
      } else {
        var instspd = new Date().getTime() - prevT;
        if (settings.ping_allowPerformanceApi) {
          try {
            //try to get accurate performance timing using performance api
            var p = performance.getEntries();
            p = p[p.length - 1];
            var d = p.responseStart - p.requestStart;
            if (d <= 0) d = p.duration;
            if (d > 0 && d < instspd) instspd = d;
          } catch (e) {
            //if not possible, keep the estimate
            console.log("Performance API not supported, using estimate");
          }
        }
        //noticed that some browsers randomly have 0ms ping
        if (instspd < 1) instspd = prevInstspd;
        if (instspd < 1) instspd = 1;
        var instjitter = Math.abs(instspd - prevInstspd);
        if (i === 1) {
          /* first ping, can't tell jitter yet*/
          ping = instspd;
        } else {
          if (instspd < ping) ping = instspd; // update ping, if the instant ping is lower
          if (i === 2) jitter = instjitter;
          //discard the first jitter measurement because it might be much higher than it should be
          else
            jitter =
              instjitter > jitter
                ? jitter * 0.3 + instjitter * 0.7
                : jitter * 0.8 + instjitter * 0.2; // update jitter, weighted average. spikes in ping values are given more weight.
        }
        prevInstspd = instspd;
      }
      pingStatus = ping.toFixed(2);
      jitterStatus = jitter.toFixed(2);
      i++;
      // console.log("ping: " + pingStatus + " jitter: " + jitterStatus);
      if (i < settings.count_ping) doPing();
      else {
        // more pings to do?
        pingProgress = 1;
        tlog(
          "ping: " +
            pingStatus +
            " jitter: " +
            jitterStatus +
            ", took " +
            (new Date().getTime() - startT) +
            "ms"
        );
        doneCallback(pingStatus);
      }
    };
    xhr[0].onerror = () => {
      // a ping failed, cancel test
      console.log("ping failed");
      if (settings.xhr_ignoreErrors === 0) {
        //abort
        pingStatus = "Fail";
        jitterStatus = "Fail";
        clearRequests();
        tlog("ping test failed, took " + (new Date().getTime() - startT) + "ms");
        pingProgress = 1;
        onErrorCallback();
      }
      if (settings.xhr_ignoreErrors === 1) doPing(); //retry ping
      if (settings.xhr_ignoreErrors === 2) {
        //ignore failed ping
        i++;
        if (i < settings.count_ping) doPing();
        else {
          // more pings to do?
          pingProgress = 1;
          tlog(
            "ping: " +
              pingStatus +
              " jitter: " +
              jitterStatus +
              ", took " +
              (new Date().getTime() - startT) +
              "ms"
          );
          doneCallback(pingStatus);
        }
      }
    };
    xhr[0].onreadystatechange = function () {
      // In local files, status is 0 upon success in Mozilla Firefox
      if(xhr[0].readyState === XMLHttpRequest.DONE) {
        var status = xhr[0].status;
        if (status === 0 || (status >= 200 && status < 400)) {
          if (!xhr[0].responseText) {
            return;
          }
          // The request has been completed successfully
          const data = JSON.parse(xhr[0].responseText)
          if (data.result == ERRORS.LOGIN_REQUIRED || data.result == ERRORS.LOGIN_FAILED) {
            try {
              xhr[0].abort();
            } catch (e) {}
            console.log("onreadystatechange ping", data.error);
            HFN.message("Session Expired. Please login.", "error");
            HFN.pages.goto("login");
          } else if (data.result !== 0) {
            onErrorCallback();
          }
        }
      }
    };
    // send xhr
    xhr[0].open("GET", url_ping + url_sep(url_ping) + "r=" + Math.random(), true); // random string to prevent caching
    xhr[0].responseType = "text";
    xhr[0].send();
  };
  doPing(); // start first ping
};

export const showResult = () => {
  var telemetryIspInfo = {
    processedString: clientIp,
    rawIspInfo: typeof ispInfo === "object" ? ispInfo : ""
  };
  const result = {
    ispinfo: JSON.stringify(telemetryIspInfo),
    dl: dlStatus,
    ul: ulStatus,
    ping: pingStatus,
    jitter: jitterStatus,
    log: settings.telemetry_level > 1 ? log : "",
    extra: settings.telemetry_extra
  };
};
// telemetry
export const sendTelemetry = done => {
  if (settings.telemetry_level < 1) return;
  xhr = new XMLHttpRequest();
  xhr.onload = () => {
    try {
      var parts = xhr.responseText.split(" ");
      if (parts[0] == "id") {
        try {
          var id = parts[1];
          done(id);
        } catch (e) {
          done(null);
        }
      } else done(null);
    } catch (e) {
      done(null);
    }
  };
  xhr.onerror = () => {
    console.log("TELEMETRY ERROR " + xhr.status);
    done(null);
  };
  xhr.open(
    "POST",
    settings.url_telemetry +
      url_sep(settings.url_telemetry) +
      (settings.mpot ? "cors=true&" : "") +
      "r=" +
      Math.random(),
    true
  );
  var telemetryIspInfo = {
    processedString: clientIp,
    rawIspInfo: typeof ispInfo === "object" ? ispInfo : ""
  };
  try {
    var fd = new FormData();
    fd.append("ispinfo", JSON.stringify(telemetryIspInfo));
    fd.append("dl", dlStatus);
    fd.append("ul", ulStatus);
    fd.append("ping", pingStatus);
    fd.append("jitter", jitterStatus);
    fd.append("log", settings.telemetry_level > 1 ? log : "");
    fd.append("extra", settings.telemetry_extra);
    xhr.send(fd);
  } catch (ex) {
    var postData =
      "extra=" +
      encodeURIComponent(settings.telemetry_extra) +
      "&ispinfo=" +
      encodeURIComponent(JSON.stringify(telemetryIspInfo)) +
      "&dl=" +
      encodeURIComponent(dlStatus) +
      "&ul=" +
      encodeURIComponent(ulStatus) +
      "&ping=" +
      encodeURIComponent(pingStatus) +
      "&jitter=" +
      encodeURIComponent(jitterStatus) +
      "&log=" +
      encodeURIComponent(settings.telemetry_level > 1 ? log : "");
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhr.send(postData);
  }
};
