// @flow

import React, { PureComponent } from "react";
import styled from "styled-components";
import Componentify, { boldConverter } from "react-componentify";

import { __ } from "../../lib/translate";
import { spanClassTagConverter } from "../../lib/componentifyConverters";

import Button from "../../components/ButtonDefault";
import { Container, Header, Message, Footer, Title } from "./SharedFamilyComponents";
import type { Member, Invite } from "./types";

type Props = {
  currentItem: Member | Invite,
  onCancelInviteButton: () => void,
  onSuccess: () => void,
  onClose: () => void
};

class CancelInvitationModal extends PureComponent<Props, {}> {
  static defaultProps = {
    currentItem: null,
    onCancelInviteButton: () => {},
    onSuccess: () => {},
    onClose: () => {}
  };

  constructor(props: Props) {
    super(props);

    (this: any).onCancel = this.onCancel.bind(this);
    (this: any).onEnter = this.onEnter.bind(this);
  }

  componentDidMount() {
    document.addEventListener("keyup", this.onEnter);
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this.onEnter);
  }

  onEnter(e) {
    if (e.keyCode === 13) {
      this.onCancel();
    }
  }

  onCancel() {
    const { onCancelInviteButton, onSuccess, currentItem } = this.props;
    const { invitationid } = currentItem;

    onCancelInviteButton(invitationid);
    onSuccess();
  }

  render() {
    const { onClose, currentItem } = this.props;
    const { email } = currentItem;

    return (
      <Container>
        <Header>{__("family_invitation_cancelled_title")}</Header>
        <Message>
          <CancelTitle>
            <Componentify
              text={__("family_invitation_cancelled", "", {
                emailattr: `class='member-email'`,
                email: email
              })}
              converters={[spanClassTagConverter]}
            />
          </CancelTitle>
        </Message>
        <Footer>
          <Button
            color="lightgray4"
            style={{
              marginRight: "5px"
            }}
            onClick={onClose}
          >
            {__("Cancel")}
          </Button>
          <Button
            color="red"
            style={{
              marginLeft: "5px"
            }}
            onClick={this.onCancel}
          >
            {__("Yes")}
          </Button>
        </Footer>
      </Container>
    );
  }
}

export default CancelInvitationModal;

const CancelTitle = styled(Title)`
  font-size: 15px;
  font-weight: normal;
  margin: 15px 0 0 0;
  color: black;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  .member-email {
    font-weight: bold;
    text-overflow: ellipsis;
    width: 250px;
    white-space: nowrap;
    display: inline-block;
    overflow: hidden;
    margin-left: 5px;
  }
`;
