// @flow

import React, { PureComponent } from "react";
import styled from "styled-components";

import SettingsContainer from "./SettingsContainer";
import EnableStep from "./EnableStep";
import { Loader } from "../TwoFactorAuth/SharedComponents";

import { __ } from "../../lib/translate";
import apiMethod from "../../api/apiMethod";

type Props = {
  isPremium: boolean,
  hasUsedBranding: boolean
};

type State = {
  screen: "enable" | "settings" | null,
  isActiveGL: boolean,
  downloadLinksCount: number,
  customLinks: Array<Object>
};

class BrandingContainer extends PureComponent<Props, State> {
  static defaultProps = {};

  constructor(props: Props) {
    super(props);

    this.state = {
      screen: null,
      isActiveGL: false,
      customLinks: [],
      downloadLinksCount: 0
    };

    (this: any).renderScreen = this.renderScreen.bind(this);
    (this: any).onEnable = this.onEnable.bind(this);
    (this: any).loadAllData = this.loadAllData.bind(this);
    (this: any).updateGeneralBrandingStatus = this.updateGeneralBrandingStatus.bind(this);
  }

  loadAllData({ generalLink, customLink }) {
    if (generalLink.result) {
      HFN.message(generalLink.error, "error");
    }
    const { hasUsedBranding } = this.props;
    const enableScreenShown = localStorage.getItem("enableScreenShown");

    const customLinks = customLink.publinks.filter(
      link => link.custombranding === true
    );

    const downloadLinksCount = customLink.publinks.length;
    const screen = (generalLink.isactive || customLinks.length || hasUsedBranding || enableScreenShown) ? "settings" : "enable";

    this.setState({ customLinks, downloadLinksCount, screen, isActiveGL: generalLink.isactive });

  }

  getGeneralLinkData() {
    return new Promise((resolve, reject) => {
      apiMethod(
        "getdefaultpublinkbranding",
        { auth: HFN.config.auth },
        data => {
          resolve(data);
        },
        reason => {
          reject(reason);
        }
      );
    });
  }

  updateGeneralBrandingStatus(isActiveGL: boolean) {
    this.setState({
      isActiveGL
    })
  }

  getCustomLinks() {
    return new Promise((resolve, reject) => {
      apiMethod(
        "listpublinks",
        { auth: HFN.config.auth, iconformat: "id" },
        data => {
          resolve(data);
        },
        reason => {
          reject(reason);
        }
      );
    });
  }
  
  componentDidMount() {
    Promise.all([this.getGeneralLinkData(), this.getCustomLinks()]).then(ret => {
      this.loadAllData({ generalLink: ret[0], customLink: ret[1] });
    });
  }

  onEnable() {
    const state = { page: "publinks" };
    localStorage.setItem("enableScreenShown", 1);
    $.bbq.pushState(state, 2);
  }

  renderScreen() {
    const { 
      screen, 
      isActiveGL,
      downloadLinksCount,
      customLinks,
    } = this.state;

    const { isPremium } = this.props;

    if (screen === "enable") {
      return (
        <EnableStep
          isPremium={isPremium}
          downloadLinksCount={downloadLinksCount}
          onEnable={this.onEnable}
        />
      );
    } else if (screen === "settings") {
      return (
        <SettingsContainer
          isPremium={isPremium}
          isActiveGL={isActiveGL}
          customLinks={customLinks}
          updateGeneralBrandingStatus={this.updateGeneralBrandingStatus}
          updateCustomBrandingStatus={this.updateCustomBrandingStatus}
        />
      );
    }

    return <Loader/>;
  }

  render() {
    return <Wrapper>{this.renderScreen()}</Wrapper>;
  }
}

export default BrandingContainer;

const Wrapper = styled.div`
  margin-top: 10px;
`;
