import React, {useEffect, useRef, useState} from "react";
import styled, {keyframes} from "styled-components";
import {motion} from "framer-motion";

const LongTextAnimationContainer = ({
    animateOnHover = false,
    fixAnimation = false,
    delay = 0,
    changeModifier = 0,
    children,
}) => {
  const [animated, setAnimated] = useState(false);
  const [containerWidth, setContainerWidth] = useState(0);
  const [animationTime, setAnimationTime] = useState(0);
  const wrapperRef = useRef();

  useEffect(() => {
    const parent = wrapperRef.current;
    const child = wrapperRef.current.querySelector('div');

    if (!child) {
      return;
    }
    setAnimated(false);

    const timeout = setTimeout(() => {
      child.style.display = 'inline-block';
      const wrapperWidth = parent.offsetWidth;
      const childWidth = child.offsetWidth;
      if (wrapperWidth < childWidth) {
        setAnimated(true);
        setContainerWidth(wrapperWidth);
        setAnimationTime((childWidth / wrapperWidth) * 10);
        child.style.removeProperty('display');
      } else {
        setAnimated(false);
      }
    }, delay);

    return () => clearTimeout(timeout);
  }, [changeModifier]);

  const animatedClass = animateOnHover ? 'hover-animated' : 'animated';

  return (
    <Wrapper
        ref={wrapperRef}
        className={animated ? animatedClass : null}
        $containerWidth={containerWidth}
        $animationTime={animationTime}
    >
      <Child
          $fixAnimation={fixAnimation}
          animate={fixAnimation ? { opacity: 1 } : null}
          transition={fixAnimation ? { duration: 0.25 , delay: 0.25} : null}
      >{children}</Child>
      {animated && (
          <Child
              $fixAnimation={fixAnimation}
              className={'second'}
              animate={fixAnimation ? { opacity: 1 } : null}
              transition={fixAnimation ? { duration: 0.25 , delay: 0.25} : null}
          >{children}</Child>
      )}
    </Wrapper>
  )
}

export default LongTextAnimationContainer;

const scroll = keyframes`
  0% {
    transform: translateX(0);
  }
  60% {
    transform: translateX(calc(-100% - (var(--containerWidth) / 3)));
  }
  100% {
    transform: translateX(calc(-100% - (var(--containerWidth) / 3)));
  }
`;

const Wrapper = styled(motion.div)`
  --containerWidth: ${({$containerWidth}) => $containerWidth ? $containerWidth+"px" : "0px"};
  --animationTime: ${({$animationTime}) => $animationTime ? $animationTime+"s" : "10s"};
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  height: inherit;
  
  transition: all 500ms linear;
  
  &.hover-animated > div.second {
    display: none;
  }
  
  &.animated,
  &.hover-animated:hover {
    display: inline-block;
    mask:
        linear-gradient(to top,  transparent 10%, #fff 15% 90%, transparent 95%),
        linear-gradient(to left, transparent 10%, #fff 15% 90%, transparent 95%);
    mask-size: 115% 110%;
    mask-position: center;
    mask-repeat:no-repeat;
    mask-composite: intersect;

    -webkit-mask:
        linear-gradient(to top,  transparent 10%, #fff 15% 90%, transparent 95%),
        linear-gradient(to left, transparent 10%, #fff 15% 90%, transparent 95%);
    -webkit-mask-size:115% 110%;
    -webkit-mask-position:center;
    -webkit-mask-repeat:no-repeat;
    -webkit-mask-composite: source-in;
    
    & > div {
      height: inherit;
      display: inline-block;
      vertical-align: top;
      margin-right: calc(var(--containerWidth) / 3);
      animation: ${scroll} var(--animationTime) linear infinite;
      animation-delay: 1s;
      -webkit-animation: ${scroll} var(--animationTime) linear infinite;
      -webkit-animation-delay: 1s;
    }

    &.hover-animated:hover > div {
      animation-delay: 0s;
      -webkit-animation-delay: 0s;
    }
  }
`;
const Child = styled(motion.div)`
  opacity: ${({$fixAnimation}) => $fixAnimation ? 0 : 1};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;