//@flow

import React, { useState, useEffect } from "react";
import Componentify from "react-componentify";
import styled from "styled-components";
import { __ } from "../../web-shared/utils";

import Modal, { OvalModalCloseSimple } from "../components/Modal";
import {
  boldConverter,
  brTagConverter,
  spanClassTagConverter
} from "../lib/componentifyConverters";

type Props = {
  email: string,
  isModalOpen: boolean,
  onModalClose: () => void
};

const EmailVerifyModal = ({ email, isModalOpen = false, onModalClose = () => {} }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(isModalOpen);
  }, [isModalOpen]);

  const onClose = () => {
    setIsOpen(false);
    onModalClose();
  };

  return (
    <Modal
      animate
      opened={isOpen}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEscape={false}
      onClose={onClose}
    >
      <CloseButton onClick={onClose} />
      <Wrapper>
        <Title>{__("Verify your account")}</Title> 
        <Description>
          <Componentify
            text={__("verify_email_success_description", false, { email: email })}
            converters={[boldConverter, brTagConverter, spanClassTagConverter]}
          />
        </Description>
      </Wrapper>
    </Modal>
  );
};

export default EmailVerifyModal;

const Wrapper = styled.div`
  width: 100%;
  min-width: 640px;
  padding: 35px 20px 40px 20px;
  border-radius: 19px;
  box-shadow: 0 20px 60px 0 rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
  box-sizing: border-box;

  @media (max-width: 768px) {
    min-width: 0px;
  }
`;

const Title = styled.div`
  font-family: "Roboto", sans-serif;
  font-size: 21px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: -0.21px;
  text-align: center;
  color: #000000;
  margin: 0 auto;
  margin-bottom: 10px;
  max-width: 490px;
`;

const Description = styled.div`
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: -0.15px;
  text-align: center;
  color: #000000;
  margin: 0 auto;
  margin-bottom: 10px;
  max-width: 490px;
`;

const CloseButton = styled(OvalModalCloseSimple)`
  top: 20px;
  right: 20px;
  margin: 0;
`;
