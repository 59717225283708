//@flow
import React, { useEffect } from "react";
import styled from "styled-components";

import { siteUrl } from "@pcloud/web-utilities/dist/config";
import { __ } from "../../lib/translate";

type Props = {
  allSettingsBack: any,
  showAlert?: boolean
};

const TopBar = ({ settingId, allSettingsBack, showAlert = false, renderButton = false}: Props) => {
  useEffect(() => {
    if (showAlert && typeof gtag === "function") {
      gtag("event", "shared_link_settings_view", {
        action: "buy premium",
        category: "conversion",
        location: "settings modal",
        location_2: settingId
      })
    }
  }, []);
  
  const openPlansPage = () => {
    if (typeof gtag === "function") {
      gtag("event", "shared_link_settings_click", {
        action: "buy premium",
        category: "conversion",
        location: "settings modal",
        location_2: settingId
      })
    }
  
    window.open(siteUrl("/cloud-storage-pricing-plans.html"));
  };

  return (
    <TopBarWrapper>
      {showAlert ? (
        <Message onClick={openPlansPage}>
          {__("link_settings_upgrade", "This is a Premium feature. Upgrade to activate.")}
        </Message>
      ) : null}
      {allSettingsBack}
      {renderButton ? renderButton() : null}
    </TopBarWrapper>
  );
};

export default TopBar;

export const Message = styled.div`
  background-color: #fff0c4;
  padding: 10px;
  font-size: 15px;
  line-height: 1.2;
  letter-spacing: -0.23px;
  text-align: center;
  color: #ff661c;
  cursor: pointer;

  &::after {
    content: "";
    display: inline-block;
    width: 5px;
    height: 5px;
    border-top: 1px solid #ff661c;
    border-right: 1px solid #ff661c;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(45deg);
    position: relative;
    top: -1px;
    margin-left: 6px;
  }
`;

export const TopBarWrapper = styled.div`
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;
`;
