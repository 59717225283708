// @flow
import React, { PureComponent } from "react";
import styled from "styled-components";
import { hiDPI } from "polished";

type Props = {
  style?: any,
  name: string,
  value: string,
  emptyText: string,
  maxLength?: string,
  isEditable: boolean,
  changeColor: boolean,
  onChange: string => void
};

type State = { showInput: boolean };

class InputDiv extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    (this: any).onPlaceholderClick = (this: any).onPlaceholderClick.bind(this);
    (this: any).onChangeInut = (this: any).onChangeInut.bind(this);
    (this: any).onBlurInput = (this: any).onBlurInput.bind(this);

    this.state = {
      showInput: false
    };
  }

  onPlaceholderClick() {
    const { isEditable } = this.props;

    if (isEditable) {
      this.setState({ showInput: true });
    }
  }

  onBlurInput() {
    this.setState({ showInput: false });
  }

  onChangeInut(e: any) {
    const { onChange } = this.props;
    const value = e.target.value;

    onChange(value);
  }

  render() {
    const {
      value,
      changeColor,
      emptyText,
      maxLength,
      isEditable,
      onChange,
      ...rest
    } = this.props;
    const { showInput } = this.state;

    if (!isEditable && value === "") {
      return null;
    }

    return (
      <React.Fragment>
        {showInput ? (
          <InputStyledText
            {...rest}
            type="text"
            value={value}
            changeColor={changeColor}
            maxLength={maxLength}
            onChange={this.onChangeInut}
            onBlur={this.onBlurInput}
            autoComplete="off"
            autoFocus
          />
        ) : (
          <Placeholder onClick={this.onPlaceholderClick}>
            <PlaceholderText {...rest} changeColor={changeColor}>
              {value || emptyText}
            </PlaceholderText>
            {isEditable ? <EditIcon changeColor={changeColor} /> : null}
          </Placeholder>
        )}
      </React.Fragment>
    );
  }
}

export default InputDiv;

const Placeholder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PlaceholderText = styled.span`
  line-height: 34px;
  color: ${props => (props.changeColor ? "#fff" : "#000")};
`;
const InputStyledText = styled.input`
  width: 100%;
  text-align: center;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 19px;
  line-height: 32px;
  box-sizing: border-box;
  color: ${props => (props.changeColor ? "#fff" : "#000")};

  &.bold {
    font-weight: bold;
  }
`;

const EditIcon = styled.div`
  width: 13px;
  height: 13px;
  margin-left: 10px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: ${props =>
    props.changeColor
      ? `url(${require("../../../root/img/branding-settings/edit-text-white.png")})`
      : `url(${require("../../../root/img/branding-settings/edit-text.png")})`};
  ${hiDPI(1.5)} {
    background-image: ${props =>
      props.changeColor
        ? `url(${require("../../../root/img/branding-settings/edit-text-white@2x.png")})`
        : `url(${require("../../../root/img/branding-settings/edit-text@2x.png")})`};
  }
`;
