import React from "react";
import {ContentHeader, ContentText} from "../styles";
import { __ } from "../../../../lib/translate";
import styled from "styled-components";

const ToggleSlideshowOnExitFSVideoContent = () => {
    return (
        <Wrapper>
            <ContentHeader>{__("onboarding_title_slideshow_off", "Slideshow off")}</ContentHeader>
            <ContentText>{__("onboarding_description_slideshow_off", "Click to resume slideshow view.")}</ContentText>
        </Wrapper>
    );
}

export default ToggleSlideshowOnExitFSVideoContent;

const Wrapper = styled.div`
    width: 280px;
`;