
import React from "react";
import styled, { keyframes } from "styled-components";
import { hiDPI } from "polished";
import { __ } from "../../lib/translate";
import OpenInApp from "../../components/OpenInApp";

import {
    PLAY_STORE_APP_URL,
    APP_STORE_APP_URL
} from "@pcloud/web-utilities/dist/config/constants"
  
  type State = { isOpen: boolean };
  type Props = { isAndroid: boolean };
  
  class OpenAppButton extends React.Component<Props, State> {
    constructor() {
      super();
  
      this.state = {
        isOpen: true
      };
  
      (this: any).open = this.open.bind(this);
      (this: any).close = this.close.bind(this);
      (this: any).openApp = this.openApp.bind(this);
    }

    componentDidMount() {
      let inputs = document.querySelectorAll("input[type='text'], input[type='number'], input[type='password'], input[type='email'], textarea");

      Array.prototype.forEach.call(inputs, (input) => {
        input.addEventListener("focusin", this.close);
        input.addEventListener("focusout", this.open);
      });
    }
  
    open() {
      this.setState({ isOpen: true });
    }
  
    close() {
      this.setState({ isOpen: false });
    }
  
    openApp() {
      const { isAndroid } = this.props;
  
      // if (isAndroid) {
      //   if (typeof gaAll === "function") {
      //     gaAll("event", "open android app", "click");
      //   }
        
      //   setTimeout(()=>{
      //     window.location = PLAY_STORE_APP_URL;
      //   }, 1000)

      // } else {
      //   if (typeof gaAll === "function") {
      //     gaAll("event", "open ios app", "click");
      //   }

      //   setTimeout(()=>{
      //     window.location = APP_STORE_APP_URL;
      //   }, 1000)
      // }

      window.location = "https://www.pcloud.com/app-open.html"
    }
  
    render() {
        const { isOpen } = this.state;

        if (isOpen) {
            return (
                <Container>
                    <OpenInApp onOpen={this.openApp} onClose={this.close} />
                </Container>
            );
        }

        return null;
    }
}
  
export default OpenAppButton;

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
`;

const Container = styled.div `
    opacity: 0;
    position: fixed;
    bottom: 30px;
    width: 100%;
    text-align: center;
    animation-name: ${ fadeInUp };
    animation-delay: 200ms;
    animation-duration: 400ms;
    animation-timing-function: ease-in-out;
    animation-direction: alternate;
    animation-fill-mode: forwards;
`

  

