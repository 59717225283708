//@flow

import {
  DefaultScFieldStyles,
  SAFECHARGE_MERCHANT_SITE_ID,
  SAFECHARGE_MERCHANT_ID,
  SAFECHARGE_ENV
} from "./constants";

import { loadScript } from "./utils";

type Params = {
  inputContainerIDs?: {
    cardNumberID: string,
    ExpDateID: string,
    CVCID: string
  },
  ScFieldStyles?: any,
  language?: string,
  callback?: () => void
}

let sf = (() => {});
let sfc;

loadScript("https://cdn.safecharge.com/safecharge_resources/v1/websdk/safecharge.js", () => {
  if (typeof SafeCharge !== 'undefined') {
    window.sf = SafeCharge;
  }

  sfc = window.sf({
    env: SAFECHARGE_ENV.replace('SC_ENVIRONMENT_VAR_', ''),
    merchantId: SAFECHARGE_MERCHANT_ID.replace('SC_TEST_MERCHANT_ID_', ''),
    merchantSiteId : SAFECHARGE_MERCHANT_SITE_ID.replace('SC_TEST_MERCHANT_ID_', ''),
  });
  window.sfc = sfc;
  
  window.dispatchEvent(new Event("sfcReady"));
})

window.SFCardNumber = null;
window.cardExpiry = null;
window.cardCvc = null;

export { sfc };

export const insertSafechargeForm = ({
  inputContainerIDs = {
    cardNumberID: "card-number",
    ExpDateID: "card-expiry",
    CVCID: "card-cvc"
  },
  ScFieldStyles = DefaultScFieldStyles,
  language = "en",
  callback = () => {}
}: Params) => {
  const { cardNumberID, ExpDateID, CVCID } = inputContainerIDs;

  const initSafeChargeFields = () => {
    const ScFields = sfc.fields({
      fonts: [
        { cssUrl: 'https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&amp;subset=cyrillic-ext,latin-ext,vietnamese' }
      ],
      locale: language
    })
  
    const ScFieldClasses = {
      focus: 'focused',
      empty: 'empty',
      invalid: 'invalid',
      complete: 'complete'
    };
  
    window.SFCardNumber = ScFields.create('ccNumber', {
      style: ScFieldStyles,
      classes: ScFieldClasses
    });
    window.SFCardNumber.attach('#' + cardNumberID);
    
    window.cardExpiry = ScFields.create('ccExpiration', {
      style: ScFieldStyles,
      classes: ScFieldClasses
    });
    window.cardExpiry.attach('#' + ExpDateID);
    
    window.cardCvc = ScFields.create('ccCvc', {
      style: ScFieldStyles,
      classes: ScFieldClasses
    });
    window.cardCvc.attach('#' + CVCID);
  
    callback && typeof callback === "function" && callback();
  }
  

  if (sfc) {
    initSafeChargeFields();
  } else {
    window.addEventListener("sfcReady", initSafeChargeFields, { once: true });
  }
}