// @flow
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import MinusIcon from "../../web-shared/images/svg/minus-icon.svg";
import PlusIcon from "../../web-shared/images/svg/plus-icon.svg";
import RangeSlider from "./RangeSlider";
import { formatSizeInGb } from "../lib/utils";
import { BUSINESS_QUOTA_STEP } from "@pcloud/web-utilities/dist/config/constants";

type Props = {
  users: number,
  minValue: number,
  maxValue: number,
  step?: number,
  startValue: number,
  minStartValue: number,
  disabled?: boolean,
  getSliderValue: string => void
};

const AdvancedSlider = ({
  users,
  minValue,
  maxValue,
  step = 100,
  startValue,
  minStartValue,
  disabled = false,
  getSliderValue = () => {}
}: Props) => {
  const [currentValue, setCurrentValue] = useState(startValue);

  useEffect(() => {
    checkValue(currentValue);
  }, [users]);

  const checkValue = (value) => {
    if (value >= maxValue / users) {
      const updatedValue = maxValue / users;
      const roundedValue = Math.floor(updatedValue / BUSINESS_QUOTA_STEP) * BUSINESS_QUOTA_STEP;

      if (roundedValue > BUSINESS_QUOTA_STEP) {
        setCurrentValue(roundedValue);
        getSliderValue(roundedValue);
      } else {
        setCurrentValue(BUSINESS_QUOTA_STEP);
        getSliderValue(BUSINESS_QUOTA_STEP);
      }
    } else if (value < minValue) {
      setCurrentValue(BUSINESS_QUOTA_STEP);
      getSliderValue(BUSINESS_QUOTA_STEP);
    } else {
      setCurrentValue(value);
      getSliderValue(value);
    }
  };

  const formatLabel = value => {
    return formatSizeInGb(value, 0);
  };

  const onMinusClick = () => {
    if (currentValue - step >= minStartValue) {
      checkValue(currentValue - step);
    }
  };

  const onPlusClick = () => {
    checkValue(currentValue + step);
  };

  const onRangeValueChange = value => {
    if (value >= minStartValue) {
      checkValue(value);
    }
  };

  return (
    <Container disabled={disabled}>
      <ButtonWrapper>
        <Button disabled={disabled} onClick={onMinusClick}>
          <MinusIcon width="14px" fill={disabled ? "#dadadc" : "#ff3434"} />
        </Button>
        <SliderWrapper>
          <RangeSlider 
            minValue={minValue}
            maxValue={maxValue}
            value={currentValue}
            step={step}
            disabled={disabled}
            showLabel={true}
            formatLabel={formatLabel}
            onRangeValueChange={onRangeValueChange}
          />
        </SliderWrapper>
        <Button disabled={disabled} onClick={onPlusClick}>
          <PlusIcon width="14px" fill={disabled ? "#dadadc" : "#86c600"} />
        </Button>
      </ButtonWrapper>
    </Container>
  );
};

export default AdvancedSlider;

const Container = styled.div`
  display: table-cell;
  vertical-align: bottom;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${props => (props.disabled ? "default" : "pointer")};
  width: 30px;
  height: 30px;
  max-height: 25px;
  border-radius: 18px;
  box-shadow: 1px 2px 5px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  box-sizing: border-box;
  user-select: none;
  border: none;
`;

const SliderWrapper = styled.div`
  width: 100%;
  margin: 0 15px;
`;
