import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import PulseAnimation from "./Components/PulseAnimation";
import PopupContainer from "./Components/PopupContainer";
import {AnimatePresence} from "framer-motion";
import {useDispatch, useSelector} from "react-redux";
import {ONBOARDING_REMOVE_ITEM} from "../../lib/state/constants/onboarding";

export type PlaceTypes =
    'top-left' |
    'top-right' |
    'bottom-left' |
    'bottom-right' |
    'left' |
    'right'

type Props = {
    id: string,
    content: Array<any>,
    children: any,
    readMore?: string,
    delay?: number,
    temporaryHide?: boolean,
    showOverlay?: boolean,
    pulseAnimation?: boolean,
    pulseCircle?: boolean,
    place?: PlaceTypes,
    container?: HTMLElement
};

const HighlightOnboardingComponent = ({
    temporaryHide = false,
    notShowAfterClose = false,
    pulseAnimation = false,
    pulseCircle = false,
    showOverlay = true,
    readMore = null,
    delay = 0,
    id = null,
    content,
    place = 'left',
    children,
    container = null
}: Props) => {
    const [show, setShow] = useState(false);
    const parentRef = useRef(null);
    const onboardingQueue = useSelector(({ onboarding }) => onboarding.queue);

    const dispatch = useDispatch();

    const handleClose = (e, permanent = false) => {
        setShow(false);
        dispatch({ type: ONBOARDING_REMOVE_ITEM, id: id });
        if (permanent || notShowAfterClose) {
            let onboardingskip = JSON.parse(localStorage.getItem("onboardingskip"))
            if (Array.isArray(onboardingskip)) {
                if (!onboardingskip.includes(id)) {
                    onboardingskip.push(id);
                    localStorage.setItem('onboardingskip', JSON.stringify(onboardingskip));
                }
            } else {
                onboardingskip = [id];
                localStorage.setItem('onboardingskip', JSON.stringify(onboardingskip));
            }
        }
    }

    useEffect(() => {
        let timer;

        const onboardingskip = JSON.parse(localStorage.getItem("onboardingskip"))
        const finalQueue = Array.isArray(onboardingskip) ? onboardingQueue.filter(item => !onboardingskip.includes(item)) : onboardingQueue;

        if (!finalQueue.length || finalQueue[0] !== id) {
            setShow(false);
        } else if (onboardingQueue.length) {
            timer = setTimeout(() => {
                setShow(true);
            }, delay);
        }

        return () => clearTimeout(timer);
    }, [onboardingQueue]);

    useEffect(() => {
        if (temporaryHide) {
            handleClose();
        }
    }, [temporaryHide]);

    if (!id || temporaryHide) {
        return (
            <>
                {children}
            </>
        );
    }

    return (
        <Wrapper ref={parentRef}>
            {pulseAnimation && show && <PulseAnimation pulseCircle={pulseCircle}/>}
            <AnimatePresence>
                {show && (
                    <PopupContainer
                        show={show}
                        onClose={handleClose}
                        content={content}
                        readMore={readMore}
                        parentRef={parentRef}
                        showOverlay={showOverlay}
                        place={place}
                        container={container}
                    />
                )}
            </AnimatePresence>
            <Child $show={show}>{children}</Child>
        </Wrapper>
    )
}

export default HighlightOnboardingComponent;



const Wrapper = styled.div`
    position: relative;
`;

const Child = styled.div`
    position: relative;
    z-index: ${({ $show }) => $show ? '1910' : '3'};
`;