// @flow

import React, { useState, useEffect } from "react";

import Modal, { OvalModalWrap, OvalModalCloseV2 } from "../../Modal";
import Button from "../../ButtonDefault";
import * as Style from "../styledComponents";
import InputText from "../../InputText";

import apiMethod from "../../../api/apiMethod";
import { __ } from "../../../lib/translate";

const EditTeamNameModal = ({ teamName = "", teamId = 0 }: { teamName: string, teamId: number }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [name, setName] = useState(teamName);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsOpen(true);
  }, []);

  const handleClose = () => {
    setIsOpen(false);
  };

  const onEnter = (e: any) => {
    if (e.keyCode === 13) {
      onSave();
    }
  };

  const onNameChange = ({ target }) => {
    setName(target.value);
  };

  const onSave = () => {
    if (isLoading) {
      return;
    }
    if (!name.trim().length) {
      HFN.message(__("business_edit_user_team_name_error"), "error");
      return;
    }

    setIsLoading(true);

    apiMethod(
      "account_teammodify",
      { name: name, teamid: teamId },
      () => {
        HFN.message("Team Changed.");
        handleClose();
        PCB.refreshAll();
      },
      {
        errorCallback: ({ error, result, field }) => {
          setIsLoading(false);
          let errorMessage = __("something_went_wrong_refresh_and_try_again");

          if (result === 2103) {
            if (field === "name") {
              errorMessage = __("business_edit_user_team_name_error");
            }
          } else if (result === 2076) {
            errorMessage = __("error_2076", "You do not have the permissions for this action.");
          } else if (result === 2082) {
            errorMessage = __("business_edit_user_team_name_exists_error", "Team with this name already exists.");
          }

          HFN.message(errorMessage, "error");
          throw new Error(error);
        }
      }
    );
  };

  return (
    <Modal animate onClose={handleClose} opened={isOpen}>
      <OvalModalWrap>
        <OvalModalCloseV2 onClick={handleClose} />
        <Style.Container>
          <Style.Header>{__("modal_edit_team_header", "Edit Team")}</Style.Header>
          <Style.Label>{__("Name", "Name")}</Style.Label>
          <InputText
            name="teamName"
            placeholder={__("modal_edit_team_input_placeholder", "Edit name")}
            onChange={onNameChange}
            value={name}
            autoFocus
            onKeyDown={onEnter}
          />
          <Style.Footer>
            <Button
              color="lightgray4"
              style={{
                marginRight: "5px"
              }}
              onClick={handleClose}
            >
              {__("Close")}
            </Button>
            <Button
              loading={isLoading}
              color="cyan"
              style={{
                marginLeft: "5px"
              }}
              disabledColor="#dddddd"
              onClick={onSave}
            >
              {__("Save")}
            </Button>
          </Style.Footer>
        </Style.Container>
      </OvalModalWrap>
    </Modal>
  );
};

export default EditTeamNameModal;
