// @flow

import React, { useState, useEffect, useCallback } from "react";

import Modal, { OvalModalWrap, CloseModalIcon } from "../Modal";
import Button from "../ButtonDefault";
import * as Style from "./styledComponents";

import apiMethod from "../../api/apiMethod";
import { __ } from "../../lib/translate";
import { errorKeys } from "../../lib/errors";

const DeleteTokenModal = ({ id = 0, deviceName, createdDate, isCurrent = false }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      console.log("ADD event on keyup DeleteTokenModal");
      document.addEventListener("keyup", onEnter);
    } else {
      console.log("REMOVE event on keyup DeleteTokenModal");
      document.removeEventListener("keyup", onEnter);
    }
  }, [isOpen]);

  const handleClose = () => {
    setIsOpen(false);
  };

  const onEnter = useCallback(
    (e: any) => {
      if (e.keyCode === 13) {
        onDeleteTokenClick();
      }
    },
    [onDeleteTokenClick]
  );

  const onDeleteTokenClick = () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    apiMethod(
      "deletetoken",
      { tokenid: id },
      () => {
        setIsLoading(false);
        if (isCurrent) {
          HFN.pages.goto("login");
        } else {
          HFN.message(__("Token Deleted."));
          $.bbq.pushState({ refr: uniqueNum.get() });
          HFN.spaceinfo.init();
        }
        handleClose();
      },
      {
        errorCallback: ({ result, error }) => {
          if (errorKeys[result]) {
            HFN.message(__(errorKeys[result]), "error");
          } else {
            handleClose();
            HFN.message(__("something_went_wrong_refresh_and_try_again"), "error");
            throw new Error(error);
          }
        }
      }
    );
  };

  return (
    <Modal animate onClose={handleClose} opened={isOpen}>
      <OvalModalWrap>
        <CloseModalIcon onClick={handleClose} />
        <Style.Container>
          <Style.Header>{__("Delete Token")}</Style.Header>
          <Style.Box>
            <Style.WrapperElements>
              <Style.ElementBold>{__("Device")}</Style.ElementBold>
              <Style.ElementBold>{__("Created")}</Style.ElementBold>
            </Style.WrapperElements>
            <Style.WrapperElements>
              <Style.ElementUnderLine>{deviceName}</Style.ElementUnderLine>
              <Style.ElementUnderLine>{createdDate}</Style.ElementUnderLine>
            </Style.WrapperElements>
          </Style.Box>
          <Style.Footer>
            <Button
              color="lightgray4"
              style={{
                marginRight: "5px"
              }}
              onClick={handleClose}
            >
              {__("Cancel")}
            </Button>
            <Button
              loading={isLoading}
              disabled={isLoading}
              color="red"
              style={{
                marginLeft: "5px"
              }}
              disabledColor="#dddddd"
              onClick={onDeleteTokenClick}
            >
              {__("Delete")}
            </Button>
          </Style.Footer>
        </Style.Container>
      </OvalModalWrap>
    </Modal>
  );
};

export default DeleteTokenModal;
