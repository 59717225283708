export const galleryHeaderConfig = {
  desktop: {
    slideshowOff: {
      image: {
        arrowBackButton: false,
        mediaTitle: true,
        mediaIcon: true,
        showNavigation: true,
        navigationArrows: true,
        options: {
          download: true,
          share: true,
          moreOption: true
        },
        closeButton: true
      },
      video: {
        arrowBackButton: false,
        mediaTitle: true,
        mediaIcon: true,
        showNavigation: true,
        navigationArrows: true,
        options: {
          download: true,
          share: true,
          moreOption: true
        },
        closeButton: true
      }
    },
    slideshowOn: {
      image: {
        arrowBackButton: false,
        mediaTitle: true,
        mediaIcon: true,
        showNavigation: true,
        navigationArrows: false,
        options: {
          download: false,
          share: false,
          moreOption: false
        },
        closeButton: true
      },
      video: {
        arrowBackButton: false,
        mediaTitle: true,
        mediaIcon: true,
        showNavigation: true,
        navigationArrows: false,
        options: {
          download: false,
          share: false,
          moreOption: false
        },
        closeButton: true
      }
    },
  },
  mobile: {
    slideshowOff: {
      image: {
        arrowBackButton: true,
        mediaTitle: true,
        mediaIcon: false,
        showNavigation: false,
        // navigationArrows: true,
        options: {
          download: true,
          share: false,
          moreOption: true
        },
        closeButton: false
      },
      video: {
        arrowBackButton: true,
        mediaTitle: true,
        mediaIcon: false,
        showNavigation: false,
        // navigationArrows: true,
        options: {
          download: true,
          share: false,
          moreOption: true
        },
        closeButton: false
      }
    },
    slideshowOn: {
      image: {
        arrowBackButton: true,
        mediaTitle: true,
        mediaIcon: false,
        showNavigation: false,
        // navigationArrows: true,
        options: {
          download: false,
          share: false,
          moreOption: false
        },
        closeButton: false
      },
      video: {
        arrowBackButton: true,
        mediaTitle: true,
        mediaIcon: false,
        showNavigation: false,
        // navigationArrows: true,
        options: {
          download: false,
          share: false,
          moreOption: false
        },
        closeButton: false
      }
    }
  },
};