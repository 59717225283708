// @flow

import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";

import apiMethod from "../../api/apiMethod";
import { __ } from "../../lib/translate";
import { setTwoFactorActiveType } from "../../lib/state/actions/user";

import { ButtonCentered } from "../../components/ButtonDefault";
import { Container, Header } from "./SharedComponents";

type Props = {
  token: string,
  password: string,
  setTwoFactorActiveType: boolean => void,
  onSuccess: () => void
};

type State = {
  isLoading: boolean
};

class DeactivateTwoFactorAuthStep extends Component<Props, State> {
  static defaultProps = {
    token: "",
    password: "",
    setTwoFactorActiveType: () => {},
    onSuccess: () => {}
  };

  constructor(props: Props) {
    super(props);

    (this: any).onTurnOffClick = (this: any).onTurnOffClick.bind(this);

    this.state = {
      isLoading: false
    };
  }

  onTurnOffClick() {
    const { token, password, onSuccess, setTwoFactorActiveType } = this.props;
    const { isLoading } = this.state;

    if (isLoading) {
      return;
    }

    this.setState({ isLoading: true });

    apiMethod(
      "tfa_deactivate",
      {
        auth: token,
        password
      },
      () => {
        this.setState({ isLoading: false });
        setTwoFactorActiveType(false);
        onSuccess();
      },
      {
        errorCallback: ({ error }) => {
          this.setState({ isLoading: false });
          HFN.message(__("something_went_wrong_refresh_and_try_again"), "error");
          throw new Error(error);
        }
      }
    );
  }

  render() {
    const { isLoading } = this.state;

    return (
      <Container>
        <Header>{__("Are you sure?")}</Header>
        <WarningText className="important-message off-message">
          <div className="msg-text">{__("tfa_setup_deactivate_message")}</div>
        </WarningText>
        <ButtonCentered
          style={{
            width: "100%",
            marginTop: "30px"
          }}
          disabled={isLoading}
          loading={isLoading}
          onClick={this.onTurnOffClick}
        >
          {__("Turn off")}
        </ButtonCentered>
      </Container>
    );
  }
}

export default connect(
  ({ user }) => {
    const { token, userinfo: { password } = {} } = user;

    return {
      token,
      password
    };
  },
  dispatch =>
    bindActionCreators(
      {
        setTwoFactorActiveType
      },
      dispatch
    )
)(DeactivateTwoFactorAuthStep);

const WarningText = styled.div`
  padding: 20px;
  box-sizing: border-box;
  border-radius: 5px;
  border: solid 1px rgba(247, 60, 60, 0.05);
  background-color: rgba(247, 60, 60, 0.07);
  font-size: 13px;
  font-weight: normal;
  color: #f73c3c;
`;
