// @flow

import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { updateUserBillingInfo } from "../lib/state/actions/user";
import { parseBillingInfo } from "../lib/state/parseUser";

import InvoiceInfo from "../components/InvoiceInfo";
import BillingHistory from "../components/BillingHistory";

type BillingInfo = {
  billingAddress: string,
  vat?: string,
  country: string,
  company?: ?string,
  names?: ?string,
  type: number
};

type PaymentsInfo = {
  amount: string,
  billingProvider: string,
  canCreateInvoice: boolean,
  currency: string,
  instrument: string,
  invoiceCode: string,
  invoiceNumber: string,
  receiptCode: string,
  paymentDate: string,
  paymentId: string
};

type Props = {
  token?: string,
  isBusiness?: boolean,
  billingInfo: BillingInfo,
  payments: PaymentsInfo,
  updateUserBillingInfo: () => void
};

class FinanceContainer extends React.Component<Props, {}> {
  static defaultProps = {
    token: "",
    isBusiness: false
  };

  constructor(props: Props) {
    super(props);

    (this: any).onApiSuccessCallback.bind(this);
  }

  onApiSuccessCallback = (params: BillingInfoParams) => {
    console.log("Received params: ", params);
    const { updateUserBillingInfo } = this.props;

    updateUserBillingInfo(parseBillingInfo(params));

    // remove Invoice info bar
    if (HFN.reactRemoveInvoiceInfoBar) {
      HFN.reactRemoveInvoiceInfoBar();
    }
  };

  render() {
    const { billingInfo, payments } = this.props;

    return (
      <React.Fragment>
        <InvoiceInfo
          billingInfo={billingInfo}
          onApiSuccessCallback={this.onApiSuccessCallback}
          {...this.props}
        />
        <BillingHistory />
      </React.Fragment>
    );
  }
}

export default connect(
  ({ user }) => {
    const { token, userSettings: { billingInfo = {}, payments = [] } = {} } = user;

    return {
      token,
      billingInfo,
      payments
    };
  },
  dispatch =>
    bindActionCreators(
      {
        updateUserBillingInfo
      },
      dispatch
    )
)(FinanceContainer);
