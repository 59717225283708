// @flow
import React, { PureComponent } from "react";
import styled from "styled-components";

import { TSpan, TString } from "../../components/intl";
import Button, { ButtonCentered } from "../../components/ButtonDefault";
import Modal, {
  ModalTop,
  ModalBody,
  ModalButtonArea
} from "../../components/Modal";

import { __ } from "../../lib/translate";

const buttonStyle = {
  margin: "0 15px",
  minWidth: "125px"
};

type Props = {
  email: string,
  isOpen: boolean,
  onCloseModal: () => void
};
type State = {};

class WarningInvitationModal extends PureComponent<Props, State> {
  static defaultProps = {
    email: "",
    isOpen: false,
    onCloseModal: () => {}
  };

  constructor(props: Props) {
    super(props);

    (this: any).onClose = this.onClose.bind(this);
    (this: any).onLogOut = this.onLogOut.bind(this);
  }

  onClose() {
    const { onCloseModal } = this.props;

    onCloseModal();
  }

  onLogOut() {
    const { onCloseModal } = this.props;

    onCloseModal();
    HFN.logout()
  }

  render() {
    const { isOpen, email } = this.props;

    return (
      <Modal opened={isOpen} onClose={this.onClose}>
        <ModalTop onClose={this.onClose}>
          <TSpan id="Warning">Warning</TSpan>
        </ModalTop>
        <ModalBody style={{ maxWidth: "450px" }}>
          <Message>
            {__("You are currently logged in as")} <b>{email}</b>.
          </Message>
          <Message>
            {__(
              "Note that by joining pCloud Business with your existing account, you will:"
            )}
          </Message>
          <Message>
            <ul>
              <li>{__("suspend all of your shares")}</li>
              <li>
                {__(
                  "keep your existing files, but users with certain permissions will be able to see them"
                )}
              </li>
            </ul>
          </Message>
          <Message>
            {__('If you want to join with a new account, click on "Logout".')}
          </Message>
        </ModalBody>
        <ModalButtonArea>
          <Button
            color="green"
            style={{ ...buttonStyle }}
            onClick={this.onClose}
            inline
          >
            {__("Continue")}
          </Button>
          <Button
            color="red"
            style={{ ...buttonStyle }}
            onClick={this.onLogOut}
            inline
          >
            {__("Logout")}
          </Button>
        </ModalButtonArea>
      </Modal>
    );
  }
}

export default WarningInvitationModal;

const Message = styled.div`
  font-size: 13px;
`;
