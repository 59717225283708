//flow

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { hiDPI } from "polished";

import Modal, { ModalHolder, OvalModalWrap, OvalModalCloseV2 } from "./Modal";
import Button from "./ButtonDefault";
import apiMethod from "../api/apiMethod";
import { __ } from "../lib/translate";

type Props = {
  folderid: number
};

const StopBackupModal = ({ data }) => {
  const [isOpened, setIsOpened] = useState(true);

  const onCancelClick = () => {
    setIsOpened(false);
  };

  const onStopClick = () => {
    let method = "";
    if (data.isbackupdevice) {
      method = "backup/stopdevice";
    } else if (data.isbackuproot) {
      method = "backup/stopbackup";
    }
    apiMethod(
      method,
      { folderid: data.folderid },
      function() {
        console.log("backup Stopped");
        HFN.message(__("Stopped"));
        setIsOpened(false);
      },
      {
        errorCallback: function() {
          HFN.message(__("something_went_wrong_refresh_and_try_again"), "error");
        }
      }
    );
  };

  return (
    <Modal shouldCloseOnOverlayClick={false} onClose={() => setIsOpened(false)} opened={isOpened}>
      <OvalModalWrap>
        <OvalModalCloseV2 onClick={() => setIsOpened(false)} />
        <Content>
          <Title>{__("Stop backing up this folder")}</Title>
          <Text>
            {__("If you proceed no longer be backed up in pCloud")}
          </Text>
          <ButtonWrapper>
            <Button inline color="lightgray4" onClick={onCancelClick}>
              {__("Cancel")}
            </Button>
            <Button inline color="red" onClick={onStopClick}>
              {__("Stop Backup")}
            </Button>
          </ButtonWrapper>
        </Content>
      </OvalModalWrap>
    </Modal>
  );
};

export default StopBackupModal;

const Content = styled.div`
  padding: 40px;
  width: 600px;
  box-sizing: border-box;
  text-align: center;
`;

const Title = styled.div`
  font-size: 21px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const Text = styled.div`
  display: inline-block;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  line-height: 1.2;
  letter-spacing: -0.1px;
  text-align: center;
  color: #111111;
  margin-bottom: 35px;
  max-width: 450px;
`;

const ButtonWrapper = styled.div`
  text-align: center;
  a {
    margin: 0px 5px;
    display: inline-block;
    min-width: 100px;
  }
`;
