// @flow

import * as React from "react";
import styled from "styled-components";

import ArrowIcon from "../../../root/img/svg/picker-breadcrumb.svg";
import { CRYPTO_FOLDER, ROOT_FOLDER_ID, ROOT_FOLDER_NAME } from "./constants";

const iconStyle = { margin: "0 5px", minWidth: "10px" };

type NavigationFolderProps = {
  index: number,
  itemId: string,
  name: string,
  onNameClick: string => void,
  shouldRenderIcon: boolean
};

const NavigationFolder = ({
  index = ROOT_FOLDER_ID,
  itemId = ROOT_FOLDER_ID,
  name = ROOT_FOLDER_NAME,
  onNameClick = () => {},
  shouldRenderIcon = true
}: NavigationFolderProps) => {
  if (itemId === "ellipsis") {
    return (
      <Wrapper>
        <ArrowIcon style={iconStyle} />
        <Ellipsis>...</Ellipsis>
      </Wrapper>
    );
  }
  return (
    <Wrapper>
      {shouldRenderIcon && <ArrowIcon style={iconStyle} />}
      <FolderName
        isBold={index == ROOT_FOLDER_ID}
        onClick={() => onNameClick(itemId)}
      >
        {name}
      </FolderName>
    </Wrapper>
  );
};

export default NavigationFolder;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
  flex-directions: column;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  min-width: 37px;
  
  &:first-of-type {
    /* padding-right: 15px; */
  }
`;

const FolderName = styled.div`
  cursor: pointer;
  font-size: 15px;
  font-weight: ${props => (props.isBold ? "bold" : "normal")};
  color: #000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
`;

const Ellipsis = styled.span`
  min-width: 16px;
`;
