// @flow

import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { hiDPI } from "polished";

import { __ } from "../lib/translate";
import apiMethod from "../api/apiMethod";
import EmailVerifyModal from "./EmailVerifyModal";

const EmailVerificationSettings = () => {
  const [openModal, setOpenModal] = useState(false);
  const isVerified = useSelector(({ user }) => user.userinfo.emailverified);
  const email = useSelector(({ user }) => user.userinfo.email);

  const onVerifyClick = () => {
    apiMethod(
      "sendverificationemail",
      {},
      () => {
        setOpenModal(true);
      },
      {
        errorCallback: ({ error }) => {
          HFN.message(__("something_went_wrong_refresh_and_try_again"), "error");
          throw new Error(error);
        }
      }
    );
  };

  const renderCol = () => {
    if (!isVerified) {
      return (
        <VerifyButton onClick={onVerifyClick}>
          {__("setting_verify_account_button", "Verify account")}
        </VerifyButton>
      );
    } else {
      return (
        <ButtonWrapper>
          <Button isVerified={isVerified}>{__("Verified")}</Button>
          <VerifiedIcon />
        </ButtonWrapper>
      );
    }
  };

  return (
    <React.Fragment>
      <Title className="row-title">{__("your_email", "Your Email")}</Title>
      <Row>
        <Col>
          <Email>
            {email}
            {!isVerified ? <NotVerifiedIcon /> : null}
          </Email>
          {!isVerified ? (
            <Description>
              {__(
                "settings_not_verifed_email_message",
                "At the moment, you don't have full access to pCloud."
              )}
            </Description>
          ) : null}
        </Col>
        <Col>{renderCol()}</Col>
      </Row>
      <EmailVerifyModal email={email} isModalOpen={openModal} onModalClose={() => setOpenModal()} />
    </React.Fragment>
  );
};

export default EmailVerificationSettings;

const Title = styled.div`
  font-size: 15px;
  font-weight: bold;
  height: 31px;
  padding-top: 17px;
  padding-left: 2px;
  margin: 10px 0 0 0;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 2px solid #ddd;
  background: #fafafa;
  border-bottom: 1px solid #e9e9e9;
  padding: 15px 8px 15px 8px;
  font-size: 13px;
  line-height: 18px;
  margin: 0;
`;

const Col = styled.div`
  display: flex;
  margin: 0px 10px;

  &:first-of-type {
    display: inline-block;
    margin-left: 0px;
  }

  &:last-of-type {
    margin-right: 0px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Button = styled.div`
  color: ${props => (!props.isVerified ? "red" : "#00B900")};
  font-weight: bold;
  text-decoration: ${props => (!props.isVerified ? "underline" : "default")};
  cursor: ${props => (!props.isVerified ? "pointer" : "default")};
  margin-right: 5px;
`;

const VerifyButton = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: bold;
  color: #17bed0;
  text-decoration: underline;
  cursor: pointer;
`;

const VerifiedIcon = styled.div`
  display: block;
  width: 20px;
  height: 20px;

  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url(${require("../../root/img/yes.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../root/img/yes@2x.png")});
  }
`;

const NotVerifiedIcon = styled(VerifiedIcon)`
  display: inline-block;
  margin-left: 8px;
  width: 20px;
  height: 20px;
  background-size: 20px 20px;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(${require("../../root/img/header/unverified.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../root/img/header/unverified@2x.png")});
  }
`;

const Email = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  font-size: 13px;
  font-weight: bold;
  color: #000;
`;

const Description = styled.div`
  font-size: 13px;
  font-weight: normal;
`;
