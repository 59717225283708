import apiMethod from "./apiMethod";
import { __ } from "../lib/translate";

export default function createFolder({ parent, folderName }, options) {
  const { folderid, encrypted } = parent;

  const optsApiMethod = {
    forceFresh: true,
    cacheTime: false,
    showErrorMessage: false,
    errorCallback: ({ result, error }) => {
      if (HFN.ERROR_MESSAGE[result]) {
        HFN.message(__(HFN.ERROR_MESSAGE[result]), "error");
      } else {
        HFN.message(error, "error");
      }

      if (options.onError) {
        options.onError({ result, error });
      }
    },
    onTimeout: () => {
      if (options.onTimeout) {
        options.onTimeout();
      }
    }
  };

  const prep = callback => {
    if (encrypted) {
      pCloudCrypto.asyncEncryptMetaAndKey(
        { parentfolderid: folderid, name: folderName },
        (encName, key) => {
          const params = {};

          params.name = encName;
          params.key = key;
          params.encrypted = 1;

          callback(params);
        }
      );
    } else callback();
  };

  prep((opts = {}) => {
    let params = $.extend({}, { folderid: folderid, name: folderName }, opts);
    let isPublink = HFN.config.isDlink();
    let method = "createfolder";

    if (!options.isSaveToCloud && isPublink) {
      method = "publink/createfolder";
      params.code = HFN.getHash("code") || HFN.getSearch("code") || "";
      params.iconformat = "id";
    }

    return apiMethod(
      method,
      params,
      ret => {
        const { metadata } = ret;
        const { ismine, folderid } = metadata;
        HFN.message(__("Folder Created."));

        if (isPublink && !ismine) {
          HFN.diffHandler.eventHandlers.createfolder(ret);
          if (options.shouldOpenAfter) {
            setTimeout(() => {
              HFN.openFolder(folderid);
            }, 0);
          }
        } else {
          if (options.shouldOpenAfter) {
            const unsub = HFN.diffHandler.listen(events => {
              const isCreateFolderEvent = events.filter(
                ev => ev.event === "createfolder" && ev.metadata.folderid === folderid
              ).length;

              if (isCreateFolderEvent) {
                setTimeout(() => {
                  unsub();
                  HFN.openFolder(folderid);
                }, 0);
              }
            });
          }
        }
        HFN.cache.expire(HFN.cache.cacheid("folders", "lookup"));
        if (options.onSuccess) {
          options.onSuccess(ret);
        }
      },
      optsApiMethod
    );
  });
}
