import React, { Component } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { toParseDt, toTime, allignTime, range } from "../lib/utils";
import apiMethod from "../api/apiMethod";
import GridDownloadLinkStats from "../components/downloadlink/UsageStats";

const day = 3600 * 24 * 1000;

export default class DLUsageStats extends Component {
	static propTypes = {
		token: PropTypes.string.isRequired,
		linkid: PropTypes.number,
		directlink: PropTypes.boolean
	};

	constructor(props) {
		super(props);

		this.state = {
			initial: true,
			loading: true,
			dates: getInitialPage(),
			list: []
		};

		this._setPrev = this._setPrev.bind(this);
		this._setNext = this._setNext.bind(this);
		this._load = this._load.bind(this);
	}

	componentDidMount() {
		this._load();
	}

	_load() {
		const { dates: { from, to } } = this.state;
		const { linkid = false, token, directlink = false } = this.props;
		const params = {
			bdate: new Date(from).toISOString(),
			edate: new Date(to).toISOString(),
			groupby: "date",
			auth: token
		};

		if (linkid) {
			params.linkids = linkid;
		}

		if (directlink) {
			params.directlink = directlink;
		}

		if (!linkid && !directlink) {
			HFN.message(__("something_went_wrong_refresh_and_try_again"), "error");
			throw new Error("Missing params linkid or directlink");
		}

		this.setState({ loading: true });

		this.xhr = apiMethod(
			"getpublinkstats",
			params,
			ret => {
				this.setState({
					list: fromPublinkStats(ret.stats, from, to),
					loading: false,
					initial: false
				});
			}
		);
	}

	componentWillUnmount() {
		if (this.xhr) {
			this.xhr.abort();
		}
	}

	_setPrev() {
		this.setState({ dates: moveDates(this.state.dates, substract) }, () =>
			this._load()
		);
	}

	_setNext() {
		this.setState({ dates: moveDates(this.state.dates, add) }, () =>
			this._load()
		);
	}

	render() {
		const { initial, loading, list, dates: { from, to } } = this.state;

		if (loading && 0) {
			return <div>Loading ...</div>;
		}

		return (
			<Wrap>
				<GridDownloadLinkStats
					from={from}
					to={to}
					list={list}
					onPrev={this._setPrev}
					onNext={this._setNext}
				/>
				<Loading loading={loading}>
					<LoadingImage />
				</Loading>
			</Wrap>
		);
	}
}

const Wrap = styled.div`
	position: relative;
	width: 100%;
`;

const Loading = styled.div`
  position: absolute;
  width: 100%;
  background: #fafafa;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: ${props => (props.loading ? "block" : "none")};
`;

const LoadingImage = styled.div`
  position: absolute;
  width: 30px;
  height: 30px;
  border: 1px solid #f6f6f6;
  top: 50%;
  left: 50%;
  margin-top: -15px;
  margin-left: -15px;
  background: #ddd url(${require("../../root/img/dlink/loading.gif")}) no-repeat center center;
  box-sizing: border-box;
`;

const add = (a, b) => a + b;
const substract = (a, b) => a - b;
const moveDates = (dates, func) => ({
	from: toParseDt(func(toTime(dates.from), day * 7)),
	to: toParseDt(func(toTime(dates.to), day * 7))
});
const getInitialPage = () => ({
	from: toParseDt(Date.now() - 3600 * 24 * 6 * 1000),
	to: toParseDt(Date.now())
});

function fromPublinkStats(stats, from, to) {
	let list = emptyList(from, to);
	let obj;

	for (let i = 0; i < stats.length; ++i) {
		if ((obj = list.filter(obj => obj.dt === stats[i].dt)[0])) {
			obj.views = parseInt(stats[i].views || 0);
			obj.downloads = parseInt(stats[i].downloads);
			obj.traffic = parseInt(stats[i].traffic, 10);
		}
	}

	return list;
}

function emptyList(from, to) {
	const begin = allignTime(from);
	const end = allignTime(to);

	return range((end - begin) / day + 1).map(i => ({
		dt: toParseDt(begin + day * i),
		downloads: 0,
		traffic: 0,
		views: 0
	}));
}
