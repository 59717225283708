import React from 'react';
import ActionButton from "../../../userinterface/ActionButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose} from '@fortawesome/pro-solid-svg-icons/faClose';
import {faMinus} from '@fortawesome/pro-solid-svg-icons/faMinus';

const FullPageBoxControls = ({
    onPlayerClose = () => {},
    onShowMiniPlayer = () => {},
 }) => {
    return (
        <>
            <ActionButton
                onClick={onShowMiniPlayer}
                className={'svg-color-default'}
                label={<FontAwesomeIcon style={{fontSize: 16}} icon={faMinus} />}
            />
            <ActionButton
                onClick={onPlayerClose}
                className={'svg-color-default'}
                label={<FontAwesomeIcon style={{fontSize: 16}} icon={faClose} />}
            />
        </>
    );
}

export default FullPageBoxControls;