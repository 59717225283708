// @flow

import React from "react";
import styled from "styled-components";
import { __ } from "../../lib/translate";

import { PLAY_STORE_APP_URL, APP_STORE_APP_URL } from "@pcloud/web-utilities/dist/config/constants";

import AndroidIcon from "../../../root/img/mobile/logo-android-use-modal.svg";
import IosIcon from "../../../root/img/mobile/logo-ios-use-modal.svg";

import GoogleStoreIcon from "../../../root/img/mobile/google-store.svg";
import AppStoreIcon from "../../../root/img/mobile/app-store.svg";

import UseAppContainerBackgroundAndroid from "../../../root/img/mobile/use-app-container-android.svg";
import UseAppContainerBackgroundIos from "../../../root/img/mobile/use-app-container-ios.svg";

type Props = { deviceKey: string }; //deviceKey android || ios

const DEVICE_KEY_TO_ICON = {
  android: {
    icon: <AndroidIcon width="64px" height="64px" />,
    button: <GoogleStoreIcon />,
    background: <UseAppContainerBackgroundAndroid />
  },
  ios: {
    icon: <IosIcon width="64px" height="64px" />,
    button: <AppStoreIcon />,
    background: <UseAppContainerBackgroundIos />
  }
};

const deviceName = {
  android: "Android",
  ios: "iPhone"
};

const appStoreURLs = {
  android: PLAY_STORE_APP_URL,
  ios: APP_STORE_APP_URL
};

const UseAppContainer = ({ deviceKey }: Props) => {
  const openAppStore = () => {
    window.location = appStoreURLs[deviceKey];
  };

  return (
    <Container deviceKey={deviceKey}>
      <ContainerBackground deviceKey={deviceKey}>
        {DEVICE_KEY_TO_ICON[deviceKey] && DEVICE_KEY_TO_ICON[deviceKey].background}
      </ContainerBackground>
      {DEVICE_KEY_TO_ICON[deviceKey] && DEVICE_KEY_TO_ICON[deviceKey].icon}
      <Message>
        {__("use_app_mobile", "For full pCloud experience, please use pCloud Mobile App for %device_name%.").replace(
          "%device_name%",
          deviceName[deviceKey]
        )}
      </Message>
      {/*  DISABLE AUTO FOCUS ON LOGIN INPUTS FOR IOS SAFARI */}
      <input style={{ display: "none" }} type="text" autoFocus="true" />
      <DownloadAppIcon onClick={openAppStore}>
        {DEVICE_KEY_TO_ICON[deviceKey] && DEVICE_KEY_TO_ICON[deviceKey].button}
      </DownloadAppIcon>
    </Container>
  );
};

export default UseAppContainer;

const DownloadAppIcon = styled.div`
  width: 50%;
  height: 45px;
  margin: 0 auto;
  margin-top: 30px;
  @media only screen and (max-width: 375px) {
    width: 170px;
  }
`;

const Container = styled.div`
  position: relative;
  text-align: center;
  padding: 20px 10px;
  font-family: "Roboto", sans-serif;
  color: #000;
  height: 375px;
  max-width: 375px;
  margin: 0 auto;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  gap: 2rem;

  @media only screen and (min-width: 375px) {
    height: 325px;
  }
`;

const ContainerBackground = styled.div`
  & > svg {
    height: 325px;
    bottom: 0;
    left: 50%;
    position: absolute;
    margin: 0 auto;
    transform: translate(-50%, 0%);
  }
`;

const Message = styled.div`
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: -0.5px;
  text-align: center;
  color: #000000;
  min-width: 220px;
  width: 50%;
  font-size: 1rem;
  margin: 0 auto;
`;
