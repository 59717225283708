// @flow

import * as React from "react";
import styled from "styled-components";

import FileIcon from "./FileIcon";
import { formatSize } from "../lib/utils";
import { ICONS, getFileIcon } from "../lib/icons";

import type { Metadata } from "../types/filesystem";

const ItemRow = ({ item, ...rest }: { item: Metadata }) => {
  const { id, isfolder, thumb, icon, name } = item;

  return (
    <Wrap {...rest}>
      {!item.isfolder && <Size>{formatSize(item.size)}</Size>}
      <Name>
        <FileIcon item={icon} type={ICONS.LIST} metadata={item} {...rest} />
        {name}
      </Name>
    </Wrap>
  );
};

export default ItemRow;

const Name = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const Wrap = styled.div`
  height: 45px;
  line-height: 45px;
  font-size: 15px;

  & .iconwrap {
    margin-top: 10px;
  }

  ${Name.selector}.thumbready div.iconwrap {
    margin-top: 7px;
  }
`;

const Size = styled.div`
  float: right;
  padding-left: 5px;
`;
