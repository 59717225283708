import React, { useCallback, useRef } from 'react';
import FileList from './FilesList';
import styled, { css } from 'styled-components';
import ToggleArrowSvg from "../../../root/img/svg/upload-manager/toggle-arrow.svg";
import CloseSvg from "../../../root/img/svg/upload-manager/close.svg";
import { UPLOAD_TAB, UPLOAD_STATUS, FAILED_BADGE_COLOR } from "./constants";
import WarningOnUploadModal from './WarningOnUploadModal';
import UploadOptionsModal from './UploadOptionsModal';
import UploadCancelModal from './UploadCancelModal';
import UploadRemoteModal from './UploadRemoteModal';
import UploadPasteModal from './UploadPasteModal';
import UploadFailedFolderItemsModal from './UploadFailedFolderItemsModal';
import UploadLockCryptoModal from './UploadLockCryptoModal';
import UploadCloseWithRetry from './UploadCloseWithRetry';
import FailedSvg from "../../../root/img/svg/upload-manager/failed-folder.svg";
import useUploadContext from './hooks/useUploadContext';
import { __ } from "../../lib/translate"
import useOutsideClicker from '../../../web-shared/hooks/useOutsideClicker';
import WarningSvg from "../../../root/img/svg/upload-manager/warning.svg";
import DropdownArrowSvg from "../../../root/img/svg/upload-manager/dropdown-arrow.svg";

const UploadManagerContainer = () => {

  const {
    uploadQueue,
    failedItems,
    isToggled,
    handleToggleState,
    isOpened,
    handleOpenState,
    handleCancel,
    activeTab,
    setActiveTab,
    donePercentage,
    isMobile,
    filteredUploadQueue,
    handleClear,
    handleRetryAll,
    isTabDropdownOpened,
    setIsTabDropdownOpened,
    tabItemsLength,
    isUploadCancelModalOpen,
    handleUploadCancelModalClose,
    handleUploadCancelModalYesClick,
  } = useUploadContext();

  const tabDropdownRef = useRef(null);

  const renderTabDropdownSelect = () => {
    const items = [
      {
        text: __('upload_manager_tabs_all', 'All'),
        id: UPLOAD_TAB.ALL,
        textSelected: __('upload_manager_tabs_all_items', 'All items'),
      },
      {
        text: __('upload_manager_tabs_active', 'Active'),
        id: UPLOAD_TAB.ACTIVE,
        textSelected: __('upload_manager_tabs_active_items', 'Active items'),
      },
      {
        text: __('upload_manager_tabs_completed', 'Completed'),
        id: UPLOAD_TAB.COMPLETED,
        textSelected: __('upload_manager_tabs_completed_items', 'Completed items'),
      },
      {
        text: __('upload_manager_tabs_failed', 'Failed'),
        id: UPLOAD_TAB.FAILED,
        textSelected: __('upload_manager_tabs_failed_items', 'Failed items'),
      }
    ]
    return <UploadTabDropdownSelect>
      <UploadTabDropdownSelectLabel onClick={() => setIsTabDropdownOpened(true)}>
        {items.find((item) => item.id === activeTab).textSelected} <StyledDropdownArrowSvg /> {tabItemsLength[UPLOAD_TAB.FAILED] !== 0 && <StyledWarningSvg />}
      </UploadTabDropdownSelectLabel>
      {isTabDropdownOpened && <UploadTabDropdownModal ref={tabDropdownRef} >
        {items.map((item) => (
          <UploadTabDropdownModalItem key={item.id} onClick={() => {
            setIsTabDropdownOpened(false)
            setActiveTab(item.id)
          }}>
            <UploadTabDropdownModalItemText>{item.text}</UploadTabDropdownModalItemText>
            <UploadTabDropdownModalItemCount $isFailed={item.id === UPLOAD_TAB.FAILED && tabItemsLength[item.id] > 0}>{tabItemsLength[item.id]}</UploadTabDropdownModalItemCount>
          </UploadTabDropdownModalItem>
        ))}
      </UploadTabDropdownModal>}
    </UploadTabDropdownSelect>
  }

  useOutsideClicker(tabDropdownRef, () => {
    setIsTabDropdownOpened(false);
  })

  const renderHeaderContent = () => {
    if (!uploadQueue.length) {
      return __('Upload Manager', 'Upload Manager');
    }

    if (donePercentage.isUploading) {
      return donePercentage.pendingItems === 1 ? __('upload_manager_uploading_single', 'Uploading 1 item') : __('upload_manager_uploading', 'Uploading %itemslength% items').replace('%itemslength%', donePercentage.pendingItems);
    } else {
      return (uploadQueue.length - donePercentage.failedItems) === 1 ? __('upload_manager_uploaded_single', '1 item uploaded') : __('upload_manager_uploaded', '%itemslength% items uploaded').replace('%itemslength%', uploadQueue.length - donePercentage.failedItems);
    }
  }

  const renderManagerActionButton = useCallback(() => {
    const { isUploading } = donePercentage;
    let actionText = '';
    let disabled = false;
    let actionHandler = () => { }
    switch (activeTab) {
      case UPLOAD_TAB.ALL:
        if (isUploading) {
          actionText = __('Cancel', 'Cancel');
          actionHandler = handleCancel;
        } else {
          actionText = __('Clear', 'Clear');
          actionHandler = handleClear;
        }
        break;
      case UPLOAD_TAB.ACTIVE:
        actionText = __('Cancel', 'Cancel');
        actionHandler = handleCancel;
        if (!isUploading) {
          disabled = true;
        }
        break;
      case UPLOAD_TAB.COMPLETED:
        actionText = __('Clear', 'Clear');
        actionHandler = handleClear;
        break;
      case UPLOAD_TAB.FAILED:
        actionText = __('upload_manager_failed_items_action_success', 'Retry all');
        actionHandler = handleRetryAll;
        break;
      default:
        break;
    }

    if (filteredUploadQueue.length === 0) {
      disabled = true;
    }

    return <ManagerActionButton disabled={disabled} onClick={actionHandler}>{actionText}</ManagerActionButton>

  }, [donePercentage.isUploading, activeTab, filteredUploadQueue])

  return (
    <>
      <WarningOnUploadModal />
      <UploadOptionsModal />
      <UploadFailedFolderItemsModal />
      <UploadLockCryptoModal />
      <UploadCloseWithRetry />
      <UploadRemoteModal />
      <UploadPasteModal />
      <ManagerContainer
        id="upload-manager-container"
        className={isMobile ? 'isMobile fixed-content' : 'fixed-content'}
        $isClosed={!isOpened}
        $isToggled={isToggled}
      >
        <TopContainer onClick={isMobile ? handleToggleState : undefined}>
          <UploadingItemsText>
            {(failedItems.length !== 0 && isToggled) && <FailedSvg />}{renderHeaderContent()}
          </UploadingItemsText>
          <IconsContainer>
            <StyledToggleArrowSvg $isToggled={isToggled} onClick={isMobile ? undefined : handleToggleState} />
            <StyledCloseSvg onClick={handleOpenState} />
          </IconsContainer>
        </TopContainer>
        <UploadCancelModal
          show={isUploadCancelModalOpen}
          handleNo={handleUploadCancelModalClose}
          handleYes={handleUploadCancelModalYesClick}
        />
        <MainContainer $isToggled={isToggled}>
          <FileListTabs>
            {renderTabDropdownSelect()}
            {renderManagerActionButton()}
          </FileListTabs>
          <FileList />
        </MainContainer>
      </ManagerContainer>
    </>
  );
};

export default UploadManagerContainer;

const ManagerContainer = styled.div`
  background-color: #FFF;
  width: 400px;
  transition: max-height 0.5s ease-in-out;
  max-height: ${({ $isClosed }) => ($isClosed ? '0' : '366px')};
  border-radius: 4px;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.22);
  overflow: hidden;
  position: relative;

  &.isMobile {
    right: 0;
    bottom: 0;
    width: 100%;
    box-shadow: none;
    border-radius: ${({ $isToggled }) => $isToggled ? 0 : 4}px;
    box-shadow: none;
  }
`;

const TopContainer = styled.div`
  background-color: #494F55;
  padding: 14px 16px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 46px;
  align-items: center;
  box-sizing: border-box;
`

const UploadingItemsText = styled.p`
  color: #FFF;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 8px;
`

const IconsContainer = styled.div`
  display: flex;
  align-items: center;
`

const StyledToggleArrowSvg = styled(ToggleArrowSvg)`
  transition: transform 0.5s ease-in-out;
  cursor: pointer;
  margin-right: 16px;

  ${({ $isToggled }) => $isToggled && css`
    transform: rotate(180deg);
  `}
`

const StyledCloseSvg = styled(CloseSvg)`
  cursor: pointer;
`

const MainContainer = styled.div`
  transition: max-height 0.5s ease-in-out;
  max-height: ${({ $isToggled }) => ($isToggled ? '0' : '320px')};

  .tabs {
    height: 39px;
    width: 280px;
    margin: 0;
  }
`

const FileListTabs = styled.div`
  height: 40px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
`

const ManagerActionButton = styled.span`
  font-family: Roboto;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: #17BED0;
  cursor: pointer;
  
  ${({ disabled }) => disabled && css`
    cursor: default;
    opacity: 0.5;
  `}
`

const UploadTabDropdownSelect = styled.div`
  display: flex;
  position: relative;
  height: 40px;
  align-items: center;
`

const UploadTabDropdownSelectLabel = styled.label`
  color: #000;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 500;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
`

const StyledDropdownArrowSvg = styled(DropdownArrowSvg)`
  margin-left: 8px;
`

const UploadTabDropdownModal = styled.div`
  position: absolute;
  width: 160px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  background: #FFF;
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.15);
  top: 43px;
  left: -8px;
  z-index: 1;
  box-sizing: border-box;
  padding: 2px 0;
`

const UploadTabDropdownModalItem = styled.div`
  display: flex;
  padding: 0 16px;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  cursor: pointer;

  &:hover {
    background: #f9f9f9;
  }
`

const UploadTabDropdownModalItemText = styled.p`
  color: #000;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
`

const UploadTabDropdownModalItemCount = styled.p`
  color: ${({ $isFailed }) => $isFailed ? '#F73C3C' : '#A2A2AA'};
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
`

const StyledWarningSvg = styled.div`
  position: absolute;
  top: 0px;
  right: 22px;
  width: 6px;
  height: 6px;
  background-color: #F73C3C;
  border-radius: 50%;
`