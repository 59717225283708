import React from "react";
import styled from "styled-components";
import { hiDPI } from "polished";

import { __ } from "../../lib/translate";
import { detectIsMobile } from "../../lib/utils";
import { URLSITE } from "@pcloud/web-utilities/dist/config";

type Props = {
  smalltitle: string,
  bigtitle: string,
  titlecolor: string,
  btntext: string,
  btntextcolor: string,
  btnlink: string,
  btnbackground: string,
  backgroundcolor: string,
  img: string,
  img2x: string,
  imgSize: string,
};

const BackupPromoBox = ({
  smalltitle=__("backup_promo_title", "GET 2 TB STORAGE WITH"),
  bigtitle=__("backup_promo_discount", "%value% DISCOUNT", { value: 33 }),
  titlecolor="333333",
  btntext=__("get_now", "GET NOW"),
  btntextcolor="ffffff",
  btnlink=(URLSITE + "cloud-storage-pricing-plans.html"),
  btnbackground="17bed0",
  backgroundcolor="f3fdfe",
  img=require("../../../root/img/backup/flash-sale.png"),
  img2x=require("../../../root/img/backup/flash-sale@2x.png"),
  imgSize="107x136"
} : Props) => {
  const imgsrc = window.devicePixelRatio > 1 ? img2x : img;
  const imgWidth = imgSize.split("x")[0];
  const imgHeight = imgSize.split("x")[1];
  const isMobileDevice = detectIsMobile();

  const onMobileClick = () => {
    if (isMobileDevice) {
      location.href = btnlink;
    }
  }

  return (
    <Wrapper bgcolor={backgroundcolor} onClick={onMobileClick}>
      <Banner src={imgsrc} width={imgWidth} height={imgHeight} />
      <TextWrapper>
        <Text color={titlecolor} size="small">{smalltitle}</Text>
        <Text color={titlecolor} size="big">{bigtitle}</Text>
      </TextWrapper>
      {
        isMobileDevice ? (
          <ArrowWrapper>
            <Arrow href={btnlink} bgcolor={btnbackground}/>
          </ArrowWrapper>
        ) : (
          <Button
            href={btnlink}
            color={btntextcolor}
            bgcolor={btnbackground}
          >
            {btntext}
          </Button>
        )
      }
    </Wrapper>
  )
}

export default BackupPromoBox;

const Wrapper = styled.div`
  width: calc(50% - 10px);
  max-height: 330px;
  box-shadow: 0 0 1px 0 rgba(23, 190, 208, 0.6), 3.5px 3.5px 10px 0 rgba(0, 0, 0, 0.04);
  background-color: ${({bgcolor}) => bgcolor ? "#" + bgcolor : "#17bed0"};
  padding-bottom: 30px;
  box-sizing: border-box;
  text-align: center;
  border-radius: 10.5px;
  position: relative;

  @media(max-width: 768px) {
    width: 100%;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.03);
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 0px;
    padding: 20px;
  }
`;

const Banner = styled.img`
  margin-bottom: 24px;

  @media(max-width: 768px) {
    margin-bottom: 0px;
    position: relative;
    top: -20px;
  }
`;

const TextWrapper = styled.div`
  margin-bottom: 29px;

  @media(max-width: 768px) {
    margin: 0px 16px
  }
`;

const Text = styled.div`
  font-family: "Roboto", sans-serif;
  font-size: ${({size}) => size === "small" ? "20px" : "29px"};
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.11;
  letter-spacing: -0.27px;
  text-align: center;
  color: ${({color}) => color ? "#" + color : "#333333"};

  @media(max-width: 768px) {
    font-size: ${({size}) => size === "small" ? "16px" : "26px"};
  }
`;

const Button = styled.a`
  display: inline-block;
  min-width: 193px;
  text-decoration: none;
  box-sizing: border-box;
  padding: 8px 17px;
  border-radius: 22px;
  box-shadow: 0px 2px 0 0 rgba(0, 0, 0, 0.05);
  background-color: ${({background}) => background ? "#" + background : "#17bed0"};
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: center;
  color: ${({ color }) => (color ? "#" + color : "#fff")};
  cursor: pointer;
`;

const Arrow = styled.a`
  display: inline-block;
  width: 12px;
  height: 12px;
  border-top: 2px solid #17bed0;
  border-right: 2px solid #17bed0;
  transform: rotate(45deg);
`

const ArrowWrapper = styled.div`
  
`;
