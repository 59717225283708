// @flow

import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";

import { __ } from "../../lib/translate";
import { getDefaultFolderNamePicker } from "../../lib/utils";
import { errorKeys } from "../../lib/errors";
import createFolder from "../../api/createfolder";
import IconCreateFolder from "../../../root/img/svg/create-folder-picker-new.svg";
import InputText from "../InputText";

const inputstyle = {
  flex: "1",
  height: "15px",
  lineHeight: "15px",
  border: "0",
  outline: "none",
  cursor: "pinter"
};

type Props = {
  currentFolder: any,
  shouldDisableCreateFolder: boolean,
  onCreateFolderSuccess: number => void
};

const CreateNewFolderInput = ({
  currentFolder = { folderid: 0, contents: null },
  shouldDisableCreateFolder = true,
  onCreateFolderSuccess = () => {}
}: Props) => {
  const [newFolderName, setNewFolderName] = useState("");
  const [showCreateFolderInput, setShowCreateFolderInput] = useState(false);
  const wrapperRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = event => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowCreateFolderInput(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      console.log("removeEventListener mousedown");
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  useEffect(() => {
    const { folderid } = currentFolder;
    const id = parseInt(folderid);
    const newName = getDefaultFolderNamePicker(currentFolder);

    setNewFolderName(newName);
  }, [currentFolder, showCreateFolderInput]);

  const onKeyUpNewFolderInput = (e: any) => {
    e.stopPropagation();
    if (e.keyCode === 13) {
      onCreateFolderClick();
    }
  };

  const onIconClick = () => {
    if (!shouldDisableCreateFolder) {
      return;
    }

    setShowCreateFolderInput(!showCreateFolderInput)
  };

  const onCreateFolderClick = () => {
    if (!shouldDisableCreateFolder) {
      // HFN.message(
      //   __("error_create_new_folder", "You can't create folders in this folder."),
      //   "error"
      // );
      return;
    }

    if (newFolderName.trim().length < 1) {
      HFN.message(__("Folder name is required."), "error");
      return;
    }

    const regex = new RegExp('[><"/]+');
    if (regex.test(newFolderName)) {
      HFN.message(__("share_folder_modal_error_folder_name_special_chars"), "error");
      return;
    }

    if (showCreateFolderInput) {
      createFolder(
        { parent: currentFolder, folderName: newFolderName },
        {
          isSaveToCloud: true,
          shouldOpenAfter: false,
          onSuccess: ({ metadata }) => {
            const { folderid } = metadata;
            onCreateFolderSuccess(folderid);
            setShowCreateFolderInput(false);
          },
          onError: ({ result, error }) => {
            if (errorKeys[result]) {
              HFN.message(__(errorKeys[result]), "error");
            } else {
              HFN.message(__("something_went_wrong_refresh_and_try_again"), "error");
              throw new Error(error);
            }
          }
        }
      );
    } else {
      setShowCreateFolderInput(true);
    }
  };

  return (
    <WrapperCreateFolder ref={wrapperRef} key="create-folder">
      <FolderIcon disabled={!shouldDisableCreateFolder} onClick={onIconClick} />
      {showCreateFolderInput && shouldDisableCreateFolder ? (
        <WrapperInput disabled={!shouldDisableCreateFolder}>
          <InputText
            style={inputstyle}
            name="folderName"
            placeholder={__("Folder name")}
            value={newFolderName}
            onChange={e => setNewFolderName(e.target.value)}
            onKeyUp={onKeyUpNewFolderInput}
            autoFocus
          />
          <CreateButton disabled={!shouldDisableCreateFolder} onClick={onCreateFolderClick}>
            {__("Create")}
          </CreateButton>
        </WrapperInput>
      ) : (
        <WrapperInput>
          <TextCreateFodler
            disabled={!shouldDisableCreateFolder}
            onClick={() => setShowCreateFolderInput(true)}
          >
            {__("create_new_folder", "Create new folder")}
          </TextCreateFodler>
          <CreateButton
            hasOpacity
            disabled={!shouldDisableCreateFolder}
            onClick={onCreateFolderClick}
          >
            {__("Create")}
          </CreateButton>
        </WrapperInput>
      )}
    </WrapperCreateFolder>
  );
};

export default CreateNewFolderInput;

const WrapperCreateFolder = styled.div`
  display: flex;
  border-top: 1px solid #eee;
  padding: 12px;
  align-items: center;
  height: 40px;
  box-sizing: border-box;
`;
const FolderIcon = styled(IconCreateFolder)`
  width: 26px;
  height: 24px;
  cursor: ${props => (props.disabled ? "initial" : "pointer")};
  opacity: ${props => (props.disabled ? "0.5" : "1")};
`;

const WrapperInput = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  opacity: ${props => (props.disabled ? "0.5" : "1")};
`;

const TextCreateFodler = styled.div`
  text-align: left;
  flex: 1;
  font-size: 15px;
  line-height: 15px;
  font-weight: normal;
  margin-left: 13px;
  opacity: ${props => (props.disabled ? "0.5" : "1")};
  cursor: ${props => (props.disabled ? "initial" : "pointer")};
`;

const CreateButton = styled.div`
  opacity: ${props => (props.hasOpacity ? "0.5" : "1")};
  color: ${props => (props.disabled ? "#8e8e95" : "#17bed0")};
  cursor: ${props => (props.disabled || props.hasOpacity ? "initial" : "pointer")};
`;
