// @flow

import React, { useRef, useEffect } from "react";
import { List } from "immutable";
import styled from "styled-components";

import Item from "./Item";
import { __ } from "../../lib/translate";
import { getIconSrc } from "../../lib/utils";
import { parseItem } from "./utils";
import { MAX_FILES_SHOW } from "@pcloud/web-utilities/dist/config/constants";

type ItemType = {
  itemId: string,
  iconId: number,
  name: string,
  isfolder: boolean
};

type ItemsListProps = {
  createdFolderId: string,
  isFolderSelectionOnly: boolean,
  selectedItemId: number,
  contents: List<any>,
  onItemClick: () => void,
  onItemDoubleClick: () => void,
  getRef: () => void
};

const ItemsList = ({
  createdFolderId = "",
  isFolderSelectionOnly = false,
  selectedItemId = "0",
  contents = List(),
  onItemClick = () => {},
  onItemDoubleClick = () => {},
  getRef = () => {}
}: ItemsListProps) => {
  let filesCounter = 0;
  const itemRef = useRef(null);

  useEffect(() => {
    getRef(itemRef);
  }, []);

  const renderRow = (item: ItemType, index: number) => {
    const { itemId, iconId, name, isfolder, encrypted } = item;
    const iconSrc = getIconSrc(item);
    const itemName = encrypted ? HFN.metaName(item) : name;
    if (!isfolder) {
      filesCounter++;
    }

    if (filesCounter == MAX_FILES_SHOW) {
      return <NumberOfFilesLeft>{`${contents.size - index} ${__("files_more", "file(s) more")}`}</NumberOfFilesLeft>;
    } else if (filesCounter > MAX_FILES_SHOW) {
      return null;
    }

    return (
      <React.Fragment key={itemId}>
        {createdFolderId == itemId ? <div ref={itemRef} /> : null}
        <Item
          iconSrc={iconSrc}
          key={itemId}
          itemId={itemId}
          iconId={iconId}
          name={itemName}
          isFolderSelectionOnly={isFolderSelectionOnly}
          disableSelection={!Perm.canCreate(item)}
          isfolder={isfolder}
          isSelected={selectedItemId === itemId}
          onItemClick={onItemClick}
          onItemDoubleClick={onItemDoubleClick}
        />
      </React.Fragment>
    );
  };

  const renderItems = () => {
    return contents.map((item, index) => renderRow(parseItem(item), index));
  };

  return (
    <Table>
      {contents.size === 0 ? (
        <EmptyFolder>
          {__("folder_is_empty", "This folder is empty.")}
        </EmptyFolder>
      ) : (
        renderItems()
      )}
    </Table>
  );
};

export default ItemsList;

const Table = styled.div`
  width: 100%;
  font-family: "Roboto", sans-serif;
`;

export const EmptyFolder = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 13px;
  color: #ababab;
`;

export const NumberOfFilesLeft = styled.div`
  display: flex;
  align-items: center;
  padding: 0 10px;
  background: #fff;
  box-sizing: border-box;
  height: 30px;
  opacity: 0.5;
`;
