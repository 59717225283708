import React from "react";

import styled from "styled-components";
import { Range, getTrackBackground } from "react-range";

type Props = {
  minValue: number,
  maxValue: number,
  value: [number],
  step?: number,
  disabled?: boolean,
  showLabel?: boolean,
  formatLabel?: (value: number) => string,
  onRangeValueChange?: (value) => void,
};
const RangeSlider = ({
  minValue,
  maxValue,
  value,
  step = 100,
  disabled = false,
  showLabel = false,
  formatLabel = (value) => value,
  onRangeValueChange = () => {}
}: Props) => {
  return (
    <Range 
      min={minValue}
      max={maxValue}
      disabled={disabled}
      step={step}
      values={[value]}
      onChange={(values) => onRangeValueChange(values[0])}
      renderTrack={({ props, children }) => (
        <RangeTrackWrapper onMouseDown={props.onMouseDown} onTouchStart={props.onTouchStart} disabled={disabled}>
          <RangeTrack
            ref={props.ref}
            style={{
              background: getTrackBackground({
                values: [value],
                colors: ["#17bed0", "#cccccc"],
                min: minValue,
                max: maxValue
              })
            }}
          >
            {children}
          </RangeTrack>
        </RangeTrackWrapper>
      )}
      renderThumb={({ props }) => (
        <RangeSliderThumbWrapper {...props} key={props.key}>
          <RangeSliderThumb disabled={disabled}/>
          {showLabel ? <RangeSliderLabel>{formatLabel(value)}</RangeSliderLabel> : null}
        </RangeSliderThumbWrapper>
      )}
    />
  );
};

export default RangeSlider;

const RangeTrackWrapper = styled.div`
  cursor: ${props => (props.disabled ? "initial" : "pointer")};
  height: 1rem;
  display: flex;
  width: 100%;
  min-height: 24px;
`;

const RangeTrack = styled.div`
  height: 0.3rem;
  width: 100%;
  border-radius: 0.3rem;
  align-self: center;
`;

const RangeSliderThumbWrapper = styled.div`
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RangeSliderThumb = styled.div`
  background: ${props => (props.disabled ? "#cccccc" : "#17bed0")};
  border: 1px solid ${props => (props.disabled ? "#cccccc" : "#17bed0")};
  border-radius: 100%;
  height: 16px;
  width: 16px;
  outline: none;
  box-shadow: ${props => (props.disabled ? "none" : "0 2px 8px 0 rgba(0, 0, 0, 0.16)")};
  transition: transform 0.3s ease-out;

  &:active {
    transform: ${props => (props.disabled ? "none" : "scale(1.3)")};
  }
`;

const RangeSliderLabel = styled.div`
  position: absolute;
  top: -27px;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: bold;
  white-space: nowrap;
  pointer-events: none;/* Prevents slider to be draggable */
`;