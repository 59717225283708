// @flow
import React from "react";
import styled from "styled-components";

type Props = {
  footerText: string,
  onFooterClick: () => void
}

export const FooterRegistration = ({ footerText, onFooterClick }: Props) => {

  return (<FooterButton onClick={onFooterClick}>{footerText}</FooterButton>)
}


const FooterButton = styled.div`
  color: #17bed0;
  font-size: 13px;
  text-decoration: underline;
  cursor: pointer;
  text-align: center;
  margin: 10px 0 10px 0;
`;

