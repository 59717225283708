import React from 'react';
import ActionButton from "../../../userinterface/ActionButton";
import {FlexWrapper} from "../../../userinterface/styles";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose} from '@fortawesome/pro-solid-svg-icons/faClose';
import {faChevronUp} from '@fortawesome/pro-solid-svg-icons/faChevronUp';

const MinimizedBoxControls = ({
    onPlayerClose = () => {},
    onPlayerMaximize = () => {},
 }) => {

    return (
        <FlexWrapper
            $columnGap={0}
            className={'animate'}
        >
            <ActionButton
                onClick={onPlayerMaximize}
                className={'svg-color-default'}
                label={<FontAwesomeIcon style={{fontSize: 16}} icon={faChevronUp} />}
            />
            <ActionButton
                onClick={onPlayerClose}
                className={'svg-color-default'}
                label={<FontAwesomeIcon style={{fontSize: 16}} icon={faClose} />}
            />
        </FlexWrapper>
    );
}

export default MinimizedBoxControls;