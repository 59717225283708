// @flow
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Menu, MenuItem, MenuButton } from "@szhsin/react-menu";

import ArrowDownIcon from "../../root/img/dlink/breadcrumb/arrow-down.svg";
import FolderBreadcrumbIcon from "../../root/img/dlink/breadcrumb/folder-breadcrumb.svg";
import FolderHomeIcon from "../../root/img/dlink/breadcrumb/folder-home.svg";
import FolderDropdownIcon from "../../root/img/dlink/breadcrumb/folder-dropdown.svg";

const BreadcrumbDropdown = ({ styleComponent = {}, folders = [] }) => {

  const onFolderClick = (id) => {
    let params = {
      folder: id
    };

    if ($.bbq.getState('tpl')) {
      params.tpl = $.bbq.getState('tpl');
    }

    $.bbq.pushState(params, 2);
  };

  const renderItem = ({ id, name }, index) => {
    return (
      <MenuItem key={id} onClick={() => { onFolderClick(id) }}>
        {index === 0 ? <FolderHomeIcon /> : <FolderDropdownIcon />}
        {name}
      </MenuItem>
    );
  };

  const currentFolder = folders.slice(-1)[0];

  return (
    <Wrapper style={styleComponent}>
      <Menu
        align="start"
        position="initial"
        menuButton={
          <div className="breadcrumb-button">
            <FolderBreadcrumbIcon className="folder-icon" />
            <span>{currentFolder.name}</span>
            <ArrowDownIcon className="arrow-down" />
          </div>}
        transition
      >
        {folders.map((folder, index) => renderItem(folder, index))}
      </Menu>
    </Wrapper>
  );
};

export default BreadcrumbDropdown;

const Wrapper = styled.div`
  display: block;
  padding: 0 0 15px 10px;

  .breadcrumb-button {
    font-size: 15px;
    display: flex;
    line-height: 21px;
  }

  .breadcrumb-button span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: calc(100% - 65px);
  }

  svg.folder-icon {
    vertical-align: middle;
    margin-right: 10px;
  }

  svg.arrow-down {
    vertical-align: middle;
    margin-left: 7px;
    margin-top: 3px;
  }

  .szh-menu {
    padding: 5px 0;
    margin-top: 10px;
    width: 300px;
    border-radius: 9px;
    box-shadow: 2px 4px 15px 0 rgba(0, 0, 0, 0.15);
    background-color: #fff;
    font-weight: normal;
    max-height: 40vh;
    overflow-y: auto;
  }

  .szh-menu__item {
    padding: 0 20px;
    height: 48px;
    line-height: 48px;
    font-family: Roboto;
    font-size: 15px !important;
    color: #17bed0 !important;
    font-weight: normal !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }

  .szh-menu__item:last-child {
    color: #000000 !important;
  }

  .szh-menu__item::before {
    content: ' ';
    border-left: 1px solid #e4e4e4;
    position: absolute;
    left: 35px;
    height: 100%;
  }

  .szh-menu__item:first-child::before {
    top: 30px;
  }

  .szh-menu__item:last-child::before {
    bottom: 30px;
  }

  .szh-menu__item svg {
    margin-right: 20px;
    top: -2px;
    position: relative;
    background: #fff;
    padding: 7px 0;
    vertical-align: middle;
  }

  .szh-menu__item--hover {
    background-color: #f8f7fe;
    color: #000 !important;
  }

`;
