import { __ } from "../../lib/translate";

export const STATUS_TEXTS = {
  inactive: "-",
  unavailable: "-",
  running: __("Running"),
  stopped: __("Stopped"),
  aborted: __("Aborted"),
  finished: __("Finished"),
  paused: __("Running"),
};

const ERROR_REASONS = {
  100: ["Internal error. Try again later."], //'Colud not connect to pCloud with the user' / Translation: "Internal error. Try again later."
  101: ["Internal error. Try again later."], //'Colud not list folder.' / Translation: "Internal error. Try again later."
  102: ["Internal error. Try again later."], //'Runtime error occured.' / Translation: "Internal error. Try again later."
  103: ["Internal error. Try again later."], //'Invalid type.' / Translation: "Internal error. Try again later."
  104: ["Internal error. Try again later."], //'Master shut down.' / Translation: "Internal error. Try again later."
  105: ["service_connection_timeout", "Service connection timeout. Please, try again later."], //'Timeout'
  106: ["backup_storage_exceeded", "Free storage exceeded."], //'User over quota'
  107: ["backup_service_unavailable", "Service temporarily unavailable. Please, try again later."], //'Upload error'
  200: ["backup_account_disconnected", "Account disconnected."], //'Could not find Facebook access token'
  201: ["backup_data_not_returned", "Expected data not returned."], //'Could not connect to Facebook with the access token'
  202: ["backup_account_disconnected", "Account disconnected."], //'Could not find Instagram access token'
  203: ["backup_data_not_returned", "Expected data not returned."], //'Could not connect to Instagram with the access token
  204: ["backup_account_disconnected", "Account disconnected."], //'Could not find Picasa access token'
  205: ["backup_data_not_returned", "Expected data not returned."], //'Could not connect to Picasa with the access token
  206: ["backup_account_disconnected", "Account disconnected."], //'Could not find Dropbox access token'
  207: ["backup_data_not_returned", "Expected data not returned."], //'Could not connect to Dropbox with the access token
  208: ["backup_account_disconnected", "Account disconnected."], //'Could not find Onedrive access token'
  209: ["backup_data_not_returned", "Expected data not returned."], //'Could not connect to Onedrive with the access token
  210: ["backup_account_disconnected", "Account disconnected."], //'Could not find Copy access token'
  211: ["backup_data_not_returned", "Expected data not returned."], //'Could not connect to Copy with the access token
  212: ["backup_account_disconnected", "Account disconnected."], //'Could not find Google Drive access token'
  213: ["backup_data_not_returned", "Expected data not returned."], //'Could not connect to Google Drive with the access token
  214: ["backup_account_disconnected", "Account disconnected."], //'Could not find Google Photos access token'
  215: ["backup_data_not_returned", "Expected data not returned."], //'Could not connect to Google Photos with the access token
  216: ["backup_google_photos_unavailable", "Google Photos service unavailable."], //'External Google Photos API error.'
  217: ["backup_google_photos_missing_permissions", "Unable to back up Google Photos due to missing access permissions."], //'Google Photos - Request had insufficient authentication scopes'
  218: ["backup_dropbox_not_verified", "Your Dropbox account is not verified."], //'Dropbox - please, verify your email first'
  219: ["backup_google_drive_not_responding", "Google Drive is not responding."], //‘External Google Drive API error.’
  220: ["backup_onedrive_not_responding", "OneDrive is not responding."], //‘External Microsoft API error.’
  221: ["backup_dropbox_not_responding", "Dropbox is not responding."], //‘External Dropbox API error.’
  222: ["backup_facebook_not_responding", "Facebook is not responding."], //‘External Facebook API error.’
  223: ["backup_instagram_not_responding", "Instagram is not responding."], //‘External Instagram API error.’
  224: ["backup_canceled_limit_reached", "Backup canceled. Account exceeds limit of 1 million items."], //‘Did not start backup, unable to back up more than 1 million files and folders.’
  225: ["backup_interrupted_limit_reached", "Backup interrupted. Unable to back up more than 1 million items."], //'Partial backup, unable to back up more than 1 million files and folders.'
}

export const getErrorReason = (reasonCode) => {
  const translationData = ERROR_REASONS[reasonCode];
  if (!translationData) {
    return __("Internal error. Try again later.");
  }
  return __(translationData[0], translationData[1]);
}
