// @flow
import { __ } from './translate';
import { htmlentities } from "./utils";
import { emailInitials } from "./user-utils";
import type { UserContacts } from "../types/user";

type Data = any;

export const isBusinessShare = (data: Data) => {
  return !!data.fromuserid;
};

export const isRequest = (data: Data, userId: number) => {
  return "sharerequestid" in data;
};

export const isBusinessNotManaged = (data: Data, userId: number) => {
  const { fromuserid, ownerid, owneruserid, folderowneruserid } = data;

  return (
    (fromuserid && fromuserid != userId && ownerid != userId) ||
    (owneruserid && owneruserid != userId && folderowneruserid != userId)
  );
};

export const canChange = (data: Data, userId: number) => {
  return !isRequest(data, userId) && !isBusinessNotManaged(data, userId);
};

export const calcPermissions = (data: Data) => {
  const pp = {
    canread: 0,
    cancreate: 1,
    canmodify: 2,
    candelete: 4,
    canmanage: 8
  };
  let perm = 0;

  if (data.permissions) {
    data = data.permissions;
  }
  for (let n in pp) {
    if (data[n]) {
      perm += pp[n];
    }
  }
  if (perm & 8) {
    perm = 15;
  } else if (perm > 0) {
    perm = 7;
  }

  return perm;
};

export const buildBusinessUsername = (data: Data) => {
  return data.firstname + " " + data.lastname;
};

export const getEntityIconSrc = (data: Data) => {
  console.log("getEntityIcon");

  if (!isBusinessShare(data)) {
    return "/img/share-mail.png";
  } else {
    getBusinessAvatarSrc(data);
  }
};

export const getBusinessAvatarSrc = (data: Data) => {
  if (data.teamobj) {
    return "/img/b/team.png";
  } else if (data.userobj && data.userobj.avatar) {
    return HFN.prepUrl(data.userobj.avatar);
  }
};

export const getEntityName = (data: any, accountId: number, userId: number, sshort?: boolean) => {
  const { teamobj, tomail, userobj } = data;

  if (!isBusinessShare(data)) {
    return htmlentities(
      HFN.strFitMail(tomail, 40) +
        (isRequest(data, userId) && !sshort ? " (" + __("pending") + ")" : "")
    );
  } else if (teamobj) {
    return htmlentities(HFN.strFit(teamobj.name, 40));
  } else if (userobj) {
    var ret = htmlentities(HFN.strFit(buildBusinessUsername(userobj), 40));
    if (userobj.id == accountId) ret += " (<span>" + __("me") + "</span>)";
    return ret;
  }
};

export const getUserInitials = (mail: string) => {
  return emailInitials(mail);
};

export const getBusinessEntityName = (data: Data) => {
  const { teamobj, userobj } = data;

  if (teamobj) {
    return HFN.strFit(teamobj.name, 40);
  } else if (userobj) {
    return HFN.strFit(buildBusinessUsername(userobj), 40);
  }
};

export const geyRowKey = (data: Data, userId: number) => {
  return isRequest(data, userId) ? data.sharerequestid : data.shareid;
};

export const getSharedContacts = (
  contacts: UserContacts,
  busers: Array<any>,
  bteams: Array<any>,
  onlyBusiness: boolean
) => {
  let userContacts = [];
  const filterdUserContacts = HFN.filterMailContacts(contacts);

  if (!onlyBusiness) {
    userContacts.push(...filterdUserContacts);
  }

  if (HFN.config.isBusiness()) {
    const activeBUsersContacts = busers
      .filter(user => user.active || user.frozen)
      .map(user => {
        return {
          name: user.firstname + " " + user.lastname,
          value: user.id,
          source: 1001, // business user
          userobj: user
        };
      });
    
    const activeBTeamsContacts = bteams
      .filter(team => team.active)
      .map(team => {
        let teamContact = {
          name: team.name,
          value: team.id,
          source: 1002, // business team
          teamobj: team
        };
        if (team.id === 0) {
          teamContact.membersCnt = activeBUsersContacts.length;
        }

        return teamContact;
      });

    const bUserEmails = activeBUsersContacts.map(contact => contact.userobj.email);
    for (var n = 0; n < bUserEmails.length; ++n) {
      for (var i = 0; i < filterdUserContacts.length; ++i) {
        if (filterdUserContacts[i].value == bUserEmails[n]) {
          filterdUserContacts.splice(i, 1);
          break;
        }
      }
    }

    userContacts.push(...activeBUsersContacts, ...activeBTeamsContacts);
  }

  console.log("CONTACTS>>>>>>>>>>>>userContacts", userContacts);
  return userContacts;
};
