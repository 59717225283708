// @flow
import React, { Component } from "react";
import styled from "styled-components";

import { __ } from "../lib/translate";

import Signin from "../containers/UserAuthentication/Signin";
import Signup from "../containers/UserAuthentication/Signup";
import LogopCloudIcon from "../../root/img/svg/pcloud-logo.svg";
import ConnectAppIcon from "../../root/img/svg/connect-app.svg";

type Props = {
  applicationName: string,
  application: any,
  response_type: any,
  redirect_uri: any,
  request_id: any,
  state: any,
  icon: any,
  onLoginSuccess: any
};
type State = { activeTab: number };

class AuthorizeOAuth2 extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      activeTab: 1
    };

    (this: any).toggleActiveTab = this.toggleActiveTab.bind(this);
  }

  toggleActiveTab(activeTab: number) {
    this.setState({ activeTab });
  }

  render() {
    const { applicationName, icon, onLoginSuccess } = this.props;
    const { activeTab } = this.state;

    return (
      <Wrapper>
        <Header>Connect {applicationName} to your account</Header>
        <Subheader>
          <b>{applicationName}</b> wants to have access to your pCloud account.
        </Subheader>
        <IconsWrapper>
          <Icon src={"//" + icon.hosts[0] + icon.path} />
          <ConnectAppIcon />
          <LogopCloudIcon />
        </IconsWrapper>

        <FormsWapper>
          <TabsHolder>
            <Tab
              className={activeTab === 1 ? "active_tab" : ""}
              onClick={() => this.toggleActiveTab(1)}
            >
              {__("vivacom_login", "Sign in")}
            </Tab>
            <Tab
              className={activeTab === 2 ? "active_tab" : ""}
              onClick={() => this.toggleActiveTab(2)}
            >
              {__("vivacom_register", "Register")}
            </Tab>
            <ActiveLine tab={activeTab} />
          </TabsHolder>
          <FormContent>
            {activeTab === 1 ? (
              <Signin showTitle={false} onSuccess={onLoginSuccess} />
            ) : (
              <Signup showTitle={false} onSuccess={onLoginSuccess} />
            )}
          </FormContent>
        </FormsWapper>
      </Wrapper>
    );
  }
}

export default AuthorizeOAuth2;

const Wrapper = styled.div`
  display: flex;
  margin-top: 20px;
  text-align: center;
  font-family: "Open sans", sans-serif;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span:after {
    left: 3px;
    height: 8px;
    width: 4px;
  }

  .form input.text {
    border-radius: 5px !important;
    border: solid 1px #a1a1a1;
    background-color: #fff;
  }

  .butt {
    font-size: 15px;
    font-family: "Roboto", sans-serif !important;
  }
`;

const Header = styled.div`
  margin-top: 20px;
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
`;

const Icon = styled.img`
  display: inline-block;
  width: 64px;
  height: 64px;
`;

const Subheader = styled.div`
  font-size: 19px;
  font-weight: normal;
  font-stretch: normal;
  margin-bottom: 20px;
  margin-top: 5px;
`;

const IconsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 360px;
  padding: 30px;
  border-radius: 45px;
  border: solid 1px rgba(161, 161, 161, 0.01);
  background-color: #f8f8f8;
  align-items: center;
}
`;

const TabsHolder = styled.div`
  border-bottom: 1px solid #eee;
  max-width: 360px;
  margin: 0 auto;
  margin-bottom: 15px;
  position: relative;
`;

const Tab = styled.div`
  width: 50%;
  display: inline-block;
  padding: 0px 0px 10px 0px;
  text-align: center;
  font-size: 15px;
  font-family: "Roboto", sans-serif;
  color: #222;
  cursor: pointer;
  transition: color 0.25s ease-in-out;
  &.active_tab {
    color: #17bed0;
    cursor: default;
  }
  @media (max-width: 768px) {
    padding: 0px 0px 10px 0px;
  }
`;

const ActiveLine = styled.div`
  width: 50%;
  position: absolute;
  bottom: -1px;
  height: 2px;
  background: #17bed0;
  transition: left 0.25s ease-in-out;
  ${props => (props.tab === 1 ? "left: 0%" : "left: 50%")};
`;

const FormWrap = styled.div`
  max-width: 340px;
  margin: 0 auto;
`;

const FormContent = styled.div`
  width: 100%;
  margin-bottom: 30px;

  & input {
    border-radius: 0px !important;
  }
`;

const FormsWapper = styled.div`
  width: 100%;
  margin-top: 30px;
`;
