//@flow

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styled, { keyframes } from "styled-components";
import { hiDPI } from "polished";

import {
  CardNumberComponent,
  ExpiryDateComponent,
  HolderNameComponent,
  CVCComponent
} from "./CreditCardInputs";

import {
  formatExpDatetoMMYY,
  preventEnteringChars,
  preventSymbolsOnCCHolderName,
  findCardType,
  getExpiryDatePeriod,
  validateExpiryDate,
  loadScript
} from "../../../web-shared/utils";

import { insertSafechargeForm } from "../../../web-shared/insertSafechargeForm";
import { SubscriptionUpdate } from "../../lib/SubscriptionUpdate";
import { __ } from "../../lib/translate";
import { rcookie, isInternetExplorer } from "../../lib/utils";
import { DEFAULT_CARDNUMBER_LENGTH } from "@pcloud/web-utilities/dist/config/constants";
import CreditCardFormTemplate from "../../containers/PaymentForm/CreditCardFormTemplate"

import SubmitButton from "../SubmitButton";

type ChangePaymentContainerProps = {
  color: string,
  provider: string,
  onClose: () => void,
  updateCreditCardInfo: (newCC: {
    lastDigits: string,
    expiryDate: string,
    cardType: string
  }) => void,
};

const ChangePaymentContainer = ({
  color = "cyan",
  provider,
  onClose = () => {},
  updateCreditCardInfo
}: ChangePaymentContainerProps) => {
  const [cardNumber, setCardNumber] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [holderName, setHolderName] = useState("");
  const [cvc, setCVC] = useState("");
  const [cardType, setCardType] = useState("default");

  const [validCardNumber, setValidCardNumber] = useState(true);
  const [validExpiryDate, setValidExpiryDate] = useState(true);
  const [validHolderName, setValidHolderName] = useState(true);
  const [validCVC, setValidCVC] = useState(true);

  const [showLoader, setShowLoader] = useState(false);
  const [cardNumberLength, setCardNumberLength] = useState(DEFAULT_CARDNUMBER_LENGTH);
  const [messageInfo, setMessageInfo] = useState({
    error: false,
    message: "",
    showMessage: false
  });
  const [safechargeFormInserted, setSafechargeFormInserted] = useState(false);
  const isIE = isInternetExplorer();

  const userAuth = useSelector(({ user }) => user.userinfo.auth);

  useEffect(() => {
    if (provider === "safecharge" && !safechargeFormInserted && !isIE) {
      insertSafechargeForm({ language: rcookie("lang") || "en" });
      setSafechargeFormInserted(true);
      loadScript("https://cdn.safecharge.com/safecharge_resources/v1/websdk/safecharge.js", () => {});
    }
    if (provider === "stripe") {
      loadScript("https://js.stripe.com/v2/", () => {});
      loadScript("https://js.stripe.com/v3/", () => {});
    }
  })

  const showPaymentError = (message: string) => {
    setMessageInfo({
      error: true,
      message: message,
      showMessage: true
    });
  };

  const handleCardNumberChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const value = event.target.value;
    let newCardType = findCardType(value);

    if (value.length <= 4 && value.length > 0) {
      newCardType = findCardType(value);
    } else if (value.length == 0) {
      setCardType("default");
      setCardNumberLength(16);
    }

    if (newCardType && cardType != newCardType.name) {
      setCardType(newCardType.name);
      setCardNumberLength(newCardType.valid_length[0]);
    }

    if ((value.length - cardNumber.length) <= 1) {
      setCardNumber(value);
    }
  };

  const handleExpDateChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setExpiryDate(value);
  };

  const handleCVCChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setCVC(value);
  };

  const handleHolderNameChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const value = event.target.value.toUpperCase();
    setHolderName(value);
  };

  const handleCardNumberKeyPress = (event: SyntheticInputEvent<HTMLInputElement>) => {
    preventEnteringChars(event);
    formatCardNumber(event);
  };

  const handleCVCKeyPress = (event: SyntheticInputEvent<HTMLInputElement>) => {
    preventEnteringChars(event);
  };

  const handleHolderNameKeyPress = (event: SyntheticInputEvent<HTMLInputElement>) => {
    preventSymbolsOnCCHolderName(event);
  };

  const formatCardNumberOnPaste = (event: SyntheticInputEvent<HTMLInputElement>) => {
    let paste = (event.clipboardData || window.clipboardData).getData('text');
    let formattedNumberSubs = [];
    paste = paste.replace(/\D/g,'');

    if (paste) {
      let newCardType = findCardType(paste);
      let validCardLength;

      if (newCardType) {
        setCardType(newCardType.name);
        validCardLength = newCardType.valid_length[0];
        paste = paste.slice(0, validCardLength);

        if (newCardType.name === "amex") {
          formattedNumberSubs.push(paste.slice(0, 4))
          formattedNumberSubs.push(paste.slice(4, 10))
          formattedNumberSubs.push(paste.slice(10))
        } else {
          formattedNumberSubs = paste.match(/.{1,4}/g);
        }
      } else {
        setCardType("default");
        setCardNumberLength(16);
        formattedNumberSubs = paste.match(/.{1,4}/g);
      }

      let formattedNumber = formattedNumberSubs.join(" ");

      setCardNumber(formattedNumber);
    }
  };

  const toggleLoader = (action: "show" | "hide") => {
    setShowLoader(action === "show");
  };

  const showBadInput = (inputName: "card_number" | "exp_date" | "cvc") => {
    if (inputName === "card_number") {
      setValidCardNumber(false);
    }
    if (inputName == "exp_date") {
      setValidExpiryDate(false);
    }
    if (inputName == "cvc") {
      setValidCVC(false);
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    setMessageInfo({
      error: false,
      message: "",
      showMessage: false
    });
    validateNewCard(() => {
      toggleLoader("show");
      const newCC = provider === "safecharge" ? {
        cardHolderName: holderName,
      } : {
        cardNumber: cardNumber.replace(/\s/g, ''),
        cardHolderName: holderName,
        expirationMonth: getExpiryDatePeriod(expiryDate, "month"),
        expirationYear: getExpiryDatePeriod(expiryDate, "year"),
        CVV: cvc
      }

      const params = {
        toggleLoader,
        showPaymentError,
        newCC,
        showBadInput,
        userAuth
      };

      let paymentMethods = new SubscriptionUpdate(params);
      const sucsessCallback = () => {
        onClose();
        updateCreditCardInfo();
      };
      if (provider === "stripe") {
        paymentMethods.setStripePaymentSource({
          refresh: false,
          callback: sucsessCallback
        });
      }
      if (provider === "safecharge") {
        paymentMethods.initSafechargePayment({
          refresh: false,
          callback: sucsessCallback,
          changeCard: true
        });
      }
      if (provider === "paypal") {
        paymentMethods.createBraintreePayment({ refresh: false });
      }
    });
  };

  const validateNewCard = (callback: () => void) => {
    let validExpDateTemp = false;
    let validCardNumberTemp = false;
    let validHolderNameTemp = false;
    let validCVCTemp = false;
    let validInputs = false;
    let filledInputs = false;

    if (provider === "stripe") {
      validCardNumberTemp = !(!cardNumber || cardNumber.length < 12)
      validHolderNameTemp = !(!holderName || holderName.length < 3);
      validCVCTemp = !(!cvc || cvc.length < 3);
      validExpDateTemp = validateExpiryDate(expiryDate);
      filledInputs = cardNumber && expiryDate && holderName && cvc;
    } else if (provider === "safecharge") {
      validExpDateTemp = !document.getElementById("card-expiry").classList.contains('empty');
      validCardNumberTemp = !document.getElementById("card-number").classList.contains('empty');
      validHolderNameTemp = !(!holderName || holderName.length < 3);
      validCVCTemp = !document.getElementById("card-cvc").classList.contains('empty');
      filledInputs = validCardNumberTemp && validExpDateTemp && holderName && validCVCTemp;
    }

    setValidCardNumber(validCardNumberTemp);
    setValidHolderName(validHolderNameTemp);
    setValidCVC(validCVCTemp);
    setValidExpiryDate(validExpDateTemp);

    validInputs = validExpDateTemp && validCardNumberTemp && validHolderNameTemp && validCVCTemp;

    console.log("validInputs", validInputs, validExpiryDate, validCardNumberTemp, validHolderNameTemp, validCVCTemp);
    
    if (validInputs && filledInputs && callback && typeof callback == "function") {
      callback();
    }
  };

  const formatExpDate = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const code = event.keyCode;
    const allowedKeys = [8];

    if (allowedKeys.indexOf(code) !== -1) {
      event.preventDefault();
      return;
    }

    let value = event.target.value;

    setExpiryDate(formatExpDatetoMMYY(value));
  };

  const formatCardNumber = (event: SyntheticInputEvent<HTMLInputElement>) => {
    let value = event.target.value.trim();
    let formatedValue = value.replace(/\s+/g, "");

    if (formatedValue.length > cardNumberLength - 1) {
      event.preventDefault();
      return;
    }

    if (cardType === "amex" && formatedValue) {
      if (formatedValue.length === 4 || formatedValue.length === 10) {
        value += " ";
        setCardNumber(value);
      }
    } else if (formatedValue && formatedValue.length % 4 === 0) {
      value += " ";
      setCardNumber(value);
    }
  };

  return (
    <Container>
      <Header>
        <FormTitle>{__("change_your_credit_card", "Change your credit card")}</FormTitle>
      </Header>
      {
        (isIE) ? (
          <div>            
            {__(
              "ie_safercharge_unsupported_change",
              "It's not possible to change your card from Internet Explorer. Please use another browser."
            )}
          </div>
        ) : (
          <CreditCardFormTemplate
            color={color}
            cardNumber={cardNumber}
            cardType={cardType}
            expDate={expiryDate}
            CVC={cvc}
            holderName={holderName}
            validCardNumber={validCardNumber}
            validExpDate={validExpiryDate}
            validCVC={validCVC}
            validHolderName={validHolderName}
            errorMessage={messageInfo.message}
            paymentLoading={showLoader}
            changeCard={false}
            handleCardNumberChange={handleCardNumberChange}
            handleCardNumberKeyPress={handleCardNumberKeyPress}
            handleCardNumberPaste={formatCardNumberOnPaste}
            handleExpDateChange={handleExpDateChange}
            handleExpDateKeyUp={formatExpDate}
            handleCVCChange={handleCVCChange}
            handleCVCKeyPress={handleCVCKeyPress}
            handleHolderNameChange={handleHolderNameChange}
            handleHolderNameKeyPress={handleHolderNameKeyPress}
            handleSubmit={handleSubmit}
            handleCardChange={()=>{}}
            shouldRenderInputs={provider !== "safecharge"}
            buttonText={__("apply_short")}
            showSecurePaymentText={false}
          />
        )}

    </Container>
  );
};

const Container = styled.div`
    z-index: 100;
    width: 440px;
    color: #222;
    font-family: "Roboto", sans-serif;
    margin: 0 auto;

  @media (max-width: 660px) {
    width: 100%;
  }
`;

const Header = styled.div`
  background-color: #fff;
  padding: 12px;
  position: relative;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
`;

const FormTitle = styled.div`
  font-weight: 700;
  text-align: center;
  font-size: 19px;
`;

const CloseButton = styled.div`
  width: 12px;
  height: 12px;
  margin: 10px;
  background-image: url(${require("../../../root/img/changepaymentmethod/close.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/changepaymentmethod/close@2x.png")});
  }
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: inline-block;
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
`;

const Form = styled.form`
  clear: both;
  padding: 12px 26px;
  box-sizing: border-box;
  background-color: #fafafa;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  @media (max-width: 800px) {
    padding: 12px;
  }
`;

const ApplyButton = styled.div`
  margin: 16px 0px 20px;
  & .submitbut {
    margin: 0 auto;
    width: 140px !important;
    text-transform: uppercase;
    font-size: 14px;
    text-align: center;
    font-weight: 600;
    left: 50%;
    margin-left: -70px;
  }
`;

const ErrorMessage = styled.div`
  margin-top: 4px;
  position: absolute;
  font-weight: 400;
  font-size: 12px;
  color: ${props => (props.isError ? "#C2313A" : "green")};
  font-family: "Roboto", sans-serif;
`;

export default ChangePaymentContainer;
