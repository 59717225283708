// @flow

import React from "react";
import styled from "styled-components";

import Button, { ButtonCentered } from "./ButtonDefault";

import InvoiceModal from "./InvoiceModal";
import { TDiv, TSpan } from "./intl";
import { __ } from "../lib/translate";

import CountryCodeToName from "../lib/countryUtils";

const keyNameToLabel = {
  billingAddress: "Billing Address:",
  vat: "VAT:",
  company: "Company:",
  names: "Names:",
  country: "Country:",
  type: "Type",
};

const keyNameToKeyTranslate = {
  billingAddress: "ph_billing_address",
  vat: "ph_vat",
  company: "Company",
  names: "ph_names",
  country: "ph_country",
  type: "type",
};

type BillingInfoParams = {
  billingaddress: string,
  vat?: string,
  country: string,
  company?: ?string,
  names?: ?string,
  type: number,
};

type BillingInfo = {
  billingAddress: string,
  vat?: string,
  country: string,
  company?: ?string,
  names?: ?string,
  type: number,
};

type Props = {
  isBusiness?: boolean,
  billingInfo: BillingInfo,
  onApiSuccessCallback: (onApiSuccessCallback: BillingInfoParams) => void,
};

const InvoiceInfo = ({ isBusiness = false, billingInfo = {}, onApiSuccessCallback = () => {}, ...rest }: Props) => {
  const hasBillingInfo = Object.keys(billingInfo).length;
  console.log("Billing Info ", billingInfo);

  const renderInvoiceModal = () => {
    let buttonName = "";

    if (isBusiness) {
      buttonName = hasBillingInfo ? __("business_invoice_update_details", "Update details") : __("business_invoice_add_details", "Add details");
    } else {
      buttonName = hasBillingInfo ? __("Update My Invoice Information", "Update") : __("insert_invoice_info", "Add");
    }

    return (
      <InvoiceModal
        isBusiness={isBusiness}
        cta={buttonName}
        billingInfo={billingInfo}
        onApiSuccessCallback={onApiSuccessCallback}
        {...rest}
      />
    );
  };

  const renderTable = () => {
    return Object.keys(billingInfo)
      .sort()
      .filter((item) => item !== "type")
      .map((keyName, keyIndex) => (
        <Row key={keyIndex}>
          <Col className="row-heading">
            <TSpan id={keyNameToKeyTranslate[keyName]}>{keyNameToLabel[keyName]}</TSpan>
          </Col>
          {<Col>{keyName == "country" ? CountryCodeToName[billingInfo[keyName]] : billingInfo[keyName]}</Col>}
        </Row>
      ));
  };

  if (isBusiness) {
    return (
      <React.Fragment>
        <TitleBusiness id="ph_invoice_info">Invoice Details:</TitleBusiness>
        <ContainerBusiness>
          {hasBillingInfo ? (
            renderTable()
          ) : (
            <EmptyTable>
              <EmptyTableMessage>
                {__("ph_billing_note", "Please add your billing details, in order to get an invoice for your payment.")}
              </EmptyTableMessage>
            </EmptyTable>
          )}
          <Wrapper>
            <InfoMessage>{__("BA_pcloud_invoice")}</InfoMessage>
            {renderInvoiceModal()}
          </Wrapper>
        </ContainerBusiness>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Title id="ph_invoice_info" className="row-title">Invoice Details:</Title>
      <Container>
        {hasBillingInfo ? (
          renderTable()
        ) : (
          <Notification id="ph_billing_note">
            Please add your billing details, in order to get an invoice for your payment.
          </Notification>
        )}
      </Container>
      {renderInvoiceModal()}
    </React.Fragment>
  );
};

export default InvoiceInfo;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 10px 15px 10px;
`;

const Notification = styled(TDiv)`
  text-align: center;
  padding: 15px 0;
  font-size: 13px;
  font-weight: 400;
  line-height: 21px;
  border-radius: 5px;
  color: #999;
`;

const Title = styled(TDiv)`
  font-size: 15px;
  font-weight: bold;
  height: 31px;
  border-bottom: 2px solid #ddd;
  padding-top: 17px;
  padding-left: 2px;
  margin: 10px 0 0 0;
`;

const TitleBusiness = styled(TDiv)`
  width: 780px;
  height: 27px;
  background: #f0f0f0;
  box-sizing: border-box;
  border-top: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
  font-family: "Roboto", sans-serif;
  font-size: 11px;
  font-weight: bold;
  color: #aeaeae;
  padding-left: 10px;
  text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.5);
  zoom: 1;
  line-height: 26px;
`;

const Container = styled.ul`
  margin: 0;
  padding: 0;
  background: #fafafa;
`;

const ContainerBusiness = styled(Container)`
  background: #fff;
`;

const Row = styled.li`
  font-size: 12px;
  line-height: 33px;
  border-bottom: 1px solid #e9e9e9;
  list-style-type: none;

  &.header-history {
    font-weight: 700;
    padding: 0;
    background: #f4f4f4;
  }
  &.billing-history {
    padding: 10px 0;
  }
  &.billing-history div,
  &.header-history div {
    width: 25%;
  }
`;

const Col = styled.div`
  width: 33.33%;
  display: inline-block;
  font-size: 12px;
  line-height: 33px;
  box-sizing: border-box;

  &.row-heading {
    font-weight: 700;
    padding: 0 0 0 10px;
  }
  &.row-heading:first-letter {
    text-transform: uppercase;
  }

  &.first-child {
    padding: 0 0 0 10px;
  }
`;

const InfoMessage = styled.div`
  font-size: 12px;
  color: #999;
  flex: 1;
`;

const EmptyTable = styled.div`
  padding: 0 10px;
`;

const EmptyTableMessage = styled.div`
  flex: 1;
  text-align: left;
  font-size: 13px;
  font-weight: bold;
  line-height: 21px;
  border-radius: 5px;
  color: #000;
  margin-top: 15px;
  width: 80%;
`;
