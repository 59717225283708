// @flow
import React, { PureComponent } from "react";
import { findDOMNode } from "react-dom";
import styled, { keyframes } from "styled-components";
import { hiDPI } from "polished";

import { __ } from "../../lib/translate";
import { Loader } from "../../containers/TwoFactorAuth/SharedComponents";
import Dropdown, { DIR_RIGHT, DIR_DOWN } from "../../components/Dropdown";
import { IMAGE_TYPE } from "@pcloud/web-utilities/dist/config/constants";

type Props = {
  imageSrc: string,
  isEditable: boolean,
  onImageUpload: (IMAGE_TYPE.LOGO | IMAGE_TYPE.COVER, File, () => void) => void,
  onImageRemove: (IMAGE_TYPE.LOGO | IMAGE_TYPE.COVER) => void
};

type State = { uploadedImage: boolean, isLoading: boolean };

class BrandingLogo extends PureComponent<Props, State> {
  static defaultProps = {
    imageSrc: "",
    isEditable: true,
    onImageUpload: () => {}
  };

  constructor(props: Props) {
    super(props);

    (this: any).triggerImageUpload = this.triggerImageUpload.bind(this);
    (this: any).onUpload = this.onUpload.bind(this);
    (this: any).discardImage = this.discardImage.bind(this);
    (this: any).getDropDownList = this.getDropDownList.bind(this);
    (this: any).onItemDropdownClick = this.onItemDropdownClick.bind(this);

    (this: any).fileInput = null;

    this.state = { uploadedImage: false, isLoading: false };
  }

  getDropDownList() {
    return [
      {
        title: __("branding_delete_title_image", "Delete title image"),
        href: false,
        extra: { id: "delete" }
      },
      {
        title: __("branding_update_title_image", "Upload new title image"),
        href: false,
        extra: { id: "upload" }
      }
    ];
  }

  onItemDropdownClick(item) {
    const {
      extra: { id }
    } = item;

    if (id === "delete") {
      this.discardImage();
    } else if (id === "upload") {
      this.triggerImageUpload();
    } else {
      console.log("unhandled", item);
    }
  }

  triggerImageUpload() {
    const fileInput: any = findDOMNode((this: any).fileInput);
    fileInput.click();

    this.setState({ isLoading: true });
  }

  onUpload() {
    const { onImageUpload } = this.props;
    const fileInput: any = findDOMNode((this: any).fileInput);
    const file = fileInput.files[0];

    onImageUpload(IMAGE_TYPE.LOGO, file, () => {
      this.setState({ isLoading: false });
    });
  }

  discardImage() {
    const { onImageRemove } = this.props;

    onImageRemove(IMAGE_TYPE.LOGO);
  }

  render() {
    const { imageSrc, isEditable } = this.props;
    const { uploadedImage } = this.state;

    return (
      <React.Fragment>
        <UploadImageWrapper>
          <FileInput
            name={IMAGE_TYPE.LOGO}
            type="file"
            onChange={this.onUpload}
            onClick={e => (e.target.value = "")}
            ref={ref => ((this: any).fileInput = ref)}
            accept={".png,.jpg,.jpeg, image/*"}
          />
          {uploadedImage || imageSrc ? (
            <ImagePreviewWrapper>
              <ImagePreview src={imageSrc} />
              {isEditable ? (
                <UpdateImageWrapper>
                  <Dropdown
                    overwriteTip={{ left: "40px" }}
                    direction={DIR_DOWN}
                    childDirection={DIR_DOWN}
                    list={this.getDropDownList()}
                    onSelect={this.onItemDropdownClick}
                  >
                    <UpdateImage>
                      <CameraIconUpdate />
                      <ImageText color="#fff" fontWeight="none">
                        {__("branding_update_image")}
                      </ImageText>
                    </UpdateImage>
                  </Dropdown>
                </UpdateImageWrapper>
              ) : null}
            </ImagePreviewWrapper>
          ) : isEditable ? (
            <UploadImage onClick={this.triggerImageUpload}>
              <CameraIcon />
              <ImageText>
                {__("general_branding_image_title", "Add a title image")}
              </ImageText>
              <Recommended>{__("general_branding_image_restrictions", "300 x 300 px recommended")}</Recommended>
            </UploadImage>
          ) : null}
        </UploadImageWrapper>
      </React.Fragment>
    );
  }
}

export default BrandingLogo;

// Title image
const UploadImageWrapper = styled.div`
  text-align: center;
  margin-bottom: 13px;
  position: relative;
`;

const UploadImage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  width: 140px;
  height: 140px;
  margin: 10px auto;
  padding: 30px 20px;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 2.9px;
  box-shadow: 0px 4px 7px 0 rgba(0, 0, 0, 0.03), 0 0 1px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
`;

const CameraIcon = styled.div`
  width: 20px;
  height: 18px;
  margin-bottom: 10px;

  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url(${require("../../../root/img/branding-settings/add-photo.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/branding-settings/add-photo@2x.png")});
  }
`;

const CameraIconUpdate = styled(CameraIcon)`
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url(${require("../../../root/img/branding-settings/edit-photo.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/branding-settings/edit-photo@2x.png")});
  }
`;

const ImageText = styled.div`
  width: 102px;
  height: 13px;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  color: ${props => props.fontWeight || "bold"};
  font-weight: bold;
  line-height: 1.23;
  letter-spacing: -0.2px;
  text-align: center;
  color: #000000;
  color: ${props => props.color || "#000"};
`;

const Recommended = styled.div`
  width: 68px;
  height: 21px;
  font-family: "Roboto", sans-serif;
  font-size: 11px;
  line-height: 1.18;
  letter-spacing: -0.2px;
  text-align: center;
  color: #000000;
  margin-top: 18px;
`;

const ImagePreviewWrapper = styled.div`
  display: inline-block;
  margin: 10px auto;
  position: relative;
  max-width: 590px;
  max-height: 146px;
  overflow: hidden;
  border: 3px solid #fff;
  border-radius: 3px;
  box-shadow: 0px 4px 7px 0 rgba(0, 0, 0, 0.03);
  background-color: #fff;
  box-sizing: border-box;
`;

const ImagePreview = styled.img`
  display: inline-block;
  max-width: 542px;
  max-height: 140px;
`;
const UpdateImage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
`;

const UpdateImageWrapper = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  height: 50%;
  bottom: 0;
  right: 0;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.6);
  justify-content: center;
  align-items: center;
`;

const FileInput = styled.input`
  display: none;
`;

const LoaderImage = styled(Loader)``;

const LoaderWrapper = styled(UploadImage)``;
