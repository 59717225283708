import React from 'react';

import Header from "./Header";
import MainPlayerLayout from "./MainPlayerLayout";
import Queue from "./Queue";
import ColoredImageBackground from "../../userinterface/ColoredImageBackground";
import usePlayerContext from "../../hooks/usePlayerContext";
import {DivWrapper, MainContainer} from "../../userinterface/styles";
import PublicLinks from "../../userinterface/PublicLinks";
import PlaylistAndVolumeControl from "./OverlayControl";
import {AnimatePresence} from "framer-motion";

const PlayerBoxUI = () => {

    const {
        queue,
        isPublic,
        playerVariant,
        additionalClass,
        controlsWithOverlay,
        isSingleSongPlayer
    } = usePlayerContext();

    const showPublicLinks = isPublic && isSingleSongPlayer;
    const showHeader = !playerVariant.isMinimize && !playerVariant.isFullPage;
    const showMainContainer = !controlsWithOverlay.showSavePlaylistPanel && !controlsWithOverlay.showLoadPlaylistPanel;
    const showQueue = !playerVariant.isMiniPlayer && !playerVariant.isMinimize;

    return (
        <>
            <ColoredImageBackground>
                {showHeader && <Header />}
                <MainContainer id={'mainContainer'} className={additionalClass}>
                    {(showMainContainer) && (
                        <DivWrapper className={additionalClass + ' main-layout'}>
                            <MainPlayerLayout />
                            {showPublicLinks && <PublicLinks/>}
                        </DivWrapper>
                    )}
                </MainContainer>
                <AnimatePresence>
                    {showQueue && <Queue />}
                </AnimatePresence>
            </ColoredImageBackground>
            <PlaylistAndVolumeControl />
        </>
    );
}

export default PlayerBoxUI;