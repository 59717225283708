// @flow

import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled, { keyframes } from "styled-components";

import { __ } from "../../lib/translate";
import { detectIsMobile } from "../../lib/utils";
import errors from "../../lib/errors";
import apiMethod from "../../api/apiMethod";
import diffs from "../../lib/diffs";
import { CHANGE_DATA_REGION_STATUS } from "@pcloud/web-utilities/dist/config/constants";
import { currencyPrices } from "../../../web-shared/prices";
import { currencyMap, PAYMENT_DISRUPTION_COUNTRIES } from "../../../web-shared/constants";

import PaymentView from "../PaymentForm/PaymentView";
import { Container } from "./SharedComponents";
import WarningIcon from "../../../web-shared/images/svg/warning.svg";

type Props = {
  currency: string,
  onSuccess: () => void,
  onClose: () => void,
  country: string
};

const PaymentStep = ({
  currency,
  onSuccess = () => {},
  onClose = () => {},
  loading = false,
  setLoading = () => {},
  country
}: Props) => {
  const [cart, setCart] = useState(null);
  const [paymentProvider, setPaymentProvider] = useState("safecharge");
  const [showIframe, setShowIframe] = useState(false);
  const auth = useSelector(({ user }) => user.token);
  const pass = useSelector(({ user }) => user.userinfo.password);

  const dispatch = useDispatch();

  const analytics = {
    category: "relocation",
    label: "relocation"
  };
  const onResizeFunctions = {};
  const currencySign = currencyMap[currency];
  const relocationPlanId = 112;
  console.log("currencySign", currencyPrices[relocationPlanId][currencySign].onetime);

  const open3DsecureIframe = data => {
    var cont = HFN.renderTemplate("#sf_modal");

    var iframeParams = {
      'id': '3d_secure_iframe', 
      'name': '3d_secure_iframe', 
      'src': ''
    };
    
    cont.find(".modal_holder").css("padding-top", "0px");

    if (!detectIsMobile()) {
      iframeParams['height'] = $(window).height() - 132;

      if (!onResizeFunctions['3d_secure_iframe_resize']) {
        onResizeFunctions['3d_secure_iframe_resize'] = function() {
          $('#3d_secure_iframe').css({'height': $(window).height() - 132});
        };
      }
    }

    $('#3d_secure_iframe, #send-sf').remove();
    var cont = HFN.renderTemplate("#sf_modal");
    Popup.open(cont, { });

    cont.find(".close").on("click", function() {
      Popup.close();
    });

    // Append Iframe to the modal
    cont.find('.content').append($('<iframe>', iframeParams));

    // Append Form and target previously added Iframe
    $('.safecharge_wrap .inner').append($('<form>', {'id': 'send-sf', 'action': (data.redirecturl || data.url), 'method': 'post', 'target': '3d_secure_iframe'}));

    // Append needed inputs in the Form
    $('#send-sf').append($('<input />', {type: 'hidden', name: 'PaReq', value: data.redirectdata || data.data.PaReq}));
    $('#send-sf').append($('<input />', {type: 'hidden', name: 'TermUrl', value: data.termurl || data.data.TermUrl}));
    
    if (data.data &&  data.data.MD) {
      $('#send-sf').append($('<input />', {type: 'hidden', name: 'MD', value: data.data.MD}));
    }

    $('#send-sf').submit();

    $('#3d_secure_iframe').off('load').on('load', function() {
      setTimeout(function() {
        $('#3d_secure_iframe').fadeIn();
      }, 500);
    });
  };

  const on3DsecureError = () => {
    $('.modal.sf_modal').find('#sf_iframe').css({'padding-top': 0, 'opacity': 0});
    $('.modal.sf_modal').find('.close').hide();
    closeModal();
    $('#sf_iframe').remove();
  }

  const onPaymentSuccess = () => {
    if (!pass) {
      throw new Error("Password missing!");
    }

    // TODO: wait for usermodify diff and then call relocation/request
    setTimeout(
      () => {
        apiMethod(
          "relocation/request",
          {
            password: pass
          },
          res => {
            dispatch({
              type: "USER_UPDATE_RELOCATION_STATUS",
              relocationStatus: CHANGE_DATA_REGION_STATUS["IN_QUEUE"]
            });
            console.log(res);
            setLoading(false);
            onSuccess();
          },
          {
            errorCallback: ({ result, error }) => {
              onClose();
              setLoading(false);
              let errorMessage = "";

              if (errors[result]) {
                errorMessage = errors[result];
              } else {
                errorMessage = __("something_went_wrong_refresh_and_try_again");
              }

              if (errors[result]) {
                HFN.message(errorMessage, "error");
              } else {
                throw new Error(error);
              }
            }
          },
        );
      },
      5000
    );
  };

  return (
    <PaymentContainer style={{ padding: "0" }} key="payment">
      {
        loading ? (
          <LoaderContainer key="payment-loader">
            <Loader />
            <LoaderText>
              {__("processing_payment", "Processing your payment, just a second...")}
            </LoaderText>
          </LoaderContainer>
        ) : null
      }
      {PAYMENT_DISRUPTION_COUNTRIES.indexOf(country) !== -1 ? (
        <PaymentDisruptionMessage>
          <WarningIcon width="44px" height="44px"/>
          <div>{__("payment_disruption")}</div>
        </PaymentDisruptionMessage> 
      ) : (
        <PaymentView
          planId={[relocationPlanId]}
          billingPeriod="onetime"
          color="cyan"
          currency={currency}
          country={country}
          showSuccessMessage={onPaymentSuccess}
          showLoading={setLoading}
          open3DsecureIframe={open3DsecureIframe}
          on3DsecureError={on3DsecureError}
          cart={cart}
          setCart={setCart}
          paymentProvider={paymentProvider}
          setPaymentProvider={setPaymentProvider}
          analytics={analytics}
          price={currencyPrices[relocationPlanId][currencySign].onetime}
          showTabs
        />
      )}
    </PaymentContainer>
  );
};

export default PaymentStep;

const PaymentContainer = styled(Container)`
  min-height: 420px;
`;

const PaymentMethodTitle = styled.div`
  font-family: "Roboto", sans-serif;
  font-size: 19px;
  font-weight: 500;
  padding-top: 40px;
`;

const Pulsate = keyframes`
  0% {transform: scale(0.1, 0.1); opacity: 0;}
  50% {opacity: 1;}
  100% {
    -webkit-backface-visibility: hidden;
    -webkit-transform: translateZ(0) scale(1.2, 1.2);
    transform: translateZ(0);
    transform: scale(1.0, 1.0);
    opacity: 0;
    }
`;

const Loader = styled.div`
  width: 50px;
  height: 50px;
  border: 4px solid #20bed6;
  border-radius: 30px;
  animation: ${Pulsate} 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
`;

const LoaderText = styled.div`
  font-family: "Roboto", sans-serif;
  font-size: 19px;
  font-weight: 500;
  margin-top: 35px;
`;

const LoaderContainer = styled.div`
  min-height: 420px;
  height: 100%;
  border-radius: 15px;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 640px;
  text-align: center;
  font-family: "Roboto", sans-serif;
  border-radius: 15px;
  box-shadow: 0px 10px 20px 0 rgba(0, 0, 0, 0.3);
  background-color: #ffffff;
  padding: 40px;
  box-sizing: border-box;
  position: absolute;
  /* height: 100%; */
  z-index: 2000;
`;

const PaypalContainer = styled.div`
  display: none;
`;

const PaymentDisruptionMessage = styled.div`
  padding: 40px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  min-height: 420px;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  line-height: 1.4;

  svg {
    margin-bottom: 20px;
  }
`;
