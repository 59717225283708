import React, {useEffect} from 'react';
import usePlayerContext from "../hooks/usePlayerContext";
import {useAnalyzerCheck} from "../helpers";
import {detectSafari} from "../../../lib";

const AudioTag = () => {
    const {
        isPublic,
        data,
        audioRef,
        currentSong,
        setIsReady,
        setDuration,
        setIsPlaying,
        volume,
        isPlaying,
        analyzerData,
        setAnalyzerData,
        onNext
    } = usePlayerContext();

    useEffect(() => {
        if (!analyzerData && isPlaying) {
            audioAnalyzer();
        }
    }, [analyzerData, isPlaying]);


    const useAnalyzer = useAnalyzerCheck(currentSong, isPublic, data);

    const audioAnalyzer = () => {
        const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
        const analyzer = audioCtx.createAnalyser();
        analyzer.fftSize = 2048;

        const bufferLength = analyzer.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);

        if (useAnalyzer) {
            const source = audioCtx.createMediaElementSource(audioRef.current);
            source.connect(analyzer);
            source.connect(audioCtx.destination);
            source.onended = () => {
                source.disconnect();
            };
        }

        setAnalyzerData({ analyzer, bufferLength, dataArray });
    };

    if (
        !currentSong ||
        !currentSong.src
    ) {
        return null;
    }

    const typesFormMP3 = ['audio/amr', 'audio/x-ms-wma', 'audio/x-aiff']

    if (!detectSafari()) {
        typesFormMP3.push('audio/mp4');
    }

    return (
        <audio
            ref={audioRef}
            crossOrigin={useAnalyzer ? "anonymous" : null}
            preload="metadata"
            onCanPlay={(e) => {
                e.currentTarget.volume = volume;
                setIsReady(true);
            }}
            onDurationChange={(e) => {
                setDuration(currentSong && !currentSong.disableDuration ? e.currentTarget.duration : 0)
            }}
            onPlaying={() => {
                setIsPlaying(true);
            }}
            onPause={() => setIsPlaying(false)}
            onEnded={(e) => {
                onNext(e, true);
            }}
        >
            <source
                id={"PlayerSource"}
                type={
                    typesFormMP3.includes(currentSong.contenttype) ?
                        'audio/mpeg' : currentSong.contenttype
                }
                src={currentSong.src}
            />
        </audio>
    );
}

export default AudioTag;