import $ from "jquery";
import browser from './browser';

var MobilePopup = {
    cache: {},
    config: {
        ajax: false,
        hasClose: false,
        closeBtnSelector: false,
        className: "",
        fadeSpeed: 0,
        overlay: false,
        innerlay: "",
        cssPosition: 'fixed',
        onEscClose: true,
        clickClose: true
    },
    instanceConfig: {},
    loaded: null,
    wrapper: null,

    init: function () {
        if (this.loaded && this.wrapper)
            return;
            
        this.wrapper =
            $('<div>')
            .addClass('g-modal g-modal-mobile')
            .append(
                    $('<div class="g-modal-inner"></div>')
                    .append( '<div class="g-modal-content"></div>' )
            )
            .appendTo(document.body)
            .hide();

        $('<a>', { 'href': 'javascript: void(0);', 'class': 'g-modal-close' }).click(function () { MobilePopup.close() }).appendTo(this.wrapper);
        this.loaded = true;
    },

    resize: function () {
        let m_h = $(window).height();
        let m_w = $(window).width();

        let m_h2 = $('.g-modal div').first().height();
        let m_h3 = $('.body').height();

        if (m_h2 > m_h) {
            m_h = m_h2;
        }
        if (m_h3 > m_h) {
            m_h = m_h3;
        }

        this.wrapper.css({
            height: m_h,
            width: m_w
        }, function () { MobilePopup.setPosition(); } );
    },

    setTitle: function (title) {
        this.wrapper.find('.g-modal-title').text(title);
    },
    open: function(cont, config) {
        this.init();

        config = this.instanceConfig = $.extend({}, this.config, config);

        this.wrapper.find('.g-modal-inner .g-modal-title').remove();
        if (config.title) {
            if(config.dont_translate_title != undefined && config.dont_translate_title == true) {
                this.wrapper.find('.g-modal-inner').prepend( $('<div class="g-modal-title"></div>').append( $('<div class="g-modal-title cwrap2"></div>').text(config.title) ));
            }else {
                this.wrapper.find('.g-modal-inner').prepend( $('<div class="g-modal-title"></div>').append( $('<div class="g-modal-title cwrap2"></div>').text(i18n.get(config.title)) ));
            }
        }
        if (config.className)
            this.wrapper.addClass(config.className);
        if (config.overlay)
            Overlay.show(config.fadeSpeed, config.clickClose ? function() { MobilePopup.close(); } : function () {}, config.clickClose);

        if (config.onEscClose) {
            $(document).off('keyup.popup').on('keyup.popup', function(e) {
                if (e.keyCode === 27)
                    MobilePopup.close();
            });
        }
        $('.g-modal .g-modal-close').show();
        if (config.hasClose)
            $('.g-modal-close').show();
        else
            $('.g-modal-close').hide();

        if (config.ajax) {

            this.contents('');
            this.loading();

            if (this.cache[cont]) {
                this.show(Popup.cache[cont]);
            } else {
                $.get(cont, {}, function (data) {
                    MobilePopup.cache[cont] = data;
                    MobilePopup.show(data);
                });
            }
        } else if (typeof cont == "string" && (cont.substring(0, 1) == '#' || cont.substring(0, 1) == '.'))
            this.show($(cont).clone().css('display', ''), config.fadeSpeed);
        else
            this.show(cont, config.fadeSpeed);
        setTimeout(function(){
            $('.megawrap').hide();
            MobilePopup.resize();
        },200);

        $(window).on('resize.resizemobpop', function() {
            MobilePopup.resize();
        });
    },

    show: function (cont, fadeSpeed) {
        this.contents(cont);

        this.resize();

        if (typeof this.instanceConfig.setPosition == 'function')
            this.instanceConfig.setPosition();
        else
            this.setPosition();

        if (this.instanceConfig.closeBtnSelector) {
            $(this.instanceConfig.closeBtnSelector).click(function(){MobilePopup.close();});
        }

        this.wrapper.fadeIn(0);
    },

    setPosition: function () {

        var size = [this.wrapper.outerWidth(), this.wrapper.outerHeight()]

        this.wrapper.css({
            top: 0,
            height:this.wrapper.outerHeight(),
            left: 0,
            position: 'absolute'
        });
    },

    animateTo: function (width, height) {
        var self = this;
        this.wrapper.css({
            width: width,
            height: height,
            marginLeft: -1 * (width / 2) + 'px',
            position: 'fixed'
        });
    },

    prepAnimate: function () {
        this.wrapper.css({ width: this.wrapper.width(), height: this.wrapper.height() });
    },

    hideClose: function () {
        this.wrapper.find('.g-modal-close').hide();
    },
    showClose: function () {
        this.wrapper.find('.g-modal-close').show();
    },

    hideModalCont: function () {
        this.wrapper.find('.g-modal-inner').hide();
    },

    showModalCont: function () {
        this.wrapper.find('.g-modal-inner').show();
    },

    startLoading: function (opts) {

        this.stopLoading(true);
        opts = $.extend({}, {

            processExplain: i18n.get('Loading ...'),
            processStopText: i18n.get('Stop'),
            stopCallback: false,
            fast: true
        }, opts);

        this.prepAnimate();
        this.hideModalCont();
        this.hideClose();
        this.wrapper.append( '<div class="processing"><div class="information"></div></div>' );

        if (opts.stopCallback) {
            this.wrapper.find('.information').text(opts.processExplain)
            .after(
                    $('<div class="stopprocess"></div>')
                    .append(
                            $('<div class="button smallbut stopbut"></div>')
                            .append('<img src="/ZCDNURL/img/stop-process.png" width="9" height="9"> ' + opts.processStopText)
                            .on('click', opts.stopCallback)
                    )
            )
        }

        var ml = this.wrapper.find('.processing'), size = [ml.outerWidth(), ml.outerHeight()], self = this;
        console.log(ml, size);

        if (opts.now) {
            this.wrapper.css({
                width: size[0],
                height: [size[1]],
                marginLeft: -1 * size[0] / 2
            });
            ml.show();
        } else {
            this.wrapper.css({
                width: size[0],
                height: [size[1]],
                marginLeft: -1 * size[0] / 2
            }, 450, function () {
                ml.show();
            });
        }
    },

    stopLoading: function (clearOnly) {

        this.wrapper.stop(true, true);
        this.wrapper.find('.processing').remove();

        if (clearOnly) {
            this.wrapper.css({
                width: '',
                height: ''
            });
            this.wrapper.find('.g-modal-inner').show();
            this.showClose();
            return this;
        }

        var mcnt = this.wrapper.find('.g-modal-inner'), size = [mcnt.outerWidth(), mcnt.outerHeight()], self = this;

        if (size[0] && size[1]) {
            this.wrapper.css({
                width: size[0],
                height: [size[1]],
                marginLeft: -1 * size[0] / 2
            });
            mcnt.show();
            self.showClose();
        } else {
            this.wrapper.css({
                width: '',
                height: ''
            });
            this.showClose();
        }

        return this;
    },

    contents: function (cont) {
        if (!cont)
            this.wrapper.find('.g-modal-content').empty();
        this.wrapper.find('.g-modal-content').html(cont);
    },

    close: function () {
        $('.megawrap').show();
        if (this.wrapper == null)
            return;

        if (this.instanceConfig && this.instanceConfig.closeCallback) {
            this.instanceConfig.closeCallback();
        }
        this.stopLoading(true);
        this.wrapper.css({ width: '', height: '' });
        this.wrapper.find('.g-modal-content').empty();
        this.wrapper.find('.g-modal-title').remove();
        this.wrapper.hide();
        this.instanceConfig = {};
        $(document).off('keyup.popup');
        $(window).off('resize.resizemobpop');
        Overlay.hide();
    },

    setOpt: function (key, val) {
        this.instanceConfig[key] = val;
    }
}

export default MobilePopup;