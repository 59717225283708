//@ flow
import React, { PureComponent } from "react";
import styled from "styled-components";
import RangeSlider from "./RangeSlider";

type Props = {
  minValue: number,
  maxValue: number,
  step?: number,
  startValue: number,
  minStartValue: number,
  disabled?: boolean,
  getSliderValue: string => void,
  formatLabel?: number => string
};

type State = { value: number };

class Slider extends PureComponent<Props, State> {
  static defaultProps = {
    startValue: 0,
    getSliderValue: () => {},
    formatLabel: value => ""
  };

  constructor(props: Props) {
    super(props);

    this.state = { value: 0 };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const { startValue, getSliderValue } = this.props;

    this.setState({ value: startValue });
    getSliderValue(startValue);
  }

  componentDidUpdate(prevProps) {
    const { startValue, getSliderValue, maxValue } = this.props;

    if (startValue !== prevProps.startValue || maxValue !== prevProps.maxValue) {
      this.setState({ value: startValue });
      getSliderValue(startValue);
    }
  }

  handleChange(value) {
    const {
      getSliderValue,
      maxValue,
      minStartValue,
      step
    } = this.props;
    let currentValue = 0;

    if (value >= minStartValue) {
      currentValue = value;
    } else {
      currentValue = minStartValue;
    }

    // Protect going above partial step below the max value.
    if (value > (maxValue - (maxValue % step))) {
      currentValue = (maxValue - (maxValue % step));
    }

    this.setState({ value: currentValue });
    getSliderValue(currentValue);
  }

  render() {
    const { minValue, maxValue, step, disabled, formatLabel } = this.props;
    const { value } = this.state;

    return (
      <Container disabled={disabled}>
        <RangeSlider 
          minValue={minValue}
          maxValue={maxValue}
          value={value}
          step={step}
          disabled={disabled}
          showLabel={formatLabel ? true : false}
          formatLabel={formatLabel}
          onRangeValueChange={this.handleChange}
        />
      </Container>
    );
  }
}

export default Slider;

const Container = styled.div`
  padding: 16px 20px;
`;
