// @flow

import React, { PureComponent } from "react";
import styled from "styled-components";

import { __ } from "../lib/translate";

export type FullTextSwitchProps = {
  name: ?string,
  checked: boolean,
  onChange: any => void
};

class FullTextSwitch extends PureComponent<FullTextSwitchProps> {
  static defaultProps = {
    name: "",
    checked: false,
    onChange: () => {}
  };

  render() {
    const { name, onChange, ...rest } = this.props;

    return (
      <Container
        textBefore={__("search_name")}
        textAfter={__("search_content")}
      >
        <input type="checkbox" name={name} onChange={onChange} {...rest} />
        <Label
          textBefore={__("search_content")}
          textAfter={__("search_name")}
        />
      </Container>
    );
  }
}

export default FullTextSwitch;

const Container = styled.div`
  font-size: 11px;
  font-weight: bold;
  position: relative;
  display: inline-block;
  min-width: 150px;
  width: 100%;
  max-width: 175px;
  height: 30px;
  line-height: 30px;
  margin: 0 10px;

  &::after,
  &::before {
    font-family: "Roboto", sans-serif;
    font-feature-settings: normal;
    font-kerning: auto;
    font-language-override: normal;
    font-stretch: normal;
    font-style: normal;
    font-synthesis: weight style;
    font-variant: normal;
    font-weight: normal;
    text-rendering: auto;
  }

  input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    opacity: 0;
    cursor: pointer;
    z-index: 100;
    margin: 0;
  }

  input:checked + label {
    background: #f2f2f2;
    justify-content: flex-start;

    &:after {
      content: '${props => props.textAfter}';
      color: #17bed0;
      left: 48%;
    }

    &:before {
      content: '${props => props.textBefore}';
      color: #8e8e95;
      font-weight: bold;
      display: flex;
      justify-content: center;
      width: 50%;
    }
  }

  @media only screen and (max-width: 400px) {
    width: "65px";

    input:checked + label {
      &:after {
        left: 36px;
      }

      &:before {
        font-size: 12px;
        padding-left: 12px;
      }
    }
  }
`;

const Label = styled.label`
  position: relative;
  display: flex;
  justify-content: flex-end;
  font-size: 11px;
  font-weight: bold;
  min-width: 150px;
  width: 100%;
  height: 30px;
  background: #f2f2f2;
  line-height: 31px;
  border-radius: 20px;
  padding-bottom: 0 !important;
  transition: 0.4s;

  &:after {
    content: '${props => props.textAfter}';
    position: absolute;
    color: #17bed0;
    line-height: 24px;
    text-align: center;
    width: 50%;
    height: 24px;
    border-radius: 12px;
    box-shadow: 1.4px 1.4px 1px 0 rgba(0, 0, 0, 0.04);
    background-color: #ffffff;
    left: 3px;
    top: 3px;
    z-index: 2;
    transition: 0.4s;
  }

  &:before {
    content: '${props => props.textBefore}';
    color: #8e8e95;
    font-weight: bold;
    display: flex;
    justify-content: center;
    width: 50%;
  }

  @media only screen and (max-width: 400px) {
    width: 65px;

    &:before {
      font-size: 12px;
      padding-left: 33px;
    }
  }
`;
