// @flow

import React, { PureComponent } from "react";
import styled from "styled-components";
import { hiDPI } from "polished";

import type { Color } from "../../types/publiclinks";

const colors = [
  "ffffff",
  "ff4d4d",
  "ff9326",
  "59b200",
  "4d4dff",
  "ff007f",
  "000000"
];

const gradients = [
  ["ffa17f", "00223e"],
  ["2c3e50", "4ca1af"],
  ["dce35b", "45b649"],
  ["ff9966", "ff5e62"],
  ["c0392b", "8e44ad"]
];

type Props = {
  color: Color,
  onSelect: Color => void,
  onClose: () => void
};

const ColorChooser = function(props: Props) {
  const { color, onSelect, onClose } = props;

  const colorItems = colors.map(curColor =>
    <ColorItem key={curColor} color={"#" + curColor} onClick={() => onSelect(curColor)}>
       {curColor === color ? <SelectedColor /> : null}
    </ColorItem>
  );
  const gradientItems = gradients.map(colors =>
    <GradientItem key={colors[0] + colors[1]} first={"#" + colors[0]} second={"#" + colors[1]} onClick={() => onSelect(colors)}>
      {colors[0] === color[0] && colors[1] === color[1] ? <SelectedColor /> : null}
    </GradientItem>
  );
  return (
    <ListWrapper>
      <Close onClick={() => onClose()} />
      {colorItems}
      {gradientItems}
    </ListWrapper>
  );
};

export default ColorChooser;

const ListWrapper = styled.div`
  background-color: #fff;
  border-radius: 5px;
  padding: 9px 0 0 9px;
  width: 198px;
  &:last-child:after {
    clear: both;
    content: "";
    display: table;
  }
  box-shadow: 0px 4px 7px 0 rgba(0, 0, 0, 0.03), 0 0 1px 0 rgba(0, 0, 0, 0.2);
`;
const Close = styled.div`
  position: absolute;
  margin-left: 178px;
  cursor: pointer;
  width: 11px;
  height: 11px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url(${require("../../../root/img/branding-settings/close-colors.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/branding-settings/close-colors@2x.png")});
  }
`;

const ColorItem = styled.div`
  background: ${props => props.color};
  box-shadow: inset 0 0 1px 0 rgba(0, 0, 0, 0.2);
  width: 20px;
  height: 20px;
  margin: 0 9px 9px 0;
  float: left;
  cursor: pointer;
`;

const SelectedColor = styled.div`
  background: rgba(255, 255, 255, 0.6);
  width: 100%;
  height: 100%;
  :after {
    content: '';
    display: block;
    position: absolute;
    margin: 4px 8px;
    width: 3px;
    height: 8px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`; 

const GradientItem = styled(ColorItem)`
  background: linear-gradient(to right, ${props => props.first}, ${props => props.second});
`
