// @flow

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import Modal, { OvalModalWrap, OvalModalCloseV2 } from "../../Modal";
import Button from "../../ButtonDefault";
import * as Style from "../styledComponents";
import InputText from "../../InputText";

import apiMethod from "../../../api/apiMethod";
import { __ } from "../../../lib/translate";

const EditCompanyInfoModal = ({ onSuccess = () => {} }: { onSuccess: () => void }) => {
  const company = useSelector(
    ({ business: { accountInfo: { account: { company } = {} } = {} } = {} }) => company
  );
  const phone = useSelector(
    ({ business: { accountInfo: { account: { phone } = {} } = {} } = {} }) => phone
  );

  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [companyName, setCompanyName] = useState(company || "");
  const [companyPhone, setCompanyPhone] = useState(phone || "");
  const dispatch = useDispatch();

  useEffect(() => {
    setIsOpen(true);
  }, []);

  const handleClose = () => {
    setIsOpen(false);
  };

  const onCompanyNameChange = ({ target }) => {
    setCompanyName(target.value);
  };

  const onCompanyPhoneChange = ({ target }) => {
    setCompanyPhone(target.value);
  };

  const onEnter = (e: any) => {
    if (e.keyCode === 13) {
      onSaveClick();
    }
  };

  const onSaveClick = () => {
    if(isLoading) {
      return;
    }
    const phone = companyPhone.trim();
    const name = companyName.trim();
    setIsLoading(true);

    if (name.trim().length < 1) {
      HFN.message(__("Company can't be empty"), "error");
      return;
    }

    if (phone.trim().length < 1) {
      HFN.message(__("Phone can't be empty"), "error");
      return;
    }

    var pattern = new RegExp(/^\+?[\(\)\- 0-9]+$/);
    if ((phone.trim().length < 1 || phone.trim().length > 30) && !pattern.test(phone)) {
      HFN.message(__("error_phone_number", "Please, provide valid phone."), "error");
      return;
    }

    apiMethod(
      "account_modify",
      { name: name, phone: phone },
      () => {
        dispatch({ type: "UPDATE_COMPANY_INFO", companyInfo: { company: name, phone: phone } });
        handleClose();
        HFN.message(__("Company info updated."));
        onSuccess();
      },
      {
        errorCallback: ({ error, result, field }) => {
          setIsLoading(false);
          let errorMessage = __("something_went_wrong_refresh_and_try_again");

          if (result === 2103) {
            if (field === "name") {
              errorMessage = __("business_edit_user_company_name_error");
            }
          } else if (result === 1088) {
            errorMessage = __("error_phone_number", "Please, provide valid phone.");
          } else if (result === 2076) {
            errorMessage = __("error_2076", "You do not have the permissions for this action.");
          }

          HFN.message(errorMessage, "error");
          throw new Error(error);
        }
      }
    );
  };

  return (
    <Modal animate onClose={handleClose} opened={isOpen}>
      <OvalModalWrap>
        <OvalModalCloseV2 onClick={handleClose} />
        <Style.Container>
          <Style.Header>
            {__("b_edit_company_info_title", "Edit Your Company Information")}
          </Style.Header>
          <Style.Label>{__("Company", "Company")}</Style.Label>
          <InputText
            style={{ marginBottom: "20px" }}
            name="companyName"
            onChange={onCompanyNameChange}
            value={companyName}
            autoFocus
            onKeyDown={onEnter}
          />
          <Style.Label>{__("Phone", "Phone")}</Style.Label>
          <InputText
            name="companyPhone"
            onChange={onCompanyPhoneChange}
            value={companyPhone}
            onKeyDown={onEnter}
          />
          <Style.Footer>
            <Button
              color="lightgray4"
              style={{
                marginRight: "5px"
              }}
              onClick={handleClose}
            >
              {__("Cancel")}
            </Button>
            <Button
              color="cyan"
              style={{
                marginLeft: "5px"
              }}
              disabledColor="#dddddd"
              onClick={onSaveClick}
            >
              {__("Save")}
            </Button>
          </Style.Footer>
        </Style.Container>
      </OvalModalWrap>
    </Modal>
  );
};

export default EditCompanyInfoModal;
