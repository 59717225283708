// @flow
import React, { PureComponent } from "react";
import { findDOMNode } from "react-dom";
import styled, { keyframes } from "styled-components";
import { hiDPI } from "polished";
import $ from "jquery";

import { Loader } from "../../containers/TwoFactorAuth/SharedComponents";
import Dropdown, { DIR_RIGHT, DIR_DOWN } from "../../components/Dropdown";
import ColorChooser from "./ColorChooser";

import type { Color } from "../../types/publiclinks";
import { IMAGE_TYPE } from "@pcloud/web-utilities/dist/config/constants";
import { __ } from "../../lib/translate";

type DropdownItem = {
  title: string,
  href: boolean | string,
  extra: { id: string }
};

type Props = {
  color: Color,
  imageSrc: string,
  positionTop: number,
  isEditable: boolean,
  isRepositionStarted: boolean,
  showGradinent: boolean,
  onImageUpload: (IMAGE_TYPE.LOGO | IMAGE_TYPE.COVER, File, () => void) => void,
  onImageRemove: (IMAGE_TYPE.LOGO | IMAGE_TYPE.COVER) => void,
  onDoneCoverReposition: () => void,
  onColorSelected: Color => void,
  onStartReposition: () => void,
  getCoverOffset: number => void
};

type State = {
  uploadedImage: boolean,
  isLoading: boolean,
  showColorChooser: boolean,
  draggingStarted: boolean,
  imageWidth: number,
  imageHeight: number,
  divOffset: { top: number, left: number },
  containerWidth: number,
  containerHeight: number,
  lastMousePosition: { x: number, y: number }
};

class BrandingCover extends PureComponent<Props, State> {
  static defaultProps = {
    color: "",
    imageSrc: "",
    positionTop: 0,
    isEditable: true,
    isRepositionStarted: false,
    showGradinent: false,
    onImageUpload: () => {},
    onImageRemove: () => {},
    onColorSelected: () => {},
    onStartReposition: () => {},
    getCoverOffset: () => {}
  };

  constructor(props: Props) {
    super(props);

    (this: any).triggerImageUpload = this.triggerImageUpload.bind(this);
    (this: any).onUpload = this.onUpload.bind(this);
    (this: any).discardImage = this.discardImage.bind(this);
    (this: any).getDropDownList = this.getDropDownList.bind(this);
    (this: any).onItemDropdownClick = this.onItemDropdownClick.bind(this);

    (this: any).onMouseDown = this.onMouseDown.bind(this);
    (this: any).onMouseMove = this.onMouseMove.bind(this);
    (this: any).onMouseUp = this.onMouseUp.bind(this);
    (this: any).onMouseLeave = this.onMouseLeave.bind(this);
    (this: any).closeChooser = this.closeChooser.bind(this);

    (this: any).fileInput = null;

    this.state = {
      uploadedImage: false,
      isLoading: false,
      showColorChooser: false,
      draggingStarted: false,
      imageWidth: 0,
      imageHeight: 0,
      divOffset: { top: 0, left: 0 },
      containerWidth: 0,
      containerHeight: 0,
      lastMousePosition: { x: 0, y: 0 }
    };
  }

  getDropDownList() {
    const { color, imageSrc } = this.props;

    const hasImage = imageSrc.length > 0;

    if (hasImage) {
      return [
        {
          title: __("branding_delete_cover_image", "Delete cover photo"),
          href: false,
          extra: { id: "delete" }
        },
        {
          title: __("branding_update_cover_image", "Update cover image"),
          href: false,
          extra: { id: "upload" }
        },
        {
          title: __("branding_reposition_image", "Reposition image"),
          href: false,
          extra: { id: "position" }
        },
        {
          title: __("branding_cover_add_color", "Select cover color"),
          href: false,
          extra: { id: "color" }
        }
      ];
    } else {
      return [
        {
          title: __("branding_cover_upload_image", "Upload cover image"),
          href: false,
          extra: { id: "upload" }
        },
        {
          title: __("branding_cover_add_color", "Select cover color"),
          href: false,
          extra: { id: "color" }
        }
      ];
    }
  }

  onItemDropdownClick(item: DropdownItem) {
    const {
      extra: { id }
    } = item;

    if (id === "delete") {
      this.discardImage();
    } else if (id === "upload") {
      this.triggerImageUpload();
    } else if (id === "color") {
      this.showColorChooser();
    } else if (id === "position") {
      this.repositionCover();
    } else {
      console.log("unhandled", item);
    }
  }

  imageLoaded() {
    const imageWidth = $(".cover-image").width();
    const imageHeight = $(".cover-image").height();

    this.setState({ uploadedImage: true, imageWidth, imageHeight });
  }

  onMouseDown(event: any) {
    const { isRepositionStarted } = this.props;
    const { uploadedImage, lastMousePosition, divOffset } = this.state;

    /* Image should be loaded before it can be dragged */
    if (uploadedImage && isRepositionStarted) {
      /* Save mouse position */
      this.setState({
        draggingStarted: true,
        lastMousePosition: {
          x: event.pageX - divOffset.left,
          y: event.pageY - divOffset.top
        }
      });
    }
  }

  onMouseUp() {
    this.setState({ draggingStarted: false });
  }

  onMouseLeave() {
    this.setState({ draggingStarted: false });
  }

  onMouseMove(event: any) {
    const { getCoverOffset } = this.props;
    const {
      draggingStarted,
      imageHeight,
      imageWidth,
      uploadedImage,
      divOffset,
      lastMousePosition,
      containerHeight,
      containerWidth
    } = this.state;

    if (draggingStarted) {
      const currentMousePosition = {
        x: event.pageX - divOffset.left,
        y: event.pageY - divOffset.top
      };
      let changeX = currentMousePosition.x - lastMousePosition.x;
      let changeY = currentMousePosition.y - lastMousePosition.y;

      /* Save mouse position */
      this.setState({ lastMousePosition: currentMousePosition });

      let imgTop = parseInt($(".cover-image").css("top"), 10);
      let imgLeft = parseInt($(".cover-image").css("left"), 10);

      let imgTopNew = imgTop + changeY;
      let imgLeftNew = imgLeft + changeX;

      /* Validate top and left do not fall outside the image, otherwise white space will be seen */
      if (imgTopNew > 0) {
        imgTopNew = 0;
      }
      if (imgTopNew < containerHeight - imageHeight) {
        imgTopNew = containerHeight - imageHeight;
      }
      if (imgLeftNew > 0) {
        imgLeftNew = 0;
      }
      if (imgLeftNew < containerWidth - imageWidth) {
        imgLeftNew = containerWidth - imageWidth;
      }

      getCoverOffset(imgTopNew);

      $(".cover-image").css({
        top: imgTopNew + "px",
        left: imgLeftNew + "px"
      });
    }
  }

  startDragging() {
    this.setState({
      divOffset: $(".cover-wrapper").offset(),
      containerWidth: $(".cover-wrapper").outerWidth(),
      containerHeight: $(".cover-wrapper").outerHeight()
    });

    // Check whether image is cached or wait for the image to load
    // This is necessary before calculating width and height of the image

    if ($(".cover-image").get(0).complete) {
      this.imageLoaded();
    } else {
      $(".cover-image").on("load", () => {
        this.imageLoaded();
      });
    }
  }

  triggerImageUpload() {
    const fileInput: any = findDOMNode((this: any).fileInput);
    fileInput.click();

    this.setState({ isLoading: true });
  }

  onUpload() {
    const { onImageUpload } = this.props;
    const fileInput: any = findDOMNode((this: any).fileInput);
    const file = fileInput.files[0];

    onImageUpload(IMAGE_TYPE.COVER, file, () => {
      $(".cover-image").css({top: "", left: ""});
      this.setState({ isLoading: false });
    });
  }


  discardImage() {
    const { onImageRemove } = this.props;

    onImageRemove(IMAGE_TYPE.COVER);
  }

  showColorChooser() {
    this.setState({ showColorChooser: true });
  }

  repositionCover() {
    const { onStartReposition } = this.props;

    onStartReposition();
    this.startDragging();
  }

  closeChooser() {
    this.setState({ showColorChooser: false });
  }

  handleColorSelect(color: Color) {
    const { onColorSelected } = this.props;

    onColorSelected(color);
  }

  renderColorChooser() {
    const { color } = this.props;

    return (
      <ColorChooserWrap>
        <ColorChooser
          color={color}
          onSelect={this.handleColorSelect.bind(this)}
          onClose={this.closeChooser}
        />
      </ColorChooserWrap>
    );
  }

  renderAddCoverButton() {
    const { color, imageSrc } = this.props;
    const hasCoverOrColor = imageSrc || color;

    return (
      <AddCoverButton onClick={this.closeChooser}>
        <Dropdown
          direction={DIR_RIGHT}
          verticalDirection={DIR_DOWN}
          childDirection={DIR_RIGHT}
          list={this.getDropDownList()}
          onSelect={this.onItemDropdownClick}
        >
          <AddCover>
            <AddIcon />
            <AddCoverText>
              {hasCoverOrColor
                ? __("branding_update_cover", "Update cover")
                : __("branding_cover_placeholder", "Add a cover")}
            </AddCoverText>
          </AddCover>
        </Dropdown>
      </AddCoverButton>
    );
  }

  render() {
    const {
      color,
      imageSrc,
      positionTop,
      isEditable,
      isRepositionStarted,
      showGradinent,
      onDoneCoverReposition
    } = this.props;
    const { uploadedImage, showColorChooser } = this.state;
    const hasGradientColor = Array.isArray(color);

    if (!isEditable && !imageSrc && !color) {
      return <EmptyCover />;
    }

    return (
      <CoverPhotoWrapper
        className="cover-wrapper"
        imageSrc={imageSrc}
        isRepositionStarted={isRepositionStarted}
        backgroundColor={!hasGradientColor && color ? "#" + color : null}
        firstBgColor={hasGradientColor ? "#" + color[0] : null}
        secondBgColor={hasGradientColor ? "#" + color[1] : null}
        onMouseDown={this.onMouseDown}
        onMouseMove={this.onMouseMove}
        onMouseUp={this.onMouseUp}
        onMouseLeave={this.onMouseLeave}
      >
        {imageSrc ? (
          <React.Fragment>
            {(!isRepositionStarted && showGradinent) || isEditable ? (
              <Gradient />
            ) : null}
            <UploadImageWrapper
              className="cover-image"
              src={imageSrc}
              positionTop={positionTop}
            />
          </React.Fragment>
        ) : null}
        <PositioningAddCoverColorChooser>
          {isEditable ? this.renderAddCoverButton() : null}
          {isEditable && showColorChooser ? this.renderColorChooser() : null}
          {isRepositionStarted &&
            <DoneRepositionButton onClick={onDoneCoverReposition}> 
              <CheckMark />
              {__("Done", "Done")}
            </DoneRepositionButton>
          }
        </PositioningAddCoverColorChooser>
        <FileInput
          name={IMAGE_TYPE.COVER}
          type="file"
          onChange={this.onUpload}
          onClick={e => (e.target.value = "")}
          ref={ref => ((this: any).fileInput = ref)}
          accept={".png,.jpg,.jpeg, image/*"}
        />
      </CoverPhotoWrapper>
    );
  }
}

export default BrandingCover;

const CoverPhotoWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  /* margin-bottom: 13px; */
  overflow: hidden;
  user-select: none;
  background: ${props =>
    props.backgroundColor
      ? props.backgroundColor
      : props.firstBgColor
      ? "linear-gradient(to right, " +
        props.firstBgColor +
        ", " +
        props.secondBgColor +
        ")"
      : "transparent"};
  cursor: ${props => (props.isRepositionStarted ? "move" : "auto")};
  top: 0px;
`;

const EmptyCover = styled(CoverPhotoWrapper)`
  background-color: #fff;
`;

const Gradient = styled.div`
  width: 100%;
  height: 200px;
  position: absolute;
  bottom: 0;
  opacity: 0.7;
  z-index: 100;
  background-image: linear-gradient(
    to top,
    #000000,
    rgba(0, 0, 0, 0.65),
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.3),
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0)
  );
`;

const UploadImageWrapper = styled.img`
  top: ${props => "-" + props.positionTop + "px" || 0};
  left: 0;
  pointer-events: none;
  position: relative;
  -moz-user-select: none;
  width: 100%;
  height: auto;
`;

const AddIcon = styled.div`
  display: block;
  width: 20px;
  min-width: 20px;
  height: 20px;
  margin-right: 10px;

  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url(${require("../../../root/img/branding-settings/add.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/branding-settings/add@2x.png")});
  }
`;

const ImageText = styled.div`
  width: 100%;
  height: 13px;
  margin-right: 5px;
  margin-left: 2px;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-weight: bold;
  line-height: 13px;
  letter-spacing: -0.2px;
  text-align: center;
  color: #fff;
  margin-bottom: 10px;
`;

const AddCoverText = styled(ImageText)`
  font-weight: none;
  color: #000000;
  margin-bottom: 0;
`;

const AddCoverButton = styled.div`
  display: inline-block;
  height: 36px;
  padding: 0 10px;
  cursor: pointer;
  border-radius: 4.5px;
  min-width: 116px;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.2), 0px 4px 7px 0 rgba(0, 0, 0, 0.03);
  background-color: #ffffff;
  z-index: 1000;
  position: absolute;
  top: -400px;
`;

const PositioningAddCoverColorChooser = styled.div`
  margin: 40px auto;
  text-align: left;
  width: 1180px;
  position: absolute;
  top: 400px;
  left: 0;
  right: 0;
  @media only screen and (max-width: 1240px) {
    width: inherit;
    min-width: 728px;
    padding-left: 20px;
  }
`

const CheckMark = styled.div`
  display: inline-block;
  width: 20px;
  height: 20px;
  position: absolute;
  left: 10px;
  top: 8px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url(${require("../../../root/img/yearpromo/check.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/yearpromo/check@2x.png")});
  }
`

const DoneRepositionButton = styled(AddCoverButton)`
  position: absolute;
  top: -400px;
  line-height: 36px;
  padding-left: 40px;
  min-width: 136px;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: -0.2px;
  color: #000;
  text-align: center;
`

const ColorChooserWrap = styled.div`
  position: absolute;
  top: -400px;
  margin-top: 50px;
  z-index: 1000;
`;

const AddCover = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 36px;
  background-color: #ffffff;
`;

const FileInput = styled.input`
  display: none;
`;
