// @flow

import * as React from "react";
import styled from "styled-components";

import { TDiv } from "./intl";

const EmptyRow = () => (
  <Empty id="empty_folder">Empty folder</Empty>
);

export default EmptyRow;

const Empty = styled(TDiv)`
  height: 40px;
  line-height: 40px;
  font-size: 15px;
  text-align: center;
`;
