import React from 'react';
import ActionButton from "../../../userinterface/ActionButton";
import {
    IconText,
    OverlayPortalContainer,
    SongControlsCenterWrapper,
    SongControlsWrapper
} from "../../../userinterface/styles";
import MinusBigIcon from "../../../../../../root/img/player/minus-progress-big.svg";
import MinusIcon from "../../../../../../root/img/player/minus-progress.svg";
import PlusBigIcon from "../../../../../../root/img/player/plus-progress-big.svg";
import PlusIcon from "../../../../../../root/img/player/plus-progress.svg";
import SleepIcon from "../../../../../../root/img/player/sleep.svg";
import SleepActiveIcon from "../../../../../../root/img/player/sleep-active.svg";
import SleepBigIcon from "../../../../../../root/img/player/sleep-big.svg";
import SleepActiveBigIcon from "../../../../../../root/img/player/sleep-active-big.svg";
import MinimizedBoxControls from "./MinimizedBoxControls";
import usePlayerContext from "../../../hooks/usePlayerContext";
import useLongPress from "../../../../../lib/hooks/useLongPress";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getPlayerState} from "../../../helpers";
import {faPlay} from "@fortawesome/pro-solid-svg-icons/faPlay";
import {faPause} from "@fortawesome/pro-solid-svg-icons/faPause";

const SongControls = () => {

    const {
        isMobile,
        onTogglePlayPause,
        playerVariant,
        onPlayerClose,
        onPlayerMaximize,
        onPrevProgress,
        onNextProgress,
        additionalClass,
        isPlaying,
        playRate,
        sleepTimer,
        controlsWithOverlay,
        onOpenPlayRateToggle,
        onOpenSleepTimerToggle
    } = usePlayerContext();

    const longPressPrev = useLongPress(onPrevProgress, (e) => {
        onPrevProgress(e);
        if (typeof gtag === "function") {
            gtag("event", "audio_player_click", {
                action: "prevtime",
                state: getPlayerState(playerVariant)
            });
        }
    });
    const longPressNext = useLongPress(onNextProgress, (e) => {
        onNextProgress(e);
        if (typeof gtag === "function") {
            gtag("event", "audio_player_click", {
                action: "nexttime",
                state: getPlayerState(playerVariant)
            });
        }
    });

    const {
        isMiniPlayer,
        isMinimize,
        isFullPage
    } = playerVariant;

    const playButtonSize = isMobile || isFullPage ? 40 : (
        isMiniPlayer ? (
            isMinimize ? 40 : 30
        ) : 24
    );
    const controlDefaultSvgColor = isMiniPlayer ? 'svg-color-default' : 'svg-color-default-light';
    const controlDefaultColor = isMiniPlayer ? 'color-default' : 'color-default-light';
    const controlBtnSize = isMobile || isFullPage ? 40 : 24;
    const showSideControlBtns = !isMinimize;
    const showMinimizedBoxControlBtns = isMinimize && !isMobile;

    return (
        <>
            <SongControlsWrapper
                className={additionalClass+' animate'}
            >
                {showSideControlBtns && (
                    <OverlayPortalContainer id="playRateContainer">
                        <ActionButton
                            onClick={onOpenPlayRateToggle}
                            className={controlsWithOverlay.showPlayRatePanel ? 'color-prime' : controlDefaultColor}
                            label={<IconText $fontSize={
                                (isMiniPlayer && !isMinimize && isMobile) || isFullPage ? 18 : (isMobile ? 16 : 13)
                            }>{playRate}x</IconText>}
                            buttonWidth={controlBtnSize}
                            buttonHeight={controlBtnSize}
                            styles={{
                                justifyContent: 'start'
                            }}
                        />
                    </OverlayPortalContainer>
                )}

                <SongControlsCenterWrapper
                    className={additionalClass+' animate'}
                >
                    <ActionButton
                        longPressEvents={longPressPrev}
                        className={'svg-color-default'}
                        label={(isMiniPlayer && !isMinimize && isMobile) || isFullPage ?
                            <MinusBigIcon /> :
                            <MinusIcon />
                        }
                        buttonWidth={playButtonSize}
                        buttonHeight={playButtonSize}
                    />
                    <ActionButton
                        onClick={(e) => {
                            onTogglePlayPause(e);

                            if (typeof gtag === "function") {
                                gtag("event", "audio_player_click", {
                                    action: isPlaying ? "pause" : "play",
                                    state: getPlayerState(playerVariant)
                                });
                            }
                        }}
                        className={'svg-color-default'}
                        label={isPlaying ?
                            <FontAwesomeIcon style={{
                                fontSize: isMiniPlayer && !isMinimize && isMobile ? 40 : (isFullPage ? 34 : (isMobile ? 20 : 16))
                            }} icon={faPause} /> :
                            <FontAwesomeIcon style={{
                                fontSize: isMiniPlayer && !isMinimize && isMobile ? 40 : (isFullPage ? 34 : (isMobile ? 20 : 16))
                            }} icon={faPlay} />
                        }
                        buttonWidth={playButtonSize}
                        buttonHeight={playButtonSize}
                    />
                    <ActionButton
                        longPressEvents={longPressNext}
                        className={'svg-color-default'}
                        label={(isMiniPlayer && !isMinimize && isMobile) || isFullPage ?
                            <PlusBigIcon /> :
                            <PlusIcon />
                        }
                        buttonWidth={playButtonSize}
                        buttonHeight={playButtonSize}
                    />
                </SongControlsCenterWrapper>

                {showSideControlBtns && (
                    <OverlayPortalContainer id="sleepTimerContainer">
                        <ActionButton
                            onClick={onOpenSleepTimerToggle}
                            className={controlsWithOverlay.showSleepTimerPanel ? 'svg-color-prime' : controlDefaultSvgColor}
                            label={(isMiniPlayer && !isMinimize && isMobile) || isFullPage ?
                                (
                                    sleepTimer > 0 ?
                                        <SleepActiveBigIcon /> :
                                        <SleepBigIcon />
                                ) :
                                (
                                    sleepTimer > 0 ?
                                        <SleepActiveIcon /> :
                                        <SleepIcon />
                                )
                            }
                            buttonWidth={controlBtnSize}
                            buttonHeight={controlBtnSize}
                            styles={{
                                justifyContent: 'end'
                            }}
                        />
                    </OverlayPortalContainer>
                )}

                {showMinimizedBoxControlBtns && (
                    <MinimizedBoxControls
                        onPlayerMaximize={onPlayerMaximize}
                        onPlayerClose={onPlayerClose}
                    />
                )}
            </SongControlsWrapper>
        </>
    );
}

export default SongControls;