// @flow

import React from "react";
import styled from "styled-components";

import FooterSmall from "./FooterSmall";
//import BusinessAd from "./BusinessAd";
//import FamilyAd from "./FamilyAd";
// import LifetimeAd from "./LifetimeAd";
// import PlansAd from "./PlansAd";

const FooterWrap = (props: {}) => (
  <React.Fragment>
    <FooterSmall />
  </React.Fragment>
);

export default FooterWrap;
