// @flow
import React, { Component } from "react";
import { hiDPI } from "polished";
import styled from "styled-components";

import { __ } from "../lib/translate";
import { loadScript } from "../lib/utils";
import { APPLE_SDK } from "@pcloud/web-utilities/dist/config/constants";
import APPLELogin from "../lib/apple_login";

type Props = {
  request_id: string
};

class AppleSigninDrive extends Component<Props, {}> {
  constructor(props: Props) {
    super(props);

    (this: any).onButtonClick = this.onButtonClick.bind(this);
  }

  componentDidMount() {
    const { request_id } = this.props;

    loadScript(APPLE_SDK, () => {
      APPLELogin.init(false, request_id);
    });
  }

  onButtonClick() {
    APPLELogin.signIn();
  }

  render() {
    console.log("🚀 ~ file: AppleSigninDrive.js ~ line 36 ~ AppleSigninDrive ~ this.props.request_id", this.props.request_id)
    let application = "Drive";
  
    if (this.props.request_id.indexOf('is_extension')) {
      application = "Save";
    }
  
    if (this.props.request_id.indexOf('passpcloud')) {
      application = "pCloud Pass";
    }

    return (
      <Wrapper>
        <AppleImage />
        <Headline>{__("drive_applesignin_headline", "Sign in with Apple")}</Headline>
        <Message>
          {__(
            "drive_applesignin_message",
            "You're about to sign in to %application% with your Apple account. Would you like to continue?", { application: application }
          )}
        </Message>
        <AppleButton onClick={this.onButtonClick}>
          {__("sign_in_with_apple", "Sign in with Apple")}
        </AppleButton>
      </Wrapper>
    );
  }
}

export default AppleSigninDrive;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  width: 450px;
  height: 100%;
  text-align: center;
`;

const AppleImage = styled.div`
  display: block;
  width: 300px;
  height: 180px;
  margin-top: 60px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 300px 180px;

  background-image: url(${require("../../root/img/apple-page.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../root/img/apple-page@2x.png")});
  }
`;

const Headline = styled.div`
  margin-top: 60px;
  font-size: 28px;
`;
const Message = styled.div`
  margin: 20px 0 40px 0;
  font-size: 17px;
`;

const AppleButton = styled.div`
  display: block;
  width: 300px;
  height: 40px;
  line-height: 40px;
  color: #fff;
  border-radius: 7px;
  box-shadow: 0px 2px 0 0 rgba(0, 0, 0, 0.01);
  background-color: #111111;
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 18px 22px;
  cursor: pointer;

  background-image: url(${require("../../root/img/apple-white.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../root/img/apple-white@2x.png")});
  }
`;
