import React from "react";
import usePlayerContext from "../../../hooks/usePlayerContext";
import {
    SettingsItemLabelWrapper,
    SettingsItemWrapper
} from "../../../userinterface/styles";
import OverlayPanelContainer from "./OverlayPanelContainer";
import { __ } from "../../../../../lib/translate";
import {createPortal} from "react-dom";
import {getPlayerState} from "../../../helpers";


const SleepTimerOverlay = () => {
    const {
        additionalClass,
        sleepTimer,
        setSleepTimer,
        onCloseAllOverlays,
        playerVariant
    } = usePlayerContext();

    const values = [
        {
            value: 5,
            time: 5,
            timeSubfix: __('minutes')
        },
        {
            value: 10,
            time: 10,
            timeSubfix: __('minutes')
        },
        {
            value: 15,
            time: 15,
            timeSubfix: __('minutes')
        },
        {
            value: 30,
            time: 30,
            timeSubfix: __('minutes')
        },
        {
            value: 60,
            time: 1,
            timeSubfix: __('hour')
        }
    ];

    const container = document.getElementById('sleepTimerContainer');

    if (!container) {
        return null;
    }

    const classPosition =
        ' sleep-timer ' +
        (
            playerVariant.isMiniPlayer ? ' position-top' : ' position-bottom'
        )
    ;

    return (
        <>
            {createPortal(
                <OverlayPanelContainer className={additionalClass + classPosition}>
                    <SettingsItemWrapper
                        className={'secondary-item color-gray'}
                    >
                        <SettingsItemLabelWrapper>
                            {__('Sleep timer')}
                        </SettingsItemLabelWrapper>
                    </SettingsItemWrapper>
                    {values.map(item => (
                        <SettingsItemWrapper
                            key={item.value}
                            className={item.value === sleepTimer ? 'active only-text' : 'only-text'}
                            onClick={() => {
                                setSleepTimer(item.value);
                                onCloseAllOverlays();

                                if (typeof gtag === "function") {
                                    gtag("event", "audio_player_click", {
                                        action: "sleeptimer change",
                                        state: getPlayerState(playerVariant),
                                        eventValue: item.value
                                    });
                                }
                            }}
                        >
                            <SettingsItemLabelWrapper>
                                {item.time + ' ' + item.timeSubfix}
                            </SettingsItemLabelWrapper>
                        </SettingsItemWrapper>
                    ))}

                    <SettingsItemWrapper
                        className={'only-text'}
                        onClick={() => {
                            setSleepTimer(0);
                            onCloseAllOverlays();

                            if (typeof gtag === "function") {
                                gtag("event", "audio_player_click", {
                                    action: "sleeptimer change",
                                    state: getPlayerState(playerVariant),
                                    eventValue: 0
                                });
                            }
                        }}
                    >
                        <SettingsItemLabelWrapper>
                            { __('label_off', 'Off') }
                        </SettingsItemLabelWrapper>
                    </SettingsItemWrapper>
                </OverlayPanelContainer>,
                container
            )}
        </>
    );
}

export default SleepTimerOverlay;