// @flow
import { useEffect, useState, useMemo } from "react";
import apiMethod from "../../../api/apiMethod";
import { __ } from "../../../lib/translate";

const useDownloadMedia = ({
  downloadOnMobileDLinkEl,
  downloadItem,
  opts,
  onClose = () => {}
}) => {
  const [onDownloadOptionClicked, setOnDownloadOptionClicked] = useState(null);
  const isDlink = useMemo(() => HFN.config.isDlink(), []);
  const isDlinkMobile = useMemo(() => HFN.config.isPublinkMobile(), []);
  const isSameLocation = useMemo(() => HFN.isSameLocation(), []);

  const { canDownload, canDownloadMobile } = useMemo(() => {
    if (isDlinkMobile) {
      // Dlink on mobile.
      if (opts.candownload) {
        // Dlink on mobile with allowed "Download".
        return {canDownloadMobile: true, canDownload: true};
      }
      // Dlink on mobile with disallowed "Download".
      return {canDownloadMobile: false, canDownload: false};
    }
    
    // 1. Not a Dlink on any device.
    // 2. Dlink on desktop with allowed "Download".
    if (!isDlink || (isDlink && opts.candownload)) {
      return {canDownloadMobile: false, canDownload: true};
    }

    return { canDownloadMobile: false, canDownload: false };
  }, [opts]);


  useEffect(() => {
    if (downloadOnMobileDLinkEl.current) {
      setupDownloadOptionsMenu();
    }
  }, []);

  /**  
   * Triggered when "Download" icon is clicked on mobile dLink.
   */
  useEffect(() => {
    if (onDownloadOptionClicked === null) {
      return;
    }
    const clickedMenuOption = onDownloadOptionClicked;

    if (clickedMenuOption.action == "download") {
      if (typeof gtag === "function") {
        gtag("event", "media_preview_click", {
          action: "download",
          category: downloadItem.metaData && downloadItem.metaData.category === HFN.CATEGORY.VIDEO ? "video" : "image"
        });
      }
      
      HFN.downloadFileMobile(downloadItem.metaData, opts.code || false, opts.linkpassword || false);
    } else if (clickedMenuOption.action == "savetopcloud") {
      
      const copy = () => {
        fs.reset();
        fs.add(downloadItem.metaData);
        HFN.copyToCloudDirect({
          code: opts.code,
          linkpassword: opts.linkpassword,
          onDone() {
            const openUrl = "https://my.pcloud.com/#page=filemanager&folder=0";
            HFN.message(
              __("items_saved_open_location", "Item(s) saved. %openUrl%[Open Location]", { openUrl }),
              "success",
              false,
              false,
              20000
            );
          }
        });
      };

      if (!HFN.config.auth && isDlink) {
        onClose();
        HFN.initSaveFileLoginRegModal(copy);
      } else {
        copy();
      }
    }
  }, [onDownloadOptionClicked]);

  const downloadFile = () => {
    if (typeof gtag === "function") {
      gtag("event", "media_preview_click", {
        action: "download",
        category: downloadItem.metaData && downloadItem.metaData.category === HFN.CATEGORY.VIDEO ? "video" : "image"
      });
    }
    
    let method = "getfilelink";
    const params = { fileid: downloadItem.metaData.fileid, forcedownload: true };

    if (opts.code) {
      method = "getpublinkdownload";
      params.code = opts.code;

      if (opts.linkpassword) {
        params.linkpassword = opts.linkpassword;
      }
    }

    if (downloadItem.metaData.revisionid) {
      params.revisionid = downloadItem.metaData.revisionid;
    }

    apiMethod(
      method,
      params,
      ret => {
        window.open(HFN.prepUrl(ret));
      },
      { 
        async: false,
        errorCallback(res) {
          if (res && res.result && res.result === 1000) {
            // Log in required.
            HFN.message(__("Session Expired. Please login."), "error");
          }
        },
        onXhrError: (xhr, status, error) => {
          HFN.message(__("Please check your Internet connection."), "error");
        }
       }
    );
  };

  const setupDownloadOptionsMenu = () => {
    const actions = [
      {
        action: "download",
        title: __("save_to_device", "Save to Device"),
        subtext: __("save_to_device_zip_description", "Save directly to your device"),
        icon: "icon-dwl"
      }
    ];

    if (isDlinkMobile && isSameLocation) {
      actions.unshift({
        action: "savetopcloud",
        title: __("save_to_pcloud", "Save to pCloud"),
        subtext: __("save_to_pcloud_description", "Save directly to pCloud for free"),
        icon: "icon-save"
      });
    }

    const downloadBtn = $(downloadOnMobileDLinkEl.current);

    actionSheet.setup({
      el: downloadBtn,
      onClick(item) {
        // Copy item to trigger hook change.
        setOnDownloadOptionClicked({ ...item });
      },
      items: actions,
      buildItem(item, obj) {
        item
          .append(
            $('<div class="info-wrap"></div>')
              .append($('<div class="title"></div>').text(obj.title))
              .append($('<div class="sub-text"></div>').text(obj.subtext))
          )
          .append($('<span class="sheet-icon sheet-icon-download"></span>').addClass(obj.icon))
          .addClass("download-mobile-sheet");
      }
    });
  };

  return {
    downloadFile,
    canDownload,
    canDownloadMobile
  }
};

export default useDownloadMedia;  