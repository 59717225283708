import React from "react";
import { GoogleCastButton } from '@vidstack/react';
import { ChromeCastIcon } from "../../icons";

const GoogleChromeCastButton = () => {
  return (
    <GoogleCastButton className="plyr__controls__item plyr__control">
      <ChromeCastIcon className="icon--pressed" />
      <ChromeCastIcon className="icon--not-pressed" />
    </GoogleCastButton>
  );
}

export default GoogleChromeCastButton;