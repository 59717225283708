// @flow

import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

import { __ } from "../../lib/translate";
import Button from "../../components/ButtonDefault";
import Modal, { OvalModalWrap } from "../../components/Modal";
import apiMethod from "../../api/apiMethod";
import { errorKeys } from "../../lib/errors";

import { Container, Header, Footer } from "../FamilyPlan/SharedFamilyComponents";

type Props = {
  linkid: string,
  isOpen: boolean,
  onClose: () => void,
  updateCustomLinksOnRemove: () => void
};

const StopCustomLink = ({ isOpen, linkid, updateCustomLinksOnRemove, onClose }: Props) => {
  const token = useSelector(({ user }) => user.token);

  const stopCustomLink = () => {
    const id = linkid;

    apiMethod(
      "removepublinkbranding",
      { auth: token, linkid: id },
      ret => {
        updateCustomLinksOnRemove(id);
      },
      {
        errorCallback: ({ result, error }) => {
          if (errorKeys[result]) {
            HFN.message(__(errorKeys[result]), "error");
          } else {
            console.log("ERROR", { result, error });
            HFN.message(__("something_went_wrong_refresh_and_try_again"), "error");
          }
        },
        onXhrError: (xhr, status, error) => {
          HFN.message(__("something_went_wrong_refresh_and_try_again"), "error");
        }
      }
    );
  };

  return (
    <Modal opened={isOpen} onClose={onClose}>
      <OvalModalWrap>
        <Container>
          <Header>{__("disable_custom_branding")}</Header>
          <MessageText>{__("disable_custom_branding_message2")}</MessageText>
          <MessageText>{__("Do you want to continue?")}</MessageText>
          <DisableFooter>
            <Button color="lightgray4" onClick={onClose}>
              {__("No")}
            </Button>
            <Button color="red" onClick={stopCustomLink}>
              {__("Yes")}
            </Button>
          </DisableFooter>
        </Container>
      </OvalModalWrap>
    </Modal>
  );
};

export default StopCustomLink;

const DisableFooter = styled(Footer)`
  a {
    margin: 0px 5px;
    display: inline-block;
  }
`;

const MessageText = styled.div`
  align-items: center;
  background-color: #fff;
  font-weight: normal;
  font-size: 15px;
`;
