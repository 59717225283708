import React, {useEffect, useState} from 'react';
import SongThumb from "../../../userinterface/SongThumb";
import usePlayerContext from "../../../hooks/usePlayerContext";
import {
    BoxSongWrapper,
    BoxTopHeaderWrapper,
    BoxWaveHolder,
    FlexWrapper,
    MobileMiniControlsWrapper,
    MobileMiniSongWrapper, MobileMiniWrapper
} from "../../../userinterface/styles";
import WaveAnimation from "../../../userinterface/WaveAnimation";
import ProgressBar from "./ProgressBar";
import OpenAppButton from "../../../userinterface/OpenAppButton";
import {getBoxThumbSize, getPlayerState} from "../../../helpers";
import BoxSongDetails from "./BoxSongDetails";
import SongControls from "./SongControls";
import {motion, useAnimationControls} from "framer-motion"
import PodcastControls from "./PodcastControls";

const MobileMiniLayout = () => {
    const {
        isPublic,
        isMobile,
        playerVariant,
        additionalClass,
        currentSong,
        onNext,
        onPrev,
        podcastMode
    } = usePlayerContext();

    const {
        showPlayer,
        isFullPage,
        isMiniPlayer,
        isMinimize,
    } = playerVariant;

    const controls = useAnimationControls();

    const thumbSize = getBoxThumbSize(playerVariant, isMobile);
    const showTitleOnTop = isFullPage;
    const showAnimation = isMiniPlayer && (!isMinimize || isFullPage);
    const showAppButton = isPublic && isMobile && !playerVariant.isMinimize;

    const [touch, setTouch] = useState({
        distanceX: 0,
        x: 0
    });

    const onTouchStart = (e) => {
        setTouch({
            ...touch,
            x: e.targetTouches[0].clientX
        });
    }

    const onTouchMove = (e) => {
        const distanceX = touch.x - e.targetTouches[0].clientX;

        setTouch({
            ...touch,
            distanceX
        });
    }

    const onTouchEnd = (e) => {
        if (touch.distanceX > 100) {
            onNext();
            onNextAnimation();

            if (typeof gtag === "function") {
                gtag("event", "audio_player_click", {
                    action: "next",
                    state: getPlayerState(playerVariant)
                });
            }
        } else if (touch.distanceX < -100) {
            onPrev();
            onPrevAnimation();

            if (typeof gtag === "function") {
                gtag("event", "audio_player_click", {
                    action: "previous",
                    state: getPlayerState(playerVariant)
                });
            }
        }
        setTouch({
            distanceX: 0,
            x: 0
        });
    }

    const onPrevAnimation = () => {
        controls.start({
            x: ["0vw", "100vw", "-100vw", "0vw"],
            transition: { duration: 0.5, times: [0, 0.5, 0.5, 1] }
        });
    }

    const onNextAnimation = () => {
        controls.start({
            x: ["0vw", "-100vw", "100vw", "0vw"],
            transition: { duration: 0.5, times: [0, 0.5, 0.5, 1] }
        });
    }

    return (
        <MobileMiniWrapper
            onTouchStart={onTouchStart}
            onTouchMove={onTouchMove}
            onTouchEnd={onTouchEnd}
        >
            <MobileMiniSongWrapper className={additionalClass}>
                <motion.div
                    animate={controls}
                >
                    <SongThumb
                        songFileId={currentSong?.fileid}
                        width={thumbSize}
                        height={thumbSize}
                        borderRadius={isFullPage ? 16 : null}
                        isActive={isMinimize}
                        disableAnimation={true}
                    />
                </motion.div>
                <BoxSongWrapper
                    className={additionalClass}
                >
                    <BoxTopHeaderWrapper
                        className={additionalClass}
                    >
                        {!showTitleOnTop && <BoxSongDetails/>}
                    </BoxTopHeaderWrapper>
                    {showAnimation && (
                        <BoxWaveHolder
                            className={additionalClass}
                        >
                            <WaveAnimation/>
                        </BoxWaveHolder>
                    )}
                </BoxSongWrapper>
            </MobileMiniSongWrapper>

            <MobileMiniControlsWrapper
                className={additionalClass + ' bottom-controls fade-transition'}
                $width={'auto'}
                $show={showPlayer}
            >
                <ProgressBar
                    showMiddleTimes={!isMiniPlayer && !isMinimize}
                    showBottomTimes={isMiniPlayer && !isMinimize}
                    showPointer={(!isMinimize && (!isFullPage || isMobile))}
                />
                {podcastMode && <PodcastControls />}
                {!podcastMode && <SongControls
                    onPrevCallback={onPrevAnimation}
                    onNextCallback={onNextAnimation}
                />}
                {showAppButton && isMiniPlayer && (
                    <FlexWrapper style={{marginTop: 32}} $justifyContent={'center'}>
                        <OpenAppButton/>
                    </FlexWrapper>
                )}
            </MobileMiniControlsWrapper>
        </MobileMiniWrapper>
    );
}

export default MobileMiniLayout;