// @flow

import React, { PureComponent } from "react";
import styled from "styled-components";
import { hiDPI } from "polished";

import Modal, { OvalModalWrap, OvalModalClose } from "../../components/Modal";
import { Loader } from "../TwoFactorAuth/SharedComponents";
import BrandingSetupHeader from "./BrandingSetupHeader";
import type { PubLink } from "../../types/publiclinks";

import Button from "../../components/ButtonDefault";
import { __ } from "../../lib/translate";
import { myUrlLocation } from "@pcloud/web-utilities/dist/config";
import { SAMPLE_BRANDING_PUBLINK_CODE } from "@pcloud/web-utilities/dist/config/constants";

type Props = {
  link?: PubLink,
  brandingType: "custom" | "default",
  isActiveCL: boolean,
  isActiveGL: boolean,
  isPremium: boolean,
  onClose: () => void,
  updateGeneralBrandingStatus: () => void,
  updateCustomBrandingStatus: (string) => void
};

type State = {
  isLoadingIframe: boolean
};

class BrandingLinkPreview extends PureComponent<Props, State> {
  static defaultProps = {
    link: {},
    isOpen: false,
    onClose: () => {}
  };

  constructor(props: Props) {
    super(props);
    (this: any).onIframeLoaded = (this: any).onIframeLoaded.bind(this);

    this.state = {
      isLoadingIframe: true
    };

    (this: any).iframeEl = null;
  }

  componentDidMount() {
    if ((this: any).iframeEl) {
      (this: any).iframeEl.addEventListener("load", this.onIframeLoaded);
    }
  }

  onIframeLoaded() {
    this.setState({ isLoadingIframe: false });
  }

  render() {
    const {
      link,
      brandingType,
      onClose,
      isActiveCL,
      isActiveGL,
      isPremium,
      updateGeneralBrandingStatus,
      updateCustomBrandingStatus
    } = this.props;
    const { isLoadingIframe } = this.state;

    // const linkCode = link ? link.code : SAMPLE_BRANDING_PUBLINK_CODE;
    const publinkUrl = link ? myUrlLocation("publink/show?code=" + link.code + "&br=0") : "https://u.pcloud.com/publink/show?code=kZx1V67ZpNEubSD4YluaUBTuqLaWaYtKYj2k&br=0";

    return (
      <Wrapper>
        <BrandingSetupHeader
          link={link}
          onClose={onClose}
          brandingType={brandingType}
          isActiveCL={isActiveCL}
          isActiveGL={isActiveGL}
          isPremium={isPremium}
          updateGeneralBrandingStatus={updateGeneralBrandingStatus}
          updateCustomBrandingStatus={updateCustomBrandingStatus}
        />
        <WrapperIframe>
          {isLoadingIframe ? <LoaderIframe /> : null}
          <Overlay />
          <IframeLink
            show={!isLoadingIframe}
            ref={el => ((this: any).iframeEl = el)}
            src={publinkUrl}
            scrolling="no"
          />
        </WrapperIframe>
      </Wrapper>
    );
  }
}

export default BrandingLinkPreview;

const Wrapper = styled.div`
  background-color: #fff;
`;
const WrapperIframe = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const IframeLink = styled.iframe`
  border: none;
  width: 100%;
  height: 100vh;
  background-color: #fff;
  opacity: ${props => (props.show ? 1 : 0)};
  overflow: hidden;
`;

const Overlay = styled.div`
  position: absolute;
  background-color: #fff;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: not-allowed;
`;

const LoaderIframe =  styled(Loader)`
  top: 30%;
`;
