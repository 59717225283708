// @flow

import PropTypes from "prop-types";
import React, { Component } from "react";
import styled from "styled-components";
import { hiDPI } from "polished";

type Props = {
  name: ?string,
  onChange: any => void,
  disableChange: boolean
};

type State = {
  isChecked: boolean
};

class CryptoLockButton extends Component<Props, State> {
  static defaultProps = {
    name: "",
    onChange: () => {},
    disableChange: false
  };

  constructor(props: Props) {
    super(props);

    (this: any)._onChange = (this: any)._onChange.bind(this);
    this.state = {
      isChecked: true
    };
  }

  _onChange() {
    const { onChange, disableChange } = this.props;

    if (!disableChange) {
      this.setState(prevState => {
        return { isChecked: !prevState.isChecked }
      });
    }

    onChange();
  };

  render() {
    const { name } = this.props;
    const { isChecked } = this.state;

    return (
      <Container>
        <input type="checkbox" name={name} checked={isChecked} onChange={this._onChange} />
        <Label />
      </Container>
    );
  }
}

export default CryptoLockButton;

const Container = styled.div`
  position: relative;
  display: inline-block;
  width: 52px;
  height: 22px;
  line-height: 31px;

  &::after,
  &::before {
    font-family: "Roboto", sans-serif;
    font-feature-settings: normal;
    font-kerning: auto;
    font-language-override: normal;
    font-stretch: normal;
    font-style: normal;
    font-synthesis: weight style;
    font-variant: normal;
    font-weight: normal;
    text-rendering: auto;
  }

  input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    opacity: 0;
    cursor: pointer;
    z-index: 100;
    margin: 0;
  }

  input:checked + label {
    background-color: #daefb6;
    &:after {
      display: inline-block;
      content: "";
      width: 28px;
      height: 28px;
      background: #88cc17;

      background-image: url(${require("../../root/img/share-icons/unlocked.png")});
      ${hiDPI(1.5)} {
        background-image: url(${require("../../root/img/share-icons/unlocked@2x.png")});
        background-repeat: no-repeat;
        background-position: center;
        background-size: 12px 15px;
      }
      background-size: 12px 15px;
      background-repeat: no-repeat;
      background-position: center;
      left: 25px;
    }

    &:before {
      content: "";
      color: #fff;
      padding-left: 15px;
    }
  }

  @media only screen and (max-width: 400px) {
    width: 40px;
    input:checked + label {
      &:after {
        background: #fff;
        left: 36px;
      }

      &:before {
        font-size: 12px;
        padding-left: 12px;
      }
    }
  }
`;

const Label = styled.label`
  position: relative;
  display: inline-block;
  width: 52px;
  height: 22px;
  background: #ffcaca;
  line-height: 31px;
  border-radius: 20px;
  padding-bottom: 0 !important;
  transition: 0.4s;

  &:after {
    content: "";
    position: absolute;
    width: 28px;
    height: 28px;
    border-radius: 100%;
    left: 0px;
    top: -3px;
    background: #ff4d4d;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    transition: 0.4s;
    background-image: url(${require("../../root/img/share-icons/locked.png")});
    ${hiDPI(1.5)} {
      background-image: url(${require("../../root/img/share-icons/locked@2x.png")});
      background-size: 12px 15px;
      background-repeat: no-repeat;
      background-position: center;
    }
    background-size: 12px 15px;
    background-repeat: no-repeat;
    background-position: center;
  }

  &:before {
    display: inline-block;
    content: "";
    width: 28px;
    height: 28px;

    font-weight: 400;
    color: #fff;
    padding-left: 38px;
  }

  @media only screen and (max-width: 400px) {
    width: 65px;

    &:before {
      font-size: 12px;
      padding-left: 33px;
    }
  }
`;
