//@flow

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { hiDPI } from "polished";

import { __ } from "../../lib/translate";
import { detectIsMobile } from "../../lib/utils";
import apiMethod from "../../api/apiMethod";
import InputCheckbox from "../InputCheckbox";
import Tooltip from "../Tooltip";
import Button from "../ButtonDefault";
import { STATUS_TEXTS, getErrorReason } from "./constants";

type Props = {
  sourceid: "gphotos" | "gdriven" | "odn" | "dbxn" | "fb" | "ig",
  // status: "inactive" | "unavailable" | "running" | "stopped" | "aborted" | "finished",
  backup_metadata: Object,
  next_execution: string,
  backuped_albums: number,
  total_albums: number,
  is_aborted: boolean,
  is_running: boolean,
  is_paused: Boolean,
  is_stopped: boolean,
  is_inactive: boolean,
  unavailable: boolean,
  mode: 0 | 1,
  source: string,
  srcobj: {},
  created: string,
  downloaded_photos: number,
  exit_code: any,
  finished: string,
  isOverquota: boolean,
  type: "ongoing" | "onetime",
  is_allowed_to_backup: boolean,
  changeBackupStatus: () => void,
  stopBackup: (params: { backupID: "gphotos" | "gdriven" | "odn" | "dbxn" | "fb" | "ig" }) => void
};

const ThirdPartyBackupBox = ({
  sourceid,
  // status = "inactive",
  backup_metadata = null,
  next_execution = null,
  backuped_albums = 0,
  total_albums = 0,
  is_aborted,
  is_running,
  is_paused,
  is_stopped,
  is_inactive,
  unavailable,
  mode,
  source,
  srcobj,
  created,
  downloaded_photos,
  exit_code,
  finished,
  isOverquota = false,
  type,
  is_allowed_to_backup,
  changeBackupStatus = () => { },
  stopBackup = () => { },
  isBackupStateLoading
}: Props) => {
  const NEXT_DATE = next_execution ? new Date(next_execution) : null;
  const NEXT_DATE_UNIX_STAMP = NEXT_DATE && NEXT_DATE.getTime()
  const CURRENT_DATE = new Date().getTime()
  const NEXT_DATE_FORMATTED = next_execution ? NEXT_DATE.toLocaleDateString("en-US") : null;
  const LAST_DATE_FORMATTED = finished ? new Date(finished).toLocaleDateString("en-US") : null;
  const PROGRESS_PERCENT = total_albums > 0 ? Math.min(100, (backuped_albums / total_albums) * 100) : 0;

  const SERVICES_INFO = {
    gphotos: {
      name: "Google Photos",
      icon: <GPhotosIcon />,
      nextBackupTooltip: __("backup_cloud_tooltip"),
      statusTooltip: __("backup_photos_unresponsive")
    },
    gdriven: {
      name: "Google Drive",
      icon: <GDriveIcon />,
      nextBackupTooltip: __("backup_cloud_tooltip")
    },
    dbxn: {
      name: "Dropbox",
      icon: <DropboxIcon />,
      nextBackupTooltip: __("backup_cloud_tooltip")
    },
    dbxb: {
      name: "Dropbox Business",
      icon: <DropboxIcon />,
      nextBackupTooltip: __("backup_cloud_tooltip")
    },
    fb: {
      name: "Facebook",
      icon: <FacebookIcon />,
      nextBackupTooltip: __("backups_socialmedia_tooltip")
    },
    ig: {
      name: "Instagram",
      icon: <InstagramIcon />,
      nextBackupTooltip: __("backups_socialmedia_tooltip")
    },
    odn: {
      name: "One Drive",
      icon: <OnedriveIcon />,
      nextBackupTooltip: __("backup_cloud_tooltip")
    }
  };

  const [status, setStatus] = useState("inactive");
  const [expandedInfo, setExpandedInfo] = useState(false);
  const [accountInfo, setAccountInfo] = useState(false);
  const [isDisconnecting, setisDisconnecting] = useState(false);

  const isMobileDevice = detectIsMobile();
  const passedNextExecutionDate = next_execution && NEXT_DATE_UNIX_STAMP < CURRENT_DATE;
  const disabledButton = isBackupStateLoading || !is_allowed_to_backup || status === "running" || isOverquota || (type === "onetime" && status !== "inactive" && !passedNextExecutionDate);
  const errorState = !!(is_aborted && exit_code);

  useEffect(() => {
    let newStatus = "inactive";

    if (is_running || (!is_running && !is_aborted && !is_stopped && !finished && !is_inactive && !is_paused)) {
      newStatus = "running";
    } else if (is_paused) {
      newStatus = "paused";
    } else if (is_aborted) {
      newStatus = "aborted";
    } else if (is_stopped) {
      newStatus = "stopped";
    } else if (finished) {
      newStatus = "finished";
    } else if (is_inactive) {
      newStatus = "inactive";
    }

    if (unavailable) {
      newStatus = "unavailable";
    }

    setStatus(newStatus);
  }, [is_running, is_aborted, is_stopped, finished, is_inactive, unavailable, is_paused]);

  useEffect(() => {
    if (status != "unavailable" && !isDisconnecting) {
      getBackupAccountInfo(
        userinfo => {
          setAccountInfo(userinfo);
        },
        () => { }
      );
    }
    setisDisconnecting(false)
  }, [getBackupAccountInfo, status]);

  const unlinkAccount = () => {
    srcobj.unlinkAccount(() => {
      setAccountInfo(false);
      setisDisconnecting(true)
      if (finished) {
        stopBackup({
          backupID: sourceid,
          successCallback: () => {
            HFN.message(
              __(
                "backup_stopped_disconnected",
                "Backup has been stopped and your account is now disconnected"
              )
            );
          }
        });
      } else {
        HFN.message(__("backup_disconnected", "Your account is now disconnected"));
      }
    });
  };

  const getBackupAccountInfo = (successCallback, errorCallback) => {
    srcobj.getUserIdentity(
      userinfo => {
        successCallback(userinfo);
      },
      () => {
        errorCallback();
      }
    );
  };

  const onExpandClick = () => {
    setExpandedInfo(!expandedInfo);
  };

  const onButtonCLick = () => {
    if (disabledButton) {
      return;
    };

    changeBackupStatus({ backupID: sourceid })
  }

  const renderInitButton = () => {
    if (type === "onetime") {
      return (
        <ButtonWrapper>
          <Button
            borderRadius="50"
            minWidth="86px"
            onClick={onButtonCLick}
            disabledColor="#eeeeee"
            disabledTextColor="#b2b2b2"
            disabled={disabledButton}
          >
            {__("Start")}
          </Button>
        </ButtonWrapper>
      )
    };

    return (
      <ButtonWrapper>
        <InputCheckbox
          name=""
          checked={Boolean(status === "running" || status === 'paused' || status === "finished")}
          onChange={onButtonCLick}
          color="cyan"
          size="small"
          disabled={status === "running" || status === "paused" || (isOverquota && status !== "finished")}
        />
      </ButtonWrapper>
    )
  }

  const renderInfoRows = () => {
    const isActive = !(status === "unavailable" || status === "inactive");

    return (
      <React.Fragment>
        <InfoRow key={0}>
          <Text isActive={isActive}>{__("Folder")}</Text>
          <State isActive={isActive}>
            {backup_metadata ? (
              <a
                href={"https://my.pcloud.com/#page=filemanager&folder=" + backup_metadata.folderid}
              >
                {backup_metadata.name}
              </a>
            ) : (
              "-"
            )}
          </State>
        </InfoRow>

        <InfoRow key={1}>
          <Text isActive={isActive}>
            {__("Status")}
          </Text>
          <State className={"status_" + status} isActive={isActive} isError={errorState}>
            {__(STATUS_TEXTS[status])}
            {errorState ? (
              <Tooltip title={getErrorReason(exit_code)}>
                <AttentionIcon />
              </Tooltip>
            ) : null}
          </State>
        </InfoRow>

        {type === "ongoing" ? (
          <InfoRow key={2}>
            <Text isActive={isActive}>
              {__("next_backup")}
              <Tooltip title={SERVICES_INFO[sourceid].nextBackupTooltip}>
                <InfoIcon />
              </Tooltip>
            </Text>
            <State isActive={isActive}>{NEXT_DATE_FORMATTED || "-"}</State>
          </InfoRow>
        ) : null}

        <InfoRow key={3}>
          <Text isActive={isActive}>{__("last_backup")}</Text>
          <State isActive={isActive}>{LAST_DATE_FORMATTED || "-"}</State>
        </InfoRow>
      </React.Fragment>
    );
  };

  return (
    <Wrapper isAvailable={status !== "unavailable"}>
      <AlignContainer>
        <HeadRow>
          <AlignVertical>
            <ServiceIcon>{SERVICES_INFO[sourceid].icon}</ServiceIcon>
            <div>
              <ServiceName>{SERVICES_INFO[sourceid].name}</ServiceName>
              <UserAccount>
                {accountInfo || __("backup_no_account", "No account connected")}
              </UserAccount>
            </div>
          </AlignVertical>
          <AlignVertical>
            {isMobileDevice ? (
              <ArrowWrapper onClick={onExpandClick}>
                <Arrow pointing={expandedInfo ? "UP" : "DOWN"} />
              </ArrowWrapper>
            ) : null}
            {isMobileDevice ? null : renderInitButton()}
          </AlignVertical>
          {isMobileDevice ? renderInitButton() : null}
        </HeadRow>
      </AlignContainer>
      <InfoContainer expanded={expandedInfo}>{renderInfoRows()}</InfoContainer>
      <AlignContainer>
        {status === "unavailable" ? (
          <UnavailableMessage>{__("facebook_signin_unavailable_title", "Temporarily unavailable")}</UnavailableMessage>
        ) : (
          <ProgressContainer>
            <Row>
              <Text>{__("Folders")}</Text>
              {total_albums ? <State>{`${backuped_albums}/${total_albums}`}</State> : null}
            </Row>
            <ProgressBar>
              <Progress progress={PROGRESS_PERCENT} isactive />
            </ProgressBar>
          </ProgressContainer>
        )}
      </AlignContainer>
      <AlignContainer>
        <AccountInfoContainer>
          <Text>
            {accountInfo && !is_running && !is_paused ? (
              <Link isActive onClick={unlinkAccount}>
                {__("backup_disconnect_from", "Disconnect from") +
                  " " +
                  SERVICES_INFO[sourceid].name}
              </Link>
            ) : null}
          </Text>
        </AccountInfoContainer>
      </AlignContainer>
    </Wrapper>
  );
};

export default ThirdPartyBackupBox;

const Wrapper = styled.div`
  width: calc(50% - 10px);
  padding: 30px 0 30px;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.25), 3.5px 3.5px 10px 0 rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
  box-sizing: border-box;
  border-radius: 10.5px;
  position: relative;

  @media (max-width: 768px) {
    width: 100%;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.03);
  }

  ${({ isAvailable }) => {
    if (!isAvailable) {
      return `
        &::after {
          content: '';
          position: absolute;
          top: 0px;
          left: 0px;
          display: inline-block;
          width: 100%;
          height: 100%;
          background-color: #ffffff;
          border-radius: 10.5px;
          opacity: 0.5;
          z-index: 101;
        }
      `;
    }
  }}
`;

const ButtonWrapper = styled.div`
  @media (max-width: 768px) {
    margin-top: 8px;
    width: 100%;
  }
`;

const AlignContainer = styled.div`
  padding: 0px 30px;
  box-sizing: border-box;

  @media (max-width: 400px) {
    padding: 0px 10px;
  }
`;

const AlignVertical = styled.div`
  display: flex;
  align-items: center;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
`;

const InfoRow = styled(Row)`
  height: 36px;
  box-sizing: border-box;
`;

const HeadRow = styled(Row)`
  margin-bottom: 30px;

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

const ServiceIcon = styled.div`
  display: inline-block;
  width: 32px;
  height: 32px;
  background-repeat: no-repeat;
  background-size: cover;
`;

const GPhotosIcon = styled(ServiceIcon)`
  background-image: url(${require("../../../root/img/backup/gp.png")});
  ${hiDPI()} {
    background-image: url(${require("../../../root/img/backup/gp@2x.png")});
  }
`;

const GDriveIcon = styled(ServiceIcon)`
  background-image: url(${require("../../../root/img/backup/gdrive.png")});
  ${hiDPI()} {
    background-image: url(${require("../../../root/img/backup/gdrive@2x.png")});
  }
`;

const OnedriveIcon = styled(ServiceIcon)`
  background-image: url(${require("../../../root/img/backup/onedrive.png")});
  ${hiDPI()} {
    background-image: url(${require("../../../root/img/backup/onedrive@2x.png")});
  }
`;

const DropboxIcon = styled(ServiceIcon)`
  background-image: url(${require("../../../root/img/backup/dropbox.png")});
  ${hiDPI()} {
    background-image: url(${require("../../../root/img/backup/dropbox@2x.png")});
  }
`;

const FacebookIcon = styled(ServiceIcon)`
  background-image: url(${require("../../../root/img/backup/facebook.png")});
  ${hiDPI()} {
    background-image: url(${require("../../../root/img/backup/facebook@2x.png")});
  }
`;

const InstagramIcon = styled(ServiceIcon)`
  background-image: url(${require("../../../root/img/backup/instagram.png")});
  ${hiDPI()} {
    background-image: url(${require("../../../root/img/backup/instagram@2x.png")});
  }
`;

const ServiceName = styled.div`
  display: inline-block;
  margin-left: 12px;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
`;

const InfoContainer = styled.div`
  background-color: #fafafa;
  padding: 0px 30px;
  box-sizing: border-box;
  margin-bottom: 20px;
  border-top: 1px solid #f8f8f8;
  border-bottom: 1px solid #f8f8f8;

  ${Row} {
    padding: 10px 0px;
    border-bottom: 1px solid #f0f0f0;

    &:last-of-type {
      border-bottom: 0px;
    }
  }

  @media (max-width: 768px) {
    display: inline-block;
    width: 100%;
    height: ${({ expanded }) => (expanded ? "145px" : "0px")};
    overflow: hidden;
    transition: height 250ms ease-in-out;
  }

  @media (max-width: 400px) {
    padding: 0px 10px
  }
`;

const Text = styled.div`
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: left;
  color: ${({ isActive }) => (isActive ? "#000000" : "#999999")};
  display: flex;
`;

const State = styled.div`
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ isActive, isError }) => {
    if (isActive && !isError) {
      return "#000000";
    }

    if (!isActive && !isError) {
      return "#999999";
    }

    if (isError) {
      return "#f73c3c";
    }
  }};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-left: 12px;

  a {
    text-decoration: underline;
    
    color: ${({ isActive, isError }) => {
    if (isActive && !isError) {
      return "#000000";
    }

    if (!isActive && !isError) {
      return "#999999";
    }

    if (isError) {
      return "#f73c3c";
    }
  }};
  }

  &.status_running {
    padding-right: 24px;
    background-image: url(${require("../../../root/img/loading.gif")});
    background-repeat: no-repeat;
    background-position: right;
    background-size: 16px 16px;
  }

  &.status_paused {
    padding-right: 24px;
    background-image: url(${require("../../../root/img/loading.gif")});
    background-repeat: no-repeat;
    background-position: right;
    background-size: 16px 16px;
  }
`;

const Link = styled(State)`
  text-decoration: underline;
  cursor: pointer;
`;

const UnavailableMessage = styled.div`
  border-radius: 3px;
  border: solid 1px #fcf2d6;
  background-color: #fff8e1;
  padding: 10px;
  position: relative;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  z-index: 102;
`;

const ProgressContainer = styled.div`
  ${Row} {
    margin-bottom: 4px;

    ${Text},
    ${State} {
      font-family: "Roboto", sans-serif;
      font-size: 11px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.18;
      letter-spacing: normal;
      text-align: left;
      color: #999999;
    }
  }
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 6px;
  border-radius: 3px;
  box-shadow: inset 0px 2px 1px 0 rgba(0, 0, 0, 0.03);
  background-color: #eeeeee;
  position: relative;
`;

const Progress = styled.div`
  display: inline-block;
  width: ${({ progress }) => (progress ? progress : 0)}%;
  height: 6px;
  border-radius: 3px;
  background-color: ${({ isactive }) => (isactive ? "#17bed0" : "#999999")};
  position: absolute;
  top: 0px;
  left: 0px;
  transition: width 300ms ease-in-out;
`;

const InfoIcon = styled.div`
  display: inline-block;
  margin: 0px 8px;
  width: 16px;
  height: 16px;
  cursor: pointer !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  background-image: url(${require("../../../root/img/data-location/info.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/data-location/info@2x.png")});
  }
`;

const AttentionIcon = styled.div`
  display: inline-block;
  margin: 0px 8px;
  width: 16px;
  height: 16px;
  cursor: pointer !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  background-image: url(${require("../../../root/img/data-location/att-red.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/data-location/att-red@2x.png")});
  }
`;

const Arrow = styled.div`
  display: inline-block;
  width: 8px;
  height: 8px;
  border-bottom: 2px solid #000000;
  border-right: 2px solid #000000;
  transform: ${({ pointing }) => (pointing === "DOWN" ? "rotate(45deg)" : "rotate(-135deg)")};
  transition: transform 250ms ease-in-out;
`;

const ArrowWrapper = styled.div`
  padding: 6px 12px;
  margin-right: 0px; //12px;
  border-right: 1px solid #f2f2f2;
`;

const UserAccount = styled.div`
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.7;
  text-align: left;
  color: #b2b2b2;
  margin-left: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const AccountInfoContainer = styled.div`
  margin-top: 8px;
  text-align: left;

  ${Link} {
    margin-left: 0px;
    margin-top: 4px;
    color: #17bed0;
    text-decoration: none;
  }
`;
