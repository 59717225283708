import React from "react";
import { PlayButton } from '@vidstack/react';
import { PlayLargeIcon, PlayLargeIconMobile } from "../../icons";

const PlayLargeButton = () => {
  return (
    <PlayButton
      className="plyr__control plyr__control--overlaid"
      aria-label="Play"
      data-plyr="play"
    >
      {/* <div className="play-large-icon"></div>
      <div className="play-large-icon-mobile"></div> */}
      <div className="play-large-icon-gradient-wrapper">
        <PlayLargeIcon />
        <PlayLargeIconMobile className="mobile-only" />
      </div>
    </PlayButton>
  );
}

export default PlayLargeButton;