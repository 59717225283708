import React, { useRef } from "react";
import styled from "styled-components";
import { ImageErrorIcon, VideoErrorIcon } from "./icons";
import useDownloadMedia from "./hooks/useDownloadMedia";

type Props = {
  itemData: {
    metaData: object
  },
  opts: {
    code: string, 
    linkpassword: string, 
    candownload: boolean
  },
  showDownloadButton: boolean,
  onClose: () => void
};

const ErrorMediaPreview = ({
  itemData,
  opts,
  showDownloadButton = true,
  onClose = () => {}
}: Props) => {

  const downloadOnMobileDLinkEl = useRef(null);
  
  const { downloadFile, canDownloadMobile, canDownload } = useDownloadMedia({
    downloadOnMobileDLinkEl,
    downloadItem: itemData,
    opts,
    onClose
  });

  const renderDownloadButton = () => {
    if (canDownloadMobile) {
      // Dlink on mobile with allowed "Download".
      return <DownloadButton ref={downloadOnMobileDLinkEl}>{__("Download", "Download")}</DownloadButton>
    }

    if (canDownload) {
      return <DownloadButton onClick={downloadFile}>{__("Download", "Download")}</DownloadButton>
    }
  };

  return (
    <ErrorWrapper>
      {itemData.metaData.category === HFN.CATEGORY.IMAGE ? <ImageErrorIcon /> : <VideoErrorIcon />}
      <ErrorMessage>{__("gallery_error_no_preview", "Looks like this file doesn’t have a preview we can show you.")}</ErrorMessage>
      {showDownloadButton ? renderDownloadButton() : null}
    </ErrorWrapper>
  );
};

export default ErrorMediaPreview;

const ErrorWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 24px;

  @media (max-width: 768px) {
    padding: 0 16px;
  }

  & > svg {
    opacity: 0.48;
    flex-shrink: 1;
    max-width: 153px;
    max-height: 152px;
    min-width: 60px;
    min-height: 60px;
    width: 100%;
    height: 100%;
  }
`;

const ErrorMessage = styled.p`
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  margin-top: 24px;
  
  @media (max-height: 380px) {
    margin-top: 0px;
  }
`;

const DownloadButton = styled.button`
  display: flex;
  height: 40px;
  padding: 0px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: #17BED0;
  border: none;
  color: #fff;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  flex-shrink: 0;

  margin-top: 24px;
  @media (max-height: 350px) {
    padding: 0px 16px;
  }
  @media (max-height: 300px) {
    margin-top: 16px;
    height: 32px;
  }
`;