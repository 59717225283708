import React, { PureComponent } from "react";
import { findDOMNode } from "react-dom";
import styled from "styled-components";
import { hiDPI } from "polished";

import InputCheckbox from "../../components/InputCheckbox";

type Props = {
  title: string,
  isActive: boolean,
  disable: boolean,
  shouldToggle: boolean,
  activate: () => void,
  renderButton: () => void,
  resetResize?: () => void
};

type State = {
  isOpen: boolean,
  contentHeight: number
};

class Tab extends PureComponent<Props, State> {
  static defaultProps = {
    shouldToggle: true
  };
  tabContent: ?HTMLElement;

  constructor(props: Props) {
    super(props);

    this.state = {
      isActive: false,
      isOpen: this.props.isActive,
      contentHeight: 0
    };

    (this: any).toggleTab = this.toggleTab.bind(this);
    (this: any).resizeTab = this.resizeTab.bind(this);
  }

  componentDidMount() {
    this.resizeTab();
  }

  resizeTab() {
    const { resetResize } = this.props;
    const contentElement = findDOMNode(this.tabContent);
    let contentHeight = 0;

    for (let i = 0; i < contentElement.children.length; i++) {
      let height = contentElement.children[i].offsetHeight;
      contentHeight += height;
    }

    this.setState({ contentHeight: contentHeight });
    if (resetResize && typeof resetResize === "function") {
      resetResize();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      isActive: prevActive,
      shouldResizeTab: prevShouldResizeTab
    } = prevProps;
    const { isActive, shouldResizeTab } = this.props;

    if (isActive !== prevActive) {
      this.setState({ isOpen: isActive });
    }

    if (shouldResizeTab !== prevShouldResizeTab && shouldResizeTab === true) {
      this.resizeTab();
    }
  }

  toggleTab() {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen
    }));
  }

  renderToggleButton() {
    const {isOpen} = this.state;
    return (
      <React.Fragment>
        <Separator />
        <ToggleButton isOpen={isOpen} onClick={this.toggleTab} />
      </React.Fragment>
    );
  }

  render() {
    const {
      title,
      activate,
      children,
      disable,
      isActive,
      renderButton,
      shouldToggle
    } = this.props;
    const { isOpen, contentHeight } = this.state;

    return (
      <TabWrapper>
        {!disable ? null : <Overlay />}
        <TabHeader>
          <Title>{title}</Title>
          <RightAlign>
            {renderButton ? (
              renderButton()
            ) : (
              <InputCheckbox
                checked={isActive && !disable}
                onChange={activate}
              />
            )}
            {shouldToggle ? this.renderToggleButton() : null}
          </RightAlign>
        </TabHeader>
        <TabContent
          isOpen={isOpen}
          ref={ref => (this.tabContent = ref)}
          contentHeight={contentHeight}
        >
          {isActive ? null : <Overlay />}
          {children}
        </TabContent>
      </TabWrapper>
    );
  }
}

export default Tab;

const TabWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 780px;
  margin: 10px auto;
  background: #fff;
  border-radius: 6px;
  border: 1px solid #eee;
  box-shadow: 0px 1px 2px 0 rgba(0, 0, 0, 0.03);
`;

const TabHeader = styled.div`
  width: 100%;
  height: 52px;
  background-color: #fafafa;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border-bottom: 1px solid #eee;
  padding: 0px 15px 0px 15px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: bold;
  text-align: left;
  color: #000;
`;

const RightAlign = styled.div`
  margin-left: 15px;
  a {
    font-size: 11px;
    height: 31px;
    line-height: 31px;
    min-width: 130px;
  }
`;

const ToggleButton = styled.div`
  display: inline-block;
  width: 40px;
  height: 40px;
  box-sizing: border-box;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 12px 8px;
  vertical-align: middle;
  transition: transform 0.4s ease-in-out;
  cursor: pointer;

  background-image: url(${require("../../../root/img/branding-settings/collapse.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/branding-settings/collapse@2x.png")});
  }

  transform: ${props => (props.isOpen ? "rotate(0deg)" : "rotate(180deg)")};
`;

const TabContent = styled.div`
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  transition: max-height 0.4s ease-in-out;
  max-height: ${props => (props.isOpen ? props.contentHeight + "px" : `0px`)};
`;

const Separator = styled.div`
  display: inline-block;
  width: 1px;
  height: 31px;
  background-color: #e9e9e9;
  margin-left: 10px;
  vertical-align: middle;
`;

const Overlay = styled.div`
  display: inline-block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 101;
  border-radius: 6px;
`;
