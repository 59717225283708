// @flow

import React from "react";
import styled from "styled-components";
import { hiDPI } from "polished";

import Arrow, { BorderedArrow } from "../Arrow";

type StepPointProps = {
  active?: boolean,
  selected?: boolean,
  completed?: boolean,
  step: number,
  expanded: boolean
};

const StepPoint = ({ step, selected, completed, expanded, ...rest }: StepPointProps) => (
  <Wrap {...rest}>
    {completed === "p" && <span>text</span>}
    {completed && <Completed />}
    {selected && !completed && <Selected />}
    {!completed && !selected && <Base>{step}</Base>}

    {selected && expanded && <BorderedArrowComp borderColor="#efefef" color="#fff" direction="top" size={9} />}
  </Wrap>
);

export default StepPoint;

const BorderedArrowComp = styled(BorderedArrow)`
  bottom: 3px;
  z-index: 2;
`;

const Wrap = styled.div`
  width: 35px;
  height: 35px;
  padding: 7.5px;
  box-sizing: border-box;
  position: relative;
  cursor: ${props => props.active ? "pointer" : "default"};
`;

const Base = styled.div`
  width: 20px;
  height: 20px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  border-radius: 100%;
  border: 2px solid #61c100;
  line-height: 16px;
  text-align: center;
  font-size: 11px;
  color: #8e8e95;
  background-color: #fff;
  box-sizing: border-box;
`;

const Selected = styled(Base)`
  border-width: 6px;
  width: 20px;
  height: 20px;
`;

const Completed = styled(Base)`
  border: 2px solid #8f8f96;
  background-image: url(${require("../../../root/img/journey/completed.png")});
  ${hiDPI()} {
    background-image: url(${require("../../../root/img/journey/completed@2x.png")});
  }
`;
