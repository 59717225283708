// @flow

import React, { Component } from "react";
import ReactDom from "react-dom";
import styled from "styled-components";
import { clearFix } from "polished";
import Clipboard from "clipboard";

import InputText from "./InputText";

type InputCopyProps = {
  title?: string,
  value: string,
  withOpen?: boolean
};

export default class InputCopy extends Component<InputCopyProps, { useClassName: string }> {
  inputRef: HTMLInputElement;
  clipboard: { destroy: () => void };

  static defaultProps = {
    withOpen: false
  };

  constructor(props: InputCopyProps) {
    super(props);

    this.state = { useClassName: classId() };

    (this: any)._onFocus = this._onFocus.bind(this);
  }

  _onFocus() {
    if (this.inputRef) {
      this.inputRef.select();
    }
  }

  componentDidMount() {
    this.clipboard = new Clipboard("." + this.state.useClassName, {
      text: () => this.props.value
    });

    this.clipboard.on("success", () => {
      HFN.message("Copied to clipboard.");
    });
  }

  componentWillUnmount() {
    console.log(this.clipboard);
    if (this.clipboard) {
      this.clipboard.destroy();
    }
  }

  render() {
    const { title, withOpen, ...rest } = this.props;
    const { useClassName } = this.state;

    return (
      <Wrap>
        <Label>
          {title}
        </Label>
          <div>
            {withOpen && (
              <a href={rest.value} target="_blank">
                <OpenInNew />
              </a>
            )}
            <Copy className={useClassName} />
            <InputWrap {...this.props}>
              <InputText
                {...rest}
                ref={ref => {
                  const input = ReactDom.findDOMNode(ref);
                  if (input && input instanceof HTMLInputElement) {
                    this.inputRef = input;
                  }
                }}
                onClick={this._onFocus}
                readOnly
              />
            </InputWrap>
          </div>
      </Wrap>
    );
  }
}

const InputWrap = styled.div`margin-right: ${props => (props.withOpen ? "90px" : "45px")};`;

const Label = styled.label``;

const Wrap = styled.div`
  font-weight: bold;
  ${clearFix()} display: block;
  margin-top: 15px;
  padding-bottom: 10px;
  text-align: left;

  &:first-child {
    margin-top: 0;
  }

  & > label {
    padding-bottom: 0px;
  }

  & > div {
    margin-top: 5px;
  }
`;

const Copy = styled.div`
  width: 40px;
  height: 40px;
  background: #1bc9d3 url("/ZCDNURL/img/ul/copy-link.png") no-repeat center center;
  border-radius: 5px;
  float: right;
  cursor: pointer;

  &:hover {
    background-color: #18b5bd;
  }
`;

const OpenInNew = styled.div`
  width: 40px;
  height: 40px;
  background: #a4a4a4 url("/ZCDNURL/img/ul/open-new-window.png") no-repeat center center;
  border-radius: 5px;
  float: right;
  cursor: pointer;
  margin: 0 0 0 5px;

  &:hover {
    background-color: #939393;
  }
`;

let id = 0;
function classId() {
  return "class_" + ++id;
}
