// @flow

import * as React from "react";
import styled from "styled-components";
import type { Metadata } from "../types/filesystem";
import InputCopy from "./InputCopy";
import { CATEGORY } from "@pcloud/web-utilities/dist/config/constants";

const EmbedDirectlink = ({ directlink, metadata }: { directlink: string, metadata: Metadata }) =>
  <Wrap>
    <InputCopy withOpen title="Direct link" value={directlink} />
    <InputCopy title="Link for Websites" value={htmlLink(directlink, metadata)} />
    <InputCopy title="Link for Forums" value={urlBBCode(directlink, metadata)} />
    {isImage(metadata) && <InputCopy title="Embed Image" value={imageBBCode(directlink)} />}
  </Wrap>;

export default EmbedDirectlink;

const Wrap = styled.div`
  width: 500px;
`;

function isImage(metadata: Metadata): boolean {
  return !metadata.isfolder && metadata.category === CATEGORY.IMAGE;
}

function htmlLink(link: string, meta: Metadata): string {
  return "<a href=\"" + link + "\">" + meta.name + "</a>";
}

function imageBBCode(link: string): string {
  return "[img]" + link + "[/img]";
}

function urlBBCode(link: string, meta: Metadata): string {
  return "[url=" + link + "]" + meta.name + "[/url]";
}
