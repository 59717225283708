// @flow

import * as React from "react";
import styled from "styled-components";

type TextareaProps = {
  name: string,
  placeholder: string,
  value: string
};

const Textarea = ({ name, placeholder, value, ...rest }: TextareaProps) => (
  <TArea {...rest} name={name} placeholder={placeholder} value={value} />
);

export default Textarea;

const TArea = styled.textarea`
  min-width: initial;
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
`;
