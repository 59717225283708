// @flow

import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";

import Modal, { OvalModalWrap, CloseModalIcon } from "../../Modal";
import Button from "../../ButtonDefault";
import { Loader } from "../../../containers/TwoFactorAuth/SharedComponents";
import * as Style from "../styledComponents";

import FileIcon from "../../FileIcon";

import { __ } from "../../../lib/translate";
import { getIconType } from "../../../lib/utils";
import apiMethod from "../../../api/apiMethod";
import { errorKeys } from "../../../lib/errors";
import { ICONS } from "../../../lib/icons";

type Props = {
  list: Array<any>
};

const TrashRestoreModal = ({ list }: Props) => {
  const [isOpened, setIsOpened] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingModal, setLoadingModal] = useState(true);

  const [restoreItemName, setRestoreItemName] = useState("");
  const [itemMetadata, setItemMetadata] = useState({});
  const [iconNameItem, setIconNameItem] = useState("");
  const [itemIndex, setItemIndex] = useState(0);

  useEffect(() => {
    setLoadingModal(false);

    list.forEach((data, index) => {
      const { isfolder, folderid, fileid } = data;

      setItemIndex(index);

      let rp = {};

      if (isfolder) {
        rp.folderid = folderid;
      } else {
        rp.fileid = fileid;
      }

      apiMethod(
        "trash_getrestorepath",
        rp,
        ret => {
          setRestoreItemName(ret.destination.name);

          setIconNameItem(getIconType(ret.destination));
          setItemMetadata(ret.metadata);
        },
        {
          errorCallback: ({ result, error }) => {
            if (errorKeys[result]) {
              HFN.message(__(errorKeys[result]), "error");
            } else {
              HFN.message(__("something_went_wrong_refresh_and_try_again"), "error");
            }
            setIsOpened(false);
            throw new Error(error);
          }
        }
      );
    });
  }, [list]); //[list]

  useEffect(() => {
    if (isOpened) {
      console.log("ADD event on keyup TrashRestoreModal");
      document.addEventListener("keyup", onEnter);
    } else {
      console.log("REMOVE event on keyup TrashRestoreModal");
      document.removeEventListener("keyup", onEnter);
    }
  }, [isOpened, onEnter]); //[isOpened, onEnter]

  const onEnter = useCallback(
    (e: any) => {
      if (e.keyCode === 13) {
        onRestoreClick();
      }
    },
    [onRestoreClick]
  );

  const onCloseClick = () => {
    if (loading) {
      return;
    }
    setIsOpened(false);
  };

  const onRestoreClick = useCallback(() => {
    if (loading) {
      return;
    }
    setLoading(true);

    let batch = new batchApiCall({
      sequential: true,
      errorCallback: function(ret, opts) {
        if (ret.result == 2246) {
          console.log(opts);
          console.error("11");
          HFN.initTrashIsPremiumFeatureNow(ret.faileditems[0]);
          HFN.refreshTrashFolder(list[0].parentfolderid);
          return;
        }

        if (ret.result === 2008) {
          HFN.openModalFullQuota();
        } else {
          HFN.message(ret.result, "error");
          HFN.refreshTrashFolder(list[0].parentfolderid);
        }

        setIsOpened(false);
      }
    });

    let n;
    for (n = 0; n < list.length; ++n) {
      if (list[n].isfolder) {
        batch.addCall("trash_restore", { folderid: list[n].folderid });
      } else {
        batch.addCall("trash_restore", { fileid: list[n].fileid });
      }
    }

    batch.execute(function() {
      HFN.message(__("Item(s) restored."));
      HFN.refreshTrashFolder(list[0].parentfolderid);

      setIsOpened(false);
    });
  });

  const renderItems = (data, index) => {
    const { name, icon } = data;
    const itemData = data;

    return (
      <Style.Row key={index}>
        <FileIcon item={icon} type={ICONS.LIST_SMALL} metadata={itemData} />
        <Title>{name}</Title>
      </Style.Row>
    );
  };

  const renderItemTo = () => {
    if (!iconNameItem) {
      return null;
    }
    return (
      <Style.Row key={itemIndex}>
        <FileIcon item={iconNameItem} type={ICONS.LIST_SMALL} metadata={itemMetadata} />
        <Title>{restoreItemName}</Title>
      </Style.Row>
    );
  };

  const renderContent = () => {
    return (
      <React.Fragment>
        <Body>
          <Header>
            {loading ? __("action_restoring", "Restoring") : __("Restore Item(s)", "Restore Item(s)")}
          </Header>
          {!loading ? (
            <Style.Container style={{ paddingBottom: "10px" }}>
              <Style.Label>{__("Items to Restore", "Items to Restore")}</Style.Label>
              <ItemsList>{list.map(renderItems)}</ItemsList>
              {list.length == 1 ? (
                <div>
                  <Style.Label style={{ marginTop: "15px" }}>{__("Restore to", "Restore to")}</Style.Label>
                  <ItemsList>{list.map(renderItemTo)}</ItemsList>
                </div>
              ) : null}
            </Style.Container>
          ) : (
            <LoaderWrapper>
              <Loader />
            </LoaderWrapper>
          )}
        </Body>
        <Style.Footer>
          <Button
            style={{ marginRight: "5px" }}
            color="lightgray4"
            disabled={loading}
            onClick={onCloseClick}
          >
            {__("Cancel")}
          </Button>
          <Button
            style={{ marginLeft: "5px" }}
            color="cyan"
            disabled={loading}
            onClick={onRestoreClick}
          >
            {__("Restore")}
          </Button>
        </Style.Footer>
      </React.Fragment>
    );
  };

  return (
    <Modal onClose={onCloseClick} opened={isOpened}>
      <OvalModalWrap>
        <CloseModalIcon onClick={onCloseClick} />
        <Content>{loadingModal ? <Loader /> : renderContent()}</Content>
      </OvalModalWrap>
    </Modal>
  );
};

export default TrashRestoreModal;

const Content = styled.div`
  padding: 30px;
  width: 480px;
  box-sizing: border-box;
  text-align: center;
  min-height: 220px;
`;

const Title = styled.div`
  font-size: 15px;
  font-weight: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex: 1;
  text-align: left;
`;

const Body = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

const LoaderWrapper = styled.div`
  position: relative;
  min-height: 90px;
`;

const ItemsList = styled(Style.Box)`
  line-height: 24px;
  padding: 10px;
  font-size: 15px;
  font-weight: normal;
  text-align: left;
  padding: 10px;
  justify-content: flex-start;
  flex-direction: column;
  max-height: 115px;
  overflow: auto;
  overflow-x: hidden;

  & > div:only-child {
    padding-bottom: 0px;
  }

  /* width */
  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 3px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #eaeaea;
    border-radius: 3px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #aaa;
    border-radius: 3px;
    transition: background 200ms ease-in-out;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const Header = styled(Style.Header)`
  margin-bottom: 0;
`;
