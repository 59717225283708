// @flow

import * as React from "react";
import styled from "styled-components";

import type { Folder } from "../../types/filesystem";
import { TDiv, TSpan } from "../../components/intl";
import { __ } from "../../lib/translate";

const PublicFolderTop = ({ folder, trialexpiretime }: { folder?: Folder, trialexpiretime?: string }) =>
  <TitleWrap>
    <Title>
      <TSpan id="public_folder">Public Folder --- A</TSpan>
      {trialexpiretime &&
        (daysUntil(trialexpiretime) ?
        <span>
          {" - "}
          {__("n_days_trial_left", "%n% days trial left", { n: daysUntil(trialexpiretime) })}
        </span> : <span>{" - "}<TSpan id="trial_last_day">last day of trial</TSpan></span>)}
    </Title>
    <SubTitle id="public_folder_explain">
      Use your pCloud storage as a hosting service to create static HTML websites, embed images or simply create direct
      links to your files. All files you place in the Public Folder will be accessible as a index directory.
    </SubTitle>
  </TitleWrap>;

export default PublicFolderTop;

const TitleWrap = styled.div`
  text-align: center;
  width: 980px;
  margin: 0 auto 20px auto;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
  margin-top: 20px;

  a {
    color: #17bed0;
    text-decoration: underline;
    font-weight: normal;

    &:hover {
      text-decoration: none;
    }
  }
`;

const SubTitle = styled(TDiv)`
  font-size: 14px;
  line-height: 20px;
  max-width: 700px;
  margin: 0 auto;
`;

function daysUntil(time: string): number {
  // test
  //const dateUntil = new Date().getTime() + (1000 * 3600 * 2);

  const dateUntil = new Date(time).getTime();
  const dateNow = new Date().getTime();
  const days = Math.floor((dateUntil - dateNow) / (3600 * 24 * 1000));

  return days;
}
