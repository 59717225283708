// @flow
import React from "react";
import styled, { css } from "styled-components";
import { hiDPI } from "polished";


export const getStrengthColor = (password) => {
  const strength = pCloudCrypto.passwordStrength(password);
  let color = "";

  if (password.length > 0 && password.length < 8) {
    color = "red";
  } else if (password.length >= 8 && password.length < 10) {
    color = "orange";
  } else if (password.length >= 10) {
    color = "green";
  }

  return color;
};

type Props = {
  password: string
};

const PasswordStrength = ({ password } : Props) => {
  const color = getStrengthColor(password);

  return (
    <StrenghtWrapper color={color}>
      <StrenghtBox className="box" />
      <StrenghtBox className="box" />
      <StrenghtBox className="box" />
    </StrenghtWrapper>
  )
};

export default PasswordStrength;

const StrenghtWrapper = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  height: 3px;

  ${props => setBgColor(props)};

  ${props =>
    props.isSelected
      ? css`
          background-image: url(${require("../../root/img/full-text-search/filter-active.png")});
          ${hiDPI(1.5)} {
            background-image: url(${require("../../root/img/full-text-search/filter-active@2x.png")});
          }
        `
      : ""}
`;

const StrenghtBox = styled.div`
  width: 100%;
  height: 3px;
  background-color: #ddd;
  border-radius: 2px;
  margin-right: 2px;
  ${props => setBgColor(props)};
`;

// const PassFill = styled.div`
//   background: url(${require("../../root/img/crypto/pass_pattern.png")})  repeat-x 0 0 #ddd;
//   position: absolute;
//   width: 100%;
//   height: 3px;
// `;

// const PassBox = styled.div`
//   background: url(${require("../../root/img/crypto/pass_pattern.png")})  repeat-x 0 0 #ff2626;
//   position: absolute;
//   height: 3px;
//   width: ${props => props.width}%;
//   max-width: 100%;
//   ${props => setBgColor(props)};
// `;

const setBgColor = (props) => {
  const colors = { red: "ff2626", orange: "ff9326", green: "88cc17" };

  if (props.color == "red") {
    return css`
      .box:nth-child(-n + 1) {
        background-color: #f73c3c;
      }
    `;
  } else if (props.color == "orange") {
    return css`
      .box:nth-child(-n + 2) {
        background-color: #ff9421;
      }
    `;
  } else if (props.color == "green") {
    return css`
      .box:nth-child(-n + 3) {
        background-color: #86c600;
      }
    `;
  }
};
