// @flow

import React, { PureComponent } from "react";
import styled from "styled-components";

import { __ } from "../../lib/translate";

import Button, { ButtonCentered } from "../../components/ButtonDefault";
import SuccessIcon from "../../../root/img/family/success.svg";

import {
  Container,
  Header,
  Message,
  Footer,
  Title
} from "./SharedFamilyComponents";

type Props = {
  onClose: () => void
};

class ResendInvitationModal extends PureComponent<Props, {}> {
  static defaultProps = {
    onClose: () => {}
  };

  render() {
    const { onClose } = this.props;

    return (
      <Container>
        <Header className="resend">{__("family_resend_header", "Resend")}</Header>
        <Message>
          <SuccessIcon />
          <Title>
            {__("family_resend_message", "Invitation sent successfully!")}
          </Title>
        </Message>
        <Footer className="single">
          <ButtonCentered 
            color={"lightgray4"}
            style={{
              width: "100%"
            }}
            onClick={onClose}
          >
            {__("Close")}
          </ButtonCentered>
        </Footer>
      </Container>
    );
  }
}

export default ResendInvitationModal;
